import { useContext, useEffect, useRef, useState } from "react"
import { AiFillWarning, AiOutlineInfoCircle } from "react-icons/ai"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import { toSignificantDigits } from "utils/toSignificant"
import { Caption } from "styles/TextStyles"

import { ONE_DAY } from "utils/calls/investmentHub"
import StakeContext from "./StakeContext"
import StakeOverviewRowHoverBox from "./StakeOverviewRowHoverBox"

interface OverviewProps {
  total?: number
  existingVoid?: number | null
  duration?: number
  existingDuration?: number | null
  startDate?: Date | null
  unlock?: number
  voidEnergyGenerated?: number
  newEstimatedRewards?: number
}

const StakeOverview = ({
  total,
  existingVoid,
  duration,
  existingDuration,
  startDate,
  unlock,
  voidEnergyGenerated,
  newEstimatedRewards,
}: OverviewProps) => {
  const { voidEnergy } = useContext(StakeContext)

  const calculateUnlockDate = (weeks: number, isStakeModified = false) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ]

    // const now = new Date(startDate) // @dev: original design, but not applicable anymore due to `user.stakeStartTime = _now()` line in contract: https://github.com/TangleSwap/tangleswap-VE-staking/blob/main/contracts/TangleswapVEStaking.sol#L171
    const now = isStakeModified ? new Date() : new Date(startDate)
    const noExtendDuration = weeks - (unlock || 0)
    const noExtendDate = new Date()

    noExtendDate.setDate(now.getDate() + noExtendDuration * 7)
    now.setDate(now.getDate() + weeks * 7)

    if (now) {
      sessionStorage.setItem("veStakingNoExtendDate", noExtendDate.toString())
      sessionStorage.setItem("veStakingNewUnlockDate", now.toString())
    }
    if (total) {
      const [a, b] = [Number(existingVoid), Number(total)]
      sessionStorage.setItem("veStakingCurrentStakeAmount", String(a))
      sessionStorage.setItem("veStakingNewStakeAmount", String(a + b))
    }

    const timeZone = now
      .toLocaleDateString("en-US", {
        day: "2-digit",
        timeZoneName: "short",
      })
      .slice(4)

    return `${now.getDate()} ${
      monthNames[now.getMonth()]
    } ${now.getFullYear()}, ${now.getHours()}:${
      now.getMinutes() > 9 ? now.getMinutes() : `0${now.getMinutes()}`
    } ${timeZone}`
  }

  const calculateWeeksLeft = () => {
    const diff = startDate.getTime() - new Date().getTime()
    return Math.round(diff / (1000 * ONE_DAY * 7))
  }

  const ONE_WEEK = 7 * 24 * 60 * 60 * 1000 // milliseconds in a week
  const extraDurationFromReset =
    new Date().getTime() - new Date(startDate).getTime()
  const extraWeeksFromReset = Math.round(extraDurationFromReset / ONE_WEEK)

  const loadedDuration = existingDuration / 7
  const totalDuration =
    Number(loadedDuration) + Number(duration) + Number(extraWeeksFromReset)

  return (
    <Container>
      <OverviewRow>
        <OverviewText>TOTAL STAKE</OverviewText>
        <OverviewText>
          {existingVoid ? (
            total ? (
              <ChangedText>
                <WhiteText>
                  {toSignificantDigits(existingVoid, 6, true, 20)} VOID
                </WhiteText>
                <YellowText>➨</YellowText>
                <YellowText>
                  {toSignificantDigits(
                    Number(existingVoid) + total,
                    6,
                    true,
                    20
                  )}{" "}
                  VOID
                </YellowText>
              </ChangedText>
            ) : (
              <WhiteText>
                {toSignificantDigits(existingVoid, 6, true, 20)} VOID
              </WhiteText>
            )
          ) : total ? (
            <YellowText>
              {toSignificantDigits(total, 6, true, 20)} VOID
            </YellowText>
          ) : (
            "—"
          )}
        </OverviewText>
      </OverviewRow>
      {/* <OverviewRow>
        <OverviewText>DURATION</OverviewText>
        <OverviewText>
          {existingDuration ? (
            duration ? (
              <ChangedText>
                <WhiteText>{loadedDuration} weeks</WhiteText>
                <YellowText>➨</YellowText>
                <YellowText>
                  {totalDuration} weeks
                </YellowText>
              </ChangedText>
            ) : (
              <WhiteText>{loadedDuration} weeks</WhiteText>
            )
          ) : duration ? (
            <YellowText>{duration} weeks</YellowText>
          ) : (
            "—"
          )}
        </OverviewText>
      </OverviewRow> */}
      {unlock !== 0 && (
        <OverviewRow>
          <OverviewText>REMAINING DURATION</OverviewText>
          <OverviewText>
            {existingDuration ? (
              duration || total ? (
                <ChangedText>
                  <WhiteText>
                    {loadedDuration} {loadedDuration > 1 ? "weeks" : "week"}
                  </WhiteText>
                  <YellowText>➨</YellowText>
                  <YellowText>
                    {totalDuration + calculateWeeksLeft()} of {totalDuration}{" "}
                    {totalDuration > 1 ? "weeks" : "week"}
                  </YellowText>
                </ChangedText>
              ) : (
                <WhiteText>
                  {Number(loadedDuration) + calculateWeeksLeft()} of{" "}
                  {loadedDuration} {loadedDuration > 1 ? "weeks" : "week"}
                </WhiteText>
              )
            ) : duration ? (
              <YellowText>
                {duration} {duration > 1 ? "weeks" : "week"}
              </YellowText>
            ) : (
              "—"
            )}
          </OverviewText>
        </OverviewRow>
      )}

      <OverviewRow>
        <OverviewText>
          {/* {unlock === 0 ? "REMAINING DURATION" : "UNLOCK ON"} */}
          UNLOCK ON
        </OverviewText>
        <OverviewText>
          {unlock === 0 ? (
            <WarningRow>
              <WarningIcon />
              <YellowText>
                EXPIRED ({loadedDuration}{" "}
                {loadedDuration > 1 ? "weeks" : "week"})!
              </YellowText>
            </WarningRow>
          ) : existingDuration ? (
            unlock || total ? (
              <YellowText>
                {calculateUnlockDate(
                  Number(loadedDuration) + Number(unlock),
                  true
                )}
              </YellowText>
            ) : (
              <WhiteText>
                {calculateUnlockDate(Number(loadedDuration), false)}
              </WhiteText>
            )
          ) : unlock ? (
            <YellowText>
              {calculateUnlockDate(Number(unlock), false)}
            </YellowText>
          ) : (
            "—"
          )}
        </OverviewText>
      </OverviewRow>
      <OverviewRow>
        <OverviewText>
          <LightPurpleText>VOID ENERGY GENERATED</LightPurpleText>
        </OverviewText>
        <OverviewText>
          {duration > 0 || total > 0 ? (
            <ChangedText>
              <WhiteText>
                {toSignificantDigits(voidEnergy, 6, true, 20)} VE
              </WhiteText>
              <YellowText>➨</YellowText>
              <YellowText>
                {toSignificantDigits(voidEnergyGenerated, 6, true, 20)} VE
              </YellowText>
            </ChangedText>
          ) : (
            <LightPurpleText>
              {voidEnergyGenerated
                ? `${toSignificantDigits(voidEnergy, 6, true, 20)} VE`
                : `—`}
            </LightPurpleText>
          )}
        </OverviewText>
      </OverviewRow>
      {/* <OverviewEstimatedRow>
        <LightPurpleText>
          ESTIMATED REWARDS{" "}
          <IconWrapper
            ref={infoRef}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            <HelpIcon />
          </IconWrapper>
          {showHelpText && (
            <>
              <StakeOverviewRowHoverBox text="Rewards are estimated based on the current APY, TVL, and Average Lock Time. The APY is extrapolated from the emission rate and current TVL." />
            </>
          )}
        </LightPurpleText>
        <OverviewText>
          {duration > 0 || total > 0 ? (
            <YellowText>
              {toSignificantDigits(newEstimatedRewards, 3, true, 18)} VOID
            </YellowText>
          ) : (
            <LightPurpleText>
              {newEstimatedRewards
                ? `${toSignificantDigits(
                    newEstimatedRewards,
                    3,
                    true,
                    18
                  )} VOID`
                : `—`}
            </LightPurpleText>
          )}
        </OverviewText>
      </OverviewEstimatedRow> */}
    </Container>
  )
}

const IconWrapper = styled.div`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const PurpleText = styled.span`
  color: #a096b7;
`

const HelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  color: ${TangleColors.lighthover};
  cursor: pointer;
  width: 16px;
`

const WarningRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const WarningIcon = styled(AiFillWarning)`
  height: 20px;
  color: ${TangleColors.tangleYellow};
  width: 20px;
`

const OverviewText = styled(Caption)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ChangedText = styled.div`
  display: flex;
  gap: 4px;
`

const LightPurpleText = styled(Caption)`
  color: ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const YellowText = styled.div`
  color: ${TangleColors.tangleYellow};
`

const WhiteText = styled.div`
  color: ${TangleColors.white};
`

const Container = styled.div`
  background: ${TangleColors.swapBG};
  margin-top: 1em;
  border-radius: 16px;
  padding: 24px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid ${TangleColors.defi};
`

const OverviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`
const OverviewEstimatedRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0;
  position: relative;
`

export default StakeOverview
