import { TangleColors } from "styles/ColorStyles"

export interface FeaturesProps {
  id: number
  title?: any
  backgroundColor?: any
  backgroundImage?: any
  className?: any
  text?: any
  link?: any
  spanColumn?: any
  spanRow?: any
  maxWidth?: number
  minHeight?: number
  specialName?: any
}
export const tanglewapFeatures: FeaturesProps[] = [
  {
    id: 1,
    title: "Trade",
    maxWidth: 900,
    minHeight: 380,
    spanColumn: "span 2",
    spanRow: "span 3",
    backgroundColor: `${TangleColors.gridBG1}`,
    backgroundImage: "https://d3m3d54t409w7t.cloudfront.net/homepage/trade.png",
    className: "trade_cover",
    text: "Experience seamless token swapping, powered by Concentrated Liquidity and advanced multi-route algorithms.",
    link: "swap",
    specialName: "Time to swap",
  },
  {
    id: 2,
    title: "Concentrated Liquidity",
    maxWidth: 408,
    minHeight: 380,
    spanColumn: "span 1",
    spanRow: "span 3",
    backgroundColor: `${TangleColors.gridBG2}`,
    backgroundImage:
      "https://d3m3d54t409w7t.cloudfront.net/homepage/liquidity.png",
    className: "liquidity_cover",
    text: "Let other explorers do the trading and earn by providing liquidity for any token pair.",
    link: "pools",
    specialName: "Provide liquidity",
  },
  {
    id: 3,
    title: "Farm",
    maxWidth: 408,
    minHeight: 640,
    spanColumn: "span 1",
    spanRow: "span 4",
    backgroundColor: `${TangleColors.gridBG3}`,
    backgroundImage: "https://d3m3d54t409w7t.cloudfront.net/homepage/farm.png",
    className: "farm_cover",
    text: "Supercharge your rewards by locking your liquidity positions in our Asteroid Farms.",
    link: "farm",
    specialName: "Explore farms",
  },
  {
    id: 4,
    title: "Void Energy Staking",
    backgroundColor: `${TangleColors.gridBG4}`,
    backgroundImage: "https://d3m3d54t409w7t.cloudfront.net/homepage/stake.png",
    className: "staking_cover",
    text: "Stake your tokens to earn steady yield, while building a decentralized governance reputation via Void Energy.",
    link: "stake",
    maxWidth: 900,
    minHeight: 320,
    spanColumn: "span 2",
    spanRow: "span 2",
    specialName: "Stake & govern!",
  },
  {
    id: 5,
    title: "Invest",
    className: "invest_cover",
    maxWidth: 900,
    backgroundImage:
      "https://d3m3d54t409w7t.cloudfront.net/homepage/invest.png",
    backgroundColor: `${TangleColors.gridBG5}`,
    text: "Become an early investor and seek out the next moonshots by participating in our Investment Hub.",
    link: "invest",
    minHeight: 320,
    spanColumn: "span 2",
    spanRow: "span 2",
    specialName: "Welcome to the Hub",
  },
  /* {
    id: 6,
    title: "NFT Staking",
    backgroundColor: `${TangleColors.gridBG6}`,
    className: "nft_staking_cover",
    backgroundImage:
      "https://d3m3d54t409w7t.cloudfront.net/homepage/nft-staking.png",
    link: "https://nft",
    maxWidth: 900,
    minHeight: 400,
    spanColumn: "span 1",
    spanRow: "span 2",
    text: "Space is freezing cold. Staking your NFTs is even cooler though.",
    specialName: "Simple and easy",
  }, */
]
