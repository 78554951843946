import { useLocation } from "react-router-dom"
import React, { FC } from "react"
import styled from "styled-components"
import Subtract from "assets/Subtract.png"
interface BobyProps {
  children?: any
  dappsClassName?: any
}
const BubbleBody: FC<BobyProps> = (props) => {
  const { children } = props

  const location = useLocation()
  const homeTrue = location.pathname === "/"
  const swapDappsClassName = location.pathname === "/swap"
  const lPAddDappsClassName = location.pathname === "/pools/add"
  const swapClass = !homeTrue && swapDappsClassName && "swapClass"
  const addLPClass = !homeTrue && lPAddDappsClassName && "addLPClass"
  return (
    <TailCover>
      <TailBody className={swapClass || addLPClass || "otherDapps"}>
        {children}
      </TailBody>
    </TailCover>
  )
}

const TailCover = styled.div`
  .otherDapps {
    padding: 12px 24px;
    border-radius: 30px;
  }
  .swapClass {
    padding: 18px;
    border-radius: 24px;
  }
  .addLPClass {
    padding: 18px;
    border-radius: 24px;
  }
`
const TailBody = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url(${Subtract}) no-repeat center center/cover;
`

export default React.memo(BubbleBody)
