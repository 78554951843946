import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { RiArrowRightUpLine } from "react-icons/ri"
import { Header4, Subtitle } from "styles/TextStyles"
import LimitRow from "./LimitRow"
import { SwapConnectButton } from "styles/ButtonStyles"
import { LimitOrderData } from "data/LimitOrderData"
import { Link } from "react-router-dom"
import useParsedQueryString from "utils/useParsedQueryString"

const LimitInformation = () => {
  const parsedQs = useParsedQueryString()

  const firstToken = parsedQs.input
  const secondToken = parsedQs.output
  return (
    <LimitBody>
      <LimitHeader>
        <HeaderTitle>Want to try out range orders? </HeaderTitle>
        <HeaderText>{"Let's"} get started!</HeaderText>
      </LimitHeader>
      <LimitRowCover>
        {LimitOrderData.map((limit) => (
          <LimitRow key={limit.id} number={limit.id} text={limit.text} />
        ))}
      </LimitRowCover>
      <LimitBottomLink>
        <LimitHref
          href="https://docs.tangleswap.exchange/dapps/swap#range-orders-next-gen-limit-orders"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to learn more
          <LimitExternalIcon />
        </LimitHref>
      </LimitBottomLink>
      <LimitButtonRow>
        {secondToken === undefined ? (
          <>
            {" "}
            <LimitButtonLink to="/pools/add">
              <LimitButton>Get Started</LimitButton>
            </LimitButtonLink>
          </>
        ) : (
          <>
            <LimitButtonLink
              to={`/pools/add?firstToken=${firstToken}&secondToken=${secondToken}`}
            >
              <LimitButton>Get Started</LimitButton>
            </LimitButtonLink>
          </>
        )}
      </LimitButtonRow>
    </LimitBody>
  )
}

const LimitBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 580px;
  margin: 24px auto;
  min-height: 600px;
  background: ${TangleColors.modalBG};
  border-radius: 32px;
  padding: 24px;
`
const LimitHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  max-width: 380px;
  margin: 8px auto;
`
const HeaderTitle = styled(Header4)`
  text-align: center;
  color: ${TangleColors.white};
  margin: 0 0 4px 0;
`

const HeaderText = styled(Subtitle)`
  text-align: center;
  margin: 0;
  color: ${TangleColors.lighthover};
`

const LimitBottomLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 56px;
  padding: 8px;
  border: 1px solid ${TangleColors.lighthover};
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  :hover {
    border: 1px solid ${TangleColors.white};
  }
`
const LimitButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 40px 0 0 0;
`

const LimitButtonLink = styled(Link)`
  width: 100%;
`

const LimitButton = styled(SwapConnectButton)`
  width: 100%;
`

const LimitRowCover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px 0;
`

const LimitExternalIcon = styled(RiArrowRightUpLine)`
  height: 24px;
  width: 24px;
  cursor: pointer;

  color: ${TangleColors.lighthover};

  margin: 0 0 0 4px;
  transition: 0.4s ease-in;
  ${LimitBottomLink}:hover & {
    color: ${TangleColors.white};
  }
`

const LimitHref = styled.a`
  margin: 0 0 0 8px;
  color: ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${LimitBottomLink}:hover & {
    color: ${TangleColors.white};
  }
`

export default LimitInformation
