import React, { useRef } from "react"
import { GrClose } from "react-icons/gr"

import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import OverviewPending from "./sections/OverviewPending"
import { useAppDispatch } from "store/hooks"
import { showWSCInterface } from "store/actions/WscProgressAction"
import { motion } from "framer-motion"
import { popAnimation } from "utils/framer"

const WalletOverview = () => {
  const bodyRef = useRef<HTMLDivElement | any>()
  const dispatch = useAppDispatch()
  const closeWalletOverview = () => {
    dispatch(showWSCInterface(false))
  }

  const closeOutsideOverview = (event?: any) => {
    event?.preventDefault()
    if (bodyRef.current === event?.target) {
      dispatch(showWSCInterface(false))
    }
  }

  return (
    <>
      <ModalCover ref={bodyRef} onClick={closeOutsideOverview}>
        <Body
          variants={popAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <ModalClose onClick={closeWalletOverview} />
          <OverviewPending />
        </Body>
      </ModalCover>
    </>
  )
}

const ModalCover = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 900;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  padding: 12px 16px;
`

const Body = styled(motion.div)`
  width: 100%;
  max-width: 850px;
  background: ${TangleColors.modalBG};
  border-radius: 16px;
  z-index: 900;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* align-items: center; */
  min-height: 550px;
  max-height: 780px;
  border: 1px solid ${TangleColors.lighthover};
  /* overflow: scroll; */
  overflow: auto;
  max-height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

const ModalClose = styled(GrClose)`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

export default WalletOverview
