import React, { useState } from "react"
import styled from "styled-components"
import { GridLinkButton } from "styles/ButtonStyles"
import HomeGridCards from "./components/HomeGridCards"
import { tanglewapFeatures } from "data/HomeFeaturesData"

const HomeDappsGrid = () => {
  const [pressedIcon, setPressedIcon] = useState<any>(0)
  const togglePressInfo = (number: number) => {
    if (number === pressedIcon) {
      return setPressedIcon(null)
    }
    setPressedIcon(number)
  }
  return (
    <Body>
      <GridCover>
        <GridTopCover>
          <GridCardTop>
            {tanglewapFeatures.map((data, index: number) => (
              <HomeGridCards
                key={index}
                title={data.title}
                maxWidth={data.maxWidth}
                minHeight={data.minHeight}
                link={data.link}
                number={data.id}
                backgroundImage={data.backgroundImage}
                togglePressInfo={() => togglePressInfo(data.id)}
                pressedIcon={Number(pressedIcon) === Number(data.id)}
                backgroundColor={data.backgroundColor}
                paragraph={data.text}
                specialName={data.specialName}
              />
            ))}
          </GridCardTop>
        </GridTopCover>
        <GridButtonCover>
          <MainGridButton href="https://docs.tangleswap.exchange">
            Visit the documentation
          </MainGridButton>
        </GridButtonCover>
      </GridCover>
    </Body>
  )
}
const Body = styled.div`
  width: 100%;
  min-height: 900px;

  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
`
const GridCover = styled.div`
  width: 100%;
  margin: 80px auto 32px auto;
  max-width: 1232px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
`

const GridButtonCover = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px auto;
`

const MainGridButton = styled(GridLinkButton)``
const GridTopCover = styled.div`
  width: 100%;
  margin: 32px auto;
  max-width: 1232px;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
`
const GridCardTop = styled.div`
  /* display: flex;
  flex-direction: row; */
  width: 100%;
  max-width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-rows: minmax(280px, auto);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-auto-flow: dense;
  @media only screen and (max-width: 850px) {
    padding: 24px 16px;
  }
`
export default HomeDappsGrid
