import { useWSCContext } from "context/MilkomedaContext"
import { PendingStateContext } from "context/PendingContext"
import { FC, useContext, useRef } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { shortenAddress } from "utils/index"
import { HeaderNavbar } from "styles/TextStyles"
import AddressLoadingSpinner from "components/utils/AddressLoadingSpinner"

import MobileAddressDropIcon from "./MobileAddressDropIcon"
import MobileAccountDropdown from "./MobileAccountDropdown"
import { useAppDispatch } from "store/hooks"
import { openAddressMenu } from "store/actions/MobileNavAction"
import Jazzicon from "navbar/JazzIcon"
interface NavProps {
  openDropDown?: () => void
  pendingTransaction?: boolean
  isAddressOpen?: boolean
  activeMenu?: any

  goBackToMainMenu?: () => void
}
const MobileAddressCover: FC<NavProps> = (props) => {
  const {
    isAddressOpen,

    activeMenu,

    goBackToMainMenu,
  } = props

  const { pendingTransaction } = useContext(PendingStateContext)
  const jazzLogoRef = useRef<any>(null)
  const { account } = useWSCContext()
  const dispatch = useAppDispatch()

  const openMainAddressMenu = () => {
    dispatch(openAddressMenu())
  }
  return (
    <CoverAddress>
      <AddressCoverWrap
        onClick={openMainAddressMenu}
        pendingTransaction={Boolean(pendingTransaction)}
      >
        {account !== undefined ? (
          <>
            <AddressWrap>
              <AddressText pendingTransaction={Boolean(pendingTransaction)}>
                {pendingTransaction ? (
                  <>Pending</>
                ) : (
                  <>
                    {" "}
                    <Jazzicon address={account} />
                    {shortenAddress(account)}
                  </>
                )}
              </AddressText>
              <NetworkWrapper>
                <MobileAddressDropIcon
                  pendingTransaction={pendingTransaction}
                  addressOpen={isAddressOpen}
                />
              </NetworkWrapper>
            </AddressWrap>
          </>
        ) : (
          <SpinnerBody>
            <AddressLoadingSpinner />
          </SpinnerBody>
        )}
      </AddressCoverWrap>
      <MobileAccountDropdown
        addressOpen={isAddressOpen}
        activeMenu={activeMenu}
        goBackToMainMenu={goBackToMainMenu}
      />
    </CoverAddress>
  )
}

const CoverAddress = styled.div`
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SpinnerBody = styled.div`
  width: 32px;
  height: 32px;

  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
const AddressWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 11px 16px 8px 16px;
  cursor: pointer;
`

const AddressCoverWrap = styled.div<NavProps>`
  height: 48px;
  width: 90px;
  padding: 0;
  border-radius: 24px;
  display: flex;
  padding: 8px 2px;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  border: 1.5px solid transparent;
  background: ${(props) =>
    props.pendingTransaction
      ? `${TangleColors.lighthover}`
      : `${TangleColors.grayLighter}`};

  color: ${TangleColors.icon};
  margin: 0 8px;
`

const AddressText = styled(HeaderNavbar)<NavProps>`
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const NetworkWrapper = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export default MobileAddressCover
