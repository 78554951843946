import { TANGLESWAP_USER_REFERRAL } from "./actionsTypes"

export const updateTangleswapUserReferral = (tangleswapUserReferral) => {
  return {
    type: TANGLESWAP_USER_REFERRAL,
    tangleswapUserReferral: tangleswapUserReferral,
  }
}

export const updateUserReferralInfo = (tangleswapUserReferral) => {
  return (dispatch) => {
    localStorage.setItem("tangleswapUserReferral", tangleswapUserReferral)
    dispatch(updateTangleswapUserReferral(tangleswapUserReferral))
  }
}

export const checkUserReferralInfo = () => {
  return (dispatch: any) => {
    const referral = localStorage.getItem("tangleswapUserReferral")
    dispatch(updateTangleswapUserReferral(referral))
  }
}
