import { FETCH_UNKNOWN_TOKEN_INFO } from "store/actions/actionsTypes"
import { updateObject } from "../utility"

export interface UnknownTokenInfoProps {
  unknownTokenName: any
  unknownTokenSymbol: any
  unknownTokenAddress: any
  unknownTokenDecimal: any
  unknownTokenl1Address: any
  unknownToken1Decimals: any
  unknownTokenl1EncodedName: any
  tokenField: any
}

const initialState: UnknownTokenInfoProps = {
  unknownTokenName: null,
  unknownTokenAddress: null,
  unknownTokenSymbol: null,
  unknownTokenDecimal: null,
  unknownTokenl1Address: null,
  unknownToken1Decimals: null,
  unknownTokenl1EncodedName: null,
  tokenField: null,
}

const unknownTokenInfoSuccess = (state, action) => {
  return updateObject(state, {
    unknownTokenName: action.unknownTokenName,
    unknownTokenAddress: action.unknownTokenAddress,
    unknownTokenSymbol: action.unknownTokenSymbol,
    unknownTokenDecimal: action.unknownTokenDecimal,
    unknownTokenl1Address: action.unknownTokenl1Address,
    unknownToken1Decimals: action.unknownToken1Decimals,
    unknownTokenl1EncodedName: action.unknownTokenl1EncodedName,
    tokenField: action.tokenField,
  })
}

const unKnownTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNKNOWN_TOKEN_INFO:
      return unknownTokenInfoSuccess(state, action)
    default:
      return state
  }
}

export default unKnownTokenReducer
