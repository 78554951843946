import {} from "@tangleswap/sdk"
import { useEffect, useState } from "react"
import { RxDotFilled } from "react-icons/rx"
import { useLocation } from "react-router-dom"
import { blockNumberUpdateFunction } from "store/actions/BlockAction"
import { useAppDispatch } from "store/hooks"
import styled from "styled-components"
import { CaptionTiny } from "styles/TextStyles"

import { toSignificantDigits } from "utils/toSignificant"
import BlockNumberBox from "./BlockNumberBox"
import BlockSpinner from "./BlockSpinner"

import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"
interface BlockProps {
  blockNumberColor?: any
  visible?: any
}
const BlockBottom = () => {
  const [blockNumber, setBlockNumber] = useState<number>(0)
  const [showBlock, setShowBlock] = useState<boolean>(false)
  const [blockColor, setBlockColor] = useState<string>("green")
  const [visible, setVisible] = useState<boolean>(false)
  const location = useLocation()

  const { chainId } = useWSCContext()
  const { tangleship } = useTangleship()
  const dispatch = useAppDispatch()
  const dappsClassName =
    location.pathname.startsWith("/swap") ||
    location.pathname === "/pools/" ||
    location.pathname.startsWith("/pools/add") ||
    location.pathname.startsWith("/pools/increase") ||
    location.pathname.startsWith("/pools/remove") ||
    location.pathname.startsWith("/nft-staking") ||
    location.pathname.startsWith("/stake") ||
    location.pathname.startsWith("/farm") ||
    location.pathname.startsWith("/invest")

  useEffect(() => {
    if (!tangleship || !chainId) return
    const updateBlock = (newBlockNumber: number) => {
      setBlockNumber(newBlockNumber)
      dispatch(blockNumberUpdateFunction(newBlockNumber))

      setVisible(true) // Trigger visibility for the animation
      setTimeout(() => setVisible(false), 1500) // Stop the animation shortly after

      const newBlockColor = newBlockNumber
        ? "green"
        : blockColor === "green"
        ? "yellow"
        : "red"

      setBlockColor(newBlockColor)
    }

    tangleship?.subscribeBlockNumber(updateBlock)
    // return () => unsubscribe() // Unsubscribe if the component unmounts
  }, [chainId, tangleship])

  const onMouseLeave = () => {
    setShowBlock(!showBlock)
  }
  const onMouseEnter = () => {
    setShowBlock(!showBlock)
  }
  return (
    <>
      {dappsClassName ? (
        <Body>
          {/* {Tangles()} */}
          <Cover
            blockNumberColor={blockColor}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
          >
            {showBlock ? (
              <>
                {" "}
                <BlockNumberBox />
              </>
            ) : null}

            <BlockCover>
              <SpinnerWrapper blockNumberColor={blockColor}>
                <BlockSpinner color={blockColor} visible={visible} />
              </SpinnerWrapper>
              <BlockDot blockNumberColor={blockColor} />
            </BlockCover>
            <BlockNumber visible={visible} blockNumberColor={blockColor}>
              {blockNumber === 0
                ? ""
                : toSignificantDigits(blockNumber, 0, false, 1)}
            </BlockNumber>
          </Cover>
        </Body>
      ) : null}
    </>
  )
}

const Body = styled.div`
  background: transparent;
  margin: 0;
  width: 100%;
  display: flex;
  position: absolute;

  top: -12%;
  /* right: 20%; */
  right: 176.5px;
  margin: 8px 0 0 0;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1050px) {
    top: 10%;
    left: 70px;
  }
`

const Cover = styled.div<BlockProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  svg {
    fill: ${(props) => props.blockNumberColor};
  }
`

const BlockCover = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 8px 0 0;
`

const SpinnerWrapper = styled.div<BlockProps>`
  width: 14px;
  height: 14px;
  border-radius: 9999px;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const BlockDot = styled(RxDotFilled)<BlockProps>`
  width: 8px;
  height: 8px;
  border-radius: 9999px;
  background: ${(props) => props.blockNumberColor};

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const BlockNumber = styled(CaptionTiny)<BlockProps>`
  color: ${(props) => props.blockNumberColor};
  line-height: 1 !important;
  opacity: ${(props) => (props.visible ? "1" : "0.4")};
`

export default BlockBottom
