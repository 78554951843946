import { REQUIRED_FEE_ADA } from "constants/milkomeda/transaction"
import { useWSCContext } from "context/MilkomedaContext"
import { useEffect, useRef, useState } from "react"

export const useCheckBalanceForGas = () => {
  const [needGasWrap, setNeedGasWrap] = useState<boolean>(false)
  const { destinationBalanceADA } = useWSCContext()
  const gasChecked = useRef<boolean>(false)

  useEffect(() => {
    if (!destinationBalanceADA.data || gasChecked.current) return
    checkBalanceForGas()
    gasChecked.current = true
  }, [destinationBalanceADA.data])

  const checkBalanceForGas = () => {
    if (REQUIRED_FEE_ADA > destinationBalanceADA.data) setNeedGasWrap(true)
  }

  return { needGasWrap }
}
