import JSBI from "jsbi"
import { ONE_DAY } from "utils/calls/investmentHub"
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000"

// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20
// export const DEFAULT_DEADLINE_FROM_NOW = 180
export const L2_DEADLINE_FROM_NOW = 60 * 5

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 25000
export const L2_TXN_DISMISS_MS = 5000
export const THREE_DAYS_IN_SECONDS = 259200000 / 1000

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(ONE_DAY * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis JSBI.BigInt
const BIPS_BASE = JSBI.BigInt(10000)

// used for warning states
