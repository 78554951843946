import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { motion } from "framer-motion"
import { useWSCContext } from "context/MilkomedaContext"
import FarmItemsTop from "./FarmItemsTop"
import FarmItemsBottom from "./FarmItemsBottom"
import {
  isInvalid,
  sqrtPriceToPrice,
  tickToPrice,
  wrapAddr,
} from "@tangleswap/sdk"

import { dateFromUnixTimestamp } from "components/farm/hooks/dateFromUnixTimestamp"
import { useQuery } from "react-query"
import LiquidityPositionsLoadingPlaceholder from "components/Liquidity/component/LiquidityPositionsLoadingPlaceholder"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { useTangleship } from "utils/useTangleship"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"

interface FarmProps {
  endBlock?: number
  feeTier?: number
  rewardLowerTick?: number
  rewardUpperTick?: number
  startBlock?: number
  token0Address?: string
  token1Address?: string
  rewardsAddress0?: string | undefined | null
  rewardsAddress1?: string | undefined | null
  token0PendingRewards?: number | undefined | null
  token1PendingRewards?: number | undefined | null
  farmAddress?: string
  isLPstakedInFarm?: any
  baseAPR?: number
  calculatedAPR?: number
  calculatedTVL?: number
  allUserPools?: any
  allPoolsLoading?: boolean
  hardcoded?: boolean
  featured?: boolean
}
interface FarmCardsProps {
  detailsShown?: boolean
  isEnded?: boolean
}

const FarmItemCard: FC<FarmProps & FarmCardsProps> = (props) => {
  const {
    endBlock,
    feeTier,
    farmAddress,
    rewardLowerTick,
    rewardUpperTick,
    hardcoded,
    token0Address,
    token1Address,
    token0PendingRewards,
    token1PendingRewards,
    rewardsAddress0,
    rewardsAddress1,
    startBlock,
    baseAPR,
    calculatedAPR,
    calculatedTVL,
    allUserPools,
    allPoolsLoading,
    featured,
  } = props

  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()
  const { tangleship } = useTangleship()
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [isEnded, setIsEnded] = useState(false)
  const [isFarmApproved, setIsFarmApproved] = useState(false)
  const [sqrtPriceX96, setSqrtPriceX96] = useState<number>(undefined)

  const [formattedPrice, setFormattedPrice] = useState<any>(undefined)
  const blockNumber = useAppSelector(
    (state) => state.blockNumberReducer.blockNumber
  )
  const toggleDetails = () => {
    setShowDetails(!showDetails)
  }

  const { data: currency0Info, isLoading: farmCurrency0Loading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["farmtoken0Address", token0Address, l1ChainId],
    () => fetchTangleCurrency(token0Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token0Address && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: currency1Info, isLoading: farmCurrency1Loading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["farmtoken1Address", token1Address, l1ChainId],
    () => fetchTangleCurrency(token1Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token1Address && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: reward0Info, isLoading: farmRewardCurrency0Loading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["farmRewardsAddress0", rewardsAddress0, l1ChainId],
    () => fetchTangleCurrency(rewardsAddress0, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!rewardsAddress0 && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: reward1Info, isLoading: farmRewardCurrency1Loading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["farmRewardsAddress1", rewardsAddress1, l1ChainId],
    () => fetchTangleCurrency(rewardsAddress1, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!rewardsAddress1 && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const fetchApproval = useCallback(
    async (refetch = false) => {
      if (!account || farmAddress === undefined || !chainId) return
      if (isWSCConnected) {
        setIsFarmApproved(true)
        return
      }

      const storageKey = `farmApproved${chainId}${account}${farmAddress}`
      const storageValue = sessionStorage.getItem(storageKey)

      if (storageValue === null)
        sessionStorage.setItem(storageKey, "loading") // to avoid refetching
      else if (!refetch) {
        const booleanValue = storageValue === "true"
        if (storageValue !== "loading")
          setIsFarmApproved(isWSCConnected ? true : Boolean(booleanValue))
        return
      }

      tangleship
        .getIsApprovedForAll(farmAddress, account, "LP")
        .then((res: any) => {
          sessionStorage.setItem(storageKey, res.toString())
          setIsFarmApproved(res)
        })
    },
    [account, farmAddress, chainId, tangleship, isWSCConnected]
  )

  useEffect(() => {
    fetchApproval()
  }, [fetchApproval])

  const isEndedValue = useMemo(() => {
    return (
      dateFromUnixTimestamp(Number(endBlock)) <= 0 ||
      new Date(endBlock * 1000) < new Date()
    )
  }, [endBlock, blockNumber])

  useEffect(() => {
    setIsEnded(Boolean(isEndedValue))
  }, [isEndedValue])

  const dispatch = useAppDispatch()

  // Define setter function
  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  // Set the reducer values
  useEffect(() => {
    if (
      isInvalid([
        currency0Info?.address,
        currency1Info?.address,
        currency0Info?.decimals,
        currency1Info?.decimals,
      ])
    )
      return

    const params = {
      token0: currency0Info?.address,
      token1: currency1Info?.address,
      decimals0: currency0Info?.decimals,
      decimals1: currency1Info?.decimals,
      feeTier: feeTier,
    }
    checkSlot0Price(params)
  }, [
    checkSlot0Price,
    currency0Info?.address,
    currency0Info?.decimals,
    currency1Info?.address,
    currency1Info?.decimals,
    feeTier,
  ])

  // Get the reducer values
  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  // Set the specific values
  useEffect(() => {
    if (!slot0PricesList) return

    const address0 = currency0Info?.address
    const address1 = currency1Info?.address
    const addr0 = wrapAddr(address0, chainId)?.tokenAddress?.toLowerCase()
    const addr1 = wrapAddr(address1, chainId)?.tokenAddress?.toLowerCase()
    const isSameOrder = addr0 < addr1
    const token0 = isSameOrder ? addr0 : addr1
    const token1 = isSameOrder ? addr1 : addr0

    const poolKey = `${token0}_${token1}_${feeTier}`
    const listItem = slot0PricesList?.[poolKey]
    if (!listItem) return

    setSqrtPriceX96(Number(listItem?.slot0.sqrtPriceX96?._hex))
    setFormattedPrice(listItem?.price)
  }, [currency0Info?.address, currency1Info?.address, feeTier, slot0PricesList])

  return (
    <Wrapper>
      <FarmCoverRow featured={featured}>
        {farmCurrency0Loading ||
        farmCurrency1Loading ||
        farmRewardCurrency0Loading ||
        farmRewardCurrency1Loading ? (
          <>
            {" "}
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
          </>
        ) : (
          <>
            {" "}
            <FarmItemsTop
              baseAPR={baseAPR}
              calculatedAPR={calculatedAPR}
              calculatedTVL={calculatedTVL}
              toggleDetails={toggleDetails}
              showDetails={showDetails}
              token0Address={token0Address}
              token1Address={token1Address}
              token0Logo={currency0Info?.logoURI}
              token1Logo={currency1Info?.logoURI}
              token0Symbol={currency0Info?.symbol}
              token1Symbol={currency1Info?.symbol}
              isEnded={isEnded}
              feeTier={feeTier}
              rewardsAddress0={rewardsAddress0}
              rewardsAddress1={rewardsAddress1}
              rewardLowerTick={rewardLowerTick}
              rewardUpperTick={rewardUpperTick}
              token0decimals={currency0Info?.decimals}
              token1decimals={currency1Info?.decimals}
              token0PendingRewards={token0PendingRewards}
              token1PendingRewards={token1PendingRewards}
              rewardToken0Symbol={reward0Info?.symbol}
              rewardToken1Symbol={reward1Info?.symbol}
              rewardToken0Logo={reward0Info?.logoURI}
              rewardToken1Logo={reward1Info?.logoURI}
              rewardToken0decimals={reward0Info?.decimals}
              rewardToken1decimals={reward1Info?.decimals}
              farmAddress={farmAddress}
              featured={featured}
            />
            <FarmItemsBottom
              showDetails={Boolean(showDetails)}
              isEnded={isEnded}
              isFarmApproved={{ fn: fetchApproval, val: isFarmApproved }}
              formattedPrice={formattedPrice}
              endBlock={endBlock}
              feeTier={feeTier}
              // isLPstakedInFarm={isLPstakedInFarm}
              token0DataAddress={currency0Info?.address}
              token1DataAddress={currency1Info?.address}
              token0DataSymbol={currency0Info?.symbol}
              token1DataSymbol={currency1Info?.symbol}
              rewardsAddress0={rewardsAddress0}
              rewardsAddress1={rewardsAddress1}
              minPrice={tickToPrice(
                rewardLowerTick,
                currency0Info?.decimals,
                currency1Info?.decimals
              )}
              maxPrice={tickToPrice(
                rewardUpperTick,
                currency0Info?.decimals,
                currency1Info?.decimals
              )}
              currentCycle={endBlock}
              farmAddress={farmAddress}
              sqrtPriceX96={sqrtPriceX96}
              allUserPools={allUserPools}
              allPoolsLoading={allPoolsLoading}
              startBlock={startBlock}
            />{" "}
          </>
        )}
      </FarmCoverRow>
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  width: 100%;
  position: relative;
`
const FarmCoverRow = styled.div<{ featured?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 4px 16px;
  border-radius: 16px;
  transition: border-radius 0.15s ease;
  max-width: 1232px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: ${TangleColors.swapBG};
  border: ${(props) =>
    props.featured
      ? `1px solid ${TangleColors.tangleYellow}`
      : `1px solid ${TangleColors.lighthover}`};

  border-radius: 24px;
  position: relative;

  margin: 6px 0;
  color: ${TangleColors.white};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;

  :hover {
    background: ${TangleColors.blackDark};
  }
`
export default FarmItemCard
