import { Axis as d3Axis, NumberValue, select } from "d3"

const AxisMain = ({
  axisGenerator,
}: {
  axisGenerator: d3Axis<NumberValue>
}) => {
  const axisRef = (axis: SVGGElement) => {
    axis &&
      select(axis)
        .call(axisGenerator)
        .call((g) => g.select(".domain").remove())
  }

  return <g ref={axisRef} />
}

export default AxisMain
