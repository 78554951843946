import TangleToggle from "components/utils/Toggle"
import React, { FC } from "react"
import styled from "styled-components"

interface CollectNativeProps {
  wrappedSymbol?: any
  isToggled?: any
  toggleOnButton?: any
  toggleOffButton?: any
}
const CollectAsWrappedNative: FC<CollectNativeProps> = (props) => {
  const { wrappedSymbol, isToggled, toggleOnButton, toggleOffButton } = props
  return (
    <BoxCollect>
      <BoxEnd>
        <EndLeft>
          <EndText>Collect as {wrappedSymbol}</EndText>
        </EndLeft>
        <EndRight>
          <TangleToggle
            isToggled={isToggled}
            toggleOnButton={toggleOnButton}
            toggleOffButton={toggleOffButton}
          />
        </EndRight>
      </BoxEnd>
    </BoxCollect>
  )
}
const BoxCollect = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  max-width: 400px;
  margin: 0 0 0 auto;
`
const BoxEnd = styled.div`
  width: 100%;
  max-width: 452px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 500px) {
    max-width: 452px;
  }
`
const EndLeft = styled.div``
const EndText = styled.div``
const EndRight = styled.div`
  margin: 0 0 0 16px;
`

export default CollectAsWrappedNative
