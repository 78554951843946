import RemoveLiquidityContainer from "components/Liquidity/RemoveLiquidity/RemoveLiquidityContainer"
import React from "react"
import styled from "styled-components"
import { useEffect } from "react"

const RemoveLiquidity = () => {
  useEffect(() => {
    document.title = "Remove Liquidity"
  }, [])
  return (
    <Body>
      <RemoveLiquidityContainer />
    </Body>
  )
}
const Body = styled.div`
  padding: 0 0 32px 0;
  min-height: 90vh;
`

export default RemoveLiquidity
