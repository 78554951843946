import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { CiGrid41, CiGrid2H } from "react-icons/ci"
interface LayoutProps {
  tokensLength?: number
  onClick?: (e?: any) => void
  number?: number
  active?: boolean
}
const WalletLayout: FC<LayoutProps> = (props) => {
  const { tokensLength, onClick, number } = props
  return (
    <SectionLayout>
      <SectionTitle>
        {tokensLength} {tokensLength > 1 ? "Tokens" : "Token"}
      </SectionTitle>
      <SectionCover>
        <LayoutRow active={number === 1} onClick={() => onClick?.(1)}>
          <GridButtonIcon active={number === 1} />
        </LayoutRow>{" "}
        <LayoutRow active={number === 2} onClick={() => onClick?.(2)}>
          <ListButtonIcon active={number === 2} />
        </LayoutRow>
      </SectionCover>
    </SectionLayout>
  )
}

const SectionLayout = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const SectionTitle = styled(Caption)`
  color: ${TangleColors.lighthover};
  text-align: left;
`
const SectionCover = styled.div`
  cursor: pointer;
  max-width: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-radius: 12px;
  background: ${TangleColors.heroCTA};
  border: 0.4px ${TangleColors.lighthover};
`
const LayoutRow = styled.div<LayoutProps>`
  background: ${(props) =>
    props.active ? `${TangleColors.lighthover}` : `${TangleColors.heroCTA}`};
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`
const GridButtonIcon = styled(CiGrid41)<LayoutProps>`
  color: ${(props) =>
    props.active ? `${TangleColors.black}` : `${TangleColors.white}`};
  width: 20px;
  height: 20px;
`
const ListButtonIcon = styled(CiGrid2H)<LayoutProps>`
  width: 20px;
  height: 20px;
  color: ${(props) =>
    props.active ? `${TangleColors.black}` : `${TangleColors.white}`};
`

export default WalletLayout
