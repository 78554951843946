import { motion } from "framer-motion"
import { FaExternalLinkAlt } from "react-icons/fa"
import { GrClose } from "react-icons/gr"
import styled from "styled-components"
import { SwapTokenButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall, CaptionSmallPara, Subtitle } from "styles/TextStyles"

export const Body = styled.div`
  position: fixed;
  z-index: 800;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.5);
`

export const WrapContainer = styled(motion.div)`
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 40px;
  padding: 20px;
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${TangleColors.lighthover};
  position: relative;
`

export const ModalTopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const TopText = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
  transition: 0.4s ease;
`

export const CloseIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
`
export const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

export const TitleTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TitleText = styled.div`
  font-size: 32px;
  font-weight: 500;
  color: ${TangleColors.white};
  margin: 16px 0;
`

export const CurrentStepWrapper = styled.div`
  width: 100%;
`

export const StepWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 16px 0;
`

export const StepButton = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 9999px;
  width: 48px;
  height: 48px;
  background: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.tangleBlack};
  color: ${TangleColors.white};
  cursor: pointer;
`

export const StepDivider = styled.div`
  width: 50%;
  height: 1px;
  background: ${TangleColors.lighthover};
  margin: 16px 8px;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const StepTitle = styled.div<{ active?: boolean }>`
  text-align: center;
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.white};
`

export const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ActionText = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  text-align: center;
`
export const ActionSmallText = styled(CaptionSmallPara)`
  color: ${TangleColors.lighthover};
  text-align: center;
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`

export const ActionButton = styled(SwapTokenButton)``

export const Link = styled.a`
  display: flex;
  color: ${TangleColors.lighthover} !important;
  margin-bottom: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const ExternalLinkIcon = styled(FaExternalLinkAlt)`
  width: 12px;
  height: 12px;
  cursor: pointer;
`
