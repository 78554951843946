import styled from "styled-components"
import { useEffect } from "react"
import AuctionDetailsPage from "components/LaunchPad/AuctionDetailsPage"

const InvHubControlRoom = () => {
  useEffect(() => {
    document.title = "Inv. Hub Auction | TangleSwap"
  }, [])

  return (
    <Body>
      <AuctionDetailsPage />
    </Body>
  )
}
const Body = styled.div``

export default InvHubControlRoom
