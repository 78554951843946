import React, { useEffect, useMemo, useState } from "react"
import { TangleColors } from "styles/ColorStyles"
import styled from "styled-components"
import { AiOutlineSwap } from "react-icons/ai"
import { FarmAddLiquidityButton } from "styles/ButtonStyles"
import { Caption } from "styles/TextStyles"

import { FiExternalLink } from "react-icons/fi"
import { dateFromUnixTimestamp } from "components/farm/hooks/dateFromUnixTimestamp"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import useExplorerInfo from "utils/useExplorerInfo"
import { Link } from "react-router-dom"
interface LiquidityActionProps {
  token0DataAddress?: string
  token0DataSymbol?: string
  token1DataSymbol?: string
  token1DataAddress?: string
  formattedPrice?: any
  feeTier?: number
  farmAddress?: string
  endBlock?: number
  minPrice?: any
  maxPrice?: any
  hardcoded?: boolean
}

const LiquidityActionRow = (props: LiquidityActionProps) => {
  const {
    token0DataAddress,
    token0DataSymbol,
    token1DataSymbol,
    token1DataAddress,
    formattedPrice,
    feeTier,
    farmAddress,
    endBlock,
    minPrice,
    maxPrice,
    hardcoded,
  } = props

  const [swapCurrency, setSwapCurrency] = useState<boolean>(false)
  const [token0Symbol, setToken0Symbol] = useState<any>(token0DataSymbol)
  const [token1Symbol, setToken1Symbol] = useState<any>(token1DataSymbol)
  const { explorerURL } = useExplorerInfo()

  const swapTokens = () => {
    setSwapCurrency(!swapCurrency)
  }

  // useEffect(() => {
  //   const token0 = swapCurrency ? token1DataSymbol : token0DataSymbol
  //   setToken0Symbol(token0)
  // }, [swapCurrency, token0DataSymbol, token1DataSymbol])

  // useEffect(() => {
  //   const token1 = swapCurrency ? token0DataSymbol : token1DataSymbol
  //   setToken1Symbol(token1)
  // }, [swapCurrency, token0DataSymbol, token1DataSymbol])
  const token0 = useMemo(() => {
    return swapCurrency ? token1DataSymbol : token0DataSymbol
  }, [swapCurrency, token0DataSymbol, token1DataSymbol])

  const token1 = useMemo(() => {
    return swapCurrency ? token0DataSymbol : token1DataSymbol
  }, [swapCurrency, token0DataSymbol, token1DataSymbol])

  useEffect(() => {
    setToken0Symbol(token0)
    setToken1Symbol(token1)
  }, [token0, token1])

  return (
    <ActionWrapper>
      <ActionTop>
        <TopRow>
          {" "}
          <ActionTextTitle>
            {hardcoded ? (
              <>
                <ActionTotal>1</ActionTotal>{" "}
                {swapCurrency ? <>SMR</> : <>MIOTA</>}
              </>
            ) : (
              <>
                <ActionTotal>1</ActionTotal> {token0Symbol}
              </>
            )}
          </ActionTextTitle>
          <SwapButton onClick={swapTokens}>
            <SwitchIcon />
          </SwapButton>
          <ActionTextTitle>
            {hardcoded ? (
              <>
                <ActionTotal>
                  {swapCurrency
                    ? formatNumber(1 / Number(2.719), 4)
                    : formatNumber(2.719, 4)}
                </ActionTotal>
                {swapCurrency ? <>MIOTA</> : <>SMR</>}
              </>
            ) : (
              <>
                {" "}
                <ActionTotal>
                  {swapCurrency
                    ? formatNumber(1 / Number(formattedPrice), 4)
                    : formatNumber(formattedPrice, 4)}
                </ActionTotal>
              </>
            )}

            {token1Symbol}
          </ActionTextTitle>
        </TopRow>
        <BottomRow>
          <ButtonActions>
            {hardcoded ? (
              <>
                {" "}
                <AddLiquidityButton disabled={true}>
                  Create New Liquidity
                </AddLiquidityButton>
              </>
            ) : (
              <>
                {" "}
                <Link
                  to={`/pools/add?firstToken=${token0DataAddress}&secondToken=${token1DataAddress}&feeAmount=${feeTier}&minPrice=${minPrice}&maxPrice=${maxPrice}`}
                >
                  <AddLiquidityButton>Create New Liquidity</AddLiquidityButton>
                </Link>
              </>
            )}
          </ButtonActions>
        </BottomRow>
        <LastRow>
          <ContractWrapper>
            <ContractViewHref
              target="_blank"
              rel="noopener noreferrer"
              href={`${explorerURL?.explorer}/address/${farmAddress}`}
            >
              <ContractViewText>View Contract</ContractViewText>
              <ContactExternalIcon />
            </ContractViewHref>
          </ContractWrapper>
          <PositionListBottom>
            <PositionTimeLeft>
              <TimeLeftText>
                {dateFromUnixTimestamp(Number(endBlock)) > 0
                  ? `${dateFromUnixTimestamp(Number(endBlock))} days left`
                  : `Time Left: 0d`}
              </TimeLeftText>
            </PositionTimeLeft>
          </PositionListBottom>
        </LastRow>
      </ActionTop>
    </ActionWrapper>
  )
}

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 32px 0 0 0 !important;
`

const AddLiquidityButton = styled(FarmAddLiquidityButton)`
  background: ${TangleColors.heroCTA};
  padding: 2px 16px;
  border-radius: 12px;
  width: 100%;
  margin: auto;
`

const ButtonActions = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  a {
    width: 100%;
  }
`

const SwapButton = styled.div`
  background: ${TangleColors.heroCTA};
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 14px;
  margin: 0 12px;

  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  cursor: pointer;
  :hover {
    border: 1px solid ${TangleColors.white};
    background: ${TangleColors.tangle10};
  }
`

const SwitchIcon = styled(AiOutlineSwap)`
  width: 22px;
  height: 22px;
  color: ${TangleColors.white};
`
const ActionTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
`
const TopRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 0 16px 0;
`
const ActionTextTitle = styled(Caption)`
  display: flex;

  flex-direction: row;
`
const ActionTotal = styled(Caption)`
  margin: 0 4px 0 0;
`

const BottomRow = styled.div`
  width: 100%;
`

const LastRow = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const ContractWrapper = styled.div`
  margin: 24px 0 10px 0;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`
const ContractViewText = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 0 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${ContractWrapper}:hover & {
    color: ${TangleColors.tangleDarkGreen};
  }
`
const ContractViewHref = styled.a`
  color: ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s ease-in;
  ${ContractWrapper}:hover & {
    color: ${TangleColors.tangleDarkGreen};
  }
`

const ContactExternalIcon = styled(FiExternalLink)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  ${ContractWrapper}:hover & {
    color: ${TangleColors.tangleDarkGreen};
  }
`

const PositionListBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin: 12px 0 0 0 !important;
`
const TimeLeftText = styled(Caption)`
  color: ${TangleColors.tangleYellow};
`
const PositionTimeLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
`

export default LiquidityActionRow
