import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import CreationContext from "../CreationContext"

import {
  ActionContainer,
  BackButton,
  CounterColumn,
  Input,
  InputGroup,
  InputLabel,
  InputSubLabel,
  ItemBody,
  ItemColumn,
  ItemSubTitle,
  ItemTitle,
  NextButton,
  StepCounter,
  TitleContainer,
} from "../CreateAuction"
import { SubmitHandler, useForm } from "react-hook-form"
import { Caption } from "styles/TextStyles"
import {
  MainParaTokenName,
  MainParaTokenText,
  MainTitleTokenText,
} from "components/LaunchPad/utils/LaunchPadText"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import { FeeAmount } from "@tangleswap/sdk"
import AuctionToggle from "./AuctionToggle"
import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"

interface LiquidityValue {
  lockupPeriod?: any
  amount?: any
  feeTier?: number
}
export interface LockupProps {
  length: number
}
export interface FieTierProps {
  feeTier: number
}

interface LaunchProps {
  active?: boolean
}
const LiquidityLaunchPad = () => {
  const { tokenDetails, setTokenDetails, currentStep, setCurrentStep } =
    useContext(CreationContext)
  const [userBalance, setUserBalance] = useState<number>(undefined)
  const [switchState, setSwitchState] = useState<boolean>(false)
  const [balanceError, setBalanceError] = useState<boolean>(false)
  const [percentageForLP, setPercentageForLP] = useState<number>(undefined)
  const { tangleship } = useTangleship()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<LiquidityValue>({
    defaultValues: { lockupPeriod: 180, feeTier: FeeAmount.HIGH },
    mode: "onChange",
    reValidateMode: "onChange",
  })
  const { account, chainId } = useWSCContext()

  const fetchUserBalance = useCallback(
    async (refetch = false) => {
      const addr = tokenDetails?.tokenAddress?.trim()?.toLowerCase()
      if (tokenDetails?.tokenSetupType === "create_token") {
        setUserBalance(Infinity)
        return
      }
      if (!addr || !tokenDetails?.tokensForSale) return

      const storageKey = `ihubNewAucTokBal${chainId}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setUserBalance(Number(storageValue))
        return
      }

      const res = await tangleship?.getTokenBalance(account, addr)
      sessionStorage.setItem(storageKey, String(res))
      setUserBalance(Number(res))
    },
    [
      chainId,
      account,
      tangleship,
      tokenDetails?.tokenAddress,
      tokenDetails?.tokenSetupType,
      tokenDetails?.tokensForSale,
    ]
  )
  useEffect(() => {
    fetchUserBalance(false)
  }, [fetchUserBalance])

  useEffect(() => {
    const amount = Number(watch("amount") || 0)
    const tokensForSale = Number(tokenDetails?.tokensForSale || 0)
    const userBal = Number(userBalance || 0)
    const totalSupply = Number(tokenDetails?.tokenSupply || 0)

    if (
      tokenDetails?.tokenSetupType === "owned_token" &&
      !watch("amount") &&
      userBalance === undefined
    )
      return

    const balanceValue =
      (tokenDetails?.tokenSetupType !== "owned_token" &&
        JSON.stringify(tokenDetails?.tokenAddress) === "") ||
      (tokenDetails?.tokenSetupType !== "owned_token" &&
        tokenDetails?.tokenAddress === undefined)
        ? amount + tokensForSale > totalSupply
        : amount + tokensForSale > userBal

    setBalanceError(balanceValue)
  }, [
    tokenDetails?.tokensForSale,
    tokenDetails?.tokenSetupType,
    userBalance,
    watch("amount"),
    tokenDetails?.tokenSupply,
    tokenDetails?.tokenAddress,
  ])

  const onSubmit: SubmitHandler<LiquidityValue> = (data) => {
    if (
      (tokenDetails?.tokenSetupType === "owned_token" &&
        !!watch("amount") &&
        Boolean(balanceError)) ||
      parseFloat(String(watch("amount"))) === 0 ||
      (!!watch("amount") &&
        Number(watch("amount")) + Number(tokenDetails?.tokensForSale) >
          Number(tokenDetails?.tokenSupply))
    )
      return

    if (Boolean(switchState) === true) {
      setTokenDetails({
        ...tokenDetails,
        liqLockTime: watch("lockupPeriod"),
        tokenForLiquidity: watch("amount"),
        feeTier: watch("feeTier"),
        liqPercentage: percentageForLP,
        liqLauncherEnabled: switchState,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    } else {
      setTokenDetails({
        ...tokenDetails,
        feeTier: watch("feeTier"),
        liqPercentage: 0,
        liqLauncherEnabled: switchState,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    }
  }

  const presetPeriods: LockupProps[] = [
    { length: 180 },
    { length: 90 },
    { length: 60 },
    { length: 30 },
    { length: 15 },
  ]
  const feeAmountTier: FieTierProps[] = [
    { feeTier: FeeAmount.LOW },
    { feeTier: FeeAmount.MEDIUM },
    { feeTier: FeeAmount.HIGH },
  ]

  const handleClick = (days?: any) => {
    setValue("lockupPeriod", days)
  }

  useEffect(() => {
    if (
      String(watch("amount")).trim() === undefined ||
      tokenDetails?.tokensForSale === undefined
    )
      return
    const percentageValue = Math.ceil(
      (watch("amount") / tokenDetails?.tokensForSale) * 100
    )
    setPercentageForLP(percentageValue)
  }, [tokenDetails?.tokensForSale, watch("amount")])
  const switchToggle = useCallback(() => {
    setSwitchState((prevSwitchState) => !prevSwitchState)
  }, [])

  return (
    <ItemBody active={currentStep === 3}>
      <CounterColumn>
        <StepCounter active={currentStep === 3}>3</StepCounter>
      </CounterColumn>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ItemColumn>
          <TitleContainer>
            <TitleRow>
              <ItemTitle>Liquidity</ItemTitle>{" "}
              {currentStep === 3 ? (
                <TitleToggle>
                  <InputGroup>
                    <AuctionToggle
                      switchState={switchState}
                      switchToggle={switchToggle}
                    />
                  </InputGroup>
                </TitleToggle>
              ) : null}
            </TitleRow>
            {currentStep === 3 ? (
              <ItemSubTitle>
                Optional — set aside tokens for automatically creating a
                liquidity pool on TangleSwap.
              </ItemSubTitle>
            ) : null}
          </TitleContainer>
          {currentStep === 3 ? (
            <>
              <Cover active={switchState}>
                <InputGroup>
                  <InputLabel>Lockup Period*</InputLabel>
                  <PeriodSelector>
                    {presetPeriods.map((time, index: number) => {
                      return (
                        <CurrencyCover key={index}>
                          <CurrencyOption
                            type="radio"
                            onClick={() => handleClick(time.length)}
                            active={
                              String(watch("lockupPeriod"))
                                .trim()
                                .toLowerCase() ===
                              String(time.length).trim().toLowerCase()
                            }
                          />
                          <Currency
                            active={
                              String(watch("lockupPeriod"))
                                .trim()
                                .toLowerCase() ===
                              String(time.length).trim().toLowerCase()
                            }
                          >
                            <CurrencyText
                              className="feeTierCurrency"
                              active={
                                String(watch("lockupPeriod"))
                                  .trim()
                                  .toLowerCase() ===
                                String(time.length).trim().toLowerCase()
                              }
                            >
                              {time.length} days
                            </CurrencyText>
                          </Currency>
                        </CurrencyCover>
                      )
                    })}
                  </PeriodSelector>
                  <FormInputWrapper>
                    <Input
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")

                        if (parseFloat(e.target.value) < 1) {
                          e.target.value = 1
                        }
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      className="form_symbol"
                      value={watch("lockupPeriod")}
                      {...register("lockupPeriod", {
                        required: switchState === true,
                      })}
                      placeholder="Custom amount of days"
                      autoComplete="off"
                      formError={errors.lockupPeriod ? true : false}
                    />
                    <FormInputTokenSymbol>
                      <SymbolText>Days</SymbolText>
                    </FormInputTokenSymbol>{" "}
                  </FormInputWrapper>
                </InputGroup>
                <InputGroup>
                  <InputLabel>
                    Amount of Tokens Reserved for Liquidity Seeding*
                  </InputLabel>
                  <Input
                    {...register("amount", {
                      required: switchState === true,
                    })}
                    placeholder="These tokens will be used for your concentrated liquidity pool"
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                      const parts = e.target.value.split(".")

                      if (parts.length > 2) {
                        e.target.value =
                          parts[0] + "." + parts.slice(1).join("")
                      }
                      if (
                        parseFloat(e.target.value) >
                        parseFloat(tokenDetails?.tokensForSale)
                      )
                        e.target.value = tokenDetails?.tokensForSale

                      if (
                        parseFloat(e.target.value) >
                        parseFloat(tokenDetails?.tokensForSale)
                      )
                        e.target.value = tokenDetails?.tokensForSale

                      // if (
                      //   parseFloat(e.target.value) <
                      //   parseFloat(tokenDetails?.tokensForSale) * 0.01
                      // )
                      //   e.target.value =
                      //     parseFloat(tokenDetails?.tokensForSale) * 0.01
                    }}
                    autoComplete="off"
                    formError={errors.amount ? true : false}
                  />
                  {parseFloat(watch("amount")) >
                  parseFloat(tokenDetails?.tokensForSale) ? (
                    <>
                      {" "}
                      <ErrorMessage
                        active={Boolean(
                          parseFloat(watch("amount")) >
                            parseFloat(tokenDetails?.tokensForSale)
                        )}
                      >
                        Amount can not be greater than token for sale
                      </ErrorMessage>
                    </>
                  ) : null}
                  {(tokenDetails?.tokenSetupType === "owned_token" &&
                    !!watch("amount") &&
                    Boolean(balanceError)) ||
                  (!!watch("amount") &&
                    Number(watch("amount")) +
                      Number(tokenDetails?.tokensForSale) >
                      Number(tokenDetails?.tokenSupply)) ? (
                    <>
                      {" "}
                      <ErrorMessage
                        active={Boolean(
                          (tokenDetails?.tokenSetupType === "owned_token" &&
                            !!watch("amount") &&
                            Boolean(balanceError)) ||
                            (!!watch("amount") &&
                              Number(watch("amount")) +
                                Number(tokenDetails?.tokensForSale) >
                                Number(tokenDetails?.tokenSupply))
                        )}
                      >
                        <>
                          Tokens For Sale + Tokens For Liquidity Seeding cannot{" "}
                          {!!watch("amount") &&
                          Number(watch("amount")) +
                            Number(tokenDetails?.tokensForSale) >
                            Number(tokenDetails?.tokenSupply) ? (
                            <> be more than the total supply</>
                          ) : (
                            <> be more than your balance</>
                          )}
                        </>
                      </ErrorMessage>
                    </>
                  ) : null}
                </InputGroup>
                <InputGroup>
                  <InputRowWrapper>
                    {" "}
                    <ParaTokenName>
                      <PairNameText> Liquidity Pool:</PairNameText>
                    </ParaTokenName>{" "}
                    <ParaTokenName>
                      <ParaTokenText left={false}>
                        {!!percentageForLP && (
                          <>
                            {/* {formatNumber(watch("amount"))}{" "} */}
                            {formatNumber(
                              (Number(tokenDetails?.tokensForSale) *
                                percentageForLP) /
                                100
                            )}{" "}
                            {tokenDetails?.tokenSymbol} + {percentageForLP}% of{" "}
                            {tokenDetails?.paymentCurrencySymbol} proceeds
                          </>
                        )}
                      </ParaTokenText>
                    </ParaTokenName>
                  </InputRowWrapper>

                  <InputSubLabel>
                    The liquidity pool will be created by pairing the tokens
                    reserved for liquidity seeding with a % of auction proceeds
                    such that the price will equal the token price during the
                    auction.
                  </InputSubLabel>
                </InputGroup>
                <InputGroup className="feeTierGroup">
                  <InputLabel>Fee tier*</InputLabel>

                  <PeriodSelector>
                    {feeAmountTier.map((fee, index: number) => {
                      return (
                        <CurrencyCover key={index} className="feeTierCover">
                          <CurrencyOption
                            type="radio"
                            {...register("feeTier", {
                              required:
                                switchState === true && !watch("feeTier"),
                            })}
                            value={Number(fee.feeTier)}
                            active={
                              String(watch("feeTier")).trim().toLowerCase() ===
                              String(Number(fee.feeTier)).trim().toLowerCase()
                            }
                          />
                          <Currency
                            active={
                              String(watch("feeTier")).trim().toLowerCase() ===
                              String(Number(fee.feeTier)).trim().toLowerCase()
                            }
                          >
                            <CurrencyText
                              className="feeTierTextGroup"
                              active={
                                String(watch("feeTier"))
                                  .trim()
                                  .toLowerCase() ===
                                String(Number(fee.feeTier)).trim().toLowerCase()
                              }
                            >
                              {fee.feeTier / 10000}
                              {""}%
                            </CurrencyText>
                          </Currency>
                        </CurrencyCover>
                      )
                    })}
                  </PeriodSelector>
                </InputGroup>
              </Cover>
              <ActionContainer>
                <NextButton
                  disabled={
                    (tokenDetails?.tokenSetupType === "owned_token" &&
                      !!watch("amount") &&
                      Boolean(balanceError)) ||
                    parseFloat(String(watch("amount"))) === 0 ||
                    (!!watch("amount") &&
                      Number(watch("amount")) +
                        Number(tokenDetails?.tokensForSale) >
                        Number(tokenDetails?.tokenSupply))
                  }
                  type="submit"
                >
                  Continue
                </NextButton>
                <BackButton
                  onClick={() => {
                    setCurrentStep((currentStep: number) => currentStep - 1)
                  }}
                >
                  Back
                </BackButton>
              </ActionContainer>
            </>
          ) : null}
        </ItemColumn>
      </form>
    </ItemBody>
  )
}

const PeriodSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 2px;
  border: 1px solid ${TangleColors.darkText};
  border-radius: 9999px;
  width: 100%;
`

const Period = styled.div<LaunchProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.white};
  cursor: pointer;
  transition: 0.4s ease-in;

  &:hover {
    color: ${TangleColors.cta};
  }
`

const Cover = styled.div<LaunchProps>`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  pointer-events: ${(props) => (props.active ? "all" : "none")};
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SwitchLabel = styled.label`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: ${TangleColors.white};

  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: ${TangleColors.swapBG};
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  display: none;
  border: 1px solid ${TangleColors.white} !important;
  border-radius: 99px;

  &:checked + ${Switch} {
    background: ${TangleColors.cta};

    &:before {
      transform: translate(30px, -50%);
    }
  }
`

const InputText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${TangleColors.white};
`

const Currency = styled.div<LaunchProps>`
  background: ${(props) =>
    props.active ? TangleColors.lighthover : "transparent"};
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.white};
  cursor: pointer;

  transition: 0.4s ease-in;
  padding: 4px 14px;
  border-radius: 20px;
  &:hover {
    color: ${TangleColors.cta};
  }
`

const CurrencyOption = styled.input<LaunchProps>`
  background: transparent;
  position: absolute;

  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`

const CurrencyCover = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CurrencyText = styled(Caption)<LaunchProps>`
  width: 100%;
  color: ${(props) => (props.active ? TangleColors.black : TangleColors.white)};
`
const ErrorMessage = styled(Caption)<LaunchProps>`
  color: ${TangleColors.red};
  display: ${(props) => (props.active ? "flex" : "none")};
`
const ParaTokenName = styled(MainParaTokenName)``
const ParaTokenText = styled(MainParaTokenText)`
  white-space: nowrap;
`
const PairNameText = styled(MainTitleTokenText)``

const FormInputWrapper = styled.div`
  position: relative;
  width: 100%;
  .form_symbol {
    padding: 14px 0 14px 96px;
  }
`
const FormInputTokenSymbol = styled.div`
  position: absolute;
  top: 50%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, -50%);
  width: 80px;
  height: 100%;
  z-index: 4;
  background: ${TangleColors.lighthover};
  border-top: 1.4px solid ${TangleColors.offWhite};
  border-bottom: 1.4px solid ${TangleColors.offWhite};
  border-left: 1.4px solid ${TangleColors.offWhite};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`
const SymbolText = styled(Caption)`
  color: ${TangleColors.black};
`
const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const TitleToggle = styled.div`
  margin: 0 0 0 12px;
`
const InputRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export default LiquidityLaunchPad
