import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import FarmLiquidityAction from "./FarmTop/FarmLiquidityAction"
import FarmLiquidityDetailsRow from "./FarmTop/FarmLiquidityDetailsRow"
interface FarmItemBottomProps {
  showDetails?: boolean
}
interface FarmBottomProps {
  isEnded?: boolean
  isFarmApproved?: any
  token0Qty?: any
  token1Qty?: any
  token0DataAddress?: any
  token0DataSymbol?: any
  token1DataSymbol?: any
  token1DataAddress?: any
  positions?: any
  rewardTokens?: any
  farmAddress?: string
  sqrtPriceX96?: number
  enabled?: any
  currentCycle?: any
  endBlock?: number
  rewardsAddress0?: string | undefined | null
  rewardsAddress1?: string | undefined | null
  formattedPrice?: any
  feeTier?: number
  isLPstakedInFarm?: any
  allUserPools?: any
  allPoolsLoading?: boolean
  minPrice?: any
  maxPrice?: any
  hardcoded?: boolean
  startBlock?: number
}

const FarmItemsBottom: FC<FarmBottomProps & FarmItemBottomProps> = (props) => {
  const {
    showDetails,
    farmAddress,
    token0DataAddress,
    token1DataAddress,
    token0DataSymbol,
    token1DataSymbol,
    isEnded,
    isFarmApproved,
    sqrtPriceX96,
    endBlock,
    rewardsAddress0,
    isLPstakedInFarm,
    formattedPrice,
    feeTier,
    allUserPools,
    allPoolsLoading,
    minPrice,
    maxPrice,
    hardcoded,
    startBlock,
  } = props

  return (
    <>
      {" "}
      <FarmBottomColumn showDetails={showDetails}>
        <FarmLiquidityAction
          formattedPrice={formattedPrice}
          feeTier={feeTier}
          farmAddress={farmAddress}
          token0DataAddress={token0DataAddress}
          token1DataAddress={token1DataAddress}
          token0DataSymbol={token0DataSymbol}
          token1DataSymbol={token1DataSymbol}
          endBlock={endBlock}
          minPrice={minPrice}
          maxPrice={maxPrice}
        />
        <FarmLiquidityDetailsRow
          feeTier={feeTier}
          sqrtPriceX96={sqrtPriceX96}
          formattedPrice={formattedPrice}
          token0DataAddress={token0DataAddress}
          token1DataAddress={token1DataAddress}
          rewardTokens={rewardsAddress0}
          farmAddress={farmAddress}
          allUserPools={allUserPools}
          allPoolsLoading={allPoolsLoading}
          isLPstakedInFarm={isLPstakedInFarm}
          startBlock={startBlock}
          endBlock={endBlock}
          isEnded={isEnded}
          isFarmApproved={isFarmApproved}
        />
      </FarmBottomColumn>
    </>
  )
}

const FarmBottomColumn = styled.div<FarmItemBottomProps>`
  display: ${(props) => (props.showDetails ? "grid" : "none")};
  grid-template-columns: 290px auto;
  overflow: hidden;
  width: 100%;
  background: ${TangleColors.featuresCL} !important;
  position: relative;

  padding: 12px 16px !important;
  gap: 12px;
  grid-gap: 12px;
  margin-bottom: 12px;
  border-radius: 0px 0px 16px 16px;
  @media only screen and (max-width: 850px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 2px 8px 8px 8px !important;
  }
`

export default FarmItemsBottom
