import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { FiRefreshCw } from "react-icons/fi"
import { Caption, CaptionSmall, Header4 } from "styles/TextStyles"
import AccountReferralRow from "./AccountReferralRow"
import ReferralsTables from "./table/ReferralsTables"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
interface AffiliateProps {
  referralsList?: any
  blockSync?: any
}
const AffiliateInfoRow: FC<AffiliateProps> = (props) => {
  const { referralsList, blockSync } = props
  return (
    <AccountWrapper>
      <DetailsWrapper>
        <AccountTopCover>
          <AccountStatTop>
            <AccountStatTitle>Affiliate Program</AccountStatTitle>
            <BlockCover>
              <UpdatedIcon />
              <AccountStateBottom>
                <StatText>
                  Last synced block: {formatNumber(blockSync)}
                </StatText>
              </AccountStateBottom>
            </BlockCover>
          </AccountStatTop>

          <AccountReferralRow />
        </AccountTopCover>
        {/* {chainId !== Chains.SEPOLIA_TEST ? (
          <>
            {" "}
            <AccountStatRow>
              <StatTitle>
                Affiliate data is only available for Ethereum. Please change the
                network to see it.
              </StatTitle>
            </AccountStatRow>
          </>
        ) : null} */}

        <AccountReferralWrapper>
          <ReferralsTables referralsData={referralsList} maxItems={12} />
        </AccountReferralWrapper>
      </DetailsWrapper>
    </AccountWrapper>
  )
}
const AccountTopCover = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: flex-start;
`

const BlockCover = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
`
const AccountWrapper = styled.div`
  background: ${TangleColors.white};

  max-width: 1232px;
  position: relative;
  border-radius: 20px;
  padding: 12px 20px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
`

const AccountStatTop = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  width: 100%;
  margin: 16px 0;
`
const AccountStatTitle = styled(Header4)`
  color: ${TangleColors.black};
  transition: 0.4s ease-in;
  text-align: left;
`
const AccountStateBottom = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  width: 100%;
  margin: 8px 0 0 0;
`

const UpdatedIcon = styled(FiRefreshCw)`
  width: 18px;
  height: 18px;
  color: ${TangleColors.grayDark};
  margin: 0 8px 0 0;
  cursor: pointer;
`
const StatText = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  cursor: pointer;
`

const AccountStatRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 24px;
  background: ${TangleColors.tangleYellowPrimary};
  border-radius: 12px;
  margin: 32px 0 0 0;
`

const StatTitle = styled(Caption)`
  color: ${TangleColors.black};
  margin: 0;
  transition: 0.4s ease-in;
`

const AccountReferralWrapper = styled.div`
  color: ${TangleColors.white};
  margin: 32px 0 0 0;
  width: 100%;
`
export default AffiliateInfoRow
