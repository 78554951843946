import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"
import { motion, AnimatePresence } from "framer-motion"

const PoolFee = () => {
  return (
    <AnimatePresence>
      <Body
        initial={{ scale: 0, y: 0, x: 0 }}
        animate={{ scale: 1, y: -10 }}
        exit={{ scale: 0, y: 0 }}
        transition={{
          duration: 0.1,
          ease: "easeIn",
        }}
        style={{ transformOrigin: "top left" }}
      >
        <Cover>Pool Fee</Cover>
      </Body>
    </AnimatePresence>
  )
}

const Body = styled(motion.div)`
  transition: 0.4s ease-in;
  position: absolute;
  top: 48px;
  left: 0%;
  width: 68px;
  height: 32px;
  border-radius: 8px;
  background: ${TangleColors.tangleBlack};
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`
const Cover = styled(CaptionSmall)``

export default PoolFee
