import { BigNumber } from "ethers"
import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import router from "assets/router.svg"
import { AiOutlinePlus } from "react-icons/ai"
import { RiCloseLine } from "react-icons/ri"
import { Body3, Caption } from "styles/TextStyles"

import { toSignificantDigits } from "utils/toSignificant"
import RouteForOrbitRouter from "./RouteForOrbitRouter"

interface SwapRouteprops {
  toggleRouter?: () => void
  showRoute?: boolean
  quote?: any
  estimatedGasUsed?: BigNumber

  estimatedGasUsedQuoteToken?: any
  estimatedGasUsedUSD?: any
  swapQuote?: any
  routeFeeTiers?: any
  routePercentages?: any
  routeTokenAddresses?: any
}

const SwapRoute: FC<SwapRouteprops> = (props) => {
  const {
    showRoute,
    toggleRouter,
    routeTokenAddresses,
    estimatedGasUsedUSD,
    routeFeeTiers,
    routePercentages,
    swapQuote,
  } = props

  const estimatedUSDgas = Number(
    toSignificantDigits(estimatedGasUsedUSD, 2, true, 2)
  )
  const bottomText =
    // false && // temporarily disable this text while ShimmerEVM gas calc is fixed
    estimatedUSDgas > 0
      ? `Best price route costs ~$${estimatedUSDgas.toLocaleString(
          "en-US"
        )} in gas. `
      : ""

  return (
    <Body onClick={toggleRouter}>
      <Cover>
        <RouterTop>
          <RouterTopLeft>
            <AutoRouterImage src={router} alt="tangleswap orbit router" />
            <RouterTitle>Orbit Router</RouterTitle>
          </RouterTopLeft>
          <RouterTopRight>
            {showRoute ? <RouterIconCross /> : <RouterIconPlus />}
          </RouterTopRight>
        </RouterTop>
        {routeTokenAddresses.map((route: any, index: number) => (
          <RouterBody showRoute={showRoute} key={index}>
            <RouteForOrbitRouter
              index={index}
              routeFeeTiers={routeFeeTiers}
              routePercentages={routePercentages}
              routeTokenAddresses={routeTokenAddresses}
            />
          </RouterBody>
        ))}

        <RouterBottom showRoute={showRoute}>
          <BottomText>
            {bottomText}TangleSwap optimizes your total output by considering
            split routes, multiple hops, and the gas cost of each step.
          </BottomText>
        </RouterBottom>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  min-height: 40px;
  max-width: 680px;
  width: 100%;
  border-radius: 16px;
  margin: 16px auto;
  padding: 16px;
  border: 2px solid ${TangleColors.tangleBlack};
  cursor: pointer;
  transition: 0.4s ease-in;

  :hover {
    border: 2px solid ${TangleColors.lighthover};
  }
`
const Cover = styled.div`
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
`

const RouterTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const RouterTopLeft = styled.div`
  max-width: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
`
const AutoRouterImage = styled.img`
  height: 20px;
  max-width: 24px;
  width: 100%;
  object-fit: cover;
`
const RouterTitle = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 8px;
  transition: 0.4s ease-in;
  white-space: nowrap;
  ${Body}:hover & {
    color: ${TangleColors.lighthover};
  }
`
const RouterTopRight = styled.div`
  max-width: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: 0.4s ease-in;
`
const RouterIconPlus = styled(AiOutlinePlus)`
  color: ${TangleColors.white};
  height: 16px;
  width: 16px;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.lighthover};
  }
`
const RouterIconCross = styled(RiCloseLine)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.lighthover};
  }
`
const RouterBody = styled.div<SwapRouteprops>`
  margin: 16px 0;
  width: 100%;
  display: ${(props) => (props.showRoute ? "flex" : "none")};
`
const RouterBottom = styled.div<SwapRouteprops>`
  max-width: 660px;
  width: 100%;
  display: ${(props) => (props.showRoute ? "flex" : "none")};
  margin: 0 auto;
  border-top: 1px solid ${TangleColors.tangleBlack};
  padding: 8px 0 0 0;
  justify-content: center;
  text-align: center;
`
const BottomText = styled(Body3)`
  text-align: justify;
`
const DottedLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  max-width: 530px;
  width: calc(100%);
  margin: 0 auto;
  flex-wrap: wrap;
  z-index: 1;
  top: 45%;
  left: 16px;
  transform: translate(-10%, -50%);
  /* opacity: 0.5; */
`
const DotColor = styled.img`
  width: 100%;
  display: flex;
  max-width: 530px;
  height: 4px;
  object-fit: contain;
  flex-wrap: wrap;
  path {
    stroke: ${TangleColors.grayLight};
  }
`
export default SwapRoute
