import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { Caption, Subtitle } from "styles/TextStyles"
import { useWSCContext } from "context/MilkomedaContext"

import { FarmingButton } from "styles/ButtonStyles"
import FarmConnectWallet from "./FarmConnectWallet"
import TimeRemaining from "components/LaunchPad/CreateAuction/components/TimeRemaining"
interface TLPProps {
  connected?: boolean
}
const FarmsGoLiveCounter = () => {
  const { account } = useWSCContext()

  return (
    <NoTLPContainer>
      <NoTLPCover>
        {" "}
        {account ? (
          <>
            {" "}
            <TLPIconWrapper>
              <TLPInfoIcon />
            </TLPIconWrapper>
            <TLPTextRow>
              <TLPText>This farm is not live yet, but it will be in:</TLPText>
              <TLPText>
                {" "}
                <TimeRemaining targetTimestamp={1700040945} />
              </TLPText>
            </TLPTextRow>
          </>
        ) : (
          <>
            <FarmConnectWallet />
          </>
        )}
      </NoTLPCover>
    </NoTLPContainer>
  )
}

const NoTLPContainer = styled.div<TLPProps>`
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  border: none !important;
  padding: 56px 72px;
  background: ${TangleColors.tangle10};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`

const NoTLPCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 640px;
  width: 100%;
  border: none !important;
`
const TLPIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: none !important;
`
const TLPInfoIcon = styled(AiOutlineInfoCircle)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
  cursor: pointer;
  transition: 0.4s ease-in;
  ${NoTLPContainer}:hover & {
    color: ${TangleColors.tangleYellow};
  }
`
const TLPTextRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: none !important;
  /* justify-content: center;
  align-items: center; */
`
const TLPText = styled(Caption)`
  margin: 2px 0;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  ${NoTLPContainer}:hover & {
    color: ${TangleColors.tangleYellow};
  }
`
const ConnectButton = styled(FarmingButton)`
  position: relative;

  max-width: 300px;
  height: 72px;
`

const ConnectFormCover = styled.div`
  margin: 32px auto;
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  background: ${TangleColors.swapBG};
  border-radius: 24px;
  border: 2px solid ${TangleColors.lighthover};
`
const ConnectFormText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 12px 0;
`
export default FarmsGoLiveCounter
