import { useWSCContext } from "context/MilkomedaContext"

import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { HeaderNavbar } from "styles/TextStyles"
import { useIsSupportedNetwork } from "constants/hooks"
import NetworkDropdown from "navbar/NetworkDropdown"
import MobileNetworkDropIcon from "./MobileNetworkDropIcon"
import warningSVG from "assets/warning.svg"
import useExplorerInfo from "utils/useExplorerInfo"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  closeNetworkMenu,
  openNetworkMenu,
} from "store/actions/MobileNavAction"

interface ActiveProps {
  active?: boolean
  networkDropDownOpen?: boolean
}

const MobileNetworkCover: FC<ActiveProps> = (props) => {
  const { account, isWSCConnected } = useWSCContext()
  const { explorerURL } = useExplorerInfo()
  const { networkDropDownOpen } = props
  const isSupportedNetwork = useIsSupportedNetwork()
  const dispatch = useAppDispatch()
  const networkOpen = useAppSelector(
    (state) => state.MobileNavReducer.networkOpen
  )
  const openNetworkDropdown = () => {
    dispatch(openNetworkMenu())
  }

  const closeMobileNavMenu = () => {
    dispatch(closeNetworkMenu())
  }
  return (
    <Body>
      <IOTACover
        {...(!isWSCConnected && {
          onClick: Boolean(networkOpen)
            ? closeMobileNavMenu
            : openNetworkDropdown,
        })}
        active={account && !isSupportedNetwork}
      >
        <AccountWarning
          src={warningSVG}
          active={account && !isSupportedNetwork}
        />

        <IOTALogo
          active={account && isSupportedNetwork}
          src={explorerURL?.logoUrl}
          alt="tangleswap iota"
        />
        <IOTAText>
          {account && !isSupportedNetwork ? (
            <>Unsupported</>
          ) : (
            <>{explorerURL?.label}</>
          )}
        </IOTAText>
        {!isWSCConnected && (
          <NetworkWrapper>
            <MobileNetworkDropIcon
              networkDropDownIconOpen={networkDropDownOpen}
            />
          </NetworkWrapper>
        )}
      </IOTACover>
      {!isWSCConnected && (
        <NetworkDropdown networkDropDownOpen={networkDropDownOpen} />
      )}
    </Body>
  )
}

const IOTACover = styled.div<ActiveProps>`
  height: 48px;
  width: 90px;

  padding: 0;

  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 99;
  margin: 0 4px 0 0;
  z-index: 90;
  margin: 0 auto;
  background: ${(props) =>
    props.active ? `${TangleColors.dockBG}` : `${TangleColors.cta}`};

  transition: 0.4s ease-in;

  border: 1.5px solid transparent;
`
const Body = styled.div`
  width: 80px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99 !important;
  border-radius: 24px;
  border: none;
`

const NetworkWrapper = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${IOTACover}:hover & {
    svg {
      color: ${TangleColors.white} !important;
    }
  }
`
const IOTALogo = styled.img<ActiveProps>`
  height: 26px;
  width: 26px;
  border-radius: 999px;
  margin: 0 4px;
  display: ${(props) => (props.active ? "flex" : "none")};
`
const IOTAText = styled(HeaderNavbar)`
  color: ${TangleColors.white};
  margin: 0 4px;
  line-height: 0.7 !important;
  white-space: nowrap;
  position: relative;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const AccountWarning = styled.img<ActiveProps>`
  height: 18px;
  width: 18px;
  color: ${TangleColors.white};

  display: ${(props) => (props.active ? "flex" : "none")};
`

export default MobileNetworkCover
