import React, { FC, RefObject } from "react"

import styled from "styled-components"
interface WSCModalProps {
  children?: React.ReactNode
  closeContinue?: () => void
  bodyRef?: RefObject<any>
}
const WSCModalCover: FC<WSCModalProps> = (props) => {
  const { children, bodyRef, closeContinue } = props

  return (
    <Body ref={bodyRef} onClick={closeContinue}>
      <ModalWrapper> {children}</ModalWrapper>
    </Body>
  )
}
export const Body = styled.div`
  position: fixed;
  z-index: 920;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.5);
`
const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  margin: 0 auto;
`
export default WSCModalCover
