import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { ReactComponent as OfflineInternetSVG } from "assets/pilot.svg"

import { Subtitle, Header6, Caption } from "styles/TextStyles"

const OfflineComponent = () => {
  const handleRetry = () => {
    window.location.reload()
  }

  return (
    <NoConnectionMessage>
      <NoConnectionCover>
        <SearchEmptyWrapper>
          <ImageWrapper>
            {/* Use the SVG component directly */}
            <OfflineInternetSVG />
          </ImageWrapper>
          <OfflineTextTitle>Connect to the Internet</OfflineTextTitle>
          <SearchText>
            Oops! It seems you are offline. Please check your connection and try
            again.
          </SearchText>
          <RetryButton onClick={handleRetry}>
            <RetryButtonText>Retry</RetryButtonText>
          </RetryButton>
        </SearchEmptyWrapper>
      </NoConnectionCover>
    </NoConnectionMessage>
  )
}

// Styled components remain unchanged
const NoConnectionMessage = styled.div`
  background: ${TangleColors.swapBG};
  width: 100%;
  background: transparent;
  min-height: 450px;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
`
const NoConnectionCover = styled.div`
  color: ${TangleColors.white};
  padding: 10px;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  min-height: 250px;
`

const SearchEmptyWrapper = styled.div`
  min-height: 60px;
  max-width: 900px;
  width: 100%;
  margin: auto;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  padding: 32px;
`
const ChartImage = styled.img`
  max-width: 150px;
  height: 150px;
  width: 100%;
  object-fit: contain;
`

const ImageWrapper = styled.div`
  max-width: 250px;
  height: 250px;
  width: 100%;
  margin: 0 auto 12px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const OfflineTextTitle = styled(Header6)`
  color: ${TangleColors.white};
  margin: 6px 0;
  text-align: center;
`
const SearchText = styled(Subtitle)`
  color: ${TangleColors.grayDark};
  margin: 2px 0;
  text-align: center;
`
const RetryButton = styled.button`
  padding: 12px 44px;
  border-radius: 999px;
  margin: 20px 0;
  border: none;
  background: ${TangleColors.lighthover};
  color: ${TangleColors.black};
  cursor: pointer;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${TangleColors.white};
    background: ${TangleColors.heroCTA};
    color: ${TangleColors.white};
  }
`
const RetryButtonText = styled(Caption)`
  color: ${TangleColors.black};
  margin: 2px 0;
  ${RetryButton}:hover & {
    color: ${TangleColors.white};
  }
`
export default OfflineComponent
