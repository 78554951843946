import { LP_PAIR_TYPEINPUT } from "store/actions/actionsTypes"
import { updateObject } from "store/utility"

export interface PairProps {
  readonly pairTypedValue: any
}
const initialState: PairProps = {
  pairTypedValue: "",
}

const pairTypeInput = (state, action) => {
  return updateObject(state, {
    ...state,
    pairTypedValue: action.pairTypedValue,
  })
}

const PairReducer = (state = initialState, action) => {
  switch (action.type) {
    case LP_PAIR_TYPEINPUT:
      return pairTypeInput(state, action)
    default:
      return state
  }
}

export default PairReducer
