export interface NFTProps {
  name?: string
  title?: string
  apr?: number | string
  media?: any
  res?: any
  nftyield?: number | string
  uid?: string
  value?: string | number
  slug?: string
  edition?: string | number
}

export const baseApyPerSpecies = {
  "Palm Springs": 20,
  "Floating Relaxation": 30,
  "Tranquillity": 60,
  "Ascension": 80,
  "Night Fall": 100,
}

export const nftData = [
  {
    name: "VOID",
    title: "Palm Springs",
    apr: 20,
    slug: "palm",
    edition: 2500,
    res: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_PalmSprings.webp",
    media:
      "https://d3m3d54t409w7t.cloudfront.net/nfts/TBB_PalmSprings_lowres.webp",
  },
  {
    name: "VOID",
    title: "Floating Relaxation",
    apr: 30,
    slug: "floating",
    edition: 1500,
    res: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_Floating.webp",
    media:
      "https://d3m3d54t409w7t.cloudfront.net/nfts/TBB_FloatingRelaxation_lowres.webp",
  },
  {
    name: "VOID",
    title: "Tranquillity",
    apr: 60,
    slug: "tranquillity",
    edition: 500,
    res: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_Tranquillity.webp",
    media:
      "https://d3m3d54t409w7t.cloudfront.net/nfts/TBB_Tranquillity_lowres.webp",
  },
  {
    name: "VOID",
    title: "Ascension",
    apr: 80,
    slug: "ascension",
    edition: 300,
    res: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_Ascencion.webp",
    media:
      "https://d3m3d54t409w7t.cloudfront.net/nfts/TBB_Ascension_lowres.webp",
  },
  {
    name: "VOID",
    title: "Night Fall",
    apr: 100,
    slug: "night",
    edition: 200,
    res: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_NightFall.webp",
    media:
      "https://d3m3d54t409w7t.cloudfront.net/nfts/TBB_NightFall_lowres.webp",
  },
];
