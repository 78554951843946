import { isInvalid } from "@tangleswap/sdk"
import { BLOCK_NUMBER_UPDATE } from "./actionsTypes"

export const updateBlockNumber = (data: any) => {
  return {
    type: BLOCK_NUMBER_UPDATE,
    data: data,
  }
}

export const blockNumberUpdateFunction = (blockNumber: any) => {
  return (dispatch: any) => {
    if (isInvalid([blockNumber])) return

    dispatch(updateBlockNumber(blockNumber))
  }
}
