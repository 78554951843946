import { UPDATE_AUCTION_AGREEMENT } from "./actionsTypes"

export const auctionKnowledgSelected = (tangleAuctionStatus: any) => {
  return {
    type: UPDATE_AUCTION_AGREEMENT,
    tangleAuctionStatus: tangleAuctionStatus,
  }
}

export const updateAuctionToggleStatus = (tangleAuctionStatus: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleAuctionStatus", tangleAuctionStatus)
    dispatch(auctionKnowledgSelected(tangleAuctionStatus))
  }
}

export const checkAuctionToggleStatus = () => {
  return (dispatch: any) => {
    const tangleTradeMode = localStorage.getItem("tangleAuctionStatus")
    // If value is null or "false" string
    if (tangleTradeMode === null || tangleTradeMode === "false") {
      dispatch(updateAuctionToggleStatus(false))
    } else {
      dispatch(updateAuctionToggleStatus(true))
    }
  }
}
