import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { escapeRegExp } from "utils/index"

interface InputProps {
  loading?: boolean
}

export const SwapInput = React.memo(function SwapInput({
  value,
  onUserInput,
  placeholder,
  name,
  disabled,
  loading,

  ...rest
}: {
  value?: string | number
  name?: string
  onUserInput?: (value: any) => void
  error?: boolean

  disabled?: boolean
  loading?: boolean
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">) {
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput?.(nextUserInput)
    }
  }

  return (
    <TokenCover>
      {/* {notActive? } */}

      <TokenValueInput
        autoComplete="off"
        disabled={disabled}
        autoCorrect="off"
        type="text"
        pattern="^[0-9]*[.,]?[0-9]*$"
        placeholder="0.0"
        minLength={1}
        maxLength={79}
        spellCheck="false"
        name={name}
        value={value}
        id={name}
        loading={loading}
        aria-label="tangleswap swap input"
        inputMode="decimal"
        onChange={(event) => {
          // replace commas with periods, because we exclusively uses period as the decimal separator
          enforcer(event.target.value.replace(/,/g, "."))
        }}
      />
    </TokenCover>
  )
})

const TokenCover = styled.div`
  position: relative;
  width: 100%;

  overflow: hidden;
`

const TokenValueInput = styled.input<InputProps>`
  max-width: 500px;
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  text-align: right;
  /* padding: 10px; */
  padding: 0 0 15.5px 0;
  /* margin: 0 0 20px 0; */
  color: ${TangleColors.white};
  font-family: "DM Sans Regular";
  font-size: 30px;
  font-style: normal;
  background: transparent;
  opacity: ${(props) => (props.loading ? "0.2" : "1")};
  ::placeholder {
    font-size: 30px;

    color: ${TangleColors.white};
    opacity: 0.4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :disabled {
    :hover {
      cursor: not-allowed;
    }
  }
`
