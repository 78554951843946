import { useWSCContext } from "context/MilkomedaContext"
import React, { useState } from "react"
import CardanoLedgerWarning from "./CardanoLedgerWarning"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateLedgerModalOpen } from "store/actions/CardanoSettingsActions"
import { Chains } from "@tangleswap/sdk"
import LedgerWarningModal from "./modals/LedgerWarningModal"

const LedgerContext = () => {
  const { chainId, isWSCConnected } = useWSCContext()

  const dispatch = useAppDispatch()
  const ledgerModalOpen = useAppSelector(
    (state) => state.CardanoSettings.ledgerModalOpen
  )
  const handleOpenDCModal = () => {
    dispatch(updateLedgerModalOpen(true))
  }
  return (
    <>
      {!!ledgerModalOpen ? (
        <>
          <LedgerWarningModal openDCModal={ledgerModalOpen} />
        </>
      ) : null}
      {!!isWSCConnected && chainId === Chains.CARDANO ? (
        <>
          {" "}
          <CardanoLedgerWarning handleOpenDCModal={handleOpenDCModal} />
        </>
      ) : null}
    </>
  )
}

export default LedgerContext
