import { DEFAULT_DEADLINE_FROM_NOW } from "components/utils/misc"
import {
  UPDATE_USER_SLIPPAGE_TOLERANCE,
  UPDATE_USER_DEADLINE_MINUTE,
} from "./actionsTypes"

export const userSlippageTolerance = (slippage: any) => {
  return {
    type: UPDATE_USER_SLIPPAGE_TOLERANCE,
    userSlippageTolerance: slippage,
  }
}
export const userDeadlineMinute = (deadlineMinute: any) => {
  return {
    type: UPDATE_USER_DEADLINE_MINUTE,
    deadlineMinute: deadlineMinute,
  }
}

export const updateUserDeadlineMinute = (deadlineMinute: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleSwapDeadlineMinute", deadlineMinute)
    dispatch(userDeadlineMinute(deadlineMinute))
  }
}

export const proCheckDeadlineMinute = () => {
  return (dispatch: any) => {
    const deadlineMinute = localStorage.getItem("tangleSwapDeadlineMinute")

    if (deadlineMinute === null || deadlineMinute === "false") {
      dispatch(updateUserDeadlineMinute(DEFAULT_DEADLINE_FROM_NOW))
    } else {
      dispatch(updateUserDeadlineMinute(deadlineMinute))
    }
  }
}

export const updateUserSlippageTolerance = (slippage: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleSwapUserSlippageTolerance", slippage)
    dispatch(userSlippageTolerance(slippage))
  }
}

export const proCheckSlippageState = () => {
  return (dispatch: any) => {
    const slippage = localStorage.getItem("tangleSwapUserSlippageTolerance")

    if (
      slippage === undefined ||
      slippage === null ||
      slippage === "undefined" ||
      slippage === "" ||
      slippage === "NaN" ||
      isNaN(Number(slippage)) ||
      Number.isNaN(Number(slippage)) ||
      slippage === "false"
    ) {
      dispatch(updateUserSlippageTolerance("auto"))
    } else {
      dispatch(updateUserSlippageTolerance(slippage))
    }
  }
}
