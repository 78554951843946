import { useCallback } from "react"
import {
  typeInput,
  selectCurrency,
  switchCurrencies,
} from "store/actions/SwapAction"
import { useAppDispatch } from "store/hooks"
import { useSwapState } from "./actions"

import { Field } from "@tangleswap/sdk"
import { useCurrencyLP } from "components/Liquidity/utils/hooks"

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: any, chainId?: any) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  swapInPutError?: any
  swapOutPutError?: any
} {
  const dispatch = useAppDispatch()

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  }: any = useSwapState()
  const inputCurrency = useCurrencyLP(inputCurrencyId, Field.INPUT)
  const outputCurrency = useCurrencyLP(outputCurrencyId, Field.OUTPUT)

  const currencies = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }
  let swapInPutError: string | undefined
  let swapOutPutError: string | undefined

  const onCurrencySelection = useCallback(
    (field: Field, currencyAddress: any, chainId?: any) => {
      dispatch(selectCurrency({ field, currencyAddress, chainId }))
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  if (!currencies[Field.OUTPUT] || currencies[Field.OUTPUT] === undefined) {
    swapOutPutError = swapOutPutError ?? "Select token"
  }
  if (
    !currencies[Field.INPUT] ||
    currencies[Field.INPUT] === undefined ||
    ![Field.INPUT]
  ) {
    swapInPutError = swapInPutError ?? "Select token"
  }

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    swapOutPutError,
    swapInPutError,
  }
}
