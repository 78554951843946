import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { AiOutlineInfoCircle, AiOutlineSwap } from "react-icons/ai"
import { Caption, CaptionSmall } from "styles/TextStyles"
import PriceLoaderSpinner from "./LoaderSpinner"

import { formatNumber } from "components/LaunchPad/utils/formatNumber"

interface RateProps {
  currencyOne?: string
  currencyTwo?: string
  price?: any
  switchPrice?: () => void
  pending?: boolean
  isNaN?: boolean
}

const TokenConversion: FC<RateProps> = (props) => {
  const { currencyOne, currencyTwo, switchPrice, pending, price } = props
  // const [isLoading, setIsLoading] = useState(true) // Assume component starts in a loading state
  const [showTimeoutMessage, setShowTimeoutMessage] = useState(false)
  // useEffect(() => {
  //   // This simulates the data fetching delay
  //   // In a real-world scenario, this will be replaced by an actual data-fetching mechanism
  //   // and once the data is fetched, you'd set isLoading to false
  //   const fetchData = setTimeout(() => {
  //     setIsLoading(false)
  //   }, 20000) // 20 seconds

  //   return () => clearTimeout(fetchData)
  // }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (pending) {
        setShowTimeoutMessage(true)
      }
    }, 15000) // 15 seconds

    // Clear the timeout if the component is unmounted or if the loading completes
    return () => clearTimeout(timer)
  }, [pending])
  return (
    <>
      {pending || parseFloat(String(price)) === 0 || price === 0 ? (
        <>
          <Body>
            <Cover>
              <RateLoadWrapper>
                <LoaderWrapper>
                  <PriceLoaderSpinner />
                </LoaderWrapper>

                {showTimeoutMessage ? (
                  <RateName>Taking longer than usual, hold on...</RateName>
                ) : (
                  <>
                    <RateName> Fetching the best rates </RateName>
                  </>
                )}
              </RateLoadWrapper>
            </Cover>
          </Body>
        </>
      ) : (
        <>
          {" "}
          <Body>
            <Cover>
              <RateWrapper>
                <InfoIcon />
                <RateName>Rate</RateName>
              </RateWrapper>
              <RateChange>
                <PriceName>
                  {parseFloat(price) > 1 ? (
                    <>
                      1 {currencyOne} = {formatNumber(price, 4)} {currencyTwo}
                    </>
                  ) : (
                    <>
                      1 {currencyOne} = {formatNumber(price, 4)} {currencyTwo}
                    </>
                  )}
                </PriceName>
                <ChangeIcon onClick={switchPrice} />
              </RateChange>
            </Cover>
          </Body>
        </>
      )}
    </>
  )
}

const Body = styled.div`
  height: 44px;
  max-width: 680px;
  width: 100%;
  margin: 16px auto;
  border-radius: 16px;
  padding: 10px;
  background: ${TangleColors.tangleBlack};
  display: flex;
  justify-content: center;
  align-items: center;
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  align-items: center;
`
const RateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const RateLoadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 600px;
  width: 100%;
`
const LoaderWrapper = styled.div`
  max-width: 40px;
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const InfoIcon = styled(AiOutlineInfoCircle)`
  cursor: pointer;
  height: 16px;
  width: 16px;
  color: ${TangleColors.white};

  margin: 0 4px 0 0;
`
const RateName = styled(Caption)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
`
const PriceName = styled(CaptionSmall)`
  color: ${TangleColors.white};
`

const RateChange = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const ChangeIcon = styled(AiOutlineSwap)`
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 0 0 0 16px;
  transition: 0.4s ease-in;
  color: ${TangleColors.grayDark};
  :hover {
    color: ${TangleColors.lighthover};
  }
`

export default TokenConversion
