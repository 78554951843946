import { FC } from "react"
import styled from "styled-components"

import { Caption, CaptionSmall } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import { Chains } from "@tangleswap/sdk"
import { useWSCContext } from "context/MilkomedaContext"

import PointerGraph from "./LPGraph"
interface LiquidityProps {
  currencyALogo?: any
  currencyBLogo?: any
  activeShimmer?: boolean
  minRange?: any
  maxRange?: any
  currentPrice?: any
}
const LiquidityDetailsGraph: FC<LiquidityProps> = (props) => {
  const { maxRange, minRange, currentPrice } = props

  const { chainId } = useWSCContext()
  return (
    <GraphCover activeShimmer={Boolean(chainId === Chains.SHIMMER)}>
      <PointerGraph
        currentPrice={currentPrice}
        startPrice={minRange}
        endPrice={maxRange}
      />
      {/* <GraphBody>
        <LiquidityImages>
          <SizeImages>
            <SizeFirstIcon src={currencyALogo} alt="tangleswap liquidity" />
            <SizeSecondIcon src={currencyBLogo} alt="tangleswap liquidity" />
          </SizeImages>
        </LiquidityImages>

        <MedianPoleWrapper>
          <MedianBarPoleCover>
            {" "}
            <MedianMinMarketPriceCover>
              <MarketPriceText>
                Min Price {""}: {toSignificantDigits(minRange, 6, true, 18)}
              </MarketPriceText>
            </MedianMinMarketPriceCover>
            <IndicatorLine
              min={minRange}
              max={maxRange}
              step="1"
              disabled={true}
              value={600}
              type="range"
            />{" "}
            <MedianMaxMarketPriceCover>
              <MarketPriceText>
                Max Price {""}: {toSignificantDigits(maxRange, 6, true, 18)}
              </MarketPriceText>
            </MedianMaxMarketPriceCover>
          </MedianBarPoleCover>
        </MedianPoleWrapper>

        <GraphIndicator />

        <LiquidityPositionDetails>
          <PositionTitle>Liquidity Position</PositionTitle>
          <PositionRow percent={80} top={true} title="DAI" />
          <PositionRow percent={20} title="USDC" />
        </LiquidityPositionDetails>
      </GraphBody> */}
    </GraphCover>
  )
}
const ChartBackground = styled.div`
  background: ${TangleColors.swapBG};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 500px;
  max-width: 800px;
  width: 100%;
  margin: 24px auto;
  max-height: 500px;
  z-index: 1;
`
const IndicatorGraph = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg height=%22auto%22 viewBox=%220 0 2000 1400%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath d=%22M0 233.333c200 116.667 600 385 1000 583.334C1400 1015 1800 1143.333 2000 1225v175H0Z%22 fill=%22%23444cf71a%22%2F%3E%3Cpath d=%22M0 233.333c200 116.667 600 385 1000 583.334C1400 1015 1800 1143.333 2000 1225%22 fill=%22none%22 stroke=%22%23444cf7%22 stroke-width=%222%22%2F%3E%3Cg fill=%22%23444cf7%22%3E%3Ccircle cy=%22233.333%22 r=%226%22%2F%3E%3Ccircle cx=%221000%22 cy=%22816.667%22 r=%226%22%2F%3E%3Ccircle cx=%222000%22 cy=%221225%22 r=%226%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
`
const GraphCover = styled.div<LiquidityProps>`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  border-radius: 32px;
  overflow: hidden;
  width: 100%;
  margin: 32px auto;
  padding: 12px 40px;
  max-width: 1020px;
  height: 620px;

  border: 1px solid ${TangleColors.lighthover};
  background: ${(props) =>
    props.activeShimmer
      ? `linear-gradient(to bottom right, black 20%, #2b0039 70%),
      linear-gradient(to bottom left, black 20%, #2b0039 70%)`
      : `linear-gradient(to bottom right, black 20%, #1b0039 70%),
      linear-gradient(to bottom left, black 20%, #1b0039 70%)`};
  background-size: ${(props) =>
    props.activeShimmer
      ? `50.1% 100%`
      : `50.1% 100%`}; /* X size should be 50%, Y size can be as you wish */
  background-position: ${(props) =>
    props.activeShimmer
      ? ` left top, right top`
      : `left top, right top`}; /* don't change */
  background-repeat: ${(props) =>
    props.activeShimmer ? `no-repeat` : `no-repeat`};
`
const GraphBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`
const LiquidityImages = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  /* justify-content: center; */
  align-items: center;
  align-self: flex-end;
  margin: 24px 0 0 0;
`

const SizeImages = styled.div`
  max-width: 56px;
  width: 100%;

  position: relative;
`
const SizeFirstIcon = styled.img`
  height: 28px;
  width: 28px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`
const SizeSecondIcon = styled.img`
  height: 28px;
  width: 28px;
  position: relative;
  z-index: 3;
  margin-left: -10px;
  border-radius: 9999px;
`

const LiquidityPositionDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 0 0 64px 0;
`
const PositionTitle = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 8px 0 16px 0;
`

const GraphIndicator = styled.div`
  width: 3px;
  height: 1200px;
  transform: rotate(-45deg);
  flex-shrink: 0;
  border-radius: 32px;
  background: ${TangleColors.white};
  position: absolute;
  z-index: 2;
  top: -50%;
`

const MedianPoleWrapper = styled.div`
  width: 550px;
  /* width: 100%; */
  /* max-width: 642px; */
  transform: rotate(45deg);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 32px;
  position: absolute;
  z-index: 5;
  top: 46%;
`
const IndicatorLine = styled.input`
  /* width: 20px;
  height: 400px; */
  width: 550px;
  /* width: 100%; */
  /* max-width: 642px; */
  /* transform: rotate(45deg);
  flex-shrink: 0;
  border-radius: 32px;
  background-color: ${TangleColors.lighthover};
  position: absolute;
  z-index: 5;
  top: 46%; */
  margin: 0;
  border-radius: 32px;
  -webkit-appearance: none;
  height: 20px;
  background: ${TangleColors.cta};
  /* margin: 8px auto; */
  outline: none;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.defi};
  }
  :disabled {
    transition: 0.4s ease-in;
    background: ${TangleColors.lighthover};
    color: ${TangleColors.white};
    :hover {
      cursor: not-allowed;
    }
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: ${TangleColors.defi};
    height: 24px;
    width: 24px;

    border-radius: 999px;
    cursor: pointer;
    transition: 0.4s ease-in;

    :hover {
      background: ${TangleColors.cta};
    }
    :disabled {
      background: ${TangleColors.lighthover};
      color: ${TangleColors.white};
      :hover {
        cursor: not-allowed;
      }
    }
  }

  ::-moz-range-thumb {
    -webkit-appearance: none;
    background: ${TangleColors.defi};
    height: 24px;
    width: 24px;

    border-radius: 999px;
    cursor: pointer;
    transition: 0.4s ease-in;

    :disabled {
      background: ${TangleColors.lighthover};
      color: ${TangleColors.white};
      :hover {
        cursor: not-allowed;
      }
    }
  }
`
const MedianBarPoleCover = styled.div`
  width: 550px;

  transform: rotate(0deg);
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  border-radius: 32px;
  position: relative;
  margin: 12px 0 0 0;
`

const MarketPriceText = styled(Caption)`
  text-align: center;
  color: ${TangleColors.white};
`

const MedianMinMarketPriceCover = styled.div`
  transform: rotate(-45deg);
  background: ${TangleColors.red};
  color: ${TangleColors.white};
  padding: 8px 12px;
  height: 72px;
  border-radius: 12px;
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const MedianMaxMarketPriceCover = styled.div`
  transform: rotate(-45deg);
  background: ${TangleColors.red};
  color: ${TangleColors.white};
  padding: 8px 12px;
  border-radius: 12px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LiquidityDetailsGraph
