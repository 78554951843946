import { TANGLE_PRO_MODE, TANGLE_POSITION_STATUS_MODE } from "./actionsTypes"

export const positionSelected = (tanglePositionMode: any) => {
  return {
    type: TANGLE_POSITION_STATUS_MODE,
    tanglePositionMode: tanglePositionMode,
  }
}
export const modeSelected = (tradingMode: any) => {
  return {
    type: TANGLE_PRO_MODE,
    tradingMode: tradingMode,
  }
}

export const updateTradingProMode = (tradingMode: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleTradeMode", tradingMode)
    dispatch(modeSelected(tradingMode))
  }
}
export const updatePositionStatusMode = (tanglePositionStatusMode: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tanglePositionStatusMode", tanglePositionStatusMode)
    dispatch(positionSelected(tanglePositionStatusMode))
  }
}

// export const proCheckTradingState = () => {
//   return (dispatch: any) => {
//     const tangleTradeMode = localStorage.getItem("tangleTradeMode")

//     // If value is null or "false" string
//     if (tangleTradeMode === null || tangleTradeMode === "true") {
//       dispatch(updateTradingProMode(true))
//     } else {
//       dispatch(updateTradingProMode(tangleTradeMode))
//     }
//   }
// }
export const proCheckTradingState = () => {
  return (dispatch: any) => {
    const tangleTradeMode = localStorage.getItem("tangleTradeMode")

    const isTradingProMode = tangleTradeMode !== "false"
    dispatch(updateTradingProMode(isTradingProMode))
  }
}

export const proCheckClosedPositionStatus = () => {
  return (dispatch: any) => {
    const tanglePositionStatusMode = localStorage.getItem(
      "tanglePositionStatusMode"
    )

    const status = tanglePositionStatusMode !== "false"
    dispatch(updatePositionStatusMode(status))
  }
}

// export const proCheckClosedPositionStatus = () => {
//   return (dispatch: any) => {
//     const tanglePositionStatusMode = localStorage.getItem(
//       "tanglePositionStatusMode"
//     )

//     const isPositionStatusModeTrue = tanglePositionStatusMode === "true"

//     dispatch(updatePositionStatusMode(isPositionStatusModeTrue))
//   }
// }
