import axios from "axios"
import { useCallback, useEffect, useMemo, useState } from "react"
import { formatTickData } from "./useTickData"
import { isInvalid } from "@tangleswap/sdk"

export default function useDensityChartData(
  chainId?: number,
  poolAddress?: any,
  tickLensAddress?: any,
  slot0?: any,
  poolLiquidity?: number,
  token0Decimals?: number,
  token1Decimals?: number,
  feeAmount?: number,
  isSameBlockhainOrder?: boolean,
  userTradingMode?: boolean
): {
  formattedData?: any
  isLoading?: any
  error?: any
} {
  const [chartData, setChartData] = useState<any>([])
  const [chartError, setChartError] = useState<any>(undefined)
  const [chartLoading, setChartLoading] = useState<boolean>(false)

  useEffect(() => {
    // if (chainId !== undefined && poolAddress !== undefined && tickLensAddress) {
    // }
    if (isInvalid([tickLensAddress, chainId, poolAddress])) return
    setChartLoading(true)
    const url = `https://backend.tangleswap.space/api/v1/liquidity/chain_id/${chainId}/pool_address/${poolAddress}/tick_lens_address/${tickLensAddress}`
    axios
      .get(url)
      .then((res) => {
        setChartData(res?.data)
        setChartLoading(false)
        return res
      })
      .catch((err) => {
        setChartError(err)
        setChartLoading(false)
      })
  }, [chainId, poolAddress, tickLensAddress])

  const formatData = useCallback(() => {
    // // console.log("ChartData API:", ChartData) // Tobi: PLEASE DON'T DELETE THIS CONSOLE LOG
    if (
      !chartData?.length ||
      isInvalid([
        chartData,
        slot0,
        poolLiquidity,
        feeAmount,
        token0Decimals,
        token1Decimals,
      ])
    )
      return undefined

    // if (!chartData?.length) return undefined
    const liquidityDepthData = formatTickData(
      chartData,
      slot0,
      poolLiquidity,
      feeAmount,
      token0Decimals,
      token1Decimals,
      isSameBlockhainOrder
    )

    //// console.log("liquidityDepthData API:", liquidityDepthData) // Tobi: PLEASE DON'T DELETE
    return liquidityDepthData
  }, [
    chartData,
    slot0,
    poolLiquidity,
    feeAmount,
    token0Decimals,
    token1Decimals,
    isSameBlockhainOrder,
  ])

  return useMemo(() => {
    return {
      isLoading: chartLoading,
      error: chartError,

      formattedData:
        !chartLoading &&
        tickLensAddress !== undefined &&
        poolAddress !== undefined &&
        tickLensAddress !== undefined
          ? formatData()
          : undefined,
    }
  }, [chartLoading, chartError, formatData])
}
