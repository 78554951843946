import { TxPendingStatus } from "milkomeda-wsc"

export const BRIDGE_DEVNET_ADDRESS =
  "0x319f10d19e21188ecF58b9a146Ab0b2bfC894648"
export const BRIDGE_MAINNET_ADDRESS =
  "0xD0Fab4aE1ff28825aabD2A16566f89EB8948F9aB"

export const BRIDGE_FEE = 1.1
export const LOCK_ADA = 3
export const EVM_ESTIMATED_FEE = 0.3
export const REQUIRED_FEE_ADA = BRIDGE_FEE + LOCK_ADA + EVM_ESTIMATED_FEE
export const LOCKED_WITH_ASSETS_ADA_AMOUNT = REQUIRED_FEE_ADA + 0.8

export const LOVELACE_UNIT = "lovelace"

export const TX_STATUS_CHECK_INTERVAL = 30 * 1000

export const TxStatus = {
  ...TxPendingStatus,
  Idle: "Idle" as const,
  Init: "Init" as const,
  Pending: "Pending" as const,
  Error: "Error" as const,
  FetchError: "FetchError" as const,
  InputError: "InputError" as const,
  UnwrapError: "UnwrapError" as const,
}
