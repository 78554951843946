import { updateObject } from "store/utility"
import { TANGLESWAP_USER_REFERRAL } from "../actions/actionsTypes"

export interface ReferralProps {
  tangleswapUserReferral: any
}
const initialState: ReferralProps = {
  tangleswapUserReferral: undefined,
}

const updateUserReferral = (state, action) => {
  return updateObject(state, {
    tangleswapUserReferral: action.tangleswapUserReferral,
  })
}

const UserReferralReducer = (state = initialState, action) => {
  switch (action.type) {
    case TANGLESWAP_USER_REFERRAL:
      return updateUserReferral(state, action)

    default:
      return state
  }
}

export default UserReferralReducer
