import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"

import PanelLoading from "./PanelLoading"
import { toSignificantDigits } from "utils/toSignificant"
import LoadingDots from "components/utils/LoadingDots"
import { FC } from "react"

interface ITokenInfo {
  token0: string
  token1: string
  symbolToken0: string
  symbolToken1: string
  amountIn: any
  amountOut: any

  slippageAdjustment: any
  priceImpactAmount: any
  loading: Boolean
}

const ConversionPanel: FC<ITokenInfo> = (props) => {
  let {
    amountOut,
    amountIn,
    symbolToken0,
    symbolToken1,
    slippageAdjustment,
    loading,
    priceImpactAmount,
  } = props

  amountIn = Number(amountIn)
  amountOut = Number(amountOut)

  //calc variables
  // const slippagePercentage = slippage === "auto" ? 50 : slippage
  //const networkFee = calcNetworkFee(Number(amountIn), [fee], [100]) //***REPLACE*** here feeTier and feeWeight with multiroute swap params
  //const minAfterSlippage = minLiquidityAfterSlippage(
  //slippagePercentage / 100,
  //  Number(amountOut)
  //  )

  return (
    <Body>
      <Cover>
        <ConversionRow>
          <RowText>
            <RowCoverText>Expected Output</RowCoverText>
          </RowText>
          {loading ? (
            <>
              <PanelRow>
                <PanelLoading />
              </PanelRow>
            </>
          ) : (
            <>
              {" "}
              {amountOut && !isNaN(amountOut) ? (
                <>
                  {" "}
                  <RowText>
                    {" "}
                    {isNaN(amountOut) ? (
                      <LoadingDots />
                    ) : (
                      toSignificantDigits(amountOut, 4, true, 18) +
                      " " +
                      symbolToken1
                    )}
                  </RowText>
                </>
              ) : (
                <>
                  <PanelRow>
                    <PanelLoading />
                  </PanelRow>
                </>
              )}
            </>
          )}
        </ConversionRow>
        <ConversionRow>
          <RowText
            className={
              (amountOut &&
                !isNaN(amountOut) &&
                priceImpactAmount > 10 &&
                priceImpactAmount?.toFixed(2) !== "Infinity" &&
                "warningCritical") ||
              (amountOut &&
                !isNaN(amountOut) &&
                priceImpactAmount > 3 &&
                priceImpactAmount < 10 &&
                priceImpactAmount?.toFixed(2) !== "Infinity" &&
                "warningLess") ||
              (amountOut &&
                !isNaN(amountOut) &&
                priceImpactAmount > 0 &&
                priceImpactAmount < 3 &&
                priceImpactAmount?.toFixed(2) !== "Infinity" &&
                "warningWhite")
            }
          >
            {" "}
            <RowCoverText>Price Impact</RowCoverText>
          </RowText>
          {loading ? (
            <>
              <PanelRow>
                <PanelLoading />
              </PanelRow>
            </>
          ) : (
            <>
              {" "}
              {amountOut && !isNaN(amountOut) ? (
                <>
                  {" "}
                  <RowText
                    className={
                      (amountOut &&
                        !isNaN(amountOut) &&
                        priceImpactAmount > 10 &&
                        priceImpactAmount?.toFixed(2) !== "Infinity" &&
                        "warningCritical") ||
                      (amountOut &&
                        !isNaN(amountOut) &&
                        priceImpactAmount > 3 &&
                        priceImpactAmount < 10 &&
                        priceImpactAmount?.toFixed(2) !== "Infinity" &&
                        "warningLess") ||
                      (amountOut &&
                        !isNaN(amountOut) &&
                        priceImpactAmount > 0 &&
                        priceImpactAmount < 3 &&
                        priceImpactAmount?.toFixed(2) !== "Infinity" &&
                        "warningWhite")
                    }
                  >
                    {/* warningGreen */}
                    {isNaN(amountOut) ? (
                      <LoadingDots />
                    ) : (
                      <>
                        {" "}
                        {priceImpactAmount?.toFixed(2) === "Infinity" ? (
                          <>
                            {" "}
                            <PanelRow>
                              <LoadingDots />
                            </PanelRow>
                          </>
                        ) : (
                          <>
                            {" "}
                            {Boolean(!priceImpactAmount) &&
                            (isNaN(Number(priceImpactAmount)) ||
                              Number.isNaN(priceImpactAmount)) ? (
                              <>
                                <LoadingDots />
                              </>
                            ) : (
                              <>
                                -
                                {toSignificantDigits(
                                  priceImpactAmount,
                                  2,
                                  true
                                )}{" "}
                                %
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </RowText>
                </>
              ) : (
                <>
                  <PanelRow>
                    <PanelLoading />
                  </PanelRow>
                </>
              )}
            </>
          )}
        </ConversionRow>
        <ConversionRow>
          <RowText>
            <RowCoverText>Minimum After Slippage</RowCoverText>
          </RowText>
          {loading ? (
            <>
              <PanelRow>
                <PanelLoading />
              </PanelRow>
            </>
          ) : (
            <>
              {" "}
              {!isNaN(amountOut) ? (
                <>
                  <RowText>
                    {isNaN(amountOut) ? (
                      <LoadingDots />
                    ) : (
                      toSignificantDigits(
                        amountOut * slippageAdjustment,
                        4,
                        true,
                        18
                      ) +
                      " " +
                      symbolToken1
                    )}
                  </RowText>
                </>
              ) : (
                <>
                  {" "}
                  <PanelRow>
                    <PanelLoading />
                  </PanelRow>
                </>
              )}
            </>
          )}
        </ConversionRow>
        {/* <ConversionRow>
          // NOTE: disabling this not-so-relevant param for lighter UX
          <RowText>Liquidity Provider Fee</RowText>
          {loading ? (
            <>
              <PanelRow>
                <PanelLoading />
              </PanelRow>
            </>
          ) : (
            <>
              {" "}
              {amountOut && !isNaN(amountOut) ? (
                <>
                  {" "}
                  <RowText>
                    {isNaN(amountOut) ? (
                      <LoadingDots />
                    ) : (
                      toSignificantDigits(
                        convertNumber(String(Number(networkFee))),
                        5,
                        true
                      ) +
                      " " +
                      symbolToken0
                    )}
                  </RowText>
                </>
              ) : (
                <>
                  {" "}
                  <PanelRow>
                    <PanelLoading />
                  </PanelRow>
                </>
              )}
            </>
          )}
        </ConversionRow> */}
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  height: 180px;
  max-width: 620px;
  width: 100%;
  border-radius: 32px;
  margin: 24px auto;
  padding: 16px 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ConversionRow = styled.div`
  display: flex;
  margin: 8px 0;
  flex-direction: row;
  color: ${TangleColors.white};
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .warningCritical {
    color: ${TangleColors.red};
  }
  .warningLess {
    color: ${TangleColors.tangleGold};
  }

  .warningWhite {
    color: ${TangleColors.white};
  }
  .warningGreen {
    color: ${TangleColors.tangleGreen};
  }
`
const RowText = styled(Caption)`
  color: ${TangleColors.white};
  @media only screen and (max-width: 650px) {
    white-space: nowrap;
  }
`
const RowCoverText = styled(Caption)`
  color: ${TangleColors.feeRateText};
  @media only screen and (max-width: 650px) {
    white-space: nowrap;
  }
`

const PanelRow = styled.div`
  max-width: 250px;
  width: 100%;
`
export default ConversionPanel
