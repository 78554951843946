import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Header5, Header7 } from "styles/TextStyles"
import { AiOutlineArrowUp } from "react-icons/ai"
import { GrClose } from "react-icons/gr"
import { CiWarning } from "react-icons/ci"
import {
  AddTokenMetamaskModalButton,
  AddTokenModalButton,
  ModalButton,
} from "styles/ButtonStyles"
import { FiCheckCircle } from "react-icons/fi"
import { toSignificantDigits } from "utils/toSignificant"
import { useWSCContext } from "context/MilkomedaContext"
import { NATIVE_SYMBOL } from "@tangleswap/sdk"
import SpinnerCombined from "./SpinnerCombined"
import metamask from "assets/metamask.png"
import useExplorerInfo from "utils/useExplorerInfo"
import UserRejectedCover from "components/utils/UserRejectedCover"
interface SwapInfoProp {
  pending?: boolean
  modalRef?: any
  amount0?: any
  amount1?: any
  currency0?: any
  currency1?: any
  link?: string
  currencyToAdd?: any
  closeModal?: (value: any) => void
  closePendingModal?: () => void
  closeSwapSectionModal?: () => void

  addToMetamask?: () => void
  addedToMetamask?: boolean
  txError?: boolean
}
const SwapConfirmationModal: FC<SwapInfoProp> = (props) => {
  const {
    pending,
    amount0,
    amount1,
    currency0,
    currency1,
    link,

    modalRef,
    txError,

    closeModal,
    closePendingModal,
    closeSwapSectionModal,
    currencyToAdd,
  } = props

  const { chainId } = useWSCContext()
  const { explorerURL } = useExplorerInfo()
  const [nativeTokenSymbol, setNativeTokenSymbol] = useState<any>(undefined)

  useEffect(() => {
    const nativeToken = NATIVE_SYMBOL[chainId]
    setNativeTokenSymbol(nativeToken)

    return () => {
      setNativeTokenSymbol(undefined)
    }
  }, [chainId])

  return (
    <Body ref={modalRef} onClick={closeModal}>
      <Cover>
        <ModalClose onClick={closePendingModal} />

        {txError ? (
          <>
            <SwapWrap>
              <UserRejectedCover closeConfirmationModal={closePendingModal} />
            </SwapWrap>
          </>
        ) : (
          <>
            {pending ? (
              <>
                {" "}
                <SwapPending>
                  <SpinnerCombined />
                  <SwapTitle>Waiting For Confirmation</SwapTitle>
                  <SwapTrade>
                    Swapping {""}
                    {toSignificantDigits(amount0, 4, true, 18)} {""}
                    {currency0} {""}for {""}
                    {toSignificantDigits(amount1, 4, true, 18)} {""}
                    {currency1}
                  </SwapTrade>
                  <SwapConfirmSmall>
                    Confirm this transaction in your wallet
                  </SwapConfirmSmall>
                </SwapPending>
              </>
            ) : (
              <>
                <SwapWrap>
                  <SwapIcon>
                    <SwapArrow />
                  </SwapIcon>

                  <SwapText>
                    {currency0} to {currency1} transaction submitted
                  </SwapText>
                  <WrapButton onClick={closeSwapSectionModal}>Close</WrapButton>

                  <a
                    className="swapaddwrap"
                    href={`${explorerURL?.explorer}/tx/${link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SwapAddToken>View transaction</SwapAddToken>
                  </a>
                </SwapWrap>
              </>
            )}
          </>
        )}

        {/* pending conditional rendering ends */}
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 260;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
`
const Cover = styled.div`
  position: relative;
  max-width: 490px;
  width: 100%;

  margin: 24px auto;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${TangleColors.white};
  justify-content: center;
  border: 1px solid ${TangleColors.lighthover};
  .swapaddwrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const SwapWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${TangleColors.white};
`
const SwapIcon = styled.div`
  height: 72px;
  max-width: 72px;
  width: 100%;
  border-radius: 9999px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${TangleColors.lighthover};
`
const SwapArrow = styled(AiOutlineArrowUp)`
  color: ${TangleColors.lighthover};
  height: 40px;
  width: 40px;
`
const SwapText = styled(Header7)`
  color: ${TangleColors.white};
`
const SwapAddToken = styled(AddTokenModalButton)`
  margin: 16px 0 24px 0;
`
const SwapAddMetamaskToken = styled(AddTokenMetamaskModalButton)`
  margin: 16px 0 0 0;
`
const WrapButton = styled(ModalButton)`
  margin: 40px 0;
`
const SwapPending = styled.div`
  display: flex;
  color: ${TangleColors.white};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
`

const SwapErrorIcon = styled.div`
  margin: 24px 0;
`

const SwapWarningError = styled(CiWarning)`
  max-width: 150px;
  height: 120px;
  width: 100%;
  margin: 0 auto;
  color: ${TangleColors.red};
`

const SwapTitle = styled(Caption)`
  margin: 40px 0 8px 0;
  color: ${TangleColors.lighthover};
`
const SwapTrade = styled(Header5)`
  margin: 8px 0;
  text-align: center;
  color: ${TangleColors.white};
`

const SwapConfirmSmall = styled(Caption)`
  margin: 8px 0;
  color: ${TangleColors.grayLight};
`
const ModalClose = styled(GrClose)`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 24px;
  right: 30px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

const CheckMark = styled(FiCheckCircle)`
  height: 18px;
  width: 18px;
  margin: 0 0 0 6px;
  color: ${TangleColors.tangleGreen};
  /* path {
    color: ${TangleColors.tangleGreen};
    stroke: ${TangleColors.tangleGreen};
    fill: ${TangleColors.tangleGreen};
  } */
`
const MetamaskLogo = styled.img`
  height: 18px;
  width: 18px;
  margin: 0;
  object-fit: contain;
`

const MetaMaskWrapper = styled.div`
  height: 18px;
  width: 18px;
  margin: -4px 0 0 12px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export default SwapConfirmationModal
