import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmallRegular, Header5, Header7 } from "styles/TextStyles"
import { AuctionBackButton, InvestHubNextButton } from "styles/ButtonStyles"

interface FormProps {
  formError?: boolean
  active?: boolean
  disabled?: boolean
}

export const StepCounter = styled.div<FormProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) =>
    props.active ? TangleColors.cta : TangleColors.darkText};
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.lighterText};
  font-size: 16px;
  font-weight: 500;
  margin-right: 16px;
`

export const ItemBody = styled.div<FormProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.active ? "flex-start" : "center")};
  .feeTierGroup {
    max-width: 264px !important;
    width: 100% !important;
  }
  .feeTierCover {
    padding: 0 3px 0 6px;
  }
  .feeTierCurrency {
    padding: 6px 8px;
  }
  .feeTierTextGroup {
    padding: 2px 6px;

    :nth-child(3) {
      padding: 2px 16px !important;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`

export const CounterColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemTitle = styled(Header5)`
  color: ${TangleColors.lighthover};
`

export const ItemSubTitle = styled(CaptionSmallRegular)`
  color: ${TangleColors.grayDark};
  margin: 4px 0 0 0;
`

export const NextButton = styled(InvestHubNextButton)<FormProps>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

export const BackButton = styled(AuctionBackButton)``

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
`

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  max-width: 600px;
  .react-datepicker-popper {
    position: relative;
    z-index: 901;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 0 !important;
    border-radius: 12px;
  }
`

export const InputLabel = styled(Header7)`
  color: ${TangleColors.lighterText};
  margin: 12px 0 4px 0;
  width: 100%;
`

export const Input = styled.input<FormProps>`
  background: ${TangleColors.modalBG};
  border: ${(props) =>
    props.formError
      ? ` 0.4px solid ${TangleColors.red}`
      : ` 0.5px solid ${TangleColors.grayLight}`};
  border-radius: 8px;
  padding: 14px 24px;
  color: ${TangleColors.offWhite};
  font-size: 16px;
  width: 100%;
  font-weight: 500;
  transition: 0.2s ease-in;
  position: relative;
  z-index: 2;

  &:focus {
    outline: none;
    border: 1px solid ${TangleColors.lighthover};
  }
  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.lighthover};
    border: none;
  }
`

export const FormRadioInput = styled.input`
  background: transparent;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`

export const InputSubLabel = styled(CaptionSmallRegular)<FormProps>`
  color: ${(props) =>
    props.formError ? `${TangleColors.red}` : `${TangleColors.grayDark}`};
`

export const InputError = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${TangleColors.red};
`
