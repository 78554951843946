import styled from "styled-components"

import PorfolioContainer from "components/portfolio/PorfolioContainer"
import AccountLayout from "container/AccountLayout"

const PortfolioOverviewContainer = () => {
  return (
    <AccountLayout>
      <Body>
        <PorfolioContainer />
      </Body>
    </AccountLayout>
  )
}

const Body = styled.div`
  /* min-height: 100vh; */
`
export default PortfolioOverviewContainer
