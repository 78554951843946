import { useCallback } from "react"
import {
  leftRangeTypeInput,
  rightRangeTypeInput,
} from "store/actions/RangeActions"
import { useAppDispatch } from "store/hooks"

export function useRangeLiquidityActionHandlers(): {
  onLeftRangeInput: (value: any) => void
  onRightRangeInput: (value: any) => void
} {
  const dispatch = useAppDispatch()

  const onLeftRangeInput = useCallback(
    (value: any) => {
      dispatch(leftRangeTypeInput(value))
    },
    [dispatch]
  )
  const onRightRangeInput = useCallback(
    (value: any) => {
      dispatch(rightRangeTypeInput(value))
    },
    [dispatch]
  )

  return {
    onLeftRangeInput,
    onRightRangeInput,
  }
}
