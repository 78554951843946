import React, { FC, ReactNode } from "react"
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body3 } from "styles/TextStyles"
import { Rangeinput } from "./Rangeinput"
interface StepCounterProps {
  value?: any
  decrement?: () => void
  increment?: () => void
  feeAmount?: any
  label?: string
  width?: string
  title?: ReactNode
  disable?: boolean
  tokenA?: string | undefined
  tokenB?: string | undefined
  right?: boolean
  left?: boolean
  name?: any
  onUserInput?: (value: any) => void
  placeholder?: string
  active?: boolean
  handleOnFocus?: () => void
  handleOnBlur?: () => void
}

const RangeSelector: FC<StepCounterProps> = (props) => {
  const {
    title,
    right,
    left,
    value,
    name,
    onUserInput,
    tokenB,
    tokenA,
    decrement,
    increment,
    placeholder,
    handleOnFocus,
    handleOnBlur,
    disable,
    active,
  } = props

  return (
    <RangeBody
      left={left}
      right={right}
      active={active}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
    >
      <RangeTitle>{title}</RangeTitle>
      <RangeRow>
        <RangeActionWrapper onClick={decrement}>
          <RangeDecrement />
        </RangeActionWrapper>
        <Rangeinput
          onUserInput={onUserInput}
          name={name}
          id={name}
          value={value}
          placeholder={placeholder}
          disable={disable}
        />
        <RangeActionWrapper onClick={increment}>
          <RangeIncrement />
        </RangeActionWrapper>
      </RangeRow>
      <RangeValue>
        {tokenB} per {tokenA}
      </RangeValue>
    </RangeBody>
  )
}

const RangeBody = styled.div<StepCounterProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  max-width: 240px;
  width: 100%;
  height: 140px;
  padding: 10px;
  margin: 8px auto;
  border-radius: 24px;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
  border: ${(props) =>
    props.active
      ? `1.21px solid ${TangleColors.cta}`
      : `1px solid ${TangleColors.grayLight}`};
  margin-left: ${(props) => props.left && "6px"};
  margin-right: ${(props) => props.right && "6px"};
  transition: 0.4s ease-in;
  /* :hover {
    border: 0.21px solid ${TangleColors.cta};
  } */
  @media only screen and (max-width: 900px) {
    margin-left: 0px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-right: 0px;
  }
`
const RangeTitle = styled(Body3)`
  display: flex;
  flex-direction: row;
  color: ${TangleColors.grayDark};
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 100%;
  margin: 16px 0 0 0;
`
const RangeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`
const RangeActionWrapper = styled.div`
  height: 30px;
  max-width: 30px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${TangleColors.white};
  background: ${TangleColors.tangleBlacker};
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.tangleBlack};
  }
`
const RangeDecrement = styled(AiOutlineMinus)`
  cursor: pointer;
`

const RangeIncrement = styled(AiOutlinePlus)`
  cursor: pointer;
`

const RangeValue = styled(Body3)`
  display: flex;
  flex-direction: row;
  color: ${TangleColors.white};
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  margin: 0 0 16px 0;
`

export default RangeSelector
