import { TOKEN_DECIMAL } from "store/actions/actionsTypes"
import { updateObject } from "../utility"
export interface TokenDecimalList {
  tokenListDecimals: any
}

const initialState: TokenDecimalList = {
  tokenListDecimals: null,
}

const tokenDecimalListSuccess = (state, action) => {
  return updateObject(state, {
    tokenListDecimals: action.tokenListDecimals,
  })
}

const TokenDecimalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOKEN_DECIMAL:
      return tokenDecimalListSuccess(state, action)
    default:
      return state
  }
}

export default TokenDecimalReducer
