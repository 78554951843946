import { UPDATE_STAKING_AGREEMENT } from "store/actions/actionsTypes"
import { updateObject } from "store/utility"

export interface AutionToggleProps {
  tangleStakingStatus: any
}
const initialState: AutionToggleProps = {
  tangleStakingStatus: null,
}

const updateStakingToggleStatus = (state: any, action: any) => {
  return updateObject(state, {
    tangleStakingStatus: action.tangleStakingStatus,
  })
}

const StakingToggleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_STAKING_AGREEMENT:
      return updateStakingToggleStatus(state, action)
    default:
      return state
  }
}

export default StakingToggleReducer
