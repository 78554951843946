import {
  IFarmData,
  ILaunchPadData,
  IWrapData,
  IWrapMultipleData,
} from "interfaces/wscSwap.interface"
import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"

export const useBackup = (
  steps: any,
  actionType: string,
  setCurrentStep: (step: number) => void,
  setSubStep?: (step: number) => void
) => {
  const [data, setData] = useState<
    IWrapData | IWrapMultipleData | IFarmData | ILaunchPadData
  >(null)
  const [backup, setBackup] = useState<boolean>(false)

  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )

  useEffect(() => {
    if (
      backup ||
      steps.length === 0 ||
      !tangleProgress ||
      tangleProgress.actionType !== actionType
    )
      return

    const matchingStep = steps.find(
      (step) => step.title === tangleProgress.stepTitle
    )
    if (matchingStep) {
      if (tangleProgress.completed) {
        setCurrentStep(matchingStep?.number + 1)
      } else {
        setCurrentStep(matchingStep?.number)
      }
    }

    if (!matchingStep && (tangleProgress.stepTitle === "Approve" || tangleProgress.stepTitle === "GasWrap")) {
      const transactStep = steps.find((step) => step.title === "Transact")
      console.log(
        "Backup step was finished, so it can't be found anymore. Skipping"
      )
      setCurrentStep(transactStep?.number)
    }

    setSubStep && setSubStep(tangleProgress?.subStep)
    setData(tangleProgress?.data)
    setBackup(true)
  }, [steps])

  // Return what's needed by the components
  return { data, backup }
}
