import { AuctionStatus } from "components/LaunchPad/types"
import { formatUnits } from "ethers/lib/utils"

export const ONE_DAY = 24 * 60 * 60

export enum IHubTokenFactoryTemplateId {
  fixed = 1,
  mintable = 2,
}

export enum MarketTemplateId {
  batch = 1,
  crowdsale = 2,
  dutch = 3,
  hyperbolic = 4,
}

export const HIGHLIGHTED_AUCTIONS = [
  "0x6Bd4B1e63DFd56fd3a1E7Afe7D97Ab7d99C8B589",
  "0xA4977c54a2D402fcd8AE3475dc767c3069427A2A",
  "0xA21747cf51250FF1Af1e913E5226fD3cce6E56d0",
  "0xD1b7F68cdf056830bD59B7eF995D353A052c95bC",
]

const formatNumber = (num, dec) =>
  // parseFloat(formatUnits(num, dec))
  num / 10 ** dec

export const populateMarketData = (rawMarkets, currentMarkets?) => {
  if (
    !rawMarkets ||
    Object.values(rawMarkets).some(
      (value) => value === null || Number.isNaN(value)
    ) ||
    !Object.values(rawMarkets).length
  )
    return currentMarkets

  const rawMarketsArr = !rawMarkets?.length ? [rawMarkets] : rawMarkets
  const currentTime = Math.floor(Date.now() / 1000)

  const markets = rawMarketsArr.map((m) => {
    if (!m) return null

    const auctionAddress = m.auctionAddress
    const auctionType = m.auctionType

    const startTime = Number(m.startTime)
    const endTime = Number(m.endTime)

    const payDecimals = Number(m.paymentCurrencyInfo[1])
    const tokenDecimals = Number(m.tokenInfo[1])
    const commitments = formatNumber(m.commitmentsTotal, payDecimals)
    const tokensForSale = formatNumber(m.totalTokens, tokenDecimals)

    const rate = m?.rate ? formatNumber(m?.rate, payDecimals) : null
    const goal = m?.goal ? formatNumber(m?.goal, payDecimals) : null
    const startPrice = m?.startPrice
      ? formatNumber(m?.startPrice, payDecimals)
      : null
    const minimumPrice = m?.minimumPrice
      ? formatNumber(m?.minimumPrice, payDecimals)
      : null
    const minimumCommitmentAmount = m?.minimumCommitmentAmount
      ? formatNumber(m?.minimumCommitmentAmount, payDecimals)
      : null

    // @dev: if "tokensRemaining === 0", the Auction is actually ended (even though the endTime is not
    //       reached yet), so we update the variables below accordingly with a 2nd "for loop" iteration.
    let auctionStatus,
      currentProgress,
      currentAuctionPrice,
      currentTokenPrice,
      tokensRemaining

    for (let i = 0; i < 2; i++) {
      auctionStatus = m.finalized
        ? AuctionStatus.FINISHED
        : currentTime < startTime
        ? AuctionStatus.UPCOMING
        : currentTime >= startTime && currentTime <= endTime && i === 0
        ? AuctionStatus.LIVE
        : AuctionStatus.FINISHED

      currentProgress =
        currentTime < startTime
          ? 0
          : currentTime > endTime || i === 1
          ? 1
          : (currentTime - startTime) / (endTime - startTime)

      currentAuctionPrice =
        auctionType === "crowdsale"
          ? rate
          : auctionType === "dutch" && i === 0
          ? startPrice + currentProgress * (minimumPrice - startPrice)
          : auctionType === "dutch" && i === 1
          ? commitments / tokensForSale
          : auctionType === "batch"
          ? commitments / tokensForSale
          : null

      currentTokenPrice =
        auctionType === "crowdsale" || auctionType === "batch"
          ? currentAuctionPrice
          : auctionType === "dutch"
          ? commitments / tokensForSale
          : null

      tokensRemaining =
        auctionType === "batch"
          ? auctionStatus === AuctionStatus.LIVE
            ? 1
            : 0
          : auctionType === "dutch" && i === 0
          ? currentAuctionPrice / tokensForSale > 0
            ? Math.max(0, 1 - commitments / currentAuctionPrice / tokensForSale)
            : 1
          : auctionType === "crowdsale" && i === 0
          ? 1 - commitments / (rate * tokensForSale)
          : 0

      if (tokensRemaining > 0 || auctionStatus === AuctionStatus.FINISHED) break
    }

    const formattedAuctionToken = {
      addr: m.tokenInfo?.[0],
      decimals: m.tokenInfo?.[1],
      name: m.tokenInfo?.[2],
      symbol: m.tokenInfo?.[3],
    }

    const formattedPaymentToken = {
      addr: m.paymentCurrencyInfo?.[0],
      decimals: m.paymentCurrencyInfo?.[1],
      name: m.paymentCurrencyInfo?.[2],
      symbol: m.paymentCurrencyInfo?.[3],
    }

    const auctionObject = {
      auctionAddress,
      auctionType,
      auctionStatus,
      currentProgress,
      category: m.category,
      startTime,
      endTime,
      postAuctionLiqLauncher: m.postAuctionLiqLauncher,
      liquidityNftId: m.liquidityNftId, // TODO: verify no Number() needed here
      auctionToken: formattedAuctionToken,
      paymentToken: formattedPaymentToken,
      currentTokenPrice,
      currentAuctionPrice,
      tokensForSale,
      totalCommitments: commitments,
      tokensRemaining,
      isPurplelistEnabled: m.usePointList,
      isFinalized: m.finalized,
      isSuccessful: m.auctionSuccessful,
      documents: m.documents,
      startPrice, // only on Dutch auctions
      minimumPrice, // only on Dutch auctions
      rate, // only on Crowdsale auctions
      goal, // only on Crowdsale auctions
      minimumCommitmentAmount, // only on Batch auctions
      // // alpha: info?.alpha, // only on Hyperbolic auctions - DO NOT DELETE!
    }

    return auctionObject
  })

  return !rawMarkets?.length ? markets[0] : markets.filter((m) => m !== null)
}
