import React, { FC } from "react"
import Confetti from "react-confetti"
import styled from "styled-components"

interface ConfettiProps {
  showFinalize?: boolean
}
const AuctionDetailsConfetti: FC<ConfettiProps> = (props) => {
  const { showFinalize } = props

  return (
    <>
      {showFinalize ? (
        <>
          <ConfettiBackground>
            <Confetti />
          </ConfettiBackground>
        </>
      ) : null}
    </>
  )
}

const ConfettiBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 30;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.02
  ); // Semi-transparent fallback for browsers without backdrop-filter support
`

export default AuctionDetailsConfetti
