import React, { FC, useRef } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { AiOutlineInfoCircle } from "react-icons/ai"
import TangleToggle from "components/utils/Toggle"
import swapSound from "assets/effect.mp3"
import { Activity } from "iconsax-react"
import Tooltip from "components/utils/Tooltip"
import { useAppDispatch, useAppSelector } from "store/hooks"

import { CancelTransactionButton } from "styles/ButtonStyles"

import {
  updateTXEffects,
  updateTransactionsMode,
} from "store/actions/CardanoSettingsActions"
interface ModalSettingsProps {
  modalOpen?: boolean
  cancelTransaction?: () => void
}

const ModalSettings: FC<ModalSettingsProps> = (props) => {
  const { modalOpen, cancelTransaction } = props

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )
  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const modalRef = useRef<any>(null)
  const dispatch = useAppDispatch()

  const toggleOffExpertMode = (e?: any) => {
    e.preventDefault()

    dispatch(updateTransactionsMode(false))
  }
  const toggleOnExpertMode = (e?: any) => {
    e.preventDefault()
    dispatch(updateTransactionsMode(true))
  }
  const playSound = () => {
    const audio = new Audio(swapSound)
    audio.play().catch((e) => console.error("Error playing sound:", e))
  }

  const toggleEffectOffExpertMode = (e?: any) => {
    e.preventDefault()
    playSound()

    dispatch(updateTXEffects(false))
  }
  const toggleEffectOnExpertMode = (e?: any) => {
    e.preventDefault()
    playSound()

    dispatch(updateTXEffects(true))
  }

  return (
    <>
      {modalOpen ? (
        <>
          <ModalSettingsWrap ref={modalRef}>
            <ModalSettingsCover>
              <SettingTitleRow>
                {" "}
                <SettingsInfoIcon size={26} />
                <SettingsTitleText>Automate your experience</SettingsTitleText>
              </SettingTitleRow>
              <SettingsInfoRow>
                <ModalSettingsRow>
                  <ModalSettingsTitle>
                    Automatic Transactions
                    <Tooltip
                      direction="left"
                      text={
                        <span>
                          Automatically request your next signature after the
                          previous
                          <DocLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.tangleswap.exchange/dapps/special/wrapped-contracts.md"
                          >
                            WSC transaction
                          </DocLink>
                          is complete.
                        </span>
                      }
                    >
                      <HelperCover>
                        <HelpIcon />
                      </HelperCover>
                    </Tooltip>
                  </ModalSettingsTitle>
                  <ToggleCover>
                    {" "}
                    <TangleToggle
                      disabled={true}
                      // isToggled={Boolean(tangleSwapTransactions)}
                      isToggled={false}
                      toggleOnButton={toggleOnExpertMode}
                      toggleOffButton={toggleOffExpertMode}
                    />
                  </ToggleCover>
                </ModalSettingsRow>
                <ModalSettingsRow>
                  <ModalSettingsTitle>
                    Tx Sound Effect
                    <Tooltip
                      direction="left"
                      text={
                        <span>
                          Play a notification sound once your
                          <DocLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://docs.tangleswap.exchange/dapps/special/wrapped-contracts.md"
                          >
                            WSC transaction
                          </DocLink>
                          is complete.
                        </span>
                      }
                    >
                      <HelperCover>
                        {" "}
                        <HelpIcon />
                      </HelperCover>
                    </Tooltip>
                  </ModalSettingsTitle>
                  <ToggleCover>
                    <TangleToggle
                      isToggled={Boolean(tangleSwapTXEffects)}
                      toggleOnButton={toggleEffectOnExpertMode}
                      toggleOffButton={toggleEffectOffExpertMode}
                    />
                  </ToggleCover>
                </ModalSettingsRow>
              </SettingsInfoRow>
              <CompletedBottom>
                <ProgressContinueButtonCover onClick={cancelTransaction}>
                  <ProgressMainButton>
                    Cancel This Transaction
                  </ProgressMainButton>
                </ProgressContinueButtonCover>
              </CompletedBottom>
            </ModalSettingsCover>
          </ModalSettingsWrap>
        </>
      ) : null}
    </>
  )
}

const ModalSettingsTitle = styled(CaptionSmall)`
  text-align: left;
  width: 100%;
  color: ${TangleColors.grayLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  width: 100%;
  position: relative;
  white-space: nowrap;
  z-index: 820;
`

const ModalSettingsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 56px;
  left: -48px;
  width: 280px;
  min-height: 180px;
  border-radius: 12px;
  padding: 12px 8px;
`
const ModalSettingsRow = styled.div`
  width: 100%;
  margin: 2px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 0;
`
const SettingTitleRow = styled.div`
  width: 100%;
  margin: 2px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 6px 0;
`
const SettingsInfoRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
`

const ModalSettingsCover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
  justify-content: flex-start;
  width: 280px;
  min-height: 180px;
  padding: 12px;
  border-radius: 24px;
  border: 1px solid ${TangleColors.lighthover};
  /* background: ${TangleColors.modalBG}; */
  background: ${TangleColors.gridBG6};
`

const HelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  margin: 6px 0 0 2px;
  width: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const SettingsTitleText = styled(Caption)`
  color: ${TangleColors.lighthover};
`

const SettingsInfoIcon = styled(Activity)`
  width: 26px;
  height: 26px;
  color: ${TangleColors.lighthover};
  margin: 0 6px 0 0;
`

const DocLink = styled.a`
  color: ${TangleColors.lighthover};
  margin: 0 3px;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lightPurple};
  }
`

const ToggleCover = styled.div`
  margin: 0 0 0 1px;
`
const HelperCover = styled.div`
  margin: 1px 0 0 4px;
`
const CompletedBottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 6px 0;
`
const ProgressContinueButtonCover = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 350px;
`
const ProgressMainButton = styled(CancelTransactionButton)`
  margin: 0;
`
export default ModalSettings
