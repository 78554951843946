import React, { FC, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall, Subtitle } from "styles/TextStyles"

import { GrClose } from "react-icons/gr"
import { SwapTokenButton } from "styles/ButtonStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

import AgreementCheckbox from "./AgreementCheckbox"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import AuctionHoverBox from "components/utils/AuctionHoverBox"
import { useAppSelector } from "store/hooks"
import UserRejectedCover from "components/utils/UserRejectedCover"

interface RowsProps {
  inputValue?: any
  inputSymbol?: any
  inputLogo?: any
  outputValue?: any
  outputSymbol?: any
  outputLogo?: any
  closeRemoveModal?: () => void
  clickParticipate?: () => void
  lpModalRef?: any
  lpBodyClick?: (e: any) => void
  modalTXError?: boolean
}
const AuctionParticipateModal: FC<RowsProps> = (props) => {
  const {
    closeRemoveModal,
    clickParticipate,
    inputValue,
    inputSymbol,
    inputLogo,
    outputValue,
    outputSymbol,
    lpModalRef,
    outputLogo,
    lpBodyClick,
    modalTXError,
  } = props
  const [agreementClicked, setAgreementClicked] = useState<boolean>(false)
  const [showModalInfo, setShowModalInfo] = useState<boolean>(false)
  const checkAgreement = useAppSelector(
    (state) => state.AuctionToggle.tangleAuctionStatus
  )

  const clickCheckbox = () => {
    setAgreementClicked(!agreementClicked)
  }
  const mouseEnter = () => {
    setShowModalInfo(true)
  }

  const mouseLeave = () => {
    setShowModalInfo(false)
  }
  return (
    <Body ref={lpModalRef} onClick={lpBodyClick}>
      {modalTXError ? (
        <>
          <Cover>
            <UserRejectedCover closeConfirmationModal={closeRemoveModal} />
          </Cover>
        </>
      ) : (
        <>
          {" "}
          <Cover>
            <LPNavigation>
              <IconText> Participate in Auction</IconText>
              <CloseIconWrapper onClick={closeRemoveModal}>
                {" "}
                <CloseIcon />
              </CloseIconWrapper>
            </LPNavigation>

            <InfoTierTop>
              {/* start of  box */}
              <LiquidityBox>
                <BoxBottom>
                  <BowFirstRow>
                    <LPBoxValue>You commit:</LPBoxValue>
                    <IconWrap>
                      <IconName>{inputValue}</IconName>

                      {!!inputLogo ? (
                        <>
                          <BoxIcon src={inputLogo} />
                        </>
                      ) : (
                        <>
                          {" "}
                          <CustomSizeWrapper>
                            <ConvertTokenSymbol text={inputSymbol} />
                          </CustomSizeWrapper>
                        </>
                      )}
                      <IconName>{inputSymbol}</IconName>
                    </IconWrap>
                  </BowFirstRow>

                  <BowSecondRow>
                    <LPBoxValue>Tokens at current rate:</LPBoxValue>
                    <IconWrap>
                      <IconName>{formatNumber(outputValue, 7)}</IconName>

                      {!!outputLogo ? (
                        <>
                          <BoxIcon src={outputLogo} />
                        </>
                      ) : (
                        <>
                          {" "}
                          <CustomSizeWrapper>
                            <ConvertTokenSymbol text={outputSymbol} />
                          </CustomSizeWrapper>
                        </>
                      )}
                      <IconName>{outputSymbol}</IconName>
                    </IconWrap>
                  </BowSecondRow>
                </BoxBottom>
              </LiquidityBox>
            </InfoTierTop>
            {!checkAgreement ? (
              <>
                {" "}
                <AgreementCover htmlFor="checkbox" onClick={clickCheckbox}>
                  <AgreementCheckbox
                    onClick={clickCheckbox}
                    checked={agreementClicked}
                  />
                  <CollectFeesText>
                    Agreed to{" "}
                    <a onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                      market participation agreement{" "}
                      {showModalInfo ? (
                        <>
                          {" "}
                          <AuctionHoverBox
                            left={-30}
                            top={30}
                            text="I understand that I am interacting with a smart contract. I understand that tokens commited are subject to the token issuer and local laws where applicable. I have reviewed the code of this smart contract and understand it fully. I agree to not hold developers or other people associated with the project liable for any losses or misunderstandings."
                          />
                        </>
                      ) : null}
                    </a>
                  </CollectFeesText>
                </AgreementCover>
              </>
            ) : null}

            <CollectFeesWrapper>
              <CommittingText>
                Once comitted, you can claim your tokens after the auction ends.
              </CommittingText>
              <SwapButtons onClick={clickParticipate}>
                <SwapButton disabled={!checkAgreement && !agreementClicked}>
                  Confirm Participation
                </SwapButton>
              </SwapButtons>
            </CollectFeesWrapper>
          </Cover>
        </>
      )}
    </Body>
  )
}

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 260;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 24px;
`
const Cover = styled.div`
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 40px;
  padding: 20px;
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${TangleColors.lighthover};
  position: relative;
`
const CloseIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
`
const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 4px 0;
  justify-content: center;
  align-items: center;
`

const IconText = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
  transition: 0.4s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const InfoTierTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const LiquidityBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
`

const BoxBottom = styled.div`
  max-width: 680px;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 24px 24px 26px 24px;
  width: 100%;
  margin: 24px auto;
`
const BowFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2px 0 16px 0;
`

const BowSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
`

const BoxIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`
const LPBoxValue = styled.div`
  margin: 0;
`

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`
const IconName = styled(Caption)`
  margin: 0 8px;
  color: ${TangleColors.white};
`

const SwapButtons = styled.div`
  width: 100%;
  margin: 8px 0;
`

const SwapButton = styled(SwapTokenButton)``

const CollectFeesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0 8px 0;
`
const CollectFeesText = styled(Caption)`
  color: ${TangleColors.tangleYellow};
  text-align: center;
  margin: 6px 0 0 0;
  a {
    position: relative;
    text-decoration: underline;
    text-decoration-color: ${TangleColors.lighthover};
    padding: 0 0 10px 0;
    -webkit-text-decoration-color: ${TangleColors.lighthover};
  }
`
const CommittingText = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  text-align: center;
  margin: 6px auto 2px auto;
  max-width: 400px;
  width: 100%;
`

const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const AgreementCover = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin: 8px auto;
`

export default React.memo(AuctionParticipateModal)
