import React from "react"

import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
interface LoadingProps {
  walletConnection?: boolean
}
const LoadingDots: React.FC<LoadingProps> = (props) => {
  const { walletConnection } = props
  return (
    <Loader>
      <LoadingText>
        Loading <Dots walletConnection={walletConnection} />
      </LoadingText>
    </Loader>
  )
}

export default LoadingDots

export const Loader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  color: ${TangleColors.grayLight};
  justify-content: center;
  align-items: center;
`

const LoadingText = styled(Subtitle)`
  color: ${TangleColors.grayLight};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

export const Dots = styled.span<LoadingProps>`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 16px;
    text-align: left;
    color: ${(props) =>
      props.walletConnection
        ? `${TangleColors.black}`
        : `${TangleColors.grayLight}`};
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`
