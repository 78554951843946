import { FARM_LIST, FARM_LIST_LOADING } from "store/actions/actionsTypes"
import { updateObject } from "../utility"
export interface FarmData {
  farmList: any
  farmLoading: boolean
}

const initialState: FarmData = {
  farmList: null,
  farmLoading: false,
}

const farmDataSuccess = (state: any, action: any) => {
  return updateObject(state, {
    farmList: action.data,
  })
}

const farmDataLoading = (state: any, action: any) => {
  return updateObject(state, {
    farmLoading: action.data,
  })
}

const farmDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FARM_LIST:
      return farmDataSuccess(state, action)
    case FARM_LIST_LOADING:
      return farmDataLoading(state, action)
    default:
      return state
  }
}

export default farmDataReducer
