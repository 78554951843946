import {
  CHECK_WALLET_OVERVIEW_STEP,
  CHECK_WSC_STEP,
  OPEN_WSC_INTERFACE,
  OPEN_WSC_STEP_MODAL,
  UPDATE_WSC_STEP,
} from "store/actions/actionsTypes"
import { updateObject } from "store/utility"

export interface WSCProgressProps {
  pendingWscTxStep?: any
  wscInterfaceOpen?: boolean
  openWSCModal?: boolean
  tangleswapWalletStep?: any
}

const initialState: WSCProgressProps = {
  pendingWscTxStep: null,
  openWSCModal: false,
  wscInterfaceOpen: false,
  tangleswapWalletStep: null,
}
export const updateWSCProgress = (state, action) => {
  return updateObject(state, {
    pendingWscTxStep: action.pendingWscTxStep,
  })
}
const showWSCInterface = (state, action) => {
  return updateObject(state, {
    wscInterfaceOpen: action.wscInterfaceOpen,
  })
}
const checkWSCProgress = (state, action) => {
  return updateObject(state, {
    pendingWscTxStep: action.pendingWscTxStep,
  })
}
const updateWalletStep = (state, action) => {
  return updateObject(state, {
    tangleswapWalletStep: action.tangleswapWalletStep,
  })
}

const openWSCProgressModal = (state, action) => {
  return updateObject(state, {
    openWSCModal: action.openWSCModal,
  })
}
const WscProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_WSC_STEP:
      return checkWSCProgress(state, action)
    case CHECK_WALLET_OVERVIEW_STEP:
      return updateWalletStep(state, action)
    case UPDATE_WSC_STEP:
      return updateWSCProgress(state, action)
    case OPEN_WSC_STEP_MODAL:
      return openWSCProgressModal(state, action)
    case OPEN_WSC_INTERFACE:
      return showWSCInterface(state, action)

    default:
      return state
  }
}
export default WscProgressReducer
