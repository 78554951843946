import { useWSCContext } from "context/MilkomedaContext"
import { GrConnect } from "react-icons/gr"
import { BiChevronDown } from "react-icons/bi"
import React from "react"

import styled from "styled-components"
import { useIsSupportedNetwork } from "constants/hooks"
import MobileWrongNetwork from "./MobileWrongNetwork"
import MobileAddressCover from "./MobileAddressCover"
import MobileNetworkCover from "./MobileNetworkCover"
import { TangleColors } from "styles/ColorStyles"
import useConnectWallet from "utils/useConnectWallet"

const MobileAccountWrapper = () => {
  const { account } = useWSCContext()

  const { connectWallet: openWalletModal } = useConnectWallet()
  const isSupportedNetwork = useIsSupportedNetwork()

  return (
    <Body>
      {account ? (
        <>
          {isSupportedNetwork ? (
            <MobileBodyCover>
              <MobileNetworkCover />
              <MobileAddressCover />
            </MobileBodyCover>
          ) : (
            <MobileBodyCover>
              <MobileNetworkCover />
              <NetworkWrap>
                <MobileWrongNetwork />
              </NetworkWrap>
            </MobileBodyCover>
          )}
        </>
      ) : (
        <>
          <ConnectWalletCover onClick={() => openWalletModal()}>
            <ConnectIcon />
            <WrongNetworkWappDropDownMenu />
          </ConnectWalletCover>
        </>
      )}
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 800px;
  margin: 0 auto;
`

const MobileBodyCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 800px;
  margin: 0 auto;
`

const NetworkWrap = styled.div`
  position: relative;
  max-width: 340px;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 850px) {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
`

const ConnectWalletCover = styled.div`
  width: 64px;
  height: 48px;
  border-radius: 40px;
  background: ${TangleColors.defi};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${TangleColors.white};
  margin: 0 4px 0 0;
`
const ConnectIcon = styled(GrConnect)`
  width: 28px;
  height: 28px;
  color: ${TangleColors.white};
  margin: 0 2px 0 0 0;
  cursor: pointer;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
`
const WrongNetworkWappDropDownMenu = styled(BiChevronDown)`
  width: 18px;
  height: 18px;
  color: ${TangleColors.white};
  margin: 0;
  cursor: pointer;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
`
export default MobileAccountWrapper
