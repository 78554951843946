import React, { FC } from "react"
import styled from "styled-components"

import { CaptionSmall } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
interface LimitRowProps {
  text?: string
  number?: number
}

const LimitRow: FC<LimitRowProps> = (props) => {
  const { text, number } = props
  return (
    <LimitBody>
      <LimitCheckIconWrapper>
        <LimitCheckIconText>{number}</LimitCheckIconText>
      </LimitCheckIconWrapper>
      <LimitTextWrapper dangerouslySetInnerHTML={{ __html: text }} />
    </LimitBody>
  )
}
const LimitBody = styled.div`
  display: grid;

  grid-template-columns: 40px auto;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 16px 0;
`
const LimitTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${TangleColors.white};
  font-family: "CabinetGrotesk Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  transition: 0.4s ease-in;

  a {
    width: 100%;
    white-space: nowrap;
    max-width: fit-content;
    transition: 0.4s ease-in;
    color: ${TangleColors.lighthover};
    margin: 0 4px 0 0;
    :hover {
      color: ${TangleColors.tangleYellow};
    }
  }
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

const LimitCheckIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 999px;
  background: ${TangleColors.lighthover};
  margin: 0 24px 0 0;
`

const LimitCheckIconText = styled(CaptionSmall)`
  width: 18px;
  height: 18px;
  color: ${TangleColors.black};
  text-align: center;
`
export default LimitRow
