import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { paireEscapeRegExp } from "utils/index"

export const PairInput = React.memo(function PairInput({
  value,
  onUserInput,
  placeholder,
  name,
  ...rest
}: {
  value?: string | number
  name?: string
  onUserInput?: (value: any) => void
  error?: boolean
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">) {
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
  const enforcer = (nextUserInput: string) => {
    if (
      nextUserInput === "" ||
      inputRegex.test(paireEscapeRegExp(nextUserInput))
    ) {
      onUserInput?.(nextUserInput)
    }
  }

  return (
    <>
      <TokenValueInput
        autoComplete="off"
        autoCorrect="off"
        type="text"
        pattern="^[0-9]*[.,]?[0-9]*$"
        placeholder="0.0"
        minLength={1}
        maxLength={79}
        spellCheck="false"
        name={name}
        value={value}
        id={name}
        inputMode="decimal"
        onChange={(event) => {
          // replace commas with periods, because we exclusively uses period as the decimal separator
          enforcer(event.target.value.replace(/,/g, "."))
        }}
      />
    </>
  )
})

const TokenValueInput = styled.input`
  max-width: 1200px;
  height: 56px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  padding: 14px;
  border-radius: 16px;
  color: ${TangleColors.black};
  caret-color: ${TangleColors.black};
  font-family: "DM Sans Regular";
  font-size: 21px;
  font-style: normal;
  background: ${TangleColors.white};
  ::placeholder {
    font-size: 28px;
    color: ${TangleColors.gray};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
