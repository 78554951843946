import { IWrapData, IWrapMultipleData } from "interfaces/wscSwap.interface"
import { FC, useEffect, useRef, useState } from "react"
import {
  ActionButton,
  ActionWrapper,
  ButtonContainer,
  ExternalLinkIcon,
  Link,
} from "components/milkomeda/styles"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import {
  useContractWrite,
  usePrepareContractWrite,
  useProvider,
  useWaitForTransaction,
} from "wagmi"
import { TxStatus } from "constants/milkomeda/transaction"
import { getEvmExplorerUrl } from "utils/milkomeda/transaction"
import { useWSCContext } from "context/MilkomedaContext"
import { TangleColors } from "styles/ColorStyles"
import styled from "styled-components"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import { extractEvmFunctionName } from "@tangleswap/sdk"
import { playSound } from "../component/PlaySound"
import { useEstimateGas } from "hooks/milkomeda/useEstimatedGas"
import { ethers } from "ethers"
import BigNumber from "bignumber.js"

interface IActionExecutionStep {
  nextStep?: () => void
  data?: IWrapData | IWrapMultipleData
  toggleModal?: () => void
  actionType?: string
  isLastStep?: boolean
  isBackup: boolean
  setIsComplete?: (isComplete: boolean) => void
}

export const statusExecuteMessages = {
  [TxStatus.Init]: "Preparing transaction",
  [TxStatus.Pending]: "Executing transaction",
  [TxStatus.Confirmed]: "Your action has been successfully executed!",
}

const ActionExecutionStep: FC<IActionExecutionStep> = (props) => {
  const { nextStep, data, actionType, isLastStep, isBackup, setIsComplete } =
    props

  const { evmFunction, evmFeedback } = data || {}

  const { chainId } = useWSCContext()
  /* const { fee: gasFee } = useEstimateGas() */

  const provider = useProvider()

  const prepareContractWriteQuery = usePrepareContractWrite(evmFunction)

  const contractWriteQuery = useContractWrite(prepareContractWriteQuery.config)

  const waitForTransactionQuery = useWaitForTransaction({
    hash: contractWriteQuery.data?.hash,
    enabled: !!contractWriteQuery.data?.hash,
  })

  const isFunctionError = !evmFunction
  const isIdle = contractWriteQuery.isIdle
  const isLoading =
    contractWriteQuery.isLoading || waitForTransactionQuery.isLoading
  const isSuccess = waitForTransactionQuery.isSuccess
  const isError =
    waitForTransactionQuery.isError ||
    contractWriteQuery.isError ||
    prepareContractWriteQuery.isError
  const txReceipt = waitForTransactionQuery.data

  const dispatch = useAppDispatch()

  // @dev: this is the part that makes the transaction execute automatically if the user has the setting enabled

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (
      tangleSwapTransactions &&
      !autoExecute.current &&
      !isFunctionError &&
      !isLoading
    ) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        executeStep()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions, isFunctionError, isLoading])

  useEffect(() => {
    // if (isSuccess && !isBackup) { // the !isBackup part was causing the "Continue Previous Transaction" to not disappear after a tx was completed
    if (isSuccess) {
      try {
        const stepperParams = {
          completed: !isError,
          stepTitle: "Transact",
          subStep: 0,
          actionType,
          actionSubType: extractEvmFunctionName(data?.evmFunction),
          txHash: contractWriteQuery.data?.hash,
          data,
          timestamp: Math.floor(Number(new Date().getTime()) / 1000),
          isLastStep,
        }

        if (tangleSwapTXEffects) playSound()
        if (isLastStep && setIsComplete) setIsComplete(true)

        dispatch(updateUserWSCProgress(isLastStep ? null : stepperParams))

        if (actionType === "LP")
          evmFeedback.function(evmFeedback.params, txReceipt)
        else if (actionType === "Swap")
          evmFeedback.function(evmFeedback.params, contractWriteQuery.data)
        else evmFeedback.function(evmFeedback.params)
      } catch (error) {
        console.log("error", error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  useEffect(() => {
    if (!contractWriteQuery.isError) return
    console.error(
      `[RPC: ${(provider as any).connection.url}] Error on transact step:`,
      contractWriteQuery.error
    )
  }, [contractWriteQuery.isError])

  const executeStep = () => {
    contractWriteQuery?.write?.()

    const stepperParams = {
      completed: false,
      stepTitle: "Transact",
      subStep: 0,
      actionType,
      actionSubType: extractEvmFunctionName(data?.evmFunction),
      txHash: contractWriteQuery.data?.hash,
      data,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
      isLastStep,
    }
    dispatch(updateUserWSCProgress(stepperParams))
  }

  return (
    <>
      <ActionWrapper>
        {prepareContractWriteQuery.isLoading && (
          <>
            <SpinnerCombined />
            <span>{statusExecuteMessages[TxStatus.Init]}</span>
          </>
        )}
        {isLoading && (
          <>
            <SpinnerCombined />
            <span>{statusExecuteMessages[TxStatus.Pending]}</span>
          </>
        )}
        {(isError || isFunctionError) && (
          <div>
            Oops, something went wrong.{" "}
            {waitForTransactionQuery.error
              ? `Astral Alignment Error: ${waitForTransactionQuery.error.message.slice(
                  0,
                  64
                )}...`
              : ""}{" "}
            {contractWriteQuery.error
              ? `Void Vortex Failure: ${contractWriteQuery.error.message.slice(
                  0,
                  64
                )}...`
              : ""}{" "}
            {prepareContractWriteQuery.error
              ? `Galactic Gateway Glitch: ${prepareContractWriteQuery.error.message.slice(
                  0,
                  64
                )}...`
              : ""}{" "}
          </div>
        )}
        {isSuccess && (
          <Link
            href={`${getEvmExplorerUrl(chainId)}/tx/${
              contractWriteQuery?.data?.hash
            }`}
          >
            {statusExecuteMessages[TxStatus.Confirmed]}
            <ExternalLinkIcon />
          </Link>
        )}
        {isSuccess && !isLastStep && (
          <ButtonContainer>
            <ActionButton onClick={nextStep}>Continue</ActionButton>
          </ButtonContainer>
        )}
        {(isIdle || isError) && (
          <ButtonContainer>
            <ActionButton
              disabled={isFunctionError || isLoading}
              onClick={executeStep}
            >
              Confirm
            </ActionButton>
          </ButtonContainer>
        )}
      </ActionWrapper>
    </>
  )
}

export default ActionExecutionStep
