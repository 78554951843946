import {
  Chains,
  NATIVE_NAME,
  NATIVE_SYMBOL,
  WRAPPED_DECIMALS,
  chainIdsToNames,
  explorerURL,
  defaultProviderURL,
} from "@tangleswap/sdk"

const prefix =
  "https://raw.githubusercontent.com/TangleSwap/assets/main/chains/"
const suffix = "/info/logo.png"
const cardanoLogoURL = prefix + "cardano" + suffix
const cardanoTestLogoURL = prefix + "cardano-testnet" + suffix
const shimmerLogoURL = prefix + "shimmer" + suffix
const shimmerTestLogoURL = prefix + "shimmer-testnet" + suffix
const sepoliaTestLogoURL = prefix + "sepolia-testnet" + suffix

export const SUPPORTED_CHAIN_IDS = Object.values(Chains) as number[]

export const CHAIN_INFO = {
  [Chains.CARDANO]: {
    explorer: explorerURL[Chains.CARDANO],
    label: chainIdsToNames[Chains.CARDANO],
    rpcUrls: defaultProviderURL[Chains.CARDANO],
    logoUrl: cardanoLogoURL,
    nativeCurrency: {
      name: NATIVE_NAME[Chains.CARDANO],
      symbol: NATIVE_SYMBOL[Chains.CARDANO],
      decimals: WRAPPED_DECIMALS[Chains.CARDANO],
    },
  },

  [Chains.CARDANO_TEST]: {
    explorer: explorerURL[Chains.CARDANO_TEST],
    label: chainIdsToNames[Chains.CARDANO_TEST],
    rpcUrls: defaultProviderURL[Chains.CARDANO_TEST],
    logoUrl: cardanoTestLogoURL,
    nativeCurrency: {
      name: NATIVE_NAME[Chains.CARDANO_TEST],
      symbol: NATIVE_SYMBOL[Chains.CARDANO_TEST],
      decimals: WRAPPED_DECIMALS[Chains.CARDANO_TEST],
    },
  },
  [Chains.SHIMMER]: {
    explorer: explorerURL[Chains.SHIMMER],
    label: chainIdsToNames[Chains.SHIMMER],
    // rpcUrls: defaultProviderURL[Chains.SHIMMER],
    rpcUrls: "https://json-rpc.evm.shimmer.network",
    logoUrl: shimmerLogoURL,
    nativeCurrency: {
      name: NATIVE_NAME[Chains.SHIMMER_TEST],
      symbol: NATIVE_SYMBOL[Chains.SHIMMER_TEST],
      decimals: WRAPPED_DECIMALS[Chains.SHIMMER_TEST],
    },
  },
  [Chains.SHIMMER_TEST]: {
    explorer: explorerURL[Chains.SHIMMER_TEST],
    label: chainIdsToNames[Chains.SHIMMER_TEST],
    rpcUrls: defaultProviderURL[Chains.SHIMMER_TEST],
    logoUrl: shimmerTestLogoURL,
    nativeCurrency: {
      name: NATIVE_NAME[Chains.SHIMMER_TEST],
      symbol: NATIVE_SYMBOL[Chains.SHIMMER_TEST],
      decimals: WRAPPED_DECIMALS[Chains.SHIMMER_TEST],
    },
  },
  [Chains.SEPOLIA_TEST]: {
    rpcUrls: defaultProviderURL[Chains.SEPOLIA_TEST],
    explorer: explorerURL[Chains.SEPOLIA_TEST],
    label: chainIdsToNames[Chains.SEPOLIA_TEST],
    logoUrl: sepoliaTestLogoURL,
    nativeCurrency: {
      name: NATIVE_NAME[Chains.SEPOLIA_TEST],
      symbol: NATIVE_SYMBOL[Chains.SEPOLIA_TEST],
      decimals: WRAPPED_DECIMALS[Chains.SEPOLIA_TEST],
    },
  },
}

export function getChainInfo(chainId: any): any {
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined
  }
  return undefined
}
