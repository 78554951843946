import { Chains, Tangleship, sqrtPriceToPrice, wrapAddr } from "@tangleswap/sdk"
import { SLOT0_PRICE, TOKEN_BALANCE } from "./actionsTypes"
import {
  EVM_ESTIMATED_FEE,
  REQUIRED_FEE_ADA,
} from "constants/milkomeda/transaction"

export const tokenBalanceSuccess = (data: any) => {
  return {
    type: TOKEN_BALANCE,
    data,
  }
}

export const slot0PriceSuccess = (data: any) => {
  return {
    type: SLOT0_PRICE,
    data,
  }
}

export let unsubscribeBalanceFn: Function[] = [] // Store unsubscribe functions
let tokenBalancesState: { [address: string]: string } = {} // Initialize empty state
let cacheBal = { chain: null, acc: null, tokensLength: null }

export const fetchTokenBalance = (
  chainId: any,
  account: any,
  tangleship: Tangleship,
  tangleswapTokenListOnChain: any,
  isWSCConnected: boolean,
  originTokens?: any,
  destinationTokens?: any,
  destinationBalanceADA?: any
) => {
  return (dispatch: any) => {
    const isFetching =
      originTokens?.fetchStatus === "fetching" ||
      destinationTokens?.fetchStatus === "fetching" ||
      destinationBalanceADA?.fetchStatus === "fetching"

    const isCached =
      !isWSCConnected &&
      cacheBal.chain === chainId &&
      cacheBal.acc === account &&
      cacheBal.tokensLength === tangleswapTokenListOnChain?.length

    if (!account || !chainId || isCached || isFetching) return

    // Clear previous state
    tokenBalancesState = {}

    // Unsubscribe from previous subscriptions
    unsubscribeBalanceFn.forEach((fn) => fn())
    unsubscribeBalanceFn = []
    cacheBal = {
      chain: chainId,
      acc: account,
      tokensLength: tangleswapTokenListOnChain?.length,
    }

    // Re-subscribe and maintain state
    tangleswapTokenListOnChain.forEach(async (tokenInfo: any) => {
      if (tokenInfo.chainId === chainId) {
        if (isWSCConnected) {
          if (originTokens && (destinationTokens || destinationBalanceADA)) {
            const tokenAddress = tokenInfo?.address?.toLowerCase()
            const l1Addr = tokenInfo?.l1Address?.toLowerCase()
            const isAda = l1Addr === "ada"
            const l1Address = isAda ? "lovelace" : l1Addr

            const originBalance = originTokens?.data?.find((tok) =>
              tok.unit?.startsWith(l1Address)
            )?.quantity

            const destBalance = destinationTokens?.data?.find(
              (tok) => tok?.contractAddress?.toLowerCase() === tokenAddress
            )?.balance

            let originBal =
              Number(originBalance || 0) / 10 ** tokenInfo.l1Decimals
            if (isAda) originBal = Math.max(0, originBal - REQUIRED_FEE_ADA)

            const destBal = isAda
              ? Math.max(0, destinationBalanceADA?.data - EVM_ESTIMATED_FEE)
              : Number(destBalance || 0) / 10 ** tokenInfo.decimals

            const maxBalance = Math.max(originBal, destBal)

            const updatedTokenBalances = {
              ...tokenBalancesState,
              [tokenAddress]: maxBalance,
            }
            tokenBalancesState = updatedTokenBalances

            dispatch(tokenBalanceSuccess(tokenBalancesState))
          }
        } else {
          const tokenAddress = tokenInfo.address.toLowerCase()
          const l1Addr = isWSCConnected
            ? tokenInfo.l1Address
            : tokenInfo.address
          const unsubscribe = await tangleship?.subscribeTokenBalance(
            account,
            l1Addr,
            (balance: string) => {
              const updatedTokenBalances = {
                ...tokenBalancesState,
                [tokenAddress]: balance,
              }
              tokenBalancesState = updatedTokenBalances

              dispatch(tokenBalanceSuccess(tokenBalancesState))
            }
          )

          if (!!unsubscribe) unsubscribeBalanceFn.push(unsubscribe)
        }
      }
    })
  }
}

// export const fetchTokenBalanceOnL2Only = (
//   chainId: any,
//   account: any,
//   tangleship: Tangleship
// ) => {
//   return (dispatch: any) => {
//     const isCached = cacheBal.chain === chainId && cacheBal.acc === account
//     if (!account || !chainId || isCached) return

//     const tokenListStored = localStorage.getItem(
//       `tangleswapChainId${chainId}Tokens`
//     )
//     if (!tokenListStored) return

//     let TangleswapTokenList = JSON.parse(tokenListStored)
//     const isL1 = [Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(chainId)
//     if (isL1) TangleswapTokenList.push({ address: "ADA", chainId })

//     // Clear previous state
//     tokenBalancesState = {}

//     // Unsubscribe from previous subscriptions
//     unsubscribeBalanceFn.forEach((fn) => fn())
//     unsubscribeBalanceFn = []
//     cacheBal = { chain: chainId, acc: account }

//     // Re-subscribe and maintain state
//     TangleswapTokenList.forEach(async (tokenInfo: any) => {
//       if (tokenInfo.chainId === chainId) {
//         const tokenAddress = tokenInfo.address.toLowerCase()

//         const unsubscribe = await tangleship?.subscribeTokenBalance(
//           account,
//           tokenInfo.address,
//           (balance: string) => {
//             const updatedTokenBalances = {
//               ...tokenBalancesState,
//               [tokenAddress]: balance,
//             }
//             tokenBalancesState = updatedTokenBalances

//             dispatch(tokenBalanceSuccess(tokenBalancesState))
//           }
//         )

//         if (!!unsubscribe) unsubscribeBalanceFn.push(unsubscribe)
//       }
//     })
//   }
// }

export let unsubscribeSlot0Fn: Function[] = [] // Store unsubscribe functions
let slot0State: { [poolKey: string]: any } = {} // Initialize empty state

export const fetchSlot0 = (
  chainId: any,
  tangleship: Tangleship,
  params: any,
  poolAddress: string = null
) => {
  return async (dispatch: any) => {
    if (!chainId) return

    const addr0 = wrapAddr(params.token0, chainId)?.tokenAddress?.toLowerCase()
    const addr1 = wrapAddr(params.token1, chainId)?.tokenAddress?.toLowerCase()
    const isSameOrder = addr0 < addr1
    const token0 = isSameOrder ? addr0 : addr1
    const token1 = isSameOrder ? addr1 : addr0
    const poolKey = `${token0}_${token1}_${params.feeTier}`

    // Avoid re-subscribing to the same pool
    if (unsubscribeSlot0Fn[poolKey] !== undefined) return
    else unsubscribeSlot0Fn[poolKey] = null

    const decimals0 = isSameOrder ? params.decimals0 : params.decimals1
    const decimals1 = isSameOrder ? params.decimals1 : params.decimals0
    const orderedParams = {
      token0,
      token1,
      decimals0,
      decimals1,
      feeTier: params.feeTier,
    }

    // Subscribe to slot0 updates
    const unsubscribe = await tangleship?.subscribeSlot0(
      poolAddress,
      (slot0: any) => {
        let price = slot0 ? Number(slot0?.sqrtPriceX96?._hex) : 0
        if (price) price = sqrtPriceToPrice(price, decimals0, decimals1)

        // Update the state with the new price
        const updatedSlot0Prices = {
          ...slot0State,
          [poolKey]: { slot0, price },
        }
        slot0State = updatedSlot0Prices

        // Dispatch action to update the store
        dispatch(slot0PriceSuccess(updatedSlot0Prices))
      },
      orderedParams
    )

    if (unsubscribe) unsubscribeSlot0Fn[poolKey] = unsubscribe
  }
}
