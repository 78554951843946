import {
  AUCTION_LIST,
  AUCTION_LIST_LOADING,
  LIQ_LAUNCHER_INFO,
  SINGLE_AUCTION_LIST,
} from "./actionsTypes"

export const auctionDataLoading = (data: any) => {
  return {
    type: AUCTION_LIST_LOADING,
    data,
  }
}
export const liqLauncherDataSuccess = (data: any) => {
  return {
    type: LIQ_LAUNCHER_INFO,
    data,
  }
}

export const auctionListSuccess = (data: any) => {
  return {
    type: AUCTION_LIST,
    data,
  }
}
export const singleAuctionListSuccess = (data: any) => {
  return {
    type: SINGLE_AUCTION_LIST,
    data,
  }
}
