import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import LoadingDots from "components/utils/LoadingDots"
import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"

interface PriceProps {
  inputPrice: any
  inputCurrency: any
  outputCurrency: any
  poolNeedsInitialization?: boolean
  tierNull?: boolean
  loadingEndInit?: boolean
}
const LPPrice: FC<PriceProps> = (props) => {
  const {
    inputPrice,
    inputCurrency,
    outputCurrency,
    poolNeedsInitialization,
    loadingEndInit,
  } = props

  return (
    <WrapMiddle>
      <TextWrap>
        <MiddleText>Current Price: {""}</MiddleText>
        <>
          {loadingEndInit ? (
            <>
              {" "}
              — {""} {outputCurrency}
              {""} per {""}
              {inputCurrency}
            </>
          ) : (
            <>
              {poolNeedsInitialization ? (
                <>
                  {" "}
                  <MiddleText>
                    {" "}
                    {Number.isNaN(inputPrice) || isNaN(Number(inputPrice)) ? (
                      <>
                        — {""} {outputCurrency}
                        {""} per {""}
                        {inputCurrency}
                      </>
                    ) : (
                      <>
                        {formatNumber(inputPrice, 4)}
                        {""} {outputCurrency}
                        {""} per {""}
                        {inputCurrency}
                      </>
                    )}
                  </MiddleText>
                </>
              ) : (
                <>
                  <MiddleText>
                    {" "}
                    {Number.isNaN(inputPrice) || isNaN(Number(inputPrice)) ? (
                      <>
                        {" "}
                        <LoadingDots />
                      </>
                    ) : (
                      <>
                        {formatNumber(inputPrice, 4)} {""} {outputCurrency}
                        {""} per {""}
                        {inputCurrency}
                      </>
                    )}
                  </MiddleText>
                </>
              )}
            </>
          )}{" "}
        </>
      </TextWrap>
    </WrapMiddle>
  )
}

const WrapMiddle = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  margin: 6px 0 4px 0;
  width: 100%;
  height: 24px;
`
const MiddleText = styled(Caption)`
  color: ${TangleColors.white};
  text-align: center;
  margin: 0 2px;
  display: flex;
  flex-direction: row;
`
const TextWrap = styled.div`
  display: flex;
  flex-direction: row;
`
export default LPPrice
