import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { motion } from "framer-motion"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { Subtitle } from "styles/TextStyles"

const LoadingInvestmentState = () => {
  return (
    <InvestmentCoverRow>
      <Cover>
        <SpinnerCombined />

        <Title>
          <TitleTop>
            <TitleText> Retrieving the Investment Hub...</TitleText>
          </TitleTop>
        </Title>
      </Cover>
    </InvestmentCoverRow>
  )
}

const InvestmentCoverRow = styled(motion.div)`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  border-radius: 24px;
  position: relative;
  z-index: 3;
  overflow: hidden;
  margin: 6px 0;
  color: ${TangleColors.white};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
    background: ${TangleColors.blackDark};
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 16px;
  }
`

const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.div`
  color: ${TangleColors.white};
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const TitleText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 3px 0 0;
`
const TitleTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 8px 0;
`

export default LoadingInvestmentState
