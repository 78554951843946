import { ScaleLinear } from "d3"

export enum Bound {
  LOWER = "LOWER",
  UPPER = "UPPER",
}
export enum FeeAmount {
  LOWEST = 100,
  LOW = 500,
  MEDIUM = 3000,
  HIGH = 10000,
}
/**
 * The default factory tick spacings by fee amount.
 */
export declare const TICK_SPACINGS: {
  [amount in FeeAmount]: number
}

export interface ChartEntry {
  activeLiquidity?: any
  price0?: any
}
export interface TickProcessedForChart {
  tick: any
  liquidityActive: any
  liquidityNet: any
  price0: any
}

export interface TickProcessed {
  tick: any

  liquidityNet: any
}
export interface Dimensions {
  width: number
  height: number
}

export interface Margins {
  top: number
  right: number
  bottom: number
  left: number
}

export interface ZoomLevels {
  initialMin: number
  initialMax: number
  min: number
  max: number
}

export interface ChartBrushProps {
  id: string
  xScale: ScaleLinear<number, number>
  interactive: boolean
  brushLabelValue: (d: "w" | "e", x: number) => string
  brushExtent: [number, number]
  setBrushExtent: (extent: [number, number], mode: string | undefined) => void
  innerWidth: number
  innerHeight: number
  westHandleColor?: string
  eastHandleColor?: string
}
export interface LiquidityChartRangeInputProps {
  // to distringuish between multiple charts in the DOM
  id?: string
  data?: {
    series?: any
    current?: number
  }
  ticksAtLimit?: { [bound in Bound]?: boolean | undefined }
  styles?: {
    area?: {
      // color of the ticks in range
      selection?: string
    }

    brush?: {
      handle?: {
        west?: string
        east?: string
      }
    }
  }

  dimensions?: Dimensions
  margins?: Margins

  interactive?: boolean

  // brushLabels?: (d: 'w' | 'e', x: number) => string
  brushLabels?: any
  // brushDomainLower?:any
  // brushDomainUpper?:any
  brushDomain: [number, number] | undefined
  onBrushDomainChange?: (
    domain: [number, number],
    mode: string | undefined
  ) => void
  zoomLevelsinitialMin?: number
  zoomLevelsinitialMax?: number

  zoomLevels?: ZoomLevels
}

export interface ChartAreaProps {
  series: ChartEntry[]
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  xValue: (d: ChartEntry) => number
  yValue: (d: ChartEntry) => number
  fill?: string | undefined
}

export interface ChartLineProps {
  value: number
  xScale: ScaleLinear<number, number>
  innerHeight: number
}

export interface DensityChartProps {
  chainId?: number
  poolAddress?: string
  tickLensAddress?: string
  slot0?: any
  poolLiquidity?: number
  currencyA?: any
  currencyB?: any
  feeAmount?: number
}
