import { TANGLE_SITEWARNING } from "./actionsTypes"

export const siteWarningFn = (siteWarning) => {
  return {
    type: TANGLE_SITEWARNING,
    siteWarning: siteWarning,
  }
}

export const updateSiteWarningMode = (siteWarning) => {
  return (dispatch) => {
    sessionStorage.setItem("tangleSiteWarning", siteWarning)
    dispatch(siteWarningFn(siteWarning))
  }
}

export const proCheckSiteWarningState = () => {
  return (dispatch) => {
    const tangleSiteWarning = sessionStorage.getItem("tangleSiteWarning")

    if (
      tangleSiteWarning === undefined ||
      tangleSiteWarning === null ||
      tangleSiteWarning === "undefined" ||
      tangleSiteWarning === "false"
    ) {
      dispatch(updateSiteWarningMode(false))
    } else {
      dispatch(updateSiteWarningMode(tangleSiteWarning))
    }
  }
}
