import { useWSCContext } from "context/MilkomedaContext"
import { getChainInfo } from "constants/chainInfo"
interface ExplorerProps {
  explorer: string
  label: string
  logoUrl: string
  nativeCurrency: { name: string; symbol: string; decimals: number }
  rpcUrls: string
}
export default function useExplorerInfo(): {
  explorerURL: any
} {
  const { chainId } = useWSCContext()
  const chainTarget: ExplorerProps = chainId ? getChainInfo(chainId) : undefined

  return {
    explorerURL: chainTarget as ExplorerProps,
  }
}
