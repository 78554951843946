import { useCallback, useMemo } from "react"

import { ethers } from "ethers"
import { calculateGasMargin } from "utils/index"
import { useTokenAllowance, useTokenContract } from "./useContract"

import BigNumber from "bignumber.js"
import { useWSCContext } from "context/MilkomedaContext"
import { WRAPPED_ADDRESS } from "@tangleswap/sdk"

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: BigNumber,
  address?: string,
  spender?: string,
  pendingApproval?: boolean
): [ApprovalState, () => Promise<void>] {
  const { chainId } = useWSCContext()

  const currentAllowance = useTokenAllowance(address, spender, pendingApproval)
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (
      address?.toLowerCase() === spender?.toLowerCase() ||
      address?.toLowerCase() === WRAPPED_ADDRESS[chainId]?.toLowerCase()
    )
      return ApprovalState.APPROVED

    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    // if (amountToApprove.currency === ETHER[chainId || Chains.ETHEREUM]) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, spender, address, chainId])

  const tokenContract = useTokenContract(address)

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error("approve was called unnecessarily")
      return
    }
    if (!address) {
      console.error("no token")
      return
    }

    if (!tokenContract) {
      console.error("tokenContract is null")
      return
    }

    if (!amountToApprove) {
      console.error("missing amount to approve")
      return
    }

    if (!spender) {
      console.error("no spender")
      return
    }

    // let useExact = false
    const estimatedGas = await tokenContract.estimateGas
      .approve(spender, ethers.constants.MaxUint256)
      .catch(() => {
        // general fallback for tokens who restrict approval amounts
        // useExact = true
        return tokenContract.estimateGas.approve(
          spender,
          amountToApprove.toString()
        )
      })

    return tokenContract
      .approve(spender, ethers.constants.MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .catch((error: Error) => {
        console.error("Failed to approve token", error)
        throw error
      })
  }, [approvalState, address, tokenContract, amountToApprove, spender])

  return [approvalState, approve]
}
