import { FETCH_UNKNOWN_TOKEN_INFO } from "./actionsTypes"

export const unknownTokenInfo = (
  unknownTokenAddress,
  unknownTokenName,
  unknownTokenSymbol,
  unknownTokenDecimal,
  unknownTokenl1Address,
  unknownToken1Decimals,
  unknownTokenl1EncodedName
) => {
  return {
    type: FETCH_UNKNOWN_TOKEN_INFO,
    unknownTokenAddress: unknownTokenAddress,
    unknownTokenName: unknownTokenName,
    unknownTokenSymbol: unknownTokenSymbol,
    unknownTokenDecimal: unknownTokenDecimal,
    unknownTokenl1Address: unknownTokenl1Address,
    unknownToken1Decimals: unknownToken1Decimals,
    unknownTokenl1EncodedName: unknownTokenl1EncodedName,
  }
}

export const updateUnknownToken = (
  tokenName,
  tokenAddress,
  tokenSymbol,
  tokenDecimal,
  tokenl1Address,
  tokenl1Decimals,
  tokenl1EncodedName
) => {
  return (dispatch) => {
    const unknownTokenAddress = tokenAddress
    const unknownTokenName = tokenName
    const unknownTokenSymbol = tokenSymbol
    const unknownTokenDecimal = tokenDecimal
    const unknownTokenl1Address = tokenl1Address
    const unknownToken1Decimals = tokenl1Decimals
    const unknownTokenl1EncodedName = tokenl1EncodedName
    dispatch(
      unknownTokenInfo(
        unknownTokenAddress,
        unknownTokenName,
        unknownTokenSymbol,
        unknownTokenDecimal,
        unknownTokenl1Address,
        unknownToken1Decimals,
        unknownTokenl1EncodedName
      )
    )
  }
}
