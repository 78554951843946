import AuctionPageLoader from "components/LaunchPad/AuctionPageLoader"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { useWSCContext } from "context/MilkomedaContext"
import { QueryError, TangleTokenProps } from "components/swap/types"

import React, { FC } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { useTangleship } from "utils/useTangleship"
export interface TokenImagesProps {
  margin?: boolean
  hideIcon?: boolean
}
interface TransactionLogoProps {
  txType?: any
  approvedTokenAddress?: string
  decodedInputDataName?: any
  fromTokenAddress?: string
  toTokenAddress?: string
  token0Address?: string
  token1Address?: string
  token0Symbol?: string
  token1Symbol?: string
  successFulTransaction?: boolean
}
const TransactionLogoInfo: FC<TransactionLogoProps> = (props) => {
  const {
    txType,
    token0Address,
    toTokenAddress,
    approvedTokenAddress,
    decodedInputDataName,
    token1Address,
    fromTokenAddress,
    successFulTransaction,
  } = props
  const { chainId, l1ChainId } = useWSCContext()
  const { tangleship } = useTangleship()
  const { data: token0Info, isLoading: token0InfoLoading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleUserTXToken0", token0Address, l1ChainId],
    () => fetchTangleCurrency(token0Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token0Address && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: token1Info, isLoading: token1InfoLoading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleUserTXToken1", token1Address, l1ChainId],
    () => fetchTangleCurrency(token1Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token1Address && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: fromTokenInfo, isLoading: fromTokenInfoLoading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleUserTXFromTokenInfo", fromTokenAddress, l1ChainId],
    () => fetchTangleCurrency(fromTokenAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!fromTokenAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: toTokenInfo, isLoading: toTokenInfoLoading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleUserTXFromToTokenInfo", toTokenAddress, l1ChainId],
    () => fetchTangleCurrency(toTokenAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!toTokenAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: approvedTokenInfo } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserTXApprovedTokenInfo", approvedTokenAddress, l1ChainId],
    () => fetchTangleCurrency(approvedTokenAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!approvedTokenAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  return (
    <>
      <SizeImages>
        {token0InfoLoading ||
        token1InfoLoading ||
        fromTokenInfoLoading ||
        toTokenInfoLoading ? (
          <>
            <LoaderImageCover>
              <AuctionPageLoader />
              <AuctionPageLoader />
              <AuctionPageLoader />
            </LoaderImageCover>
          </>
        ) : (
          <>
            {" "}
            {successFulTransaction ? (
              <>
                {(txType !== null && txType === "approve") ||
                (txType !== null &&
                  txType === "approve" &&
                  decodedInputDataName?.name !== null &&
                  decodedInputDataName?.name === "approve") ? (
                  <>
                    <TransactionLogoWrapper>
                      {!!approvedTokenInfo?.logoURI ? (
                        <>
                          <TransactionLogo src={approvedTokenInfo?.logoURI} />
                        </>
                      ) : (
                        <SizeSecondIconCover>
                          {" "}
                          <ConvertTokenSymbol
                            text={approvedTokenInfo?.symbol}
                          />
                        </SizeSecondIconCover>
                      )}
                    </TransactionLogoWrapper>
                  </>
                ) : null}
                {(txType !== null && txType === "mint") ||
                (txType !== null &&
                  txType === "mint" &&
                  decodedInputDataName?.name === "mint") ||
                (txType !== null &&
                  txType === "multicallLiquidity" &&
                  decodedInputDataName?.name === "mint") ? (
                  <>
                    {!!token0Info?.logoURI ? (
                      <>
                        {" "}
                        <SizeFirstIcon
                          src={token0Info?.logoURI}
                          alt="tangleswap transaction logo"
                        />
                      </>
                    ) : (
                      <SizeFirstIconCover>
                        <ConvertTokenSymbol text={token0Info?.symbol} />
                      </SizeFirstIconCover>
                    )}

                    {!!token1Info?.logoURI ? (
                      <>
                        {" "}
                        <SizeSecondIcon
                          src={token1Info?.logoURI}
                          alt="tangleswap transaction logo"
                        />
                      </>
                    ) : (
                      <SizeSecondIconCover>
                        <ConvertTokenSymbol text={token1Info?.symbol} />
                      </SizeSecondIconCover>
                    )}
                  </>
                ) : null}
                {(txType !== null && txType === "exactInput") ||
                (txType !== null && txType === "exactOutputSingle") ||
                (txType !== null && txType === "exactOutput") ||
                (txType !== null && txType === "exactInputSingle") ||
                (txType !== null &&
                  txType === "multicallSwap" &&
                  decodedInputDataName?.name !== null &&
                  decodedInputDataName?.name === "exactInput") ||
                (txType !== null &&
                  txType === "multicallSwap" &&
                  decodedInputDataName?.name !== null &&
                  decodedInputDataName?.name === "exactInputSingle") ||
                (txType !== null &&
                  txType === "multicallSwap" &&
                  decodedInputDataName?.name !== null &&
                  decodedInputDataName?.name === "exactOutput") ||
                (txType !== null &&
                  txType === "multicallSwap" &&
                  decodedInputDataName?.name !== null &&
                  decodedInputDataName?.name === "exactOutputSingle") ? (
                  <>
                    {fromTokenInfo !== null ? (
                      <>
                        <>
                          {!!fromTokenInfo?.logoURI ? (
                            <>
                              <SizeFirstIcon
                                src={fromTokenInfo?.logoURI}
                                alt="  tangleswap transaction logo"
                              />
                            </>
                          ) : (
                            <SizeFirstIconCover>
                              <ConvertTokenSymbol
                                text={fromTokenInfo?.symbol}
                              />
                            </SizeFirstIconCover>
                          )}
                          {!!toTokenInfo?.logoURI ? (
                            <>
                              {" "}
                              <SizeSecondIcon
                                src={toTokenInfo?.logoURI}
                                alt="  tangleswap transaction logo"
                              />
                            </>
                          ) : (
                            <SizeSecondIconCover>
                              {" "}
                              <ConvertTokenSymbol text={toTokenInfo?.symbol} />
                            </SizeSecondIconCover>
                          )}
                        </>
                      </>
                    ) : null}
                  </>
                ) : null}
                {(txType !== null && txType === "collect") ||
                (txType !== null &&
                  txType === "collect" &&
                  decodedInputDataName?.name === "collect") ? (
                  <>
                    <>
                      {!!token0Info?.logoURI ? (
                        <>
                          {" "}
                          <SizeFirstIcon
                            src={token0Info?.logoURI}
                            alt="tangleswap transaction logo"
                          />
                        </>
                      ) : (
                        <SizeFirstIconCover>
                          <ConvertTokenSymbol text={token0Info?.symbol} />
                        </SizeFirstIconCover>
                      )}
                      {!!token1Info?.logoURI ? (
                        <>
                          <SizeSecondIcon
                            src={token1Info?.logoURI}
                            alt="  tangleswap transaction logo"
                          />
                        </>
                      ) : (
                        <SizeSecondIconCover>
                          {" "}
                          <ConvertTokenSymbol text={token1Info?.symbol} />
                        </SizeSecondIconCover>
                      )}
                    </>
                  </>
                ) : null}
                {txType === "increaseLiquidity" ||
                (txType === "increaseLiquidity" &&
                  decodedInputDataName?.name === "increaseLiquidity") ? (
                  <>
                    {!!token0Info?.logoURI ? (
                      <>
                        <SizeFirstIcon
                          src={token0Info?.logoURI}
                          alt="tangleswap transaction logo"
                        />
                      </>
                    ) : (
                      <SizeFirstIconCover>
                        <ConvertTokenSymbol text={token0Info?.symbol} />
                      </SizeFirstIconCover>
                    )}
                    {!!token1Info?.logoURI ? (
                      <>
                        <SizeSecondIcon
                          src={token1Info?.logoURI}
                          alt="  tangleswap transaction logo"
                        />
                      </>
                    ) : (
                      <SizeSecondIconCover>
                        <ConvertTokenSymbol text={token1Info?.symbol} />
                      </SizeSecondIconCover>
                    )}
                  </>
                ) : null}
                {txType === "multicallLiquidity" &&
                decodedInputDataName?.name ===
                  "createAndInitializePoolIfNecessary" ? (
                  <>
                    {!!token0Info?.logoURI ? (
                      <>
                        {" "}
                        <SizeFirstIcon
                          src={token0Info?.logoURI}
                          alt="tangleswap transaction logo"
                        />
                      </>
                    ) : (
                      <SizeFirstIconCover>
                        {" "}
                        <ConvertTokenSymbol text={token0Info?.symbol} />
                      </SizeFirstIconCover>
                    )}
                    {!!token1Info?.logoURI ? (
                      <>
                        {" "}
                        <SizeSecondIcon
                          src={token1Info?.logoURI}
                          alt="  tangleswap transaction logo"
                        />
                      </>
                    ) : (
                      <SizeSecondIconCover>
                        <ConvertTokenSymbol text={token1Info?.symbol} />
                      </SizeSecondIconCover>
                    )}
                  </>
                ) : null}
                {txType === "multicallLiquidity" &&
                decodedInputDataName?.name === "decreaseLiquidity" ? (
                  <>
                    <TransactionLogoWrapper>
                      {!!token0Info?.logoURI ? (
                        <>
                          <TransactionLogo src={token0Info?.logoURI} />
                        </>
                      ) : (
                        <>
                          <SizeFirstIconCover>
                            <ConvertTokenSymbol text={token0Info?.symbol} />
                          </SizeFirstIconCover>
                        </>
                      )}
                    </TransactionLogoWrapper>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </SizeImages>
    </>
  )
}

const TransactionLogoWrapper = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${TangleColors.black};
  margin: -8px 2px 0 0;
`
const TransactionLogo = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 9999px;
`

const LoaderImageCover = styled.div`
  width: 28px;
  height: 28px;
  margin: -6px 0 0 -8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 999px;
  border: 1px solid ${TangleColors.white};
  background: ${TangleColors.black};
  /* position: relative; */
`
const SizeImages = styled.div`
  max-width: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0 -6px;
`
const SizeFirstIcon = styled.img`
  height: 22px;
  width: 22px;
  object-fit: cover;
  border-radius: 9999px;
  z-index: 5;
`
const SizeSecondIcon = styled.img`
  height: 22px;
  width: 22px;
  object-fit: cover;

  border-radius: 9999px;
`
const SizeFirstIconCover = styled.div`
  height: 22px;
  width: 22px;
  object-fit: cover;

  top: 0;
  border-radius: 9999px;
`
const SizeSecondIconCover = styled.div`
  height: 22px;
  width: 22px;
  object-fit: cover;

  border-radius: 9999px;

  z-index: 3;
`

export default TransactionLogoInfo
