import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Header5, Header7 } from "styles/TextStyles"
import { AiOutlineArrowUp } from "react-icons/ai"
import { GrClose } from "react-icons/gr"
import { AddTokenModalButton, ModalButton } from "styles/ButtonStyles"
import SpinnerCombined from "./SpinnerCombined"
import useExplorerInfo from "utils/useExplorerInfo"

interface LPInfoProp {
  pending?: boolean
  modalRef?: any
  link?: string
  closeModal: (value: any) => void
  closePendingModal?: () => void
  closeCollectFeesModal?: () => void
}
const CollectFeesConfirmationModal: FC<LPInfoProp> = (props) => {
  const {
    pending,
    link,
    modalRef,
    closeModal,
    closePendingModal,
    closeCollectFeesModal,
  } = props

  const { explorerURL } = useExplorerInfo()

  return (
    <Body ref={modalRef} onClick={closeModal}>
      <Cover>
        <ModalClose onClick={closePendingModal} />
        {/* pending conditional rendering starts */}
        {pending ? (
          <>
            {" "}
            <SwapPending>
              <SpinnerCombined />

              <SwapTitle>Waiting For Confirmation</SwapTitle>

              <SwapTrade>Collecting fees</SwapTrade>
              <SwapConfirmSmall>
                Confirm this transaction in your wallet
              </SwapConfirmSmall>
            </SwapPending>
          </>
        ) : (
          <>
            <SwapWrap>
              <SwapIcon>
                <SwapArrow />
              </SwapIcon>
              <SwapText>Transaction submitted</SwapText>

              <WrapButton onClick={closeCollectFeesModal}>Close</WrapButton>

              <a
                className="swapaddwrap"
                href={`${explorerURL?.explorer}/tx/${link}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <SwapAddToken>View transaction</SwapAddToken>
              </a>
            </SwapWrap>
          </>
        )}
        {/* pending conditional rendering ends */}
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 400;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
`
const Cover = styled.div`
  height: 450px;
  position: relative;
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${TangleColors.white};
  justify-content: center;
  border: 1px solid ${TangleColors.lighthover};
  .swapaddwrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const SwapWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${TangleColors.white};
`
const SwapIcon = styled.div`
  height: 72px;
  max-width: 72px;
  width: 100%;
  border-radius: 9999px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${TangleColors.lighthover};
`
const SwapArrow = styled(AiOutlineArrowUp)`
  color: ${TangleColors.lighthover};
  height: 40px;
  width: 40px;
`
const SwapText = styled(Header7)`
  color: ${TangleColors.white};
`
const SwapAddToken = styled(AddTokenModalButton)`
  margin: 16px 0 0 0;
`
const WrapButton = styled(ModalButton)`
  margin: 40px 0;
`
const SwapPending = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SwapTitle = styled(Caption)`
  margin: 40px 0 8px 0;
  color: ${TangleColors.lighthover};
`
const SwapTrade = styled(Header5)`
  margin: 8px 0;
  text-align: center;
  color: ${TangleColors.white};
`

const SwapConfirmSmall = styled(Caption)`
  margin: 8px 0;
  color: ${TangleColors.lighthover};
`
const ModalClose = styled(GrClose)`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 24px;
  right: 30px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
export default React.memo(CollectFeesConfirmationModal)
