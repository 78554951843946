import IncreaseLiquidityContainer from "components/Liquidity/IncreaseLiquidity/IncreaseLiquidityContainer"
import React from "react"
import styled from "styled-components"

const IncreaseLiquidityPageContainer = () => {
  return (
    <Body>
      <IncreaseLiquidityContainer />
    </Body>
  )
}
const Body = styled.div`
  padding: 0 0 32px 0;
  min-height: 90vh;
`
export default IncreaseLiquidityPageContainer
