import React, { FC, useRef } from "react"
import { GrClose } from "react-icons/gr"
import {
  closeConnectWalletModal,
  noMetMaskModal,
} from "store/actions/modalActions"
import { useAppDispatch, useAppSelector } from "store/hooks"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall, Header7, Subtitle } from "styles/TextStyles"
import firefly from "assets/firefly.png"
import metamask from "assets/metamask.png"
import namiWallet from "assets/nami.svg"
import eternlWallet from "assets/eternl.svg"
import nufiWallet from "assets/nufi.svg"
import yoroiWallet from "assets/yoroi.svg"
import {
  updateSelectedWallet,
  updateWalletModalState,
} from "store/actions/SelectedWalletAction"
import { useWSCContext } from "context/MilkomedaContext"

interface ModalProps {
  fireFlyClicked?: () => void
  closeWalletModal?: () => void
  networkActive?: boolean
}

const WalletModal: FC<ModalProps> = (props) => {
  const { closeWalletModal, fireFlyClicked } = props
  const bodyRef = useRef<HTMLDivElement | any>()

  const activeNetwork = useAppSelector((state) => state.wallet.activeNetwork)

  const dispatch = useAppDispatch()
  const { connectToMetamask, connectToWSC } = useWSCContext()
  // const { isConnected: isWalletConnected } = useAccount()

  const check = typeof window !== "undefined"
  const isMetamaskWalletInstalled = check && window?.ethereum?.isMetaMask
  const isEternlWalletInstalled = check && window?.cardano?.eternl
  const isNamiWalletInstalled = check && window?.cardano?.nami
  // const isYoroiWalletInstalled = check && window?.cardano?.yoroi
  // const isNufiWalletInstalled = check && window?.cardano?.nufi

  const connectMetamask = async () => {
    if (!isMetamaskWalletInstalled) {
      // alert("MetaMask not installed, rerouting to the install page...")
      // window.open("https://metamask.io/", "_blank")
      dispatch(noMetMaskModal())
      return
    }
    connectToMetamask()
    updateSelectedWallet("metamask")
    dispatch(closeConnectWalletModal())
  }

  const connectNamiWallet = async () => {
    if (!isNamiWalletInstalled) {
      alert("Nami not installed, rerouting to the install page...")
      window.open("https://namiwallet.io/", "_blank")
      return
    }
    connectToWSC("Nami")
    updateSelectedWallet("nami")
    dispatch(closeConnectWalletModal())
  }

  const connectEternlWallet = async () => {
    if (!isEternlWalletInstalled) {
      alert("Eternl not installed, rerouting to the install page...")
      window.open("https://eternl.io/", "_blank")
      return
    }
    connectToWSC("Eternl")
    updateSelectedWallet("eternl")
    dispatch(closeConnectWalletModal())
  }

  // const connectYoroiWallet = async () => {
  //   if (!isYoroiWalletInstalled) {
  //     alert("Yoroi not installed, rerouting to the install page...")
  //     window.open("https://yoroi-wallet.com/#/", "_blank")
  //     return
  //   }
  //   connectToWSC("Yoroi")
  //   updateSelectedWallet("yoroi")
  //   dispatch(closeConnectWalletModal())
  // }

  // const connectNufiWallet = async () => {
  //   if (!isNufiWalletInstalled) {
  //     alert("NuFi not installed, rerouting to the install page...")
  //     window.open("https://nu.fi/", "_blank")
  //     return
  //   }
  //   connectToWSC("NuFi")
  //   updateSelectedWallet("nufi")
  //   dispatch(closeConnectWalletModal())
  // }

  const changeActiveNetwork = (value: number) => {
    if (value === activeNetwork) return
    dispatch(updateWalletModalState(value))

    localStorage.setItem("tangleWalletNetworkMode", JSON.stringify(value))
  }

  const closeWalletModalFn = (e: any) => {
    if (e?.target === bodyRef.current) {
      dispatch(closeConnectWalletModal())
    }
  }

  return (
    <>
      <ModalCover ref={bodyRef} onClick={closeWalletModalFn}>
        <Body>
          <ModalClose onClick={closeWalletModal} />
          <Title>
            <TitleText>Connect to a wallet</TitleText>
          </Title>

          <TopWalletNetworkCover>
            <NetworkCover
              networkActive={activeNetwork === 1}
              onClick={() => changeActiveNetwork(1)}
            >
              <NetworkText networkActive={activeNetwork === 1}>
                Cardano
              </NetworkText>
            </NetworkCover>
            <NetworkCover
              networkActive={activeNetwork === 2}
              onClick={() => changeActiveNetwork(2)}
            >
              <NetworkText networkActive={activeNetwork === 2}>
                Shimmer
              </NetworkText>
            </NetworkCover>
          </TopWalletNetworkCover>

          <Cover>
            <WalletTypes
              networkActive={activeNetwork === 2}
              className="metamask"
              onClick={connectMetamask}
            >
              <WalletIcon src={metamask} />
              <WalletName>MetaMask</WalletName>
            </WalletTypes>
            <WalletTypes
              networkActive={activeNetwork === 2}
              onClick={fireFlyClicked}
              className="firefly"
            >
              <WalletIcon src={firefly} />
              <WalletName>Firefly</WalletName>{" "}
              <ComingSoon>
                <SoonText>Soon™</SoonText>
              </ComingSoon>
            </WalletTypes>
            <WalletTypes
              networkActive={activeNetwork === 1}
              className="nami_wallet"
              onClick={connectNamiWallet}
            >
              <WalletIcon src={namiWallet} />
              <WalletName>Nami</WalletName>
            </WalletTypes>
            <WalletTypes
              networkActive={activeNetwork === 1}
              className="eternl_wallet"
              onClick={connectEternlWallet}
            >
              {" "}
              <WalletIcon src={eternlWallet} />
              <WalletName>Eternl</WalletName>
            </WalletTypes>
            <WalletTypes
              networkActive={activeNetwork === 1}
              className="yoroi_wallet"
              // onClick={connectYoroiWallet}
            >
              <WalletIcon src={yoroiWallet} />
              <WalletName>Yoroi</WalletName>
              <ComingSoon>
                <SoonText>Soon™</SoonText>
              </ComingSoon>
            </WalletTypes>
            <WalletTypes
              networkActive={activeNetwork === 1}
              className="nufi_wallet"
              // onClick={connectNufiWallet}
            >
              <WalletIcon src={nufiWallet} />
              <WalletName>NuFi</WalletName>
              <ComingSoon>
                <SoonText>Soon™</SoonText>
              </ComingSoon>
            </WalletTypes>
            <WalletTypes
              networkActive={activeNetwork === 1}
              className="metamask"
              onClick={connectMetamask}
            >
              <WalletIcon src={metamask} />
              <WalletName>MetaMask</WalletName>
            </WalletTypes>
          </Cover>
        </Body>
      </ModalCover>
    </>
  )
}

const ModalCover = styled.div`
  position: fixed;

  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 900;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;

  align-items: center;

  padding: 12px;
  @media only screen and (max-width: 650px) {
    padding: 20px;
  }
`
const Body = styled.div<ModalProps>`
  width: 100%;
  min-height: 340px;

  max-width: 500px;
  background: ${TangleColors.modalBG};
  border-radius: 24px;
  margin: 0 auto;
  padding: 16px;
  position: fixed;
  z-index: 900;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  position: relative;

  border: 1px solid ${TangleColors.lighthover};
  @media only screen and (max-width: 650px) {
    padding: 8px;
    max-width: 480px;
  }
  .nami_wallet {
    border: 2px solid ${TangleColors.namiWallet};
    :hover {
      box-shadow: ${TangleColors.namiWallet} 0px 4px 14px,
        ${TangleColors.namiWallet} 0px 0.5px 1.7px;
    }
  }
  .metamask {
    border: 2px solid ${TangleColors.orangeDark};
    :hover {
      box-shadow: ${TangleColors.orangeDark} 0px 4px 14px,
        ${TangleColors.orangeDark} 0px 0.5px 1.7px;
    }
  }
  .firefly {
    border: 2px solid ${TangleColors.firefly};
    :hover {
      box-shadow: ${TangleColors.firefly} 0px 4px 14px,
        ${TangleColors.firefly} 0px 0.5px 1.7px;
    }
  }
  .yoroi_wallet {
    border-radius: 999px;
    border: 2px solid ${TangleColors.yoroi};
    :hover {
      box-shadow: ${TangleColors.yoroi} 0px 4px 14px,
        ${TangleColors.yoroi} 0px 0.5px 1.7px;
    }
  }
  .nufi_wallet {
    border: 2px solid ${TangleColors.nufi};
    :hover {
      box-shadow: ${TangleColors.nufi} 0px 4px 14px,
        ${TangleColors.nufi} 0px 0.5px 1.7px;
    }
  }
  .eternl_wallet {
    border: 2px solid ${TangleColors.eternl};
    :hover {
      box-shadow: ${TangleColors.eternl} 0px 4px 14px,
        ${TangleColors.eternl} 0px 0.5px 1.7px;
    }
  }
`
const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 16px 0;
`

const TitleText = styled(Header7)`
  color: ${TangleColors.white};
`

const Cover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
`

const WalletTypes = styled.button<ModalProps>`
  display: ${(props) => (props.networkActive ? `flex` : `none`)};
  flex-direction: row;
  height: 64px;
  max-width: 416px;
  width: 100%;
  border: none;
  position: relative;
  z-index: 66;
  outline: none;
  margin: 8px auto;
  background: ${TangleColors.modalBG};
  color: ${TangleColors.white};
  cursor: pointer;
  border-radius: 64px;
  padding: 16px 30px;
  justify-content: flex-start;
  align-items: center;
  transition: 0.2s ease;
`

const WalletIcon = styled.img`
  height: 32px;
  width: 32px;
  object-fit: contain;

  @media only screen and (max-width: 650px) {
    height: 24px;
    width: 24px;
  }
`

const WalletName = styled(Header7)`
  margin: 0 0 0 24px;
  @media only screen and (max-width: 650px) {
    margin: 0 0 0 8px;
  }
`

const ModalClose = styled(GrClose)`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

const TopWalletNetworkCover = styled.div`
  max-width: 350px;
  width: 100%;
  margin: 24px auto;
  border-radius: 99px;
  background: ${TangleColors.heroCTA};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  gap: 8px;
  padding: 4px;
`
const NetworkCover = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 20px;
  width: 100%;
  max-width: 450px;

  border: ${(props) =>
    props.networkActive ? `1px solid ${TangleColors.dockBG}` : `none`};
  background: ${(props) =>
    props.networkActive ? `${TangleColors.lighthover}` : `transparent`};
  border-radius: 99px;
  cursor: pointer;
`
const NetworkText = styled(Subtitle)<ModalProps>`
  color: ${(props) =>
    props.networkActive ? ` ${TangleColors.black}` : ` ${TangleColors.white}`};
`
const ComingSoon = styled.div`
  display: flex;
  flex-direction: row;
  width: 72px;
  height: 32px;
  position: absolute;
  right: -20px;
  top: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${TangleColors.blackSlateGray};
  transform: translate(-50%, -50%);
`

const SoonText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`

export default WalletModal
