import { useWSCContext } from "context/MilkomedaContext"
import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { HeaderNavbar } from "styles/TextStyles"
import NetworkDropdown from "./NetworkDropdown"
import NetworkDropIcon from "./NetworkDropIcon"
import { useIsSupportedNetwork } from "constants/hooks"
import warningSVG from "assets/warning.svg"
import useExplorerInfo from "utils/useExplorerInfo"
import { useAppDispatch } from "store/hooks"
import { openNetworkMenu } from "store/actions/MobileNavAction"
interface ActiveProps {
  active?: boolean
  networkDropDownOpen?: boolean
  openNetworkDropdown?: () => void
}

const NetworkCover: FC<ActiveProps> = (props) => {
  const { account, isWSCConnected } = useWSCContext()
  const { explorerURL } = useExplorerInfo()
  const { networkDropDownOpen, openNetworkDropdown } = props
  const dispatch = useAppDispatch()
  const isSupportedNetwork = useIsSupportedNetwork()
  const openMobileNetworkDropdown = () => {
    dispatch(openNetworkMenu())
  }
  return (
    <Body>
      <IOTACover
        onClick={openNetworkDropdown}
        active={account && !isSupportedNetwork}
      >
        <AccountWarning
          src={warningSVG}
          active={account && !isSupportedNetwork}
        />

        <IOTALogo
          active={account && isSupportedNetwork}
          src={explorerURL?.logoUrl}
          alt="tangleswap iota"
        />
        <IOTAText>
          {account && !isSupportedNetwork ? (
            <>Unsupported</>
          ) : (
            <>{explorerURL?.label}</>
          )}
        </IOTAText>
        {!isWSCConnected && (
          <NetworkWrapper>
            <NetworkDropIcon networkDropDownIconOpen={networkDropDownOpen} />
          </NetworkWrapper>
        )}
      </IOTACover>
      <MobileIOTACover
        {...(!isWSCConnected && {
          onClick: openMobileNetworkDropdown,
        })}
        active={account && !isSupportedNetwork}
      >
        <AccountWarning
          src={warningSVG}
          active={account && !isSupportedNetwork}
        />

        <IOTALogo
          active={account && isSupportedNetwork}
          src={explorerURL?.logoUrl}
          alt="tangleswap iota"
        />
        <IOTAText>
          {account && !isSupportedNetwork ? (
            <>Unsupported</>
          ) : (
            <>{explorerURL?.label}</>
          )}
        </IOTAText>
        {!isWSCConnected ? (
          <NetworkWrapper>
            <NetworkDropIcon networkDropDownIconOpen={networkDropDownOpen} />
          </NetworkWrapper>
        ) : null}
      </MobileIOTACover>
      {!isWSCConnected ? (
        <NetworkDropdown networkDropDownOpen={networkDropDownOpen} />
      ) : null}
    </Body>
  )
}

const IOTACover = styled.div<ActiveProps>`
  height: 48px;
  max-width: 240px;
  min-width: 160px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 99;
  margin: 0 16px 0 0;
  z-index: 90;
  margin: 0 auto;
  background: ${(props) =>
    props.active ? `${TangleColors.dockBG}` : `${TangleColors.cta}`};
  transition: 0.4s ease-in;

  border: 1.5px solid transparent;
  :hover {
    background: ${TangleColors.black};
    border: 1.5px solid ${TangleColors.white};
  }
  @media only screen and (max-width: 850px) {
    max-width: 740px;
    margin: 8px auto;
    height: 64px;
    width: 100%;
    padding: 8px 24px;
    border-radius: 32px;
    position: relative;
    z-index: 1;
    display: none;
  }
`
const MobileIOTACover = styled.div<ActiveProps>`
  height: 48px;
  max-width: 240px;
  min-width: 160px;
  padding: 8px 12px;
  width: 100%;
  border-radius: 24px;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 99;
  margin: 0 16px 0 0;
  z-index: 90;
  margin: 0 auto;
  background: ${(props) =>
    props.active ? `${TangleColors.dockBG}` : `${TangleColors.cta}`};
  transition: 0.4s ease-in;

  border: 1.5px solid transparent;
  :hover {
    background: ${TangleColors.black};
    border: 1.5px solid ${TangleColors.white};
  }
  @media only screen and (max-width: 850px) {
    max-width: 740px;
    margin: 8px auto;
    height: 64px;
    width: 100%;
    padding: 8px 24px;
    border-radius: 32px;
    position: relative;
    z-index: 1;
    display: flex;
  }
`
const Body = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 99 !important;
  border-radius: 24px;
  border: none;
  @media only screen and (max-width: 850px) {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 800px;
    margin: 0;
    width: 100%;
  }
`

const NetworkWrapper = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${IOTACover}:hover & {
    svg {
      color: ${TangleColors.white} !important;
    }
  }
`
const IOTALogo = styled.img<ActiveProps>`
  height: 26px;
  width: 26px;
  border-radius: 999px;
  margin: 0 4px;
  display: ${(props) => (props.active ? "flex" : "none")};
`
const IOTAText = styled(HeaderNavbar)`
  color: ${TangleColors.white};
  margin: 0 4px;
  line-height: 0.7 !important;
  white-space: nowrap;
  position: relative;
`

const AccountWarning = styled.img<ActiveProps>`
  height: 18px;
  width: 18px;
  color: ${TangleColors.white};

  display: ${(props) => (props.active ? "flex" : "none")};
`

export default NetworkCover
