import LimitOrderContainer from "components/limitorder/LimitOrderContainer"
import { useEffect } from "react"
import styled from "styled-components"

const LimitOrder = () => {
  useEffect(() => {
    document.title = "Limit Order"
  }, [])
  return (
    <>
      <Body>
        <LimitOrderContainer />
      </Body>
    </>
  )
}
const Body = styled.div``

export default LimitOrder
