import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import Papa from "papaparse"
import { FaUpload } from "react-icons/fa"
import { GrClose } from "react-icons/gr"
import AuctionCard from "components/LaunchPad/AuctionCard"
import countryList from "react-select-country-list"
import { HiOutlineExternalLink } from "react-icons/hi"
import { toast as TangleToast } from "sonner"
import {
  Caption,
  CaptionSmall,
  CaptionSmallRegular,
  CaptionTiny,
  Header5Gothic,
  Header6,
  Header7,
  Subtitle,
} from "styles/TextStyles"
import { AuctionDetailsProps, AuctionStatus } from "components/LaunchPad/types"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { populateMarketData } from "utils/calls/investmentHub"

import {
  Chains,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  ZERO_ADDRESS,
  isInvalid,
} from "@tangleswap/sdk"
import { useWSCContext } from "context/MilkomedaContext"

import { useAppDispatch, useAppSelector } from "store/hooks"

import { useForm, SubmitHandler } from "react-hook-form"
import AuctionToggle from "./AuctionToggle"

import {
  convertDate,
  countrySelectedName,
} from "components/LaunchPad/utils/formatNumber"
import { ethers } from "ethers"
import useAuctionArray from "utils/useAuctionArray"
import UpdatePurplelistModal from "components/confirmation/UpdatePurplelistModal"
import { PendingStateContext } from "context/PendingContext"
import EditWithdrawModal from "components/confirmation/EditWithdrawModal"
import EditSaveSettingsModal from "components/confirmation/EditSaveSettingsModal"
import LoadingDots from "components/utils/LoadingDots"
import EditCancelAuctionModal from "components/confirmation/EditCancelAuctionModal"
import useExplorerInfo from "utils/useExplorerInfo"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { useQuery, useQueryClient } from "react-query"
import { isAddress } from "utils"
import { useTangleship } from "utils/useTangleship"
import GoBackButton from "components/LaunchPad/CreateAuction/components/GoBackButton"
import EditFinalizeLPModal from "components/confirmation/EditFinalizeLPModal"
import TimeRemaining from "components/LaunchPad/CreateAuction/components/TimeRemaining"
import { AuctionSaveButton } from "styles/ButtonStyles"
import { liqLauncherDataSuccess } from "store/actions/AuctionDataAction"
import { toSignificantDigits } from "utils/toSignificant"
interface PoolNFTProps {
  description?: string
  image?: string
  name?: string
  active?: boolean
  hide?: boolean
  disabled?: boolean
}
interface EditPageProps {
  align?: string
  formError?: boolean
  color?: string
  active?: boolean
  hide?: boolean
}
interface EditFormData {
  website?: string
  whitepaper?: string
  tokenomics?: string
  category?: string
  icon?: string
  amount?: number | string
  desktopBanner?: string
  mobileBanner?: string
  warningMessage?: string
  description?: string
  twitter?: string
  telegram?: string
  discord?: string
  github?: string
  blog?: string
  pointListAddress?: string
  purplelistChecker?: string
  userAddress?: string
  bannedCountries?: any
  fileInput?: any
}
const WARNING_MAX_CHAR = 300
const DESCRIPTION_MAX_CHAR = 300
interface AuctionEditProps {
  status?: string
}
const AuctionEditPage = () => {
  const { address: urlAuctionAddress } = useParams()

  const [purplelistEnableLoading, setPurplelistEnableLoading] =
    useState<boolean>(false)
  const [enablePurplelistStatus, setEnablePurplelistStatus] =
    useState<boolean>(false)

  const [withdrawTimeDisabled, setWithdrawTimeDisabled] =
    useState<any>(undefined)
  const { setPendingTransaction } = useContext(PendingStateContext)

  const [fileDragging, setFileDragging] = useState<boolean>(false)
  const [fileIsInvalid, setFileIsInvalid] = useState<boolean>(false)
  const [modalTXError, setModalTXError] = useState<boolean>(false)

  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<EditFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [launcherInfoUnlock, setLauncherInfoUnlock] = useState<any>(undefined)
  const [launcherInfoTimestamp, setLauncherInfoTimestamp] =
    useState<any>(undefined)

  const [poolNFT, setPoolNFT] = useState<any>(undefined)

  const [timeGreater, setTimeGreater] = useState<boolean>(false)

  const [market, setMarket] = useState<any>([])

  const [postAuctionLiqLauncher, setPostAuctionLiqLauncher] =
    useState<string>(undefined)
  const [liquidityNftId, setLiquidityNftId] = useState<number>(null)

  const [purplelistAddress, setPurplelistAddress] = useState<string>(undefined)
  const [auctionLPOwner, setAuctionLPOwner] = useState<any>(undefined)

  const [purplelistMaxAllocation, setPurplelistMaxAllocation] =
    useState<number>(undefined)

  const [csvData, setCsvData] = useState<any>(undefined)
  const [csvDataWallets, setCsvDataWallets] = useState<any>(undefined)
  const [csvDataAmount, setCsvDataAmount] = useState<any>(undefined)

  const [fileName, setFileName] = useState<any>(undefined)

  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isOperator, setIsOperator] = useState<boolean>(false)
  const [saveSettingApproving, setSaveSettingApproving] =
    useState<boolean>(false)
  const [saveSettingPending, setSaveSettingPending] = useState<boolean>(false)
  const [saveSettingApproved, setSaveSettingApproved] = useState<boolean>(false)

  const [saveSettingTx, setSaveSettingTx] = useState<any>(undefined)

  const [saveSettingModal, setSaveSettingModal] = useState<boolean>(false)
  const [withdrawTokenTx, setWithdrawTokenTx] = useState<any>(undefined)
  const [updatePurpleListApproving, setUpdatePurpleListApproving] =
    useState<boolean>(false)
  const [updatePurpleListPending, setUpdatePurpleListPending] =
    useState<boolean>(false)
  const [updatePurpleListApproved, setUpdatePurpleListApproved] =
    useState<boolean>(false)
  const [updatePurpleListModal, setUpdatePurpleListModal] =
    useState<boolean>(false)
  const [updatePurpleListTx, setUpdatePurpleListTx] = useState<any>(undefined)
  const [withdrawTokenApproving, setWithdrawTokenApproving] =
    useState<boolean>(false)
  const [withdrawTokenPending, setWithdrawTokenPending] =
    useState<boolean>(false)
  const [withdrawTokenApproved, setWithdrawTokenApproved] =
    useState<boolean>(false)
  const [withdrawTokenModal, setWithdrawTokenModal] = useState<boolean>(false)

  const [cancelAuctionTx, setCancelAuctionTx] = useState<any>(undefined)

  const [cancelAuctionPending, setCancelAuctionPending] =
    useState<boolean>(false)

  const [cancelAuctionModal, setCancelAuctionModal] = useState<boolean>(false)
  const [finalizeLPApproving, setFinalizeLPApproving] = useState<any>(undefined)
  const [finalizeLPPending, setFinalizeLPPending] = useState<boolean>(false)
  const [finalizeLPApproved, setFinalizeLPApproved] = useState<boolean>(false)
  const [finalizeLPModal, setFinalizeLPModal] = useState<boolean>(false)
  const [auctionQueryData, setAuctionQueryData] = useState<any>(undefined)
  const [finalizeLPTx, setFinalizeLPTx] = useState<any>(undefined)
  const dispatch = useAppDispatch()
  const { account, chainId, l1ChainId } = useWSCContext()
  const { singleTangleAuctions, refetchSingleAuction } =
    useAuctionArray(urlAuctionAddress)
  const launcherInfo = useAppSelector(
    (state) => state.auctionDataReducer.launcherInfo
  )
  const { tangleship } = useTangleship()

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!chainId) return
    const auctionQuery: any = queryClient.getQueryData([
      "tangleswap-auction-info",
      [chainId],
    ])
    setAuctionQueryData(auctionQuery)
  }, [chainId, queryClient])
  //debug here, issue #1
  // useEffect(() => {
  //   if (!urlAuctionAddress) return

  //   const marketData = !!auctionQueryData // && !market
  //     ? auctionQueryData?.find((data) => {
  //         return (
  //           String(data?.auctionAddress).trim().toLowerCase() ===
  //           String(urlAuctionAddress).trim().toLowerCase()
  //         )
  //       })
  //     : singleTangleAuctions

  //   if (!marketData) return
  //   // setAuctionData(res)
  //   const updatedMarket = populateMarketData(marketData, market)
  //   setMarket(updatedMarket)
  // }, [auctionQueryData, market, singleTangleAuctions, urlAuctionAddress])
  useEffect(() => {
    if (!urlAuctionAddress) return

    const marketData =
      // auctionQueryData ? auctionQueryData.find(
      //       (data) =>
      //         String(data?.auctionAddress).trim().toLowerCase() ===
      //         String(urlAuctionAddress).trim().toLowerCase()
      //     ) :
      singleTangleAuctions

    if (!marketData) return

    const updatedMarket = populateMarketData(marketData, market)
    setMarket(updatedMarket)
  }, [auctionQueryData, singleTangleAuctions, urlAuctionAddress])
  // Removed 'market' from dependencies to prevent re-triggering

  const { data: paymentCurrency } = useQuery<TangleTokenProps, QueryError>(
    ["tangleEditUserToken1Info", market?.paymentToken, l1ChainId],
    () => fetchTangleCurrency(market?.paymentToken, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!market?.paymentToken && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: auctionCurrency } = useQuery<TangleTokenProps, QueryError>(
    ["tangleEditUserToken0Info", market?.auctionToken, l1ChainId],
    () => fetchTangleCurrency(market?.auctionToken, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!market?.auctionToken && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const checkOwner = useCallback(async () => {
    const auctionType = market?.auctionType
    const auctionAddr = market?.auctionAddress

    if (!auctionAddr || !auctionType || !chainId) return
    if (!account) {
      setIsAdmin(null)
      setIsOperator(null)
      // setIsFundsReceiver(null)
      return
    }

    const storageKey0 = `ihubIsAdmin${chainId}${account}${auctionAddr}`
    const storageKey1 = `ihubIsOperator${chainId}${account}${auctionAddr}`
    // const storageKey2 = `ihubIsFundReceive${chainId}${account}${auctionAddr}`
    const storageValue0 = sessionStorage.getItem(storageKey0)
    const storageValue1 = sessionStorage.getItem(storageKey1)
    // const storageValue2 = sessionStorage.getItem(storageKey2)

    const isStorageAdmin = storageValue0 === "true"
    const isStorageOperator = storageValue1 === "true"
    if (
      storageValue0 !== null &&
      storageValue1 !== null
      // storageValue2 !== null
    ) {
      setIsAdmin(isStorageAdmin)
      setIsOperator(isStorageOperator)
      // setIsFundsReceiver(Boolean(storageValue2))
      return
    }

    const [isAdmin, isOperator] = await Promise.all([
      tangleship?.hasAdminRole(account, auctionType, auctionAddr),
      tangleship?.hasOperatorRole(account, auctionType, auctionAddr),
      // tangleship?.hasFundsReceiverRole(auctionType, auctionAddr),
    ])

    if (!isAdmin && !isOperator) {
      setIsAdmin(null)
      setIsOperator(null)
      // setIsFundsReceiver(null)
      return
    }
    sessionStorage.setItem(storageKey0, String(isAdmin))
    sessionStorage.setItem(storageKey1, String(isOperator))
    // sessionStorage.setItem(storageKey2, String(isFundsReceiver === account))
    setIsAdmin(isAdmin)
    setIsOperator(isOperator)
    // setIsFundsReceiver(isFundsReceiver === account)
  }, [
    account,
    chainId,
    market?.auctionAddress,
    market?.auctionType,
    tangleship,
  ])

  useEffect(() => {
    checkOwner()
  }, [checkOwner])

  const isCurrentTimeGreaterThan = (timestamp) => {
    // Convert given timestamp from seconds to milliseconds
    const givenTimeInMilliseconds = timestamp * 1000

    // Get the current time in milliseconds since UNIX epoch
    const currentTimeInMilliseconds = Date.now()

    // Check if current time is greater than the given timestamp
    return currentTimeInMilliseconds >= givenTimeInMilliseconds
  }
  const checkGreaterTime = async (startTime?: any) => {
    const dataTime = await isCurrentTimeGreaterThan(startTime)
    return dataTime
  }
  useEffect(() => {
    if (!market?.startTime) return
    checkGreaterTime(market?.startTime).then((res) => {
      if (!res) return
      setTimeGreater(res)
    })
  }, [market?.startTime])

  const warningMessageValue = watch("warningMessage", "")
  const descriptionMessageValue = watch("description", "")
  const handleWarningInputChange = (event) => {
    const inputValue = event.target.value

    // If input exceeds max length, truncate it
    if (inputValue.length > WARNING_MAX_CHAR) {
      const truncatedValue = inputValue.substring(0, WARNING_MAX_CHAR)
      setValue("warningMessage", truncatedValue)
      return
    }
    setValue("warningMessage", inputValue)
  }

  const handleDescriptionInputChange = (event) => {
    const inputValue = event.target.value
    // If input exceeds max length, truncate it
    if (inputValue.length > DESCRIPTION_MAX_CHAR) {
      const truncatedValue = inputValue.substring(0, WARNING_MAX_CHAR)
      setValue("description", truncatedValue)
      return
    }
    setValue("description", inputValue)
  }
  const countryListOptions = useMemo(() => countryList().getData(), [])
  //issue #2
  useEffect(() => {
    if (!liquidityNftId || !chainId || !tangleship) return

    const storageKey = `liquidityIDimage${chainId}${liquidityNftId}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setPoolNFT(storageValue)
      return
    }

    const addr = NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]
    tangleship
      .getURIFromNFT(addr, Number(liquidityNftId))
      .then((res: PoolNFTProps) => {
        if (!res?.image) return
        localStorage.setItem(storageKey, res.image)
        setPoolNFT(res.image)
      })
  }, [liquidityNftId, chainId, tangleship])

  const { explorerURL } = useExplorerInfo()
  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      if (file) {
        if (
          parseFloat(String(file?.size)) > parseFloat(String(10 * 1024 * 1024)) //file size validation done
        ) {
          setError("fileInput", { message: "File size exceeds 10MB" })
        } else {
          setFileName(file?.name)
          const blobUrl = URL.createObjectURL(file)

          Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: function (result) {
              const allValid = result?.data.every((data, index) => {
                const isAddressChecked = data?.wallet_address?.includes("0x")
                const isMaxAmountChecked =
                  typeof data?.max_token_allowance === "number"

                if (!isAddressChecked || !isMaxAmountChecked)
                  console.log(`Row ${index + 1} failed: `, data)

                return isAddressChecked && isMaxAmountChecked
              })

              if (allValid) {
                setCsvData(result.data)
                setFileIsInvalid(false)
              } else {
                setFileIsInvalid(true)
                setCsvData(undefined)
              }
            },
          })
        }
      }
    })
  }

  const onDrop = useCallback(
    async (e) => {
      e.preventDefault()
      setFileDragging(false)

      const files = e.dataTransfer.files
      if (files.length > 0) {
        parseCSV(files[0])
      }
    },
    [setValue]
  )

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    setFileDragging(false)
    if (file) {
      setFileName(file.name)
      parseCSV(file)
      setValue("fileInput", file)
    }
  }

  const onDragOver = useCallback((e) => {
    setFileDragging(true)
    e.preventDefault()
  }, [])
  const onDragEnd = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setFileDragging(false)
  }, [])

  const cancelAuction = async () => {
    setPendingTransaction(true)
    setModalTXError(false)
    setCancelAuctionPending(true)

    setCancelAuctionModal(true)

    tangleship
      .cancelAuction(market?.auctionType, market?.auctionAddress)
      .then((res) => {
        setCancelAuctionTx(res?.hash)
        setPendingTransaction(false)
        setModalTXError(false)
        setCancelAuctionPending(true)

        setCancelAuctionModal(false)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            setPendingTransaction(false)
            setModalTXError(false)
            TangleToast.success("Transaction successful", {
              description: `Auction cancelling successful`,
            })
          })
        } else {
          setCancelAuctionPending(false)
          setModalTXError(true)
          setCancelAuctionModal(true)
          setCancelAuctionTx(res?.hash)
          setPendingTransaction(false)

          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }

  const togglePurplelist = async (e?: any, status?: boolean) => {
    e.preventDefault()
    setPurplelistEnableLoading(true)
    tangleship
      .enablePurplelist(status, market?.auctionType, market?.auctionAddress)
      .then((res) => {
        if (enablePurplelistStatus) {
          setPurplelistEnableLoading(false)
          setEnablePurplelistStatus(false)
        } else {
          setPurplelistEnableLoading(false)
          setEnablePurplelistStatus(true)
        }
      })
  }

  const withdrawLPLauncherNFT = async () => {
    setModalTXError(false)
    setWithdrawTokenApproving(true)
    setWithdrawTokenPending(true)
    setWithdrawTokenApproved(false)
    setWithdrawTokenModal(true)
    setPendingTransaction(true)
    tangleship?.withdrawLPLauncherNFT(postAuctionLiqLauncher).then((res) => {
      if (res?.tx !== null) {
        setModalTXError(false)
        res.tx?.wait().then((receipt) => {
          setModalTXError(false)
          setWithdrawTokenApproving(false)
          setWithdrawTokenPending(false)
          setWithdrawTokenApproved(true)
          setWithdrawTokenModal(true)
          setWithdrawTokenTx(res?.hash)
          setPendingTransaction(false)
          refetchSingleAuction()
          TangleToast.success("Transaction successful", {
            description: `LP Withdrawal successful`,
          })
        })
      } else {
        setModalTXError(true)
        setWithdrawTokenApproving(false)
        setWithdrawTokenPending(false)
        setWithdrawTokenApproved(true)
        setWithdrawTokenModal(true)
        setWithdrawTokenTx(res?.hash)
        setPendingTransaction(false)

        TangleToast.error("Transaction rejected", {
          description: `User rejected transaction`,
        })
        return
      }
    })
  }

  const finalizeLPLauncher = async () => {
    setFinalizeLPApproving(true)
    setFinalizeLPPending(true)
    setFinalizeLPApproved(false)
    setFinalizeLPModal(true)
    setPendingTransaction(true)
    setModalTXError(false)
    tangleship
      .finalizeLPLauncher(
        postAuctionLiqLauncher,
        market?.currentTokenPrice,
        auctionCurrency?.address,
        paymentCurrency?.address,
        auctionCurrency?.decimals,
        market?.paymentToken?.decimals,
        launcherInfo?.feeTier
      )
      .then((res) => {
        if (res?.tx !== null) {
          setModalTXError(false)
          res.tx?.wait().then((receipt) => {
            setFinalizeLPApproving(false)
            setFinalizeLPPending(false)
            setModalTXError(false)
            setFinalizeLPApproved(true)
            setFinalizeLPModal(true)
            setFinalizeLPTx(res?.hash)
            setPendingTransaction(false)
            refetchSingleAuction()
            TangleToast.success("Transaction successful", {
              description: `Finalizing LP successful`,
            })
          })
        } else {
          setModalTXError(true)
          setFinalizeLPApproving(false)
          setFinalizeLPPending(false)
          setFinalizeLPApproved(true)
          setFinalizeLPModal(true)
          setFinalizeLPTx(res?.hash)
          setPendingTransaction(false)

          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }

  const fetchLauncherInfo = async (refetch = false) => {
    const storageKey = `ihubLauncherInfo${chainId}${urlAuctionAddress}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      return JSON.parse(storageValue)
    }

    const info = await tangleship?.getAllLauncherInfo(urlAuctionAddress)
    sessionStorage.setItem(storageKey, JSON.stringify(info))
    return info
  }

  const fetchLiqLauncherData = (refetch: boolean = false) => {
    return (dispatch: any) => {
      fetchLauncherInfo(refetch).then((info) => {
        dispatch(liqLauncherDataSuccess(info))
      })
    }
  }

  useEffect(() => {
    if (!tangleship || !chainId || !urlAuctionAddress) return
    dispatch(fetchLiqLauncherData(false))
  }, [tangleship, chainId, urlAuctionAddress])

  const selectedCountries = watch("bannedCountries") || []
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    setValue("bannedCountries", [...selectedCountries, value])
  }

  const openCountriesDropdown = useCallback(
    (e?: any) => {
      e.preventDefault()
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  const removeCountries = (countryName?: string) => {
    if (!countryName) return

    const value = selectedCountries.filter((data) => data !== countryName)
    setValue("bannedCountries", value)
  }

  const onSubmit: SubmitHandler<EditFormData> = (data) => {
    const documents = {
      website: watch("website"),
      whitepaper: watch("whitepaper"),
      tokenomics: watch("tokenomics"),
      category: watch("category"),
      icon: watch("icon"),
      amount: watch("amount"),
      desktopBanner: watch("desktopBanner"),
      mobileBanner: watch("mobileBanner"),
      warningMessage: watch("warningMessage"),
      description: watch("description"),
      twitter: watch("twitter"),
      telegram: watch("telegram"),
      discord: watch("discord"),
      github: watch("github"),
      blog: watch("blog"),
      bannedCountries: JSON.stringify(watch("bannedCountries")),
    }
    const filteredDocuments = Object.fromEntries(
      Object.entries(documents).filter(([_, val]) => !!val)
    )
    setSaveSettingApproving(true)
    setSaveSettingPending(true)
    setSaveSettingApproved(false)
    setSaveSettingModal(true)
    setPendingTransaction(true)
    setModalTXError(false)
    tangleship
      .setDocuments(
        Object.keys(filteredDocuments),
        Object.values(filteredDocuments) as string[],
        market?.auctionType,
        market?.auctionAddress
      )
      .then((res) => {
        if (res?.tx !== null) {
          setModalTXError(false)
          res.tx?.wait().then((receipt) => {
            setModalTXError(false)
            setSaveSettingApproving(false)
            setSaveSettingPending(false)
            setSaveSettingApproved(true)
            setSaveSettingModal(true)
            setSaveSettingTx(res?.hash)
            setPendingTransaction(false)

            TangleToast.success("Transaction successful", {
              description: `Settings saved`,
            })
          })
        } else {
          setModalTXError(true)
          setSaveSettingApproving(false)
          setSaveSettingPending(false)
          setSaveSettingApproved(true)
          setSaveSettingModal(true)
          setSaveSettingTx(res?.hash)
          setPendingTransaction(false)

          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }
  const checkPurplelist = useCallback(async () => {
    const auctionType = market?.auctionType
    const addr = market?.auctionAddress
    if (!addr || !auctionType) return

    const storageKey = `ihubPurplelist${chainId}${addr}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setPurplelistAddress(storageValue)
      return
    }

    const purplelist = await tangleship?.getPurplelistAddress(auctionType, addr)
    localStorage.setItem(storageKey, purplelist)
    setPurplelistAddress(purplelist)
  }, [chainId, market?.auctionAddress, market?.auctionType, tangleship])

  useEffect(() => {
    checkPurplelist()
  }, [checkPurplelist])

  const checkMaxAllocation = useCallback(
    async (refetch = false) => {
      const acc = watch("userAddress")
      const addr = purplelistAddress
      if (!addr || purplelistAddress === ZERO_ADDRESS) {
        setPurplelistMaxAllocation(null)
        return
      }
      if (!acc || !market?.paymentToken?.decimals) return

      const storageKey = `ihubMaxAlloc${chainId}${acc}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setPurplelistMaxAllocation(Number(storageValue))
        return
      }

      const data = await tangleship?.checkPurplelistMaxAllocation(acc, addr)
      const allocation =
        Number(data?._hex) / 10 ** market?.paymentToken?.decimals || 0
      sessionStorage.setItem(storageKey, String(allocation))
      setPurplelistMaxAllocation(allocation)
    },
    [
      chainId,
      market?.paymentToken?.decimals,
      purplelistAddress,
      tangleship,
      watch,
    ]
  )

  useEffect(() => {
    checkMaxAllocation(false)
  }, [checkMaxAllocation])

  useEffect(() => {
    if (!liquidityNftId || !chainId) return

    const storageKey = `auctionNftOwner${chainId}${liquidityNftId}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      const owner = storageValue?.trim()?.toLowerCase()
      setAuctionLPOwner(owner)
      return
    }

    tangleship?.getNFTOwner(Number(liquidityNftId)).then((data) => {
      const owner = data?.trim()?.toLowerCase()
      if (!owner) return
      sessionStorage.setItem(storageKey, owner)
      setAuctionLPOwner(owner)
    })
  }, [liquidityNftId, chainId, tangleship])

  useEffect(() => {
    if (!urlAuctionAddress || !paymentCurrency || !launcherInfo) return

    const postAuctionLiqLauncher = launcherInfo?.postAuctionLiqLauncher ?? null
    const liquidityNftId = launcherInfo?.liquidityNftId ?? null
    const unlockTimestamp = Number(launcherInfo?.unlock?._hex)
    const currentTimestamp = Math.floor(Date.now() / 1000)

    setPostAuctionLiqLauncher(postAuctionLiqLauncher)
    setLiquidityNftId(liquidityNftId)
    setLauncherInfoTimestamp(unlockTimestamp)
    setLauncherInfoUnlock(currentTimestamp > unlockTimestamp)
  }, [chainId, launcherInfo, paymentCurrency, urlAuctionAddress])

  useEffect(() => {
    if (!csvData || !market?.paymentToken?.decimals) return
    const walletAddresses =
      csvData?.length > 0
        ? csvData?.map((item) => item.wallet_address)
        : csvData?.wallet_address
    const maxAmounts =
      csvData?.length > 0
        ? csvData?.map((item) =>
            ethers.utils.parseUnits(
              String(item.max_token_allowance),
              market?.paymentToken?.decimals
            )
          )
        : csvData?.max_amount

    setCsvDataWallets(walletAddresses)
    setCsvDataAmount(maxAmounts)
  }, [csvData, market?.paymentToken?.decimals])

  const updatePurplelist = useCallback(() => {
    setUpdatePurpleListPending(true)
    setUpdatePurpleListApproving(true)
    setModalTXError(false)
    setUpdatePurpleListApproved(false)
    setUpdatePurpleListModal(true)
    setPendingTransaction(true)

    tangleship
      .editPurplelist(csvDataWallets, csvDataAmount, purplelistAddress)
      .then((res) => {
        if (res?.tx !== null) {
          setModalTXError(false)
          res.tx?.wait().then((receipt) => {
            setUpdatePurpleListApproving(false)
            setUpdatePurpleListPending(false)
            setUpdatePurpleListApproved(true)
            setUpdatePurpleListModal(true)
            setUpdatePurpleListTx(res?.hash)
            setPendingTransaction(false)
            setModalTXError(false)
            TangleToast.success("Transaction successful", {
              description: `Purplelist updated`,
            })
          })
        } else {
          setModalTXError(true)
          setUpdatePurpleListApproving(false)
          setUpdatePurpleListPending(false)
          setUpdatePurpleListApproved(true)
          setUpdatePurpleListModal(true)
          setUpdatePurpleListTx(res?.hash)
          setPendingTransaction(false)

          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }, [csvDataAmount, csvDataWallets, purplelistAddress])

  const onSubmitPurplelist = useCallback(
    (e?: any) => {
      e.preventDefault()
      updatePurplelist()
    },
    [updatePurplelist]
  )
  //debug here
  useEffect(() => {
    if (market?.isPurplelistEnabled)
      setEnablePurplelistStatus(market?.isPurplelistEnabled)
  }, [market?.isPurplelistEnabled])
  const checkWithdrawTime = async (marketInfo?: any, launcher?: any) => {
    const now = new Date()
    const marketStartTime = new Date(marketInfo)
    const lockTime = new Date(launcher)

    const disableTime = now < marketStartTime || now < lockTime
    return disableTime
  }
  useEffect(() => {
    if (!market?.startTime || !launcherInfo?.locktime) return
    checkWithdrawTime(market?.startTime, launcherInfo?.locktime).then((res) => {
      if (!res) return
      setWithdrawTimeDisabled(res)
    })
  }, [launcherInfo?.locktime, market?.startTime])
  const updatePurpleListRef = useRef<any>(null)
  const saveSettingsRef = useRef<any>(null)
  const withdrawTokenRef = useRef<any>(null)
  const finalizeLPRef = useRef<any>(null)
  const cancelAuctionRef = useRef<any>(null)

  const closeUpdatePurpleListPendingModal = () => {
    setUpdatePurpleListModal(false)
  }
  const closeUpdatePurpleListConfirmationModal = () => {
    setUpdatePurpleListModal(false)
  }

  const closeUpdatePurpleListModal = (event: any) => {
    if (updatePurpleListRef.current === event?.target) {
      setUpdatePurpleListModal(false)
    }
  }

  const closeCancelAuctionPendingModal = () => {
    setCancelAuctionModal(false)
  }
  const closeCancelAuctionConfirmationModal = () => {
    setCancelAuctionModal(false)
  }

  const closeCancelAuctionModal = (event: any) => {
    if (cancelAuctionRef.current === event?.target) {
      setCancelAuctionModal(false)
    }
  }

  const closeSaveSettingsPendingModal = () => {
    setSaveSettingModal(false)
  }
  const closeSaveSettingsConfirmationModal = () => {
    setSaveSettingModal(false)
  }

  const closeSaveSettingsModal = (event: any) => {
    if (saveSettingsRef.current === event?.target) {
      setSaveSettingModal(false)
    }
  }

  const closeWithdrawTokenPendingModal = () => {
    setWithdrawTokenModal(false)
  }
  const closeWithdrawTokenConfirmationModal = () => {
    setWithdrawTokenModal(false)
  }

  const closeWithdrawTokenModal = (event: any) => {
    if (withdrawTokenRef.current === event?.target) {
      setWithdrawTokenModal(false)
    }
  }
  const closeFinalizeLPEditModal = (event: any) => {
    if (finalizeLPRef.current === event?.target) {
      setFinalizeLPModal(false)
    }
  }

  const closeFinalizeLPEditPendingModal = () => {
    setFinalizeLPModal(false)
  }
  const closeFinalizeLPEditConfirmationModal = () => {
    setFinalizeLPModal(false)
  }

  return (
    <>
      {updatePurpleListModal ? (
        <>
          <UpdatePurplelistModal
            pending={updatePurpleListPending}
            link={updatePurpleListTx}
            modalTXError={modalTXError}
            modalRef={updatePurpleListRef}
            closeModal={closeUpdatePurpleListModal}
            closePendingModal={closeUpdatePurpleListPendingModal}
            closeConfirmationModal={closeUpdatePurpleListConfirmationModal}
          />
        </>
      ) : null}
      {withdrawTokenModal ? (
        <>
          <EditWithdrawModal
            pending={withdrawTokenPending}
            modalTXError={modalTXError}
            link={withdrawTokenTx}
            modalRef={withdrawTokenRef}
            closeModal={closeWithdrawTokenModal}
            closePendingModal={closeWithdrawTokenPendingModal}
            closeConfirmationModal={closeWithdrawTokenConfirmationModal}
          />
        </>
      ) : null}
      {finalizeLPModal ? (
        <>
          <EditFinalizeLPModal
            pending={finalizeLPPending}
            modalTXError={modalTXError}
            link={finalizeLPTx}
            modalRef={finalizeLPRef}
            closeModal={closeFinalizeLPEditModal}
            closePendingModal={closeFinalizeLPEditPendingModal}
            closeConfirmationModal={closeFinalizeLPEditConfirmationModal}
          />
        </>
      ) : null}
      {saveSettingModal ? (
        <>
          <EditSaveSettingsModal
            pending={saveSettingPending}
            modalTXError={modalTXError}
            link={saveSettingTx}
            modalRef={saveSettingsRef}
            closeModal={closeSaveSettingsModal}
            closePendingModal={closeSaveSettingsPendingModal}
            closeConfirmationModal={closeSaveSettingsConfirmationModal}
          />
        </>
      ) : null}
      {cancelAuctionModal ? (
        <>
          <EditCancelAuctionModal
            modalTXError={modalTXError}
            pending={cancelAuctionPending}
            link={cancelAuctionTx}
            modalRef={cancelAuctionRef}
            closeModal={closeCancelAuctionModal}
            closePendingModal={closeCancelAuctionPendingModal}
            closeConfirmationModal={closeCancelAuctionConfirmationModal}
          />
        </>
      ) : null}
      <TopBar status={AuctionStatus[market?.auctionStatus]}>
        <Cover>
          <TopRow>
            <TopTextContainer>
              <RowLeft>
                <GoBackButton link={`/invest/${urlAuctionAddress}`} />
              </RowLeft>
              <TopText>Edit Auction</TopText>{" "}
              <TopSubText>
                Please note, only the Auction admin and approved operators are
                able to edit any of the details below.
              </TopSubText>
            </TopTextContainer>
          </TopRow>
        </Cover>
      </TopBar>
      <Body>
        <Cover>
          <EditContainer>
            <InputsContainer>
              <SectionWrapper>
                <AuctionForm onSubmit={handleSubmit(onSubmit)}>
                  <EditAuctionTitle>Project details</EditAuctionTitle>
                  <InputGroup>
                    <TitleLabel htmlFor="website">
                      <Title>Website</Title>
                    </TitleLabel>

                    <Input
                      placeholder="https://example.com"
                      id="website"
                      type="text"
                      aria-invalid={errors.website ? "true" : "false"}
                      {...register("website")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>Whitepaper</Title>
                    <Input
                      placeholder="https://example.com/whitepaper.pdf"
                      id="whitepaper"
                      type="text"
                      {...register("whitepaper")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>Tokenomics</Title>
                    <Input
                      placeholder="https://example.com/tokenomics.pdf"
                      id="tokenomics"
                      type="text"
                      {...register("tokenomics")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>Category</Title>
                    <Select {...register("category")}>
                      <option selected disabled>
                        Select a category
                      </option>
                      <option value="DEFI">DeFi</option>
                      <option value="GAMING">Gaming</option>
                      <option value="UTILITY">Utility</option>
                      <option value="SOCIAL">Social</option>
                      <option value="GOVERNANCE">Governance</option>
                      <option value="NFTS">NFTs</option>
                    </Select>
                    <InfoSubText>
                      Selecting a category will increase your project's
                      findability. Please message us if you would like to
                      request a new category.
                    </InfoSubText>
                  </InputGroup>
                  <InputGroup>
                    <Title>Icon</Title>
                    <Input
                      {...register("icon")}
                      placeholder="https://example.com/icon.png"
                    />
                    <InfoSubText>
                      Icon must be smaller than 250kB (to keep page load
                      optimized). Dimensions recommended 128x128 or smaller.
                    </InfoSubText>
                  </InputGroup>
                  <InputGroup>
                    <Title>Desktop Banner</Title>
                    <Input
                      {...register("desktopBanner")}
                      placeholder="https://example.com/desktop-banner.png"
                    />
                    <InfoSubText>
                      Banner must be smaller than 250kB (to keep page load
                      optimized). Dimensions recommended 1280x196 or similar
                      ratio.
                    </InfoSubText>
                  </InputGroup>
                  <InputGroup>
                    <Title>Mobile Banner</Title>
                    <Input
                      {...register("mobileBanner")}
                      placeholder="https://example.com/mobile-banner.png"
                    />
                    <InfoSubText>
                      Banner must be smaller than 250kB (to keep page load
                      optimized). Dimensions recommended 768x360 or similar
                      ratio.
                    </InfoSubText>
                  </InputGroup>
                  <InputGroup>
                    <Title>Description</Title>
                    <TextArea
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tincidunt mi sed risus sodales eleifend. Fusce egestas libero quam, non blandit dui bibendum et."
                      rows={5}
                      {...register("description", {
                        maxLength: DESCRIPTION_MAX_CHAR,
                      })}
                      onChange={handleDescriptionInputChange}
                    />

                    {!!watch("description") ? (
                      <>
                        <InfoSubText>
                          {DESCRIPTION_MAX_CHAR -
                            (descriptionMessageValue?.length || 0)}{" "}
                          / 300 characters remaining.
                        </InfoSubText>
                      </>
                    ) : (
                      <InfoSubText>
                        Please provide a short description of your project. Max
                        300 characters.
                      </InfoSubText>
                    )}
                  </InputGroup>
                  <Divider />
                  <InfoBox>
                    <EditAuctionTitle>Social Media</EditAuctionTitle>
                  </InfoBox>
                  <InputGroup>
                    <Title>Twitter</Title>
                    <Input
                      placeholder="https://twitter.com/TangleSwap"
                      {...register("twitter")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>Telegram</Title>
                    <Input
                      placeholder="https://t.me/TangleSwap"
                      {...register("telegram")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>Discord</Title>
                    <Input
                      placeholder="https://discord.gg/tangleswap"
                      {...register("discord")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>GitHub</Title>
                    <Input
                      placeholder="https://github.com/TangleSwap"
                      {...register("github")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Title>Medium / Blog</Title>
                    <Input
                      placeholder="https://blog.tangleswap.exchange"
                      {...register("blog")}
                    />
                  </InputGroup>
                  <Divider />
                  <InfoBox>
                    <EditAuctionTitle>Settings</EditAuctionTitle>
                  </InfoBox>
                  <InputGroup>
                    <TitleLabel htmlFor="country">
                      <Title>
                        Country/Region <span>*</span>
                      </Title>
                    </TitleLabel>
                    <DropdownToggle onClick={openCountriesDropdown}>
                      <FormPurpleListName>Choose countries</FormPurpleListName>
                    </DropdownToggle>

                    {isOpen ? (
                      <>
                        <DropdownContainer>
                          {countryListOptions?.map(
                            (country: any, index: number) => (
                              <CheckboxContainer key={index}>
                                <input
                                  type="checkbox"
                                  value={country.value}
                                  onChange={handleCheckboxChange}
                                  {...register("bannedCountries")}
                                />
                                <FormCountryText>
                                  {country.label}
                                </FormCountryText>
                              </CheckboxContainer>
                            )
                          )}
                        </DropdownContainer>
                      </>
                    ) : null}

                    <InfoSubText>
                      <SelectedCountriesText>
                        Selected Countries:
                      </SelectedCountriesText>{" "}
                      <CountriesList>
                        {" "}
                        {selectedCountries?.map((data, index: number) => (
                          <CountriesBody key={index}>
                            <CountriesText>
                              {
                                countrySelectedName(data, countryListOptions)
                                  ?.label
                              }
                            </CountriesText>{" "}
                            <CountryRemoveCover
                              onClick={() => removeCountries(data)}
                            >
                              <CountryRemoveWrapper />
                              <CountryRemove />
                            </CountryRemoveCover>
                          </CountriesBody>
                        ))}
                      </CountriesList>
                    </InfoSubText>
                  </InputGroup>
                  <InputGroup>
                    <Title>Warning Message</Title>
                    <TextArea
                      placeholder="The content contained in this auction does not constitute an offer or sale of securities in or into any jurisdictions where it is unlawful to do so. Transfer of XXX tokens may be subject to legal restrictions under applicable laws."
                      rows={8}
                      {...register("warningMessage", {
                        maxLength: WARNING_MAX_CHAR,
                      })}
                      onChange={handleWarningInputChange}
                    />
                    {!!watch("warningMessage") ? (
                      <>
                        <InfoSubText>
                          {WARNING_MAX_CHAR -
                            (warningMessageValue?.length || 0)}{" "}
                          / 300 characters remaining.
                        </InfoSubText>
                      </>
                    ) : (
                      <>
                        <InfoSubText>
                          Optionally, add a legal warning for your project.
                          Maximum 300 characters.
                        </InfoSubText>
                      </>
                    )}
                  </InputGroup>
                  {!timeGreater &&
                  AuctionStatus[market?.auctionStatus] !== "FINISHED" &&
                  (isAdmin || isOperator) ? (
                    <>
                      <Divider />

                      <TitleRowWrapper>
                        <EditAuctionTitle>Danger Zone</EditAuctionTitle>
                      </TitleRowWrapper>
                      <CancelButton onClick={cancelAuction}>
                        <CancelTitle>Cancel this auction</CancelTitle>
                        <CancelText>
                          Once you cancel an auction there is no going back.
                          Please be certain!
                        </CancelText>
                      </CancelButton>
                    </>
                  ) : null}
                  <Divider />
                  <ButtonContainer>
                    <SaveButton disabled={!(isAdmin || isOperator)}>
                      {saveSettingApproving && !saveSettingApproved ? (
                        <>
                          <LoadingDots />
                        </>
                      ) : (
                        <> Save changes</>
                      )}
                    </SaveButton>
                  </ButtonContainer>
                </AuctionForm>
              </SectionWrapper>

              <SectionWrapper
                hide={
                  (!!market && purplelistAddress === undefined) ||
                  (!!market &&
                    purplelistAddress?.trim().toLowerCase() ===
                      ZERO_ADDRESS?.trim().toLowerCase())
                }
              >
                <AuctionForm
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  onDragLeave={onDragEnd}
                >
                  {" "}
                  <InfoBox>
                    <InfoSwitchWrapper>
                      <EditAuctionTitle>Purplelisting</EditAuctionTitle>

                      <AuctionToggle
                        isToggled={Boolean(enablePurplelistStatus)}
                        toggleOnButton={(e?: any) => togglePurplelist(e, true)}
                        toggleOffButton={(e?: any) =>
                          togglePurplelist(e, false)
                        }
                        purplelistEnableLoading={purplelistEnableLoading}
                      />
                    </InfoSwitchWrapper>
                  </InfoBox>
                  <PurpleListCover disabled={!enablePurplelistStatus}>
                    <InputGroup>
                      <Title>{market?.isPurplelistEnabled}</Title>
                    </InputGroup>
                    <InputGroup>
                      {/* <Title>Upload</Title> */}

                      <FormInputCover>
                        <UploadTextWrapper>
                          <UploadIcon />

                          {fileDragging ? (
                            <>
                              <UploadTitle>
                                <span>Drop your file here</span>
                              </UploadTitle>
                            </>
                          ) : (
                            <>
                              {" "}
                              {!!enablePurplelistStatus &&
                              fileIsInvalid !== undefined &&
                              !!fileName ? (
                                <>
                                  <UploadTitle>
                                    {" "}
                                    <span>File uploaded successfully</span>
                                  </UploadTitle>
                                  <UploadText>CSV up to 10MB</UploadText>
                                </>
                              ) : (
                                <>
                                  <UploadTitle>
                                    {" "}
                                    <span>Upload a file</span> or drag and drop
                                  </UploadTitle>
                                  <UploadText>CSV up to 10MB</UploadText>
                                </>
                              )}
                            </>
                          )}
                        </UploadTextWrapper>{" "}
                        <FormUploadLabel htmlFor="fileInput">
                          <FormUploadLabelText> </FormUploadLabelText>
                        </FormUploadLabel>
                        <FormUploadInput
                          type="file"
                          id="fileInput"
                          accept=".csv,text/csv"
                          {...register("fileInput", {
                            required:
                              !!enablePurplelistStatus &&
                              fileIsInvalid === undefined,
                          })}
                          onChange={handleFileChange}
                        />
                      </FormInputCover>
                      <>
                        {!!enablePurplelistStatus &&
                        fileIsInvalid !== undefined &&
                        !!fileName ? (
                          <FileInfoWrapper>
                            <FormInputName>{fileName}</FormInputName>
                          </FileInfoWrapper>
                        ) : null}
                      </>
                    </InputGroup>
                    <ButtonContainer align="flex-end">
                      <SaveButton
                        onClick={onSubmitPurplelist}
                        disabled={!enablePurplelistStatus}
                      >
                        {updatePurpleListApproving &&
                        !updatePurpleListApproved ? (
                          <>
                            <LoadingDots />
                          </>
                        ) : (
                          <> Update Purplelist</>
                        )}
                      </SaveButton>
                    </ButtonContainer>
                    <Divider />
                    <FormRowWrapper>
                      {" "}
                      <TitleRowWrapper>
                        {" "}
                        <Title>Purplelist Checker</Title>
                        <TitleInfo>
                          Check if an address is purplelisted
                        </TitleInfo>
                      </TitleRowWrapper>{" "}
                      <InputGroup>
                        <Input
                          disabled={!enablePurplelistStatus}
                          placeholder="User address"
                          {...register("userAddress")}
                        />
                      </InputGroup>
                    </FormRowWrapper>
                  </PurpleListCover>
                  {!!watch("userAddress") &&
                  purplelistMaxAllocation !== undefined ? (
                    <>
                      {" "}
                      <InfoSubText
                        color={
                          !!watch("userAddress") &&
                          purplelistMaxAllocation !== undefined
                            ? TangleColors.tangleGreen
                            : TangleColors.tangleYellow
                        }
                      >
                        <SubCoverText>Result:</SubCoverText>{" "}
                        {purplelistMaxAllocation > 0 ? (
                          <>
                            Success! Address is in the purplelist, with an
                            initial maximum allocation of{" "}
                            {`${toSignificantDigits(
                              purplelistMaxAllocation,
                              5,
                              true,
                              18
                            )} ${paymentCurrency?.symbol}`}
                            .
                          </>
                        ) : (
                          <>
                            Not this time, Explorer! Address is *not* in the
                            purplelist.
                          </>
                        )}
                      </InfoSubText>
                    </>
                  ) : null}
                </AuctionForm>
              </SectionWrapper>
            </InputsContainer>
            <CardPreview>
              <TopSubTitle>Example card</TopSubTitle>
              <AuctionCard
                address={market?.auctionAddress}
                isPurplelistEnabledStatus={market?.isPurplelistEnabled}
                auctionToken={market?.auctionToken}
                currentProgress={market?.currentProgress}
                totalCommitments={market?.totalCommitments}
                category={market?.category}
                logo={market?.documents?.find((doc) => doc[0] === "icon")?.[1]}
                auctionType={market?.auctionType}
                isFinalized={market?.isFinalized}
                auctionStatus={market?.auctionStatus}
                currentPrice={market?.currentTokenPrice}
                isSuccessful={market?.isSuccessful}
                auctionPrice={market?.currentAuctionPrice}
                amountForSale={market?.tokensForSale}
                paymentToken={market?.paymentToken}
                tokensRemaining={market?.tokensRemaining}
                endTime={market?.endTime}
                startTime={market?.startTime}
              />
            </CardPreview>
          </EditContainer>
          <ShowEditValuesContainer hide={!launcherInfo}>
            <ValueSectionWrapper>
              <ValuesWrapper>
                <EditAuctionTitle>Liquidity Position</EditAuctionTitle>
                <ValueFormRow>
                  <ValueFormLabel>
                    <GridContainer>
                      <ItemKey>Post-Auction Liquidity Launcher</ItemKey>{" "}
                      {!!postAuctionLiqLauncher ? (
                        <>
                          <TabHrefExternal
                            href={`${explorerURL?.explorer}/address/${postAuctionLiqLauncher}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormMainValue>
                              {postAuctionLiqLauncher}
                            </FormMainValue>
                            <ExternalIcon />
                          </TabHrefExternal>
                        </>
                      ) : (
                        <TabHrefNotLink>
                          {" "}
                          <FormMainValue>—</FormMainValue>
                        </TabHrefNotLink>
                      )}{" "}
                    </GridContainer>
                  </ValueFormLabel>
                </ValueFormRow>
                <ValueFormRow>
                  <ValueFormLabel>
                    <GridContainer>
                      <ItemKey>Liquidity Unlocks On</ItemKey>{" "}
                      {!!launcherInfo?.locktime && !!market?.endTime ? (
                        <>
                          {!!liquidityNftId ? (
                            <>
                              {" "}
                              <TabHrefLink to={`/pools/${liquidityNftId}`}>
                                <FormMainValue>
                                  {convertDate(
                                    market?.endTime + launcherInfo?.locktime
                                  )}
                                </FormMainValue>
                                <ExternalIcon />
                              </TabHrefLink>
                            </>
                          ) : (
                            <>
                              {" "}
                              <TabHrefNotLink>
                                <FormDateMainValue>
                                  {convertDate(
                                    market?.endTime + launcherInfo?.locktime
                                  )}
                                </FormDateMainValue>
                              </TabHrefNotLink>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <TabHrefNotLink>
                            {" "}
                            <FormMainValue>—</FormMainValue>
                          </TabHrefNotLink>
                        </>
                      )}
                    </GridContainer>
                  </ValueFormLabel>
                </ValueFormRow>
                {AuctionStatus[market?.auctionStatus] === "FINISHED" ? (
                  <>
                    {" "}
                    <ButtonContainer>
                      <SaveButton
                        disabled={
                          withdrawTimeDisabled ||
                          (withdrawTokenApproving && !withdrawTokenApproved)
                        }
                        onClick={
                          !launcherInfo?.launched
                            ? finalizeLPLauncher
                            : auctionLPOwner !== urlAuctionAddress
                            ? null
                            : withdrawLPLauncherNFT
                        }
                      >
                        {withdrawTokenApproving && !withdrawTokenApproved ? (
                          <>
                            <LoadingDots />
                          </>
                        ) : (
                          <>
                            {!launcherInfo?.launched ? (
                              <>
                                {market?.isSuccessful ? (
                                  <>Launch Liquidity Position</>
                                ) : (
                                  <>Cancel Liquidity Position</>
                                )}
                              </>
                            ) : (
                              <>
                                {!launcherInfoUnlock ? (
                                  <>
                                    Liquidity locked for:{" "}
                                    <TimeRemaining
                                      targetTimestamp={launcherInfoTimestamp}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {auctionLPOwner === urlAuctionAddress ? (
                                      <>Withdraw Liquidity Position</>
                                    ) : (
                                      <>
                                        <TabHrefLink
                                          to={`/pools/${liquidityNftId}`}
                                        >
                                          <FormMainValue>
                                            View Liquidity Position
                                          </FormMainValue>
                                          <ExternalIcon />
                                        </TabHrefLink>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </SaveButton>
                    </ButtonContainer>
                  </>
                ) : null}
              </ValuesWrapper>{" "}
              <ValuesNftCard>
                {liquidityNftId !== null ? (
                  <>
                    <InfoNftWrapper
                      href={`${
                        chainId === Chains.SEPOLIA_TEST
                          ? `${explorerURL?.explorer}/nft/${NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]}/${liquidityNftId}`
                          : `${explorerURL?.explorer}/token/${NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]}/instance/${liquidityNftId}`
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InfoNFTImage src={poolNFT} />
                    </InfoNftWrapper>
                  </>
                ) : (
                  <>
                    <NFTImageInfoCover>
                      <NFTImageText>
                        {!!market && !market?.isFinalized ? (
                          <>
                            Your LP NFT will show up here if your Auction
                            succeeds and once it will be finalized.
                          </>
                        ) : (
                          <>
                            Your Liquidity Position was not launched because
                            your Auction did not succeed.
                          </>
                        )}
                      </NFTImageText>
                    </NFTImageInfoCover>
                  </>
                )}
              </ValuesNftCard>
            </ValueSectionWrapper>
          </ShowEditValuesContainer>
        </Cover>
      </Body>
    </>
  )
}

const Body = styled.div`
  width: 100%;
  padding: 16px;
`

const TopTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TopText = styled.div`
  font-size: 32px;
  font-weight: 600;
  color: ${TangleColors.lighterText};
`

const TopSubText = styled(Subtitle)`
  margin: 20px 0 0 0;
  color: ${TangleColors.lighthover};
`

const TopSubTitle = styled(Subtitle)`
  margin: 0 0 20px 0;
  color: ${TangleColors.white};
`

const FormMainValue = styled(Header7)`
  margin: 0;
  color: ${TangleColors.lighthover};
`
const FormDateMainValue = styled(Header7)`
  margin: 0;
  color: ${TangleColors.tangleYellow};
`

const ValueFormRow = styled.div`
  display: flex;
  flex-direction: column;
`
const ValueFormLabel = styled.div``

const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ShowEditValuesContainer = styled.div<PoolNFTProps>`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: row;
  gap: 16px;
  width: 100%;
  margin: 32px 0;
  min-height: 450px;
  max-width: 865px;
`
const ValueSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  padding: 0;
  max-width: 1300px;
  border-radius: 20px;
  margin: 24px auto;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 32px;
  max-width: 1300px;
  border-radius: 20px;
  margin: 24px auto;
  background: ${TangleColors.swapBG};
`
const ValuesNftCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 32px;
  max-width: 350px;
  border-radius: 20px;
  margin: 24px auto;
  background: transparent;
`

const UploadIcon = styled(FaUpload)`
  width: 24px;
  height: 24px;

  color: ${TangleColors.white};
`

const UploadTitle = styled.div`
  font-size: 16px;
  color: ${TangleColors.white};
`

const FormCountryText = styled(Caption)`
  color: ${TangleColors.black};
  margin: 0 0 0 12px;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px auto;
  gap: 4px;
  width: 100%;
  max-width: 1100px;
`

const TitleLabel = styled.label``

const EditAuctionTitle = styled(Header5Gothic)`
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  margin: 24px auto;
  max-width: 300px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
`

const Title = styled(Subtitle)`
  color: ${TangleColors.lighterText};
  margin: 0 0 4px 0;
`

const InfoSubText = styled(CaptionSmallRegular)<EditPageProps>`
  color: ${(props) =>
    props.color ? `${props.color}` : `${TangleColors.grayDark}`};
  margin: 6px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
const SelectedCountriesText = styled(CaptionSmallRegular)`
  color: ${TangleColors.grayDark};
  margin: 0 6px 0 0;
  white-space: nowrap;
`

const SubCoverText = styled(CaptionSmallRegular)`
  color: ${TangleColors.white};
  margin: 0 4px 0 0;
`
const TitleRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const NFTImageInfoCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  min-height: 350px;
  position: relative;
  margin: 0 auto;
  border-radius: 28px;
  background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/invest.png")
    no-repeat center center/cover;
`

const NFTImageText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 4px 0 0;
  text-align: center;
  position: relative;
  z-index: 5;
  padding: 12px;
`

const FormRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
`
const PurpleListCover = styled.div<PoolNFTProps>`
  opacity: ${(props) => (props.disabled ? `0.3` : `1`)};
`
const TitleInfo = styled(CaptionSmall)<EditPageProps>`
  margin: 0;
  color: ${(props) =>
    props.formError ? ` ${TangleColors.red}` : ` ${TangleColors.darkGray}`};
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`

const SaveButton = styled(AuctionSaveButton)``

const ButtonContainer = styled.div<EditPageProps>`
  display: flex;
  max-width: 1100px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px auto;
`

const CancelButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  max-width: 1100px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${TangleColors.red};
  color: ${TangleColors.red};
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover {
    background: ${TangleColors.red};
    color: ${TangleColors.white} !important;
  }
`

const UploadTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: ${TangleColors.lightPurple};
  }
`

const UploadText = styled(CaptionTiny)`
  color: ${TangleColors.grayDark};
`

const FormUploadLabel = styled.label`
  color: ${TangleColors.white};
  margin: 0 0 6px 0;
`

const FormUploadLabelText = styled(Caption)`
  color: ${TangleColors.white};
`
const FormUploadInput = styled.input`
  width: 100%;
  max-width: 900px;

  min-height: 250px;
  color: ${TangleColors.white};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`
const CancelTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const CancelText = styled.div`
  font-size: 12px;
  font-weight: 400;
`

const CardPreview = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  @media only screen and (max-width: 850px) {
    max-width: 850px;
  }
`

const SectionWrapper = styled.div<EditPageProps>`
  display: ${(props) => (props.hide ? `none` : `flex`)};
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  border-radius: 20px;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  position: relative;
`

const AuctionForm = styled.form`
  display: flex;
  flex-direction: column;
`

const Input = styled.input<EditPageProps>`
  width: 100%;
  padding: 12px 8px;
  border-radius: 8px;
  border: ${(props) =>
    props.formError
      ? `0.5px solid ${TangleColors.red}`
      : `0.5px solid ${TangleColors.white}`};
  background: ${TangleColors.modalBG};
  color: ${TangleColors.lighterText};
  font-size: 16px;

  font-family: "CabinetGrotesk Medium";

  font-weight: 500;
  line-height: 1.2;

  &:focus {
    outline: none;
    border: 0.5px solid ${TangleColors.lighthover};
  }

  &::placeholder {
    color: ${TangleColors.grayDark};
    font-size: 16px;

    font-family: "CabinetGrotesk Medium";

    font-weight: 500;
    line-height: 1.2;

    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
`
const InfoNftWrapper = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 450px;
  border-radius: 32px !important;
`
const InfoNFTImage = styled.img`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 32px !important;
  object-fit: contain !important;
  height: 620px !important;

  @media only screen and (max-width: 800px) {
    min-height: 450px !important;
  }
`
const Select = styled.select<EditPageProps>`
  width: 100%;
  padding: 12px 8px;
  border-radius: 8px;

  background: ${TangleColors.modalBG};
  color: ${TangleColors.white};
  font-size: 16px;
  border: ${(props) =>
    props.formError
      ? `0.5px solid ${TangleColors.red}`
      : `0.5px solid ${TangleColors.white}`};
  font-family: "CabinetGrotesk Medium";

  font-weight: 500;
  line-height: 1.2;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.08691 9.5957L12.0869 15.5957L18.0869 9.5957" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');

  background-position: right 8px top 50%;

  background-repeat: no-repeat;
  background-size: 18px;
  transition: 0.4s ease-in;
  cursor: pointer;
  &:focus {
    outline: none;
    border: 0.5px solid ${TangleColors.lighthover};
  }

  &::placeholder {
    color: ${TangleColors.grayDark};
    font-size: 16px;

    font-family: "CabinetGrotesk Medium";

    font-weight: 500;
    line-height: 1.2;

    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
`

const TextArea = styled.textarea<EditPageProps>`
  height: 110px;
  resize: none;
  width: 100%;
  padding: 12px 8px;
  border-radius: 8px;
  border: ${(props) =>
    props.formError
      ? `0.5px solid ${TangleColors.red}`
      : `0.5px solid ${TangleColors.white}`};
  background: ${TangleColors.modalBG};
  color: ${TangleColors.lighterText};
  font-size: 16px;

  font-family: "CabinetGrotesk Medium";

  font-weight: 500;
  line-height: 1.2;

  &:focus {
    outline: none;
    border: 0.5px solid ${TangleColors.lighthover};
  }

  &::placeholder {
    color: ${TangleColors.grayDark};
    font-size: 16px;

    font-family: "CabinetGrotesk Medium";
    font-weight: 500;
    line-height: 1.2;
    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${TangleColors.cardSecondary};
  margin: 16px 0 8px 0;
`

const Cover = styled.div`
  max-width: 1232px;
  margin: 16px auto 32px auto;
  width: 100%;
`

const TopBar = styled.div<AuctionEditProps>`
  position: relative;
  padding: 12px 0;
  background: ${(props) =>
    props.status === "LIVE"
      ? TangleColors.dockBG
      : props.status === "UPCOMING"
      ? TangleColors.stake
      : props.status === "FINISHED"
      ? TangleColors.farmBG
      : TangleColors.modalBG};
  min-height: 250px;
  border-top: 2px solid ${TangleColors.white};
  border-bottom: 2px solid ${TangleColors.white};

  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 32px 0;
`

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 2em;
  }
  @media (min-width: 1025px) {
    padding: 2em 0;
  }
`
const RowLeft = styled.div`
  margin: 0 0 16px 0;
`

const InfoSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
`

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`

const ItemKey = styled(Subtitle)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const TabHrefExternal = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const TabHrefLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const TabHrefNotLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const ExternalIcon = styled(HiOutlineExternalLink)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.lighthover};
  margin: 0 0 0 8px;
`

const FormInputCover = styled.div`
  width: 100%;
  border-radius: 24px;
  border: 1px dotted ${TangleColors.white};
  height: 250px;
  padding: 32px;
  max-width: 900px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
const FormInputName = styled(Header6)`
  color: ${TangleColors.lighthover};
`

const FormPurpleListName = styled(CaptionSmall)`
  color: ${TangleColors.grayLight};
`
const FileInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0;
  max-width: 590px;
`

const DropdownContainer = styled.div`
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  background: ${TangleColors.white};
`

const DropdownToggle = styled.button<EditPageProps>`
  /* Add styles for the dropdown toggle button */
  background: ${TangleColors.white};
  height: 56px;
  width: 100%;
  padding: 12px 8px;
  border-radius: 8px;
  border: ${(props) =>
    props.formError
      ? `0.5px solid ${TangleColors.red}`
      : `0.5px solid ${TangleColors.white}`};
  background: ${TangleColors.modalBG};
  color: ${TangleColors.lighterText};
  font-size: 16px;

  font-family: "CabinetGrotesk Medium";

  font-weight: 500;
  line-height: 1.2;

  &:focus {
    outline: none;
    border: 0.5px solid ${TangleColors.lighthover};
  }

  &::placeholder {
    color: ${TangleColors.grayDark};
    font-size: 16px;

    font-family: "CabinetGrotesk Medium";

    font-weight: 500;
    line-height: 1.2;

    @media only screen and (max-width: 650px) {
      font-size: 14px;
    }
  }
`

const CheckboxContainer = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in;
  :hover {
    background: ${TangleColors.lighthover};
  }
`

const CountriesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
  width: 100%;
`
const CountriesBody = styled.div`
  padding: 10px 18px;
  border-radius: 999px;
  background: ${TangleColors.defi};
  margin: 4px 4px 0 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const CountriesText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`
const CountryRemoveWrapper = styled.input`
  width: 28px;
  height: 28px;
  border: none;
  background: transparent;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
`
const CountryRemoveCover = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 999px;
  background: ${TangleColors.lighthover};
  margin: 0 0 0 6px;
`
const CountryRemove = styled(GrClose)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-85%, -50%);
  color: ${TangleColors.black};
  margin: 0 0 0 6px;

  cursor: pointer;
  path {
    stroke: ${TangleColors.black};
    fill: ${TangleColors.black};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.heroCTA};
      fill: ${TangleColors.heroCTA};
    }
  }
`
export default React.memo(AuctionEditPage)
