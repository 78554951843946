import {
  BLOCK_TRANSACTION_STATE,
  SELECTED_WALLET,
  USER_WALLET_CONNECTION,
  USER_WALLET_STATE,
  WALLET_MODAL_STATE,
} from "./actionsTypes"

export const updateSelectedWallet = (selectedWallet) => {
  return {
    type: SELECTED_WALLET,
    selectedWallet: selectedWallet,
  }
}

export const proWalletSelected = (tangleswapWallet: any) => {
  return {
    type: USER_WALLET_CONNECTION,
    tangleswapWallet: tangleswapWallet,
  }
}

export const updateConnectWalletState = (tangleswapWalletState: any) => {
  return {
    type: USER_WALLET_STATE,
    tangleswapWalletState: tangleswapWalletState,
  }
}

export const txUpdateBlockedTransactionState = (
  blockTransactionState: boolean
) => {
  return {
    type: BLOCK_TRANSACTION_STATE,
    blockTransactionState: blockTransactionState,
  }
}
export const walletModalState = (activeNetwork: boolean) => {
  return {
    type: WALLET_MODAL_STATE,
    activeNetwork: activeNetwork,
  }
}
export const selectedWalletUpdate = (selectedWallet) => {
  return (dispatch) => {
    dispatch(updateSelectedWallet(selectedWallet))
  }
}
export const updateWalletModalState = (activeNetwork: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleWalletNetworkMode", activeNetwork)
    dispatch(walletModalState(activeNetwork))
  }
}

export const updateTangleswapWalletMode = (walletContext: any) => {
  return (dispatch: any) => {
    const serializedContext = JSON.stringify(walletContext)
    localStorage.setItem("tangleswapWallet", serializedContext)

    dispatch(proWalletSelected(serializedContext))
  }
}

export const proCheckConnectedWalletStatus = () => {
  return (dispatch: any) => {
    const tangleswapWallet = JSON.parse(
      localStorage.getItem("tangleswapWallet")
    )

    if (tangleswapWallet === null || tangleswapWallet === "false") {
      dispatch(proWalletSelected(tangleswapWallet))
    } else {
      // Assuming you've stored as "true" string for true values
      dispatch(proWalletSelected(tangleswapWallet))
    }
  }
}

export const checkConnectedWallet = (l1Account?: any) => {
  return (dispatch: any) => {
    const connectedTangleswapWallet = localStorage.getItem("connectedWallet")
    const connecting = Boolean(
      (connectedTangleswapWallet === undefined ||
        connectedTangleswapWallet === null) &&
        (l1Account === null || l1Account === undefined)
    )
    dispatch(updateConnectWalletState(connecting))
  }
}

export const proCheckSelectedNetworkStatus = () => {
  return (dispatch: any) => {
    const tangleSwapActiveNetwork = localStorage.getItem(
      "tangleWalletNetworkMode"
    )
    let networkModeNumber =
      tangleSwapActiveNetwork === null || tangleSwapActiveNetwork === "null"
        ? 1
        : parseInt(tangleSwapActiveNetwork, 10)

    // If the parsed number is not a valid number, default to 1
    if (isNaN(networkModeNumber)) {
      networkModeNumber = 1
    }

    dispatch(updateWalletModalState(networkModeNumber))
  }
}
