import {
  Chains,
  FIXED_RANGE_FARMS,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
} from "@tangleswap/sdk"

export const getFarmConfig = async (
  chainId: number,
  l1ChainId: any,
  account?: string,
  tangleship?: any
) => {
  const farmAddresses = FIXED_RANGE_FARMS[chainId]

  const farmInfos = await Promise.all(
    farmAddresses.map(async (farmAddress: string) => {
      const rawInfo = await tangleship?.getFarmInfoWithUserPendingRewards(
        farmAddress,
        account
      )

      const rawAddresses = [
        rawInfo?.token0Address,
        rawInfo?.token1Address,
        rawInfo?.rewardsAddress0,
        rawInfo?.rewardsAddress1,
      ]

      const [token0, token1, reward0, reward1] = rawAddresses.map(
        (addr: string) => {
          const wrapAddr = WRAPPED_ADDRESS[chainId]
          const isWrapped = addr?.toLowerCase() === wrapAddr.toLowerCase()
          const isEvm = ![Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(
            l1ChainId
          )
          return isWrapped && isEvm ? NATIVE_SYMBOL[chainId] : addr
        }
      )

      const wrappedInfo = {
        ...rawInfo,
        token0Address: token0,
        token1Address: token1,
        rewardsAddress0: reward0,
        rewardsAddress1: reward1,
      }
      return wrappedInfo
    })
  )

  return farmInfos
}
