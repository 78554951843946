import { NavTypes, navlinkData } from "data/NavbarData"
import { useEffect, useRef, useState } from "react"
import { GrClose } from "react-icons/gr"
import { FiMenu } from "react-icons/fi"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Header4, Subtitle } from "styles/TextStyles"
import { motion, Variants } from "framer-motion"
import AccountNavbar from "./AccountNavbar"
import MobileAccountNavbar from "./MobileAccountNavbar"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { FiMoreHorizontal } from "react-icons/fi"
import NavbarExternalLinks from "./NavbarExternalLinks"
const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
}

interface MobileNavProps {
  open?: boolean
  swapClass?: boolean
  navbarPosition?: boolean
  siteWarningState?: boolean
  mobileNavbarActive?: boolean
}

//let window: any
const Navbar = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [openExternalMenu, setOpenExternalMenu] = useState<boolean>(false)
  const [navLinksURL] = useState<NavTypes[]>(navlinkData)
  const externalLinkRef = useRef<any>(null)
  const router = useLocation()
  const openMobileMenu = () => {
    setOpenMenu(true)
  }
  const closeMobileMenu = () => {
    setOpenMenu(false)
  }
  const toggleExternalMenu = () => {
    setOpenExternalMenu(!openExternalMenu)
  }
  useEffect(() => {
    setOpenMenu(false)
  }, [router])
  const location = useLocation()
  const swapClass: any = String(location.pathname)?.toLowerCase() === "/swap"
  const isActive = (path) => location.pathname === path
  const homeTrue = location.pathname === "/"

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        externalLinkRef.current &&
        !externalLinkRef.current.contains(e.target)
      ) {
        setOpenExternalMenu(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  return (
    <>
      <NavContainerCover navbarPosition={Boolean(homeTrue)}>
        <NavCoverWrapper navbarPosition={Boolean(homeTrue)}>
          <NavLogoLinkWrapper>
            <LogoCover>
              <NavLinkLogoHref to="/">
                <LogoImg>
                  <NavbarLogo
                    src="https://d3m3d54t409w7t.cloudfront.net/logos/Logo_White_Alpha.webp"
                    alt="tangleswap white logo"
                  />
                </LogoImg>
              </NavLinkLogoHref>
            </LogoCover>
            <NavLinksBody open={openMenu} swapClass={swapClass}>
              <NavCover>
                {navLinksURL.map((nav) => {
                  return (
                    <NavCoverLI key={nav.id}>
                      {nav.external === true ? (
                        <NavLinkHref
                          className={isActive ? "activeNav" : null}
                          href={`${nav.link}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <NavCoverLIText>{nav.text}</NavCoverLIText>
                        </NavLinkHref>
                      ) : (
                        <>
                          <NavLinkInApp
                            className={isActive ? "activeNav" : null}
                            to={`/${nav.link}`}
                          >
                            <NavCoverLIText>{nav.text}</NavCoverLIText>
                          </NavLinkInApp>
                        </>
                      )}
                    </NavCoverLI>
                  )
                })}
                <NavbarExternal ref={externalLinkRef}>
                  <NavbarExternalIconCover onClick={toggleExternalMenu}>
                    <MoreExternalIcon />
                  </NavbarExternalIconCover>

                  <NavbarExternalLinks openExternalMenu={openExternalMenu} />
                </NavbarExternal>
              </NavCover>
            </NavLinksBody>
          </NavLogoLinkWrapper>
          <MobileNetworkCover>
            <MobileAccountNavbar />
          </MobileNetworkCover>
          {/* <MobileNetwork openNetworkBottomModal={openNetworkBottomModal} /> */}
          <AccountMobileMenu
            mobileNavbarActive={openMenu}
            onClick={openMobileMenu}
          >
            <MenuToggleIcon />
          </AccountMobileMenu>

          <AccountsWrapper>
            <AccountNavbar />
          </AccountsWrapper>
        </NavCoverWrapper>
        <MobileMenuClose
          mobileNavbarActive={openMenu}
          onClick={closeMobileMenu}
        >
          <CloseMobileMenuIcon mobileNavbarActive={openMenu} />
        </MobileMenuClose>

        <MainMobileNavCover
          mobileNavbarActive={openMenu}
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
                delayChildren: 0.3,
                staggerChildren: 0.05,
              },
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            },
          }}
        >
          {navLinksURL.map((nav) => (
            <MobileNavCoverLI variants={itemVariants} key={nav.id}>
              {nav.external === true ? (
                <MobileNavLinkHref
                  href={`${nav.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MobileNavCoverLIText>{nav.text}</MobileNavCoverLIText>
                </MobileNavLinkHref>
              ) : (
                <>
                  <MobileNavLinkInApp to={`/${nav.link}`}>
                    <MobileNavCoverLIText>{nav.text}</MobileNavCoverLIText>
                  </MobileNavLinkInApp>
                </>
              )}
            </MobileNavCoverLI>
          ))}

          <MobileAccountsWrapper>
            <AccountNavbar />
          </MobileAccountsWrapper>
        </MainMobileNavCover>
      </NavContainerCover>
    </>
  )
}

const NavContainerCover = styled.div<MobileNavProps>`
  position: ${(props) => (props.navbarPosition ? "fixed" : "relative")};
  top: 0;
  left: 0;
  z-index: 273;
  width: 100%;
  height: 96px;
  background: transparent;
  display: flex;

  opacity: 1;
  justify-content: center;
  align-items: center;

  padding: 8px 32px;

  .activeNav a.active {
    color: ${TangleColors.white} !important;
    background: ${TangleColors.heroCTA} !important;
    padding: 8px 10px;
    border-radius: 8px;
    position: relative;
    z-index: 3;
    transition: 0.4s ease-in;
    border: 1px solid transparent;
    :hover {
      color: ${TangleColors.lighthover} !important;
      border: 1px solid ${TangleColors.white}!important;
    }
    @media only screen and (max-width: 800px) {
      width: 100%;

      max-width: 150px;
      margin: 4px auto;
      height: 40px;
    }
  }
  @media only screen and (max-width: 850px) {
    height: 64px;
    margin: 12px 0 0 0;
  }
`
const NavCoverWrapper = styled.div<MobileNavProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  backdrop-filter: blur(20px);
  background: linear-gradient(
    rgba(209, 209, 209, 0.15) 0%,
    rgba(102, 102, 102, 0.05) 100%
  );
  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.11) 0px 0.636953px 0.636953px -0.9375px,
    rgba(0, 0, 0, 0.1) 0px 1.9316px 1.9316px -1.875px,
    rgba(0, 0, 0, 0.09) 0px 5.10612px 5.10612px -2.8125px,
    rgba(0, 0, 0, 0.04) 0px 16px 16px -3.75px;
  margin: 32px 0 0 0;
  padding: 10px 24px;
  position: relative;
  z-index: 99;
`
const LogoCover = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;

  width: 100%;
  max-width: 48px;
  height: 48px;
  object-fit: contain;
  margin: -20px 0 0 40px;
  @media only screen and (max-width: 850px) {
    height: 56px;
    display: flex;
    margin: 0 0 0 -28px;
  }
`
const NavLinkLogoHref = styled(Link)`
  width: 100%;
  cursor: pointer;

  object-fit: contain;
  max-width: 48px;
  height: 48px;
  width: 100%;
`
const LogoImg = styled.div`
  max-width: 48px;
  height: 48px;
  width: 100%;

  object-fit: contain;
  transition: 0.4s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 18px;
  position: relative;
  z-index: 3;
  svg {
    object-fit: contain;
  }
`
const AccountsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  margin: 0;
  padding: 0 20px 0 0;
  position: relative;
  z-index: 820;
  opacity: 1;
  @media only screen and (max-width: 950px) {
    display: none;
    opacity: 0;
  }
`

const MobileNetworkCover = styled.div`
  display: none;
  flex-direction: row;
  gap: 6px;
  margin: 0 2px 0 0 0;

  @media only screen and (max-width: 850px) {
    display: flex;
  }
`
const MobileAccountsWrapper = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 24px auto 0 auto;
  max-width: 440px;
  padding: 0 20px 0 0;
  position: relative;
  z-index: 90;
  opacity: 0;
  @media only screen and (max-width: 950px) {
    display: flex;
    opacity: 1;
  }
`

const NavLogoLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  width: 100%;
  margin: -24px 0 0 -24px;
  @media only screen and (max-width: 850px) {
    max-width: 64px;
    margin: 0;
  }
`

const NavLinkHref = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
`

const NavLinkInApp = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const NavLinksBody = styled.div<MobileNavProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TangleColors.black};
  position: relative;
  z-index: 3;
  margin: 28px 0 0 32px;

  @media only screen and (max-width: 1200px) {
    margin: 20px 0 0 0;
  }
`
const NavCover = styled.ul`
  width: 100%;
  max-width: 720px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const NavCoverLIText = styled(Subtitle)`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  position: relative;
  z-index: 3;

  :hover {
    color: ${TangleColors.lighthover};
  }
  a {
    color: ${TangleColors.white};
    transition: 0.4s ease-in;
    :hover {
      color: ${TangleColors.lighthover};
    }
  }
  @media only screen and (max-width: 800px) {
    color: ${TangleColors.white};
    margin: 16px 0;
    width: 100%;
  }
  transition: 0.4s ease-in;
`
const NavCoverLI = styled(Subtitle)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 14px;
  white-space: nowrap;

  color: ${TangleColors.white};

  transition: 0.4s ease-in;
  @media only screen and (max-width: 800px) {
    width: 100%;
    max-width: 150px;
    margin: 4px auto;
    height: 32px;
  }
`

const NavbarLogo = styled.img`
  width: 100%;
  object-fit: contain;
  border-radius: 9999px;
  position: relative;
  z-index: 3;
  max-width: 96px;
  height: 96px;
`

const AccountMobileMenu = styled.span<MobileNavProps>`
  width: 48px;
  height: 48px;

  position: relative;
  z-index: 988;
  display: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 850px) {
    display: ${(props) => (props.mobileNavbarActive ? "none" : "flex")};
  }
`
const MenuToggleIcon = styled(FiMenu)`
  width: 28px;
  height: 28px;
  color: ${TangleColors.white};
`

const MainMobileNavCover = styled(motion.ul)<MobileNavProps>`
  width: 100%;
  max-width: 1000px;
  height: 600px;

  display: none;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: ${TangleColors.swapBG};
  @media only screen and (max-width: 850px) {
    display: ${(props) => (props.mobileNavbarActive ? "flex" : "none")};
    flex-direction: column;
    color: ${TangleColors.white};
  }
`
const CloseMobileMenuIcon = styled(GrClose)<MobileNavProps>`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
  cursor: pointer;
  transition: 0.4s ease-in;
  display: ${(props) => (props.mobileNavbarActive ? "flex" : "none")};
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
`
const MobileMenuClose = styled.div<MobileNavProps>`
  color: ${TangleColors.white};
  width: 48px;
  height: 48px;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  right: 12px;
  top: 24px;
  @media only screen and (max-width: 850px) {
    display: ${(props) => (props.mobileNavbarActive ? "flex" : "none")};
  }
`
const MobileNavCoverLIText = styled(Header4)`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  position: relative;
  z-index: 3;
  margin: 24px 0;
  :hover {
    color: ${TangleColors.lighthover};
  }
  a {
    color: ${TangleColors.white};
    transition: 0.4s ease-in;
    :hover {
      color: ${TangleColors.lighthover};
    }
  }
  @media only screen and (max-width: 800px) {
    color: ${TangleColors.white};
    margin: 16px 0;
    width: 100%;
  }
  transition: 0.4s ease-in;
`
const MobileNavCoverLI = styled(motion.li)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 14px;
  white-space: nowrap;

  color: ${TangleColors.white};

  transition: 0.4s ease-in;
  @media only screen and (max-width: 800px) {
    width: 100%;
    max-width: 150px;
    margin: 4px auto;
    height: 32px;
  }
`

const MobileNavLinkHref = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  margin: 24px 0;
`

const MobileNavLinkInApp = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  margin: 24px 0;

  :hover {
    color: ${TangleColors.lighthover};
  }
`

const NavbarExternal = styled.div`
  position: relative;
  z-index: 999 !important;
  transition: 0.4s ease-in;
`
const NavbarExternalIconCover = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  margin: 0 0 0 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;

  transition: 0.4s ease-in;
  border-radius: 999px;

  border: 1px solid ${TangleColors.lighthover};
  :hover {
    color: ${TangleColors.lighthover};
    background: ${TangleColors.dockBG};
  }
`
const MoreExternalIcon = styled(FiMoreHorizontal)`
  width: 26px;
  height: 26px;
  color: ${TangleColors.lighthover};

  ${NavbarExternalIconCover}:hover & {
    color: ${TangleColors.white};
  }
`

export default Navbar
