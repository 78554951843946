import React, { FC, useCallback, useMemo, useState } from "react"

import { batch } from "react-redux"
import { AutoColumn } from "./ChartColumn"
import { Bound, FeeAmount } from "./types"
import { isInvalid, wrapAddr, TICK_LENS_ADDRESS } from "@tangleswap/sdk"
import { TangleColors } from "styles/ColorStyles"
import { format } from "d3"

import Chart from "./Chart"
import { useWSCContext } from "context/MilkomedaContext"

import ChartLoading from "./ChartLoading"
import styled from "styled-components"
import useDensityChartData from "./useDensityChartData"
import ChartError from "./ChartError"
import ChartFormattedDataError from "./ChartFormattedDataError"
import ChartActivePoolLoading from "./ChartActivePoolLoading"
import { ZOOM_LEVELS } from "./Zoomlevels"

interface ChartProps {
  price?: any
  feeAmount?: any
  currencyA?: any
  currencyB?: any
  priceLower?: any
  priceUpper?: any
  poolAddress?: any
  poolLiquidity?: any
  slot0?: any
  token0Decimals: any
  token1Decimals: any
  minUiTextPrice?: any
  maxUiTextPrice?: any
  interactive?: boolean
  ticksAtLimit: any
  onLeftRangeInput: (value: string) => void
  onRightRangeInput: (value: string) => void
  address0: string | undefined
  address1: string | undefined
  poolNeedsInitialization?: boolean
  userTradingMode?: boolean
}

const LiquidityChartRangeInput: FC<ChartProps> = (props) => {
  const {
    price,
    feeAmount,
    token0Decimals,
    token1Decimals,
    poolAddress,
    poolLiquidity,
    slot0,
    ticksAtLimit,
    interactive,
    priceLower,
    priceUpper,
    onLeftRangeInput,
    onRightRangeInput,
    address0,
    address1,
    currencyA,
    currencyB,
    poolNeedsInitialization,
    userTradingMode,
  } = props

  const { chainId } = useWSCContext()
  const wrappedAddress0 = wrapAddr(address0, chainId).tokenAddress
  const wrappedAddress1 = wrapAddr(address1, chainId).tokenAddress
  const isSorted = isInvalid([wrappedAddress0, wrappedAddress1])
    ? null
    : wrappedAddress0.toLowerCase() < wrappedAddress1.toLowerCase()
  const isSameBlockhainOrder = isInvalid([address0, address1])
    ? undefined
    : address0?.toLowerCase() < address1?.toLowerCase()

  const {
    formattedData,
    isLoading,
    error: chartError,
  } = useDensityChartData(
    chainId,
    poolAddress,
    TICK_LENS_ADDRESS[chainId],
    slot0,
    poolLiquidity,
    token0Decimals,
    token1Decimals,
    feeAmount,
    isSameBlockhainOrder,
    userTradingMode
  )

  const [isDefaultRangeSet, setIsDefaultRangeSet] = useState<boolean>(false)

  const onBrushDomainChangeEnded = useCallback(
    (domain: [number, number], mode: string | undefined) => {
      // Use this flag to avoid conflicts while setting default Min/Max values.
      setTimeout(() => {
        setIsDefaultRangeSet(true)
      }, 100)

      let leftRangeValue = Number(domain[0])
      let rightRangeValue = Number(domain[1])

      if (leftRangeValue <= 0) leftRangeValue = 1 / 10 ** 6

      batch(() => {
        // simulate user input for auto-formatting and other validations
        if (
          !ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER] &&
          leftRangeValue > 0 &&
          isDefaultRangeSet
        ) {
          onLeftRangeInput(String(leftRangeValue))
        }

        if (
          !ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER] &&
          rightRangeValue > 0 &&
          isDefaultRangeSet
        ) {
          onRightRangeInput(String(rightRangeValue))
        }
      })
    },
    [
      isSorted,
      onLeftRangeInput,
      onRightRangeInput,
      ticksAtLimit,
      isDefaultRangeSet,
    ]
  )

  const brushDomain: [number, number] | undefined = useMemo(() => {
    const leftPrice = priceLower
    const rightPrice = priceUpper

    // return leftPrice && rightPrice
    //   ? [
    //       parseFloat(toSignificantDigits(leftPrice, 4, true, 18)),
    //       parseFloat(toSignificantDigits(rightPrice, 4, true, 18)),
    //     ]
    //   : undefined
    return leftPrice && rightPrice
      ? [parseFloat(leftPrice), parseFloat(rightPrice)]
      : undefined
  }, [priceLower, priceUpper])

  const brushLabelValue = useCallback(
    (d: "w" | "e", x: number) => {
      if (!price) return ""

      if (d === "w" && ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER])
        return "0"
      if (d === "e" && ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER])
        return "∞"

      const percent =
        (x < price ? -1 : 1) *
        ((Math.max(x, price) - Math.min(x, price)) / price) *
        100

      return price
        ? `${format(Math.abs(percent) > 1 ? ".2~s" : ".2~f")(percent)}%`
        : ""
    },
    [isSorted, price, ticksAtLimit]
  )

  const isUninitialized =
    (formattedData === undefined && !isLoading) ||
    (!isLoading && !currencyA && !currencyB) ||
    !currencyA ||
    !currencyB ||
    !poolAddress ||
    !TICK_LENS_ADDRESS[chainId]

  const interactiveValue = interactive && Boolean(formattedData?.length)

  return (
    <AutoColumn gap="md" style={{ minHeight: "200px" }}>
      {" "}
      <ChartCover>
        {isUninitialized ? (
          <>
            <ChartLoading />
            {/* <ChartActivePoolLoading /> */}
          </>
        ) : (
          <>
            {!poolNeedsInitialization ? (
              <>
                {isLoading ? (
                  <>
                    {" "}
                    <ChartLoading />
                  </>
                ) : null}
                {!isLoading && chartError ? (
                  <>
                    {" "}
                    <ChartFormattedDataError />
                  </>
                ) : null}
                {!isLoading &&
                chartError &&
                formattedData &&
                formattedData?.length < 1 ? (
                  <>
                    {" "}
                    <ChartError />
                  </>
                ) : null}
                {!isLoading && formattedData !== undefined ? (
                  <>
                    {" "}
                    <ChartWrapper>
                      {" "}
                      <Chart
                        data={{ series: formattedData, current: Number(price) }}
                        dimensions={{ width: 400, height: 217.5 }}
                        margins={{ top: 20, right: 0, bottom: 10, left: 0 }}
                        styles={{
                          area: {
                            selection: TangleColors.lighthover,
                          },
                          brush: {
                            handle: {
                              west: TangleColors.heroCTA,
                              east: TangleColors.lightPurple,
                            },
                          },
                        }}
                        zoomLevels={ZOOM_LEVELS[feeAmount ?? FeeAmount.MEDIUM]}
                        interactive={interactiveValue}
                        brushLabels={brushLabelValue}
                        brushDomain={brushDomain}
                        onBrushDomainChange={onBrushDomainChangeEnded}
                        ticksAtLimit={ticksAtLimit}
                      />
                    </ChartWrapper>
                  </>
                ) : null}
              </>
            ) : (
              <>
                {" "}
                <ChartError />
              </>
            )}
          </>
        )}
      </ChartCover>
    </AutoColumn>
  )
}

const ChartCover = styled.div`
  /* max-width: 2000px !important;
  width: 100% !important;
  margin: 0 auto; */
`
const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  svg {
    /* margin: -12px 0 0 0;
    max-width: 1000px !important;
    width: 100% !important;
    height: 280px !important; */
  }
`
export default LiquidityChartRangeInput
