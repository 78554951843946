import SwapContainer from "components/swap/SwapContainer"
import styled from "styled-components"
import { useEffect } from "react"

const Swap = () => {
  useEffect(() => {
    document.title = "Trade"
  }, [])
  return (
    <>
      <Body>
        <SwapContainer />
      </Body>
    </>
  )
}
const Body = styled.div`
  min-height: 70vh;
  /* margin: 96px 0 0 0; */
  @media only screen and (max-width: 700px) {
    min-height: 100vh;
    height: 100%;
    padding: 16px;
    overflow: auto;
  }
`

export default Swap
