import React, { FC, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import { Caption } from "styles/TextStyles"

import TimeRemaining from "components/LaunchPad/CreateAuction/components/TimeRemaining"
import { dateFromUnixTimestamp } from "components/farm/hooks/dateFromUnixTimestamp"
interface TLPProps {
  startBlock?: number
  endBlock?: number
}
const RewardsAsteroidFarm: FC<TLPProps> = (props) => {
  const { startBlock, endBlock } = props
  const currentDate = Math.floor(Date.now() / 1000)
  const [timeCheck, setTimeCheck] = useState<boolean>(false)

  useEffect(() => {
    setTimeCheck(startBlock < currentDate)
  }, [currentDate, startBlock])
  const isEndedValue = useMemo(() => {
    return (
      dateFromUnixTimestamp(Number(endBlock)) <= 0 ||
      new Date(endBlock * 1000) < new Date()
    )
  }, [endBlock])
  return (
    <NoTLPContainer>
      <NoTLPCover>
        <TLPTextRow>
          <TLPText>
            {!isEndedValue ? (
              <>
                {" "}
                {timeCheck ? (
                  <>This Asteroid Farm is live!</>
                ) : (
                  <>
                    Rewards will begin in:{" "}
                    <TimeRemaining targetTimestamp={startBlock} />
                  </>
                )}
              </>
            ) : null}
          </TLPText>
        </TLPTextRow>
      </NoTLPCover>
    </NoTLPContainer>
  )
}

const NoTLPContainer = styled.div<TLPProps>`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border: none !important;
  padding: 12px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  text-align: right;
  margin: 0 -44px 0 0;
`

const NoTLPCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 640px;
  width: 100%;
  border: none !important;
`

const TLPTextRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border: none !important;
`
const TLPText = styled(Caption)`
  margin: 2px 0;
  transition: 0.4s ease-in;
  color: ${TangleColors.tangleYellow};
`

export default RewardsAsteroidFarm
