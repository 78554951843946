import React, { FC } from "react"
import styled from "styled-components"
import chevronUp from "assets/chevblackup.svg"
import chevronDown from "assets/chevblackdown.svg"
import chevronWhiteUp from "assets/chevron-white-up.svg"
import chevronWhiteDown from "assets/chevron-white-down.svg"
interface IconProps {
  addressOpen?: boolean
  pendingTransaction?: boolean
  addressDropIconRef?: any
  hoverPending?: boolean
}

const AddressDropIcon: FC<IconProps> = (props) => {
  const { addressOpen, addressDropIconRef, pendingTransaction, hoverPending } =
    props
  return (
    <AddressCover
      className="NetworkDropIcon"
      ref={addressDropIconRef}
      src={
        addressOpen
          ? pendingTransaction
            ? chevronUp
            : chevronDown
          : chevronDown
      }
    />
  )
}

const AddressCover = styled.img<IconProps>`
  display: ${(props) => (props.pendingTransaction ? "none" : "flex")};
  height: 24px;
  width: 24px;

  display: flex;

  justify-content: center;

  align-items: center;
  transition: 0.4s ease-in;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
`

export default AddressDropIcon
