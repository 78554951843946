import React, { FC } from "react"

import styled from "styled-components"

import { useWSCContext } from "context/MilkomedaContext"

import BottomPositionInfo from "../FarmBottom/BottomPositionInfo"
import NoTLPPosition from "../components/NoTLPPosition"
import FarmConnectWallet from "../components/FarmConnectWallet"
import LiquidityPositionsLoadingPlaceholder from "components/Liquidity/component/LiquidityPositionsLoadingPlaceholder"

import FarmsGoLiveCounter from "../components/FarmsGoLiveCounter"
import RewardsAsteroidFarm from "../components/RewardsAsteroidFarm"

interface LiquidityDetailsProps {
  // positions: DeserializedPosition[]
  positions?: any
  rewardTokens?: any
  farmAddress?: string
  sqrtPriceX96?: number
  enabled?: boolean

  token0DataAddress?: string
  token1DataAddress?: string
  formattedPrice?: number

  feeTier?: number

  isLPstakedInFarm?: any
  allUserPools?: any
  allPoolsLoading?: boolean
  hardcoded?: boolean
  startBlock?: number
  endBlock?: number
  isEnded?: boolean
  isFarmApproved?: any
}

const FarmLiquidityDetailsRow: FC<LiquidityDetailsProps> = (props) => {
  const {
    token1DataAddress,
    token0DataAddress,
    startBlock,
    formattedPrice,
    sqrtPriceX96,
    feeTier,
    farmAddress,
    endBlock,
    isLPstakedInFarm,
    allUserPools,
    allPoolsLoading,
    hardcoded,
    isEnded,
    isFarmApproved,
  } = props

  const { account } = useWSCContext()

  return (
    <>
      <DetailsCover>
        {account ? (
          <>
            {/*  */}
            <>
              {allPoolsLoading ? (
                <PositionLoadingCover>
                  <LiquidityPositionsLoadingPlaceholder />
                  <LiquidityPositionsLoadingPlaceholder />
                  <LiquidityPositionsLoadingPlaceholder />
                </PositionLoadingCover>
              ) : (
                <>
                  {hardcoded ? (
                    <>
                      <FarmsGoLiveCounter />
                    </>
                  ) : (
                    <>
                      <PositionListRow>
                        {allUserPools && allUserPools?.length > 0 ? (
                          <>
                            <PositionListTop>
                              <BottomPositionInfo
                                feeTier={feeTier}
                                isLPstakedInFarm={isLPstakedInFarm}
                                farmAddress={farmAddress}
                                positions={allUserPools}
                                token1DataAddress={token1DataAddress}
                                token0DataAddress={token0DataAddress}
                                formattedPrice={formattedPrice}
                                sqrtPriceX96={sqrtPriceX96}
                                isEnded={isEnded}
                                isFarmApproved={isFarmApproved}
                              />
                            </PositionListTop>
                          </>
                        ) : (
                          <>
                            <NoTLPPosition />
                          </>
                        )}{" "}
                        <RewardsAsteroidFarm
                          startBlock={startBlock}
                          endBlock={endBlock}
                        />
                      </PositionListRow>
                    </>
                  )}
                </>
              )}
            </>
          </>
        ) : (
          <>
            <FarmConnectWallet />{" "}
          </>
        )}
      </DetailsCover>
    </>
  )
}

const DetailsCover = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0;

  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PositionListRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
  max-width: 1000px;
`
const PositionListTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
`

const PositionLoadingCover = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default FarmLiquidityDetailsRow
