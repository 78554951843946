import {
  TANGLE_FARMS_REFRESH_STATUS,
  TANGLE_FARMS_TLP_STATUS,
} from "./actionsTypes"

export const updateFarmTLPSelected = (tangleTLPState: any) => {
  return {
    type: TANGLE_FARMS_TLP_STATUS,
    tangleTLPState: tangleTLPState,
  }
}

export const updateFarmStateStatus = (farmsStateRefresh: any) => {
  return {
    type: TANGLE_FARMS_REFRESH_STATUS,
    farmsStateRefresh: farmsStateRefresh,
  }
}

export const updateFarmsTLPStatusMode = (tangleTLPState: any) => {
  return (dispatch: any) => {
    localStorage.setItem("tangleFarmsTLP", tangleTLPState)
    dispatch(updateFarmTLPSelected(tangleTLPState))
  }
}

export const checkUserFarmStatus = (state: boolean) => {
  return (dispatch: any) => {
    dispatch(updateFarmStateStatus(state))
  }
}
export const proCheckFarmsTLPState = () => {
  return (dispatch: any) => {
    const farmsTLPMode = localStorage.getItem("tangleFarmsTLP")
    if (
      farmsTLPMode === undefined ||
      farmsTLPMode === null ||
      farmsTLPMode === "undefined" ||
      farmsTLPMode === "null"
    ) {
      dispatch(updateFarmsTLPStatusMode(false))
    } else if (farmsTLPMode === "false" || Boolean(farmsTLPMode) === false) {
      dispatch(updateFarmsTLPStatusMode(false))
    } else if (farmsTLPMode === "true" || Boolean(farmsTLPMode) === true) {
      dispatch(updateFarmsTLPStatusMode(true))
    } else {
      dispatch(updateFarmsTLPStatusMode(farmsTLPMode))
    }
  }
}
