import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { BodyCaption, Caption, Subtitle } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"

import { IoWarningOutline } from "react-icons/io5"
import { useAppDispatch } from "store/hooks"
import { updateUnknownTokenField } from "store/actions/UnknownTokenFieldAction"
import { Field } from "@tangleswap/sdk"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

interface TokenProps {
  tokenName: string
  tokenSymbol: string
  tokenLogo?: any
  otherCurrency: any
  selectedCurrency: any
  address: string
  balance?: any
  activeField?: Field
  customToken?: boolean
  activateCustomModal?: () => void
}
interface CurrencyProps {
  otherCurrency?: any
}
const UnknownToken: FC<TokenProps> = (props) => {
  const {
    tokenName,
    tokenSymbol,
    otherCurrency,
    tokenLogo,
    activeField,
    address,
    activateCustomModal,
  } = props
  const dispatch = useAppDispatch()

  const handleUnknownCurrencySelect = () => {
    dispatch(updateUnknownTokenField(address, activeField))
  }

  return (
    <Body otherCurrency={otherCurrency} onClick={handleUnknownCurrencySelect}>
      <Cover onClick={activateCustomModal}>
        <TokenWrap>
          <TokenIconWrapper>
            {!!tokenLogo ? (
              <>
                {" "}
                <TokenIcon src={tokenLogo} alt={`TangleSwap ${tokenName}`} />
              </>
            ) : (
              <>
                <ConvertTokenSymbol text={tokenSymbol} />
              </>
            )}
          </TokenIconWrapper>

          <TokenInfo>
            <TokenNameRow>
              {" "}
              <TokenName>{tokenSymbol}</TokenName> <TokenWarning />
            </TokenNameRow>
            <TokenInformation>
              <TokenSymbol>{tokenName}</TokenSymbol>
            </TokenInformation>
          </TokenInfo>
        </TokenWrap>
      </Cover>
    </Body>
  )
}

const Body = styled.button<CurrencyProps>`
  width: 100%;
  height: 56px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
  color: ${TangleColors.white};
  background: transparent;
  border: none;
  outline: none;
  padding: 0 26px;
  transition: 0.4s ease-in;

  :hover {
    background: ${TangleColors.tangleBlack};
  }
`
const Cover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
  margin: 0 auto;
`
const TokenWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const TokenIcon = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 9999px;
  object-fit: contain;
`
const TokenIconWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;

  align-items: center;
  justify-content: center;
  margin: 0 4px 0 0;
`

const TokenName = styled(Subtitle)`
  margin: 0;
`
const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 0 12px;
`
const TokenInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2px 0 0 0;
`
const TokenNameRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
`

const TokenWarning = styled(IoWarningOutline)`
  height: 20px;
  width: 20px;
  margin: 0 0 0 6px;
  color: ${TangleColors.lighthover};
`

const TokenSymbol = styled(BodyCaption)`
  color: ${TangleColors.white};
`

export default UnknownToken
