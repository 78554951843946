/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Header5Gothic } from "styles/TextStyles"
import LimitInformation from "./LimitInformation"

// import { toast as SonnerToast } from "sonner"
const LimitOrderContainer = () => {
  return (
    <>
      <Body>
        <Cover>
          <SwapTop>
            <SwapRight />
            <SwapTextDescriptionCover>
              <LimitDescription>
                <Link to="/swap">Swap</Link>
              </LimitDescription>
              <SwapDescription>Limit</SwapDescription>
            </SwapTextDescriptionCover>

            <SettingsWrap></SettingsWrap>
          </SwapTop>
          <LimitInformation />
        </Cover>
      </Body>
    </>
  )
}

const Body = styled.div`
  width: 100%;
  position: relative;
  margin: 24px 0 0 0;
`
const Cover = styled.div`
  max-width: 620px;
  width: 100%;
  /* margin: 24px auto 0 auto; */
  margin: 0 auto;
  position: relative;
  z-index: 3;
  border: 1px solid ${TangleColors.lighthover};
  background: ${TangleColors.swapBG};
  border-radius: 40px;
  padding: 8px 20px;
  color: ${TangleColors.white};
  min-height: 600px;
`
const SettingsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* width: 100%; */
  position: relative;
`
const SwapTop = styled.div`
  width: 100%;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  margin: 15.5px 0 10px 0;
`
const SwapTextDescriptionCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const SwapDescription = styled(Header5Gothic)`
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  margin: 0;
`
const LimitDescription = styled(Header5Gothic)`
  color: ${TangleColors.grayDark};

  text-align: center;
  margin: 0 24px 0 0;
  cursor: pointer;
  a {
    color: ${TangleColors.darkGray};
  }
`

const SwapRight = styled.div``

export default LimitOrderContainer
