import CurrencyTokenImages from "components/Liquidity/component/CurrencyTokenImages"
import styled from "styled-components"

export interface TokenImagesProps {
  token0Logo?: string
  token1Logo?: string
  token0Name?: string
  token1Name?: string
  margin?: boolean
  hardcoded?: boolean
}

const TokenImages = (props: TokenImagesProps) => {
  const { token0Logo, token0Name, token1Logo, token1Name, hardcoded } = props

  return (
    <SizeImageWrapper>
      {hardcoded ? (
        <>
          {" "}
          <SizeImages>
            <SizeFirstIcon
              src=" https://raw.githubusercontent.com/TangleSwap/assets/main/img/smpc-bridge/miota.png"
              alt={token0Name}
            />
            <SizeSecondIcon
              src="https://raw.githubusercontent.com/TangleSwap/assets/main/img/smpc-bridge/smr.png"
              alt={token1Name}
            />
          </SizeImages>
        </>
      ) : (
        <>
          <CurrencyTokenImages
            token0Logo={token0Logo}
            token1Logo={token1Logo}
            token0Symbol={token0Name}
            token1Symbol={token1Name}
          />
        </>
      )}
    </SizeImageWrapper>
  )
}

const SizeImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  max-width: 64px;
`
const SizeImages = styled.div`
  max-width: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  align-items: center;
`
const SizeFirstIcon = styled.img`
  height: 22px;
  width: 22px;
  object-fit: cover;
  border-radius: 9999px;
  z-index: 5;
`
const SizeSecondIcon = styled.img`
  height: 22px;
  width: 22px;
  object-fit: cover;

  border-radius: 9999px;
`

export default TokenImages
