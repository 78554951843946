import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import ReferralDataRow from "./ReferralDataRow"
import TokenBreak from "components/LaunchPad/Tabs/TokenBreak"
import TanglePagination from "./TanglePagination"
import SearchEmptyState from "./SearchEmptyState"

interface ReferralTableProps {
  referralsData?: any
  maxItems?: any
}

const ReferralsTables: FC<ReferralTableProps> = (props) => {
  const { referralsData, maxItems } = props

  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    let extraPages = 1
    if (referralsData) {
      if (referralsData?.length % maxItems === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(referralsData?.length / maxItems) + extraPages)
    }
  }, [maxItems, referralsData])

  return (
    <TokenWrapper>
      <TokensTableCover>
        <TokenAutoColumn>
          <TokensTableTitle>
            <TokenLabel>
              {" "}
              <BoxDataName># </BoxDataName>
            </TokenLabel>
            <ClickableText>
              <BoxDataName>Referral Address </BoxDataName>
            </ClickableText>

            <ClickableText>
              <BoxDataName>Volume </BoxDataName>
            </ClickableText>

            <ClickableText>
              <BoxDataName>Date</BoxDataName>
            </ClickableText>
          </TokensTableTitle>
          {referralsData && referralsData.length > 0 ? (
            <>
              <TokensTableBody>
                {referralsData?.map((data: any, i: number) => {
                  if (data) {
                    return (
                      <React.Fragment key={i}>
                        <TokenBreak />
                        <ReferralDataRow
                          index={(page - 1) * maxItems + i}
                          referralData={data}
                        />
                      </React.Fragment>
                    )
                  }
                  return null
                })}
              </TokensTableBody>{" "}
              <TanglePagination
                maxPage={maxPage}
                setPage={setPage}
                page={page}
              />
            </>
          ) : (
            <>
              <SearchEmptyState />
            </>
          )}
        </TokenAutoColumn>
      </TokensTableCover>
    </TokenWrapper>
  )
}
const TokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  width: 100%;
  margin: 8px auto;
`
const TokenAutoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1100px;
  width: 100%;
  margin: 8px auto;
`
const TokensTableCover = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  width: 100%;
  margin: 8px auto;
`

const TokensTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4px 0;
`

const TokenLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

const ClickableText = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  color: ${TangleColors.white};
  font-variant-numeric: tabular-nums;
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
  text-align: end;

  user-select: none;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`
const BoxDataName = styled(Caption)`
  color: ${TangleColors.grayDark};

  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const TokensTableTitle = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  padding: 0;
  grid-template-columns: 56px 3fr repeat(2, 1fr);
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 1.5fr repeat(2, 1fr);
    & :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 20px 1.5fr repeat(1, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

export default ReferralsTables
