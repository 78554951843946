// import NoMetaMask from "components/modals/NoMetaMask"
import { createAction } from "@reduxjs/toolkit"

export const openFireFlyModal = createAction<void>("modal/openFireFlyModal")
export const closeFireFlyModal = createAction<void>("modal/closeFireFlyModal")
export const noMetMaskModal = createAction<void>("modal/noMetMaskModal")
export const closeNoMetMaskModal = createAction<void>(
  "modal/closeNoMetMaskModal"
)
export const connectWalletModal = createAction<void>("modal/connectWalletModal")
export const closeConnectWalletModal = createAction<void>(
  "modal/closeconnectwalletmodal"
)

export const noNamiWalletModal = createAction<void>("modal/noNamiWalletModal")
export const closeNamiWalletModal = createAction<void>(
  "modal/closeNamiWalletModal"
)
