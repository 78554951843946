import {
  MAGIC_ADDRESS,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
  isInvalid,
} from "@tangleswap/sdk"
import axios from "axios"

import { isAddress } from "utils"

export async function fetchTangleFiatValue(
  tokenAddress: string,
  tokenDecimal: number,
  chainId: number
) {
  try {
    if (!chainId || !isAddress(tokenAddress)) return

    const storageKey = `fiat${chainId}${tokenAddress}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined")
      return Number(storageValue)

    const addr = tokenAddress?.trim().toLowerCase()
    const isNative = addr === NATIVE_SYMBOL[chainId].toLowerCase()
    const isWrapped = addr === WRAPPED_ADDRESS[chainId].toLowerCase()
    const isMagic = addr === MAGIC_ADDRESS[chainId].toLowerCase()
    const fetchFiatURL =
      isNative || isWrapped || isMagic
        ? `https://backend.tangleswap.space/api/v1/fiat/price/${NATIVE_SYMBOL[chainId]}/USD`
        : `https://backend.tangleswap.space/api/v1/chains/${chainId}/indirect-fiat/token_address/${tokenAddress}/token_decimal/${tokenDecimal}`

    const fetchFiat = await axios.get(fetchFiatURL)
    if (fetchFiat) {
      sessionStorage.setItem(storageKey, String(fetchFiat?.data))
      return fetchFiat?.data
    }
  } catch (error) {
    return 0
  }
}
