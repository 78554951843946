import React, { FC } from "react"
import styled from "styled-components"
import { SwapConnectButton } from "styles/ButtonStyles"
import useConnectWallet from "utils/useConnectWallet"

import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { useAppSelector } from "store/hooks"
import { Dots } from "./LoadingDots"
interface ConnectWalletProps {
  isNavbar?: boolean
}
const ConnectWalletButton: FC<ConnectWalletProps> = (props) => {
  const { isNavbar } = props
  const { connectWallet } = useConnectWallet()
  const tangleswapWalletState = useAppSelector(
    (state) => state.wallet.tangleswapWalletState
  )

  return (
    <>
      <ConnectButton
        isNavbar={isNavbar}
        tangleswapWalletState={Boolean(tangleswapWalletState) === false}
        onClick={connectWallet}
      >
        {Boolean(tangleswapWalletState) === true ? (
          <> Connect Wallet </>
        ) : (
          <>
            <SpinnerCover isNavbar={isNavbar}>
              <SpinnerCombined
                bigRadius={isNavbar ? 20 : 32}
                smallRadius={isNavbar ? 14 : 24}
                height={isNavbar ? 32 : 80}
                width={isNavbar ? 32 : 80}
                color="#000"
              />{" "}
            </SpinnerCover>
            Connecting wallet <Dots walletConnection={true} />
          </>
        )}
      </ConnectButton>
    </>
  )
}

const ConnectButton = styled(SwapConnectButton)``
const SpinnerCover = styled.div<ConnectWalletProps>`
  margin: ${(props) => (props.isNavbar ? `32px 20px 0 0` : `0 32px 0 0`)};
`
export default ConnectWalletButton
