import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
import Asteroidempty from "assets/Asteroidempty.png"
const SearchEmptyState = () => {
  return (
    <SearchEmptyWrapper>
      <ImageWrapper>
        <ChartImage src={Asteroidempty} alt="tangleswap info search" />
      </ImageWrapper>
      <SearchText>No results found..</SearchText>
    </SearchEmptyWrapper>
  )
}

const SearchEmptyWrapper = styled.div`
  min-height: 60px;
  max-width: 900px;
  width: 100%;
  margin: 12px auto 8px auto;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  padding: 32px;
`
const ChartImage = styled.img`
  max-width: 300px;
  height: 150px;
  width: 100%;
  object-fit: contain;
`
const ImageWrapper = styled.div`
  max-width: 300px;
  height: 150px;
  width: 100%;
  margin: 0 auto 12px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const SearchText = styled(Subtitle)`
  color: ${TangleColors.black};
`
export default SearchEmptyState
