import { useWSCContext } from "context/MilkomedaContext"
import React, { FC } from "react"
import ProgressFollowUp from "./ProgressFollowUp"
interface PendingProps {
  closeNotification?: () => void
  notificationActive?: boolean
}
const PendingTxNotifications: FC<PendingProps> = (props) => {
  const { closeNotification, notificationActive } = props
  const { pendingWSCTxs } = useWSCContext()

  return (
    <>
      <ProgressFollowUp
        notificationActive={notificationActive}
        closeNotification={closeNotification}
        pendingTxs={pendingWSCTxs}
      />
    </>
  )
}

export default PendingTxNotifications
