import React from "react"
import ReactDOM from "react-dom/client"
import { tsStore } from "store/store"
import "./index.css"
import App from "./App"

import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"

import PendingContext from "context/PendingContext"

import HeroContext from "context/HeroContext"
import AppWrapper from "components/milkomeda/AppWrapper"
import WalletContext from "context/WalletContext"
import CancelModalContext from "context/CancelModalContext"

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    {" "}
    <WalletContext>
      <Provider store={tsStore}>
        <QueryClientProvider client={queryClient}>
          <AppWrapper>
            <PendingContext>
              <HeroContext>
                <CancelModalContext>
                  <App />
                </CancelModalContext>
              </HeroContext>
            </PendingContext>
          </AppWrapper>
        </QueryClientProvider>
      </Provider>
    </WalletContext>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
