import { createAction } from "@reduxjs/toolkit"
import { Field } from "@tangleswap/sdk"

export const selectLiquidityCurrency = createAction<{
  field: Field
  currencyAddress: any
  chainId?: any
}>("liquidity/selectcurrency")
export const switchCurrencies = createAction<void>("liquidity/switchcurrencies")
export const typeInputLiqudity = createAction<{
  field: Field
  typedValue: any
}>("liquidity/typeinput")
