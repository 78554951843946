import {
  AUCTION_LIST,
  AUCTION_LIST_LOADING,
  LIQ_LAUNCHER_INFO,
  SINGLE_AUCTION_LIST,
} from "store/actions/actionsTypes"
import { updateObject } from "../utility"
export interface AuctionData {
  auctionList: any
  singleAuctionList: any
  launcherInfo: any
  auctionLoading: boolean
}

const initialState: AuctionData = {
  auctionList: null,
  singleAuctionList: null,
  launcherInfo: null,
  auctionLoading: false,
}

const auctionDataSuccess = (state: any, action: any) => {
  return updateObject(state, {
    auctionList: action.data,
  })
}
const singleAuctionDataSuccess = (state: any, action: any) => {
  return updateObject(state, {
    singleAuctionList: action.data,
  })
}

const auctionDataLoading = (state: any, action: any) => {
  return updateObject(state, {
    auctionLoading: action.data,
  })
}

const liqLauncherDataSuccess = (state: any, action: any) => {
  return updateObject(state, {
    launcherInfo: action.data,
  })
}

const auctionDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AUCTION_LIST:
      return auctionDataSuccess(state, action)
    case AUCTION_LIST_LOADING:
      return auctionDataLoading(state, action)
    case SINGLE_AUCTION_LIST:
      return singleAuctionDataSuccess(state, action)
    case LIQ_LAUNCHER_INFO:
      return liqLauncherDataSuccess(state, action)

    default:
      return state
  }
}

export default auctionDataReducer
