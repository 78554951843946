import ConnectWalletButton from "components/utils/ConnectWalletButton"
import { Subtitle } from "iconsax-react"
import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

const FarmConnectWallet = () => {
  return (
    <ConnectFormCover>
      <ConnectButton>
        {" "}
        <ConnectWalletButton />
      </ConnectButton>
    </ConnectFormCover>
  )
}

const ConnectButton = styled.div`
  position: relative;
  max-width: 550px;
  height: 68px;
  width: 100%;
  border-radius: 24px !important;
`

const ConnectFormCover = styled.div`
  max-width: 1232px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;

  border-radius: 24px;
`
const ConnectFormText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 12px 0;
  width: 100%;
`

export default FarmConnectWallet
