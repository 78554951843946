import IncreaseLiquidityPageContainer from "container/pages/IncreaseLiquidityPageContainer"
import { useEffect } from "react"

const IncreaseLiquidity = () => {
  useEffect(() => {
    document.title = "Increase Liquidity"
  }, [])
  return (
    <>
      {" "}
      <IncreaseLiquidityPageContainer />
    </>
  )
}

export default IncreaseLiquidity
