import { formatBigNumberToFixed } from "components/BigNumber/FormatBalance"
import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionMedium } from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"
import LoadingDots from "components/utils/LoadingDots"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"

interface DataProps {
  pendingRewards?: any
  rewardsAddress?: string | undefined | null
  tokenSymbol?: any
  tokenLogo?: any
  tokenDecimals?: any
  hardcoded?: boolean
}

const TopRewardsRow: FC<DataProps> = (props) => {
  const {
    rewardsAddress,
    pendingRewards,
    tokenSymbol,
    tokenLogo,
    tokenDecimals,
    hardcoded,
  } = props
  const rewardsEarning = pendingRewards / 10 ** tokenDecimals
  return (
    <>
      {rewardsAddress !== null ? (
        <>
          {" "}
          <BottomLargeBox>
            {hardcoded ? (
              <>
                {" "}
                <BoxImageWrapper>
                  <CurrencyLogo
                    src="https://raw.githubusercontent.com/TangleSwap/assets/main/img/smpc-bridge/soon.png"
                    alt={`tangleswap farms logo for ${tokenSymbol}`}
                  />
                </BoxImageWrapper>
              </>
            ) : (
              <>
                {" "}
                <BoxImageWrapper>
                  {!!tokenLogo ? (
                    <>
                      {" "}
                      <CurrencyLogo
                        src={tokenLogo}
                        alt={`tangleswap farms logo for ${tokenSymbol}`}
                      />
                    </>
                  ) : (
                    <CustomCurrencyLogoCover>
                      {" "}
                      <ConvertTokenSymbol text={tokenSymbol} />
                    </CustomCurrencyLogoCover>
                  )}
                </BoxImageWrapper>
              </>
            )}

            <BoxRowCover>
              <BoxPriceTitle>
                {hardcoded ? (
                  <>
                    <BoxPriceAmount>{formatNumber(100000, 4)}</BoxPriceAmount>
                  </>
                ) : (
                  <>
                    <BoxPriceAmount>
                      {`${
                        rewardsEarning ? formatNumber(rewardsEarning, 4) : "0"
                      }`}{" "}
                    </BoxPriceAmount>
                  </>
                )}

                {hardcoded ? (
                  <>
                    {" "}
                    <BoxDataName>SOON</BoxDataName>
                  </>
                ) : (
                  <>
                    {" "}
                    <BoxDataName>
                      {" "}
                      {tokenSymbol === undefined ? (
                        <>
                          <LoadingDots />
                        </>
                      ) : (
                        <>{tokenSymbol}</>
                      )}
                    </BoxDataName>
                  </>
                )}
              </BoxPriceTitle>
            </BoxRowCover>
          </BottomLargeBox>
        </>
      ) : null}
    </>
  )
}

const BottomLargeBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 4px 0;
  gap: 8px;
  width: 100%;
`
const CurrencyLogo = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 9999px;
  object-fit: contain;
`
const CustomCurrencyLogoCover = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 9999px;
  object-fit: contain;
`

const BoxImageWrapper = styled.div`
  width: 100%;
  max-width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BoxDataName = styled(Caption)`
  color: ${TangleColors.grayLight};
  text-align: left;
  margin: 0 0 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const BoxPriceAmount = styled(Caption)`
  color: ${TangleColors.grayLight};
  text-align: left;
  margin: 0 0 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BoxPriceTitle = styled(CaptionMedium)`
  color: ${TangleColors.grayLight};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
`

const BoxRowCover = styled.div`
  width: 100%;
  max-width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export default TopRewardsRow
