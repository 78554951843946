import { Chains } from "@tangleswap/sdk"
import styled from "styled-components"
import { ChainChangeButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import addChainToMetamask from "./switchChain"
import { Subtitle } from "styles/TextStyles"
import { defaultChainId } from "context/MilkomedaContext"

const WrongNetworkHandler = () => {
  return (
    <WrongNetworkCover>
      <WrongNetworkText>
        To onboard the Tangleship, please switch first to a supported chain.
      </WrongNetworkText>
      <ChainButton onClick={() => addChainToMetamask(defaultChainId)}>
        Switch to Cardano
      </ChainButton>
    </WrongNetworkCover>
  )
}
const WrongNetworkCover = styled.div`
  margin: 32px auto;
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  background: ${TangleColors.swapBG};
  border-radius: 24px;
  border: 2px solid ${TangleColors.lighthover};
`
const ChainButton = styled(ChainChangeButton)`
  position: relative;
  z-index: 9;
`
const WrongNetworkText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 12px 0;
`
export default WrongNetworkHandler
