import React, { FC, RefObject, useCallback, useRef, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Subtitle } from "styles/TextStyles"
import { AiOutlineInfoCircle } from "react-icons/ai"
import SlipageAuto from "./SlipageAuto"
import {
  updateUserDeadlineMinute,
  updateUserSlippageTolerance,
} from "store/actions/SettingsActions"
import { DEFAULT_DEADLINE_FROM_NOW, THREE_DAYS_IN_SECONDS } from "./misc"
import { SlippageInput } from "./SlippageInput"
import SlippageHoverBox from "./SlippageHoverBox"
import { useAppDispatch, useAppSelector } from "store/hooks"
import LPHoverBox from "./LPHoverBox"
import TangleToggle from "./Toggle"
import { updateTradingProMode } from "store/actions/proActions"
import { useLocation } from "react-router-dom"

interface SlippageProps {
  slippageRef?: RefObject<HTMLDivElement | any>
  showSlippage?: boolean
  slippageTop?: any
}
interface SlippagePaddingProps {
  slippagePadding?: boolean
}

enum SlippageError {
  InvalidInput = "InvalidInput",
}

enum DeadlineError {
  InvalidInput = "InvalidInput",
}

const SlippageContainer: FC<SlippageProps> = (props) => {
  const { slippageRef, showSlippage, slippageTop } = props
  const slippageTolerance = useAppSelector(
    (state) => state.settings.userSlippageTolerance
  )
  const deadlineMinute = useAppSelector(
    (state) => state.settings.deadlineMinute
  )
  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  const infoRef = useRef<HTMLDivElement | any>()
  const textRef = useRef<HTMLDivElement | any>()
  const [showHelpText, setShowHelpText] = useState<boolean>(false)
  const [showTXHelpText, setShowTXHelpText] = useState<boolean>(false)
  const [slippageValue, setSlippageValue] = useState<string | number | any>("")
  const [showProHelpText, setShowProHelpText] = useState<boolean>(false)
  const [slippageError, setSlippageError] = useState<SlippageError | boolean>(
    false
  )
  const [isModeToggled, setIsModeToggled] = useState<boolean>(false)
  const [deadlineInput, setDeadlineInput] = useState("")
  const [deadlineError, setDeadlineError] = useState<DeadlineError | false>(
    false
  )

  const mouseEnter = () => {
    setShowHelpText(true)
  }

  const mouseLeave = () => {
    setShowHelpText(false)
  }
  const mouseTXEnter = () => {
    setShowTXHelpText(true)
  }

  const mouseTXLeave = () => {
    setShowTXHelpText(false)
  }
  const dispatch = useAppDispatch()
  //slippage tolerance conversion
  // const parseSlippageInput = useCallback(
  //   (value: string) => {
  //     setSlippageError(false)

  //     if (value?.toLowerCase() === "auto") {
  //       setSlippageValue("1")
  //       const slippageValue = "auto"
  //       dispatch(updateUserSlippageTolerance(slippageValue))
  //     } else {
  //       setSlippageValue(value)
  //       if (value?.length === 0) {
  //         const slippageValue = "auto"
  //         dispatch(updateUserSlippageTolerance(slippageValue))
  //       } else {
  //         const parsed = Math.floor(Number.parseFloat(value) * 100)

  //         if (!Number.isInteger(parsed) || parsed < 0 || Number(parsed) === 1) {
  //           const slippageValue = "auto"

  //           dispatch(updateUserSlippageTolerance(slippageValue))
  //           if (value !== ".") {
  //             setSlippageError(SlippageError.InvalidInput)
  //           }
  //         } else {
  //           dispatch(updateUserSlippageTolerance(parsed))
  //         }
  //       }
  //     }
  //   },
  //   [setSlippageError, dispatch]
  // )
  const parseSlippageInput = useCallback(
    (value: string) => {
      setSlippageError(false)

      if (value?.toLowerCase() === "auto") {
        setSlippageValue("1")
        dispatch(updateUserSlippageTolerance("auto"))
      } else if (value === "1") {
        setSlippageValue("1") // Automatically switch to "auto" if 1 is entered

        const slippageValue = "auto"
        dispatch(updateUserSlippageTolerance(slippageValue))
      } else {
        setSlippageValue(value)
        if (value?.length === 0) {
          const slippageValue = "auto"
          dispatch(updateUserSlippageTolerance(slippageValue))
        } else {
          const parsed = Math.floor(Number.parseFloat(value) * 100)

          if (!Number.isInteger(parsed) || parsed < 0) {
            const slippageValue = "auto"

            dispatch(updateUserSlippageTolerance(slippageValue))
            if (value !== ".") {
              setSlippageError(SlippageError.InvalidInput)
            }
          } else {
            dispatch(updateUserSlippageTolerance(parsed))
          }
        }
      }
    },
    [setSlippageError, dispatch]
  )

  const route = useLocation()
  const homeTrue = route.pathname === "/"

  const notDappsClassName =
    route.pathname === "/nft-staking" ||
    route.pathname === "/swap" ||
    route.pathname === "/stake" ||
    route.pathname === "/farm" ||
    route.pathname === "/governance" ||
    route.pathname === "/nft-staking/:slug" ||
    route.pathname === "/invest" ||
    route.pathname === "/media"

  function parseCustomDeadline(value: string) {
    // populate what the user typed and clear the error
    setDeadlineInput(value)
    setDeadlineError(false)

    if (value.length === 0) {
      dispatch(updateUserDeadlineMinute(DEFAULT_DEADLINE_FROM_NOW))
    } else {
      try {
        const deadlineMinute: number = Math.floor(Number.parseFloat(value) * 60)
        if (
          !Number.isInteger(deadlineMinute) ||
          deadlineMinute < 60 ||
          deadlineMinute > THREE_DAYS_IN_SECONDS
        ) {
          setDeadlineError(DeadlineError.InvalidInput)
        } else {
          dispatch(updateUserDeadlineMinute(deadlineMinute))
        }
      } catch (error) {
        console.error(error)
        setDeadlineError(DeadlineError.InvalidInput)
      }
    }
  }
  const mouseLPEnter = (e: any) => {
    setShowProHelpText(true)
  }
  const onMouseEnter = (e: any) => {
    setShowProHelpText(true)
  }

  const mouseLPLeave = (e: any) => {
    if (infoRef.current !== e.target && textRef.current !== e.target) {
      setShowProHelpText(false)
    }
  }
  const onMouseLeave = (e: any) => {
    setShowProHelpText(false)
  }
  const toggleOffExpertMode = (e?: any) => {
    e.preventDefault()
    setIsModeToggled(false)
    dispatch(updateTradingProMode(Boolean(false)))
  }
  const toggleOnExpertMode = (e?: any) => {
    e.preventDefault()
    setIsModeToggled(true)
    dispatch(updateTradingProMode(Boolean(true)))
  }

  return (
    <>
      {showSlippage ? (
        <HoverBody
          ref={slippageRef}
          slippagePadding={!homeTrue && !notDappsClassName}
          slippageTop={slippageTop}
        >
          {/* <SlippageTitle>Transaction Settings</SlippageTitle> */}

          <SlippageWrap>
            <SlippageRow>
              <SlippageTitle>
                Slippage
                <HelpIcon onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
                {showHelpText && (
                  <SlippageHoverBox
                    text="This percentage sets the threshold at which your transaction will revert if the price changes unfavorably."
                    left={52}
                    top={36}
                  />
                )}
              </SlippageTitle>
              <SlippageMain>
                <SlipageAuto
                  isToggled={
                    slippageTolerance !== null &&
                    slippageTolerance?.toString().toLowerCase() === "auto"
                      ? true
                      : false
                  }
                  onChange={() => {
                    parseSlippageInput("auto")
                  }}
                />
                <SlippageInput
                  minute={true}
                  name="Slippage"
                  placeholder="1"
                  percent={true}
                  value={
                    slippageValue?.length > 0
                      ? slippageValue
                      : slippageTolerance?.toString()?.toLowerCase() === "auto"
                      ? ""
                      : Number(slippageTolerance / 100).toString()
                  }
                  onUserInput={parseSlippageInput}
                />
              </SlippageMain>
            </SlippageRow>
            <SlippageRow>
              <SlippageTitle>
                Tx Deadline
                <HelpIcon
                  onMouseEnter={mouseTXEnter}
                  onMouseLeave={mouseTXLeave}
                />
                {showTXHelpText && (
                  <SlippageHoverBox
                    left={74}
                    top={36}
                    text="This number sets the minutes after which your transaction will expire and revert."
                  />
                )}
              </SlippageTitle>
              <SlippageMain>
                <SlippageInput
                  minute={false}
                  name="deadline"
                  placeholder={(DEFAULT_DEADLINE_FROM_NOW / 60).toString()}
                  value={
                    deadlineInput?.length > 0
                      ? deadlineInput
                      : deadlineMinute === DEFAULT_DEADLINE_FROM_NOW
                      ? ""
                      : Number(deadlineMinute / 60).toString()
                  }
                  onUserInput={parseCustomDeadline}
                />
                <SlippageText> minutes</SlippageText>
              </SlippageMain>
            </SlippageRow>
            <SlippageRow>
              {!homeTrue && !notDappsClassName ? (
                <LPMode>
                  <LPModeStatus>
                    <ModeText>
                      Pro Mode
                      <IconWrapper
                        ref={infoRef}
                        onMouseEnter={mouseLPEnter}
                        onMouseLeave={mouseLPLeave}
                      >
                        <LPHelpIcon />
                      </IconWrapper>
                    </ModeText>

                    <LPHoverBox
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      showHelpText={showProHelpText}
                      textRef={textRef}
                      text="Switch between Lite and Pro mode. To learn more,"
                      linktext="please visit our docs."
                      link="https://docs.tangleswap.exchange/guides/step-by-step/providing-liquidity#lite-vs-pro"
                    />
                    <TangleToggle
                      isToggled={Boolean(userTradingMode)}
                      toggleOnButton={toggleOnExpertMode}
                      toggleOffButton={toggleOffExpertMode}
                    />
                  </LPModeStatus>
                </LPMode>
              ) : null}
            </SlippageRow>
          </SlippageWrap>
        </HoverBody>
      ) : null}
    </>
  )
}

const HoverBody = styled.div<SlippagePaddingProps & SlippageProps>`
  width: 280px;
  /* width: 100%; */
  border-radius: 24px;
  position: absolute;
  /* top: 32px; */
  right: 0px;

  top: ${(props) => props.slippageTop && `${props.slippageTop}px`};
  /* transform: translate(-20%, -50%); */
  transition: 0.4s ease-in;
  background: ${TangleColors.tangleHarshBlack};
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  border-radius: 20px;
  padding: ${(props) =>
    props.slippagePadding ? "10px 20px 16px 20px" : "10px 20px 8px 20px"};
  z-index: 88;
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.lighthover};
`

const SlippageTitle = styled(Subtitle)`
  text-align: left;
  width: 100%;
  color: ${TangleColors.grayLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  width: 100%;
  position: relative;
`

const SlippageText = styled(Caption)`
  text-align: left;
  width: 100%;
  color: ${TangleColors.grayLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  width: 100%;
  position: relative;
`

const SlippageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const SlippageRow = styled.div`
  width: 100%;
  margin: 4px 0;
`
const HelpIcon = styled(AiOutlineInfoCircle)`
  height: 18px;
  margin: 0 8px;
  width: 18px;
  color: ${TangleColors.white};
  cursor: pointer;
  position: relative;
`
const SlippageMain = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;
  justify-content: space-between;
`

const LPMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  /* margin: 16px 16px 0 0; */
`
const LPModeStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ModeText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 0 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`
const LPHelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
`
const IconWrapper = styled.div`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
  margin: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export default SlippageContainer
