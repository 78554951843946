import { useEffect } from "react"
import farmHeroBg from "assets/hero-green.png"
import SiteheroSection from "components/SiteheroSection"
import FarmsContainer from "components/farm/FarmsContainer"
import styled from "styled-components"
import LedgerContext from "components/LedgerContext"

const Farming = () => {
  useEffect(() => {
    document.title = "Farms"
  }, [])

  return (
    <Body>
      <SiteheroSection
        backgroundImage={farmHeroBg}
        title="Asteroid Farms"
        subText="Leverage your LP positions to earn additional rewards."
      />
      <LedgerContext />
      <FarmsContainer />
    </Body>
  )
}

const Body = styled.div`
  min-height: 100vh;
`
export default Farming
