import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { CaptionSmall } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

interface TokenRowProps {
  tokenName: string
  tokenSymbol: string
  tokenLogo?: any
  selectCurrency: (address: string) => void
  otherCurrency: any
  selectedCurrency: any
  address: string
}
interface CurrencyProps {
  otherCurrency?: any
  disabled?: boolean
}
const TokenRow: FC<TokenRowProps> = (props) => {
  const {
    tokenName,
    tokenSymbol,
    tokenLogo,
    otherCurrency,
    selectedCurrency,
    selectCurrency,
    address,
  } = props

  return (
    <Body
      disabled={selectedCurrency}
      otherCurrency={otherCurrency}
      onClick={() => selectCurrency(address)}
    >
      <TrendingRow>
        <TokenImageWrapper>
          {" "}
          {!!tokenLogo ? (
            <>
              <TokenSymbol
                disabled={selectedCurrency}
                src={tokenLogo}
                alt={`Tangleswap top token ${tokenSymbol} ${tokenName}`}
              />
            </>
          ) : (
            <>
              <ConvertTokenSymbol text={tokenName} />
            </>
          )}
        </TokenImageWrapper>

        <TokenNameText>{tokenSymbol}</TokenNameText>
      </TrendingRow>
    </Body>
  )
}

const Body = styled.button<CurrencyProps>`
  width: 100%;
  height: 38px;
  max-width: 90px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  background: transparent;
  align-items: center;
  outline: none;
  cursor: pointer;
  justify-content: center;
  margin: 6px 12px 6px 0;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  border: 1px solid ${TangleColors.grayLight};

  border-radius: 16px;
  :hover {
    transition: 0.4s ease-in;
    background: ${TangleColors.heroCTA};
  }
  :disabled {
    background: ${TangleColors.tangleGray};
    color: ${TangleColors.black};
    cursor: not-allowed;
    border: 1px solid ${TangleColors.cta};

    :hover {
      background: ${TangleColors.tangleGray};
      color: ${TangleColors.black};
      border: 1px solid ${TangleColors.cta};
      cursor: not-allowed;
    }
  }
`

const TrendingRow = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  max-width: 130px;
`

const TokenImageWrapper = styled.div`
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TokenSymbol = styled.img<CurrencyProps>`
  height: 24px;
  width: 24px;
  border-radius: 9999px;
  object-fit: cover;
  margin: 0 8px 0 0;
  border: ${(props) =>
    props.disabled ? `2px solid ${TangleColors.black}` : "none"};
`
const TokenNameText = styled(CaptionSmall)``

export default TokenRow
