import { StarsDataProps, starsData } from "data/StarsItem"
import React, { FC } from "react"
import styled, { keyframes } from "styled-components"
interface StarsProps {
  skyBackground?: any
  starColors?: any
  number?: any
  size?: any
  speed?: any
  children?: any
}
const StarsBackground: FC<StarsProps> = (props) => {
  const {
    starColors = [
      "#000000",
      "#4C0066",
      "#740E95",
      "#8F4EAD",
      "#D874FA",
      "#FFFFFF",
    ],

    number = 150,
    size = { min: 0.3, max: 2 },
    speed = { min: 30, max: 200 },
    children,
  } = props
  const getNumberFromInterspace = (min, max, round = 0) => {
    return +(min + Math.random() * (max + 1 - min)).toFixed(round)
  }

  const generateOptions = ({
    colorInterspace,
    speedInterspace,
    sizeInterspace,
  }) => {
    const speed =
      getNumberFromInterspace(speedInterspace.min, speedInterspace.max, 2) + "s"

    const position = getNumberFromInterspace(0, 100) + "%"
    const size =
      getNumberFromInterspace(sizeInterspace.min, sizeInterspace.max) + "px"
    const color =
      colorInterspace[getNumberFromInterspace(0, colorInterspace.length - 1)]

    return {
      color,
      position,
      size,
      speed,
    }
  }

  const items = []

  for (let i = 0; i < number; i++) {
    items.push(
      generateOptions({
        colorInterspace: starColors,
        sizeInterspace: size,
        speedInterspace: speed,
      })
    )
  }

  return (
    <>
      <StarsCover>
        {starsData.map((options: StarsDataProps, index) => {
          return (
            <Stars
              key={index}
              // className={styles.item}
              style={{
                left: options.position,
                width: options.size,
                height: options.size,
                backgroundColor: options.color,
                animationDuration: options.speed,
              }}
            />
          )
        })}
        {children}
      </StarsCover>
    </>
  )
}

const starsFlyingAnimation = keyframes`
 /* from {
    bottom: 0%;
  }
  to {
    top: 0%;
  } */
  from {
    top: 120%;
  }
  to {
    top: -10%;
  }
   /* from {
    top: 110%;
  }
  to {
    top: -10%;
  } */
  `

const StarsCover = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: transparent;
  overflow: hidden;
  position: relative;
  width: 100%;

  z-index: 1;
`
const Stars = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 50%;

  animation: ${starsFlyingAnimation} 5s linear infinite running;
`
export default React.memo(StarsBackground)
