import { fetchCurrency, fetchUnknownLP } from "../hooks"
import { Chains, Tangleship } from "@tangleswap/sdk"

export async function fetchTangleCurrency(
  address: string,
  chainId: Chains,
  tangleship: Tangleship,
  tokenInfo: any = null
) {
  if (!address || !chainId) {
    console.error("Invalid arguments in fetchTangleCurrency", address, chainId)
    return
  }

  const token = fetchCurrency(address, chainId)
  if (token) return token

  const res = await fetchUnknownLP(address, chainId, tangleship, tokenInfo)
  if (res) return res
  return undefined
}
