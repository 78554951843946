import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
interface ToggleProps {
  onChange?: () => void
  isToggled: boolean
}
const SlipageAuto: FC<ToggleProps> = (props) => {
  const { onChange, isToggled } = props
  return (
    <InputWrapper>
      <InputMain type="checkbox" checked={isToggled} onChange={onChange} />
      <InputSpan>
        {" "}
        <SlippageTitle> Auto</SlippageTitle>
      </InputSpan>
    </InputWrapper>
  )
}

const InputWrapper = styled.label`
  position: relative;
  margin: 0 8px 0 0;
  transition: 0.4s ease-in;
  height: 32px;
`
const SlippageTitle = styled(Caption)`
  text-align: left;
  width: 100%;
  color: ${TangleColors.white};

  margin: 8px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
`
const InputMain = styled.input`
  position: absolute;
  left: -9999px;
  top: -9999px;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.dockBG};
  }
  &:checked + span {
    transition: 0.4s ease-in;
    background: ${TangleColors.heroCTA};

    border-radius: 14px;
    :hover {
      background: ${TangleColors.lighthover};
      color: ${TangleColors.black};
    }
    &::before {
      transition: 0.4s ease-in;
      /* left: 25px; */
      left: calc(100% - 2px);
      transform: translateX(-100%);
      :hover {
        color: ${TangleColors.black};
        background: ${TangleColors.lighthover};
      }
    }
  }
`
const InputSpan = styled.span`
  display: flex;
  height: 32px;
  width: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 14px;
  background: ${TangleColors.tangleBlack};
  transition: background-color 0.4s ease-in;

  :hover {
    background: ${TangleColors.disabledBG};
    /* border: 1px solid ${TangleColors.white}; */
  }
  &::before {
    content: "";
    position: absolute;
    transition: 0.4s ease-in;
    border-radius: 14px;
    background: ${TangleColors.black};

    transition: 0.2s;
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  }
`
export default SlipageAuto
