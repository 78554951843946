import React, { FC } from "react"
import styled from "styled-components"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"

import { TangleColors } from "styles/ColorStyles"
interface IconProps {
  networkDropDownIconOpen?: boolean
  pendingTransaction?: boolean
}

const NetworkDropIcon: FC<IconProps> = (props) => {
  const { networkDropDownIconOpen, pendingTransaction } = props
  return (
    <DropdownCover
      className="NetworkDropIcon"
      pendingTransaction={pendingTransaction}
      // src={
      //   networkDropDownIconOpen
      //     ? pendingTransaction
      //       ? chevronUp
      //       : chevronWhiteUp
      //     : pendingTransaction
      //     ? chevronDown
      //     : chevronWhiteDown
      // }
    >
      {" "}
      {!pendingTransaction ? (
        <>
          {" "}
          {networkDropDownIconOpen ? <AddressUpArrow /> : <AddressDownArrow />}
        </>
      ) : null}
    </DropdownCover>
  )
}
const DropdownCover = styled.div<IconProps>`
  display: ${(props) => (props.pendingTransaction ? "none" : "flex")};
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
`
const AddressUpArrow = styled(FiChevronUp)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.white};
`
const AddressDownArrow = styled(FiChevronDown)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.white};
`

export default NetworkDropIcon
