import React, { FC, useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { FormTextInput, SelectInput, TextLabel } from "styles/InputStyles"
import { TangleColors } from "styles/ColorStyles"
import NFTBody from "components/nfts/NFTBody"
import { nftData } from "components/nfts/NFTData"
import { DAILY_RATE, LOCK_PERIOD_DAYS, ZERO } from "config/constants"
import { useWSCContext } from "context/MilkomedaContext"

import { useTangleship } from "utils/useTangleship"
import { calculateInterest } from "utils"
import moment from "moment"

interface NFTProps {}

const NFTContainer: FC<NFTProps> = (props) => {
  const [query, setQuery] = useState("")
  const [sortOption, setSortOption] = useState("Popular")

  const { account: userAddress, chainId } = useWSCContext()

  const { tangleship } = useTangleship()

  const [stakedNfts, setStakedNfts] = useState<any>([])
  const [totalLocked, setTotalLocked] = useState<any>([])
  const [stakeRewards, setStakeRewards] = useState<any>([])

  useEffect(() => {
    if (!chainId) return

    const fetchTotalLocked = async () => {
      const newTotalLocked = []
      for (const rate of Object.keys(DAILY_RATE)) {
        const storageKey = `nftLockedPerRate${chainId}${rate}`
        const storageValue = sessionStorage.getItem(storageKey)

        let res
        if (storageValue === null) {
          res = await tangleship?.getTotalNftLockedPerRate(rate)
          sessionStorage.setItem(storageKey, res)
        } else res = storageValue

        newTotalLocked.push({ rate, value: res })
      }
      setTotalLocked(newTotalLocked)
    }

    fetchTotalLocked()
  }, [chainId])

  useMemo(() => {
    const slugs = ["palm", "floating", "tranquillity", "ascension", "night"]
    let rewards = {
      palm: ZERO,
      floating: ZERO,
      tranquillity: ZERO,
      ascension: ZERO,
      night: ZERO,
    }
    stakedNfts.forEach((nft: any) => {
      const slug = slugs.filter((t: string) =>
        nft.name.toLowerCase().includes(t.toLowerCase())
      )[0]

      rewards[slug] = rewards[slug].add(
        calculateInterest(
          moment().utc().unix(),
          nft.stakeTime,
          nft.baseApy,
          nft.rewardPrincipal,
          nft.interestClaimed
        )
      )
    })
    setStakeRewards(rewards)
  }, [stakedNfts])

  const getUserStakedNfts = useCallback(
    async (refetch = false) => {
      if (!userAddress || !chainId) return

      const storageKey = `userStakedNfts${chainId}${userAddress}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        return JSON.parse(storageValue)
      }

      const res = await tangleship?.getNftStakedOfOwner(userAddress)
      const nfts = res || []
      sessionStorage.setItem(storageKey, JSON.stringify(nfts))
      return nfts
    },
    [userAddress, chainId]
  )

  async function getData() {
    try {
      // const staked = await tangleship?.getNftStakedOfOwner(userAddress)
      const staked = await getUserStakedNfts()
      const stakedTmp = []
      for (let i = 0; i < staked[0].length; i++) {
        const nftId = staked[0][i].toString()
        const ipfsUrl = staked[1][i]
        const stake = staked[2][i]

        const ipfsResponse = await fetch(ipfsUrl)
        const ipfsData = await ipfsResponse.json()
        const name = ipfsData.name

        stakedTmp.push({
          id: nftId,
          name: name,
          baseApy: stake["baseApy"],
          claimedTime: stake["claimedTime"],
          interestClaimed: stake["interestClaimed"],
          lockPeriodDays: LOCK_PERIOD_DAYS, // stake["lockPeriodDays"],
          rewardPrincipal: stake["rewardPrincipal"],
          stakeTime: stake["stakeTime"],
        })
      }
      setStakedNfts(stakedTmp)
    } catch (e) {}
  }

  useEffect(() => {
    if (!userAddress || !chainId) return
    getData()
  }, [userAddress, chainId])

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const allItems = nftData
    .filter((nft) => {
      return (
        nft.name.toLowerCase().includes(query) ||
        nft.slug.toLowerCase().includes(query) ||
        nft.title.toLowerCase().includes(query)
      )
    })
    .sort((a, b) => {
      if (sortOption === "Highest APR") return a.apr < b.apr ? 1 : -1
      else if (sortOption === "Popular") return a.apr > b.apr ? 1 : -1
      else {
        // sortOption === "Total Locked"
        const order1 = Object.keys(DAILY_RATE).indexOf(a.apr.toString())
        const order2 = Object.keys(DAILY_RATE).indexOf(b.apr.toString())
        const total = [2500, 1500, 500, 300, 200]

        return ((totalLocked[order1]?.result?.[0]?.toNumber() ?? 0) * 100) /
          total[order1] <
          ((totalLocked[order2]?.result?.[0]?.toNumber() ?? 0) * 100) /
            total[order2]
          ? 1
          : -1
      }
    })

  return (
    <Body>
      <SearchCover>
        <SearchForm>
          <Label htmlFor="search">SEARCH</Label>
          <SearchInput
            type="text"
            value={query}
            name="search"
            id="search"
            onChange={handleChangeQuery}
            placeholder="Search NFTs "
          />
        </SearchForm>

        <SelectForm>
          {" "}
          <Label htmlFor="sort">SORT</Label>
          <SelectButton
            id="sort"
            name="sort"
            value={sortOption}
            onChange={(event) => setSortOption(event.target.value)}
          >
            <option value="Popular">Popular</option>
            <option value="Highest APR">Highest APR</option>
            <option value="Total Locked">Total Locked</option>
          </SelectButton>
        </SelectForm>
      </SearchCover>
      <NFTBody
        data={allItems}
        rewards={stakeRewards}
        totalLocked={totalLocked}
      />{" "}
    </Body>
  )
}

export default NFTContainer

const Body = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 16px;
  margin: 0;
`
const SearchCover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1232px;
  margin: 26px auto 8px auto;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    margin: 24px auto 8px auto;
  }
`
const SearchForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 16px 0 0;
  @media only screen and (max-width: 750px) {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
`
const SearchInput = styled(FormTextInput)``
const SelectButton = styled(SelectInput)``
const Label = styled(TextLabel)`
  color: ${TangleColors.white};
  margin: 8px 0;
`
const SelectForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  @media only screen and (max-width: 750px) {
    max-width: 800px;
    margin: 16px auto;
    width: 100%;
  }
`
