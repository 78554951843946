import React, { FC, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body3, CaptionMedium, CaptionSmall, Subtitle } from "styles/TextStyles"
import { AiOutlineInfoCircle } from "react-icons/ai"
import NFTHoverBox from "./components/NFTHoverBox"
import { toSignificantDigits } from "utils/toSignificant"
import { voidLogo } from "components/utils/FrequentTokenLogos"
interface NFTProps {
  nftyield?: string | number
  text?: string
  title?: string
  locked?: string | number
  apr?: string | number
  image?: any
  logo?: any
  slug?: string
  totalLocked: any
}
const NFTCard: FC<NFTProps> = (props) => {
  const { nftyield, text, title, apr, image, slug, totalLocked } = props
  const [showStakedHelpText, setShowStakedHelpText] = useState<boolean>(false)

  const mouseEnterStaked = () => {
    setShowStakedHelpText(true)
  }

  const mouseLeaveStaked = () => {
    setShowStakedHelpText(false)
  }

  const reward = Number(nftyield.toString().replace(/,/g, "."))

  return (
    <>
      <CoverLink to={`/nft-staking/${slug}/`}>
        {" "}
        <Body>
          <Cover>
            <NFTImage src={image} alt={title} />
            <NFTTop>
              {" "}
              <NFTLogo src={voidLogo} alt={title} />
              <NFTText>
                {" "}
                <Title>{title}</Title>
                <Text>
                  <h4>
                    {text} {""}
                  </h4>{" "}
                  {"//"} {""}
                  <p> {""}TangleSwap Genesis NFT</p>
                </Text>
              </NFTText>
            </NFTTop>
            <NFTRow>
              <NFTPara>APY</NFTPara>
              <NFTParaMain>{apr}%</NFTParaMain>
            </NFTRow>{" "}
            <NFTRow>
              <NFTPara>
                Global NFTs Staked
                <InfoIconWrapper
                  onMouseEnter={mouseEnterStaked}
                  onMouseLeave={mouseLeaveStaked}
                >
                  {showStakedHelpText && (
                    <>
                      <NFTHoverBox text="Worldwide percentage of NFTs staked, relative to total supply of this category." />
                    </>
                  )}
                  <InfoIcon />
                </InfoIconWrapper>
              </NFTPara>
              <NFTAPR>
                {toSignificantDigits(totalLocked?.toString(), 2, true)}%
              </NFTAPR>
            </NFTRow>{" "}
            <NFTRow>
              <NFTPara>Your Rewards </NFTPara>
              <NFTAPR>{toSignificantDigits(reward, 4, false, 18)} VOID</NFTAPR>
            </NFTRow>
          </Cover>
        </Body>
      </CoverLink>
    </>
  )
}

const CoverLink = styled(Link)`
  width: 100%;
`
const Body = styled.div`
  min-height: 600px;
  max-width: 650px;
  width: 100%;
  padding: 8px;
  margin: 16px auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.4s ease-in;
  border: 1px solid ${TangleColors.grayLighter};

  background: ${TangleColors.swapBG};

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
  @media only screen and (max-width: 750px) {
    padding: 16px;
    max-width: 800px;
  }
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  color: ${TangleColors.white};

  width: 100%;
`

const NFTImage = styled.img`
  min-height: 350px;
  max-width: 800px;
  width: 100%;
  margin: 8px auto;
  object-fit: fill;
  border-radius: 4px;
`
const NFTTop = styled.div`
  max-width: 352px;
  width: 100%;
  margin: 24px auto 16px auto;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 750px) {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    margin: 16px auto 8px auto;
  }
`
const NFTLogo = styled.img`
  height: 40px;
  width: 40px;
  outline: none;
  border-radius: 99999px;
`
const NFTText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 16px;
  /* justify-content: center;
  align-items: center; */
`
const Title = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 0 0 8px 0;
  text-transform: uppercase;
`
const Text = styled(Body3)`
  color: ${TangleColors.white};
  /* margin: 0 8px 0 0; */

  display: flex;
  flex-direction: row;
  p {
    margin: 0 0 0 8px;
  }
  h4 {
    margin: 0 8px 0 0;
  }
`
const NFTRow = styled.div`
  max-width: 352px;
  width: 100%;
  margin: 16px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${TangleColors.white};
  padding: 0 0 16px 0;
  :last-child {
    border-bottom: none;
  }
  @media only screen and (max-width: 750px) {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
    margin: 16px auto 8px auto;
  }
`
const NFTPara = styled(Subtitle)`
  color: ${TangleColors.gray};
  /* text-transform: capitalize; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`
const NFTParaMain = styled(Subtitle)`
  color: ${TangleColors.lighthover};
`
const NFTAPR = styled(Subtitle)`
  color: ${TangleColors.white};
`
const InfoIconWrapper = styled.div`
  margin: 0 0 0 8px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  position: relative;
`
const InfoIcon = styled(AiOutlineInfoCircle)`
  width: 16px;
  height: 16px;
`
export default NFTCard
