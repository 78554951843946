import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"

interface SwapChartProps {
  token0: string
  token1: string
}
const SwapChart: FC<SwapChartProps> = (props) => {
  const { token0, token1 } = props
  // const { tokenHourData: token0HourData } = useHourDataChart(token0)
  // const { tokenHourData: token1HourData } = useHourDataChart(token1)
  let token0HourData: any
  let token1HourData: any
  const [latestValue, setLatestValue] = React.useState<number | undefined>()
  const [valueLabel, setValueLabel] = React.useState<string | undefined>()
  // const tokenPriceComparison = React.useMemo(() => {
  //   if (token0HourData && token1HourData) {
  //     // First, create a map for quick lookup from token1HourData
  //     const token1DataMap = new Map(
  //       token1HourData.map((day) => [day.timestamp, day])
  //     )

  //     // Now, filter and map token0HourData based on matching timestamps
  //     return token0HourData
  //       .filter((day0) => token1DataMap.has(day0.timestamp)) // Keep only days with matching timestamps
  //       .map((day0) => {
  //         const day1 = token1DataMap.get(day0.timestamp)
  //         return {
  //           // time: Number(day0.timestamp),
  //           // token0: {
  //           //   close: Number(day0.close),
  //           //   open: Number(day0.open),
  //           //   high: Number(day0.high),
  //           //   low: Number(day0.low),
  //           // },
  //           // token1: {
  //           //   close: Number(day1.close),
  //           //   open: Number(day1.open),
  //           //   high: Number(day1.high),
  //           //   low: Number(day1.low),
  //           // },
  //         }
  //       })
  //   }
  //   return []
  // }, [token0HourData, token1HourData])

  return (
    <ChartBackground>
      <ChartText>Coming Soon</ChartText>{" "}
      {/* <TokensCandleChart
        // data={adjustedToCurrent}
        setValue={setLatestValue}
        setLabel={setValueLabel}
        color={TangleColors.lighthover}
      /> */}
    </ChartBackground>
  )
}

const ChartBackground = styled.div`
  min-height: 500px;
  width: 100%;
  margin: 24px auto;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ChartText = styled(CaptionSmall)`
  color: ${TangleColors.tangleYellow};
  text-align: center;
`

export default SwapChart
