import React from "react"
import styled from "styled-components"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
interface CurrencyProps {
  token0Logo?: string
  token1Logo?: string
  token0Symbol?: string
  token1Symbol?: string
}
const OrbitRouterCurrencyImages: React.FC<CurrencyProps> = (props) => {
  const { token0Logo, token1Logo, token0Symbol, token1Symbol } = props
  return (
    <SizeImageWrapper>
      <>
        {" "}
        <SizeImages>
          {!!token0Logo ? (
            <>
              {" "}
              <SizeFirstIcon src={token0Logo} />
            </>
          ) : (
            <>
              {" "}
              <SizeFirstIconCover>
                <ConvertTokenSymbol
                  width={26}
                  height={26}
                  text={token0Symbol}
                />{" "}
              </SizeFirstIconCover>
            </>
          )}
          {!!token1Logo ? (
            <>
              <SizeSecondIcon src={token1Logo} />
            </>
          ) : (
            <>
              {" "}
              <SizeSecondIconCover>
                {" "}
                <ConvertTokenSymbol
                  width={26}
                  height={26}
                  text={token1Symbol}
                />
              </SizeSecondIconCover>
            </>
          )}
        </SizeImages>
      </>
    </SizeImageWrapper>
  )
}
const SizeImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  max-width: 72px;
  width: 100%;
  margin: 8px 2px -6px 0;
`
const SizeImages = styled.div`
  max-width: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`
const SizeFirstIcon = styled.img`
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 9999px;
  z-index: 5;
`
const SizeSecondIcon = styled.img`
  height: 24px;
  width: 24px;
  object-fit: cover;

  border-radius: 9999px;
`
const SizeFirstIconCover = styled.div`
  height: 24px;
  width: 24px;
  object-fit: cover;

  top: 0;
  border-radius: 9999px;
`
const SizeSecondIconCover = styled.div`
  height: 24px;
  width: 24px;
  object-fit: cover;

  border-radius: 9999px;

  z-index: 3;
`
export default OrbitRouterCurrencyImages
