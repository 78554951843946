import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { BsArrowLeft } from "react-icons/bs"
import { Caption } from "styles/TextStyles"
interface BackProps {
  link?: string
}
const GoBackButton: React.FC<BackProps> = (props) => {
  const { link } = props
  return (
    <>
      <GoBack to={`${link}`}>
        <BackIcon />
        <BackText>Go back</BackText>
      </GoBack>
    </>
  )
}
const GoBack = styled(Link)`
  max-width: 120px;
  width: 100%;
  height: 40px;
  border-radius: 24px;
  margin: 0 0 12px 0;
  background: ${TangleColors.white};
  color: ${TangleColors.black};
  display: flex;
  justify-content: center;
  border: 1px solid transparent;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.heroCTA};
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.lighthover};
  }
`
const BackIcon = styled(BsArrowLeft)`
  margin: 0 4px 0 0;
  width: 24px;
  height: 24px;
  color: ${TangleColors.black};
  transition: 0.4s ease-in;
  ${GoBack}:hover & {
    color: ${TangleColors.white};
  }
`
const BackText = styled(Caption)`
  color: ${TangleColors.black};
  transition: 0.4s ease-in;
  ${GoBack}:hover & {
    color: ${TangleColors.white};
  }
`
export default GoBackButton
