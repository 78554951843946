import { LOVELACE_UNIT } from "constants/milkomeda/transaction"
import { useState, Fragment, FC, useEffect, RefObject } from "react"
import {
  ActionButton,
  ButtonContainer,
  CurrentStepWrapper,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  WrapContainer,
} from "../styles"
import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"
import WrapStep from "../transactionStepper/WrapStep"
import TokenAllowanceStep from "../transactionStepper/TokenAllowanceStep"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import UnwrapStep from "../transactionStepper/UnwrapStep"
import { IWrapData } from "interfaces/wscSwap.interface"
import ModalTopIcon from "../component/ModalTopIcon"
import WSCModalCover from "../component/WSCModalCover"

import ModalActionState from "../ModalActionState"
import { useBackup } from "../component/Backup"
import { isInvalid } from "@tangleswap/sdk"
import { useCheckBalanceForGas } from "../component/GasCheck"
import WrapGas from "../transactionStepper/WrapGasStep"

interface SwapModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  swapData?: IWrapData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  skipWallet?: () => void
  openWalletOverview?: () => void
  cancelTransaction?: () => void
}

const SwapModal: FC<SwapModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    swapData,
    wscModalRef,
    closeWSCContinue,
    skipWallet,
    openWalletOverview,
    cancelTransaction,
  } = props

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [needApproveOrigin, setNeedApproveOrigin] = useState<boolean>(false)
  const [needApproveDest, setNeedApproveDest] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [steps, setSteps] = useState<any[]>([])

  const actionType = "Swap" // Swap, LP, Farm, VEStaking, or IHub
  const { tangleship } = useTangleship()
  const { account, chainId } = useWSCContext()
  const { data, backup } = useBackup(steps, actionType, setCurrentStep) as {
    data: IWrapData
    backup: boolean
  }
  const { needGasWrap } = useCheckBalanceForGas()

  const nextStep = () => setCurrentStep((prev) => prev + 1)

  const gasWrap = needGasWrap && swapData?.tokenIn?.unit !== LOVELACE_UNIT

  useEffect(() => {
    if (!backup) {
      setCurrentStep(needGasWrap ? 0 : 1)
    }

    const newSteps = []
    let stepCounter = 1

    if (gasWrap && !backup) {
      newSteps.push({
        number: stepCounter++,
        title: "Gas",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            data={backup ? data : swapData}
            nextStep={nextStep}
            actionType={actionType}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Wrap",
      actionTitle: "Wrapping",
      component: (
        <WrapStep
          data={backup ? data : swapData}
          nextStep={nextStep}
          actionType={actionType}
          isBackup={backup}
          isUnwrapNeeded={true}
        />
      ),
    })

    if (needApproveOrigin) {
      newSteps.push({
        number: stepCounter++,
        title: "Approve",
        actionTitle: "Approving",
        component: (
          <TokenAllowanceStep
            nextStep={nextStep}
            tokenToCheck={backup ? data?.tokenIn : swapData?.tokenIn}
            actionType={actionType}
            evmFunction={backup ? data?.evmFunction : swapData?.evmFunction}
            data={backup ? data : swapData}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={backup ? data : swapData}
          nextStep={nextStep}
          actionType={actionType}
          isBackup={backup}
          toggleModal={toggleModal}
        />
      ),
    })

    if (needApproveDest) {
      newSteps.push({
        number: stepCounter++,
        title: "Approve",
        actionTitle: "Approving",
        component: (
          <TokenAllowanceStep
            nextStep={nextStep}
            tokenToCheck={backup ? data.tokenOut : swapData.tokenOut}
            actionType={actionType}
            evmFunction={backup ? data.evmFunction : swapData.evmFunction}
            data={backup ? data : swapData}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Unwrap",
      actionTitle: "Unwrapping",
      component: (
        <UnwrapStep
          data={backup ? data : swapData}
          toggleModal={toggleModal}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapData, data, backup, needApproveDest, needApproveOrigin, gasWrap])

  const fetchAllowance = async (token, refetch = false) => {
    if (!account || !token?.address || isInvalid([token?.decimals])) return

    const storageKey = `wscAllowance${chainId}${account}${token.address}${actionType}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      return Number(storageValue)
    }

    const res = await tangleship?.getAllowance(
      token.address,
      account,
      actionType,
      true
    )
    const allowance = Number(res?._hex) / 10 ** token.decimals
    sessionStorage.setItem(storageKey, String(allowance))
    return allowance
  }

  const checkExecuteActionAllowance = async () => {
    if (!swapData?.tokenIn || (backup && !data?.tokenIn)) return
    const token = backup ? data?.tokenIn : swapData?.tokenIn

    if (token.unit === LOVELACE_UNIT) {
      setNeedApproveOrigin(false)
      return
    }

    const allowance = await fetchAllowance(token)
    setNeedApproveOrigin(Number(allowance) < token?.amount)
  }

  useEffect(() => {
    if (!account || !chainId) return
    checkExecuteActionAllowance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapData?.tokenIn, account, chainId, backup])

  return (
    <>
      {isVisible ? (
        <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
          <WrapContainer onClick={(e) => e.stopPropagation()}>
            <ModalTopIcon
              title="Swap Tokens"
              closeModal={toggleModal}
              cancelTransaction={cancelTransaction}
            />

            <ModalActionState
              skipWallet={skipWallet}
              openWalletOverview={openWalletOverview}
              actionTitle={steps[currentStep - 1]?.actionTitle}
            />
            {/* Wait For Pending Transaction */}
            <StepWrapper>
              {steps.map((step, index) => (
                <Fragment key={step.number}>
                  <StepContainer>
                    <StepButton
                      active={currentStep === step.number}
                      onClick={() => {
                        if (currentStep === step.number) return
                        setCurrentStep(step.number)
                      }}
                    >
                      {step.number}
                    </StepButton>
                    <StepTitle active={currentStep === step.number}>
                      {step.title}
                    </StepTitle>
                  </StepContainer>
                  {index < steps.length - 1 && <StepDivider />}
                </Fragment>
              ))}
            </StepWrapper>
            <CurrentStepWrapper>
              {steps[currentStep - 1]?.component}
            </CurrentStepWrapper>
            {isComplete && currentStep === steps.length && (
              <ButtonContainer>
                <ActionButton onClick={toggleModal}>
                  Continue using TangleSwap
                </ActionButton>
              </ButtonContainer>
            )}
          </WrapContainer>
        </WSCModalCover>
      ) : null}
    </>
  )
}

export const useSwapModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default SwapModal
