import React from "react"
import Jazzicon from "react-jazzicon-custom-colors"
import styled from "styled-components"

interface JazziconProps {
  address?: string
}
const JazzIcon: React.FC<JazziconProps> = (props) => {
  const { address } = props

  const myColorsArray = ["#4C0066", "#740E95", "#8F4EAD", "#D874FA"]

  const addr = address?.slice(2, 10)
  const seedNumber = parseInt(addr, 16)

  return (
    <JazzCoverWrapper>
      <Jazzicon colors={myColorsArray} diameter={28} seed={seedNumber} />
    </JazzCoverWrapper>
  )
}
const JazzCoverWrapper = styled.div`
  width: 28px;
  height: 24px;
  margin: -2px 6px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default JazzIcon
