import React, { FC } from "react"
import { TangleColors } from "styles/ColorStyles"
import styled from "styled-components"

interface GraphProps {
  startPrice?: number
  endPrice?: number
  currentLevel?: number
  currentPrice?: number
  width?: number // Added width prop
  height?: number // Added height prop
  currentPriceSymbol?: string
  auctionType?: boolean
  color?: any
}

interface LiquidityProps {
  width: number
  height: number
}

const LiquiditySmallGraph: FC<GraphProps> = ({
  currentLevel = 0, // Assigning a default value for optional props

  auctionType,
  color,
}) => {
  const startY = 47
  const startX = 0
  const endX = 250

  // Calculate the x-coordinate of the current level indicator
  const currentX = startX + (endX - startX) * currentLevel

  return (
    <StyledSVG
      auctionType={auctionType}
      className="text-green"
      viewBox={`0 0 310 120`}
      width={450}
      height={480}
    >
      {/* Start circle */}
      <circle
        r="5"
        cx={startX}
        cy={startY}
        className="firstCircle"
        fill={TangleColors.lighthover}
      ></circle>

      {/* Entire line (black) */}
      <line
        x1={startX}
        y1={startY}
        x2={endX}
        y2={startY}
        stroke={TangleColors.grayDark}
        strokeWidth="2"
        opacity="0.7"
      ></line>

      {/* Completed line (green) */}
      <line
        x1={startX}
        y1={startY}
        x2={currentX}
        y2={startY}
        stroke={TangleColors.lighthover}
        strokeWidth="2"
      ></line>

      {/* Current price line (red) */}
      <line
        className="lineShowcase"
        x1={currentX}
        y1={startY - 10}
        x2={currentX}
        y2={startY + 10}
        stroke={color}
        strokeWidth="2"
      ></line>

      {/* End circle */}
      <circle
        className="circleShowcase"
        r="5"
        cx={endX}
        cy={startY}
        fill={TangleColors.grayDark}
      ></circle>

      {/* End price label */}
    </StyledSVG>
  )
}

const StyledSVG = styled.svg<LiquidityProps & GraphProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  color: ${TangleColors.white};
  margin-top: ${(props) => (props.auctionType ? `96px` : `64px`)};
  margin-left: 8px;
  transform: ${(props) =>
    props.auctionType ? `rotate(15deg)` : `rotate(0deg)`};
  .firstCircle {
    margin: 0 0 0 8px;
  }
  .circleShowcase {
    position: relative;
    z-index: 66;
  }
  .lineShowcase {
    position: relative;
    z-index: 88;
  }
  text {
    fill: ${TangleColors.white} !important;
  }
`

export default LiquiditySmallGraph
