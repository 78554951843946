import React, { FC, ReactNode } from "react"

import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Header7 } from "styles/TextStyles"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

interface AccountProps {
  children: ReactNode
}

interface ActiveLink {
  active?: boolean
}
const AccountLayout: FC<AccountProps> = (props) => {
  const { children } = props
  const route = useLocation()

  const airdropTrue = route.pathname === "/airdrop"
  const portfolioTrue = route.pathname === "/portfolio"
  return (
    <AccountBody>
      <AccountCover>
        <AccountSidebar>
          <SidebarLinksWrapper>
            <SidebarLinks>
              <Link to="/airdrop">
                <SidebarLinksText active={airdropTrue}>
                  • Airdrop
                </SidebarLinksText>
              </Link>
            </SidebarLinks>
            {/* <SidebarLinks>
              {" "}
              <Link to="/portfolio">
                <SidebarLinksText active={portfolioTrue}>
                  • Portfolio
                </SidebarLinksText>
              </Link>
            </SidebarLinks> */}
          </SidebarLinksWrapper>
        </AccountSidebar>
        <AccountContent>{children}</AccountContent>
      </AccountCover>
    </AccountBody>
  )
}

const AccountBody = styled.div`
  width: 100%;
  max-width: 1232px;
  margin: 8px auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const AccountCover = styled.div`
  display: grid;
  grid-template-columns: 140px auto;
  grid-gap: 32px;
  gap: 32px;
  width: 100%;
`
const AccountSidebar = styled.div`
  margin: 20px 0 0 0;
  padding: 0 12px;
`
const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
`

const SidebarLinksWrapper = styled.ul`
  display: flex;
  flex-direction: column;
`
const SidebarLinks = styled.li`
  width: 100%;
  margin: 16px 0;
`
const SidebarLinksText = styled(Header7)<ActiveLink>`
  cursor: pointer;
  color: ${(props) =>
    props.active ? `${TangleColors.white}` : `${TangleColors.grayDark}`};
  transition: 0.4s ease-in;

  :hover {
    color: ${(props) =>
      props.active
        ? `${TangleColors.lighthover}`
        : `${TangleColors.lightPurple}`};
  }
`
export default React.memo(AccountLayout)
