import Spinner from "react-spinner-material"
import styled from "styled-components"

const LoaderSpinner = () => {
  return (
    <SpinnerCover>
      <Spinner radius={14} color={"#FFCB00"} stroke={2} visible={true} />
    </SpinnerCover>
  )
}
const SpinnerCover = styled.div`
  display: flex;
  margin: 0 0 0 6px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export default LoaderSpinner
