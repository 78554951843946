import { updateObject } from "store/utility"
import {
  TANGLE_PRO_MODE,
  TANGLE_POSITION_STATUS_MODE,
} from "../actions/actionsTypes"

export interface TradingMode {
  tradingMode: any
  tanglePositionMode: any
}
const initialState: TradingMode = {
  tradingMode: null,
  tanglePositionMode: null,
}

const updateTradingPro = (state: any, action: any) => {
  return updateObject(state, {
    tradingMode: action.tradingMode,
  })
}
const updateTradingPositionStatus = (state: any, action: any) => {
  return updateObject(state, {
    tanglePositionMode: action.tanglePositionMode,
  })
}

const TradingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TANGLE_PRO_MODE:
      return updateTradingPro(state, action)
    case TANGLE_POSITION_STATUS_MODE:
      return updateTradingPositionStatus(state, action)
    default:
      return state
  }
}

export default TradingReducer
