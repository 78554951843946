import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall, Subtitle } from "styles/TextStyles"
import { shortenWSCAddress } from "utils"
import { HiDotsVertical } from "react-icons/hi"
interface TransactionProps {
  text?: string
  subText?: any
  link?: any
  hash?: string
}

const TransactionHrefRow: FC<TransactionProps> = (props) => {
  const { text, link, hash } = props
  const href = `${link}${hash}`
  const openLink = () => {
    window.open(href, "_blank")
  }
  return (
    <TransactionInfoCover>
      <TransactionTitle>{text}</TransactionTitle>
      <TxDot />

      <TransactionHref
        href={href}
        onClick={openLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TransactionDetailCover>
          <BottomText>{shortenWSCAddress(hash)}</BottomText>
        </TransactionDetailCover>
      </TransactionHref>
    </TransactionInfoCover>
  )
}

const TransactionHref = styled.a`
  color: ${TangleColors.lighthover};

  cursor: pointer;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.white};
  }
`
const TransactionInfoCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${TangleColors.white};
`
const TxDot = styled(HiDotsVertical)`
  width: 16px;
  height: 16px;
  margin: 0 4px 0 8px;
  color: ${TangleColors.white};
`
const TransactionDetailCover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 0;
  color: ${TangleColors.white};
`

const TransactionTitle = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  margin: 0 8px 0 0;
`
const TransactionText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 0;
`
const BottomText = styled(Subtitle)`
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  ${TransactionText}:hover & {
    color: ${TangleColors.white};
  }
`
export default TransactionHrefRow
