import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body3, CaptionTiny } from "styles/TextStyles"
import { motion, AnimatePresence } from "framer-motion"
interface HoverProps {
  text?: string | any
  left?: any
  top?: any
}
const AuctionHoverBox: FC<HoverProps> = (props) => {
  const { text, left, top } = props
  return (
    <AnimatePresence>
      <HoverBody
        top={top}
        left={left}
        initial={{ scale: 0, y: 0 }}
        animate={{ scale: 1, y: 10 }}
        exit={{ scale: 0, y: 0 }}
        transition={{
          duration: 0.1,
          ease: "easeIn",
        }}
        style={{ transformOrigin: "top left" }}
      >
        {" "}
        <HoverCover>
          {" "}
          <HoverText>{text}</HoverText>
        </HoverCover>
      </HoverBody>
    </AnimatePresence>
  )
}

const HoverBody = styled(motion.div)<HoverProps>`
  height: 144px;
  width: 320px;

  position: absolute;
  /* top: 56px;
  left: 68px; */
  top: ${(props) => (props.top ? `${props.top}px` : `56px`)};

  left: ${(props) => props.left && `${props.left}px`};
  transition: 0.4s ease-in;
  background: ${TangleColors.tangleHarshBlack};
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 8px 16px;
  z-index: 88;
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.lighthover};
`
const HoverCover = styled(motion.div)<HoverProps>`
  height: 88px;
  width: 320px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    position: absolute;
    content: "";
    top: -43px;
    width: 16px;
    height: 16px;
    left: 20px;
    background-color: ${TangleColors.tangleHarshBlack};
    transform: translate(-50%, 50%) rotate(225deg);

    border-width: 2px;
    border-right-style: solid;
    border-bottom-style: solid;
    border-color: ${TangleColors.lighthover};
  }
`
const HoverText = styled(CaptionTiny)`
  text-align: left;
  line-height: 1.4 !important;
`
export default AuctionHoverBox
