import React, { useCallback, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import CreationContext from "../CreationContext"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import {
  ActionContainer,
  BackButton,
  CounterColumn,
  Input,
  InputGroup,
  InputLabel,
  InputSubLabel,
  ItemBody,
  ItemColumn,
  ItemSubTitle,
  ItemTitle,
  NextButton,
  StepCounter,
  TitleContainer,
} from "../CreateAuction"
import { Caption } from "styles/TextStyles"
import { SubmitHandler, useForm } from "react-hook-form"

import { useWSCContext } from "context/MilkomedaContext"
import { PaymentCurrencyData } from "data/PaymentCurrencyData"
import { Chains } from "@tangleswap/sdk"
import { useAppSelector } from "store/hooks"

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { TokenInfoType } from "components/LaunchPad/types"
import { useTangleship } from "utils/useTangleship"
interface GeneralDetailsValue {
  startDate?: any
  endDate?: any
  paymentCurrencyAddress?: string
}

interface FormProps {
  formError?: boolean
  active?: boolean
}
const GeneralDetails = () => {
  const { tokenDetails, setTokenDetails, currentStep, setCurrentStep } =
    useContext(CreationContext)
  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(
    new Date(startDate.getTime() + 1 * 60 * 60 * 1000)
  )
  const { tangleship } = useTangleship()
  const [startDateError, setStartDateError] = useState<any>(undefined)
  const [tokenChainInfo, setTokenChainInfo] = useState<any>(undefined)
  const [tokenDecimals, setTokenDecimals] = useState<any>(undefined)

  const [tokenSymbol, setTokenSymbol] = useState<any>(undefined)
  const [invalidToken, setInvalidToken] = useState<boolean>(false)
  const [endDateError, setEndDateError] = useState<any>(undefined)
  const { chainId, l1ChainId } = useWSCContext()

  const { register, handleSubmit, watch, setValue, setError } =
    useForm<GeneralDetailsValue>({
      defaultValues: {
        paymentCurrencyAddress: "",
      },

      mode: "onChange",
      reValidateMode: "onChange",
    })
  const isToday = (date) => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }
  const filterPassedTime = (time) => {
    const currentTime = new Date()

    if (isToday(endDate)) {
      return new Date(time).getTime() > currentTime.getTime()
    }
    return true
  }

  const isStartDate = (date) => {
    const today = new Date(startDate)
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const filterEndPassedTime = (time) => {
    const currentTime = new Date(startDate)
    if (isStartDate(endDate)) {
      return new Date(time).getTime() > currentTime.getTime()
    }
    return true
  }

  const onSubmit: SubmitHandler<GeneralDetailsValue> = (data) => {
    if (
      startDate === undefined ||
      endDate === undefined ||
      new Date(startDate) > new Date(endDate) ||
      new Date(watch("startDate")) > new Date(watch("endDate")) ||
      new Date(startDate).getTime() === new Date(endDate).getTime() ||
      new Date(watch("startDate")).getTime() ===
        new Date(watch("endDate")).getTime()
    )
      return
    setTokenDetails({
      ...tokenDetails,
      paymentCurrencyAddress: watch("paymentCurrencyAddress"),
      paymentCurrencySymbol: tokenSymbol,
      startDate: watch("startDate"),
      endDate: watch("endDate"),
      paymentCurrencyDecimals: tokenDecimals,
    })
    setCurrentStep((currentStep: number) => currentStep + 1)
  }

  useEffect(() => {
    if (!chainId) return
    const chainValue: Chains = chainId
    const data = PaymentCurrencyData[chainValue]
    if (!data) {
      console.warn(`Data for chainValue ${chainValue} is not found.`)
      return
    }

    const tokenData = tangleswapTokenListOnChain?.filter(
      (res: TokenInfoType) => {
        return data.includes(res.address)
      }
    )

    setTokenChainInfo(tokenData)
  }, [chainId, tangleswapTokenListOnChain, PaymentCurrencyData])

  const fetchTokenInfo = async (refetch = false) => {
    setInvalidToken(false)
    const addr = String(watch("paymentCurrencyAddress"))?.trim()
    if (!addr || !chainId) return

    const storageKey = `ihubNewAucTokInfo${chainId}${addr}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      const res = JSON.parse(storageValue)
      setTokenSymbol(res?.symbol)
      setTokenDecimals(res?.decimals)
      setInvalidToken(false)
      return
    }

    const res = await fetchTangleCurrency(addr, l1ChainId, tangleship)

    localStorage.setItem(storageKey, JSON.stringify(res))
    if (res === undefined) {
      setInvalidToken(true)
    } else {
      setInvalidToken(false)
      setTokenSymbol(res?.symbol)
      setTokenDecimals(res?.decimals)
    }
  }

  useEffect(() => {
    if (!chainId || !watch("paymentCurrencyAddress")) return
    if (!!watch("paymentCurrencyAddress")) {
      fetchTokenInfo()
    }
  }, [chainId, watch("paymentCurrencyAddress")])

  const handleClick = (value?: any) => {
    setValue("paymentCurrencyAddress", value)
  }

  useEffect(() => {
    if (startDate === undefined) setStartDateError("Please select start date")

    if (
      new Date(startDate) > new Date(endDate) ||
      new Date(watch("startDate")) > new Date(watch("endDate"))
    )
      setStartDateError("Start date can not be ahead of end date")
    if (
      new Date(startDate).getTime() === new Date(endDate).getTime() ||
      new Date(watch("startDate")).getTime() ===
        new Date(watch("endDate")).getTime()
    )
      setStartDateError("Start date can not be the same as end date")
    if (endDate === undefined) setEndDateError("Please update end date")
  }, [startDate, endDate, watch("endDate"), watch("startDate")])

  return (
    <ItemBody active={currentStep === 1}>
      <CounterColumn>
        <StepCounter active={currentStep === 1}>1</StepCounter>
      </CounterColumn>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ItemColumn>
          <TitleContainer>
            <ItemTitle>General Details*</ItemTitle>
            {currentStep === 1 ? (
              <ItemSubTitle>
                Select the currency you will accept as payment, and the dates
                for the auction.
              </ItemSubTitle>
            ) : null}
          </TitleContainer>
          {currentStep === 1 ? (
            <>
              <InputGroup>
                <InputLabel>Payment Currency*</InputLabel>
                <CurrencySelector>
                  {tokenChainInfo?.map((symbol, index) => {
                    return (
                      <CurrencyCover key={index}>
                        {" "}
                        <CurrencyOption
                          type="radio"
                          value={symbol.address}
                          onClick={() => handleClick(symbol.address)}
                          active={
                            String(watch("paymentCurrencyAddress"))
                              .trim()
                              .toLowerCase() ===
                            String(symbol.symbol).trim().toLowerCase()
                          }
                        />
                        <Currency
                          active={
                            String(watch("paymentCurrencyAddress"))
                              .trim()
                              .toLowerCase() ===
                            String(symbol.address).trim().toLowerCase()
                          }
                        >
                          <CurrencyText
                            active={
                              String(watch("paymentCurrencyAddress"))
                                .trim()
                                .toLowerCase() ===
                              String(symbol.address).trim().toLowerCase()
                            }
                          >
                            {symbol.symbol}
                          </CurrencyText>
                        </Currency>
                      </CurrencyCover>
                    )
                  })}
                </CurrencySelector>
                <FormInputWrapper>
                  <FormInputTokenSymbol>
                    <SymbolText>
                      {!!watch("paymentCurrencyAddress") && invalidToken
                        ? null
                        : tokenSymbol}
                    </SymbolText>
                  </FormInputTokenSymbol>
                  <Input
                    className="form_symbol"
                    {...register("paymentCurrencyAddress", {
                      required: true,
                    })}
                    onInput={(e: any) => {
                      setValue("paymentCurrencyAddress", e.target.value)
                      e.target.value = e.target.value.replace(
                        /[,.#?"-+=|!()@%^*~`<>{}]/g,
                        ""
                      )
                      const parts = e.target.value.split(".")

                      if (parts.length > 2) {
                        e.target.value =
                          parts[0] + "." + parts.slice(1).join("")
                      }
                      if (
                        String(e.target.value).trim().toLowerCase() ===
                          String(tokenDetails?.tokenAddress)
                            .trim()
                            .toLowerCase() ||
                        String(watch("paymentCurrencyAddress"))
                          .trim()
                          .toLowerCase() ===
                          String(tokenDetails?.tokenAddress)
                            .trim()
                            .toLowerCase()
                      ) {
                        // e.target.value = ""
                        setError("paymentCurrencyAddress", {
                          message:
                            "Payment Currency can not be the same as provided token",
                        })
                      }
                    }}
                    value={watch("paymentCurrencyAddress")}
                  />
                </FormInputWrapper>
                {!!watch("paymentCurrencyAddress") && invalidToken ? (
                  <>
                    {" "}
                    <ErrorMessage
                      active={Boolean(
                        !!watch("paymentCurrencyAddress") && invalidToken
                      )}
                    >
                      Invalid token address
                    </ErrorMessage>
                  </>
                ) : null}

                {!!watch("paymentCurrencyAddress") ? (
                  <>
                    {Boolean(
                      String(watch("paymentCurrencyAddress"))
                        .trim()
                        .toLowerCase() ===
                        String(tokenDetails?.tokenAddress).trim().toLowerCase()
                    ) ? (
                      <>
                        {" "}
                        <ErrorMessage
                          active={Boolean(
                            String(watch("paymentCurrencyAddress"))
                              .trim()
                              .toLowerCase() ===
                              String(tokenDetails?.tokenAddress)
                                .trim()
                                .toLowerCase()
                          )}
                        >
                          Payment currency can not be the same as provided token
                          address
                        </ErrorMessage>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <InputSubLabel>
                      If you don't see the payment token you want, please paste
                      your desired token address.
                    </InputSubLabel>
                  </>
                )}
              </InputGroup>
              <InputGroup>
                <InputLabel>Start Date*</InputLabel>
                <DatePickerCover
                  className="formInputRow"
                  {...register("startDate", { required: true })}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    setValue("startDate", date)
                  }}
                  autoComplete="off"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  minDate={new Date()}
                  placeholderText={new Date()}
                  filterTime={filterPassedTime}
                />
                {/* <DatePickerWrapper>

                  <DatePickerIconWrapper>
                    <DatePickerIcon />
                  </DatePickerIconWrapper>
                </DatePickerWrapper> */}
                {startDate === undefined ||
                new Date(startDate) > new Date(endDate) ||
                new Date(watch("startDate")) > new Date(watch("endDate")) ||
                new Date(startDate).getTime() === new Date(endDate).getTime() ||
                new Date(watch("startDate")).getTime() ===
                  new Date(watch("endDate")).getTime() ? (
                  <>
                    <ErrorMessage active={Boolean(startDateError)}>
                      <>{startDateError}</>
                    </ErrorMessage>
                  </>
                ) : null}{" "}
              </InputGroup>
              <InputGroup>
                <InputLabel>End Date*</InputLabel>
                {/* <DatePickerWrapper>
                  {" "}
                  <DatePickerIconWrapper>
                    <DatePickerIcon />
                  </DatePickerIconWrapper>
                </DatePickerWrapper> */}
                <DatePickerCover
                  autoComplete="off"
                  {...register("endDate", { required: true })}
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date)
                    setValue("endDate", date)
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  filterTime={filterEndPassedTime}
                  placeholderText={new Date()}
                  minDate={
                    startDate
                      ? new Date(startDate.getTime() + 1 * 60 * 60 * 1000)
                      : new Date()
                  }
                />
                {endDateError === undefined ? (
                  <>
                    <ErrorMessage active={Boolean(endDateError)}>
                      <>{endDateError}</>
                    </ErrorMessage>
                  </>
                ) : null}
              </InputGroup>
              <ActionContainer>
                <NextButton
                  disabled={
                    !watch("paymentCurrencyAddress") ||
                    !watch("startDate") ||
                    !watch("endDate") ||
                    Boolean(
                      String(watch("paymentCurrencyAddress"))
                        .trim()
                        .toLowerCase() ===
                        String(tokenDetails?.tokenAddress).trim().toLowerCase()
                    ) ||
                    watch("paymentCurrencyAddress") === "" ||
                    watch("startDate") === undefined ||
                    watch("endDate") === undefined ||
                    new Date(startDate) > new Date(endDate) ||
                    new Date(watch("startDate")) > new Date(watch("endDate")) ||
                    new Date(startDate).getTime() ===
                      new Date(endDate).getTime() ||
                    new Date(watch("startDate")).getTime() ===
                      new Date(watch("endDate")).getTime()
                  }
                  type="submit"
                >
                  Continue
                </NextButton>
                <BackButton
                  onClick={() => {
                    setCurrentStep((currentStep: number) => currentStep - 1)
                  }}
                >
                  Back
                </BackButton>
              </ActionContainer>
            </>
          ) : null}
        </ItemColumn>
      </form>
    </ItemBody>
  )
}

const CurrencySelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding: 4px;
  border: 1px solid ${TangleColors.darkText};
  border-radius: 9999px;
  margin: 6px 0;
`

const Currency = styled.div<FormProps>`
  background: ${(props) =>
    props.active ? TangleColors.lighthover : "transparent"};
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.white};
  cursor: pointer;

  transition: 0.4s ease-in;
  padding: 4px 14px;
  border-radius: 12px;
  &:hover {
    color: ${TangleColors.cta};
  }
`

const CurrencyOption = styled.input<FormProps>`
  background: transparent;
  position: absolute;

  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`

const CurrencyCover = styled.div`
  position: relative;
`
const CurrencyText = styled(Caption)<FormProps>`
  color: ${(props) => (props.active ? TangleColors.black : TangleColors.white)};
`
const DatePickerCover = styled(DatePicker)<FormProps>`
  width: 100%;

  height: 48px;
  padding: 12px 16px;
  color: ${TangleColors.white};
  background: ${TangleColors.swapBG};
  position: relative;
  z-index: 1;
  border: ${(props) =>
    props.formError
      ? ` 1.5px solid ${TangleColors.red}`
      : ` 1.4px solid ${TangleColors.white}`};
  border-radius: 8px;
  font-family: "CabinetGrotesk Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.lighthover};
    border: none;
    border: 1.4px solid ${TangleColors.white};
  }
  :focus {
    outline: none;
    border: 1.4px solid ${TangleColors.white};
  }
  :hover {
    outline: none;
    border: 1.4px solid ${TangleColors.white};
  }
  ::placeholder {
    color: ${TangleColors.grayDark};
  }
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

const ErrorMessage = styled(Caption)<FormProps>`
  color: ${TangleColors.red};
  width: 100%;
  display: ${(props) => (props.active ? "flex" : "none")};
`

const FormInputWrapper = styled.div`
  position: relative;
  width: 100%;
  .form_symbol {
    padding: 14px 0 14px 96px;
  }
`
const FormInputTokenSymbol = styled.div`
  position: absolute;
  top: 50%;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, -50%);
  width: 80px;
  height: 100%;
  z-index: 4;
  background: ${TangleColors.lighthover};
  border-top: 1.4px solid ${TangleColors.offWhite};
  border-bottom: 1.4px solid ${TangleColors.offWhite};
  border-left: 1.4px solid ${TangleColors.offWhite};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`
const SymbolText = styled(Caption)`
  color: ${TangleColors.black};
`

export default GeneralDetails
