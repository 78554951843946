import React, { FC, ReactNode, createContext, useRef } from "react"
interface HeroProps {
  children?: ReactNode
}
const HeroStateHeightContext = createContext<any>(undefined)
const HeroContext: FC<HeroProps> = (props) => {
  const { children } = props
  const heroRef = useRef<any>(null)

  const value = {
    heroRef,
  }
  return (
    <HeroStateHeightContext.Provider value={value}>
      {children}
    </HeroStateHeightContext.Provider>
  )
}

export default React.memo(HeroContext)
