import { TransactionHistoryProps } from "navbar/types"
import { useEffect, useState } from "react"
import {
  getYear,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isSameYear,
  isYesterday,
} from "date-fns"

export interface TransactionHistoryGroup {
  title: string
  transactions: Array<TransactionHistoryProps>
}
const getTimeSince = (timestamp: number) => {
  const seconds = Math.floor(Date.now() - timestamp * 1000)

  let interval
  // TODO(cartcrom): use locale to determine date shorthands to use for non-english
  if ((interval = seconds / 31556952000) > 1) return Math.floor(interval) + "y"
  if ((interval = seconds / 2592000000) > 1) return Math.floor(interval) + "mo"
  if ((interval = seconds / 86400000) > 1) return Math.floor(interval) + "d"
  if ((interval = seconds / 3600000) > 1) return Math.floor(interval) + "h"
  if ((interval = seconds / 60000) > 1) return Math.floor(interval) + "m"
  else return Math.floor(seconds / 1000) + "s"
}

export function useTimeSince(timestamp: number) {
  const [timeSince, setTimeSince] = useState<any>(getTimeSince(timestamp))

  useEffect(() => {
    const refreshTime = () => {
      if (Math.floor(Date.now() - timestamp * 1000) / 61000 <= 1) {
        setTimeSince(getTimeSince(timestamp))
        setTimeout(() => {
          refreshTime()
        }, 1000)
      }
    }
    refreshTime()
  }, [timestamp])

  return timeSince
}

const sortActivities = (
  a: TransactionHistoryProps,
  b: TransactionHistoryProps
) => Number(b?.timeStamp) - Number(a?.timeStamp)

export const createGroups = (activities?: Array<TransactionHistoryProps>) => {
  if (!activities || !activities.length) return []
  const now = Date.now()

  const today: Array<TransactionHistoryProps> = []
  const yesterday: Array<TransactionHistoryProps> = []
  const currentWeek: Array<TransactionHistoryProps> = []
  const last30Days: Array<TransactionHistoryProps> = []
  const currentYear: Array<TransactionHistoryProps> = []
  const lastYear: Array<TransactionHistoryProps> = []
  const yearMap: { [key: string]: Array<TransactionHistoryProps> } = {}

  // TODO(cartcrom): create different time bucket system for activities to fall in based on design wants
  activities.forEach((activity) => {
    const addedTime = Number(activity.timeStamp) * 1000

    if (isSameDay(now, addedTime)) {
      today.push(activity)
    } else if (isYesterday(addedTime)) {
      yesterday.push(activity)
    } else if (isSameWeek(addedTime, now)) {
      currentWeek.push(activity)
    } else if (isSameMonth(addedTime, now)) {
      last30Days.push(activity)
    } else if (isSameYear(addedTime, now)) {
      currentYear.push(activity)
    } else if (!isSameYear(addedTime, now)) {
      lastYear.push(activity)
    } else {
      const year = getYear(addedTime)

      if (!yearMap[year]) {
        yearMap[year] = [activity]
      } else {
        yearMap[year].push(activity)
      }
    }
  })

  const transactionGroups: Array<TransactionHistoryGroup> = [
    { title: `Today`, transactions: today.sort(sortActivities) },
    { title: `Yesterday`, transactions: yesterday.sort(sortActivities) },
    { title: `This week`, transactions: currentWeek.sort(sortActivities) },
    { title: `This month`, transactions: last30Days.sort(sortActivities) },
    { title: `This year`, transactions: currentYear.sort(sortActivities) },
    {
      title: `An eternity ago`,
      transactions: lastYear.sort(sortActivities),
    },
    // ...sortedYears,
  ]

  return transactionGroups.filter(
    (transactionInformation) => transactionInformation.transactions.length > 0
  )
}
