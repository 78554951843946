import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { BodyCaption, Caption, Subtitle } from "styles/TextStyles"
interface TierProps {
  editActive?: boolean
  onClick?: () => void
  number?: string | number | any
  percent?: string | number | any
  isOutputTokenInValid?: any
  isInputTokenInValid?: any
  disabled?: boolean
  percentDisabled?: boolean
  select?: any
  selectUndefined?: boolean
  poolInitialized?: boolean
  currentPrice?: any
}
const LiquidTier: FC<TierProps> = (props) => {
  const {
    editActive,
    percent,
    onClick,
    number,
    percentDisabled,
    isOutputTokenInValid,
    isInputTokenInValid,
    disabled,
    selectUndefined,
    poolInitialized,

    currentPrice,
  } = props

  const select = Boolean(isOutputTokenInValid) && Boolean(isInputTokenInValid)

  const selectPercent = select && !percentDisabled
  const selectShowTier = select && currentPrice !== "NaN"

  return (
    <Body>
      <Cover>
        <TierCover>
          <TierRow>
            {number !== 0 &&
            (selectPercent ||
              selectShowTier ||
              isNaN(Number(number)) ||
              number === undefined ||
              number === null) ? (
              <>
                <TierText select={select}>{number}% fee tier</TierText>

                {poolInitialized ||
                isNaN(Number(percent)) ||
                percent === undefined ||
                percent === null ? null : (
                  <TierPercent>
                    <PercentText select={selectUndefined}>
                      {percent === null ? (
                        <PercentBody>Not created</PercentBody>
                      ) : (
                        <>
                          <PercentBody>{percent}% select</PercentBody>
                        </>
                      )}
                    </PercentText>{" "}
                  </TierPercent>
                )}
              </>
            ) : (
              <TierRow>
                {" "}
                <TierTitle>Fee tier</TierTitle>
                <TierTextTLP>The % you earn in LP fees</TierTextTLP>
              </TierRow>
            )}
          </TierRow>
          <TierWrap onClick={onClick} disabled={disabled}>
            <>
              {editActive ? (
                <>
                  <TierButton>
                    <ButtonText>Close</ButtonText>
                  </TierButton>
                </>
              ) : (
                <>
                  {" "}
                  <TierButton>
                    <ButtonText>Edit</ButtonText>
                  </TierButton>
                </>
              )}
            </>
          </TierWrap>
        </TierCover>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
`
const Cover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const TierRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${TangleColors.white};
`
const TierText = styled(Caption)<TierProps>`
  margin: 4px 0;
  color: ${(props) =>
    props.select ? `${TangleColors.gray}` : `${TangleColors.white}`};
`
const TierTextTLP = styled(Caption)<TierProps>`
  margin: 4px 0;
  color: ${(props) =>
    props.select ? `${TangleColors.primaryDarker}` : `${TangleColors.white}`};
  opacity: ${(props) => (props.select ? `0.3` : `0.3`)};
`
const TierTitle = styled(Subtitle)`
  margin: 0;
`
const TierPercent = styled.div`
  height: 23px;
  max-width: 96px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${TangleColors.tangle10};
  border-radius: 12px;
  padding: 6px;
  margin: 2px 0 0 0;
  border: 1px solid ${TangleColors.tangle10};
`

const PercentText = styled(BodyCaption)<TierProps>`
  color: ${(props) =>
    props.select ? `${TangleColors.gray}` : `${TangleColors.white}`};
  margin: 16px 0;
  display: ${(props) => (props.select ? `none` : `flex`)};
`

const PercentBody = styled(BodyCaption)<TierProps>`
  color: ${TangleColors.white};
  /* margin: 0 4px 0 0; */
`
const TierWrap = styled.button`
  width: 100%;
  max-width: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  :disabled {
    cursor: not-allowed;
    :hover {
      cursor: not-allowed;
    }
  }
`
const TierButton = styled.button`
  height: 28px;
  max-width: 72px;
  border: none;
  width: 100%;
  cursor: pointer;
  background: ${TangleColors.cta};
  border-radius: 6px;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.dockBG};
    color: ${TangleColors.white};
    border: 0.5px solid ${TangleColors.white};
  }
  :disabled {
    cursor: not-allowed;
    :hover {
      cursor: not-allowed;
    }
  }
`

const TierCover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 104px;
  align-items: center;
  max-width: 816px;
  width: 100%;
  height: 96px;
  border-radius: 24px;
  margin: 16px auto;
  padding: 0 24px;
  border: 2px solid ${TangleColors.tangleBlack};
  color: ${TangleColors.white};
`

const ButtonText = styled(Caption)`
  color: ${TangleColors.white};
  /* cursor: pointer; */
`

export default LiquidTier
