import React, { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Subtitle } from "styles/TextStyles"
import { CommitmentProps } from "../types"
import { useWSCContext } from "context/MilkomedaContext"

import EmptyCommitment from "./EmptyCommitment"

import CommitmentTables from "./CommitmentTables"
interface CommitmentTabProps {
  allCommitments?: CommitmentProps[]
  currencySymbol?: string
  active?: boolean
}
const CommitmentsTab: FC<CommitmentTabProps> = (props) => {
  const { allCommitments, currencySymbol, active } = props
  const [switchState, setSwitchState] = useState<boolean>(false)
  const [uniqueParticipants, setUniqueParticipants] =
    useState<number>(undefined)
  const [filteredCommitment, setFilteredCommitment] = useState<
    CommitmentProps[] | undefined
  >(undefined)
  const { account } = useWSCContext()
  const filterSwitch = () => {
    setSwitchState(!switchState)
  }
  useEffect(() => {
    const data = switchState
      ? allCommitments?.filter((data) => {
          const dataInfo =
            String(data.account).trim().toLowerCase() ===
            String(account).trim().toLowerCase()
          return dataInfo
        })
      : allCommitments
    const sortedData = data?.sort((a, b) => Number(b.block) - Number(a.block))
    setFilteredCommitment(sortedData)
  }, [switchState, account, allCommitments])

  useEffect(() => {
    if (!allCommitments) return
    const uniqueAccounts = new Set(allCommitments.map((c) => c.account)).size
    setUniqueParticipants(uniqueAccounts)
  }, [allCommitments])

  return (
    <Container active={active}>
      <InfoRow>
        <Participants>
          <ItemKey>Total Commitments</ItemKey>
          <ItemValue>{allCommitments?.length}</ItemValue>
        </Participants>
        <Participants>
          <ItemKey>Total Participants</ItemKey>
          <ItemValue>{uniqueParticipants}</ItemValue>
        </Participants>
        <SwitchWrapper>
          {" "}
          <SwitchLabel>
            <ItemValueShow>Show my bids only</ItemValueShow>
            <SwitchInput
              type={"checkbox"}
              checked={switchState}
              onChange={filterSwitch}
            />
            <Switch />
          </SwitchLabel>
        </SwitchWrapper>
      </InfoRow>

      {filteredCommitment?.length > 0 ? (
        <>
          {" "}
          <CommitmentTables
            allCommitment={filteredCommitment}
            paymentSymbol={currencySymbol}
          />
        </>
      ) : (
        <>
          <EmptyCommitment text="There have been no commitments — yet!" />
        </>
      )}
    </Container>
  )
}

const Container = styled.div<CommitmentTabProps>`
  margin-top: 32px;
  display: ${(props) => (props.active ? `none` : `flex`)};
  flex-direction: column;
`

const InfoRow = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 0 0 8px 0;
  grid-template-columns: 1.5fr 3.5fr 4.2fr;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1.2fr 2.5fr 4fr;
    & :nth-child(3) {
      display: none;
    }
  }
`

const SwitchLabel = styled.label`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: #222;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: black;
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: ${TangleColors.cta};

    &:before {
      transform: translate(30px, -50%);
    }
  }
`

const Participants = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 6px;
`

const ItemKey = styled(Caption)`
  color: ${TangleColors.grayDark};
`

const ItemValue = styled(Subtitle)<CommitmentProps>`
  display: flex;
  align-items: center;
  margin: 4px 0 0 0;
  color: ${TangleColors.white};
`
const ItemValueShow = styled(Caption)<CommitmentProps>`
  display: flex;
  align-items: center;
  margin: 0 4px 0 0;
  color: ${TangleColors.white};
`

const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: flex-end;
`
export default CommitmentsTab
