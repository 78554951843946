import styled from "styled-components"
import { TangleColors } from "./ColorStyles"

export const FormInput = styled.input`
  height: 32px;
  max-width: 320px;
  border-radius: 24px;
  background: ${TangleColors.primaryDarker};
  font-family: "DM Sans Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  padding: 10px;
  border: none;
  outline: none;
  color: ${TangleColors.white};

  ::placeholder {
    color: ${TangleColors.gray};
  }
`
export const StakingMainInput = styled.input`
  width: 100%;

  color: ${TangleColors.white};
  font-family: "DM Sans Regular";
  font-size: 18px;
  text-align: right;
  border-radius: 16px;
  margin: 0;
  outline: none;
  font-weight: 600;
  margin: 8px 0 0 0;
  transition: 0.4s ease-in;
`
export const DurationMainInput = styled.input`
  width: 100%;
  border: none;
  background: ${TangleColors.tangleBlack};
  border: 0.5px solid ${TangleColors.grayLighter};
  color: ${TangleColors.white};
  font-family: "DM Sans Regular";
  font-size: 18px;
  text-align: right;
  padding: 12px 24px;
  border-radius: 16px;
  margin: 0;
  outline: none;
  font-weight: 600;
  margin: 8px 0 0 0;
  transition: 0.4s ease-in;
  :focus {
    transition: 0.4s ease-in;
    border: 0.5px solid ${TangleColors.lighthover};
  }
  :hover {
    transition: 0.4s ease-in;
    /* opacity: 0.8; */

    border: 0.5px solid ${TangleColors.lighthover};
  }
`
export const SelectInput = styled.select`
  height: 46px;
  max-width: 185px;
  width: 100%;
  border-radius: 12px;
  background-color: ${TangleColors.inputBG};
  font-family: "DM Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 10px;
  border: 1px solid ${TangleColors.white};
  outline: none;
  color: ${TangleColors.white};
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.08691 9.5957L12.0869 15.5957L18.0869 9.5957" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');

  background-position: right 8px top 50%;

  background-repeat: no-repeat;
  background-size: 18px;
  transition: 0.4s ease-in;
  cursor: pointer;
  ::placeholder {
    color: ${TangleColors.gray};
  }

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }

  option {
    width: 100%;
    height: 48px;
    padding: 8px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
`

export const FormTextInput = styled.input`
  height: 46px;
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  background: ${TangleColors.inputBG};
  cursor: pointer;
  font-family: "DM Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 10px 10px 10px 14px;
  border: 1px solid ${TangleColors.grayLighter};
  outline: none;
  color: ${TangleColors.white};

  ::placeholder {
    font-family: "DM Sans Regular";
    font-size: 14px;
    color: ${TangleColors.white};
    opacity: 0.7;
  }
  :hover {
    transition: 0.4s ease-in;

    border: 1px solid ${TangleColors.lighthover};
  }
  @media only screen and (max-width: 750px) {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
`
export const FormikTextInput = styled.input`
  height: 56px;
  max-width: 300px;
  width: 100%;
  border-radius: 16px;
  background: ${TangleColors.inputBG};
  font-family: "DM Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 10px;
  border: 0.5px solid ${TangleColors.white};
  outline: none;
  color: ${TangleColors.white};
  ::placeholder {
    color: ${TangleColors.gray};
  }
`
export const TextLabel = styled.label`
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

export const StakingInput = styled.input`
  height: 46px;
  border-radius: 12px;
  max-width: 400px;
  width: 100%;
  background: ${TangleColors.inputBG};
  cursor: pointer;
  font-family: "DM Sans Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 10px 10px 10px 14px;
  border: 1px solid ${TangleColors.grayLighter};
  outline: none;
  color: ${TangleColors.white};

  ::placeholder {
    font-family: "DM Sans Regular";
    font-size: 14px;
    color: ${TangleColors.white};
    opacity: 0.7;
  }
  :hover {
    transition: 0.4s ease-in;

    border: 1px solid ${TangleColors.lighthover};
  }
  @media only screen and (max-width: 750px) {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
`

export const PurpleListFormInput = styled.input`
  width: 100%;
  border: none;
  background: ${TangleColors.black};
  border: 0.3px solid ${TangleColors.grayLighter};
  color: ${TangleColors.white};
  font-family: "DM Sans Regular";
  font-size: 15px;
  text-align: left;
  padding: 14px 32px;
  border-radius: 12px;
  margin: 0;
  outline: none;
  font-weight: 600;
  margin: 8px 0 0 0;
  transition: 0.4s ease-in;
  :focus {
    transition: 0.4s ease-in;
    border: 0.5px solid ${TangleColors.lighthover};
  }
  :hover {
    transition: 0.4s ease-in;
    /* opacity: 0.8; */

    border: 0.5px solid ${TangleColors.lighthover};
  }
`
