import React, { FC, useEffect, useRef, useState } from "react"
import { TangleswapTokenList } from "@tangleswap/sdk"
import styled from "styled-components"
import { CardanoWalletButton } from "styles/ButtonStyles"

import { useWSCContext } from "context/MilkomedaContext"
import { Caption, CaptionSmall, Header7 } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import EmptyOverviewState from "../components/EmptyOverviewState"
import WalletInformation from "../components/WalletInformation"
import { useWSCProvider } from "milkomeda-wsc-ui-test-beta"

import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"
import {
  EVM_ESTIMATED_FEE,
  REQUIRED_FEE_ADA,
  TX_STATUS_CHECK_INTERVAL,
  TxStatus,
} from "constants/milkomeda/transaction"
import BigNumber from "bignumber.js"
import LoaderSpinner from "components/LoaderSpinner"
import CardanoTxFeedback from "../components/CardanoTxFeedback"

import { DestinationDataProps } from "../utils/types"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"

import WalletLayout from "../components/WalletLayout"
import { walletOverviewUnwrapMessages } from "../utils/helper"
import { convertTokensToWei } from "components/utils/convertWeiToTokens"

import useInterval from "components/utils/useInterval"
import { useTangleship } from "utils/useTangleship"
import { WRAPPED_ADDRESS } from "@tangleswap/sdk"
import {
  getBridgeExplorerUrl,
  getCardanoscanUrl,
  getEvmExplorerUrl,
} from "utils/milkomeda/transaction"
import TransferModal from "../components/TransferModal"
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import { useAppDispatch } from "store/hooks"
import { useEstimateGas } from "hooks/milkomeda/useEstimatedGas"

interface OverviewProps {
  active?: boolean
  token?: string
  amount?: string | number
  contract?: string
  grid?: boolean
}

const OverviewTransactionInfo: FC<OverviewProps> = (props) => {
  const { active } = props
  const {
    destinationTokens,
    destinationBalanceADA,
    account,
    chainId,
    l1ChainId,
    l1Account,
  } = useWSCContext()
  const [txHash, setTxHash] = useState<string | undefined>()
  const [walletBalance, setWalletBalance] = useState<any>(undefined)

  const [activeLayout, setActiveLayout] = useState<number | null>(1)
  const [transactionIndex, setTransactionIndex] = useState<number | null>(null)
  const [transactionErrorIndex, setTransactionErrorIndex] = useState<
    number | null
  >(null)
  const [errorDisplay, setErrorDisplay] = useState<number | null>(null)

  const { wscProvider } = useWSCProvider()
  const [transactionList, setTransactionList] = useState<any>(undefined)

  const { txStatus, txStatusError, setTxStatusError, setTxStatus } =
    useTransactionStatus()
  const { tangleship } = useTangleship()

  useInterval(
    async () => {
      if (!wscProvider || txHash == null) return
      const response = await wscProvider.getTxStatus(txHash)

      setTxStatus(response)
    },
    txHash != null && txStatus !== TxStatus.Confirmed
      ? TX_STATUS_CHECK_INTERVAL
      : null
  )

  const dispatch = useAppDispatch()
  const executeStep = (tx: any, token: DestinationDataProps) => {
    const stepperParams = {
      completed: false,
      stepTitle: "Unwrap",
      subStep: 0,
      actionType: "Unwrap",
      actionSubType: "Unwrap",
      txHash: tx,
      data: {
        tokenOut: token,
      },
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
    }
    dispatch(updateUserWSCProgress(stepperParams))
  }

  const { feeCardano } = useEstimateGas()
  const destinationBalance = destinationBalanceADA?.data

  const unwrapToken = async (index: number, token: DestinationDataProps) => {
    if (!token || !wscProvider) return
    setErrorDisplay(null)
    setTransactionIndex(index)
    setTxStatus(TxStatus.Init)
    const isNativeToken = token.contractAddress === "ADA"
    const value = isNativeToken
      ? convertTokensToWei({
          value: new BigNumber(destinationBalance ?? 0).dp(6),
          token: { decimals: 6 },
        }).toFixed()
      : new BigNumber(token?.balance ?? 0).toString()
    const unwrapOptions = {
      destination: undefined,
      assetId: isNativeToken ? undefined : token.contractAddress,
      amount: isNativeToken
        ? new BigNumber(value ?? 0).minus(feeCardano)
        : new BigNumber(value ?? 0),
    }

    try {
      const txHash = await wscProvider.unwrap(
        unwrapOptions.destination,
        unwrapOptions.assetId!,
        unwrapOptions.amount
      )
      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)
      setErrorDisplay(null)
      setTransactionIndex(null)
    } catch (err) {
      console.error(err)
      setErrorDisplay(err)
      setTransactionErrorIndex(index)
      setTransactionIndex(null)
      setTxStatus(TxStatus.Error)
      if (err instanceof Error) {
        setTxStatusError(err.message)
      }
    }
  }
  useEffect(() => {
    const infoData = destinationTokens?.data?.filter(
      (data: DestinationDataProps) => data.type === "ERC-20"
    )

    const transactiondata = [...(infoData || [])]
    const adaToken = {
      decimals: 6,
      name: "Cardano",
      symbol: "ADA",
      contractAddress: "ADA",
      balance: convertTokensToWei({
        value: new BigNumber(walletBalance).dp(6),
        token: { decimals: 6 },
      }).toFixed(),
    }
    const tokens: any[] = [adaToken, ...transactiondata]

    setTransactionList(tokens) // Set processed tokens instead of original tokens
  }, [
    destinationTokens?.data,
    l1ChainId,
    walletBalance,
    TangleswapTokenList?.tokens,
  ])

  const changeLayout = (value: number) => {
    setActiveLayout(value)
  }

  useEffect(() => {
    if (!destinationBalanceADA) return

    const adaBalance: number = Number(destinationBalanceADA?.data)
    setWalletBalance(adaBalance)
  }, [destinationBalanceADA])

  const disableCardano = !!(Number(walletBalance) < REQUIRED_FEE_ADA)
  const disableEVMMove = !!(Number(walletBalance) < EVM_ESTIMATED_FEE)
  const calculateBalance = (balance: number, decimals: number) => {
    const tokenValue = balance / 10 ** decimals
    const tokenBalance = formatNumber(tokenValue)
    return tokenBalance
  }

  const [selectedToken, setSelectedToken] = useState(null)
  const [showTransferModal, setShowTransferModal] = useState(false)

  const [wadaUnwrapFn, setWadaUnwrapFn] = useState<any>()
  const [unwrapWadaAmount, setUnwrapWadaAmount] = useState<any>()
  const writeCalledRef = useRef(false)

  useEffect(() => {
    if (
      !account ||
      !tangleship ||
      !chainId ||
      !WRAPPED_ADDRESS[chainId] ||
      !unwrapWadaAmount
    )
      return
    const getWadaUnwrapFn = async () => {
      const wadaUnwrap = await tangleship?.directSwap(
        WRAPPED_ADDRESS[chainId],
        "ADA",
        123, // param not needed, feetier
        unwrapWadaAmount,
        BigInt(0),
        account,
        123 // param not needed, deadline
      )
      setWadaUnwrapFn(wadaUnwrap)
      writeCalledRef.current = false
    }
    getWadaUnwrapFn()
  }, [WRAPPED_ADDRESS[chainId], account, tangleship, unwrapWadaAmount])

  const prepareContractWriteQuery = usePrepareContractWrite(wadaUnwrapFn)

  const contractWriteQuery = useContractWrite(prepareContractWriteQuery.config)

  const waitForTransactionQuery = useWaitForTransaction({
    hash: contractWriteQuery.data?.hash,
    enabled: !!contractWriteQuery.data?.hash,
  })

  const unwrapWada = (index: number, amount: string) => {
    setTransactionIndex(index)
    setUnwrapWadaAmount(amount)
  }

  const wadaSuccess = waitForTransactionQuery.isSuccess
  const wadaError =
    waitForTransactionQuery.isError ||
    contractWriteQuery.isError ||
    prepareContractWriteQuery.isError

  useEffect(() => {
    if (wadaSuccess) {
      setErrorDisplay(null)
    } else if (wadaError) {
      console.error("Error unwrapping WADA", wadaError)
    }
  }, [wadaSuccess, wadaError])

  useEffect(() => {
    if (contractWriteQuery?.write && !writeCalledRef.current) {
      writeCalledRef.current = true
      contractWriteQuery?.write?.()
    }
  }, [contractWriteQuery?.write])

  return (
    <OverviewStepperCover active={active}>
      {showTransferModal && (
        <TransferModal
          data={selectedToken}
          closeModal={() => setShowTransferModal(false)}
        />
      )}
      <StepperTopRow>
        {" "}
        <StepperRowTitle>Assets in your Cardano L2 Wallet</StepperRowTitle>
        <WalletInformation account={account} />
        {disableCardano && (
          <CardanoTxFeedback
            error={true}
            txStatus="You need L2 ADA to pay gas before you can unwrap your tokens."
          />
        )}
        {transactionIndex && (
          <CardanoTxFeedback txStatus="Unwrapping may take a few minutes (~2-3 min)" />
        )}
      </StepperTopRow>

      <OverviewBalanceBody>
        <WalletLayout
          tokensLength={transactionList?.length}
          number={activeLayout}
          onClick={changeLayout}
        />
        {transactionList?.length > 0 ? (
          <>
            {transactionList?.map(
              (data: DestinationDataProps, index: number) => {
                const tokenBalance = calculateBalance(
                  Number(data.balance),
                  Number(data.decimals)
                )
                const wrappedADA =
                  data?.contractAddress?.toLowerCase() ===
                  WRAPPED_ADDRESS[chainId]?.toLowerCase()
                const isWrap = data.type === "Wrap"
                const isUnwrap = data.type === "Unwrap"
                const explorerUrl =
                  isWrap || isUnwrap
                    ? `${getBridgeExplorerUrl(chainId)}/search/tx?query=`
                    : `${getEvmExplorerUrl(chainId)}/tx/`
                const href = `${explorerUrl}${data?.contractAddress}`

                return (
                  <React.Fragment key={index}>
                    <OverviewButtonCover key={index}>
                      <OverviewCardanoTextRow grid={activeLayout === 1}>
                        <BalanceText grid={activeLayout === 1}>
                          Balance:{" "}
                          <BalanceNumber>
                            {" "}
                            {tokenBalance}{" "}
                            <a
                              href={href}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <BalanceTextRow>
                                {" "}
                                <CardanoTokenSymbol>
                                  {data.symbol}
                                </CardanoTokenSymbol>{" "}
                              </BalanceTextRow>
                            </a>
                          </BalanceNumber>
                        </BalanceText>
                        <OverviewMainButton
                          grid={activeLayout === 1}
                          disabled={
                            disableCardano ||
                            !TangleswapTokenList?.tokens.some(
                              (entry) =>
                                entry.address?.toLowerCase() ===
                                data?.contractAddress?.toLowerCase()
                            )
                          }
                          onClick={() => {
                            wrappedADA
                              ? unwrapWada(index, data.balance)
                              : unwrapToken(index, data)
                          }}
                        >
                          {disableCardano ? (
                            <>
                              {">"} {REQUIRED_FEE_ADA.toFixed(1)} ADA required{" "}
                            </>
                          ) : (
                            <>
                              {transactionIndex === index ? (
                                <>
                                  {walletOverviewUnwrapMessages[txStatus]}
                                  <LoaderSpinner />
                                </>
                              ) : wrappedADA ? (
                                <>Convert to Native ADA</>
                              ) : (
                                <>Move to My L1 Wallet</>
                              )}
                            </>
                          )}
                        </OverviewMainButton>
                        <OverviewSecondaryButton
                          grid={activeLayout === 1}
                          disabled={
                            disableEVMMove || data.contractAddress === "ADA"
                          }
                          onClick={() => {
                            setSelectedToken(data)
                            setShowTransferModal(true)
                          }}
                        >
                          {disableEVMMove ? (
                            <>
                              {">"} {EVM_ESTIMATED_FEE.toFixed(1)} ADA required{" "}
                            </>
                          ) : (
                            <>
                              {transactionIndex === index ? (
                                <>
                                  <LoaderSpinner />
                                </>
                              ) : (
                                <>Send to Another L2 Wallet</>
                              )}
                            </>
                          )}
                        </OverviewSecondaryButton>
                      </OverviewCardanoTextRow>

                      {errorDisplay !== null &&
                        transactionErrorIndex === index &&
                        txStatus === "Error" && (
                          <CardanoTxFeedback
                            error={true}
                            txStatus={txStatusError}
                          />
                        )}
                      {wadaError && transactionIndex === index && (
                        <CardanoTxFeedback
                          error={true}
                          txStatus={
                            "Error while unwrapping WADA. Please refresh and try again."
                          }
                        />
                      )}
                      {wadaSuccess && transactionIndex === index && (
                        <CardanoTxFeedback
                          txStatus={"Unwrapped WADA successfully"}
                          error={false}
                        />
                      )}
                    </OverviewButtonCover>
                  </React.Fragment>
                )
              }
            )}
          </>
        ) : (
          <>
            {" "}
            <EmptyOverviewState title="No tokens available" />
          </>
        )}
      </OverviewBalanceBody>
    </OverviewStepperCover>
  )
}
const OverviewStepperCover = styled.div<OverviewProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.4s ease-in;
  padding: 8px;
  margin: 12px 0 0 0;
`

const StepperTopRow = styled.div`
  display: flex;
  width: 100%;
  margin: 12px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StepperRowTitle = styled(Header7)`
  color: ${TangleColors.white};
  margin: 4px 0;
`

const OverviewBalanceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
`

const OverviewButtonCover = styled.div`
  width: 100%;
  margin: 12px 0;
`
const OverviewMainButton = styled(CardanoWalletButton)<OverviewProps>`
  margin: 12px 0;
  width: 100%;
  transition: 0.4s ease-in;
  max-width: ${(props) => (props.grid ? `220px` : `900px`)};
`

const OverviewSecondaryButton = styled(CardanoWalletButton)<OverviewProps>`
  margin: 12px 0;
  width: 100%;
  transition: 0.4s ease-in;
  max-width: ${(props) => (props.grid ? `220px` : `900px`)};
  color: ${TangleColors.black};
  background: ${TangleColors.lighthover};
  :disabled {
    color: ${TangleColors.white};
  }
`

const OverviewCardanoTextRow = styled.div<OverviewProps>`
  display: flex;
  flex-direction: ${(props) => (props.grid ? `row` : `column`)};
  align-items: ${(props) => (props.grid ? `center ` : `flex-start`)};
  transition: 0.4s ease-in;
  position: relative;
  z-index: 800;
  gap: 12px;
`
const BalanceText = styled(CaptionSmall)<OverviewProps>`
  margin: 0;
  color: ${TangleColors.darkerText};
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start;
  align-items: center; */
  transition: 0.4s ease-in;
  max-width: ${(props) => (props.grid ? `550px` : `900px`)};
  margin-right: auto;
`
const BalanceNumber = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 200px;
  width: 100%;
  /* justify-content: center;
  align-items: center; */
`

const CardanoTokenSymbol = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 8px;
  cursor: pointer;
`

const BalanceTextRow = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export default OverviewTransactionInfo
