import { max, scaleLinear, ZoomTransform } from "d3"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { Bound, ChartEntry, LiquidityChartRangeInputProps } from "./types"
import ChartZoom, { ZoomOverlay } from "./ChartZoom"
import AxisBottom from "./AxisBottom"
import ChartBrush from "./ChartBrush"
import ChartArea from "./ChartArea"
import ChartLine from "./ChartLine"

export const xAccessor = (d: ChartEntry) => d?.price0
export const yAccessor = (d: ChartEntry) => d?.activeLiquidity

const Chart: FC<LiquidityChartRangeInputProps> = (props) => {
  const {
    id = "liquidityChartRangeInput",
    data: { series, current },
    ticksAtLimit,
    styles,
    dimensions: { width, height },
    margins,
    interactive = true,
    brushDomain,
    brushLabels,
    onBrushDomainChange,
    zoomLevels,
  } = props

  const zoomRef = useRef<SVGRectElement | null>(null)

  const [zoom, setZoom] = useState<ZoomTransform | null>(null)

  const [innerHeight, innerWidth] = useMemo(
    () => [
      height - margins?.top - margins?.bottom,
      width - margins?.left - margins?.right,
    ],
    [width, height, margins]
  )

  // max(data, (d) => (typeof d === "number" ? d : undefined))
  const { xScale, yScale } = useMemo(() => {
    const scales = {
      xScale: scaleLinear()
        ?.domain([
          isNaN(current * zoomLevels?.initialMin) ||
          Number.isNaN(current * zoomLevels?.initialMin)
            ? 0
            : current * zoomLevels?.initialMin,
          isNaN(current * zoomLevels?.initialMax) ||
          Number.isNaN(current * zoomLevels?.initialMax)
            ? 0
            : current * zoomLevels?.initialMax,
        ] as any[])
        ?.range([
          0,
          isNaN(innerWidth) || Number.isNaN(innerWidth) ? 0 : innerWidth,
        ]),
      yScale: scaleLinear()
        ?.domain([
          0,
          series === undefined ? 0 : max(series, yAccessor),
        ] as any[])
        ?.range([
          isNaN(innerHeight) || Number.isNaN(innerHeight) ? 0 : innerHeight,
          0,
        ]),
    }

    if (zoom) {
      const newXscale = zoom?.rescaleX(scales?.xScale)
      scales?.xScale?.domain(newXscale?.domain())
    }

    return scales
  }, [
    current,
    zoomLevels?.initialMin,
    zoomLevels?.initialMax,
    innerWidth,
    series,
    innerHeight,
    zoom,
  ])

  useEffect(() => {
    // reset zoom as necessary
    setZoom(null)
  }, [zoomLevels])

  useEffect(() => {
    if (!brushDomain) {
      onBrushDomainChange(xScale?.domain() as [number, number], undefined)
    }
  }, [brushDomain, onBrushDomainChange, xScale])

  return (
    <>
      <ChartZoom
        svg={zoomRef.current}
        xScale={xScale}
        setZoom={setZoom}
        width={innerWidth}
        height={
          // allow zooming inside the x-axis
          height
        }
        resetBrush={() => {
          onBrushDomainChange(
            [
              current * zoomLevels.initialMin,
              current * zoomLevels.initialMax,
            ] as [number, number],
            "reset"
          )
        }}
        showResetButton={Boolean(
          ticksAtLimit[Bound.LOWER] || ticksAtLimit[Bound.UPPER]
        )}
        zoomLevels={zoomLevels}
      />
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${width} ${height}`}
        style={{ overflow: "visible" }}
      >
        <defs>
          <clipPath id={`${id}-chart-clip`}>
            <rect x="0" y="0" width={innerWidth} height={height} />
          </clipPath>

          {brushDomain && (
            // mask to highlight selected area
            <mask id={`${id}-chart-area-mask`}>
              <rect
                fill="white"
                x={xScale(brushDomain[0])}
                y="0"
                width={xScale(brushDomain[1]) - xScale(brushDomain[0])}
                height={innerHeight}
              />
            </mask>
          )}
        </defs>

        <g transform={`translate(${margins.left},${margins.top})`}>
          <g clipPath={`url(#${id}-chart-clip)`}>
            {series !== undefined ? (
              <>
                {" "}
                <ChartArea
                  series={series}
                  xScale={xScale}
                  yScale={yScale}
                  xValue={xAccessor}
                  yValue={yAccessor}
                />
              </>
            ) : null}

            {brushDomain && (
              // duplicate area chart with mask for selected area
              <g mask={`url(#${id}-chart-area-mask)`}>
                {series !== undefined ? (
                  <>
                    {" "}
                    <ChartArea
                      series={series}
                      xScale={xScale}
                      yScale={yScale}
                      xValue={xAccessor}
                      yValue={yAccessor}
                      fill={styles.area.selection}
                    />
                  </>
                ) : null}
              </g>
            )}

            <ChartLine
              value={current}
              xScale={xScale}
              innerHeight={innerHeight}
            />

            <AxisBottom xScale={xScale} innerHeight={innerHeight} />
          </g>

          <ZoomOverlay width={innerWidth} height={height} ref={zoomRef} />

          <ChartBrush
            id={id}
            xScale={xScale}
            interactive={interactive}
            brushLabelValue={brushLabels}
            brushExtent={brushDomain ?? (xScale.domain() as [number, number])}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            setBrushExtent={onBrushDomainChange}
            westHandleColor={styles.brush.handle.west}
            eastHandleColor={styles.brush.handle.east}
          />
        </g>
      </svg>
    </>
  )
}

export default Chart
