import MobileAccountDropdown from "navbar/mobile/MobileAccountDropdown"
import MobileNetworkDropdown from "navbar/mobile/MobileNetworkDropdown"
import React, { useState, useRef, useEffect, FC } from "react"
import { closeMobileNav } from "store/actions/MobileNavAction"
import { GrClose } from "react-icons/gr"
import { useAppDispatch, useAppSelector } from "store/hooks"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
interface LayoutProps {
  isOpen?: boolean
  isAddressOpen?: boolean
  networkDropDownOpen?: boolean
}

const LayoutDrawer = () => {
  const [dragStart, setDragStart] = useState(null)
  const sheetRef = useRef(null)
  const dispatch = useAppDispatch()
  const mobileNavOpen = useAppSelector(
    (state) => state.MobileNavReducer.navOpen
  )
  const mobileActiveMenu = useAppSelector(
    (state) => state.MobileNavReducer.activeMenu
  )
  const networkOpen = useAppSelector(
    (state) => state.MobileNavReducer.networkOpen
  )
  const addressOpen = useAppSelector(
    (state) => state.MobileNavReducer.addressOpen
  )
  const handleMouseDown = (e) => {
    setDragStart(e.clientY)
  }

  const handleMouseUp = (e) => {
    const dragEnd = e.clientY

    if (dragStart - dragEnd > 50) {
      // setIsOpen(false)
    }

    setDragStart(null)
  }

  useEffect(() => {
    const element = sheetRef.current
    if (element) {
      element.addEventListener("mousedown", handleMouseDown)
      window.addEventListener("mouseup", handleMouseUp)
    }
    return () => {
      if (element) {
        element.removeEventListener("mousedown", handleMouseDown)
        window.removeEventListener("mouseup", handleMouseUp)
      }
    }
  }, [dragStart])
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (sheetRef.current && !sheetRef.current.contains(e.target)) {
        dispatch(closeMobileNav())
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  const goBackToMainMenu = () => {}
  const closeMobileNavMenu = () => {
    dispatch(closeMobileNav())
  }
  const openTransactionMenu = () => {}
  return (
    <BottomSheetCover isOpen={Boolean(mobileNavOpen)}>
      <BottomSheetContainer ref={sheetRef} isOpen={Boolean(mobileNavOpen)}>
        <SheetWrapper>
          <Handle />
          <CloseIcon onClick={closeMobileNavMenu} />
          <MainSheetBody>
            <MobileNetworkDropdown networkDropDownOpen={Boolean(networkOpen)} />

            <MobileAccountDropdown
              addressOpen={Boolean(addressOpen)}
              activeMenu={mobileActiveMenu}
              goBackToMainMenu={goBackToMainMenu}
            />
          </MainSheetBody>
        </SheetWrapper>
      </BottomSheetContainer>
    </BottomSheetCover>
  )
}
const BottomSheetCover = styled.div<LayoutProps>`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 260;
  background: rgba(0, 0, 0, 0.5);
  padding: 16px;
`
const BottomSheetContainer = styled.div<LayoutProps>`
  position: fixed;
  z-index: 999;
  bottom: ${(props) => (props.isOpen ? "0px" : "-450px")};
  right: 0;
  height: 450px;
  background: ${TangleColors.white};
  transition: bottom 0.4s;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const SheetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  min-height: 400px;
`

const MainSheetBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Handle = styled.div`
  width: 56px;
  height: 4px;
  background: ${TangleColors.grayDark};
  border-radius: 8px;
  margin: 12px auto;
  top: 16px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`
const CloseIcon = styled(GrClose)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
  position: absolute;
  top: 24px;
  right: 30px;
  cursor: pointer;
  path {
    stroke: ${TangleColors.black};
    fill: ${TangleColors.black};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
export default LayoutDrawer
