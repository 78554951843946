// import NoMetaMask from "components/modals/NoMetaMask"
import { createAction } from "@reduxjs/toolkit"

export const openMobileNav = createAction<void>("mobile/openMobileNav")
export const closeMobileNav = createAction<void>("mobile/closeMobileNav")
export const openNetworkMenu = createAction<void>("mobile/openNetwork")
export const closeNetworkMenu = createAction<void>("mobile/closeNetwork")
export const openAddressMenu = createAction<void>("mobile/openAddress")
export const closeAddressMenu = createAction<void>("mobile/closeAddress")
export const openTransactionMenu = createAction<void>("mobile/openTransaction")
export const closeTransactionMenu = createAction<void>(
  "mobile/closeTransaction"
)

export const closeTransactionBackMenu = createAction<void>(
  "mobile/closeTransactionBackMenu"
)
