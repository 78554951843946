import {
  ScaleLinear,
  select,
  zoom,
  ZoomBehavior,
  zoomIdentity,
  ZoomTransform,
} from "d3"
import React, { useEffect, useMemo, useRef } from "react"

import styled from "styled-components"
import { RiZoomInLine, RiRefreshLine, RiZoomOutLine } from "react-icons/ri"

import { ZoomLevels } from "./types"
import { ButtonGray } from "./ChartButton"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
interface ZoomProps {
  disabled?: boolean
  count?: any
  resetTrue?: boolean
}
function ChartZoom({
  svg,
  xScale,
  setZoom,
  width,
  height,
  resetBrush,
  showResetButton,
  zoomLevels,
}: {
  svg: SVGElement | null
  xScale: ScaleLinear<number, number>
  setZoom: (transform: ZoomTransform) => void
  width: number
  height: number
  resetBrush: () => void
  showResetButton: boolean
  zoomLevels: ZoomLevels
}) {
  const zoomBehavior = useRef<ZoomBehavior<Element, unknown>>()

  const [zoomIn, zoomOut, zoomInitial, zoomReset] = useMemo(
    () => [
      () =>
        svg &&
        zoomBehavior.current &&
        select(svg as Element)
          .transition()
          .call(zoomBehavior.current.scaleBy, 2),
      () =>
        svg &&
        zoomBehavior.current &&
        select(svg as Element)
          .transition()
          .call(zoomBehavior.current.scaleBy, 0.5),
      () =>
        svg &&
        zoomBehavior.current &&
        select(svg as Element)
          .transition()
          .call(zoomBehavior.current.scaleTo, 0.5),
      () =>
        svg &&
        zoomBehavior.current &&
        select(svg as Element)
          .call(
            zoomBehavior.current.transform,
            zoomIdentity.translate(0, 0).scale(1)
          )
          .transition()
          .call(zoomBehavior.current.scaleTo, 0.5),
    ],
    [svg]
  )

  useEffect(() => {
    if (!svg) return

    zoomBehavior.current = zoom()
      .scaleExtent([zoomLevels.min, zoomLevels.max])
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", ({ transform }: { transform: ZoomTransform }) =>
        setZoom(transform)
      )

    select(svg as Element).call(zoomBehavior.current)
  }, [
    height,
    width,
    setZoom,
    svg,
    xScale,
    zoomBehavior,
    zoomLevels,
    zoomLevels.max,
    zoomLevels.min,
  ])

  useEffect(() => {
    // reset zoom to initial on zoomLevel change
    zoomInitial()
  }, [zoomInitial, zoomLevels])

  return (
    <WrapperTop>
      <TopText>Set Price Range</TopText>
      <ZoomControlWrapper resetTrue={showResetButton}>
        {showResetButton && (
          <ZoomIconRefreshButton
            onClick={() => {
              resetBrush()
              zoomReset()
            }}
            disabled={false}
          >
            <ChartZoomRefresh />
          </ZoomIconRefreshButton>
        )}

        <ZoomIconButton onClick={zoomIn} disabled={false}>
          <ChartZoomIn />
        </ZoomIconButton>
        <ZoomIconButton onClick={zoomOut} disabled={false}>
          <ChartZoomOut />
        </ZoomIconButton>
      </ZoomControlWrapper>
    </WrapperTop>
  )
}

const WrapperTop = styled.div<ZoomProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px 0;
  padding: 0 0 0 1px;
`
const TopText = styled(Subtitle)`
  color: ${TangleColors.grayDark};
`

const ZoomIconButton = styled.button`
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  opacity: 0.8;
  width: 26px;
  height: 26px;
  border-radius: 24px;
  padding: 4px;
  margin: 0 0 0 8px;
  transition: 0.4s ease-in;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${TangleColors.tangleBlack};
  color: ${TangleColors.white};
  &:hover {
    background: ${TangleColors.grayLight};
    color: ${TangleColors.black};
    opacity: 1;
  }
`
const ZoomIconRefreshButton = styled.button`
  outline: none;
  border: 1px solid transparent;
  cursor: pointer;
  opacity: 0.8;
  width: 26px;
  height: 26px;
  border-radius: 24px;
  padding: 4px;
  margin: 0 0 0 8px;
  transition: 0.4s ease-in;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${TangleColors.tangleBlack};
  color: ${TangleColors.white};
  &:hover {
    background: ${TangleColors.grayLight};
    color: ${TangleColors.lighthover};
    opacity: 1;
  }
`

export const ZoomOverlay = styled.rect`
  fill: transparent;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`
const ChartZoomOut = styled(RiZoomOutLine)`
  width: 16px;
  height: 16px;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  opacity: 0.8;
  ${ZoomIconButton}:hover & {
    color: ${TangleColors.black};
    opacity: 1;
  }
`
const ChartZoomIn = styled(RiZoomInLine)`
  width: 16px;
  height: 16px;
  color: ${TangleColors.white};
  opacity: 0.8;
  transition: 0.4s ease-in;
  ${ZoomIconButton}:hover & {
    color: ${TangleColors.black};
    opacity: 1;
  }
`
const ChartZoomRefresh = styled(RiRefreshLine)`
  width: 16px;
  height: 16px;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  opacity: 0.8;
  ${ZoomIconRefreshButton}:hover & {
    color: ${TangleColors.lighthover};
    opacity: 1;
  }
`

const ZoomControlWrapper = styled.div<ZoomProps>`
  /* max-width: ${(props) => (props.resetTrue ? ` 130px` : ` 90px`)}; */
  max-width: 130px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

export default ChartZoom
