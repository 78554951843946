import React, { FC } from "react"
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts"
import styled from "styled-components"
import CustomTooltip from "./CustomTooltip" // Make sure this path is correct
import { formattedUnixToDate, unixToDate } from "utils/time"
import { TangleColors } from "styles/ColorStyles"

// Styled container for the chart
const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

// Interface for the data points in the data array
interface DataPoint {
  time: number // Assuming time is a Unix timestamp
  value: number // The value you want to plot on the y-axis
}

// Props for the CommitmentChart component
interface ChartProps {
  data: DataPoint[] // Use the specific type for your data
}

// Utility function to format Unix timestamp to a date string


// The CommitmentChart component
const CommitmentChart: FC<ChartProps> = ({ data }) => {
  // A function that formats the tick values on the X-axis
  const formatTick = (unixTime: number) => formattedUnixToDate(Number(unixTime))

  return (
    <ChartContainer>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="time"
          interval="preserveStartEnd"
          tickFormatter={formatTick}
          type="number"
          domain={["dataMin", "dataMax"]}
        />
        <YAxis />
        <Tooltip content={CustomTooltip} />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke={TangleColors.lighthover}
        />
      </LineChart>
    </ChartContainer>
  )
}

export default CommitmentChart
