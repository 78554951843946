import { Chains } from "@tangleswap/sdk"
import { getChainInfo } from "constants/chainInfo"
import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

interface ChainProps {
  targetChain?: Chains
  onSelectChain?: (targetChain: number) => void
}
const NetworkChainRow: FC<ChainProps> = (props) => {
  const { targetChain, onSelectChain } = props

  const chainInfo = getChainInfo(targetChain)
  if (!chainInfo) return null
  const { label, logoUrl } = chainInfo

  return (
    <NetworkBody onClick={() => onSelectChain(Number(targetChain))}>
      <ChainCover>
        <ChainIcon src={logoUrl} alt={label} />
        <ChainName>{label}</ChainName>
      </ChainCover>
    </NetworkBody>
  )
}

const NetworkBody = styled.div<ChainProps>`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px auto;
`
const ChainCover = styled.div`
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 8px auto;
`
const ChainIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 999px;
`
const ChainName = styled(Subtitle)`
  color: ${TangleColors.black};
  width: 100%;
  margin: 0 0 0 8px;
`
export default NetworkChainRow
