import React, { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"
import NoMetaMask from "./NoMetaMask"
import NoNamiWallet from "./NoNamiWallet"
import Firefly from "./Firefly"
import WalletModal from "./WalletModal"
import {
  closeConnectWalletModal,
  closeFireFlyModal,
  openFireFlyModal,
} from "store/actions/modalActions"

const FeedbackModals = () => {
  const dispatch = useAppDispatch()
  const fireFlyClicked = useCallback(() => {
    dispatch(openFireFlyModal())
  }, [dispatch])

  const closeWalletModal = useCallback(() => {
    dispatch(closeConnectWalletModal())
  }, [dispatch])

  const closeFirefly = useCallback(() => {
    dispatch(closeFireFlyModal())
  }, [dispatch])
  const connectWalletState = useAppSelector(
    (state) => state.modal.connectWallet
  )

  const noMetamaskState = useAppSelector((state) => state.modal.noMetamask)
  const noNamiWalletState = useAppSelector((state) => state.modal.noNamiWallet)
  const fireflyState = useAppSelector((state) => state.modal.selectFirefly)
  return (
    <div>
      {" "}
      {noMetamaskState ? (
        <>
          <NoMetaMask />
        </>
      ) : null}
      {noNamiWalletState ? (
        <>
          <NoNamiWallet />
        </>
      ) : null}
      {fireflyState ? (
        <>
          <Firefly closeFirefly={closeFirefly} />
        </>
      ) : null}
      {connectWalletState ? (
        <>
          <WalletModal
            fireFlyClicked={fireFlyClicked}
            closeWalletModal={closeWalletModal}
          />
        </>
      ) : null}
    </div>
  )
}

export default FeedbackModals
