import { ScaleLinear } from "d3"
import React, { useMemo } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

const ChartLine = ({
  value,
  xScale,
  innerHeight,
}: {
  value: number
  xScale: ScaleLinear<number, number>
  innerHeight: number
}) =>
  useMemo(
    () => (
      <StyledLine
        x1={xScale(value)}
        y1="0"
        x2={xScale(value)}
        y2={innerHeight}
      />
    ),
    [value, xScale, innerHeight]
  )

const StyledLine = styled.line`
  opacity: 0.5;
  stroke-width: 2;
  stroke: ${TangleColors.white};
  fill: none;
`

export default ChartLine
