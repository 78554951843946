import { BigNumber } from "ethers"
import { Field } from "@tangleswap/sdk"
import { createReducer } from "@reduxjs/toolkit"
import { clearNfts, fetchNfts, freezeNft, selectNft } from "./action"

export interface StakingState {
  readonly byId: {
    readonly [id: number]: {
      readonly id: number
      readonly name: string
      readonly image: string
      readonly baseApy: number | null
      readonly stakeTime: number | null
      readonly lockPeriod: number | null
      readonly rewardPrincipal: BigNumber | null
      readonly interestClaimed: BigNumber | null
    }
  }

  readonly ownedNftIds: number[] | undefined // nft ids owned or staked
  readonly [Field.INPUT]: {
    nftId: number
  }
  readonly [Field.OUTPUT]: {
    nftId: number
  }
}

const initialState: StakingState = {
  byId: {},
  ownedNftIds: [],
  [Field.INPUT]: {
    nftId: 0,
  },
  [Field.OUTPUT]: {
    nftId: 0,
  },
}

export default createReducer<StakingState>(initialState, (builder) => {
  builder
    .addCase(fetchNfts, (state, { payload: { nftList } }) => {
      const ids = Object.keys(nftList)
      ids.map((id) => {
        state.byId[Number(id)] = nftList[Number(id)]
      }) // eslint-disable-line
      state.ownedNftIds = Object.keys(state.byId).map((id: string) =>
        Number(id)
      )
    })
    .addCase(clearNfts, (state) => {
      state.byId = {}
      state.ownedNftIds = []
    })
    .addCase(selectNft, (state, { payload: { field, nftId } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      return {
        ...state,
        [field]: {
          nftId: state[field].nftId === nftId ? 0 : nftId,
        },
        [otherField]: {
          nftId: 0,
        },
      }
    })
    .addCase(freezeNft, (state, { payload: { field } }) => {
      return {
        ...state,
        [field]: {
          nftId: 0,
        },
      }
    })
})
