import { ReactNode, useCallback } from "react"

import { useLiquidityState } from "components/Liquidity/utils/actions"
import { useCurrencyLP } from "components/Liquidity//utils/hooks"
import {
  selectLiquidityCurrency,
  typeInputLiqudity,
} from "store/actions/LiquidityActions"
import { useAppDispatch } from "store/hooks"
import { Field } from "@tangleswap/sdk"

export function useLiquidityActionHandlers(): {
  onCurrencySelection: (field: Field, currency: any, chainId?: any) => void
  onUserInput: (field: Field, typedValue: string) => void
  LPOutPutError?: ReactNode
  LPInPutError?: ReactNode
} {
  const dispatch = useAppDispatch()

  // const tangleswapTokenListOnChain = useAppSelector(
  //   (state) => state.tokenList.tokenList
  // )
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useLiquidityState()

  const inputCurrency = useCurrencyLP(inputCurrencyId, Field.INPUT)
  const outputCurrency = useCurrencyLP(outputCurrencyId, Field.OUTPUT)

  const currencies = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }
  let LPInPutError: string | undefined
  let LPOutPutError: string | undefined
  const onCurrencySelection = useCallback(
    (field: Field, currencyAddress: any, chainId?: any) => {
      dispatch(selectLiquidityCurrency({ field, currencyAddress, chainId }))
    },
    [dispatch]
  )

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInputLiqudity({ field, typedValue }))
    },
    [dispatch]
  )

  if (!currencies[Field.OUTPUT] || currencies[Field.OUTPUT] === undefined) {
    LPOutPutError = LPOutPutError ?? "Select token"
  }
  if (
    !currencies[Field.INPUT] ||
    currencies[Field.INPUT] === undefined ||
    ![Field.INPUT]
  ) {
    LPInPutError = LPInPutError ?? "Select token"
  }

  return {
    LPInPutError,
    LPOutPutError,
    onCurrencySelection,
    onUserInput,
  }
}
