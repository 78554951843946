import { FC } from "react"
import styled from "styled-components"
import TangleToggle from "../utils/Toggle"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { FormTextInput, SelectInput, TextLabel } from "styles/InputStyles"

interface FarmSearchProps {
  searchRegister?: any
  active?: boolean
  tangleswapFarmsData?: any
  farmState?: string | undefined
  switchLive?: (value?: any) => void
  isModeToggled?: boolean
  toggleOffExpertMode?: () => void
  toggleOnExpertMode?: () => void
  setValue?: any
}
const FarmSearch: FC<FarmSearchProps> = (props) => {
  const {
    searchRegister,
    farmState,
    setValue,
    toggleOffExpertMode,
    toggleOnExpertMode,
    isModeToggled,
  } = props

  return (
    <Body>
      <Cover>
        <FarmOptions>
          <FarmStatus>
            <StatusToggle>My positions</StatusToggle>
            <TangleToggle
              isToggled={Boolean(isModeToggled)}
              toggleOnButton={toggleOnExpertMode}
              toggleOffButton={toggleOffExpertMode}
            />
          </FarmStatus>
          <FarmToggleWrapper>
            {" "}
            <FarmWrap>
              <CycleText
                value="live"
                active={farmState === "live"}
                onClick={() => setValue("farmState", "live")}
                {...searchRegister("farmState")}
              >
                Live
              </CycleText>
              <CycleText
                value="ended"
                onClick={() => setValue("farmState", "ended")}
                active={farmState === "ended"}
                {...searchRegister("farmState")}
              >
                Ended
              </CycleText>
            </FarmWrap>
          </FarmToggleWrapper>
        </FarmOptions>
        <FarmRow>
          <SelectForm>
            {/* <Label htmlFor="sort">SORT BY</Label> */}
            <SelectButton
              id="sort"
              name="sort"
              as="select"
              {...searchRegister("farmSorting")}
            >
              <option value="popular">Popular</option>
              <option value="latest">Latest</option>
              <option value="apr">APR</option>
              <option value="yield">Value Locked</option>
            </SelectButton>
          </SelectForm>
          <SearchForm>
            {/* <Label htmlFor="search">SEARCH</Label> */}
            <SearchInput
              type="text"
              as="input"
              name="search"
              id="search"
              placeholder="Search farms"
              {...searchRegister("search")}
            ></SearchInput>
          </SearchForm>
        </FarmRow>
      </Cover>
    </Body>
  )
}
const Body = styled.div`
  min-height: 79px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1232px;
  margin: 8px auto;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`
const SearchForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  @media only screen and (max-width: 850px) {
    margin: 6px 0;
    max-width: 800px;
  }
`
const SearchInput = styled(FormTextInput)`
  @media only screen and (max-width: 850px) {
    margin: 6px 0;
    max-width: 800px;
  }
`

const SelectForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  max-width: 250px;
  @media only screen and (max-width: 850px) {
    margin: 6px 0;
    max-width: 800px;
  }
`

const SelectButton = styled(SelectInput)`
  max-width: 250px;
  @media only screen and (max-width: 850px) {
    margin: 6px 0;
    max-width: 800px;
  }
`
const FarmOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  flex-direction: row;
  width: 100%;
  max-width: 440px;
  margin: 0;
  @media only screen and (max-width: 850px) {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;

    justify-content: space-between;
  }
`
const FarmWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${TangleColors.tangle10};
  border-radius: 18px;
  padding: 8px;
  cursor: pointer;
`

const FarmToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 24px;
`

const CycleText = styled(Caption)<FarmSearchProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  background: ${(props) =>
    props.active ? TangleColors.coloredButtonSecondary : TangleColors.tangle10};
  color: ${(props) => (props.active ? TangleColors.white : TangleColors.white)};
  padding: 6px 20px;
  border-radius: 10px;
`

const FarmRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  max-width: 647px;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    margin-top: 32px;
    max-width: 800px;
  }
`

const SwitchLabel = styled.label`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`
const SwitchWrapper = styled.label`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: #222;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: black;
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: ${TangleColors.cta};

    &:before {
      transform: translate(30px, -50%);
    }
  }
`
const FarmStatus = styled.div`
  width: 100%;
  display: flex;
  max-width: 170px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StatusToggle = styled(Caption)`
  margin: 0 4px 0 0;
  color: ${TangleColors.white};
  @media only screen and (max-width: 800px) {
    white-space: nowrap;
  }
`
export default FarmSearch
