import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

const ChartFormattedDataError = () => {
  return (
    <LiquidityChart>
      <Cover>
        <ImageWrapper>
          {" "}
          <ChartImage
            src="https://d3m3d54t409w7t.cloudfront.net/homepage/icon-singularity.png"
            alt="tangleswap chart not available"
          />
        </ImageWrapper>
        <Title>Singularity detected — there is no liquidity data.</Title>
      </Cover>
    </LiquidityChart>
  )
}

const ChartImage = styled.img`
  max-width: 300px;
  height: 150px;
  width: 100%;
  object-fit: contain;
`
const ImageWrapper = styled.div`
  max-width: 300px;
  height: 150px;
  width: 100%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LiquidityChart = styled.div`
  width: 100%;
  max-width: 500px;
  height: 250px;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0;
`

export default ChartFormattedDataError
