import { updateObject } from "store/utility"
import {
  TANGLE_FARMS_REFRESH_STATUS,
  TANGLE_FARMS_TLP_STATUS,
} from "../actions/actionsTypes"

export interface FarmTLPMode {
  tangleTLPState: any
  farmsStateRefresh: boolean
}
const initialState: FarmTLPMode = {
  tangleTLPState: null,
  farmsStateRefresh: false,
}

const updateFarmTLPStatus = (state, action) => {
  return updateObject(state, {
    tangleTLPState: action.tangleTLPState,
  })
}

const updateFarmStateStatus = (state, action) => {
  return updateObject(state, {
    farmsStateRefresh: action.farmsStateRefresh,
  })
}

const FarmsTLPReducer = (state = initialState, action) => {
  switch (action.type) {
    case TANGLE_FARMS_TLP_STATUS:
      return updateFarmTLPStatus(state, action)
    case TANGLE_FARMS_REFRESH_STATUS:
      return updateFarmStateStatus(state, action)

    default:
      return state
  }
}

export default FarmsTLPReducer
