import LiquidityDetailsContainer from "components/Liquidity/LiquidityDetails/LiquidityDetailsContainer"

import { useEffect } from "react"
import styled from "styled-components"

const PoolsDetail = () => {
  useEffect(() => {
    document.title = "LP Control Room"
  }, [])
  return (
    <>
      <Body>
        <LiquidityDetailsContainer />
      </Body>
    </>
  )
}
const Body = styled.div`
  /* min-height: 100vh; */
`
export default PoolsDetail
