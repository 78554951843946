import { FETCH_TOKENS, FETCH_TOKENS_LOADING } from "store/actions/actionsTypes"
import { updateObject } from "../utility"
export interface TokenList {
  tokenList: any
  tokenListLoading: boolean
}

const initialState: TokenList = {
  tokenList: null,
  tokenListLoading: false,
}

const tokenListSuccess = (state: any, action: any) => {
  return updateObject(state, {
    tokenList: action.tokenList,
  })
}
const tokenListOnChainLoading = (state: any, action: any) => {
  return updateObject(state, {
    tokenListLoading: action.tokenListLoading,
  })
}

const tokenListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_TOKENS:
      return tokenListSuccess(state, action)
    case FETCH_TOKENS_LOADING:
      return tokenListOnChainLoading(state, action)
    default:
      return state
  }
}
export default tokenListReducer
