import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { FiExternalLink } from "react-icons/fi"
import { toApproxMax, toApproxMin } from "utils/toSignificant"
import { Chains, NONFUNGIBLE_POSITION_MANAGER_ADDRESS } from "@tangleswap/sdk"
import useExplorerInfo from "utils/useExplorerInfo"
import { useWSCContext } from "context/MilkomedaContext"
interface TvlProps {
  lowerTick?: any
  upperTick?: any
  rangeNumber?: any
  farmAddress?: string
}

const BottomRange = (props: TvlProps) => {
  const { lowerTick, upperTick, rangeNumber } = props

  const { explorerURL } = useExplorerInfo()
  const { chainId } = useWSCContext()
  return (
    <Body>
      <TextTitle>Your Range</TextTitle>
      <TvlText>
        {toApproxMin(lowerTick, 4, true)} — {toApproxMax(upperTick, 4, true)}
      </TvlText>
      <RangeBottom>
        <NftIdText
          href={`${
            chainId === Chains.SEPOLIA_TEST
              ? `${explorerURL?.explorer}/token/${NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]}?=${rangeNumber}`
              : `${explorerURL?.explorer}/token/${NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]}/instance/${rangeNumber}`
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <RangeText> NFT ID - {rangeNumber}</RangeText>
          <RangeExternalIcon />
        </NftIdText>
      </RangeBottom>
    </Body>
  )
}
const NftIdText = styled.a`
  color: ${TangleColors.tangleYellow};
  font-weight: 500;
  display: flex;
  align-items: center;
`
const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  margin: 0 0 2px 0;
`

const TvlText = styled(Caption)`
  color: ${TangleColors.white};
`

const RangeBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: 0.4s ease-in;
  margin: 4px 0 0 0;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const RangeExternalIcon = styled(FiExternalLink)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.tangleYellow};
  transition: 0.4s ease-in;
  ${RangeBottom}:hover & {
    color: ${TangleColors.lighthover};
  }
`

const RangeText = styled(Caption)`
  color: ${TangleColors.tangleYellow};
  margin: 0 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${RangeBottom}:hover & {
    color: ${TangleColors.lighthover};
  }
`
export default BottomRange
