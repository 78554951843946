import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
interface AuctionProps {
  switchState?: boolean
  switchToggle?: () => void
}
const AuctionToggle: FC<AuctionProps> = (props) => {
  const { switchState, switchToggle } = props
  return (
    <>
      {" "}
      <SwitchLabel>
        <SwitchInput
          type={"checkbox"}
          checked={switchState}
          onChange={switchToggle}
        />
        <Switch />
      </SwitchLabel>
    </>
  )
}
const SwitchLabel = styled.label`
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

const Switch = styled.div`
  position: relative;
  width: 60px;
  height: 28px;
  background: ${TangleColors.white};

  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: ${TangleColors.swapBG};
    transform: translate(0, -50%);
  }
`

const SwitchInput = styled.input`
  display: none;
  border: 1px solid ${TangleColors.white} !important;
  border-radius: 99px;

  &:checked + ${Switch} {
    background: ${TangleColors.cta};

    &:before {
      transform: translate(30px, -50%);
    }
  }
`

const InputText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${TangleColors.white};
`
export default AuctionToggle
