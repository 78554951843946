export const popAnimation = {
  initial: { scale: 0.6, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: { type: "spring", stiffness: 260, damping: 20 },
  },
  exit: { scale: 0.6, opacity: 0, transition: { duration: 0.2 } },
}

export const fadeInOutAnimation = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: { opacity: 0, transition: { duration: 0.3 } },
}

export const zoomInOutAnimation = {
  initial: { scale: 0.8, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: { scale: 0.8, opacity: 0, transition: { duration: 0.2 } },
}

export const flipAnimation = {
  initial: { rotateX: 90, opacity: 0 },
  animate: {
    rotateX: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 260, damping: 20 },
  },
  exit: { rotateX: 90, opacity: 0, transition: { duration: 0.2 } },
}

/**
 * Creates slide-in and slide-out animation variants for a modal component.
 *
 * @param direction - The direction from which the modal should appear and disappear.
 *                    Accepts one of the following values: 'bottom', 'top', 'left', 'right'.
 *                    - 'bottom': Modal will slide in from the bottom and slide out to the bottom.
 *                    - 'top': Modal will slide in from the top and slide out to the top.
 *                    - 'left': Modal will slide in from the left and slide out to the left.
 *                    - 'right': Modal will slide in from the right and slide out to the right.
 * @returns An object containing Framer Motion variants for initial, animate, and exit states.
 */
export const slideAnimation = (
  direction: "bottom" | "top" | "left" | "right"
): { initial: any; animate: any; exit: any } => {
  const directions = {
    bottom: { initial: { y: 300, opacity: 0 }, exit: { y: 300 } },
    top: { initial: { y: -300, opacity: 0 }, exit: { y: -300 } },
    left: { initial: { x: -300, opacity: 0 }, exit: { x: -300 } },
    right: { initial: { x: 300, opacity: 0 }, exit: { x: 300 } },
  }

  const chosenDirection = directions[direction]

  return {
    initial: chosenDirection.initial,
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 100 },
    },
    exit: {
      ...chosenDirection.exit,
      opacity: 0,
      transition: { duration: 0.2 },
    },
  }
}
