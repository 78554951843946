import { toast as SonnerToast } from "sonner"

export const addTokenToMetamask = async (
  address: string,
  symbol: string,
  decimals: number,
  image: string
) => {
  const { ethereum } = window as any

  if (ethereum) {
    if (!address.startsWith("0x")) {
      return SonnerToast.message("Cannot add native token to Metamask.")
    }

    try {
      const wasAdded = await ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address,
            symbol,
            decimals,
            image,
          },
        },
      })

      if (wasAdded) {
        return SonnerToast.success("Token added to Metamask.")
      } else {
        return SonnerToast.error("Unable to add token to Metamask.")
      }
    } catch (error) {
      SonnerToast.error("An error occurred while adding token to Metamask.")
      console.log(error)
    }
  }
}
