import React, { FC } from "react"

import { HiOutlineExternalLink } from "react-icons/hi"

import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Header7, Subtitle } from "styles/TextStyles"
import { convertDate, formatNumber } from "../utils/formatNumber"

import LoadingInvestmentState from "../LoadingInvestmentState"
import { Link } from "react-router-dom"

import useExplorerInfo from "utils/useExplorerInfo"
interface DetailsProps {
  textColor?: string
  lockedFor?: number
  tokenAddress?: string
  tokensForSale?: any
  startTime?: any
  endTime?: any
  auctionAddress?: string
  auctionType?: string
  minimumCommitmentAmount?: number
  startPrice?: any
  endPrice?: any
  crowdSaleRate?: any
  crowdSaleGoal?: any
  templateName?: any
  totalTokenSupply?: number
  liquidityAmount?: any
  liquidityID?: number
  postAuctionLiqLauncher?: string
  active?: boolean
  currencySymbol?: string
  tokenSymbol?: string
}
const AuctionDetailsTab: FC<DetailsProps> = (props) => {
  const {
    lockedFor,
    tokenAddress,
    tokensForSale,
    startTime,
    endTime,
    auctionAddress,
    auctionType,
    minimumCommitmentAmount,
    startPrice,
    endPrice,
    crowdSaleRate,
    crowdSaleGoal,
    templateName,
    totalTokenSupply,
    liquidityAmount,
    liquidityID,
    postAuctionLiqLauncher,
    active,
    currencySymbol,
    tokenSymbol,
  } = props
  const { explorerURL } = useExplorerInfo()

  return (
    <>
      {!!endTime || !!startTime ? (
        <>
          <Grid active={active}>
            {lockedFor === undefined || isNaN(lockedFor) ? null : (
              <>
                <GridContainer>
                  <ItemKey>Liquidity Locked For</ItemKey>
                  <ItemValue>
                    {lockedFor === undefined || isNaN(lockedFor) ? (
                      <>—</>
                    ) : (
                      <>
                        {" "}
                        {!!liquidityID ? (
                          <>
                            <TabHrefInternal to={`/pools/${liquidityID}`}>
                              <ItemAddressValue>
                                {formatNumber(lockedFor, 3)}

                                {!!lockedFor && lockedFor > 1
                                  ? " days"
                                  : " day"}
                              </ItemAddressValue>

                              <ExternalIcon />
                            </TabHrefInternal>
                          </>
                        ) : (
                          <>
                            {" "}
                            <TabHrefNotLink>
                              <FormMainValue>
                                {formatNumber(lockedFor, 3)}
                                {!!lockedFor && lockedFor > 1
                                  ? " days"
                                  : " day"}
                              </FormMainValue>
                            </TabHrefNotLink>
                          </>
                        )}
                      </>
                    )}
                  </ItemValue>
                </GridContainer>
                <GridContainer>
                  <ItemKey>Liquidity Amount</ItemKey>
                  <ItemValue>
                    {!liquidityAmount ? (
                      <>—</>
                    ) : (
                      <>
                        <TabHrefExternal
                          href={`${explorerURL?.explorer}/address/${postAuctionLiqLauncher}`}
                        >
                          <ItemAddressValue>{liquidityAmount}</ItemAddressValue>
                          <ExternalIcon />
                        </TabHrefExternal>
                      </>
                    )}
                  </ItemValue>
                </GridContainer>
              </>
            )}
            {!!tokenAddress || tokenAddress !== undefined ? (
              <>
                <GridContainer>
                  <ItemKey>Token Address</ItemKey>{" "}
                  <TabHrefExternal
                    href={`${explorerURL?.explorer}/token/${tokenAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ItemAddressValue>{tokenAddress}</ItemAddressValue>
                    <ExternalIcon />
                  </TabHrefExternal>
                </GridContainer>
              </>
            ) : null}

            <GridContainer>
              <ItemKey>Auction Address</ItemKey>

              <TabHrefExternal
                href={`${explorerURL?.explorer}/address/${auctionAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ItemAddressValue>{auctionAddress}</ItemAddressValue>
                <ExternalIcon />
              </TabHrefExternal>
            </GridContainer>
            <GridContainer>
              <ItemKey>Tokens Available For Sale</ItemKey>
              <ItemValue>{`${formatNumber(
                tokensForSale,
                4
              )} ${tokenSymbol}`}</ItemValue>
            </GridContainer>

            {!!totalTokenSupply ? (
              <>
                <GridContainer>
                  <ItemKey>Total Token Supply</ItemKey>
                  <ItemValue>{`${formatNumber(
                    totalTokenSupply,
                    4
                  )} ${tokenSymbol}`}</ItemValue>
                </GridContainer>
              </>
            ) : null}
            <GridContainer>
              <ItemKey>Token Template</ItemKey>
              <ItemValue>
                {templateName === "CUSTOM"
                  ? "Custom"
                  : templateName === "FIXED"
                  ? "Fixed Supply"
                  : "Mintable Supply"}
              </ItemValue>
            </GridContainer>

            {auctionType === "batch" ? (
              <>
                <GridContainer>
                  <ItemKey>Minimum Raised</ItemKey>
                  <ItemValue>
                    {`${formatNumber(
                      minimumCommitmentAmount,
                      4
                    )} ${currencySymbol}`}
                  </ItemValue>
                </GridContainer>
              </>
            ) : (
              <>
                {auctionType === "dutch" ? (
                  <>
                    {" "}
                    <GridContainer>
                      <ItemKey>Minimum Price</ItemKey>
                      <ItemValue>
                        {`${formatNumber(endPrice, 4)} ${currencySymbol}`}
                      </ItemValue>
                    </GridContainer>
                    <GridContainer>
                      <ItemKey>Maximum Price</ItemKey>
                      <ItemValue>
                        {`${formatNumber(startPrice, 4)} ${currencySymbol}`}
                      </ItemValue>
                    </GridContainer>
                    {!isNaN(Number(endPrice * tokensForSale)) ? (
                      <>
                        <GridContainer>
                          <ItemKey>Minimum Raised Target</ItemKey>
                          <ItemValue>
                            {`${formatNumber(
                              endPrice * tokensForSale,
                              4
                            )} ${currencySymbol}`}
                          </ItemValue>
                        </GridContainer>
                      </>
                    ) : null}
                    {!isNaN(Number(startPrice * tokensForSale)) ? (
                      <>
                        {" "}
                        <GridContainer>
                          <ItemKey>Maximum Raised Target</ItemKey>
                          <ItemValue>
                            {`${formatNumber(
                              startPrice * tokensForSale,
                              4
                            )} ${currencySymbol}`}
                          </ItemValue>
                        </GridContainer>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <GridContainer>
                      <ItemKey>Price</ItemKey>
                      <ItemValue>
                        {`${formatNumber(crowdSaleRate, 4)} ${currencySymbol}`}
                      </ItemValue>
                    </GridContainer>
                    <GridContainer>
                      <ItemKey>Minimum Raised Target</ItemKey>
                      <ItemValue>{`${formatNumber(
                        crowdSaleGoal,
                        4
                      )} ${currencySymbol}`}</ItemValue>
                    </GridContainer>
                    <GridContainer>
                      <ItemKey>Maximum Raised Target</ItemKey>
                      <ItemValue>
                        {`${formatNumber(
                          crowdSaleRate * tokensForSale,
                          4
                        )} ${currencySymbol}`}
                      </ItemValue>
                    </GridContainer>
                  </>
                )}
              </>
            )}

            <GridContainer>
              <ItemKey>Auction Starts On</ItemKey>

              <ItemValue>{convertDate(startTime)}</ItemValue>
            </GridContainer>
            <GridContainer>
              <ItemKey>Auction Ends On</ItemKey>
              <ItemValue>{convertDate(endTime)}</ItemValue>
            </GridContainer>
          </Grid>
        </>
      ) : (
        <TabCover>
          <LoadingInvestmentState />
        </TabCover>
      )}
    </>
  )
}
const TabCover = styled.div`
  width: 100%;
  margin: 32px auto;
`

const Grid = styled.div<DetailsProps>`
  margin-top: 32px;
  display: ${(props) => (props.active ? `none` : `grid`)};
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
`

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ItemKey = styled(Caption)`
  color: ${TangleColors.grayDark};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const ItemValue = styled(Caption)<DetailsProps>`
  display: flex;
  align-items: center;
  margin: 4px 0 0 0;
  color: ${TangleColors.white};
`
const ItemAddressValue = styled(Subtitle)<DetailsProps>`
  display: flex;
  align-items: center;
  margin: 4px 0 0 0;
  color: ${TangleColors.lighthover};
`

const TabHrefInternal = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
  margin: 0 2px 0 4px;
  text-underline-offset: 4px;
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  text-decoration-color: ${TangleColors.lighthover};
  -webkit-text-decoration-color: ${TangleColors.lighthover};
`
const TabHrefExternal = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
  margin: 0 2px 0 4px;
  text-underline-offset: 4px;
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  text-decoration-color: ${TangleColors.lighthover};
  -webkit-text-decoration-color: ${TangleColors.lighthover};
`
const ExternalIcon = styled(HiOutlineExternalLink)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.lighthover};
  margin: 0 0 0 8px;
`
const TabHrefNotLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const FormMainValue = styled(Subtitle)`
  margin: 0;
  color: ${TangleColors.tangleYellow};
`

export default AuctionDetailsTab
