import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

const AuctionEmptyState = () => {
  return (
    <>
      <ConnectFormCover>
        <ConnectFormText>
          No auctions currently in this category!
        </ConnectFormText>
      </ConnectFormCover>
    </>
  )
}

const ConnectFormCover = styled.div`
  margin: 32px auto;
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  background: ${TangleColors.swapBG};
  border-radius: 24px;
  border: 2px solid ${TangleColors.lighthover};
`
const ConnectFormText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 12px 0;
`

export default AuctionEmptyState
