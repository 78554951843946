import { FETCH_USER_TRANSACTION } from "store/actions/actionsTypes"
import { updateObject } from "../utility"
export interface TransactionProps {
  transactionHistory: any
  transactionListGroup?: any
}

const initialState: TransactionProps = {
  transactionHistory: null,
  transactionListGroup: null,
}

const transactionHistorySuccess = (state, action) => {
  return updateObject(state, {
    transactionHistory: action.transactionHistory,
    transactionListGroup: action.transactionListGroup,
  })
}

const TransactionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_TRANSACTION:
      return transactionHistorySuccess(state, action)
    default:
      return state
  }
}

export default TransactionHistoryReducer
