import { useState, Fragment, FC, useEffect, RefObject } from "react"
import {
  ActionButton,
  ButtonContainer,
  CurrentStepWrapper,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  WrapContainer,
} from "../styles"
import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { IWrapData } from "interfaces/wscSwap.interface"
import ModalTopIcon from "../component/ModalTopIcon"
import WrapStep from "../transactionStepper/WrapStep"
import TokenAllowanceStep from "../transactionStepper/TokenAllowanceStep"
import { LOVELACE_UNIT } from "constants/milkomeda/transaction"
import WSCModalCover from "../component/WSCModalCover"
import ModalActionState from "../ModalActionState"
import { useBackup } from "../component/Backup"
import WrapGas from "../transactionStepper/WrapGasStep"
import { isInvalid } from "@tangleswap/sdk"
import { useCheckBalanceForGas } from "../component/GasCheck"

interface BeginStakeModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: IWrapData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  skipWallet?: () => void
  openWalletOverview?: () => void
  cancelTransaction?: () => void
}

const BeginStakeModal: FC<BeginStakeModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    wscModalRef,
    closeWSCContinue,
    skipWallet,
    openWalletOverview,
    cancelTransaction,
  } = props

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [needApproveOrigin, setNeedApproveOrigin] = useState<boolean>(false)
  const [steps, setSteps] = useState<any[]>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const actionType = "VEStaking"
  const { account, chainId, destinationBalanceADA } = useWSCContext()
  const { tangleship } = useTangleship()
  const { data, backup } = useBackup(steps, actionType, setCurrentStep) as {
    data: IWrapData
    backup: boolean
  }
  const { needGasWrap } = useCheckBalanceForGas()

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData, backup, data])

  useEffect(() => {
    if (!backup) {
      setCurrentStep(1)
    }

    const newSteps = []
    let stepCounter = 1

    if (needGasWrap && !wrapData?.tokenIn && !backup) {
      newSteps.push({
        number: stepCounter++,
        title: "Gas",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            nextStep={nextStep}
            actionType={actionType}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    if (wrapData?.tokenIn) {
      newSteps.push({
        number: stepCounter++,
        title: "Wrap",
        actionTitle: "Wrapping",
        component: (
          <WrapStep
            data={backup ? data : wrapData}
            nextStep={nextStep}
            actionType={actionType}
            isBackup={backup}
            isUnwrapNeeded={false}
          />
        ),
      })
    }

    if (needApproveOrigin) {
      newSteps.push({
        number: stepCounter++,
        title: "Approve",
        actionTitle: "Approving",
        component: (
          <TokenAllowanceStep
            nextStep={nextStep}
            tokenToCheck={wrapData?.tokenIn}
            actionType={actionType}
            evmFunction={wrapData?.evmFunction}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          isBackup={backup}
          toggleModal={toggleModal}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
  }, [wrapData, needApproveOrigin, backup, data, needGasWrap])

  const fetchAllowance = async (token, refetch = false) => {
    if (!account || !token?.address || isInvalid([token?.decimals])) return

    const storageKey = `wscAllowance${chainId}${account}${token.address}${actionType}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      return Number(storageValue)
    }

    const res = await tangleship?.getAllowance(
      token.address,
      account,
      actionType,
      true
    )
    const allowance = Number(res?._hex) / 10 ** token.decimals
    sessionStorage.setItem(storageKey, String(allowance))
    return allowance
  }

  const checkExecuteActionAllowance = async () => {
    if (!wrapData?.tokenIn) return
    if (wrapData?.tokenIn?.unit === LOVELACE_UNIT) {
      setNeedApproveOrigin(false)
      return
    }

    const allowance = await fetchAllowance(wrapData?.tokenIn)

    setNeedApproveOrigin(Number(allowance) < wrapData?.tokenIn?.amount)
  }

  useEffect(() => {
    if (!account || !chainId) return
    checkExecuteActionAllowance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapData?.tokenIn, account, chainId])

  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title="Manage Staking"
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <ModalActionState
          skipWallet={skipWallet}
          openWalletOverview={openWalletOverview}
          actionTitle={steps[currentStep - 1]?.actionTitle}
        />

        {steps.length > 1 && (
          <StepWrapper>
            {steps.map((step, index) => (
              <Fragment key={step.number}>
                <StepContainer>
                  <StepButton
                    active={currentStep === step.number}
                    onClick={() => {
                      if (currentStep === step.number) return
                      setCurrentStep(step.number)
                    }}
                  >
                    {step.number}
                  </StepButton>
                  <StepTitle active={currentStep === step.number}>
                    {step.title}
                  </StepTitle>
                </StepContainer>
                {index < steps.length - 1 && <StepDivider />}
              </Fragment>
            ))}
          </StepWrapper>
        )}
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useBeginStakeModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default BeginStakeModal
