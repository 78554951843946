import React from "react"
import { useLocation } from "react-router-dom"
import MinimalFooter from "./MinimalFooter"

import styled from "styled-components"

const Footer = () => {
  const route = useLocation()

  const homeTrue = route.pathname === "/"

  return <Cover>{homeTrue ? null : <MinimalFooter />}</Cover>
}

const Cover = styled.div`
  margin-top: auto;
  width: 100%;
`

export default Footer
