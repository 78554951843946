import { useAppDispatch } from "store/hooks"
import { connectWalletModal } from "store/actions/modalActions"
import addChainToMetamask from "connect/switchChain"
import { defaultChainId } from "context/MilkomedaContext"

export default function useConnectWallet(): {
  connectWallet: any
  switchChain: any
} {
  const dispatch = useAppDispatch()
  const connectWallet = () => {
    dispatch(connectWalletModal())
  }
  const switchChain = () => {
    addChainToMetamask(defaultChainId)
  }

  return { connectWallet, switchChain }
}
