import { BLOCK_NUMBER_UPDATE } from "store/actions/actionsTypes"
import { updateObject } from "store/utility"

export interface BlockProps {
  blockNumber: any
}

const initialState: BlockProps = {
  blockNumber: null,
}

const blockNumberUpdate = (state, action) => {
  return updateObject(state, {
    blockNumber: action.data,
  })
}

const blockNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case BLOCK_NUMBER_UPDATE:
      return blockNumberUpdate(state, action)
    default:
      return state
  }
}

export default blockNumberReducer
