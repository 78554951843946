import AddLiquidityModal from "components/Liquidity/AddLiquid/AddLiquidityModal"
import AddTokenContainer from "components/Liquidity/AddLiquid/AddTokenContainer"
import LiquidTier from "components/Liquidity/AddLiquid/LiquidTier"
import TiersBox from "components/Liquidity/AddLiquid/TiersBox"
import SlippageContainer from "components/utils/SlippageContainer"
import { TierProps } from "data/TierData"
import { toast as TangleToast } from "sonner"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { RiSettings2Line } from "react-icons/ri"
import { BsArrowLeft } from "react-icons/bs"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import styled from "styled-components"
import {
  ChainChangeButton,
  SwapConnectButton,
  SwapTokenButton,
} from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Header5Gothic, Subtitle } from "styles/TextStyles"

import {
  FeeAmount,
  calcToken0Deposit,
  calcToken1Deposit,
  getDivisibleTicker,
  wrapAddr,
  infinityTickerizedRange,
  moveRangeByPercentage,
  nextTickerizedRange,
  previousTickerizedRange,
  rangeToTickerizedRange,
  stringToBigInt,
  zeroTickerizedRange,
  priceToTick,
  Field,
  isInvalid,
  MAX_UINT256,
  VOID_TOKEN,
} from "@tangleswap/sdk"
import { useCurrencyLP } from "components/Liquidity/utils/hooks"
import { useDebounce } from "use-debounce"
import { usePairActionHandlers } from "components/Liquidity/utils/usePairActionHandlers"
import { ZERO_ADDRESS } from "components/utils/misc"
import PairInitialize from "./PairInitialize"
import { defaultChainId, useWSCContext } from "context/MilkomedaContext"
import LPConfirmationModal from "components/confirmation/LPConfirmationModal"

import addChainToMetamask from "connect/switchChain"
import { PendingStateContext } from "context/PendingContext"

import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  convertNumber,
  toApproxMax,
  toApproxMin,
  toSignificantDigits,
} from "utils/toSignificant"
import useParsedQueryString from "utils/useParsedQueryString"
import { useRangeLiquidityActionHandlers } from "./useRangeLiquidityActionHandlers"
import useTierArray from "./useTierArray"
import LPPrice from "components/Liquidity/AddLiquid/LPPrice"
import { useLiquidityActionHandlers } from "components/Liquidity/utils/useLiquidityActionHandlers"
import TokensModal from "components/modals/tokenmodal/TokensModal"
import { useLiquidityState } from "../utils/actions"
import DappsDivider from "utils/DappsDivider"
import PriceRange from "../LiquidityChartRangeInput/PriceRange"

import CustomTokenModal from "components/CustomToken/CustomTokenModal"
import {
  fetchTokensOnChain,
  updateTangleswapTokenList,
} from "store/actions/TokenListAction"
import { updateUnknownTokenField } from "store/actions/UnknownTokenFieldAction"
import { updateUnknownToken } from "store/actions/UnknownTokenAction"
import ReverseTokenOrder from "components/utils/ReverseTokenOrder"

import { useIsSupportedNetwork } from "constants/hooks"

import { TangleTokenProps } from "components/swap/types"
import useTangleArray, { setNewTokenId } from "utils/useTangleArray"

import { isAddress } from "utils"

import { fetchTangleCurrency } from "../utils/liquidity/useFetchLPCurrency"
import { useTangleship } from "utils/useTangleship"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"
import { fetchTangleFiatValue } from "utils/useTangleFiatValue"
import { getNativeAddress } from "./useNative"
import ConnectWalletButton from "components/utils/ConnectWalletButton"
import { Dots } from "components/utils/LoadingDots"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import LPModal, {
  useLPModalControl,
} from "components/milkomeda/liquidity/LPModal"

import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"
import { updateTradingVoidModal } from "store/actions/VoidTradingActions"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import VoidErrorInfo from "components/swap/VoidErrorInfo"
import LedgerContext from "components/LedgerContext"
import { DEFAULT_SLIPPAGE } from "components/swap/SwapContainer"

interface GuideValues {
  uiGuideAbled?: boolean
  uiGuidePoolInitialized?: boolean
}

interface TradingModeProps {
  userTradingMode?: boolean
}

interface LPCoverProps {
  rangeWarningDisabled?: boolean
}

interface MarginProps {
  marginUp?: boolean
  layoutPro?: boolean
}

const AddLiquidContainer = () => {
  const { account, chainId, l1ChainId } = useWSCContext()
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const { tangleship } = useTangleship()
  const navigate = useNavigate()
  const pairTypedValue = useAppSelector(
    (state) => state.pairReducer.pairTypedValue
  )
  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )
  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )
  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  const isSupportedNetwork = useIsSupportedNetwork()
  const supported = isSupportedNetwork
  const independentField = useAppSelector(
    (state) => state.liquidity.independentField
  )
  const leftRangeTypedValue = useAppSelector(
    (state) => state.rangeReducer.leftRangeTypedValue
  )

  const rightRangeTypedValue = useAppSelector(
    (state) => state.rangeReducer.rightRangeTypedValue
  )

  const slippageTolerance = useAppSelector(
    (state) => state.settings.userSlippageTolerance
  )

  const deadlineMinute = useAppSelector(
    (state) => state.settings.deadlineMinute
  )

  const unknownTokenField = useAppSelector(
    (state) => state.unKnownTokenFieldReducer.tokenField
  )

  const unknownTokenSymbol = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenSymbol
  )
  const unKnownFieldTokenAddress = useAppSelector(
    (state) => state.unKnownTokenFieldReducer.tokenAddress
  )
  const unknownTokenDecimal = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenDecimal
  )
  const unknownTokenName = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenName
  )
  const unknownTokenl1Address = useAppSelector(
    (state) => state.unKnownTokenFieldReducer.unknownTokenl1Address
  )
  const unknownToken1Decimals = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownToken1Decimals
  )
  const unknownTokenl1EncodedName = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenl1EncodedName
  )
  const locationChange = useLocation()
  const { openWSCModal } = useOpenWSCActionModal()
  const nativeTokenAddress = getNativeAddress(l1ChainId)
  const [tierActive, setTierActive] = useState<boolean>(false)

  const { setPendingTransaction } = useContext(PendingStateContext)
  const [openCustomTokenModal, setOpenCustomTokenModal] =
    useState<boolean>(false)
  const { onPairUserInput } = usePairActionHandlers()
  const [minRealPrice, setMinRealPrice] = useState<any>("")
  const [maxRealPrice, setMaxRealPrice] = useState<any>("")

  const [minUiTextPrice, setMinUiTextPrice] = useState<any>("")
  const [maxUiTextPrice, setMaxUiTextPrice] = useState<any>("")
  const [slugFeeAmount, setSlugFeeAmount] = useState<number>(0)
  const [token0Fiat, setToken0Fiat] = useState<any>(undefined)
  const [token1Fiat, setToken1Fiat] = useState<any>(undefined)
  const [token0USDFiat, setToken0USDFiat] = useState<any>(undefined)
  const [token1USDFiat, setToken1USDFiat] = useState<any>(undefined)
  const [slugFeePercent, setSlugFeePercent] = useState<any>("")
  const [removeModalActive, setRemoveModalActive] = useState<boolean>(false)
  const [efficiency, setEfficiency] = useState<boolean>(false)
  const [tokenChange, setTokenChange] = useState<boolean>(false)
  const [tierChanged, setTierChanged] = useState<boolean>(false)
  const [token0CompareBoolean, setToken0CompareBoolean] =
    useState<boolean>(false)
  const [token1CompareBoolean, setToken1CompareBoolean] =
    useState<boolean>(false)
  const [displayApprovalOne, setDisplayApprovalOne] = useState<boolean>(false)
  const [displayApprovalTwo, setDisplayApprovalTwo] = useState<boolean>(false)

  const [txSent, setTxSent] = useState<any>(undefined)
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false)
  const [txpending, setTxPending] = useState<boolean>(true)
  const [poolNeedsInitialization, setPoolNeedsInitialization] =
    useState<boolean>(false)
  // const [manuallyInverted, setManuallyInverted] = useState<boolean>(false)
  const [showSlippage, setShowSlippage] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const [tokenModal, setTokenModal] = useState<boolean>(false)
  const [token0Allowance, setToken0Allowance] = useState<number>(0)
  const [token1Allowance, setToken1Allowance] = useState<number>(0)
  const [poolLiquidity, setPoolLiquidity] = useState<any>(undefined)
  const [poolAddress, setPoolAddress] = useState<any>() //ZERO_ADDRESS)
  const [slot0, setSlot0] = useState<any>()
  const [slot0Price, setSlot0Price] = useState<any>()

  const [token0Disabled, setToken0Disabled] = useState<boolean>(false)
  const [token1Disabled, setToken1Disabled] = useState<boolean>(false)
  const [bothTokensDisabled, setBothTokensDisabled] = useState<boolean>(false)
  const [isDefaultSet, setIsDefaultSet] = useState<boolean>(false)
  const [selectedToken, setSelectedToken] = useState<any>(undefined)
  const [lpTXError, setLpTXError] = useState<boolean>(false)
  const [isUseWeb3ReactLoaded, setIsUseWeb3ReactLoaded] =
    useState<boolean>(false)
  setTimeout(() => {
    setIsUseWeb3ReactLoaded(true)
  }, 1500)
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const wscModalRef = useRef<any>(null)
  const parsedQs = useParsedQueryString()

  const [searchParams, setSearchParams] = useSearchParams()

  const [token0Approved, setToken0Approved] = useState<boolean | any>(undefined)
  const [token1Approved, setToken1Approved] = useState<boolean | any>(undefined)
  const [token0Approving, setToken0Approving] = useState<boolean | any>(
    undefined
  )
  const [token1Approving, setToken1Approving] = useState<boolean | any>(
    undefined
  )
  const [token0Balance, setToken0Balance] = useState<any>()
  const [token1Balance, setToken1Balance] = useState<any>()
  const [selectedCurrency, setSelectedCurrency] = useState("")

  const [activeField, setActiveField] = useState()
  const [otherCurrency, setOtherCurrency] = useState("")

  const modalRef = useRef<HTMLDivElement | any>()
  const slippageRef = useRef<HTMLDivElement | any>()
  const closeRef = useRef<HTMLDivElement | any>()
  const bodyRef = useRef<HTMLDivElement | any>()
  const swapRef = useRef<HTMLDivElement | any>()
  const lpModalRef = useRef<HTMLDivElement | any>()

  const customTokenRef = useRef<any>()
  const { onCurrencySelection, onUserInput, LPInPutError, LPOutPutError } =
    useLiquidityActionHandlers()
  const { onLeftRangeInput, onRightRangeInput } =
    useRangeLiquidityActionHandlers()

  const { isWSCConnected } = useWSCContext()
  const [wrapData, setWrapData] = useState<IWrapMultipleData>(undefined)
  const { isVisible: isWSCModalVisible, toggle: toggleWSCModal } =
    useLPModalControl()

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    typedValue,
  } = useLiquidityState()
  const inputCurrency = useCurrencyLP(inputCurrencyId, Field.INPUT)
  const outputCurrency = useCurrencyLP(outputCurrencyId, Field.OUTPUT)

  // const currencies = {
  //   [Field.INPUT]: inputCurrency ?? undefined,
  //   [Field.OUTPUT]: outputCurrency ?? undefined,
  // }
  const currencies = useMemo(() => {
    return {
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }
  }, [inputCurrency, outputCurrency])
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const handlePairOutput = useCallback(
    (value: string) => {
      onPairUserInput(value)
    },
    [onPairUserInput]
  )

  const disableVoid = false

  const address0 = currencies[Field.INPUT]?.address
  const address1 = currencies[Field.OUTPUT]?.address
  const wrappedAddress0 = wrapAddr(
    address0,
    chainId
  )?.tokenAddress?.toLowerCase()
  const wrappedAddress1 = wrapAddr(
    address1,
    chainId
  )?.tokenAddress?.toLowerCase()

  const isSameBlockhainOrder = isInvalid([wrappedAddress0, wrappedAddress1])
    ? null
    : wrappedAddress0 < wrappedAddress1

  // const blockchainDecimals0 = isSameBlockhainOrder
  //   ? currencies[Field.INPUT]?.decimals
  //   : currencies[Field.OUTPUT]?.decimals
  // const blockchainDecimals1 = isSameBlockhainOrder
  //   ? currencies[Field.OUTPUT]?.decimals
  //   : currencies[Field.INPUT]?.decimals

  const isInputTokenInValid = !LPInPutError
  const isOutputTokenInValid = !LPOutPutError

  const token0Selected = Boolean(isInputTokenInValid)
  const token1Selected = Boolean(isOutputTokenInValid)

  const [debouncedLeftRangeValue] = useDebounce(leftRangeTypedValue, 200)
  const [debouncedRightRangeValue] = useDebounce(rightRangeTypedValue, 200)

  const handleInputSelect = useCallback(
    (address: string, field: any, otherAddress: string) => {
      setShowSlippage(false)
      setOtherCurrency(otherAddress)
      setSelectedCurrency(address)
      setActiveField(field)
      setTokenModal(true)
    },
    [onCurrencySelection]
  )
  const handleOutputSelect = useCallback(
    (address: string, field: any, otherAddress: string) => {
      setShowSlippage(false)
      setOtherCurrency(otherAddress)
      setSelectedCurrency(address)
      setActiveField(field)
      setTokenModal(true)
    },
    [onCurrencySelection]
  )

  const [debouncedSlugValueAmount] = useDebounce(slugFeeAmount, 100)
  useEffect(() => {
    if (debouncedSlugValueAmount !== undefined) {
      const slugAmountFeeURL = Number(debouncedSlugValueAmount)
      const feePercent = slugAmountFeeURL / 10000
      const dataPercent = tierData?.filter((data: any) => {
        const dataAmount = data.title === feePercent
        return dataAmount
      })

      if (dataPercent?.number !== null || dataPercent?.number !== undefined) {
        setSlugFeePercent(dataPercent?.number)
      }
    }
  }, [debouncedSlugValueAmount, chainId])
  const bothTokensSelected = Boolean(token0Selected && token1Selected)

  const closeTokenModal = useCallback(() => {
    setTokenModal(false)
  }, [tokenModal, modalRef])

  const closeModal = useCallback(
    (event: any) => {
      if (modalRef.current === event?.target) {
        setTokenModal(false)
      }
    },
    [tokenModal, modalRef]
  )

  const showRemoveModal = () => {
    setRemoveModalActive(true)
  }

  const showSlippageDropdown = () => {
    setShowSlippage(!showSlippage)
  }
  const closeRemoveModal = () => {
    setRemoveModalActive(false)
  }

  const closeSlippageDropdown = useCallback(
    (event: any) => {
      if (
        event?.target === closeRef.current &&
        event?.target !== slippageRef.current
      ) {
        setShowSlippage(false)
      }
    },
    [showSlippage, slippageRef]
  )
  const dispatch = useAppDispatch()

  const selectCurrency = useCallback(
    (field: any, currencyAddress: any) => {
      onCurrencySelection(field, currencyAddress, l1ChainId)
      setTokenChange(true)
    },
    [onCurrencySelection, tangleswapTokenListOnChain]
  )
  const userUnderstand = () => {
    const tokenInfo = {
      address: unKnownFieldTokenAddress,
      name: unknownTokenName,
      symbol: unknownTokenSymbol,
      chainId: l1ChainId,
      decimals: unknownTokenDecimal,
      logoURI: undefined,
      l1Address: unknownTokenl1Address,
      l1Decimals: unknownToken1Decimals,
      l1EncodedName: unknownTokenl1EncodedName,
    }

    const newToken = [...tangleswapTokenListOnChain, tokenInfo]

    dispatch(updateTangleswapTokenList(newToken, l1ChainId))

    setTokenModal(false)
    setOpenCustomTokenModal(false)
    const tokenField =
      unknownTokenField === Field.INPUT ? Field.INPUT : Field.OUTPUT
    onCurrencySelection(tokenField, unKnownFieldTokenAddress, l1ChainId)
    dispatch(fetchTokensOnChain(l1ChainId))
  }
  //close token selection modal
  const closeTokenCustomModal = () => {
    setOpenCustomTokenModal(false)
  }
  const closeCustomTokenModal = () => {
    setTokenModal(false)
    setOpenCustomTokenModal(false)
  }
  const activateCustomModal = () => {
    setTokenModal(false)
    setOpenCustomTokenModal(true)
  }
  //close token selection modal
  const closeCustomTokenModalWithBg = (event: any) => {
    if (customTokenRef.current === event?.target) {
      setTokenModal(false)
      setOpenCustomTokenModal(false)
    }
  }

  const fetchAllowanceToken0 = useCallback(
    async (refetch = false) => {
      if (
        !account ||
        isInvalid(
          [currencies[Field.INPUT]?.address, currencies[Field.INPUT]?.decimals],
          { null: true }
        )
      )
        return

      if (isWSCConnected) {
        setToken0Allowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const addr = currencies[Field.INPUT]?.address.toLowerCase()
      const storageKey = `addLiq0Allowance${chainId}${account}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setToken0Allowance(Number(storageValue))
        return
      }

      const res = await tangleship?.getAllowance(
        currencies[Field.INPUT]?.address,
        account,
        "LP"
      )
      const allowance =
        Number(res?._hex) / 10 ** currencies[Field.INPUT]?.decimals
      sessionStorage.setItem(storageKey, allowance.toString())
      setToken0Allowance(allowance)
    },
    [
      account,
      chainId,
      currencies[Field.INPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      tangleship,
      isWSCConnected,
    ]
  )
  useEffect(() => {
    fetchAllowanceToken0()
  }, [fetchAllowanceToken0])

  const fetchAllowanceToken1 = useCallback(
    async (refetch = false) => {
      if (
        !account ||
        isInvalid(
          [
            currencies[Field.OUTPUT]?.address,
            currencies[Field.OUTPUT]?.decimals,
          ],
          { null: true }
        )
      )
        return

      if (isWSCConnected) {
        setToken1Allowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const addr = currencies[Field.OUTPUT]?.address.toLowerCase()
      const storageKey = `addLiq1Allowance${chainId}${account}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setToken1Allowance(Number(storageValue))
        return
      }

      const res = await tangleship?.getAllowance(
        currencies[Field.OUTPUT]?.address,
        account,
        "LP"
      )
      const allowance =
        Number(res?._hex) / 10 ** currencies[Field.OUTPUT]?.decimals
      sessionStorage.setItem(storageKey, allowance.toString())
      setToken1Allowance(allowance)
    },
    [
      account,
      chainId,
      currencies[Field.OUTPUT]?.address,
      currencies[Field.OUTPUT]?.decimals,
      tangleship,
    ]
  )
  useEffect(() => {
    fetchAllowanceToken1()
  }, [fetchAllowanceToken1])

  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  useEffect(() => {
    if (!wrappedAddress0 || !wrappedAddress1 || slot0PricesList === undefined)
      return

    const token0 = isSameBlockhainOrder ? wrappedAddress0 : wrappedAddress1
    const token1 = isSameBlockhainOrder ? wrappedAddress1 : wrappedAddress0

    for (const fee of Object.values(FeeAmount)) {
      const poolKey = `${token0}_${token1}_${fee}`
      const listItem = slot0PricesList?.[poolKey]

      setSlot0((args: any) => ({ ...args, [fee]: listItem?.slot0 || 0 }))
      setSlot0Price((args: any) => ({ ...args, [fee]: listItem?.price || 0 }))
    }
  }, [wrappedAddress0, wrappedAddress1, isSameBlockhainOrder, slot0PricesList])

  const updatePoolLiquidities = useCallback(
    (feeTierToUpdate: any = undefined) => {
      if (
        !wrappedAddress0 ||
        !wrappedAddress1 ||
        !chainId ||
        !FeeAmount ||
        isInvalid([
          currencies[Field.INPUT]?.decimals,
          currencies[Field.OUTPUT]?.decimals,
        ])
      )
        return

      if (!feeTierToUpdate) {
        const fetchAndUpdate = async (tier: any) => {
          const addrA = wrappedAddress0?.toLowerCase()
          const addrB = wrappedAddress1?.toLowerCase()
          const isSameOrder = addrA < addrB
          const addr0 = isSameOrder ? addrA : addrB
          const addr1 = isSameOrder ? addrB : addrA
          if (isInvalid([addr0, addr1, tier, chainId])) return

          let poolAddress
          const storageKey = `poolAddress${chainId}${addr0}${addr1}${tier}`
          const storageValue = localStorage.getItem(storageKey)
          if (storageValue !== null && storageValue !== "undefined")
            poolAddress = storageValue
          else {
            poolAddress = await tangleship?.getPool(addrA, addrB, tier)
            if (poolAddress !== ZERO_ADDRESS)
              localStorage.setItem(storageKey, poolAddress)
          }
          setPoolAddress((args: any) => ({ ...args, [tier]: poolAddress }))

          const params = {
            token0: wrappedAddress0,
            token1: wrappedAddress1,
            decimals0: currencies[Field.INPUT]?.decimals,
            decimals1: currencies[Field.OUTPUT]?.decimals,
            feeTier: tier,
          }
          checkSlot0Price(params, poolAddress)

          if (!isInvalid([poolAddress])) {
            const storageKey = `poolLiq${chainId}${addr0}${addr1}${tier}`
            const storageValue = sessionStorage.getItem(storageKey)

            let poolLiq
            if (storageValue !== null && storageValue !== "undefined")
              poolLiq = storageValue
            else {
              poolLiq = await tangleship?.getPoolLiquidity(poolAddress)
              sessionStorage.setItem(storageKey, String(poolLiq))
            }
            setPoolLiquidity((args: any) => ({
              ...args,
              [tier]: Number(poolLiq),
            }))
          }
        }

        for (const fee of Object.values(FeeAmount)) fetchAndUpdate(fee)
      }
    },
    [
      wrappedAddress0,
      wrappedAddress1,
      chainId,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals,
      tangleship,
      checkSlot0Price,
    ]
  )

  const _poolAddress = poolAddress?.[slugFeeAmount]
  const _poolLiquidity = poolLiquidity?.[slugFeeAmount]
  const _slot0Price = slot0Price?.[slugFeeAmount]
  const _slot0 = slot0?.[slugFeeAmount]

  useEffect(() => {
    updatePoolLiquidities(undefined)
  }, [updatePoolLiquidities])

  useEffect(() => {
    if (!account || !currencies[Field.INPUT]?.address) return
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[
        currencies[Field.INPUT]?.address?.toLowerCase()
      ]
    setToken0Balance(!tokenBalanceFig ? 0 : tokenBalanceFig)
  }, [currencies[Field.INPUT]?.address, tangleswapTokenListBalances, account])

  useEffect(() => {
    if (!account || !currencies[Field.OUTPUT]?.address) return
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[
        currencies[Field.OUTPUT]?.address.toLowerCase()
      ]
    setToken1Balance(!tokenBalanceFig ? 0 : tokenBalanceFig)
  }, [tangleswapTokenListBalances, currencies[Field.OUTPUT]?.address, account])

  useEffect(() => {
    const closeSlippageDropdownBody = (event) => {
      if (showSlippage && !slippageRef.current.contains(event.target)) {
        setShowSlippage(false)
      }
    }

    document.addEventListener("mousedown", closeSlippageDropdownBody)

    return () => {
      document.removeEventListener("mousedown", closeSlippageDropdownBody)
    }
  }, [showSlippage])

  const sendTransaction = () => {
    showRemoveModal()
  }

  const uiPrice = useCallback(() => {
    if (isInvalid([wrappedAddress0, wrappedAddress1, _slot0Price])) return

    const uiAdjustedPrice = isSameBlockhainOrder ? _slot0Price : 1 / _slot0Price
    if (_slot0Price !== 0) return uiAdjustedPrice
  }, [_slot0Price, isSameBlockhainOrder, wrappedAddress0, wrappedAddress1])

  const calc =
    independentField === Field.INPUT
      ? String(
          calcToken1Deposit(typedValue, uiPrice(), minRealPrice, maxRealPrice)
        )
      : String(
          calcToken0Deposit(typedValue, uiPrice(), minRealPrice, maxRealPrice)
        )
  const [debounceToken0Disabled] = useDebounce(token0Disabled, 50)
  const [debounceToken1Disabled] = useDebounce(token1Disabled, 50)
  const [debounceBothTokensDisabled] = useDebounce(bothTokensDisabled, 50)

  const pairCalc =
    independentField === Field.INPUT
      ? Number(typedValue) === parseInt("0") && !debounceToken0Disabled
        ? ""
        : String(
            calcToken1Deposit(
              typedValue,
              pairTypedValue,
              minRealPrice,
              maxRealPrice
            )
          )
      : Number(typedValue) === parseInt("0") && !token1Disabled
      ? ""
      : String(
          calcToken0Deposit(
            typedValue,
            pairTypedValue,
            minRealPrice,
            maxRealPrice
          )
        )

  const formattedAmounts = useMemo(
    () => ({
      [independentField]:
        Number.isNaN(typedValue) || isNaN(Number(String(typedValue)))
          ? ""
          : typedValue,
      [dependentField]: poolNeedsInitialization
        ? String(Number(pairCalc)) ?? ""
        : String(Number(calc)) ?? "",
    }),
    [
      dependentField,
      poolNeedsInitialization,
      independentField,
      calc,
      pairCalc,
      typedValue,
    ]
  )

  const formattedAmountsConvert = useMemo(
    () => ({
      [independentField]:
        Number.isNaN(typedValue) || isNaN(Number(String(typedValue)))
          ? ""
          : typedValue,
      [dependentField]: poolNeedsInitialization
        ? toSignificantDigits(pairCalc, 6, true, 18) ?? ""
        : toSignificantDigits(calc, 6, true, 18) ?? "",
    }),
    [
      calc,
      dependentField,
      independentField,
      pairCalc,
      poolNeedsInitialization,
      typedValue,
    ]
  )

  const token0InputValue = toSignificantDigits(
    String(formattedAmounts[Field.INPUT]),
    6
  )
  const token1InputValue = toSignificantDigits(
    String(formattedAmounts[Field.OUTPUT]),
    6
  )
  const [debouncedInputValue] = useDebounce(formattedAmounts[Field.INPUT], 100)
  const [debouncedOutputValue] = useDebounce(
    formattedAmounts[Field.OUTPUT],
    100
  )
  const [debouncedCurrentPrice] = useDebounce(
    poolNeedsInitialization
      ? pairTypedValue === ""
        ? "—"
        : pairTypedValue
      : String(uiPrice()),
    100
  )
  const { refetchAllPool: refreshTanglePools } = useTangleArray(
    account,
    chainId
  )

  const {
    tierData,
    tierAllNull,
    tierValue,
    tierLoading,
    updatePoolLiquidities: updateTierArrayLiquidities,
  } = useTierArray(
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.OUTPUT]?.decimals
  )

  const token0Slug = address0
  const token1Slug = address1

  useEffect(() => {
    if (token0Selected && token1Selected) {
      // If there's a predetermined feeTier param in the URL:
      if (parsedQs.feeAmount !== undefined) {
        const urlFeeAmount = parsedQs?.feeAmount
        const slugAmountFeeURL = Number(urlFeeAmount)
        const feePercent = slugAmountFeeURL / 10000
        const dataPercent = tierData?.filter((data: any) => {
          const dataAmount = data.title === feePercent
          return dataAmount
        })

        if (dataPercent?.number !== null || dataPercent?.number !== undefined) {
          setSlugFeePercent(dataPercent?.number)
        }

        setSlugFeeAmount(Number(parsedQs?.feeAmount))
      } else {
        if (!tierLoading) {
          if (!tierAllNull) {
            const slugFeeInfo = tierData.sort((a: any, b: any) => {
              const slugInfo = b.number - a.number
              return slugInfo
            })

            if (
              !(
                (slugFeeInfo?.title === 0.05 &&
                  (isNaN(Number(slugFeeInfo?.number)) ||
                    Number.isNaN(slugFeeInfo?.number))) ||
                slugFeeInfo?.number === null ||
                slugFeeInfo?.number === undefined
              )
            ) {
              const slugConvertAmount = slugFeeInfo?.title * 10000
              setSlugFeeAmount(Number(slugConvertAmount))
              setSlugFeePercent(slugFeeInfo?.number)
            }
          } else {
            const slugConvertAmount = 0.3 * 10000
            setSlugFeeAmount(Number(slugConvertAmount))
          }
        }
      }
    }
  }, [
    parsedQs?.feeAmount,
    tierValue,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    chainId,
    account,
  ])

  const amount0 = isInvalid([formattedAmounts[Field.INPUT]])
    ? "0"
    : formattedAmounts[Field.INPUT]
  const amount1 = isInvalid([formattedAmounts[Field.OUTPUT]])
    ? "0"
    : formattedAmounts[Field.OUTPUT]

  const prepAddLiqFn = () => {
    const feeTier = Number(debouncedSlugValueAmount)
    const tickLower = getDivisibleTicker(
      priceToTick(
        Number(minRealPrice),
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals
      ),
      feeTier
    )
    const tickUpper = getDivisibleTicker(
      priceToTick(
        Number(maxRealPrice),
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals
      ),
      feeTier
    )
    const deadline = Math.floor(Date.now() / 1000 + Number(deadlineMinute)) // deadlineMinute is stored in seconds, despite its name
    const slippage =
      slippageTolerance === "auto"
        ? DEFAULT_SLIPPAGE
        : 1 - Number(slippageTolerance) / 10000 // slippageTolerance is e.g. "400" for "4%""
    const token0Amount = debounceToken0Disabled ? "0" : amount0
    const token1Amount = token1Disabled ? "0" : amount1
    const amount0Desired = stringToBigInt(
      token0Amount,
      currencies[Field.INPUT]?.decimals
    )
    const amount1Desired = stringToBigInt(
      token1Amount,
      currencies[Field.OUTPUT]?.decimals
    )
    const amount0Min = stringToBigInt(
      String(Number(token0Amount) * slippage),
      currencies[Field.INPUT]?.decimals
    )
    const amount1Min = stringToBigInt(
      String(Number(token1Amount) * slippage),
      currencies[Field.OUTPUT]?.decimals
    )

    const fn = tangleship?.newMint
    const fnParams = [
      currencies[Field.INPUT]?.address,
      currencies[Field.OUTPUT]?.address,
      feeTier,
      tickLower,
      tickUpper,
      amount0Desired,
      amount1Desired,
      amount0Min,
      amount1Min,
      account,
      deadline,
    ] as const

    const fnFeedbackParams = { feeTier }
    const fnFeedback = (params, txReceipt) => {
      const log = txReceipt?.logs?.find(
        (log) => log.topics[0].startsWith("0x3067048b") // corresponds to "IncreaseLiquidity"
      )
      setNewTokenId(log ? parseInt(log.topics[1], 16) : undefined)
      refreshTanglePools() // not needed, can comment it out
      updateTierArrayLiquidities(params.feeTier, true)
    }

    return { fn, fnParams, fnFeedback, fnFeedbackParams }
  }

  const addLiquidity = () => {
    if (
      isInvalid([
        currencies[Field.INPUT]?.address,
        currencies[Field.OUTPUT]?.address,
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals,
        Number(debouncedSlugValueAmount),
        minRealPrice,
        maxRealPrice,
        slippageTolerance,
        deadlineMinute,
        account,
        chainId,
      ])
    ) {
      return
    }

    setLpTXError(false)
    if (!supported) {
      return
    } else {
      setRemoveModalActive(false)
      setShowPendingModal(true)
      setTxPending(true)
      setPendingTransaction(true)
      if (account === undefined || account === null) return

      const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepAddLiqFn()

      fn?.(...fnParams)?.then((res) => {
        setPendingTransaction(true)
        setLpTXError(false)

        setRemoveModalActive(false)
        setTxSent(res?.hash)
        setTxPending(false)

        onUserInput(Field.INPUT, "")
        onUserInput(Field.OUTPUT, "")
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fnFeedback(fnFeedbackParams, receipt)

            setPendingTransaction(false)
            TangleToast.success("Transaction successful", {
              description: `Add liquidity for ${
                currencies[Field.INPUT]?.symbol
              } and ${currencies[Field.OUTPUT]?.symbol} successful`,
            })
          })
        } else {
          setLpTXError(true)

          setRemoveModalActive(false)
          setPendingTransaction(false)
          setTxSent(res?.hash)
          setTxPending(false)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  const prepInitLiqFn = () => {
    const feeTier = Number(debouncedSlugValueAmount)
    const tickLower = getDivisibleTicker(
      priceToTick(
        Number(minRealPrice),
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals
      ),
      feeTier
    )
    const tickUpper = getDivisibleTicker(
      priceToTick(
        Number(maxRealPrice),
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals
      ),
      feeTier
    )
    const deadline = Math.floor(Date.now() / 1000 + Number(deadlineMinute)) // deadlineMinute is stored in seconds, despite its name
    const slippage =
      slippageTolerance === "auto"
        ? DEFAULT_SLIPPAGE
        : 1 - Number(slippageTolerance) / 10000 // slippageTolerance is e.g. "400" for "4%""

    const token0Amount = debounceToken0Disabled ? "0" : amount0
    const token1Amount = token1Disabled ? "0" : amount1
    const amount0Desired = stringToBigInt(
      token0Amount,
      currencies[Field.INPUT]?.decimals
    )
    const amount1Desired = stringToBigInt(
      token1Amount,
      currencies[Field.OUTPUT]?.decimals
    )

    const amount0Min = stringToBigInt(
      String(Number(token0Amount) * slippage),
      currencies[Field.INPUT]?.decimals
    )
    const amount1Min = stringToBigInt(
      String(Number(token1Amount) * slippage),
      currencies[Field.OUTPUT]?.decimals
    )

    const fn = tangleship?.createInitializeMintToken
    const fnParams = [
      currencies[Field.INPUT]?.address,
      currencies[Field.OUTPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals,
      feeTier,
      Number(pairTypedValue),
      tickLower,
      tickUpper,
      amount0Desired,
      amount1Desired,
      amount0Min,
      amount1Min,
      account,
      deadline,
    ] as const

    const fnFeedbackParams = { feeTier }
    const fnFeedback = (params, txReceipt) => {
      const log = txReceipt?.logs?.find(
        (log) => log.topics[0].startsWith("0x3067048b") // corresponds to "IncreaseLiquidity"
      )
      setNewTokenId(log ? parseInt(log.topics[1], 16) : undefined)
      refreshTanglePools() // not needed, can comment it out
      updateTierArrayLiquidities(params.feeTier, true)
    }

    return { fn, fnParams, fnFeedback, fnFeedbackParams }
  }

  const createAndInitialize = () => {
    if (!formattedAmounts[Field.OUTPUT] || !formattedAmounts[Field.INPUT])
      return

    if (
      isInvalid([
        currencies[Field.INPUT]?.address,
        currencies[Field.OUTPUT]?.address,
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals,
        Number(debouncedSlugValueAmount),
        pairTypedValue,
        minRealPrice,
        maxRealPrice,
        // formattedAmounts[Field.INPUT],
        // formattedAmounts[Field.OUTPUT],
        slippageTolerance,
        deadlineMinute,
        account,
        chainId,
      ])
    ) {
      return
    }
    setLpTXError(false)
    if (!supported) {
      return
    } else {
      setRemoveModalActive(false)
      setShowPendingModal(true)
      setTxPending(true)
      setPendingTransaction(true)
      if (account === undefined || account === null) return

      const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepInitLiqFn()

      fn?.(...fnParams)?.then((res) => {
        setPendingTransaction(true)
        setLpTXError(false)
        setRemoveModalActive(false)
        setTxSent(res?.hash)
        setTxPending(false)

        onUserInput(Field.INPUT, "")
        onUserInput(Field.OUTPUT, "")
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fnFeedback(fnFeedbackParams, receipt)

            setPendingTransaction(false)
            TangleToast.success("Transaction successful", {
              description: `Initializing liquidity for ${
                currencies[Field.INPUT]?.symbol
              } and ${currencies[Field.OUTPUT]?.symbol} successful`,
            })
          })
        } else {
          setLpTXError(true)

          setRemoveModalActive(false)
          setPendingTransaction(false)
          setTxSent(res?.hash)
          setTxPending(false)

          refreshTanglePools()

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  useEffect(() => {
    if (slot0PricesList === undefined) {
      setPoolNeedsInitialization(false)
      return
    }

    if (
      !currencies[Field.INPUT]?.address ||
      !currencies[Field.OUTPUT]?.address ||
      _slot0Price === undefined ||
      _poolAddress === undefined
    )
      return

    const needsInit = _slot0Price === 0 && _poolAddress === ZERO_ADDRESS
    setPoolNeedsInitialization(needsInit)
  }, [
    _poolAddress,
    _slot0Price,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    chainId,
    account,
    slot0PricesList,
  ])

  useEffect(() => {
    if (isInvalid([token0Allowance, debouncedInputValue, chainId])) return
    const initialized =
      token0Allowance === 0 ||
      token0Allowance <
        Number(debouncedInputValue) * 10 ** currencies[Field.INPUT]?.decimals
    setDisplayApprovalOne(initialized)
  }, [
    token0Allowance,
    debouncedInputValue,
    currencies[Field.INPUT]?.decimals,
    token0Approved,
    chainId,
    account,
  ])

  useEffect(() => {
    if (isInvalid([token1Allowance, debouncedOutputValue, chainId])) return
    const initialized =
      token1Allowance === 0 ||
      token1Allowance <
        Number(debouncedOutputValue) * 10 ** currencies[Field.OUTPUT]?.decimals
    setDisplayApprovalTwo(initialized)
  }, [
    token1Allowance,
    debouncedOutputValue,
    token1Approved,
    chainId,
    currencies[Field.OUTPUT]?.decimals,
    account,
  ])

  const lpBodyClick = useCallback((event?: any) => {
    if (lpModalRef.current === event?.target) {
      setRemoveModalActive(false)
    }
  }, [])

  const approveInputToken = () => {
    const tokenAddress = currencies[Field.INPUT]?.address
    if (!isAddress(tokenAddress)) {
      return
    }

    if (!supported) {
      return
    } else {
      setPendingTransaction(true)
      tangleship?.approveContract(tokenAddress, "LP").then((res) => {
        setPendingTransaction(true)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fetchAllowanceToken0(true)
            setToken0Approving(false)
            setPendingTransaction(false)
            setToken0Approved(true)

            TangleToast.success("Transaction successful", {
              description: `Token approval for ${
                currencies[Field.INPUT]?.symbol
              } successful`,
            })
          })
        } else {
          setPendingTransaction(false)
          setToken0Approved(false)
          setToken0Approving(false)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  const approveOutputToken = () => {
    const tokenAddress = currencies[Field.OUTPUT]?.address
    if (!isAddress(tokenAddress)) {
      return
    }

    if (!supported) {
      return
    } else {
      setPendingTransaction(true)
      tangleship?.approveContract(tokenAddress, "LP").then((res) => {
        setToken1Approving(true)
        setPendingTransaction(true)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fetchAllowanceToken0(true)

            setToken1Approving(false)
            setPendingTransaction(false)
            setToken1Approved(true)
            TangleToast.success("Transaction successful", {
              description: `Token approval for ${
                currencies[Field.OUTPUT]?.symbol
              } successful`,
            })
          })
        } else {
          setPendingTransaction(false)
          setToken1Approved(false)
          setToken1Approving(false)
          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  const showInputMaxButton = Boolean(
    Number(token0Balance)
    // > 0
    // && Number(formattedAmounts[Field.INPUT]) !== Number(token0Balance)
  )

  // const handleMaxInput = useCallback(() => {
  //   const balanceCappedAtMaxDecimals = isInvalid([currencies[Field.INPUT]?.decimals])
  //     ? Number(token0Balance)
  //     : Number(token0Balance?.toFixed(currencies[Field.INPUT]?.decimals))
  //   onUserInput(Field.INPUT, toSignificantDigits(balanceCappedAtMaxDecimals, 6))
  // }, [token0Balance, onUserInput])
  const handleMaxInput = useCallback(() => {
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[
        currencies[Field.INPUT]?.address.toLowerCase()
      ]
    const tokenBalance = !tokenBalanceFig ? 0 : tokenBalanceFig
    onUserInput(Field.INPUT, toSignificantDigits(tokenBalance, 6))
  }, [
    tangleswapTokenListBalances,
    onUserInput,
    currencies[Field.INPUT]?.address,
  ])

  const handleMaxOutput = useCallback(() => {
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[
        currencies[Field.OUTPUT]?.address.toLowerCase()
      ]
    const tokenBalance = !tokenBalanceFig ? 0 : tokenBalanceFig
    onUserInput(Field.OUTPUT, toSignificantDigits(tokenBalance, 6))
  }, [
    tangleswapTokenListBalances,
    onUserInput,
    currencies[Field.OUTPUT]?.address,
  ])

  // const handleMaxInput = useCallback(() => {
  //   onUserInput(
  //     Field.INPUT,
  //     toSignificantDigits(String(Number(token0Balance)), 6)
  //   )
  // }, [token0Balance, onUserInput])
  // const handleMaxOutput = useCallback(() => {
  //   onUserInput(
  //     Field.OUTPUT,
  //     toSignificantDigits(String(Number(token1Balance)), 6)
  //   )
  // }, [token1Balance, onUserInput])
  // const handleMaxOutput = useCallback(() => {
  //   const balanceCappedAtMaxDecimals = isInvalid([currencies[Field.OUTPUT]?.decimals])
  //     ? Number(token1Balance)
  //     : Number(token1Balance?.toFixed(currencies[Field.OUTPUT]?.decimals))
  //   onUserInput(
  //     Field.OUTPUT,
  //     toSignificantDigits(balanceCappedAtMaxDecimals, 6)
  //   )
  // }, [token1Balance, onUserInput])

  const showOutputMaxButton = Boolean(
    Number(token1Balance)
    // > 0
    // && Number(formattedAmounts[Field.OUTPUT]) !== Number(token1Balance)
  )

  const closePendingModal = () => {
    if (txpending) {
      setShowPendingModal(false)
    } else {
      refreshTanglePools()
      setShowPendingModal(false)
      navigate("/pools")
    }
  }

  const closeShowPendingModal = (event: any) => {
    if (modalRef.current === event?.target) {
      setShowPendingModal(false)
    }
  }
  const closeDismissModal = (event: any) => {
    // if (modalRef.current === event?.target) {
    //   setShowPendingModal(false)
    // }
    setShowPendingModal(false)
  }

  const closeLPTransactionModal = () => {
    if (txpending) {
      setShowPendingModal(false)
    } else {
      refreshTanglePools()
      setShowPendingModal(false)
      navigate("/pools")
    }
  }

  const tierClicked = () => {
    setTierActive(!tierActive)
  }

  const priceValue = uiPrice()
  const [debouncedPriceValue] = useDebounce(priceValue, 100)
  const [debouncedPairTypedValue] = useDebounce(pairTypedValue, 100)

  useEffect(() => {
    setTimeout(() => {
      setTokenChange(false)
    }, 1000)
  }, [tokenChange])

  useEffect(() => {
    if (
      isDefaultSet ||
      !debouncedSlugValueAmount ||
      isInvalid([
        wrappedAddress0,
        wrappedAddress1,
        token0Selected,
        token1Selected,
        debouncedCurrentPrice, // note: we need "currentPrice" and not "priceValue" here
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals,
        chainId,
      ])
    )
      return

    setTimeout(() => {
      setIsDefaultSet(true)
    }, 500)

    // If on Lite mode, set ranges to (0, ∞):
    if (Boolean(userTradingMode) === false) {
      setFullRangeZeroToInfinity()
    }
    // Set the default values only if the LP is initialized:
    else if (
      Boolean(userTradingMode) === true &&
      !poolNeedsInitialization &&
      bothTokensSelected
    ) {
      setTokenChange(true) // we use this to avoid initial-load flickering

      let defaultMinRangeValue =
        !isDefaultSet && Number(parsedQs.minPrice) > 0
          ? Number(parsedQs.minPrice)
          : moveRangeByPercentage(
              priceValue,
              Number(debouncedSlugValueAmount),
              -50,
              currencies[Field.INPUT]?.decimals,
              currencies[Field.OUTPUT]?.decimals
            )
      onLeftRangeInput(defaultMinRangeValue)

      let defaultMaxRangeValue =
        !isDefaultSet && Number(parsedQs.maxPrice) > 0
          ? Number(parsedQs.maxPrice)
          : moveRangeByPercentage(
              priceValue,
              Number(debouncedSlugValueAmount),
              +100,
              currencies[Field.INPUT]?.decimals,
              currencies[Field.OUTPUT]?.decimals
            )
      onRightRangeInput(defaultMaxRangeValue)
    }
  }, [
    tierChanged,
    parsedQs.firstToken,
    parsedQs.secondToken,
    debouncedSlugValueAmount,
    priceValue,
    pairTypedValue,
    poolNeedsInitialization,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.OUTPUT]?.decimals,
    chainId,
    account,
    userTradingMode,
    debouncedCurrentPrice,
    locationChange.pathname,
    isDefaultSet,
    onRightRangeInput,
    onLeftRangeInput,
    userUnderstand,
  ])

  useEffect(() => {
    if (parsedQs.firstToken === null && parsedQs.secondToken === null) {
      setDisableButton(true)
    } else {
      setDisableButton(false)
    }
  }, [parsedQs.firstToken, parsedQs.secondToken, account, chainId])
  useEffect(() => {
    if (Boolean(token0Selected && token1Selected)) {
      if (
        (Boolean(poolNeedsInitialization) &&
          Boolean(userTradingMode) === true &&
          pairTypedValue === "") ||
        (Boolean(poolNeedsInitialization) &&
          Boolean(userTradingMode) === true &&
          tierChanged)
      ) {
        setMinRealPrice("")
        setMinUiTextPrice("")
      } else {
        if (
          debouncedLeftRangeValue === "" ||
          debouncedLeftRangeValue === undefined ||
          debouncedLeftRangeValue === "undefined" ||
          isNaN(Number(debouncedLeftRangeValue)) ||
          Number.isNaN(debouncedLeftRangeValue)
        )
          return
        const minValue = rangeToTickerizedRange(
          debouncedLeftRangeValue,
          debouncedSlugValueAmount,
          currencies[Field.INPUT]?.decimals,
          currencies[Field.OUTPUT]?.decimals
        )
        setMinRealPrice(minValue)
        // onLeftRangeInput(minValue)
        setMinUiTextPrice(toApproxMin(minValue, 5, true, true))
      }
    }
  }, [
    debouncedLeftRangeValue,
    debouncedSlugValueAmount,
    currencies[Field.INPUT]?.decimals,
    pairTypedValue,
    currencies[Field.OUTPUT]?.decimals,
    poolNeedsInitialization,
    chainId,
    account,
    tierChanged,
  ])

  useEffect(() => {
    if (Boolean(token0Selected && token1Selected)) {
      if (
        (Boolean(poolNeedsInitialization) &&
          Boolean(userTradingMode) === true &&
          pairTypedValue === "") ||
        (Boolean(poolNeedsInitialization) &&
          Boolean(userTradingMode) === true &&
          tierChanged)
      ) {
        setMaxRealPrice("")
        setMaxUiTextPrice("")
      } else {
        if (
          debouncedRightRangeValue === "" ||
          debouncedRightRangeValue === undefined ||
          debouncedRightRangeValue === "undefined" ||
          isNaN(Number(debouncedRightRangeValue)) ||
          Number.isNaN(debouncedRightRangeValue)
        )
          return
        const maxValue = rangeToTickerizedRange(
          debouncedRightRangeValue,
          debouncedSlugValueAmount,
          currencies[Field.INPUT]?.decimals,
          currencies[Field.OUTPUT]?.decimals
        )
        setMaxRealPrice(maxValue)
        // onRightRangeInput(maxValue)
        setMaxUiTextPrice(toApproxMax(maxValue, 5, false))
      }
    }
  }, [
    onRightRangeInput,
    debouncedRightRangeValue,
    debouncedSlugValueAmount,
    currencies[Field.INPUT]?.decimals,
    tierChanged,
    currencies[Field.OUTPUT]?.decimals,
    pairTypedValue,
    poolNeedsInitialization,
    chainId,
    account,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
  ])

  const minDecrement = () => {
    const priceOfInterest = minRealPrice
    const priceValue = previousTickerizedRange(
      priceOfInterest,
      debouncedSlugValueAmount,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals
    )
    setMinRealPrice(priceValue)
    const minValueConverted = toApproxMin(priceValue, 5, true, true)
    setMinUiTextPrice(minValueConverted)
  }

  const minIncrement = () => {
    const priceOfInterest = minRealPrice
    const priceValue = nextTickerizedRange(
      priceOfInterest,
      debouncedSlugValueAmount,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals
    )
    setMinRealPrice(priceValue)
    const minValueConverted = toApproxMin(priceValue, 5, true, true)
    setMinUiTextPrice(minValueConverted)
  }

  const maxDecrement = () => {
    const priceOfInterest = maxRealPrice
    const priceValue = previousTickerizedRange(
      priceOfInterest,
      debouncedSlugValueAmount,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals
    )
    setMaxRealPrice(priceValue)
    const maxValueConverted = toApproxMax(priceValue, 5, false)
    setMaxUiTextPrice(maxValueConverted)
  }

  const maxIncrement = () => {
    const priceOfInterest = maxRealPrice
    const priceValue = nextTickerizedRange(
      priceOfInterest,
      debouncedSlugValueAmount,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals
    )
    setMaxRealPrice(priceValue)
    const maxValueConverted = toApproxMax(priceValue, 5, false)
    setMaxUiTextPrice(maxValueConverted)
  }
  const fullRangeClick = () => {
    setEfficiency(!efficiency)
  }
  const closeEfficiency = () => {
    setEfficiency(false)
  }

  const setFullRangeZeroToInfinity = () => {
    // Set the Min Price to ~ 0
    const zeroPrice = zeroTickerizedRange(
      Number(debouncedSlugValueAmount),
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals
    )
    const approxMinValue = toApproxMin(zeroPrice, 8, true, true)
    // Set the Max Price to ~ ∞
    const infinityPrice = infinityTickerizedRange(
      Number(debouncedSlugValueAmount),
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals
    )

    const approxMaxValue = toApproxMax(infinityPrice, 8, false)
    setMinRealPrice(zeroPrice)
    setMaxRealPrice(infinityPrice)
    setMinUiTextPrice(approxMinValue)
    setMaxUiTextPrice(approxMaxValue)
    // onLeftRangeInput(zeroPrice) // these 2 cause conflicts, avoid!
    // onRightRangeInput(infinityPrice) // these 2 cause conflicts, avoid!
    setEfficiency(false)
  }

  useEffect(() => {
    if (
      isInvalid([
        poolNeedsInitialization,
        debouncedPairTypedValue || debouncedPriceValue,
        minRealPrice,
        maxRealPrice,
        chainId,
        account,
        currencies[Field.INPUT]?.address,
        currencies[Field.OUTPUT]?.address,
      ])
    )
      return

    const validPrice = poolNeedsInitialization
      ? !isNaN(Number(pairTypedValue)) && pairTypedValue !== ""
      : !isNaN(Number(priceValue))
    const priceVal = poolNeedsInitialization
      ? Number(debouncedPairTypedValue)
      : Number(debouncedPriceValue)
    const minVal = Number(minRealPrice)
    const maxVal = Number(maxRealPrice)

    if (!(minVal > 0) || !(maxVal > 0) || !validPrice || tokenChange) return

    setToken0Disabled(maxVal < priceVal)
    setToken1Disabled(minVal > priceVal)
    setBothTokensDisabled(minVal > maxVal)
  }, [
    poolNeedsInitialization,
    debouncedPairTypedValue,
    debouncedPriceValue,
    minRealPrice,
    maxRealPrice,
    chainId,
    account,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    tokenChange,
  ])

  const pairInit =
    currencies[Field.INPUT]?.address &&
    currencies[Field.OUTPUT]?.address &&
    poolNeedsInitialization

  const previewDisabled =
    disableButton ||
    Boolean(debounceBothTokensDisabled) ||
    (!(debounceToken0Disabled || token1Disabled) &&
      (!formattedAmounts[Field.INPUT] || !formattedAmounts[Field.OUTPUT])) ||
    (!(debounceToken0Disabled || token1Disabled) &&
      (isNaN(Number(formattedAmounts[Field.OUTPUT])) ||
        isNaN(Number(formattedAmounts[Field.INPUT]))))

  useEffect(() => {
    if (!tierLoading && !slugFeeAmount) {
      // find the tier with the highest "tier.number" value
      const bestTier: any = Object.values(tierData).reduce(
        (prev: any, current: any) =>
          prev.number > current.number ? prev : current
      )
      setSlugFeeAmount(Number(bestTier.title) * 10000)
    }
  }, [tierLoading])

  useEffect(() => {
    if (Boolean(token0Selected && token1Selected)) {
      if (!tierLoading && (tierAllNull || poolNeedsInitialization)) {
        setTierActive(true)
      } else {
        setTierActive(false)
      }
    }
  }, [
    token0Selected,
    token1Selected,
    tierAllNull,
    tierLoading,
    poolNeedsInitialization,
    account,
    chainId,
  ])
  const currencyA = currencies[Field.INPUT]?.symbol
  const currencyB = currencies[Field.OUTPUT]?.symbol
  const handleFeePoolSelect = useCallback(
    (newFeeAmount: any) => {
      // onLeftRangeInput("")
      // onRightRangeInput("")
      setTierChanged(true)
      setTierActive(!tierActive)
      setSlugFeeAmount(Number(newFeeAmount))
    },
    [tierActive, onRightRangeInput]
  )

  useEffect(() => {
    if (tierChanged) {
    }
    setTimeout(() => {
      setTierChanged(false)
    }, 3000)
  }, [tierChanged])
  const SwapTokenDisabled =
    account !== undefined && poolNeedsInitialization && pairTypedValue === ""

  const currentValueActive = debouncedCurrentPrice !== "NaN"
  const token0RangeDisabled =
    (debounceToken0Disabled && token1Selected && currentValueActive) ||
    (debounceBothTokensDisabled && token1Selected && currentValueActive)
  const token1RangeDisabled =
    (token1Disabled && token1Selected && currentValueActive) ||
    (debounceBothTokensDisabled && token1Selected && currentValueActive)

  const swapInputAllowed = poolNeedsInitialization
    ? (Boolean(token0Selected) &&
        Boolean(token1Selected) &&
        parsedQs.firstToken !== null &&
        parsedQs.secondToken !== null &&
        Boolean(slugFeeAmount) &&
        pairTypedValue !== "" &&
        Boolean(minUiTextPrice) &&
        Boolean(maxUiTextPrice)) ||
      (maxUiTextPrice?.trim() === "∞" && minUiTextPrice?.trim() === "≈0") ||
      (Boolean(minUiTextPrice) && Boolean(maxUiTextPrice))
    : Boolean(token0Selected && !token1Selected) ||
      Boolean(!token0Selected && token1Selected) ||
      (Boolean(token0Selected) &&
        Boolean(token1Selected) &&
        debouncedCurrentPrice !== "NaN" &&
        Boolean(!tierLoading) &&
        Boolean(!tierAllNull))

  const rangeAllowed = poolNeedsInitialization
    ? Boolean(token0Selected) &&
      Boolean(token1Selected) &&
      parsedQs.firstToken !== null &&
      parsedQs.secondToken !== null &&
      Boolean(slugFeeAmount) &&
      pairTypedValue !== ""
    : Boolean(token0Selected) &&
      Boolean(token1Selected) &&
      Boolean(!tierLoading) &&
      Boolean(!tierAllNull)

  const tierDisabled =
    debouncedCurrentPrice === "NaN" && tierAllNull && !tierLoading

  const maxRangeInputValue =
    maxUiTextPrice === undefined || maxUiTextPrice === "undefined"
      ? ""
      : maxUiTextPrice

  const minRangeInputValue =
    minUiTextPrice === undefined || minUiTextPrice === "undefined"
      ? ""
      : minUiTextPrice

  useEffect(() => {
    if (
      isInvalid([
        debouncedSlugValueAmount,
        minRealPrice,
        maxRealPrice,
        token0Slug,
        token1Slug,
      ])
    )
      return
    const feeTier = Number(debouncedSlugValueAmount)
    // const minPrice = toSignificantDigits(minRealPrice, 6, true)
    // const maxPrice = toSignificantDigits(maxRealPrice, 6, true)
    const minPrice = convertNumber(minRealPrice)
    const maxPrice = convertNumber(maxRealPrice)
    if (token0Slug && token1Slug && isDefaultSet) {
      let slug = `firstToken=${token0Slug}&secondToken=${token1Slug}`
      if (Boolean(feeTier)) {
        slug = slug.concat(`&feeAmount=${feeTier}`)
        if (
          maxPrice !== undefined &&
          minPrice !== undefined &&
          Boolean(userTradingMode) === true
        ) {
          slug = slug.concat(`&minPrice=${minPrice}&maxPrice=${maxPrice}`)
        }
      }
      if (
        maxPrice !== undefined &&
        minPrice !== undefined &&
        Boolean(userTradingMode) === false
      ) {
        slug = slug.concat(`&minPrice=${minPrice}&maxPrice=${maxPrice}`)
      }

      setSearchParams(slug)
    }
  }, [
    token1Slug,
    token0Slug,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    debouncedSlugValueAmount,
    minRealPrice,
    maxRealPrice,
    chainId,
    account,
    isDefaultSet,
    userTradingMode,
  ])

  const token0Input =
    Boolean(userTradingMode) === true &&
    Boolean(token0RangeDisabled) &&
    Boolean(token1Selected) &&
    Boolean(token0Selected) &&
    debouncedCurrentPrice !== "NaN"

  const token1Input =
    Boolean(userTradingMode) === true &&
    Boolean(token1RangeDisabled) &&
    Boolean(token1Selected) &&
    Boolean(token0Selected) &&
    debouncedCurrentPrice !== "NaN"

  const bothInputDisabled =
    Boolean(userTradingMode) === true &&
    Boolean(debounceBothTokensDisabled) &&
    Boolean(token1Selected) &&
    Boolean(token0Selected) &&
    debouncedCurrentPrice !== "NaN"

  const [debounceBothInputDisabled] = useDebounce(bothInputDisabled, 150)
  //TODO: SEVANE - DLEETE THIS AFTER NO BUGS REPORT ON ADD LP token0
  // useEffect(() => {
  //   if (!tangleswapTokenListOnChain || tangleswapTokenListOnChain === null)
  //     return
  //   if (
  //     parsedQs.firstToken === undefined ||
  //     parsedQs.firstToken === "undefined"
  //   )
  //     return
  //   if (parsedQs.firstToken !== undefined) {
  //     const inputTangleswapTokens = tangleswapTokenListOnChain?.filter(
  //       (token: TangleTokenProps) => {
  //         const tokenInfo =
  //           String(token?.address)?.trim()?.toLowerCase() ===
  //             String(parsedQs.firstToken)?.trim()?.toLowerCase() &&
  //           String(token.chainId) === String(l1ChainId)
  //         return tokenInfo
  //       }
  //     )

  //     if (inputTangleswapTokens === undefined) return
  //     if (inputTangleswapTokens?.length > 0) {
  //       onCurrencySelection(Field.INPUT, parsedQs.firstToken, l1ChainId)
  //     } else if (isUseWeb3ReactLoaded) {
  //       const tokenField = Field.INPUT
  //       const tokenAddress = parsedQs.firstToken
  //       fetchTangleCurrency(
  //         String(parsedQs.firstToken).trim()?.toLowerCase(),
  //         l1ChainId,
  //         tangleship
  //       )?.then((res: TangleTokenProps) => {
  //         if (
  //           res === undefined ||
  //           (res?.name === null &&
  //             res?.symbol === null &&
  //             res?.decimals === null)
  //         ) {
  //           setTokenModal(false)
  //           setOpenCustomTokenModal(false)
  //           return
  //         }

  //         dispatch(
  //           updateUnknownToken(
  //             res?.name,
  //             parsedQs.secondToken,
  //             res?.symbol,
  //             res?.decimals,
  //             res?.l1Address,
  //             res?.l1Decimals,
  //             res?.l1EncodedName
  //           )
  //         )
  //         dispatch(updateUnknownTokenField(tokenAddress, tokenField))
  //         activateCustomModal()
  //       })
  //     }
  //   }
  // }, [
  //   isUseWeb3ReactLoaded,
  //   tangleswapTokenListOnChain,
  //   parsedQs.firstToken,
  //   l1ChainId,
  //   isInputTokenInValid,
  //   locationChange.pathname,
  //   parsedQs.secondToken,
  // ])
  useEffect(() => {
    if (!tangleswapTokenListOnChain) return
    if (
      parsedQs.firstToken === undefined ||
      parsedQs.firstToken === "undefined"
    )
      return

    const inputTangleswapTokens = tangleswapTokenListOnChain.filter(
      (token: TangleTokenProps) => {
        return (
          String(token?.address).trim().toLowerCase() ===
            String(parsedQs.firstToken).trim().toLowerCase() &&
          String(token.chainId) === String(l1ChainId)
        )
      }
    )

    if (inputTangleswapTokens.length > 0) {
      onCurrencySelection(Field.INPUT, parsedQs.firstToken, l1ChainId)
    } else if (isUseWeb3ReactLoaded) {
      if (!!isWSCConnected) return
      activateCustomModal() // Activate the modal immediately

      fetchTangleCurrency(
        String(parsedQs.firstToken).trim().toLowerCase(),
        l1ChainId,
        tangleship
      ).then((res: TangleTokenProps) => {
        if (!res || !res.name || !res.symbol || !res.decimals) {
          setTokenModal(false)
          setOpenCustomTokenModal(false)
          return
        }

        // Corrected to use firstToken for the address
        dispatch(
          updateUnknownToken(
            res.name,
            parsedQs.firstToken,
            res.symbol,
            res.decimals,
            res.l1Address,
            res.l1Decimals,
            res.l1EncodedName
          )
        )
        dispatch(updateUnknownTokenField(parsedQs.firstToken, Field.INPUT))
      })
    }
  }, [
    isUseWeb3ReactLoaded,
    tangleswapTokenListOnChain,
    parsedQs.firstToken,
    l1ChainId,
    isWSCConnected,
  ])
  //TODO: SEVANE - DLEETE THIS AFTER NO BUGS REPORT ON ADD LP token1
  // useEffect(() => {
  //   if (!tangleswapTokenListOnChain || tangleswapTokenListOnChain === null)
  //     return
  //   if (
  //     parsedQs.secondToken === undefined ||
  //     parsedQs.secondToken === "undefined"
  //   )
  //     return
  //   const outputTangleswapTokens = tangleswapTokenListOnChain?.filter(
  //     (token: TangleTokenProps) => {
  //       const tokenInfo =
  //         String(token?.address)?.trim()?.toLowerCase() ===
  //           String(parsedQs.secondToken)?.trim()?.toLowerCase() &&
  //         String(token.chainId) === String(l1ChainId)
  //       return tokenInfo
  //     }
  //   )
  //   if (outputTangleswapTokens === undefined) return
  //   if (outputTangleswapTokens?.length > 0) {
  //     onCurrencySelection(Field.OUTPUT, parsedQs.secondToken, l1ChainId)
  //   } else if (isUseWeb3ReactLoaded) {
  //     const tokenField = Field.OUTPUT
  //     const tokenAddress = parsedQs.secondToken
  //     fetchTangleCurrency(
  //       String(parsedQs.secondToken).trim()?.toLowerCase(),
  //       l1ChainId,
  //       tangleship
  //     )?.then((res: TangleTokenProps) => {
  //       if (
  //         res === undefined ||
  //         (res?.name === null && res?.symbol === null && res?.decimals === null)
  //       ) {
  //         setTokenModal(false)
  //         setOpenCustomTokenModal(false)
  //         return
  //       }

  //       dispatch(
  //         updateUnknownToken(
  //           res?.name,
  //           parsedQs.secondToken,
  //           res?.symbol,
  //           res?.decimals,
  //           res?.l1Address,
  //           res?.l1Decimals,
  //           res?.l1EncodedName
  //         )
  //       )
  //       dispatch(updateUnknownTokenField(tokenAddress, tokenField))
  //     })
  //     activateCustomModal()
  //   }
  // }, [
  //   isUseWeb3ReactLoaded,
  //   tangleswapTokenListOnChain,
  //   parsedQs.secondToken,
  //   l1ChainId,
  //   isOutputTokenInValid,
  //   locationChange.pathname,
  // ])
  useEffect(() => {
    if (!tangleswapTokenListOnChain) return
    if (
      parsedQs.secondToken === undefined ||
      parsedQs.secondToken === "undefined"
    )
      return

    const outputTangleswapTokens = tangleswapTokenListOnChain.filter(
      (token: TangleTokenProps) => {
        return (
          String(token?.address).trim().toLowerCase() ===
            String(parsedQs.secondToken).trim().toLowerCase() &&
          String(token.chainId) === String(l1ChainId)
        )
      }
    )

    if (outputTangleswapTokens.length > 0) {
      onCurrencySelection(Field.OUTPUT, parsedQs.secondToken, l1ChainId)
    } else if (isUseWeb3ReactLoaded) {
      if (!!isWSCConnected) return
      activateCustomModal() // Activate the modal immediately

      fetchTangleCurrency(
        String(parsedQs.secondToken).trim().toLowerCase(),
        l1ChainId,
        tangleship
      ).then((res: TangleTokenProps) => {
        if (!res || !res.name || !res.symbol || !res.decimals) {
          setTokenModal(false)
          setOpenCustomTokenModal(false)
          return
        }

        dispatch(
          updateUnknownToken(
            res.name,
            parsedQs.secondToken,
            res.symbol,
            res.decimals,
            res.l1Address,
            res.l1Decimals,
            res.l1EncodedName
          )
        )
        dispatch(updateUnknownTokenField(parsedQs.secondToken, Field.OUTPUT))
      })
    }
  }, [
    isUseWeb3ReactLoaded,
    tangleswapTokenListOnChain,
    parsedQs.secondToken,
    l1ChainId,
    isWSCConnected,
  ])

  useEffect(() => {
    if (parsedQs.firstToken !== undefined) {
      onCurrencySelection(Field.INPUT, parsedQs.firstToken, l1ChainId)
    }
    if (parsedQs.secondToken !== undefined) {
      onCurrencySelection(Field.OUTPUT, parsedQs.secondToken, l1ChainId)
    }
  }, [
    parsedQs.firstToken,
    parsedQs.secondToken,
    isOutputTokenInValid,
    onCurrencySelection,
    Field,
    l1ChainId,
    nativeTokenAddress,
  ])

  useEffect(() => {
    if (
      parsedQs.firstToken === undefined &&
      parsedQs.secondToken === undefined &&
      !isOutputTokenInValid
    ) {
      onCurrencySelection(Field.INPUT, `${nativeTokenAddress}`, l1ChainId)
    }
  }, [
    parsedQs.firstToken,
    parsedQs.secondToken,
    isOutputTokenInValid,
    onCurrencySelection,
    Field,
    l1ChainId,
    nativeTokenAddress,
  ])

  useEffect(() => {
    if (
      isInvalid([
        currencies[Field.INPUT]?.address,
        currencies[Field.INPUT]?.decimals,
      ])
    )
      return

    fetchTangleFiatValue(
      currencies[Field.INPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      chainId
    ).then((res) => {
      setToken0Fiat(res || 0)
    })
  }, [currencies[Field.INPUT]?.address, currencies[Field.INPUT]?.decimals])

  useEffect(() => {
    if (
      isInvalid([
        currencies[Field.OUTPUT]?.address,
        currencies[Field.OUTPUT]?.decimals,
      ])
    )
      return

    fetchTangleFiatValue(
      currencies[Field.OUTPUT]?.address,
      currencies[Field.OUTPUT]?.decimals,
      chainId
    ).then((res) => {
      setToken1Fiat(res || 0)
    })
  }, [currencies[Field.OUTPUT]?.address, currencies[Field.OUTPUT]?.decimals])

  useEffect(() => {
    if (isInvalid([token0Fiat, formattedAmounts[Field.INPUT]])) return
    const fiatValue = token0Fiat * Number(formattedAmounts[Field.INPUT])
    setToken0USDFiat(fiatValue)
  }, [token0Fiat, formattedAmounts[Field.INPUT]])
  useEffect(() => {
    if (isInvalid([token1Fiat, formattedAmounts[Field.OUTPUT]])) return
    const fiatValue = token1Fiat * Number(formattedAmounts[Field.OUTPUT])
    setToken1USDFiat(fiatValue)
  }, [token1Fiat, formattedAmounts[Field.OUTPUT]])

  const priceRangeTokenDisabledWarning = Boolean(
    debounceToken0Disabled ||
      debounceToken1Disabled ||
      debounceBothInputDisabled
  )

  const token0InputDisabled = Boolean(token0Input)
  const token1InputDisabled = Boolean(token1Input)

  useEffect(() => {
    const tokenCompareBoolean =
      Number(token0InputValue) > Number(token0Balance) &&
      formattedAmounts[Field.OUTPUT] &&
      debouncedCurrentPrice !== "NaN"
    setToken0CompareBoolean(Boolean(tokenCompareBoolean))
  }, [
    token0InputValue,
    token0Balance,
    formattedAmounts[Field.OUTPUT],
    debouncedCurrentPrice,
    // account,
    // chainId,
    // formattedAmounts,
    // tangleswapTokenListBalances,
    // formattedAmounts[Field.INPUT],
  ])

  useEffect(() => {
    const tokenCompareBoolean =
      Number(token1InputValue) > Number(token1Balance) &&
      formattedAmounts[Field.OUTPUT] &&
      debouncedCurrentPrice !== "NaN"
    setToken1CompareBoolean(Boolean(tokenCompareBoolean))
  }, [
    token1InputValue,
    token1Balance,
    formattedAmounts[Field.OUTPUT],
    debouncedCurrentPrice,
    // chainId,
    // account,
    // tangleswapTokenListBalances,
    // formattedAmounts,
    // formattedAmounts[Field.INPUT],
  ])

  useEffect(() => {
    if (currencies[Field.INPUT]?.symbol === undefined) return
    setSelectedToken(currencies[Field.INPUT]?.symbol)
  }, [currencies[Field.INPUT]?.symbol])

  const handleTokenToggle = (tokenName: string | any) => {
    setSelectedToken(String(tokenName)?.toLowerCase())
    selectCurrency(Field.INPUT, currencies[Field.OUTPUT]?.address)
  }

  const isWrapReady = () =>
    isWSCConnected &&
    currencies[Field.INPUT]?.address &&
    currencies[Field.OUTPUT]?.address

  const fireWSCModal = () => {
    if (!isWrapReady()) return

    const addLiqData = prepAddLiqFn()
    const initLiqData = prepInitLiqFn()

    const fn = poolNeedsInitialization ? initLiqData : addLiqData
    if (!fn.fn) return

    const tokenInData = []

    fn.fn(...fn.fnParams).then((res: any) => {
      if (!token0InputDisabled) {
        tokenInData.push({
          address: currencies[Field.INPUT]?.address,
          name: currencies[Field.INPUT]?.name,
          symbol: currencies[Field.INPUT]?.symbol,
          chainId: chainId,
          decimals: currencies[Field.INPUT]?.decimals,
          logoURI: currencies[Field.INPUT]?.logoURI,
          amount: Number(formattedAmounts[Field.INPUT]),
          unit: getTokenUnit(currencies[Field.INPUT]?.l1Address),
          l1Address: currencies[Field.INPUT]?.l1Address,
          l1EncodedName: currencies[Field.INPUT]?.l1EncodedName,
          l1Decimals: currencies[Field.INPUT]?.l1Decimals,
        })
      }

      if (!token1InputDisabled) {
        tokenInData.push({
          address: currencies[Field.OUTPUT]?.address,
          name: currencies[Field.OUTPUT]?.name,
          symbol: currencies[Field.OUTPUT]?.symbol,
          chainId: chainId,
          decimals: currencies[Field.OUTPUT]?.decimals,
          logoURI: currencies[Field.OUTPUT]?.logoURI,
          amount: Number(formattedAmounts[Field.OUTPUT]),
          unit: getTokenUnit(currencies[Field.OUTPUT]?.l1Address),
          l1Address: currencies[Field.OUTPUT]?.l1Address,
          l1EncodedName: currencies[Field.OUTPUT]?.l1EncodedName,
          l1Decimals: currencies[Field.OUTPUT]?.l1Decimals,
        })
      }

      setWrapData({
        tokenIn: tokenInData,
        evmFunction: res,
        evmFeedback: {
          function: fn.fnFeedback,
          params: fn.fnFeedbackParams,
        },
      })

      setTimeout(() => {
        toggleWSCModal()
      }, 0)
    })
  }

  const handlePendingTxModal = () => {
    openWSCModal()
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleWSCModal()
      closeRemoveModal()
    }
  }
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    toggleWSCModal()
  }

  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    toggleWSCModal()
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWSCModal()
    setOpenModalSettings(false)
  }

  return (
    <>
      {showPendingModal && (
        <>
          <LPConfirmationModal
            modalRef={modalRef}
            amount0={amount0}
            amount1={amount1}
            currency0={currencies[Field.INPUT]?.symbol}
            currency1={currencies[Field.OUTPUT]?.symbol}
            pending={txpending}
            closePendingModal={closePendingModal}
            closeModal={closeShowPendingModal}
            closeDismissModal={closeDismissModal}
            txError={lpTXError}
            closeLPTransactionModal={closeLPTransactionModal}
            link={txSent}
          />
        </>
      )}
      {removeModalActive && (
        <>
          {" "}
          <AddLiquidityModal
            liquidityTitle={poolNeedsInitialization}
            lpModalRef={lpModalRef}
            inputLogo={currencies[Field.INPUT]?.logoURI}
            outputLogo={currencies[Field.OUTPUT]?.logoURI}
            inputSymbol={currencies[Field.INPUT]?.symbol}
            outputSymbol={currencies[Field.OUTPUT]?.symbol}
            inputValue={amount0}
            outputValue={amount1}
            clickAdd={
              isWSCConnected
                ? () => {
                    fireWSCModal()
                    closeRemoveModal()
                  }
                : poolNeedsInitialization
                ? createAndInitialize
                : addLiquidity
            }
            closeRemoveModal={closeRemoveModal}
            lpBodyClick={lpBodyClick}
          />
        </>
      )}
      {isWSCConnected && (
        <LPModal
          wrapData={wrapData}
          isVisible={isWSCModalVisible}
          toggleModal={toggleWSCModal}
          wscModalRef={wscModalRef}
          closeWSCContinue={closeWSCContinue}
          skipWallet={skipWallet}
          cancelTransaction={cancelTransaction}
          openWalletOverview={openWalletOverview}
        />
      )}
      {openCustomTokenModal && !tokenModal ? (
        <>
          {" "}
          <CustomTokenModal
            userUnderstand={userUnderstand}
            selectCurrency={selectCurrency}
            customTokenRef={customTokenRef}
            closeTokenCustomModal={closeTokenCustomModal}
            closeCustomTokenModalWithBg={closeCustomTokenModalWithBg}
            closeCustomTokenModal={closeCustomTokenModal}
          />{" "}
        </>
      ) : null}
      {tokenModal && (
        <>
          {" "}
          <TokensModal
            // tokenList={tangleswapTokenListOnChain}
            // tokenBalance={tangleswapTokenListBalances}
            // tokenOpenModal={tokenModal}
            // activeField={activeField}
            // selectCurrency={selectCurrency}
            // selectedCurrency={selectedCurrency}
            // otherCurrency={otherCurrency}
            // modalRef={modalRef}
            // closeTokenModal={closeTokenModal}
            // closeModal={closeModal}
            activateCustomModal={activateCustomModal}
            tokenOpenModal={tokenModal}
            activeField={activeField}
            selectCurrency={selectCurrency}
            selectedCurrency={selectedCurrency}
            otherCurrency={otherCurrency}
            modalRef={modalRef}
            closeTokenModal={closeTokenModal}
            closeModal={closeModal}
          />
        </>
      )}

      <BodyWrapper ref={bodyRef}>
        <LedgerContext />
        <Body ref={swapRef} userTradingMode={Boolean(userTradingMode) === true}>
          <LiquidityCover
            rangeWarningDisabled={priceRangeTokenDisabledWarning}
            ref={swapRef}
          >
            <SwapTop layoutPro={Boolean(userTradingMode) === true}>
              <LPBack>
                {" "}
                <Link to="/pools">
                  <BackIcon />
                </Link>
              </LPBack>

              <DescWrapper>
                {" "}
                <SwapDescription layoutPro={Boolean(userTradingMode) === true}>
                  {" "}
                  {poolNeedsInitialization ? "Initialize" : "Add"} {""}{" "}
                  Liquidity
                </SwapDescription>
              </DescWrapper>
              <LPRight>
                <ReverseTokenOrder
                  currencyA={
                    isSameBlockhainOrder
                      ? currencies[Field.INPUT]?.symbol
                      : currencies[Field.OUTPUT]?.symbol
                  }
                  currencyB={
                    isSameBlockhainOrder
                      ? currencies[Field.OUTPUT]?.symbol
                      : currencies[Field.INPUT]?.symbol
                  }
                  handleRateToggle={handleTokenToggle}
                  selectedTokenSymbol={selectedToken}
                />
                <SlippageContainer
                  slippageTop={56}
                  showSlippage={showSlippage}
                  slippageRef={slippageRef}
                />

                <SettingsWrapper ref={closeRef} onClick={closeSlippageDropdown}>
                  <SwapSettingsIcon onClick={showSlippageDropdown} />
                </SettingsWrapper>
              </LPRight>
            </SwapTop>
            <DappsDivider />
            <LPWrapper userTradingMode={Boolean(userTradingMode) === true}>
              <LPWrapLeft marginUp={Boolean(token1Selected)}>
                {/* Initialize LP start*/}
                {Boolean(pairInit) ? (
                  <PairInitialize
                    onUserInput={handlePairOutput}
                    value={pairTypedValue}
                    proMode={Boolean(userTradingMode)}
                    name="pairValue"
                  />
                ) : null}
                {/* Initialize LP end*/}
                {token0Selected && Boolean(token1Selected) ? (
                  <LPPriceCover>
                    <LPPrice
                      loadingEndInit={Boolean(tierDisabled)}
                      tierNull={tierAllNull}
                      poolNeedsInitialization={poolNeedsInitialization}
                      inputPrice={priceValue}
                      inputCurrency={currencies[Field.INPUT]?.symbol}
                      outputCurrency={currencies[Field.OUTPUT]?.symbol}
                    />
                  </LPPriceCover>
                ) : null}

                <LPTiers uiGuideAbled={!token1Selected}>
                  <LiquidTier
                    currentPrice={debouncedCurrentPrice}
                    number={Number(debouncedSlugValueAmount) / 10000}
                    percent={slugFeePercent}
                    isOutputTokenInValid={isOutputTokenInValid}
                    isInputTokenInValid={isInputTokenInValid}
                    onClick={tierClicked}
                    editActive={tierActive}
                    percentDisabled={!debouncedSlugValueAmount}
                    poolInitialized={poolNeedsInitialization}
                    selectUndefined={
                      debouncedSlugValueAmount === undefined ||
                      debouncedSlugValueAmount === null
                    }
                    disabled={!token1Selected}
                  />
                  {tierActive ? (
                    <>
                      <TierGrid>
                        {tierData.map((tier: TierProps) => (
                          <TiersBox
                            handleFeePoolSelect={handleFeePoolSelect}
                            title={tier.title}
                            key={tier.id}
                            text={tier.text}
                            number={tier.number}
                            selected={
                              tierAllNull || poolNeedsInitialization
                                ? Number(tier.title) ===
                                  Number(debouncedSlugValueAmount) / 10000
                                : Number(tier.title) ===
                                  Number(debouncedSlugValueAmount) / 10000
                            }
                          />
                        ))}
                      </TierGrid>
                    </>
                  ) : null}
                </LPTiers>

                <SwapInputCover>
                  <LPDescription>Deposit Amounts</LPDescription>

                  <SwapTokenDiv
                    uiGuideAbled={Boolean(
                      SwapTokenDisabled || !swapInputAllowed
                    )}
                  >
                    <AddTokenContainer
                      inputDisabled={!swapInputAllowed}
                      bothTokensDisabled={debounceBothInputDisabled}
                      tokenBalance={tangleswapTokenListBalances}
                      inputTokenRangeDisabled={token0InputDisabled}
                      balanceHidden={token0Selected}
                      showMaxButton={showInputMaxButton}
                      onMax={handleMaxInput}
                      fiatValue={token0USDFiat}
                      tokenselected={token0Selected}
                      maxButtonDisabled={SwapTokenDisabled || !swapInputAllowed}
                      tokenAddress={currencies[Field.INPUT]?.address}
                      tokenImage={currencies[Field.INPUT]?.logoURI}
                      tokenSymbol={currencies[Field.INPUT]?.symbol}
                      tokenName={currencies[Field.INPUT]?.name}
                      currency={currencies[Field.INPUT]?.address}
                      onUserInput={handleTypeInput}
                      value={
                        (typedValue === "" &&
                          String(formattedAmounts[Field.INPUT]) === "0") ||
                        formattedAmounts[Field.INPUT] === "NaN" ||
                        (independentField === Field.OUTPUT &&
                          !token1Disabled &&
                          Number(typedValue) === parseInt("0"))
                          ? ""
                          : formattedAmountsConvert[Field.INPUT]
                      }
                      onCurrencySelect={() =>
                        handleInputSelect(
                          currencies[Field.INPUT]?.address,
                          Field.INPUT,
                          currencies[Field.OUTPUT]?.address
                        )
                      }
                      disabled={
                        account === undefined ||
                        account === null ||
                        (Boolean(poolNeedsInitialization) &&
                          pairTypedValue === "") ||
                        !token1Selected
                      }
                      otherCurrency={currencies[Field.OUTPUT]?.address}
                      id="lpCurrencyInput"
                    />

                    <AddTokenContainer
                      bothTokensDisabled={debounceBothInputDisabled}
                      tokenBalance={tangleswapTokenListBalances}
                      onUserInput={handleTypeOutput}
                      fiatValue={token1USDFiat}
                      inputDisabled={!swapInputAllowed}
                      maxButtonDisabled={SwapTokenDisabled || !swapInputAllowed}
                      value={
                        (typedValue === "" &&
                          String(formattedAmounts[Field.OUTPUT]) === "0") ||
                        formattedAmounts[Field.OUTPUT] === "NaN" ||
                        (independentField === Field.INPUT &&
                          !debounceToken0Disabled &&
                          Number(typedValue) === parseInt("0"))
                          ? ""
                          : formattedAmountsConvert[Field.OUTPUT]
                      }
                      inputTokenRangeDisabled={token1InputDisabled}
                      showMaxButton={showOutputMaxButton}
                      onMax={handleMaxOutput}
                      tokenAddress={currencies[Field.OUTPUT]?.address}
                      tokenImage={currencies[Field.OUTPUT]?.logoURI}
                      tokenSymbol={currencies[Field.OUTPUT]?.symbol}
                      tokenName={currencies[Field.OUTPUT]?.name}
                      currency={currencies[Field.OUTPUT]?.address}
                      onCurrencySelect={() =>
                        handleOutputSelect(
                          currencies[Field.OUTPUT]?.address,
                          Field.OUTPUT,
                          currencies[Field.INPUT]?.address
                        )
                      }
                      otherCurrency={currencies[Field.OUTPUT]?.address}
                      balanceHidden={token1Selected}
                      tokenselected={token1Selected}
                      id="lpCurrencyOutput"
                      disabled={
                        account === undefined ||
                        (Boolean(poolNeedsInitialization) &&
                          pairTypedValue === "") ||
                        !token1Selected
                      }
                    />
                  </SwapTokenDiv>
                  {!!disableVoid ? (
                    <>
                      <VoidErrorInfo />
                    </>
                  ) : null}
                  <LPBottom>
                    <SwapButtons
                      uiGuideAbled={
                        !token1Selected
                        // (poolNeedsInitialization && pairTypedValue === "")
                      }
                    >
                      <>
                        {" "}
                        {account ? (
                          <>
                            {/* button starts */}
                            <>
                              {account !== undefined && !token1Selected ? (
                                <>
                                  {" "}
                                  <>
                                    {/* invalid pair button starts */}
                                    <>
                                      <SwapButton disabled={!token1Selected}>
                                        Invalid Pair
                                      </SwapButton>
                                    </>
                                    {/* invalid pair button ends */}
                                  </>
                                </>
                              ) : (
                                <>
                                  {/* pool initialized logic start */}

                                  <>
                                    {poolNeedsInitialization &&
                                    token1Selected ? (
                                      <>
                                        {pairTypedValue === "" ? (
                                          <>
                                            {" "}
                                            <SwapButton disabled={true}>
                                              Set a Starting Price
                                            </SwapButton>
                                          </>
                                        ) : (
                                          <>
                                            {typedValue === "" ||
                                            typedValue === "0" ||
                                            (independentField === Field.INPUT &&
                                              !debounceToken0Disabled &&
                                              Number(
                                                formattedAmounts[Field.INPUT]
                                              ) === parseInt("0")) ||
                                            (independentField ===
                                              Field.OUTPUT &&
                                              !token1Disabled &&
                                              Number(
                                                formattedAmounts[Field.OUTPUT]
                                              ) === parseInt("0")) ? (
                                              <>
                                                {" "}
                                                <SwapButton disabled={true}>
                                                  Enter an Amount
                                                </SwapButton>
                                              </>
                                            ) : (
                                              <>
                                                <>
                                                  {Boolean(
                                                    token0CompareBoolean
                                                  ) ||
                                                  Boolean(
                                                    token1CompareBoolean
                                                  ) ? (
                                                    <>
                                                      {Boolean(
                                                        token0CompareBoolean
                                                      ) ? (
                                                        <>
                                                          <SwapButton
                                                            disabled={true}
                                                          >
                                                            <>
                                                              Insufficient{" "}
                                                              {
                                                                currencies[
                                                                  Field.INPUT
                                                                ]?.symbol
                                                              }{" "}
                                                              Balance
                                                            </>
                                                          </SwapButton>
                                                        </>
                                                      ) : null}
                                                      {Boolean(
                                                        token1CompareBoolean
                                                      ) ? (
                                                        <>
                                                          <SwapButton
                                                            disabled={true}
                                                          >
                                                            <>
                                                              Insufficient{" "}
                                                              {
                                                                currencies[
                                                                  Field.OUTPUT
                                                                ]?.symbol
                                                              }{" "}
                                                              Balance
                                                            </>
                                                          </SwapButton>
                                                        </>
                                                      ) : null}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {(displayApprovalOne &&
                                                        !token0Approved &&
                                                        !debounceToken0Disabled) ||
                                                      (displayApprovalTwo &&
                                                        !token1Approved &&
                                                        !token1Disabled) ? (
                                                        <>
                                                          {/* approval button starts here */}
                                                          <ApproveButtonWrapper>
                                                            {displayApprovalOne &&
                                                              !token0Approved &&
                                                              !debounceToken0Disabled &&
                                                              isOutputTokenInValid && (
                                                                <>
                                                                  <ApproveButton
                                                                    onClick={
                                                                      approveInputToken
                                                                    }
                                                                    disabled={Boolean(
                                                                      token0Approving
                                                                    )}
                                                                  >
                                                                    {token0Approving ? (
                                                                      <>
                                                                        Approving{" "}
                                                                        {
                                                                          currencies[
                                                                            Field
                                                                              .INPUT
                                                                          ]
                                                                            ?.symbol
                                                                        }
                                                                        <Dots />
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        Approve{" "}
                                                                        {
                                                                          currencies[
                                                                            Field
                                                                              .INPUT
                                                                          ]
                                                                            ?.symbol
                                                                        }
                                                                      </>
                                                                    )}
                                                                  </ApproveButton>
                                                                </>
                                                              )}
                                                            {displayApprovalTwo &&
                                                              !token1Approved &&
                                                              !token1Disabled &&
                                                              isOutputTokenInValid && (
                                                                <>
                                                                  <ApproveButton
                                                                    onClick={
                                                                      approveOutputToken
                                                                    }
                                                                    disabled={Boolean(
                                                                      token1Approving
                                                                    )}
                                                                  >
                                                                    {token1Approving ? (
                                                                      <>
                                                                        Approving{" "}
                                                                        {
                                                                          currencies[
                                                                            Field
                                                                              .OUTPUT
                                                                          ]
                                                                            ?.symbol
                                                                        }
                                                                        <Dots />
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        Approve{" "}
                                                                        {
                                                                          currencies[
                                                                            Field
                                                                              .OUTPUT
                                                                          ]
                                                                            ?.symbol
                                                                        }
                                                                      </>
                                                                    )}
                                                                  </ApproveButton>
                                                                </>
                                                              )}
                                                          </ApproveButtonWrapper>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {" "}
                                                          <SwapButton
                                                            disabled={
                                                              !!disableVoid ||
                                                              disableButton ||
                                                              debounceBothInputDisabled ||
                                                              // to avoid pseudo-infinity prices, do not allow single-sided initialization:
                                                              debounceToken0Disabled ||
                                                              token1Disabled
                                                            }
                                                            blockTransactionState={
                                                              !!blockTransactionState
                                                            }
                                                            onClick={
                                                              isWSCConnected
                                                                ? !!blockTransactionState
                                                                  ? handlePendingTxModal
                                                                  : () =>
                                                                      fireWSCModal()
                                                                : sendTransaction
                                                            }
                                                          >
                                                            {!!blockTransactionState ? (
                                                              <>
                                                                <SwapButtonSpinner />
                                                              </>
                                                            ) : null}
                                                            {!!blockTransactionState ? (
                                                              <>
                                                                Continue Pending
                                                                Transaction
                                                              </>
                                                            ) : (
                                                              <>
                                                                {" "}
                                                                Initialize Pair
                                                              </>
                                                            )}
                                                          </SwapButton>
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <>
                                          {typedValue === "" ||
                                          typedValue === "0" ||
                                          (Number(
                                            formattedAmounts[Field.INPUT]
                                          ) === parseInt("0") &&
                                            !debounceToken0Disabled) ||
                                          (Number(
                                            formattedAmounts[Field.OUTPUT]
                                          ) === parseInt("0") &&
                                            !token1Disabled) ||
                                          Boolean(disableButton) ? (
                                            <>
                                              {" "}
                                              <SwapButton disabled={true}>
                                                Enter an Amount
                                              </SwapButton>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <>
                                                {Boolean(
                                                  token0CompareBoolean
                                                ) ||
                                                Boolean(
                                                  token1CompareBoolean
                                                ) ? (
                                                  <>
                                                    {Boolean(
                                                      token0CompareBoolean
                                                    ) && (
                                                      <>
                                                        <SwapButton
                                                          disabled={true}
                                                        >
                                                          <>
                                                            Insufficient{" "}
                                                            {
                                                              currencies[
                                                                Field.INPUT
                                                              ]?.symbol
                                                            }{" "}
                                                            Balance
                                                          </>
                                                        </SwapButton>
                                                      </>
                                                    )}
                                                    {Boolean(
                                                      token1CompareBoolean
                                                    ) && (
                                                      <>
                                                        <SwapButton
                                                          disabled={true}
                                                        >
                                                          <>
                                                            Insufficient{" "}
                                                            {
                                                              currencies[
                                                                Field.OUTPUT
                                                              ]?.symbol
                                                            }{" "}
                                                            Balance
                                                          </>
                                                        </SwapButton>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {(displayApprovalOne &&
                                                      !token0Approved &&
                                                      !debounceToken0Disabled) ||
                                                    (displayApprovalTwo &&
                                                      !token1Approved &&
                                                      !token1Disabled) ? (
                                                      <>
                                                        {/* approval button starts here */}
                                                        <ApproveButtonWrapper>
                                                          {displayApprovalOne &&
                                                            !token0Approved &&
                                                            !debounceToken0Disabled &&
                                                            isOutputTokenInValid && (
                                                              <>
                                                                <ApproveButton
                                                                  onClick={
                                                                    approveInputToken
                                                                  }
                                                                  disabled={Boolean(
                                                                    token0Approving
                                                                  )}
                                                                >
                                                                  {token0Approving ? (
                                                                    <>
                                                                      Approving{" "}
                                                                      {
                                                                        currencies[
                                                                          Field
                                                                            .INPUT
                                                                        ]
                                                                          ?.symbol
                                                                      }
                                                                      <Dots />{" "}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      Approve{" "}
                                                                      {
                                                                        currencies[
                                                                          Field
                                                                            .INPUT
                                                                        ]
                                                                          ?.symbol
                                                                      }
                                                                    </>
                                                                  )}
                                                                </ApproveButton>
                                                              </>
                                                            )}
                                                          {displayApprovalTwo &&
                                                            !token1Approved &&
                                                            !token1Disabled &&
                                                            isOutputTokenInValid && (
                                                              <>
                                                                <ApproveButton
                                                                  onClick={
                                                                    approveOutputToken
                                                                  }
                                                                  disabled={Boolean(
                                                                    token1Approving
                                                                  )}
                                                                >
                                                                  {token1Approving ? (
                                                                    <>
                                                                      Approving{" "}
                                                                      {
                                                                        currencies[
                                                                          Field
                                                                            .OUTPUT
                                                                        ]
                                                                          ?.symbol
                                                                      }
                                                                      <Dots />
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      Approve{" "}
                                                                      {
                                                                        currencies[
                                                                          Field
                                                                            .OUTPUT
                                                                        ]
                                                                          ?.symbol
                                                                      }
                                                                    </>
                                                                  )}
                                                                </ApproveButton>
                                                              </>
                                                            )}
                                                        </ApproveButtonWrapper>
                                                      </>
                                                    ) : (
                                                      // preview start
                                                      <>
                                                        {" "}
                                                        <SwapButton
                                                          onClick={
                                                            !!isWSCConnected &&
                                                            !!blockTransactionState
                                                              ? handlePendingTxModal
                                                              : sendTransaction
                                                          }
                                                          blockTransactionState={
                                                            !!blockTransactionState
                                                          }
                                                          disabled={
                                                            !!disableVoid ||
                                                            previewDisabled ||
                                                            !account ||
                                                            !isSupportedNetwork
                                                          }
                                                        >
                                                          {!!blockTransactionState ? (
                                                            <>
                                                              <SwapButtonSpinner />
                                                            </>
                                                          ) : null}
                                                          {!!blockTransactionState ? (
                                                            <>
                                                              Continue Pending
                                                              Transaction
                                                            </>
                                                          ) : (
                                                            <>Preview</>
                                                          )}
                                                        </SwapButton>
                                                      </>
                                                      // preview ends
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            </>
                                          )}
                                        </>
                                      </>
                                    )}
                                  </>

                                  {/* pool initialized logic ends */}
                                </>
                              )}
                            </>
                            {/* button ends */}
                          </>
                        ) : (
                          <>
                            {!isSupportedNetwork ? (
                              <>
                                <ChainButton
                                  onClick={() =>
                                    addChainToMetamask(defaultChainId)
                                  }
                                >
                                  Switch to Cardano
                                </ChainButton>
                              </>
                            ) : (
                              <>
                                <ConnectWalletButton />
                              </>
                            )}
                          </>
                        )}
                      </>
                    </SwapButtons>
                  </LPBottom>
                </SwapInputCover>
              </LPWrapLeft>
              <LPWrapRight
                uiGuideAbled={rangeAllowed}
                userTradingMode={Boolean(userTradingMode) === true}
              >
                {!token1Selected && <DisabledWrapper />}
                <PriceRange
                  poolNeedsInitialization={Boolean(poolNeedsInitialization)}
                  address0={address0}
                  address1={address1}
                  token0Decimals={currencies[Field.INPUT]?.decimals}
                  token1Decimals={currencies[Field.OUTPUT]?.decimals}
                  currencyA={currencyA}
                  currencyB={currencyB}
                  priceRangeTokenDisabledWarning={
                    priceRangeTokenDisabledWarning
                  }
                  closeEfficiency={closeEfficiency}
                  priceLower={minRealPrice}
                  priceUpper={maxRealPrice}
                  minUiTextPrice={minUiTextPrice}
                  maxUiTextPrice={maxUiTextPrice}
                  token0Disabled={debounceToken0Disabled}
                  token1Disabled={token1Disabled}
                  interactive={Boolean(!poolNeedsInitialization)}
                  price={priceValue}
                  feeAmount={Number(slugFeeAmount)}
                  poolAddress={_poolAddress}
                  poolLiquidity={_poolLiquidity}
                  slot0={_slot0}
                  disabledByBothTokens={debounceBothInputDisabled}
                  onLeftRangeInput={onLeftRangeInput}
                  onRightRangeInput={onRightRangeInput}
                  acceptEfficiency={setFullRangeZeroToInfinity}
                  userInputMaxPrice={maxRangeInputValue}
                  userInputMinPrice={minRangeInputValue}
                  fullRangeClick={fullRangeClick}
                  minDecrement={minDecrement}
                  minIncrement={minIncrement}
                  maxDecrement={maxDecrement}
                  maxIncrement={maxIncrement}
                  efficiency={efficiency}
                  tokenA={currencies[Field.INPUT]?.symbol}
                  tokenB={currencies[Field.OUTPUT]?.symbol}
                />
              </LPWrapRight>
            </LPWrapper>
          </LiquidityCover>
        </Body>
      </BodyWrapper>
    </>
  )
}

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 4px !important;
`
const Body = styled.div<TradingModeProps>`
  width: 100%;
  display: flex;

  margin: 0 auto;
  max-width: ${(props) =>
    props.userTradingMode ? `960px !important` : "618px !important"};
  position: relative;
  z-index: 3;
`
const LiquidityCover = styled.div<LPCoverProps>`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  background: ${TangleColors.swapBG};
  border-radius: 40px;
  /* padding: 0 20px 0 20px; */
  padding: ${(props) =>
    props.rangeWarningDisabled ? `0px 20px 35px 20px` : `0px 20px 0 20px`};

  min-height: 650px;
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.lighthover};
`
/* padding: 0 20px 0 20px; */
const SwapTop = styled.div<MarginProps>`
  width: 100%;

  display: grid;
  margin: 0 0 -12px 0;

  grid-template-columns: repeat(3, 1fr);
`
const LPRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  position: relative;
  max-width: 250px;
  justify-self: flex-end;
  /* align-self: flex-end; */
`

const SwapDescription = styled(Header5Gothic)<MarginProps>`
  margin: 0;
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
`

const DescWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 150px;
  justify-content: center;
  align-items: center;
  color: ${TangleColors.white};
  white-space: nowrap;
  margin: 24px auto;
  text-align: center;
`
const LPWrapper = styled.div<TradingModeProps>`
  width: 100%;
  display: grid;
  grid-gap: 12px;
  gap: 12px;

  grid-template-columns: ${(props) =>
    props.userTradingMode ? `repeat(2, 1fr)` : `repeat(1, 1fr)`};

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const LPWrapLeft = styled.div<MarginProps>`
  margin: ${(props) => (props.marginUp ? `24px 0 0 0` : `8px 0 0 0`)};
`

const LPPriceCover = styled.div`
  margin: -4px 0 0 0;
`

const SwapSettingsIcon = styled(RiSettings2Line)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  border-radius: 0px;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const SwapTokenDiv = styled.div<GuideValues>`
  width: 100%;
  position: relative;
  opacity: ${(props) => (props.uiGuideAbled ? "0.1" : "1")};
`

const SwapButtons = styled.div<GuideValues>`
  width: 100%;
  margin: 40px 0;
  position: relative;
  z-index: 9;
  opacity: ${(props) => (props.uiGuideAbled ? "0.1" : "1")};
  .tokenBalanceIncompete {
    background: ${TangleColors.balanceColor} !important;
    color: ${TangleColors.white} !important;
  }
`
const ConnectButton = styled(SwapConnectButton)`
  position: relative;
  z-index: 9;
`
const ChainButton = styled(ChainChangeButton)`
  position: relative;
  z-index: 9;
`
const ApproveButton = styled(SwapTokenButton)`
  background: ${TangleColors.heroCTA};
  margin: 10px 0;
  position: relative;
  z-index: 9;
`

const ApproveButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SwapButton = styled(SwapTokenButton)`
  background: ${TangleColors.heroCTA};
  margin: 10px 0;
`

const LPBack = styled.div`
  max-width: 40px;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
`
const BackIcon = styled(BsArrowLeft)`
  height: 32px;
  width: 32px;
  margin: 0 4px 0 0;
  cursor: pointer;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const LPDescription = styled(Subtitle)`
  color: ${TangleColors.grayDark};
`
const LPTiers = styled.div<GuideValues>`
  position: relative;
  margin: 16px 0 0 0;
  opacity: ${(props) => (props.uiGuideAbled ? "0.1" : "1")};
`

const LPBottom = styled.div`
  position: relative;
`
const DisabledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background: #120d18;

  opacity: 0.6;
`

const TierGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin: 0 0 28px 0;
`
const LPWrapRight = styled.div<TradingModeProps & GuideValues>`
  display: ${(props) => (props.userTradingMode ? `flex` : `none`)};
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0 0 0 26px;
  opacity: ${(props) => (props.uiGuideAbled ? "1" : "0.1")};
`

const SwapInputCover = styled.div<TradingModeProps & GuideValues>`
  flex-direction: column;
  position: relative;
  overflow: hidden;

  opacity: 1;
`

const SettingsWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: ${TangleColors.white};
`

export default React.memo(AddLiquidContainer)
