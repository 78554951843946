import React, { FC, useMemo } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall, Header5, Subtitle } from "styles/TextStyles"
import { countrySelectedName } from "../utils/formatNumber"
import countryList from "react-select-country-list"
interface AboutProps {
  defaultInfo?: any
  description?: string
  bannedWarning?: string
  bannedCountries?: string
  active?: boolean
}
interface ProjectProps {
  textColor?: string
}
const AboutProjectTab: FC<AboutProps> = (props) => {
  const { description, bannedWarning, bannedCountries, active } = props

  const countryListOptions = useMemo(() => countryList().getData(), [])
  return (
    <>
      {!!bannedCountries || !!description || !!bannedWarning ? (
        <>
          <Container active={active}>
            {description && <Title>{description}</Title>}
            {bannedCountries && (
              <DefaultInfo>
                <TitleText>
                  The following countries are banned from participation
                </TitleText>
                <CountryListTab>
                  {JSON.parse(bannedCountries)?.map((data, index: number) => (
                    <CountryListCover>
                      <CountryBodyText key={index}>
                        {countrySelectedName(data, countryListOptions)?.label}
                      </CountryBodyText>
                    </CountryListCover>
                  ))}
                </CountryListTab>
              </DefaultInfo>
            )}
            {bannedWarning && (
              <DefaultInfo>
                <BodyText>{bannedWarning}</BodyText>
              </DefaultInfo>
            )}
          </Container>
        </>
      ) : null}
    </>
  )
}

const Container = styled.div<AboutProps>`
  margin-top: 32px;
  display: ${(props) => (props.active ? `none` : `flex`)};
  flex-direction: column;
`

const Title = styled(Header5)<ProjectProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  color: ${TangleColors.white};
`

const DefaultInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`

const TitleText = styled(Caption)`
  color: ${TangleColors.grayDark};
  font-style: italic;
  margin: 0 0 4px 0;
`
const CountryListTab = styled.ul``
const CountryListCover = styled.li`
  list-style-type: disc;
  color: ${TangleColors.grayDark};
  margin: 4px 0 4px 12px;
`
const CountryBodyText = styled(CaptionSmall)`
  color: ${TangleColors.tangleGray};
  font-style: italic;
  margin: 4px 0;
`
const BodyText = styled(CaptionSmall)`
  color: ${TangleColors.offWhite};
  font-style: italic;
  margin: 4px 0;
`
export default AboutProjectTab
