import { configureStore } from "@reduxjs/toolkit"
import modalReducer from "store/reducer/modalReducer"

import { load } from "redux-localstorage-simple"
import multicall from "state/multicall/reducer"
import staking from "state/staking/reducer"
import IncreaseLiquidityReducer from "store/reducer/IncreaseLiquidityReducer"
import LiquidityReducer from "store/reducer/LiquidityReducer"
import PairReducer from "store/reducer/PairReducer"
import RangeReducer from "store/reducer/RangeReducer"
import SettingsReducer from "store/reducer/SettingsReducer"
import swapReducer from "store/reducer/SwapReducer"
import blockNumberReducer from "./reducer/BlockReducer"
import tokenBalanceReducer from "./reducer/TokenBalanceReducer"
import tokenListReducer from "./reducer/TokenListReducer"
import TradingReducer from "./reducer/TradingReducer"
import SiteWarningReducer from "./reducer/SiteWarningReducer"
import unKnownTokenReducer from "./reducer/UnknownTokenReducer"
import unKnownTokenFieldReducer from "./reducer/UnknownTokenFieldReducer"
import TransactionHistoryReducer from "./reducer/TransactionHistoryReducer"
import FarmsTLPReducer from "./reducer/FarmsTLPReducer"
import SelectedWalletReducer from "./reducer/SelectedWalletReducer"
import TokenDecimalReducer from "./reducer/TokenDecimalReducer"
import farmDataReducer from "./reducer/FarmDataReducer"
import auctionDataReducer from "./reducer/auctionDataReducer"
import MobileNavReducer from "./reducer/MobileNavReducer"
import UserReferralReducer from "./reducer/UserReferralReducer"
import AuctionToggleReducer from "./reducer/AuctionToggleReducer"
import WscProgressReducer from "./reducer/WscProgressReducer"
import CardanoSettingsReducer from "./reducer/CardanoSettingsReducer"
import VoidTradingReducer from "./reducer/VoidTradingReducer"
import StakingToggleReducer from "./reducer/StakingAgreementReducer"

type MergedState = {
  histories: {
    [key: string]: any
  }
}

const PERSISTED_KEYS: string[] = []
const loadedState = load({ states: PERSISTED_KEYS }) as MergedState
const tempState: any = loadedState

export const tsStore = configureStore({
  reducer: {
    auctionDataReducer: auctionDataReducer,
    AuctionToggle: AuctionToggleReducer,
    StakingToggle: StakingToggleReducer,
    blockNumberReducer: blockNumberReducer,
    swap: swapReducer,
    increaseliquidity: IncreaseLiquidityReducer,
    liquidity: LiquidityReducer,
    pairReducer: PairReducer,
    farmDataReducer: farmDataReducer,
    FarmsTLPReducer: FarmsTLPReducer,
    rangeReducer: RangeReducer,
    siteWarning: SiteWarningReducer,
    settings: SettingsReducer,
    staking,
    tokenList: tokenListReducer,
    tangleDecimalList: TokenDecimalReducer,
    transactionHistory: TransactionHistoryReducer,
    tokenBalance: tokenBalanceReducer,
    tradingMode: TradingReducer,
    multicall,
    modal: modalReducer,
    MobileNavReducer: MobileNavReducer,
    WscProgressReducer: WscProgressReducer,
    wallet: SelectedWalletReducer,
    UserReferralReducer: UserReferralReducer,
    unKnownTokenReducer: unKnownTokenReducer,
    unKnownTokenFieldReducer: unKnownTokenFieldReducer,
    CardanoSettings: CardanoSettingsReducer,
    VoidTradingReducer: VoidTradingReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: tempState,
})

export type AppState = ReturnType<typeof tsStore.getState>
export type AppDispatch = typeof tsStore.dispatch
