import SpinnerCombined from "components/confirmation/SpinnerCombined"
import React from "react"
import styled from "styled-components"

const SwapButtonSpinner = () => {
  return (
    <SpinnerCover>
      {" "}
      <SpinnerCombined
        bigRadius={28}
        smallRadius={20}
        height={32}
        width={32}
        color="#fff"
      />
    </SpinnerCover>
  )
}

const SpinnerCover = styled.div`
  margin: 32px 22px 0 0;
`

export default SwapButtonSpinner
