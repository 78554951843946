import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import { FC, useContext, useEffect, useState } from "react"

import { Caption, CaptionSmall } from "styles/TextStyles"
import VoidLogo from "assets/void.png"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import StakeContext from "./StakeContext"
import { Add } from "iconsax-react"
import PositionsLoadingPlaceholder from "components/PositionsLoadingPlaceholder"
import { AiOutlineInfoCircle } from "react-icons/ai"
import Tooltip from "components/utils/Tooltip"
interface InfoProps {
  stakingLoading?: boolean
}
const InfoRow: FC<InfoProps> = (props) => {
  const { stakingLoading } = props
  const { reward, voidEnergy, voidApy, tvl, avgLockTime }: any =
    useContext(StakeContext)

  return (
    <Wrapper>
      {stakingLoading ? (
        <>
          <PositionsLoadingPlaceholder />
        </>
      ) : (
        <>
          <InfoContainer>
            <InfoImage src={VoidLogo} alt="ve" height={40} width="auto" />
            <InfoText>VOID</InfoText>
          </InfoContainer>
          <RewardContainer>
            <RewardText>You earned</RewardText>
            {/* <Tooltip
              text={
                <span>
                  💡 Pro Tip: To collect your VOID rewards before the stake
                  period expires (e.g. to auto-compound, or as passive income),
                  submit below a transaction to Add More VOID or to Add
                  Duration!
                </span>
              }
            >
              <HelpIcon />
            </Tooltip> */}
            <RewardValue>
              <VoidNumber>{formatNumber(reward ?? 0, 3)}</VoidNumber>
              <VoidTextValue>VOID</VoidTextValue>
            </RewardValue>
            <InfoPlus size={14} />
            <VoidValue>
              <VoidNumber> {formatNumber(voidEnergy ?? 0, 3)}</VoidNumber>{" "}
              <VoidTextValue>VE</VoidTextValue>
            </VoidValue>
          </RewardContainer>
          <Container>
            <StakeInfoTitle>VOID APY</StakeInfoTitle>
            <StakeInfoText>{formatNumber(voidApy ?? 0)}%</StakeInfoText>
          </Container>
          <Container>
            <StakeInfoTitle>TVL</StakeInfoTitle>
            <StakeInfoText>{`${formatNumber(tvl ?? 0, 3)} VOID`}</StakeInfoText>
          </Container>
          <Container>
            <StakeInfoTitle>Average Lock Time</StakeInfoTitle>
            <StakeInfoText>
              {formatNumber(avgLockTime ?? 0, 3)} days
            </StakeInfoText>
          </Container>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  padding: 24px 48px;
  position: relative;
  border-radius: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px 16px;
    gap: 24px;
    align-items: flex-start;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0 auto;
  }
`

const InfoImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 9999px;
  margin-right: 16px;
`

const InfoText = styled.div`
  font-weight: 600;
  color: ${TangleColors.white};
`

const RewardContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  background: ${TangleColors.dockBG};
  border: 1px solid ${TangleColors.lightPurple};
  padding: 18px 24px;
  border-radius: 16px;
  gap: 8px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const RewardText = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  text-transform: uppercase;
`
const RewardValue = styled(Caption)`
  color: ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`
const VoidValue = styled(Caption)`
  font-weight: 600;
  color: ${TangleColors.tangleYellow};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 4px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StakeInfoText = styled(Caption)`
  color: ${TangleColors.white};
  margin: 6px 0 0 0;
`
const VoidTextValue = styled(Caption)`
  margin: 0 0 0 6px;
`

const StakeInfoTitle = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
`
const VoidNumber = styled(Caption)`
  margin: 0 2px;
`

const InfoPlus = styled(Add)`
  color: ${TangleColors.white};
`

const HelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
`

export default InfoRow
