import {
  LEFT_RANGE_TYPED_VALUE,
  RIGHT_RANGE_TYPED_VALUE,
} from "store/actions/actionsTypes"
import { updateObject } from "store/utility"
export interface RangeProps {
  leftRangeTypedValue: any
  rightRangeTypedValue: any
}

const initialState: RangeProps = {
  leftRangeTypedValue: "",
  rightRangeTypedValue: "",
}

const rightRangeTypeInput = (state, action) => {
  return updateObject(state, {
    ...state,
    rightRangeTypedValue: action.rightRangeTypedValue,
  })
}
const leftRangeTypeInput = (state, action) => {
  return updateObject(state, {
    ...state,
    leftRangeTypedValue: action.leftRangeTypedValue,
  })
}

const RangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case RIGHT_RANGE_TYPED_VALUE:
      return rightRangeTypeInput(state, action)
    case LEFT_RANGE_TYPED_VALUE:
      return leftRangeTypeInput(state, action)
    default:
      return state
  }
}

export default RangeReducer
