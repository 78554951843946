import { UPDATE_AUCTION_AGREEMENT } from "store/actions/actionsTypes"
import { updateObject } from "store/utility"

export interface AutionToggleProps {
  tangleAuctionStatus: any
}
const initialState: AutionToggleProps = {
  tangleAuctionStatus: null,
}

const updateAuctionToggleStatus = (state: any, action: any) => {
  return updateObject(state, {
    tangleAuctionStatus: action.tangleAuctionStatus,
  })
}

const AuctionToggleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_AUCTION_AGREEMENT:
      return updateAuctionToggleStatus(state, action)
    default:
      return state
  }
}

export default AuctionToggleReducer
