import { FeeAmount, isInvalid } from "@tangleswap/sdk"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useWSCContext } from "context/MilkomedaContext"

import { useTangleship } from "utils/useTangleship"

export default function useTierArray(
  address0: any,
  address1: any,
  decimals0: number,
  decimals1: number
): {
  tierData: any
  tierMode: any
  tierValue: any
  tierAllNull: any
  tierLoading: any
  updatePoolLiquidities: any
} {
  const { chainId } = useWSCContext()

  const { tangleship } = useTangleship()
  const defaultType = Object.values(FeeAmount).reduce((acc, key) => {
    acc[key] = undefined
    return acc
  }, {}) as typeof FeeAmount

  const [liquidity0, setLiquidity0] = useState<typeof FeeAmount>(defaultType)
  const [liquidity1, setLiquidity1] = useState<typeof FeeAmount>(defaultType)
  const [percentage, setPercentage] = useState<typeof FeeAmount>(defaultType)

  const updatePoolLiquidities = useCallback(
    async (tier: number, refetch = false) => {
      if (
        isInvalid([address0, address1, FeeAmount, chainId])
        // || decimals0 === 0
        // || decimals1 === 0
      )
        return

      const storageKey0 = `poolTok0Liq${chainId}${address0}${address1}${tier}`
      const storageKey1 = `poolTok1Liq${chainId}${address0}${address1}${tier}`
      const val0 = sessionStorage.getItem(storageKey0)
      const val1 = sessionStorage.getItem(storageKey1)

      if (val0 !== null && val1 !== null && !refetch) {
        setLiquidity0((args: any) => ({
          ...args,
          [tier]: val0 === "null" ? null : Number(val0) / 10 ** decimals0,
        }))
        setLiquidity1((args: any) => ({
          ...args,
          [tier]: val1 === "null" ? null : Number(val1) / 10 ** decimals1,
        }))
        return
      }

      const liq0 = await tangleship?.getToken0Liquidity(
        address0,
        address1,
        tier
      )
      const liq1 = await tangleship?.getToken1Liquidity(
        address0,
        address1,
        tier
      )
      sessionStorage.setItem(storageKey0, String(liq0))
      sessionStorage.setItem(storageKey1, String(liq1))

      setLiquidity0((args: any) => ({
        ...args,
        [tier]: liq0 === null ? null : Number(liq0?._hex) / 10 ** decimals0,
      }))
      setLiquidity1((args: any) => ({
        ...args,
        [tier]: liq1 === null ? null : Number(liq1?._hex) / 10 ** decimals1,
      }))
    },
    [address0, address1, chainId, decimals0, decimals1, tangleship]
  )

  useEffect(() => {
    // Compute the % of liquidity that's currently deposited in each fee tier:
    for (const fee of Object.values(FeeAmount)) updatePoolLiquidities(fee)
  }, [updatePoolLiquidities])

  useEffect(() => {
    if (
      Object.values(liquidity0).some((value) => value === undefined) ||
      Object.values(liquidity1).some((value) => value === undefined)
    )
      return

    const totalLiq0 = (Object.values(liquidity0) as number[]).reduce(
      (acc, currentValue) => acc + currentValue,
      0
    )
    const totalLiq1 = (Object.values(liquidity1) as number[]).reduce(
      (acc, currentValue) => acc + currentValue,
      0
    )

    const percent0 = Object.fromEntries(
      Object.entries(liquidity0).map(([key, value]) => [
        key,
        totalLiq0 > 0 ? Number(value) / totalLiq0 : null,
      ])
    )

    const percent1 = Object.fromEntries(
      Object.entries(liquidity1).map(([key, value]) => [
        key,
        totalLiq1 > 0 ? Number(value) / totalLiq1 : null,
      ])
    )

    for (const fee of Object.values(FeeAmount)) {
      setPercentage((args: any) => ({
        ...args,
        [fee]:
          percent0[fee] !== null || percent1[fee] !== null
            ? Math.round(((percent0[fee] + percent1[fee]) / 2) * 100)
            : null,
      }))
    }
  }, [liquidity0, liquidity1])

  // We use tierMode to check if the tierData has been loaded yet
  const tierMode =
    isNaN(Number(percentage?.[FeeAmount.MEDIUM])) ||
    Number.isNaN(percentage?.[FeeAmount.MEDIUM]) ||
    percentage?.[FeeAmount.MEDIUM] === undefined

  const tierData = [
    {
      id: 1,
      text: "Best for stable pairs.",
      title: 0.05,
      number: percentage?.[FeeAmount.LOW],
    },
    {
      id: 2,
      text: "Best as default value.",
      title: 0.3,
      number: percentage?.[FeeAmount.MEDIUM],
    },
    {
      id: 3,
      text: "Best for volatile pairs.",
      title: 1,
      number: percentage?.[FeeAmount.HIGH],
    },
  ]

  const tierLoading = Object.values(percentage).some(
    (value) => value === undefined
  )

  const tierValue = Object.values(percentage).every(
    (value) => value === undefined || value === null
  )

  const tierAllNull = Object.values(percentage).every((value) => value === null)

  return {
    tierData,
    tierMode,
    tierValue,
    tierAllNull,
    tierLoading,
    updatePoolLiquidities,
  }
}
