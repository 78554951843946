import { useMemo } from "react"
import { useCallback } from "react"
import { clearNfts, fetchNfts, freezeNft, selectNft } from "./action"
import { NftList } from "./type"
import { Field } from "@tangleswap/sdk"
import { BigNumber } from "ethers"
import { ZERO } from "config/constants"
import { NFT_LIST, NFT_NAME } from "config/constants/nfts/nftList"
import { calculateInterest, getContract } from "utils/index"
import moment from "moment"
import type { AppState } from "store/store"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { TANGLE_HELPER_ADDRESS } from "@tangleswap/sdk"
import helperABI from "../../config/constants/abis/helper.json"
import { useWSCContext } from "context/MilkomedaContext"

import { useTangleship } from "utils/useTangleship"

function useStakingState(): AppState["staking"] {
  return useAppSelector((state) => state.staking)
}

export function useStakingActionHandlers(): {
  onFreezeNft: (field: Field) => void
  onNftSelection: (field: Field, nftId: number) => void
} {
  const dispatch = useAppDispatch()

  const onNftSelection = useCallback(
    (field: Field, nftId: number) => {
      dispatch(selectNft({ field, nftId }))
    },
    [dispatch]
  )

  const onFreezeNft = useCallback(
    (field: Field) => {
      dispatch(freezeNft({ field }))
    },
    [dispatch]
  )

  return {
    onFreezeNft,
    onNftSelection,
  }
}

export function useDerivedStakingInfo(slug?: string) {
  const {
    byId: nftById,
    ownedNftIds,
    [Field.INPUT]: { nftId: activeId },
    [Field.OUTPUT]: { nftId: stakedId },
  } = useStakingState()

  const nftIds = {
    [Field.INPUT]: activeId,
    [Field.OUTPUT]: stakedId,
  }

  // const { limit } = useContext(PendingContext)

  const { active, staked } = useMemo(() => {
    let nftActive = [],
      nftStaked = []
    ownedNftIds
      .filter((id) => nftById[id].name.toLowerCase().includes(slug || ""))
      .map((id) => {
        // eslint-disable-line
        if (nftById[id].stakeTime === null || nftById[id].stakeTime <= 0) {
          nftActive.push(nftById[id])
        } else {
          nftStaked.push(nftById[id])
        }
      })
    return {
      // active: nftActive.slice(0, limit[slug] ?? 9),
      // staked: nftStaked.slice(0, limit[slug] ?? 9)
      active: nftActive,
      staked: nftStaked,
    }
  }, [nftById, ownedNftIds, slug])

  const rewardsPerSlug = useMemo(() => {
    const slugs = ["palm", "floating", "tranquillity", "ascension", "night"]
    let rewards = {
      palm: ZERO,
      floating: ZERO,
      tranquillity: ZERO,
      ascension: ZERO,
      night: ZERO,
    }
    staked.map((nft) => {
      // eslint-disable-line
      const slug = slugs.filter((t: string) =>
        nft.name.toLowerCase().includes(t.toLowerCase())
      )[0]

      rewards = {
        ...rewards,
        [slug]: rewards[slug].add(
          calculateInterest(
            moment().utc().unix(),
            nft.stakeTime,
            nft.baseApy,
            nft.rewardPrincipal instanceof BigNumber
              ? nft.rewardPrincipal
              : ZERO,
            nft.interestClaimed instanceof BigNumber
              ? nft.interestClaimed
              : ZERO
          )
        ),
      }
    })
    return rewards
  }, [staked])

  return { active, nftIds, rewardsPerSlug, staked }
}

// export function useGetClaimableAmount(ids: number[]): BigNumber {
//   const { chainId } = useWSCContext()

//   const { tangleship } = useTangleship()

//   // const stakingContract = useStakingContract(chainId)
//   const [amount, setAmount] = useState<BigNumber>(ZERO)

//   useEffect(() => {
//     if (chainId && ids[0] !== 0) {
//       tangleship?.getNftClaimableAmount(ids).then((res) => setAmount(res))
//     } else {
//       setAmount(ZERO)
//     }
//   }, [chainId, ids])

//   return amount
// }

// export function useGetNftList() {
//   const dispatch = useAppDispatch()
//   /* const helperContract = useNFTHelperContract() */

//   const [helperContract, setHelperContract] = useState<any>(null)

//   useEffect(() => {
//     if (!chainId) return
//     setHelperContract(
//       getContract(TANGLE_HELPER_ADDRESS[chainId], chainId, helperABI)
//     )
//   }, [chainId])

//   const active = useMemo(async () => {
//     if (!helperContract || !account) return null
//     return await helperContract.walletOfOwner(account)
//   }, [helperContract, account])

//   const staked = useMemo(async () => {
//     if (!helperContract || !account) return null
//     return await helperContract.stakedOfOwner(account)
//   }, [helperContract, account])

//   useEffect(() => {
//     dispatch(clearNfts({}))
//   }, [account, dispatch])

//   if (active !== undefined) {
//     active[1]?.map((uri: string, index: number) => {
//       // eslint-disable-line
//       fetchURI(uri, active[0][index]).then((response) => {
//         const nft: NftList = {
//           [active[0][index]]: {
//             id: active[0][index].toNumber(),
//             name: response.name,
//             image: "",
//             baseApy: null,
//             stakeTime: null,
//             claimedTime: null,
//             lockPeriod: null,
//             rewardPrincipal: null,
//             interestClaimed: null,
//           },
//         }
//         dispatch(fetchNfts({ nftList: nft }))
//       })
//     })
//   }

//   if (staked !== undefined) {
//     staked[1]?.map((uri: string, index: number) => {
//       // eslint-disable-line
//       fetchURI(uri, staked[0][index]).then((response) => {
//         const nft: NftList = {
//           [staked[0][index]]: {
//             id: staked[0][index].toNumber(),
//             name: response.name,
//             image: response.image,
//             baseApy: staked[2][index].baseApy.toNumber(),
//             stakeTime: staked[2][index].stakeTime.toNumber(),
//             claimedTime: staked[2][index].claimedTime.toNumber(),
//             lockPeriod: staked[2][index].lockPeriodDays.toNumber(),
//             rewardPrincipal: staked[2][index].rewardPrincipal,
//             interestClaimed: staked[2][index].interestClaimed,
//           },
//         }
//         dispatch(fetchNfts({ nftList: nft }))
//       })
//     })
//   }
// }

// async function fetchURI(uri: string, id: number) {
//   const hash = uri.slice(-46)
//   return {
//     ...NFT_LIST[hash],
//     name: NFT_NAME[id - 1],
//   }
// }
