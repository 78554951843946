import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
interface CardProps {
  cardMargin?: any
  degree?: any
  image?: any
  className?: any
}
const HomeHeroCards: FC<CardProps> = (props) => {
  const { image, degree, cardMargin, className } = props
  return (
    <HeroCards className={className} degree={degree} cardMargin={cardMargin}>
      <HeroCardsImage src={image} alt="dapps hero" />
    </HeroCards>
  )
}

const HeroCards = styled.div<CardProps>`
  max-width: 400px;
  height: 470px;
  transform: ${(props) => (props.degree ? `rotate(${props.degree}deg)` : "0")};
  border-radius: 32px;
  background: ${TangleColors.tangle10};
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 0px 12px rgba(255, 255, 255, 0.3);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;

  margin: ${(props) =>
    props.cardMargin
      ? `${props.cardMargin}px 0 -${props.cardMargin}px 0`
      : "0"};
  transition: 0.4s ease-in;
  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;

    border-radius: 32px;
    background: ${TangleColors.tangle10};

    z-index: -1;
    transition: 0.4s ease-in;
  }
  /* :hover {
    transform: rotate(-4deg);
    position: relative;
    z-index: 2;
    ::before {
      transform: rotate(0deg);
    }
  } */
  @media only screen and (max-width: 850px) {
    height: 250px;
  }
`
const HeroCardsImage = styled.img`
  border-radius: 32px;
  object-fit: contain;
  width: 100%;
  height: 100%;
`
export default HomeHeroCards
