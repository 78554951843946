import React, { FC } from "react"
import styled from "styled-components"

import Spinner from "react-spinner-material"
interface SpinnerProps {
  width?: number
  height?: number
  bigRadius?: number
  smallRadius?: number
  color?: string
}
const SpinnerCombined: FC<SpinnerProps> = (props) => {
  const { width, height, bigRadius, color, smallRadius } = props
  return (
    <SpinnerWrapper height={height} width={width}>
      <SpinnerCover height={height} width={width}>
        <SpinnerTop>
          <Spinner
            radius={bigRadius ?? 72}
            color={color ?? "#D874FA"}
            stroke={2}
            visible={true}
          />
        </SpinnerTop>
        <SpinnerMidle>
          <Spinner
            radius={smallRadius ?? 48}
            color={color ?? "#D874FA"}
            stroke={2}
            visible={true}
          />
        </SpinnerMidle>
      </SpinnerCover>
    </SpinnerWrapper>
  )
}

const SpinnerCover = styled.div<SpinnerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${(props) => (props.width ? `${props.width}px` : `160px`)};
  height: ${(props) => (props.width ? `${props.height}px` : `160px`)};

  width: 100%;
  margin: 0 auto;
`
const SpinnerWrapper = styled.div<SpinnerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${(props) => (props.width ? `${props.width}px` : `160px`)};
  height: ${(props) => (props.width ? `${props.height}px` : `160px`)};
  width: 100%;
  margin: -32px auto;
`

const SpinnerTop = styled.div`
  position: absolute;

  transform: translate(-0%, -0%);

  max-width: 160px;
  height: 160px;
  width: 100%;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const SpinnerMidle = styled.div`
  position: absolute;
  /* top: 30%;
  left: 30%; */
  width: 100%;
  transform: translate(-0%, -0%);
  max-width: 140px;
  height: 140px;
  width: 100%;
  transform: rotate(-90deg);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export default SpinnerCombined
