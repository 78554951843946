import { axisBottom, ScaleLinear } from "d3"
import React, { useMemo } from "react"
import styled from "styled-components"
import AxisMain from "./AxisMain"
import { TangleColors } from "styles/ColorStyles"

const AxisBottom = ({
  xScale,
  innerHeight,
  offset = 0,
}: {
  xScale: ScaleLinear<number, number>
  innerHeight: number
  offset?: number
}) =>
  useMemo(
    () => (
      <StyledGroup transform={`translate(0, ${innerHeight + offset})`}>
        <AxisMain axisGenerator={axisBottom(xScale).ticks(6)} />
      </StyledGroup>
    ),
    [innerHeight, offset, xScale]
  )

const StyledGroup = styled.g`
  line {
    display: none;
  }

  text {
    background: ${TangleColors.lighthover};
    transform: translateY(5px);
  }
`

export default AxisBottom
