import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import LiquidityPositionsLoadingPlaceholder from "./LiquidityPositionsLoadingPlaceholder"

const LiquidityLoading = () => {
  return (
    <Body>
      <Cover>
        <LPNavigation>
          <LiquidityPositionsLoadingPlaceholder />
        </LPNavigation>
        <LPInfo>
          <InfoTop>
            {/* start of left box */}
            <LiquidityBox>
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
            </LiquidityBox>

            <LiquidityBox>
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
            </LiquidityBox>

            {/* end of right box */}
          </InfoTop>
          <InfoBottom>
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
          </InfoBottom>
        </LPInfo>
      </Cover>
    </Body>
  )
}

export default React.memo(LiquidityLoading)

const Body = styled.div`
  width: 100%;
  position: relative;
  z-index: 3;
`
const Cover = styled.div`
  max-width: 1056px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 3;
`

const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 80px;
  position: relative;
  z-index: 3;
`

const LPInfo = styled.div`
  width: 100%;
`
const InfoTop = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  gap: 32px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const InfoBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 450px;
  border: 1px solid ${TangleColors.lighthover};
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  margin: 32px 0;
  padding: 22.5px;
  position: relative;
  z-index: 3;
`

const LiquidityBox = styled.div`
  height: 412px;
  max-width: 512px;
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
  z-index: 3;
  border: 1px solid ${TangleColors.lighthover};

  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
`
