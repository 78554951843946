import { useWSCContext } from "context/MilkomedaContext"
import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

interface ToggleProps {
  isToggled?: boolean
  toggleOffButton?: (e: any) => void
  toggleOnButton?: (e: any) => void
  disabled?: boolean
}
const TangleToggle: FC<ToggleProps> = (props) => {
  const { isToggled, toggleOnButton, toggleOffButton, disabled } = props
  const { account } = useWSCContext()
  return (
    <>
      <StyleWrapper
        // disabled={disabled}
        onClick={isToggled ? toggleOffButton : toggleOnButton}
      >
        <button
          disabled={!account || disabled}
          className={isToggled ? "on" : "off"}
        >
          <span />
        </button>
      </StyleWrapper>
    </>
  )
}

const StyleWrapper = styled.div`
  height: 32px;
  width: 62px;
  transition: 0.4s ease-in;
  border-radius: 100px;
  outline: none !important;

  border: 0.5px solid transparent !important;
  cursor: pointer;
  :hover {
    border: 0.5px solid ${TangleColors.white};
    outline: none !important;
  }
  &:hover button {
    border: 0.5px solid ${TangleColors.white};
    outline: none !important;
    background: ${TangleColors.black} !important;
  }
  &:hover .off span {
    background: ${TangleColors.white} !important;
  }
  &:hover .on span {
    background: ${TangleColors.cta} !important;
  }
  button {
    height: 32px;
    width: 62px;
    cursor: pointer;
    border-radius: 100px;

    position: relative;
    background: ${TangleColors.tangleBlack};
    outline: none !important;
    border: 0.5px solid transparent;
    transition: 0.4s ease-in;
    :disabled {
      background: ${TangleColors.darkGray};
      cursor: not-allowed;
      color: ${TangleColors.grayLight};

      border: none;
      :disabled {
        background: ${TangleColors.grayDark} !important;
        border: none !important;
        :hover {
          cursor: not-allowed !important;
          color: ${TangleColors.white} !important;
          background: ${TangleColors.grayDark} !important;
        }
      }
    }

    span {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 26px;
      height: 26px;
      background: ${TangleColors.black};
      outline: none !important;
      border-radius: 999px;
      border: none;
      transition: all 0.2s cubic-bezier(0, 0, 0.46, 1.82);
      transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
    }

    &:active {
      span {
        width: 50px;
        outline: none !important;
      }

      &.on span {
        margin-left: -54px;
        outline: none !important;
      }
    }

    &.on {
      background: ${TangleColors.lightPurple};
      outline: none !important;
      span {
        left: 100%;
        margin-left: -30px;
        margin-top: -2px;
        background: ${TangleColors.black};
        outline: none !important;
      }
    }
    &.off {
      background: ${TangleColors.tangleBlack};
      outline: none !important;
      span {
        outline: none !important;
        right: 100%;
        margin-left: 0px;
        margin-top: -2px;
        background: ${TangleColors.black};
      }
    }
  }
`

export default TangleToggle
