import { Chains } from "@tangleswap/sdk"
import addChainToMetamask from "connect/switchChain"
import { defaultChainId } from "context/MilkomedaContext"
import React from "react"
import styled from "styled-components"
import { ChainCardanoChangeButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

const AuctionCreationCardano = () => {
  return (
    <>
      <ConnectFormCover>
        <ChainCoverButton>
          <ImageWrapper>
            <ChartImage
              src="https://d3m3d54t409w7t.cloudfront.net/homepage/icon-materializing.png"
              alt="tangleswap chart waiting"
            />
          </ImageWrapper>
          <ConnectFormText>
            Creating Auctions is only possible in Cardano by first setting up
            the L1{"<>"}L2 token wrapping. Please reach out to the TangleSwap
            Crew if you're interested or need more information, the process is
            fairly simple.
          </ConnectFormText>
          {/* <ChainButton onClick={() => addChainToMetamask(defaultChainId)}>
            Switch to Cardano
          </ChainButton> */}
        </ChainCoverButton>
      </ConnectFormCover>
    </>
  )
}
const ChartImage = styled.img`
  max-width: 300px;
  height: 150px;
  width: 100%;
  object-fit: contain;
`
const ImageWrapper = styled.div`
  max-width: 300px;
  height: 150px;
  width: 100%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const ChainCoverButton = styled.div`
  padding: 12px 40px;
  width: 100%;
  max-width: 450px;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
`
const ChainButton = styled(ChainCardanoChangeButton)`
  position: relative;
  z-index: 9;
  margin: 24px 0;
  width: 100%;
`
const ConnectFormCover = styled.div`
  margin: 32px auto;
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  background: ${TangleColors.swapBG};
  border-radius: 24px;
  padding: 32px;
  border: 2px solid ${TangleColors.lighthover};
`
const ConnectFormText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 12px 0;
  text-align: center;
`

export default AuctionCreationCardano
