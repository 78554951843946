import { Chains } from "@tangleswap/sdk"

export const PaymentCurrencyData = {
  [Chains.CARDANO]: ["ADA"],
  [Chains.CARDANO_TEST]: [
    "ADA",
    "0x18a6752Fa58Ea77BBfBB13AEd9993F77b465205f", // USDC
    "0xb2a3b87f74C628F1656e1B06e3b0B28EaEb931fF", // USDT
    "0xE17dF25bD3981783BeC8FBeC38CB1E444B9E79D7", // WBTC
    "0x38546EA154cDd64bA6312799493B605B52B19dbf", // VOID
  ],
  [Chains.SHIMMER_TEST]: [
    "0x1074010000000000000000000000000000000000",
    "0x89131F3EaC8455897645A2428b07E693DCd89407",
    "0x56Aa26318DbcDE7431E0f1a148b17a66ed1fF75F",
    "0x16E5462e554fECc48e089b84e569f8fAA289CD08",
    "0xe4B4F6eEd50cdd045c30Ed31dFEeD9DA3CCd00ee",
  ],
  [Chains.SHIMMER]: [
    "0x1074010000000000000000000000000000000000",
    "0xa158A39d00C79019A01A6E86c56E96C461334Eb0",
    "0x1cDF3F46DbF8Cf099D218cF96A769cea82F75316",
  ],
  [Chains.SEPOLIA_TEST]: [
    "ETH",
    "0x3D0380AC2b4e9212cAE93B499f8b2468C12A6cEe",
    "0xaDdF4574395b9F1af979F0b50076EA9a48356976",
    "0x01678AC1ba242E92e1f6B1E31c2898C850A4B93D",
    "0x8dFC8a2bb54CCaf38B996530fb1e49e069D0b4D3",
    "0x7bd139C3AA21E3232aD693d7dC11b2965366AA39",
  ],
}
