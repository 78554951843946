import styled from "styled-components"
import { useEffect } from "react"
import LiquidContainer from "components/Liquidity/utils/LiquidContainer"

const LiquidityPools = () => {
  useEffect(() => {
    document.title = "My Liquidity Positions"
  }, [])
  return (
    <>
      <Body>
        <LiquidContainer />
      </Body>
    </>
  )
}
const Body = styled.div`
  width: 100%;

  @media only screen and (max-width: 850px) {
    position: relative;
    overflow: scroll;
  }
`
export default LiquidityPools
