import React, { FC } from "react"
import { FiCheck } from "react-icons/fi"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
interface CheckboxProps {
  checked?: boolean
  onClick?: () => void
}

const AgreementCheckbox: FC<CheckboxProps> = (props) => {
  const { checked, onClick } = props
  return (
    <CheckboxCover>
      <CheckboxContainer
        type="checkbox"
        name="checkbox"
        checked={checked}
        onClick={onClick}
      />
      <CheckboxBody checked={checked}>
        <CheckboxIconCover checked={checked}>
          {" "}
          <CheckboxIcon checked={checked} />
        </CheckboxIconCover>
      </CheckboxBody>
    </CheckboxCover>
  )
}
const CheckboxCover = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
`
const CheckboxBody = styled.div<CheckboxProps>`
  width: 16px;
  height: 16px;
  position: relative;
  background: ${(props) =>
    props.checked ? `${TangleColors.lighthover}` : `${TangleColors.white}`};
  border-radius: 4px;
  border: 1px solid ${TangleColors.defi};
  margin: -19px 4px 0 0;
`
const CheckboxIconCover = styled.div<CheckboxProps>`
  width: 13px;
  height: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: ${(props) => (props.checked ? "flex" : "none")};
  align-items: center;
  justify-content: center;
`
const CheckboxIcon = styled(FiCheck)<CheckboxProps>`
  width: 13px;
  height: 13px;
  color: ${TangleColors.white};
  display: ${(props) => (props.checked ? "flex" : "none")};
`
const CheckboxContainer = styled.input`
  display: inline-block;
  vertical-align: middle;
  border-radius: 12px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin: 4px 8px 0 0;
  opacity: 0;
`

export default AgreementCheckbox
