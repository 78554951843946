import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc) // Don't forget this line

export function unixToDate(
  unix: number,
  format = "D MMM, YYYY",
  useUTC = true
): string {
  const timestamp = unix >= 10000000000 ? unix / 1000 : unix // handle milliseconds
  let dayInstance = dayjs.unix(timestamp)

  if (useUTC) {
    dayInstance = dayInstance.utc()
  }

  return dayInstance.format(format)
}

export function formattedUnixToDate(
  unix: number,
  format = "D MMM",
  useUTC = true
): string {
  const timestamp = unix >= 10000000000 ? unix / 1000 : unix // handle milliseconds
  let dayInstance = dayjs.unix(timestamp)
  if (useUTC) {
    dayInstance = dayInstance.utc()
  }
  return dayInstance.format(format)
}

export const formatTime = (unix: string, buffer?: number) => {
  const now = dayjs()
  const timestamp = dayjs.unix(parseInt(unix)).add(buffer ?? 0, "minute")

  const inSeconds = now.diff(timestamp, "second")
  const inMinutes = now.diff(timestamp, "minute")
  const inHours = now.diff(timestamp, "hour")
  const inDays = now.diff(timestamp, "day")

  if (inMinutes < 1) {
    return "recently"
  }

  if (inHours >= 24) {
    return `${inDays} ${inDays === 1 ? "day" : "days"} ago`
  } else if (inMinutes >= 60) {
    return `${inHours} ${inHours === 1 ? "hour" : "hours"} ago`
  } else if (inSeconds >= 60) {
    return `${inMinutes} ${inMinutes === 1 ? "minute" : "minutes"} ago`
  } else {
    return `${inSeconds} ${inSeconds === 1 ? "second" : "seconds"} ago`
  }
}
