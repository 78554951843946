import React, { FC } from "react"
import styled from "styled-components"
import { BodyCaption, Caption, CaptionSmall } from "styles/TextStyles"
import { BsDot } from "react-icons/bs"
import { TangleColors } from "styles/ColorStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { toSignificantDigits } from "utils/toSignificant"
import { ReactComponent as MetamaskIcon } from "assets/metamask.svg"
import { addTokenToMetamask } from "utils/addTokenToMetamask"

interface TokenProps {
  tokenName?: string
  tokenSymbol?: string
  tokenAddress?: string
  tokenDecimals?: number
  tokenLogo?: any
  selectCurrency?: (address: string) => void
  otherCurrency?: any
  selectedCurrency?: any
  address?: string
  balance?: any
  customToken?: boolean
}
interface CurrencyProps {
  otherCurrency?: any
}
const TokenList: FC<TokenProps> = (props) => {
  const {
    tokenName,
    tokenSymbol,
    tokenAddress,
    tokenDecimals,
    tokenLogo,
    customToken,
    otherCurrency,
    selectedCurrency,
    selectCurrency,
    address,
    balance = 0,
  } = props

  return (
    <Body
      disabled={selectedCurrency}
      otherCurrency={otherCurrency}
      onClick={() => selectCurrency(address)}
    >
      <Cover>
        <TokenWrap>
          <TokenIconWrapper>
            {!!tokenLogo ? (
              <>
                {" "}
                <TokenIcon src={tokenLogo} alt={`TangleSwap ${tokenName}`} />
              </>
            ) : (
              <>
                <ConvertTokenSymbol text={tokenName} />
              </>
            )}
          </TokenIconWrapper>

          <TokenInfo>
            <TokenName>{tokenSymbol}</TokenName>
            <TokenInformation>
              <TokenSymbol>{tokenName}</TokenSymbol>

              {customToken ? (
                <>
                  {" "}
                  <TokenDot />
                  <TokenAdded>Added by user</TokenAdded>{" "}
                </>
              ) : null}
            </TokenInformation>
          </TokenInfo>
        </TokenWrap>
        <TokenAmount balance={Number(balance) === 0}>
          <AmountValue balance={Number(balance) === 0}>
            {toSignificantDigits(balance, 4, true, 18)}
          </AmountValue>
        </TokenAmount>
        {tokenAddress.startsWith("0x") && !selectedCurrency && (
          <MetamaskContainer
            onClick={(e: any) => {
              e.stopPropagation()
              addTokenToMetamask(
                tokenAddress,
                tokenSymbol,
                tokenDecimals,
                tokenLogo
              )
            }}
          >
            <MetamaskIcon />
          </MetamaskContainer>
        )}
      </Cover>
    </Body>
  )
}

const Body = styled.button<CurrencyProps>`
  width: 100%;
  height: 56px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
  color: ${(props) =>
    props.otherCurrency ? `${TangleColors.grayDark}` : `${TangleColors.white}`};
  background: ${(props) =>
    props.otherCurrency ? `transparent` : `${TangleColors.swapBG}`};
  border: none;
  outline: none;
  padding: 0 26px;
  transition: 0.4s ease-in;
  :disabled {
    background: transparent;
    color: ${TangleColors.grayDark};
    cursor: not-allowed;

    :hover {
      background: ${TangleColors.grayDark};
      color: ${TangleColors.white};
      cursor: not-allowed;
    }
  }
  :hover {
    background: ${TangleColors.tangleBlack};
  }
`
const Cover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
  margin: 0 auto;
`
const TokenWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const TokenIcon = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 9999px;
  object-fit: contain;
`
const TokenIconWrapper = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 0;
  /* margin: -4px 0 0 0; */
`

const TokenName = styled(CaptionSmall)`
  margin: 0;
`
const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 0 12px;
`
const TokenInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2px 0 0 0;
`
const TokenDot = styled(BsDot)`
  height: 8px;
  width: 8px;
  color: ${TangleColors.white};
`
const TokenSymbol = styled(BodyCaption)`
  color: ${TangleColors.white};
  opacity: 0.7;
`
const TokenAdded = styled(BodyCaption)``
const TokenAmount = styled.div<TokenProps>`
  color: ${(props) =>
    props.balance ? `${TangleColors.grayDark}` : `${TangleColors.white}`};
`
const AmountValue = styled(Caption)<TokenProps>`
  color: ${(props) =>
    props.balance ? `${TangleColors.grayDark}` : `${TangleColors.white}`};
`

const MetamaskContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  cursor: pointer;
  margin-left: 8px;
`

export default TokenList
