import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"

interface FeedbackProps {
  txStatus?: any
  error?: boolean
}
const CardanoTxFeedback: FC<FeedbackProps> = (props) => {
  const { txStatus, error } = props

  return (
    <>
      {!!txStatus ? (
        <CardanoErrorWrapper error={error}>
          <StepperRowError error={error}>{txStatus}</StepperRowError>
        </CardanoErrorWrapper>
      ) : null}
    </>
  )
}

const CardanoErrorWrapper = styled.div<FeedbackProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 32px;
  margin: 20px auto;

  background: ${(props) =>
    props.error
      ? `${TangleColors.tangleRed05}`
      : `${TangleColors.secondary05}`};
  border: ${(props) =>
    props.error
      ? `1px solid ${TangleColors.red}`
      : `1px solid ${TangleColors.tangleYellow600}`};
  padding: 8px 10px;
  border-radius: 10px;
  text-align: center;

  position: relative;
`

const StepperRowError = styled(CaptionSmall)<FeedbackProps>`
  color: ${(props) =>
    props.error ? `${TangleColors.red} ` : `${TangleColors.tangleYellow600}`};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export default CardanoTxFeedback
