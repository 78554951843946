import React from "react"
import styled, { keyframes } from "styled-components"

const PanelLoading = () => {
  return (
    <Body>
      <Shimmer>
        <ShimmerHalf />
      </Shimmer>
    </Body>
  )
}

const Body = styled.div`
  height: 8px;
  width: 100%;
  max-width: 1100px;

  overflow: hidden;
  position: relative;
`
const animationLoading = keyframes`
0%{
transform: translateX(-150%);
}

50%{
    transform: translateX(-60%);
}
100%{
    transform: translateX(150%);
}
`

const Shimmer = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  animation: ${animationLoading} 1.5s infinite;
`

const ShimmerHalf = styled.div`
  width: 100%;
  height: 100%;
  transform: skewX(-20deg);
  background: rgba(255, 255, 255, 0.8);
`

export default PanelLoading
