import numbro from "numbro"
// using a currency library here in case we want to add more in future
export const formatDollarAmount = (num, digits = 2, round = true) => {
  if (num === 0) return "$0.00"
  if (!num) return "-"
  if (num < 0.001 && digits <= 3) {
    return "<$0.001"
  }
  if (num >= 1e9) {
    return `$${numbro(num / 1e9).format({
      average: round,
      mantissa: 0, // No digits after the decimal for billions
    })}`
  }

  // Handle millions and thousands without 'greater than' symbol
  const scale = num >= 1e6 ? "M" : undefined
  const scaledNum = scale === "M" ? num / 1e6 : num

  return numbro(scaledNum).formatCurrency({
    average: round,
    mantissa: num >= 1e6 ? 2 : digits,
    optionalMantissa: true,
    abbreviations: {
      thousand: "k",
      million: "M",
      // Note: Billion and trillion are handled above, so they're not needed here
    },
  })
}
