import BigNumber from "bignumber.js"
import { convertTokensToWei } from "components/utils/convertWeiToTokens"
import useInterval from "components/utils/useInterval"
import {
  LOCK_ADA,
  LOVELACE_UNIT,
  TX_STATUS_CHECK_INTERVAL,
  TxStatus,
} from "constants/milkomeda/transaction"
import { useWSCContext } from "context/MilkomedaContext"
import { useTransactionFees } from "hooks/milkomeda/useTransactionFees"
import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"
import { IWrapData } from "interfaces/wscSwap.interface"
import { FC, useEffect, useRef, useState } from "react"
import {
  getBridgeExplorerUrl,
  getDefaultTokenByChainId,
} from "utils/milkomeda/transaction"
import {
  ActionButton,
  ActionText,
  ActionWrapper,
  ButtonContainer,
  ExternalLinkIcon,
  Link,
} from "components/milkomeda/styles"
import { TxPendingStatus } from "milkomeda-wsc"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import LabelWithBalance from "../LabelWithBalance"
import { useGetWSCTokens, useWSCProvider } from "milkomeda-wsc-ui-test-beta"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import {
  VOID_TOKEN,
  WRAPPED_ADDRESS,
  extractEvmFunctionName,
} from "@tangleswap/sdk"
import { playSound } from "../component/PlaySound"
import { useEstimateGas } from "hooks/milkomeda/useEstimatedGas"
import { useContractWrite, usePrepareContractWrite } from "wagmi"
import { useTangleship } from "utils/useTangleship"

interface IUnwrapStep {
  nextStep?: () => void
  toggleModal?: () => void
  data?: IWrapData
  actionType?: string
  isLastStep?: boolean
  setIsComplete?: (isComplete: boolean) => void
}

export const statusUnwrapMessages = {
  [TxStatus.Init]: "Confirm Unwrapping",
  [TxStatus.Pending]: "Unwrapping your token",
  [TxStatus.WaitingL1Confirmation]: "Waiting for L1 confirmation",
  [TxStatus.WaitingBridgeConfirmation]: "Waiting for bridge confirmation",
  [TxStatus.WaitingL2Confirmation]: "Waiting for L2 confirmation",
  [TxStatus.Confirmed]: "Your asset has been successfully unwrapped!",
  [TxStatus.UnwrapError]:
    "The selected token cannot be unwrapped to L1 yet, please stay tuned as it will become available very soon!",
}

const UnwrapStep: FC<IUnwrapStep> = (props) => {
  const { toggleModal, data, actionType, isLastStep, setIsComplete } = props
  const [txHash, setTxHash] = useState<string | undefined>()
  const [selectedUnwrapToken, setSelectedUnwrapToken] = useState<any>()
  const [isUnwrapPending, setIsUnwrapPending] = useState<boolean>(false)
  const [fetchTokens, setFetchTokens] = useState<boolean>(true)

  const { chainId, destinationBalanceADA, account } = useWSCContext()
  const { wscProvider } = useWSCProvider()
  const { unwrappingFee } = useTransactionFees()
  const {
    txStatus,
    txStatusError,
    setTxStatusError,
    setTxStatus,
    isIdle,
    isLoading,
    isError,
    isSuccess,
  } = useTransactionStatus()
  const {
    isSuccess: isTokensSuccess,
    tokens,
    refetch: refetchTokens,
  } = useGetWSCTokens()
  const { feeCardano } = useEstimateGas()
  const { tangleship } = useTangleship()

  const destinationBalance = destinationBalanceADA?.data
  const tokenOut = data?.tokenOut
  const isAda =
    tokenOut?.unit === LOVELACE_UNIT ||
    selectedUnwrapToken?.contractAddress?.toLowerCase() ===
      WRAPPED_ADDRESS[chainId]?.toLowerCase() ||
    selectedUnwrapToken?.contractAddress === "ADA"
  const defaultSymbol = getDefaultTokenByChainId(chainId)

  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )

  useEffect(() => {
    if (!tangleProgress) return
    if (tangleProgress.stepTitle === "Unwrap") {
      setTxHash(tangleProgress.txHash)
      setTxStatus(TxStatus.WaitingBridgeConfirmation)
      setIsUnwrapPending(true)
      if (setIsComplete && tangleProgress.txHash) setIsComplete(true)
    }
  }, [])

  // @dev: this is the part that makes the transaction execute automatically if the user has the setting enabled

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (tangleSwapTransactions && !autoExecute.current && tokenOut) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        unwrapToken()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions, tokenOut])

  useInterval(
    async () => {
      if (!wscProvider || txHash == null) return
      const response = await wscProvider.getTxStatus(txHash)
      setTxStatus(response)
      if (response === TxStatus.Confirmed) {
        if (setIsComplete) setIsComplete(true)
        if (tangleSwapTXEffects) playSound()

        dispatch(updateUserWSCProgress(null))
      } else if (response === TxStatus.WaitingBridgeConfirmation) {
        if (setIsComplete) setIsComplete(true)
      }
    },
    txHash != null && txStatus !== TxStatus.Confirmed
      ? TX_STATUS_CHECK_INTERVAL
      : null
  )

  useEffect(() => {
    if (isTokensSuccess && fetchTokens) {
      const selectedToken = !isAda
        ? tokens.find(
            (t) =>
              t.contractAddress.toLowerCase() === tokenOut.address.toLowerCase()
          )
        : {
            balance: "0",
            contractAddress: "ADA",
            decimals: "18",
            name: "",
            symbol: defaultSymbol,
            type: "",
          }

      if (!selectedToken) {
        console.log("NO BALANCE, REFETCHING", selectedToken)
        refetchTokens()
      }

      const defaultToken = {
        ...selectedToken,
        hasError: !selectedToken ? true : false,
        balance: !isAda
          ? new BigNumber(selectedToken?.balance ?? 0).toString() // unscaled
          : convertTokensToWei({
              value: new BigNumber(destinationBalance ?? 0).dp(6),
              token: { decimals: 6 },
            }).toFixed(), // unscaled
      }

      setSelectedUnwrapToken(defaultToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenOut, tokens, destinationBalance])

  const dispatch = useAppDispatch()
  const executeStep = (tx: any) => {
    const stepperParams = {
      completed: false,
      stepTitle: "Unwrap",
      subStep: 0,
      actionType,
      actionSubType: extractEvmFunctionName(data?.evmFunction),
      txHash: tx,
      data,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
      isLastStep,
    }
    setFetchTokens(false)
    dispatch(updateUserWSCProgress(stepperParams))
  }

  const [wadaUnwrapFn, setWadaUnwrapFn] = useState<any>()

  useEffect(() => {
    if (
      !selectedUnwrapToken ||
      !account ||
      !tangleship ||
      !chainId ||
      selectedUnwrapToken?.contractAddress?.toLowerCase() !==
        WRAPPED_ADDRESS[chainId]?.toLowerCase()
    )
      return
    const getWadaUnwrapFn = async () => {
      const wadaUnwrap = await tangleship?.directSwap(
        selectedUnwrapToken?.contractAddress,
        "ADA",
        123, // param not needed, feetier
        selectedUnwrapToken?.balance ?? 0,
        BigInt(0),
        account,
        123 // param not needed, deadline
      )
      setWadaUnwrapFn(wadaUnwrap)
    }
    getWadaUnwrapFn()
  }, [selectedUnwrapToken, account, tangleship])

  const prepareContractWriteQuery = usePrepareContractWrite(wadaUnwrapFn)

  const contractWriteQuery = useContractWrite(prepareContractWriteQuery.config)

  const directSwapped = useRef(false)

  const unwrapToken = async () => {
    if (!tokenOut || !wscProvider || !unwrappingFee || !selectedUnwrapToken)
      return

    setTxStatus(TxStatus.Init)

    if (
      selectedUnwrapToken?.contractAddress.toLowerCase() ===
        WRAPPED_ADDRESS[chainId].toLowerCase() &&
      !directSwapped.current
    ) {
      await contractWriteQuery?.writeAsync?.()
      directSwapped.current = true
    }

    const unwrapOptions = {
      destination: undefined,
      assetId: !isAda ? tokenOut.address : undefined,
      amount: !isAda
        ? new BigNumber(selectedUnwrapToken?.balance ?? 0)
        : new BigNumber(selectedUnwrapToken?.balance ?? 0).minus(feeCardano),
    }

    try {
      const txHash = await wscProvider.unwrap(
        unwrapOptions.destination,
        unwrapOptions.assetId,
        unwrapOptions.amount
      )
      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)
      executeStep(txHash)
    } catch (err) {
      console.error("Error while unwrapping token: ", err.message)
      if (err instanceof Error) {
        if (err.message.includes("No assetId found")) {
          if (setIsComplete) setIsComplete(true)
          if (tangleSwapTXEffects) playSound()
          setFetchTokens(false)
          setTxStatus(TxStatus.UnwrapError)
          dispatch(updateUserWSCProgress(null))
        } else {
          setTxStatus(TxStatus.Error)
          setTxStatusError(err.message)
        }
      }
    }
  }
  return (
    <>
      <>
        {" "}
        <ActionWrapper>
          {!isSuccess && (
            <>
              {!isLoading && !selectedUnwrapToken?.hasError && (
                // <div>
                //   {isAda ? (
                //     <LabelWithBalance
                //       label="Bridge Lock-up:"
                //       amount={LOCK_ADA}
                //       assetName={defaultSymbol}
                //     />
                //   ) : (
                //     <>
                //       <LabelWithBalance
                //         label="Received:"
                //         amount={
                //           tokenOut &&
                //           convertTokensToWei({
                //             value: tokenOut.amount,
                //             token: { decimals: 6 },
                //           })
                //             .dividedBy(10 ** 6)
                //             .toFixed()
                //         }
                //         assetName={defaultSymbol}
                //       />
                //       <LabelWithBalance
                //         label="Bridge Lock-up:"
                //         amount={LOCK_ADA}
                //         assetName={defaultSymbol}
                //       />
                //       <LabelWithBalance
                //         label="Bridge fees:"
                //         amount={unwrappingFee?.toFixed()}
                //         assetName={unwrappingFee && defaultSymbol}
                //         tooltipMessage="This fee is paid to the bridge for unwrapping your token."
                //       />
                //     </>
                //   )}

                //   {isAda ? (
                //     <>
                //       <div style={{ alignSelf: "center" }}>You'll transfer</div>
                //       <LabelWithBalance
                //         label="Token:"
                //         amount={tokenOut?.amount}
                //         assetName={unwrappingFee && tokenOut?.symbol}
                //       />
                //       <LabelWithBalance
                //         label="ADA:"
                //         amount={LOCK_ADA}
                //         assetName={defaultSymbol}
                //       />
                //     </>
                //   ) : (
                //     <>
                //       <LabelWithBalance
                //         label="You'll transfer:"
                //         amount={
                //           tokenOut &&
                //           unwrappingFee &&
                //           convertTokensToWei({
                //             value: tokenOut.amount,
                //             token: { decimals: 6 },
                //           })
                //             .plus(adaLocked.multipliedBy(10 ** 6))
                //             .plus(unwrappingFee?.multipliedBy(10 ** 6))
                //             .dividedBy(10 ** 6)
                //             .toFixed()
                //         }
                //         assetName={defaultSymbol}
                //       />
                //     </>
                //   )}
                // </div>
                <ActionText>
                  Unwrapping may take a few minutes (~2 min)
                </ActionText>
              )}
            </>
          )}
          {txHash && txStatus === TxStatus.WaitingBridgeConfirmation && (
            <ActionText>
              Unwrapping in progress. You can now return back to the Tangleship!
            </ActionText>
          )}
          {isLoading && (
            <>
              <SpinnerCombined />
              <span>{statusUnwrapMessages[txStatus]}</span>
              {/* <p style={{ marginBottom: 30, fontSize: "0.875rem" }}>
            Unwrapping may take a few minutes (~2 min)
          </p> */}
            </>
          )}
          {isError && (
            <div>
              Oops, something went wrong.{" "}
              {txStatusError ? `Error: ${txStatusError}` : ""}{" "}
            </div>
          )}
          {txStatus === TxStatus.UnwrapError && (
            <span>{statusUnwrapMessages[txStatus]}</span>
          )}
          {selectedUnwrapToken?.hasError && !isUnwrapPending && (
            <div>
              It seems like you don't have any tokens to unwrap. Perhaps you
              already unwrapped them?{" "}
            </div>
          )}
          {isSuccess && (
            <>
              <Link
                href={`${getBridgeExplorerUrl(
                  chainId
                )}/search/tx?query=${txHash}`}
              >
                {statusUnwrapMessages[TxPendingStatus.Confirmed]}
                <ExternalLinkIcon />
              </Link>
            </>
          )}
          {selectedUnwrapToken?.hasError && !isUnwrapPending && (
            <ButtonContainer>
              <ActionButton onClick={() => refetchTokens()}>
                Refetch Tokens
              </ActionButton>
            </ButtonContainer>
          )}
          {(isIdle || isError) && !selectedUnwrapToken?.hasError && (
            <ButtonContainer>
              <ActionButton onClick={unwrapToken} disabled={!tokenOut}>
                Confirm Unwrapping
              </ActionButton>
            </ButtonContainer>
          )}
        </ActionWrapper>
      </>
    </>
  )
}

export default UnwrapStep
