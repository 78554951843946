export const DEVNET_ORACLE_URL = "https://wsc-server-devnet.c1.milkomeda.com"

export const DEVNET_BLOCKFROST_ID =
  process.env.REACT_APP_BLOCKFROST_API_KEY_TEST
export const DEVNET_NETWORK = "Cardano C1 Devnet"
export const DEVNET_CHAINID = 200101

const DEFAULT_MAINNET_KEY = process.env.REACT_APP_BLOCKFROST_API_KEY

export const MAINNET_ORACLE_URL = "https://wsc-oracle-c1-mainnet.milkomeda.com"

export const MAINNET_BLOCKFROST_ID = DEFAULT_MAINNET_KEY
export const MAINNET_NETWORK = "Cardano C1 Mainnet"
export const MAINNET_CHAINID = 2001

export const CARDANO_ALLOWED_WALLETS = [
  "nami",
  "eternl",
  // "yoroi",
  // "nufi",
  // "flint",
]
