import { FETCH_UNKNOWN_TOKEN_FIELD } from "store/actions/actionsTypes"
import { updateObject } from "../utility"

export interface UnknownTokenInfoProps {
  tokenAddress: any
  tokenField: any
}

const initialState: UnknownTokenInfoProps = {
  tokenAddress: null,
  tokenField: null,
}

const unknownTokenInfoFieldSuccess = (state, action) => {
  return updateObject(state, {
    tokenAddress: action.tokenAddress,

    tokenField: action.tokenField,
  })
}

const unKnownTokenFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNKNOWN_TOKEN_FIELD:
      return unknownTokenInfoFieldSuccess(state, action)
    default:
      return state
  }
}

export default unKnownTokenFieldReducer
