import { updateObject } from "store/utility"
import { VOID_STOP_MODAL } from "../actions/actionsTypes"

export interface SettingsProps {
  tradingVoid: boolean
}
const initialState: SettingsProps = {
  tradingVoid: false,
}

const tradingVoidModal = (state: any, action: any) => {
  return updateObject(state, {
    tradingVoid: action.tradingVoid,
  })
}

const VoidTradingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case VOID_STOP_MODAL:
      return tradingVoidModal(state, action)

    default:
      return state
  }
}

export default VoidTradingReducer
