import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
interface EmptyStateProps {
  text: string
}
const EmptyCommitment: FC<EmptyStateProps> = (props) => {
  const { text } = props
  return (
    <LiquidityChart>
      <Cover>
        <ImageWrapper>
          <ChartImage
            src="https://d3m3d54t409w7t.cloudfront.net/homepage/icon-materializing.png"
            alt="tangleswap chart waiting"
          />
        </ImageWrapper>

        <Title>
          <TitleTop>
            {" "}
            <TitleText>{text}</TitleText>
          </TitleTop>
        </Title>
      </Cover>
    </LiquidityChart>
  )
}

const ChartImage = styled.img`
  max-width: 300px;
  height: 150px;
  width: 100%;
  object-fit: contain;
`
const ImageWrapper = styled.div`
  max-width: 300px;
  height: 150px;
  width: 100%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LiquidityChart = styled.div`
  width: 100%;
  max-width: 500px;
  height: 250px;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.div`
  color: ${TangleColors.white};
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const TitleText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 3px 0 0;
`
const TitleTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 8px 0;
`
const TitleBottom = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export default EmptyCommitment
