import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { GrClose } from "react-icons/gr"
import { AuctionStatus } from "./types"
import { TickCircle } from "iconsax-react"
import { CaptionSmall, CaptionTiny, CaptionTinyBold } from "styles/TextStyles"
import { FC, useEffect, useMemo, useState } from "react"
import { useWSCContext } from "context/MilkomedaContext"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { formatNumber } from "./utils/formatNumber"
import { Link } from "react-router-dom"
import TimeRemaining from "./CreateAuction/components/TimeRemaining"
import dutchSVG from "assets/auction01.svg"
import crowdSVG from "assets/auction02.svg"
import batchSVG from "assets/auction03.svg"
import AuctionPageLoader from "./AuctionPageLoader"
import { BsFillLockFill } from "react-icons/bs"
import { BiWorld } from "react-icons/bi"
import LiquiditySmallGraph from "components/Liquidity/LiquidityDetails/LiquiditySmallGraph"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { useQuery } from "react-query"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { useTangleship } from "utils/useTangleship"
interface TokenProps {
  active?: boolean
  gap?: number
  align?: string
  disabled?: boolean
  status?: string
  category?: string
  color?: any
  margin?: boolean
}
interface AuctionProps {
  hideValue?: boolean
  auctionBg?: any
}
interface CardProps {
  address?: string
  category?: any
  logo?: string
  auctionToken?: any
  name?: string
  symbol?: string
  auctionType?: string
  auctionStatus?: any
  currentPrice?: any
  amountForSale?: number
  auctionPrice?: any
  paymentToken?: any
  tokensRemaining?: number
  totalCommitments?: any
  endTime?: any
  startTime?: any
  sticky?: boolean
  currentProgress?: number
  isPurplelistEnabledStatus?: any
  isSuccessful?: boolean
  isFinalized?: boolean
  handleTimeRemainingChange?: any
}

interface AuctionProps {
  sticky?: boolean
  category?: string
  status?: string
  hideCategory?: boolean
}

const AuctionCard: FC<CardProps> = (props) => {
  const {
    address,
    category,
    auctionToken,
    currentProgress,
    logo,
    auctionType,
    auctionStatus,
    currentPrice,
    amountForSale,
    auctionPrice,
    paymentToken,
    tokensRemaining,
    totalCommitments,
    endTime,
    startTime,
    isPurplelistEnabledStatus,
    isSuccessful,
    isFinalized,
    handleTimeRemainingChange,
  } = props
  const { chainId, l1ChainId } = useWSCContext()
  const { tangleship } = useTangleship()

  const auctionEndTime = useMemo(() => {
    return endTime || 0
  }, [endTime])

  const auctionStartTime = useMemo(() => {
    return startTime || 0
  }, [startTime])

  const { data: tangleAuctionToken } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapInvHubAuctionToken", auctionToken?.addr, l1ChainId],
    () =>
      fetchTangleCurrency(
        auctionToken?.addr,
        l1ChainId,
        tangleship,
        auctionToken
      ),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!auctionToken && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: tanglePaymentToken } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapInvHubPaymentToken", paymentToken?.addr, l1ChainId],
    () =>
      fetchTangleCurrency(
        paymentToken?.addr,
        l1ChainId,
        tangleship,
        paymentToken
      ),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,

      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!paymentToken && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const paymentTokenInfo = useMemo(() => {
    if (!tanglePaymentToken || !l1ChainId) return null
    return tanglePaymentToken
  }, [l1ChainId, tanglePaymentToken])

  const auctionTokenInfo = useMemo(() => {
    if (!tangleAuctionToken || !l1ChainId) return null
    return tangleAuctionToken
  }, [l1ChainId, tangleAuctionToken])

  return (
    <Background to={`/invest/${address}`}>
      <AuctionCardBody status={AuctionStatus[auctionStatus]}>
        <CardInfoContainer>
          <TokenInfoContainer>
            {!!auctionTokenInfo ? (
              <>
                {" "}
                <>
                  <TopNameSymbol>
                    {!!logo || !!auctionTokenInfo?.logoURI ? (
                      <>
                        <TopSymbolIcon
                          src={logo || auctionTokenInfo?.logoURI}
                          alt={`${auctionTokenInfo?.name} logo`}
                        />
                      </>
                    ) : (
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={auctionTokenInfo?.symbol} />
                      </CustomSizeWrapper>
                    )}
                  </TopNameSymbol>
                  <TopSymbolRow>
                    <TopSymbol>{auctionTokenInfo?.symbol}</TopSymbol>
                    <TopSubtitleText>{auctionTokenInfo?.name}</TopSubtitleText>
                  </TopSymbolRow>
                </>
              </>
            ) : (
              <AuctionPageLoader />
            )}
          </TokenInfoContainer>
          <CardTopRow>
            {!!category ? (
              <>
                <CategoryRow>
                  <CategoryBubble category={category}>
                    <CategoryBubbleText> {category}</CategoryBubbleText>
                  </CategoryBubble>
                </CategoryRow>
              </>
            ) : null}
          </CardTopRow>
        </CardInfoContainer>
        <AuctionTypeContainer status={AuctionStatus[auctionStatus]}>
          <AuctionTypeBasic>
            {auctionType === "dutch" ? (
              <AuctionTypeImage auctionBg={dutchSVG} />
            ) : auctionType === "batch" ? (
              <AuctionTypeImage auctionBg={batchSVG} />
            ) : (
              <AuctionTypeImage auctionBg={crowdSVG} />
            )}
            <AuctionTypeText>
              {auctionType === "dutch"
                ? "Dutch"
                : auctionType === "batch"
                ? "Fair Launch"
                : auctionType === "crowdsale"
                ? "Crowdsale"
                : auctionType === "hyperbolic"
                ? "Hyperbolic"
                : "Not Set"}
            </AuctionTypeText>
            <RestrictedRow
              color={
                !isPurplelistEnabledStatus
                  ? TangleColors.tangleGreen
                  : TangleColors.tangleYellow
              }
            >
              {!isPurplelistEnabledStatus ? (
                <>
                  {" "}
                  <GlobleMainIcon color={TangleColors.tangleGreen} />{" "}
                </>
              ) : (
                <>
                  {" "}
                  <GlobleLockIcon />
                </>
              )}
            </RestrictedRow>{" "}
          </AuctionTypeBasic>

          {isSuccessful !== undefined ? (
            <>
              <AuctionInfoTokens>
                {!!paymentTokenInfo ? (
                  <>
                    {" "}
                    <AuctionTypeIndicator status={AuctionStatus[auctionStatus]}>
                      {AuctionStatus[auctionStatus]}
                    </AuctionTypeIndicator>
                  </>
                ) : (
                  <>
                    {" "}
                    <AuctionPageLoader />
                  </>
                )}{" "}
                {isFinalized ? (
                  <>
                    {" "}
                    {isSuccessful === true ? (
                      <>
                        {" "}
                        <AuctionStatusWrapper>
                          <AuctionSuccessful size={14} />
                        </AuctionStatusWrapper>
                      </>
                    ) : (
                      <>
                        {" "}
                        <AuctionStatusWrapper>
                          <AuctionFailed />
                        </AuctionStatusWrapper>
                      </>
                    )}
                  </>
                ) : null}
              </AuctionInfoTokens>
            </>
          ) : (
            <>
              <AuctionPageLoader />
            </>
          )}
        </AuctionTypeContainer>
        <AuctionSaleInfoContainer>
          <AuctionSalePriceContainer>
            <AuctionSalePriceInfo>Current Price</AuctionSalePriceInfo>
            {!!paymentTokenInfo ? (
              <>
                {" "}
                <AuctionSalePrice>{`${formatNumber(currentPrice, 4, true)} ${
                  paymentTokenInfo?.symbol
                }`}</AuctionSalePrice>
              </>
            ) : (
              <AuctionPageLoader />
            )}
          </AuctionSalePriceContainer>
          <AuctionSaleAmountContainer>
            <AuctionSaleAmountInfo>Amount for sale</AuctionSaleAmountInfo>
            {!!auctionTokenInfo ? (
              <AuctionSaleAmount>
                {formatNumber(amountForSale, 4, true)}{" "}
                {auctionTokenInfo?.symbol}
              </AuctionSaleAmount>
            ) : (
              <AuctionPageLoader />
            )}
          </AuctionSaleAmountContainer>
        </AuctionSaleInfoContainer>
        <ChartBackground
          margin={
            auctionType === "dutch" &&
            AuctionStatus[auctionStatus] === "FINISHED"
          }
        >
          <ChartTop>
            {" "}
            <LiquiditySmallGraph
              startPrice={0}
              endPrice={1}
              currentLevel={formatNumber(currentProgress, 4)}
              currentPrice={formatNumber(currentProgress, 4)}
              width={24}
              height={24}
              color={
                AuctionStatus[auctionStatus] === "LIVE"
                  ? TangleColors.tangleGreen
                  : AuctionStatus[auctionStatus] === "UPCOMING"
                  ? TangleColors.tangleYellow
                  : TangleColors.tangleRed
              }
              auctionType={auctionType === "dutch"}
              currentPriceSymbol={paymentTokenInfo?.symbol}
            />
          </ChartTop>
        </ChartBackground>

        <InfoWrapper>
          <InfoCover>
            <AuctionInfoContainer>
              <AuctionCardPriceText>Auction Price</AuctionCardPriceText>
              <AuctionCardPriceText>
                {!!paymentTokenInfo ? (
                  <>
                    <AuctionCardPriceInfo>
                      {" "}
                      {formatNumber(auctionPrice, 4, true)}{" "}
                      {paymentTokenInfo?.symbol}
                    </AuctionCardPriceInfo>
                    <CustomSizeWrapper>
                      {!!paymentTokenInfo?.logoURI ? (
                        <AuctionPriceImage src={paymentTokenInfo?.logoURI} />
                      ) : (
                        <>
                          {" "}
                          <ConvertTokenSymbol text={paymentTokenInfo?.symbol} />
                        </>
                      )}
                    </CustomSizeWrapper>
                  </>
                ) : (
                  <AuctionPageLoader />
                )}
              </AuctionCardPriceText>
            </AuctionInfoContainer>
            <AuctionInfoContainer>
              <AuctionInfoTokens>Tokens Remaining</AuctionInfoTokens>
              <AuctionInfoTokens>
                {auctionType === "batch" ? (
                  <AuctionTextCover>
                    {AuctionStatus[auctionStatus] === "FINISHED" ? (
                      <>0%</>
                    ) : (
                      <>100%</>
                    )}
                  </AuctionTextCover>
                ) : (
                  <AuctionTextCover>
                    {" "}
                    {formatNumber(tokensRemaining * 100, 2)}
                    {""}%
                  </AuctionTextCover>
                )}
              </AuctionInfoTokens>
            </AuctionInfoContainer>
            <AuctionInfoContainer>
              <AuctionInfoTokens>Total Raised</AuctionInfoTokens>
              <AuctionInfoTokens>
                <AuctionTextCover>
                  {" "}
                  {formatNumber(totalCommitments, 4)} {""}
                  {paymentTokenInfo?.symbol}
                </AuctionTextCover>
              </AuctionInfoTokens>
            </AuctionInfoContainer>
            <AuctionInfoContainer
              hideValue={AuctionStatus[auctionStatus] === "FINISHED"}
            >
              <AuctionInfoTokens>
                {AuctionStatus[auctionStatus] === "UPCOMING"
                  ? "Starts In"
                  : "Time Remaining"}
              </AuctionInfoTokens>
              {AuctionStatus[auctionStatus] === "FINISHED" ? null : (
                <AuctionInfoTimeRemaining>
                  <TimeRemaining
                    targetTimestamp={
                      AuctionStatus[auctionStatus] === "UPCOMING"
                        ? auctionStartTime
                        : auctionEndTime
                    }
                    onTimeRemainingChange={handleTimeRemainingChange}
                  />
                </AuctionInfoTimeRemaining>
              )}
            </AuctionInfoContainer>
          </InfoCover>
        </InfoWrapper>
      </AuctionCardBody>{" "}
    </Background>
  )
}
const AuctionCardBody = styled.div<AuctionProps>`
  min-height: 485px;
  width: 100%;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 12px auto;
  transition: 0.4s ease-in;
  border: 1px solid
    ${(props) =>
      props.status === "LIVE"
        ? TangleColors.tangleGreen
        : props.status === "UPCOMING"
        ? TangleColors.tangleYellow
        : TangleColors.tangleRed};
  background: ${(props) =>
    props.status === "LIVE"
      ? TangleColors.transparentDockBG
      : props.status === "UPCOMING"
      ? TangleColors.transparentStake
      : props.status === "FINISHED"
      ? TangleColors.transparentFarmBG
      : TangleColors.transparentModalBG};

  :hover {
    border: 1px solid
      ${(props) =>
        props.status === "LIVE"
          ? TangleColors.tangleDarkGreen
          : props.status === "UPCOMING"
          ? TangleColors.tangleYellow05
          : TangleColors.tangleDarkRed};
  }
`
const Background = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  width: 100%;
`

export const getCategoryColor = (category: string) => {
  switch (category) {
    case "DeFi":
      return `
        background: ${TangleColors.defi};
        color: ${TangleColors.white};
      `
    case "Gaming":
      return `
        background: ${TangleColors.gaming};
        color: ${TangleColors.white};
      `
    case "Utility":
      return `
        background: ${TangleColors.utility};
        color: ${TangleColors.white};
      `
    case "Social":
      return `
        background: ${TangleColors.social};
        color: ${TangleColors.white};
      `
    case "Governance":
      return `
        background: ${TangleColors.governance};
        color: ${TangleColors.white};
      `
    case "NFTs":
      return `
        background: ${TangleColors.nfts};
        color: ${TangleColors.black};
      `
    default:
      return `
        background: ${TangleColors.heroCTA};
        color: ${TangleColors.white};
      `
  }
}

const CategoryBubble = styled.div<AuctionProps>`
  display: flex;
  ${(props) => getCategoryColor(props.category || "")}
  border-radius: 9999px;
  padding: 3px 12px;
  font-size: 12px;
  margin: 0;
  font-weight: 600;
`

const CategoryBubbleText = styled(CaptionTiny)`
  color: ${TangleColors.white};
`

const CategoryRow = styled.div<AuctionProps>`
  display: ${(props) => (props.hideCategory ? "none" : "flex")};
  flex-direction: row;
  justify-content: flex-end;
`

const TokenInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0 0 0;
  min-height: 42px;
  width: 100%;
`

const AuctionTypeContainer = styled.div<AuctionProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${TangleColors.modalBG};
  border-radius: 0;
  padding: 10px 4px;
  margin: 10px 0;

  border-top: 0.4px solid
    ${(props) =>
      props.status === "LIVE"
        ? TangleColors.tangleGreen
        : props.status === "UPCOMING"
        ? TangleColors.tangleYellow
        : TangleColors.tangleRed};
  border-bottom: 0.4px solid
    ${(props) =>
      props.status === "LIVE"
        ? TangleColors.tangleGreen
        : props.status === "UPCOMING"
        ? TangleColors.tangleYellow
        : TangleColors.tangleRed};
`

const AuctionTypeBasic = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AuctionTypeImage = styled.div<AuctionProps>`
  width: 42px;
  height: 36px;
  padding: 8px;
  border-radius: 9999px;
  background: url(${(props) => props.auctionBg && props.auctionBg}) no-repeat
    center center/65%;
  margin: 0 4px 0 0;
  object-fit: contain;
  display: flex;

  align-items: center;
  justify-content: center;
`

const AuctionTypeText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`

const AuctionTypeIndicator = styled(CaptionSmall)<AuctionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) =>
    props.status === "LIVE"
      ? TangleColors.tangleGreen
      : props.status === "UPCOMING"
      ? TangleColors.tangleYellow
      : TangleColors.tangleRed};
`

const AuctionSaleInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: normal;
  margin: 2px 0 -12px 0;
  padding: 4px;
`

const AuctionSalePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AuctionSalePriceInfo = styled(CaptionTiny)`
  color: ${TangleColors.smokeWhite};
  text-transform: uppercase;
`

const AuctionSalePrice = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  margin: 8px 0 0 0;
  white-space: normal;
`

const AuctionSaleAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const AuctionSaleAmountInfo = styled(CaptionTiny)`
  color: ${TangleColors.smokeWhite};
  text-transform: uppercase;
`

const AuctionSaleAmount = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  white-space: normal;
`

const AuctionInfoContainer = styled.div<AuctionProps>`
  display: ${(props) => (props.hideValue ? "none" : "flex")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  color: ${TangleColors.white};
  background: transparent;
  border-radius: 0 0 8px 8px;
  min-height: 42px;
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const AuctionCardPriceText = styled(CaptionTiny)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${TangleColors.white};
  white-space: nowrap;
  margin: 0 0 -8px 0;
`
const AuctionCardPriceInfo = styled(CaptionTinyBold)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${TangleColors.lighthover};
  white-space: nowrap;
`

const AuctionPriceImage = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  border-radius: 9999px;
  margin-right: 8px;
`

const AuctionInfoTokens = styled(CaptionTiny)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
`
const AuctionTextCover = styled(CaptionTiny)`
  color: ${TangleColors.lighthover};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const AuctionInfoTimeRemaining = styled(CaptionTiny)`
  color: ${TangleColors.grayDark};
  display: flex;
  flex-direction: row;
  letter-spacing: 2px;
`

const InfoCover = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${TangleColors.modalBG};
  border-radius: 0 0 8px 8px;
  padding: 1px 2px 8px 1px;
`
const InfoWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 16px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  padding: 4px;
`

const GlobleLockIcon = styled(BsFillLockFill)<TokenProps>`
  margin: 0;
  width: 16px;
  height: 16px;
  color: ${(props) => props.color};
`
const GlobleMainIcon = styled(BiWorld)<TokenProps>`
  margin: 0;
  width: 16px;
  height: 16px;
  color: ${(props) => props.color};
`

const RestrictedRow = styled.div<TokenProps>`
  margin: 0;
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 6px;
  color: ${(props) => props.color};
`

const CardTopRow = styled.div<TokenProps>`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const TopSymbol = styled(CaptionSmall)`
  color: ${TangleColors.darkerText};
  white-space: nowrap;
`
const TopSymbolIcon = styled.img`
  height: 34px;
  width: 34px;
  object-fit: cover;
  border-radius: 999px;
  margin: 0 8px 0 0;
`

const TopSymbolRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: -3px 0 0 6px;
`

const TopSubtitleText = styled(CaptionTiny)`
  color: ${TangleColors.grayDark};
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0;
  white-space: nowrap;
  width: 100%;
`
const TopNameSymbol = styled(CaptionSmall)`
  color: ${TangleColors.lighterText};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin: 0 6px;
  white-space: nowrap;
  width: 100%;
  height: 34px;
  width: 34px;
`

const AuctionStatusWrapper = styled(CaptionSmall)`
  max-width: 40px;
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 12px;
`
const AuctionSuccessful = styled(TickCircle)`
  width: 14px;
  height: 14px;
  color: ${TangleColors.tangleGreen};

  cursor: pointer;
`

const AuctionFailed = styled(GrClose)`
  width: 20px;
  height: 20px;
  color: ${TangleColors.red};

  cursor: pointer;
  path {
    stroke: ${TangleColors.red};
    fill: ${TangleColors.red};
    transition: 0.4s ease-in;
  }
`
const CustomSizeWrapper = styled.div`
  height: 34px;
  width: 34px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 -3px 0 8px;
`
const ChartBackground = styled.div<TokenProps>`
  border-radius: 8px;

  position: relative;
  height: 100px;
  max-width: 800px;
  width: 100%;
  margin: ${(props) => (props.margin ? `0 0 24px 12px` : `23px 0 0 12px`)};

  svg {
    width: 100%;
    height: 100%;
  }
`

const ChartTop = styled.div`
  margin: -160px 0 0 12px;
`
export default AuctionCard
