import React, { FC, useCallback } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall, Subtitle } from "styles/TextStyles"
import { toast as SonnerToast } from "sonner"
import { HiOutlineExternalLink, HiOutlineDuplicate } from "react-icons/hi"

import { GrClose } from "react-icons/gr"
import { TiWarningOutline } from "react-icons/ti"
import { CustomTokenModalButton } from "styles/ButtonStyles"
import { useAppSelector } from "store/hooks"
import CustomTokenLoading from "./CustomTokenLoading"
import { Field } from "@tangleswap/sdk"
import useExplorerInfo from "utils/useExplorerInfo"

interface CustomTokenInfoProp {
  customTokenRef?: any
  closeCustomTokenModal?: (value: any) => void
  closeCustomTokenModalWithBg?: (value: any) => void
  selectCustomCurrency?: (field: Field, address: string) => void

  selectCurrency?: (field: Field, address: string) => void
  closeTokenCustomModal?: () => void
  userUnderstand: () => void
}
const CustomTokenModal: FC<CustomTokenInfoProp> = (props) => {
  const {
    customTokenRef,
    closeCustomTokenModal,
    closeCustomTokenModalWithBg,
    userUnderstand,
  } = props

  const unKnownTokenAddress = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenAddress
  )
  const unknownTokenSymbol = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenSymbol
  )

  const { explorerURL } = useExplorerInfo()
  const fetchTokenDataURL = `${explorerURL?.explorer}/token/${unKnownTokenAddress}`
  const CopyAddress = useCallback(() => {
    navigator.clipboard.writeText(unKnownTokenAddress)
    SonnerToast.success("Token Address Copied")
  }, [unKnownTokenAddress])

  return (
    <Body ref={customTokenRef} onClick={closeCustomTokenModalWithBg}>
      <Cover>
        <ModalClose onClick={closeCustomTokenModal} />
        {!!unknownTokenSymbol ? (
          <>
            {" "}
            {/* pending conditional rendering starts */}
            <SwapWrap>
              <SwapErrorIcon>
                <ErrorText>
                  Warning{" "}
                  <WarningWrapper>
                    <SwapWarningError />
                  </WarningWrapper>
                </ErrorText>
              </SwapErrorIcon>
              <TokenTradeCover>
                <TokenName>{String(unknownTokenSymbol)?.slice(0, 3)}</TokenName>
              </TokenTradeCover>
              <InfoText>
                <SwapText>
                  This token {"isn't"} frequently traded on TangleSwap. Always
                  conduct your own research before trading.
                  {/* <a href="http://" target="_blank" rel="noopener noreferrer">
                    {" "}
                    Learn More
                  </a> */}
                </SwapText>{" "}
              </InfoText>
              {explorerURL ? (
                <>
                  {" "}
                  <TokenAddressCover>
                    <>
                      {" "}
                      <TokenAddressWrapper>
                        <TokenAddressHref
                          href={`${fetchTokenDataURL}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TokenAddressText>
                            {fetchTokenDataURL}
                          </TokenAddressText>{" "}
                        </TokenAddressHref>
                        <IconCover>
                          <a
                            href={`${fetchTokenDataURL}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TokenAddressExternal />
                          </a>
                        </IconCover>

                        <IconCover>
                          <TokenAddressCopy onClick={CopyAddress} />
                        </IconCover>
                      </TokenAddressWrapper>
                    </>
                  </TokenAddressCover>
                </>
              ) : null}

              <ButtonWrap>
                <WrapButton onClick={userUnderstand}>I understand</WrapButton>
                <WrapCancelButton onClick={closeCustomTokenModal}>
                  Cancel
                </WrapCancelButton>
              </ButtonWrap>
            </SwapWrap>
            {/* pending conditional rendering ends */}
          </>
        ) : (
          <CustomTokenLoading />
        )}
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 260;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
`
const Cover = styled.div`
  position: relative;
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* justify-content: center; */
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.lighthover};
`

const SwapWrap = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  width: 100%;
  color: ${TangleColors.white};
`

const WrapButton = styled(CustomTokenModalButton)`
  margin: 0 0 16px 0;
`
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${TangleColors.white};
  margin: 24px 0 2px 0;
`
const WrapCancelButton = styled(Subtitle)`
  color: ${TangleColors.grayLight};
  margin: 0;
  cursor: pointer;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const InfoText = styled.div`
  width: 100%;

  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SwapText = styled(Caption)`
  color: ${TangleColors.white};
  text-align: center;
  color: ${TangleColors.white};
  margin: 0 0 0 8px;
  padding: 8px;
  /* @media only screen and (max-width: 650px) {
    padding: 16px;
  } */
  a {
    /* color: ${TangleColors.white}; */
    margin: 0 2px 0 4px;
    /* text-underline-offset: 4px; */
    color: ${TangleColors.lighthover};
    transition: 0.4s ease-in;
    text-decoration-color: ${TangleColors.lighthover};
    -webkit-text-decoration-color: ${TangleColors.lighthover};
    :hover {
      color: ${TangleColors.tangleYellowPrimary};
      text-decoration-color: ${TangleColors.tangleYellowPrimary};
      -webkit-text-decoration-color: ${TangleColors.tangleYellowPrimary};
    }
  }
`

const TokenAddressCover = styled.div`
  margin: 0 auto;
  height: 42px;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  background: ${TangleColors.tangle10};
  padding: 2px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: 0.4s ease-in;
  cursor: pointer;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`

const TokenAddressText = styled(Caption)`
  margin: 0 auto;
  color: ${TangleColors.feeRateText};
  max-width: 320px;
  width: 100%;
  overflow: hidden;
  transition: 0.4s ease-in;
  white-space: nowrap;
  overflow-x: scroll;
  ${TokenAddressCover}:hover & {
    color: ${TangleColors.white};
  }
  /* width */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    display: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`
const TokenAddressHref = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 6px 0 0;
`

const TokenAddressWrapper = styled.div`
  margin: 24px auto;
  max-width: 420px;
  width: 100%;
  background: transparent;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const IconCover = styled.div`
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1px;
  a {
    height: 28px;
    width: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`
const TokenAddressCopy = styled(HiOutlineDuplicate)`
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin: 0 0 0 4px;
  color: ${TangleColors.feeRateText};
  transition: 0.4s ease-in;
  ${TokenAddressCover}:hover & {
    color: ${TangleColors.white};
  }
`
const TokenAddressExternal = styled(HiOutlineExternalLink)`
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin: 0 6px 0 0;

  color: ${TangleColors.feeRateText};
  transition: 0.4s ease-in;
  ${TokenAddressCover}:hover & {
    color: ${TangleColors.white};
  }
`
const SwapErrorIcon = styled.div`
  margin: 8px auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  max-width: 92px;
  width: 100%;
  border-radius: 10px;
  padding: 6px 10px;
  color: ${TangleColors.white};
  background: ${TangleColors.dockBG};
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`

const ErrorText = styled(CaptionSmall)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const SwapWarningError = styled(TiWarningOutline)`
  width: 18px;
  height: 18px;
  width: 100%;
  margin: 0 0 0 4px;
  color: ${TangleColors.white};
`

const WarningWrapper = styled.div`
  max-width: 24px;
  height: 24px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const LearnMoreText = styled(Caption)`
  margin: 8px 0;
  color: ${TangleColors.lighthover};
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.4s ease-in;

  :hover {
    color: ${TangleColors.cta};
  }
`
const TokenTradeCover = styled.div`
  height: 40px;
  max-width: 40px;
  width: 100%;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 16px auto;
  background: ${TangleColors.grayLight};
`

const TokenName = styled(CaptionSmall)`
  margin: 0;
  text-transform: uppercase;
  color: ${TangleColors.black};
`
const ModalClose = styled(GrClose)`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 24px;
  right: 30px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

export default CustomTokenModal
