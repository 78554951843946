import BigNumber from "bignumber.js"
import { convertWeiToTokens } from "components/utils/convertWeiToTokens"
import { EVM_ESTIMATED_FEE, LOCK_ADA } from "constants/milkomeda/transaction"
import { useWSCContext } from "context/MilkomedaContext"
import React from "react"

export const useTransactionFees = () => {
  const { stargate } = useWSCContext()

  return React.useMemo(() => {
    const wrappingFee =
      stargate &&
      convertWeiToTokens({
        valueWei: stargate.fromNativeTokenInLoveLaceOrMicroAlgo,
        token: { decimals: 6 },
      })
    const unwrappingFee =
      stargate && new BigNumber(stargate.stargateNativeTokenFeeToL1)
    const bridgeFees =
      wrappingFee && unwrappingFee && wrappingFee.plus(unwrappingFee)
    const adaLocked = new BigNumber(LOCK_ADA)
    const evmEstimatedFee = new BigNumber(EVM_ESTIMATED_FEE)
    return {
      wrappingFee,
      unwrappingFee,
      bridgeFees,
      adaLocked,
      evmEstimatedFee,
    }
  }, [stargate])
}
