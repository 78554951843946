import { useCallback } from "react"

import { increaseTypeInput } from "store/actions/IncreaseLiquidityAction"
import { useAppDispatch } from "store/hooks"
import { Field } from "@tangleswap/sdk"

export function useIncreaseLiquidityActionHandlers(): {
  onUserInput: (field: Field, typedValue: string) => void
} {
  const dispatch = useAppDispatch()

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(increaseTypeInput({ field, typedValue }))
    },
    [dispatch]
  )

  return {
    onUserInput,
  }
}
