import React, { FC } from "react"
import { CommitmentProps } from "../types"
import TokenBreak from "./TokenBreak"
import CommitmentDataRow from "./CommitmentDataRow"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
import TanglePagination from "components/account/table/TanglePagination"

interface CommitTableProps {
  allCommitment?: CommitmentProps[]
  paymentSymbol?: string
}

const CommitmentTables: FC<CommitTableProps> = (props) => {
  const { allCommitment, paymentSymbol } = props

  // pagination
  const [page, setPage] = React.useState(1)
  const [maxPage, setMaxPage] = React.useState(1)
  const [isReady, setIsReady] = React.useState<boolean>(false)
  const maxItems = 10
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setIsReady(!isReady)
    }, 6000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])
  React.useEffect(() => {
    let extraPages = 1
    if (allCommitment) {
      if (allCommitment?.length % maxItems === 0) {
        extraPages = 0
      }
      const pagesToShow = Math.min(50, allCommitment?.length)
      setMaxPage(Math.floor(pagesToShow / maxItems) + extraPages)
    }
  }, [maxItems, allCommitment])

  const sortedTokens = React.useMemo(() => {
    return allCommitment?.slice(maxItems * (page - 1), page * maxItems) || []
  }, [allCommitment, maxItems, page])

  return (
    <TokenWrapper>
      <TokensTableCover>
        <TokenAutoColumn>
          <TokensTableTitle>
            <ClickableText>
              <BoxDataName>Account</BoxDataName>
            </ClickableText>
            <ClickableText>
              <BoxDataName> Committed </BoxDataName>
            </ClickableText>
            <ClickableText>
              <BoxDataName> Block</BoxDataName>
            </ClickableText>

            <ClickableText>
              <BoxDataName> Timestamp</BoxDataName>
            </ClickableText>
          </TokensTableTitle>

          <TokensTableBody>
            {sortedTokens &&
              sortedTokens?.map((data: any, index: number) => {
                if (data) {
                  return (
                    <React.Fragment key={index}>
                      <TokenBreak />
                      <CommitmentDataRow
                        index={index}
                        commitmentData={data}
                        paymentSymbol={paymentSymbol}
                      />
                    </React.Fragment>
                  )
                }
                return null
              })}
            <TanglePagination
              lightMode={true}
              maxPage={maxPage}
              setPage={setPage}
              page={page}
            />
          </TokensTableBody>
        </TokenAutoColumn>
      </TokensTableCover>
    </TokenWrapper>
  )
}
const TokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  width: 100%;
  margin: 40px auto 16px auto;
`
const TokenAutoColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  width: 100%;
  margin: 8px auto;
`
const TokensTableCover = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  width: 100%;
  margin: 8px auto;
`

const TokensTableBody = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 16px 0;
`

const ClickableText = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: ${TangleColors.white};
  text-align: left;
  margin: 0 0 0 8px;
`
const BoxDataName = styled(Subtitle)`
  color: ${TangleColors.grayLight};

  text-align: left;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const TokensTableTitle = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 0 0 8px 0;
  grid-template-columns: 1.5fr 3.5fr 1.2fr 3fr;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1.2fr 2.5fr 1.2fr 3fr;
    & :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1.5fr repeat(1, 1fr);
    & :nth-child(5) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

export default CommitmentTables
