import { useCallback } from "react"
import { pairTypeInput } from "store/actions/PairAction"
import { useAppDispatch } from "store/hooks"

export function usePairActionHandlers(): {
  onPairUserInput: (typedValue: string) => void
} {
  const dispatch = useAppDispatch()

  const onPairUserInput = useCallback(
    (typedValue: string) => {
      dispatch(pairTypeInput(typedValue))
    },
    [dispatch]
  )

  return { onPairUserInput }
}
