import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { useWSCContext } from "context/MilkomedaContext"
import { QueryError, TangleTokenProps } from "components/swap/types"
import React, { FC } from "react"
import { useQuery } from "react-query"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionTiny } from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"
import { useTangleship } from "utils/useTangleship"

interface TokenTransaction {
  txType?: any
  approvedTokenAddress?: string

  decodedInputDataName?: any
  fromTokenAddress?: string
  toTokenAddress?: string
  fromTokenAmount?: any
  toTokenAmount?: any
  token0Address?: any
  token1Address?: any

  token0Decimals?: number
  token1Decimals?: number
  token0Amount?: any
  token1Amount?: any
  token0Name?: string
  token1Name?: string
  token0Symbol?: string
  token1Symbol?: string
  successFulTransaction?: boolean
}

const TokenTransactionInfo: FC<TokenTransaction> = (props) => {
  const {
    txType,
    token0Address,

    token0Symbol,

    token0Amount,
    token1Address,

    token1Symbol,

    token1Amount,
    approvedTokenAddress,
    decodedInputDataName,
    toTokenAddress,
    fromTokenAddress,
    fromTokenAmount,
    toTokenAmount,
    successFulTransaction,
  } = props
  const { chainId, l1ChainId } = useWSCContext()
  const { tangleship } = useTangleship()
  const { data: approvedTokenInfo } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserApprovedTokenInfo", approvedTokenAddress, l1ChainId],
    () => fetchTangleCurrency(approvedTokenAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,

      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!approvedTokenAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: fromTokenInfo } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserFromTokenInfo", fromTokenAddress, l1ChainId],
    () => fetchTangleCurrency(fromTokenAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!fromTokenAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: toTokenInfo } = useQuery<TangleTokenProps, QueryError>(
    ["tangleUserToTokenInfo", toTokenAddress, l1ChainId],
    () => fetchTangleCurrency(toTokenAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,

      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!toTokenAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  return (
    <DropTextBody>
      {successFulTransaction ? (
        <>
          {" "}
          {(txType !== null && txType === "approve") ||
          (txType !== null &&
            txType === "approve" &&
            decodedInputDataName?.name !== null &&
            decodedInputDataName?.name === "approve") ? (
            <>{approvedTokenInfo?.symbol} Approved</>
          ) : null}
          {(txType !== null && txType === "mint") ||
          (txType !== null &&
            txType === "mint" &&
            decodedInputDataName?.name === "mint") ||
          (txType !== null &&
            txType === "multicallLiquidity" &&
            decodedInputDataName?.name === "mint") ? (
            <>
              {token0Amount !== null &&
              token1Amount !== null &&
              token0Address !== null &&
              token1Address !== null ? (
                <>
                  <TokenAmount>
                    {token0Amount} {token0Symbol}{" "}
                  </TokenAmount>
                  <TokenInfoSpacer>and</TokenInfoSpacer>
                  <TokenAmount>
                    {token1Amount} {token1Symbol}
                  </TokenAmount>
                </>
              ) : (
                <>Add Liquidity successful</>
              )}
            </>
          ) : null}
          {(txType !== null && txType === "exactInput") ||
          (txType !== null && txType === "exactOutputSingle") ||
          (txType !== null && txType === "exactOutput") ||
          (txType !== null && txType === "exactInputSingle") ||
          (txType !== null &&
            txType === "multicallSwap" &&
            decodedInputDataName?.name !== null &&
            decodedInputDataName?.name === "exactInput") ||
          (txType !== null &&
            txType === "multicallSwap" &&
            decodedInputDataName?.name !== null &&
            decodedInputDataName?.name === "exactInputSingle") ||
          (txType !== null &&
            txType === "multicallSwap" &&
            decodedInputDataName?.name !== null &&
            decodedInputDataName?.name === "exactOutput") ||
          (txType !== null &&
            txType === "multicallSwap" &&
            decodedInputDataName?.name !== null &&
            decodedInputDataName?.name === "exactOutputSingle") ? (
            <>
              {fromTokenAmount !== null &&
              toTokenAmount !== null &&
              fromTokenInfo !== null &&
              toTokenInfo !== null ? (
                <>
                  <TokenAmount>
                    {toSignificantDigits(fromTokenAmount, 5, true)}{" "}
                    {fromTokenInfo?.symbol}{" "}
                  </TokenAmount>
                  <TokenInfoSpacer>for</TokenInfoSpacer>
                  <TokenAmount>
                    {" "}
                    {toSignificantDigits(toTokenAmount, 5, true)}{" "}
                    {toTokenInfo?.symbol}
                  </TokenAmount>
                </>
              ) : (
                <>Token Swap Successful</>
              )}
            </>
          ) : null}
          {txType === "collect" ||
          (txType === "collect" && decodedInputDataName?.name === "collect") ? (
            <>
              {token0Amount !== null &&
              token1Amount !== null &&
              token0Address !== null &&
              token1Address !== null ? (
                <>
                  <TokenAmount>
                    {" "}
                    {token0Amount} {token0Symbol}
                  </TokenAmount>
                  <TokenInfoSpacer>and</TokenInfoSpacer>
                  <TokenAmount>
                    {" "}
                    {token1Amount} {token1Symbol}
                  </TokenAmount>
                </>
              ) : (
                <>Collected Fee successful</>
              )}
            </>
          ) : null}
          {txType === "multicallLiquidity" &&
          decodedInputDataName?.name ===
            "createAndInitializePoolIfNecessary" ? (
            <>
              {token0Amount !== null &&
              token1Amount !== null &&
              token0Address !== null &&
              token1Address !== null ? (
                <>
                  <TokenAmount>
                    {" "}
                    {token0Amount} {token0Symbol}{" "}
                  </TokenAmount>
                  <TokenInfoSpacer>and</TokenInfoSpacer>
                  <TokenAmount>
                    {" "}
                    {token1Amount} {token1Symbol}
                  </TokenAmount>
                </>
              ) : (
                <>Initialize Liquidity successful</>
              )}
            </>
          ) : null}
          {txType === "multicallLiquidity" &&
          decodedInputDataName?.name === "decreaseLiquidity" ? (
            <>
              {token0Amount !== null &&
              token1Amount !== null &&
              token0Address !== null &&
              token1Address !== null ? (
                <>Remove Liquidity successful</>
              ) : (
                <>Remove Liquidity successful</>
              )}
            </>
          ) : null}
          {txType === "increaseLiquidity" ||
          (txType === "increaseLiquidity" &&
            decodedInputDataName?.name === "increaseLiquidity") ? (
            <>
              {token0Amount !== null &&
              token1Amount !== null &&
              token0Address !== null &&
              token1Address !== null ? (
                <>
                  <TokenAmount>
                    {token0Amount} {token0Symbol}
                  </TokenAmount>
                  <TokenInfoSpacer>and</TokenInfoSpacer>
                  <TokenAmount>
                    {" "}
                    {token1Amount} {token1Symbol}
                  </TokenAmount>
                </>
              ) : (
                <>Increase Liquidity successful</>
              )}
            </>
          ) : null}
        </>
      ) : (
        <>Transaction failed</>
      )}
    </DropTextBody>
  )
}

const DropTextBody = styled(CaptionTiny)`
  color: ${TangleColors.darkGray};
  display: flex;
  flex-direction: row;
  margin: 4px 0 0 0;
`
const TokenAmount = styled(CaptionTiny)`
  color: ${TangleColors.darkGray};
  margin: 0;
`
const TokenInfoSpacer = styled(CaptionTiny)`
  color: ${TangleColors.darkGray};
  margin: 0 2px;
`

export default TokenTransactionInfo
