export interface HomeCardsProps {
  image?: any
  degree?: any
  cardMargin?: any
  className?: any
}
export const homeCardsData: HomeCardsProps[] = [
  {
    image: "https://d3m3d54t409w7t.cloudfront.net/homepage/trade.png",
    degree: -12,
  },
  {
    degree: 12,
    image: "https://d3m3d54t409w7t.cloudfront.net/homepage/liquidity.png",
    cardMargin: 80,
    className: "hide_mobile",
  },
  {
    degree: -8.272,
    image: "https://d3m3d54t409w7t.cloudfront.net/homepage/farm.png",
    cardMargin: 80,
  },
  {
    degree: 20,
    className: "hide_mobile",
    image: "https://d3m3d54t409w7t.cloudfront.net/homepage/stake.png",
  },
]
