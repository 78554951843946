import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body4, Caption, CaptionLittle, Subtitle } from "styles/TextStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { NoTransactionButton } from "styles/ButtonStyles"
import { Link } from "react-router-dom"

import backiconImg from "assets/backicon.svg"
import useExplorerInfo from "utils/useExplorerInfo"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import { closeTransactionBackMenu } from "store/actions/MobileNavAction"
import { TransactionHistoryProps } from "navbar/types"
import TransactionLogoInfo from "navbar/TransactionLogoInfo"
import TokenTransactionInfo from "navbar/TokenTransactionInfo"
import TransactionHistoryLoading from "navbar/TransactionHistoryLoading"
import TimeSinceRow from "navbar/TimeSinceRow"
interface TransactionStatus {
  success?: boolean
}

const MobileTransactionDropdown = () => {
  const { explorerURL } = useExplorerInfo()
  const transactionHistory = useAppSelector(
    (state) => state.transactionHistory.transactionHistory
  )
  const transactionListGroup = useAppSelector(
    (state) => state.transactionHistory.transactionListGroup
  )
  const dispatch = useAppDispatch()
  const goBackToMainMenu = () => {
    dispatch(closeTransactionBackMenu())
  }

  return (
    <>
      <>
        <NavCover>
          <LPNavigation>
            <TransactionBackIcon src={backiconImg} onClick={goBackToMainMenu} />
            <BackText>Your Transaction History</BackText>
          </LPNavigation>
          <Body>
            {transactionListGroup?.length !== 0 ? (
              <>
                <Cover>
                  {" "}
                  {transactionHistory !== null ? (
                    <>
                      {" "}
                      <DropUL>
                        {transactionHistory !== null &&
                        transactionListGroup?.length !== 0 ? (
                          <>
                            {transactionListGroup?.length > 0 ? (
                              <>
                                <>
                                  {transactionListGroup?.map(
                                    (
                                      transactionDetails: any,
                                      index: number
                                    ) => (
                                      <TransactionRowWrapper key={index}>
                                        <TransactionDateWrapper>
                                          <TransactionDate>
                                            {transactionDetails?.title}
                                          </TransactionDate>
                                        </TransactionDateWrapper>
                                        {transactionDetails?.transactions?.map(
                                          (
                                            transactionHistory: TransactionHistoryProps,
                                            index: number
                                          ) => (
                                            <DropHref
                                              href={`${explorerURL?.explorer}/tx/${transactionHistory?.hash}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              key={index}
                                            >
                                              <DropLi>
                                                <TransactionCover>
                                                  <TransactionLogoInfo
                                                    txType={
                                                      transactionHistory?.txType
                                                    }
                                                    successFulTransaction={
                                                      transactionHistory?.isError ===
                                                      "0"
                                                    }
                                                    token0Symbol={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.token0Symbol
                                                    }
                                                    token1Symbol={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.token1Symbol
                                                    }
                                                    approvedTokenAddress={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.approvedTokenAddress
                                                    }
                                                    token0Address={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.token0Address
                                                    }
                                                    token1Address={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.token1Address
                                                    }
                                                    fromTokenAddress={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.fromTokenAddress
                                                    }
                                                    toTokenAddress={
                                                      transactionHistory
                                                        ?.tokenTransferData
                                                        ?.toTokenAddress
                                                    }
                                                    decodedInputDataName={
                                                      transactionHistory?.decodedInputData
                                                    }
                                                  />

                                                  <DropText>
                                                    <TitleWrapper>
                                                      <DropTextTitle>
                                                        {transactionHistory?.txType ===
                                                        "approve" ? (
                                                          <>Token Approval</>
                                                        ) : null}
                                                        {transactionHistory?.txType ===
                                                        "mint" ? (
                                                          <>Add Liquidity</>
                                                        ) : null}
                                                        {transactionHistory?.txType ===
                                                          "increaseLiquidity" ||
                                                        (transactionHistory?.txType ===
                                                          "increaseLiquidity" &&
                                                          transactionHistory
                                                            ?.decodedInputData?.[0]
                                                            .name ===
                                                            "increaseLiquidity") ? (
                                                          <>
                                                            Increase Liquidity
                                                          </>
                                                        ) : null}
                                                        {transactionHistory?.txType ===
                                                          "collect" ||
                                                        (transactionHistory?.txType ===
                                                          "collect" &&
                                                          transactionHistory
                                                            ?.decodedInputData?.[0]
                                                            .name ===
                                                            "collect") ? (
                                                          <>Collect Fees</>
                                                        ) : null}
                                                        {transactionHistory?.txType ===
                                                          "exactInput" ||
                                                        transactionHistory?.txType ===
                                                          "exactOutputSingle" ||
                                                        transactionHistory?.txType ===
                                                          "exactOutput" ||
                                                        transactionHistory?.txType ===
                                                          "exactInputSingle" ||
                                                        (transactionHistory?.txType ===
                                                          "multicallSwap" &&
                                                          transactionHistory
                                                            ?.decodedInputData?.[0]
                                                            .name ===
                                                            "exactInput") ||
                                                        (transactionHistory?.txType ===
                                                          "multicallSwap" &&
                                                          transactionHistory
                                                            ?.decodedInputData?.[0]
                                                            .name ===
                                                            "exactInputSingle") ||
                                                        (transactionHistory?.txType ===
                                                          "multicallSwap" &&
                                                          transactionHistory
                                                            ?.decodedInputData?.[0]
                                                            .name ===
                                                            "exactOutput") ||
                                                        (transactionHistory?.txType ===
                                                          "multicallSwap" &&
                                                          transactionHistory
                                                            ?.decodedInputData?.[0]
                                                            .name ===
                                                            "exactOutputSingle") ? (
                                                          <>Swap</>
                                                        ) : null}

                                                        {transactionHistory?.txType ===
                                                          "multicallLiquidity" &&
                                                        transactionHistory
                                                          ?.decodedInputData?.[0]
                                                          .name ===
                                                          "createAndInitializePoolIfNecessary" ? (
                                                          <>
                                                            Initialize Liquidity
                                                          </>
                                                        ) : null}
                                                        {transactionHistory?.txType ===
                                                          "multicallLiquidity" &&
                                                        transactionHistory
                                                          ?.decodedInputData?.[0]
                                                          .name === "mint" ? (
                                                          <>Add Liquidity</>
                                                        ) : null}
                                                        {transactionHistory?.txType ===
                                                          "multicallLiquidity" &&
                                                        transactionHistory
                                                          ?.decodedInputData?.[0]
                                                          .name ===
                                                          "decreaseLiquidity" ? (
                                                          <>Remove Liquidity</>
                                                        ) : null}
                                                      </DropTextTitle>
                                                      {transactionHistory?.isError ===
                                                      "0" ? (
                                                        <>
                                                          <DropTitleStatus
                                                            success={true}
                                                          >
                                                            <StatusText
                                                              success={true}
                                                            >
                                                              Success
                                                            </StatusText>
                                                          </DropTitleStatus>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <DropTitleStatus
                                                            success={false}
                                                          >
                                                            <StatusText
                                                              success={false}
                                                            >
                                                              Failed
                                                            </StatusText>
                                                          </DropTitleStatus>
                                                        </>
                                                      )}{" "}
                                                    </TitleWrapper>
                                                    <TokenTransactionInfo
                                                      successFulTransaction={
                                                        transactionHistory?.isError ===
                                                        "0"
                                                      }
                                                      txType={
                                                        transactionHistory?.txType
                                                      }
                                                      approvedTokenAddress={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.approvedTokenAddress
                                                      }
                                                      token0Amount={formatNumber(
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.token0Amount,
                                                        3,
                                                        true
                                                      )}
                                                      token1Amount={7}
                                                      token0Symbol={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.token0Symbol
                                                      }
                                                      token1Symbol={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.token1Symbol
                                                      }
                                                      token0Decimals={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.token0Decimals
                                                      }
                                                      token1Decimals={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.token1Decimals
                                                      }
                                                      token0Address={
                                                        transactionHistory
                                                          ?.decodedInputData?.[0]
                                                          ?.args?.[0]?.token0
                                                      }
                                                      token1Address={
                                                        transactionHistory
                                                          ?.decodedInputData?.[0]
                                                          ?.args?.[0]?.token1
                                                      }
                                                      fromTokenAddress={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.fromTokenAddress
                                                      }
                                                      toTokenAddress={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.toTokenAddress
                                                      }
                                                      fromTokenAmount={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.fromTokenAmount
                                                      }
                                                      toTokenAmount={
                                                        transactionHistory
                                                          ?.tokenTransferData
                                                          ?.toTokenAmount
                                                      }
                                                      decodedInputDataName={
                                                        transactionHistory?.decodedInputData
                                                        // &&
                                                        // transactionHistory
                                                        //   ?.decodedInputData?.[0]
                                                        //   .name
                                                      }
                                                    />
                                                  </DropText>
                                                  <TimeSinceRow
                                                    timestamp={
                                                      transactionHistory?.timeStamp
                                                    }
                                                  />
                                                </TransactionCover>
                                              </DropLi>{" "}
                                            </DropHref>
                                          )
                                        )}
                                      </TransactionRowWrapper>
                                    )
                                  )}
                                </>
                              </>
                            ) : (
                              <>
                                {" "}
                                <NoTransactionDone>
                                  <NoTransactionRow>
                                    <NoTransactionTitle>
                                      No Transactions Yet
                                    </NoTransactionTitle>
                                    <NoTransactionText>
                                      Dear Explorer, you {"haven't"} made any
                                      transactions yet. Once you do, {"they'll"}
                                      appear here
                                    </NoTransactionText>
                                    <NoTransactionButtonLink to="/swap">
                                      {" "}
                                      <NoTransactionButtonCover>
                                        Add Transaction
                                      </NoTransactionButtonCover>
                                    </NoTransactionButtonLink>
                                  </NoTransactionRow>
                                </NoTransactionDone>
                              </>
                            )}
                          </>
                        ) : null}
                      </DropUL>
                    </>
                  ) : (
                    <>
                      {" "}
                      <TransactionHistoryLoading />
                    </>
                  )}
                </Cover>
              </>
            ) : (
              <>
                {" "}
                <TransactionHistoryLoading />
              </>
            )}
          </Body>
        </NavCover>
      </>
    </>
  )
}

const NavCover = styled.div`
  width: 100%;
`
const Body = styled.div`
  max-width: 800px;
  width: 100%;
  max-height: 300px;
  min-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 8px;

  background: ${TangleColors.white};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  display: flex;
  transition: 0.4s ease-in;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  ::-webkit-scrollbar {
    width: 8px;
    height: 30px;
    border-radius: 32px;
    margin: 12px 0;
  }

  ::-webkit-scrollbar-track {
    margin: 12px 0;
    box-shadow: inset 0 0 6px transparent;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8f4ead;
    border-radius: 32px;
    margin: 12px 0;
    outline: 1px solid #8f4ead;
  }
  @media only screen and (max-width: 850px) {
    max-width: 800px;
    width: 100%;
  }
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  width: 100%;
  position: relative;
`
const DropUL = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 392px;
  margin: 0 auto;
  width: 100%;
`

const DropLi = styled.li`
  margin: 6px 0;
  display: flex;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 4px 8px;
  transition: 0.4s ease-in;
  border-radius: 12px;
  border: 1px solid transparent;
  :hover {
    background: ${TangleColors.snowWhite};
    border: 1px solid ${TangleColors.lighthover};
  }
`
const TransactionRowWrapper = styled.li`
  margin: 12px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;

  transition: 0.4s ease-in;
`

const DropHref = styled.a`
  margin: 3px 0 0 0;
  display: flex;
  flex-direction: row;
  height: 80px;

  cursor: pointer;
  width: 100%;
`
const TransactionCover = styled.div`
  display: grid;
  grid-template-columns: 40px auto 32px;
  grid-gap: 12px;
  gap: 12px;
  cursor: pointer;
  width: 100%;

  transition: 0.4s ease-in;
`

const DropText = styled.div`
  display: flex;
  flex-direction: column;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 3px 0;
`
const DropTitleStatus = styled.div<TransactionStatus>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
  max-width: 40px;
  width: 100%;
  border-radius: 8px;

  color: ${(props) =>
    props.success ? ` ${TangleColors.black}` : ` ${TangleColors.white}`};
  background: ${(props) =>
    props.success ? ` ${TangleColors.tangleGreen}` : ` ${TangleColors.red}`};
  transition: 0.4s ease-in;
  border: 1px solid transparent;
`
const StatusText = styled(CaptionLittle)<TransactionStatus>`
  color: ${(props) =>
    props.success ? ` ${TangleColors.black}` : ` ${TangleColors.white}`};
`
const DropTextTitle = styled(Caption)`
  color: ${TangleColors.black};
  margin: 0 8px 0 0;
`

const TransactionDate = styled(Caption)`
  color: ${TangleColors.grayDark};
`

const TransactionDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 12px 0 0 0;
`

const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  margin: 12px 0;
  padding: 0 16px;
`

const BackText = styled(Caption)`
  color: ${TangleColors.black};
  margin: 0 0 0 8px;
  transition: 0.4s ease-in;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const NoTransactionDone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  margin: 0 auto;
`
const NoTransactionRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const NoTransactionTitle = styled(Subtitle)`
  color: ${TangleColors.black};
  margin: 12px 0 4px 0;
`
const NoTransactionText = styled(Body4)`
  color: ${TangleColors.grayDark};
  margin: 0;
  text-align: center;
`
const NoTransactionButtonLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0 16px 0;
`
const TransactionBackIcon = styled.img`
  height: 28px;
  width: 24px;
  color: ${TangleColors.icon};
  cursor: pointer;
  transition: 0.4s ease-in;
`

const NoTransactionButtonCover = styled(NoTransactionButton)``

export default React.memo(MobileTransactionDropdown)
