import { useQuery } from "react-query"
import { useWSCContext } from "context/MilkomedaContext"
import { useTangleship } from "./useTangleship"
import axios from "axios"
import {
  BLACKLISTED_AUCTIONS,
  Chains,
  WHITELISTED_AUCTIONS,
} from "@tangleswap/sdk"
import { getTestnetChains } from "utils/toSignificant"

let isPageLoaded = false
setTimeout(() => {
  isPageLoaded = true
}, 1000 * 5)

export default function useAuctionArray(auctionAddress?: string): {
  allTangleAuctions: any
  auctionsLoading: any
  refetchAuctions?: any
  singleTangleAuctions: any
  singleAuctionLoading: any
  refetchSingleAuction?: any
  singleAuctionFetching?: any
} {
  const { chainId, l1ChainId } = useWSCContext()
  const { tangleship } = useTangleship()

  const fetchAllAuctions = async () => {
    // return await tangleship.getAllAuctionInfos(false, false) // @dev: don't delete! Fallback option
    const url = `https://backend.tangleswap.space/api/v1/chains/${chainId}/auctions`
    const response = await axios.get(url)
    const filteredData = response.data.filter((auction: any) => {
      const addr = auction.auctionAddress.toLowerCase()
      const whitelisted = WHITELISTED_AUCTIONS.map((a) => a.toLowerCase())
      const blacklisted = BLACKLISTED_AUCTIONS.map((a) => a.toLowerCase())

      const testnetChains = getTestnetChains()
      const whitelistCheck =
        whitelisted.includes(addr) || testnetChains.includes(chainId)
      const blacklistCheck = !blacklisted.includes(addr)

      return whitelistCheck && blacklistCheck
    })
    return filteredData
  }

  const fetchSingleAuction = async (address: string) => {
    const url = `https://backend.tangleswap.space/api/v1/chains/${chainId}/auctions/${address}`
    // if (isPageLoaded) axios.get(url)
    axios.get(url)
    // const response = await axios.get(url) // sadly Tangular's API has a bit of delay, so better to always ask for fresh data
    // console.log("sevSingleAuction", url, response)
    // return response.data
    return await tangleship.getAuctionInfo(address) // @dev: don't delete! Fallback option
  }

  const {
    data: TangleAuctionData,
    isLoading: allAuctionsLoading,
    refetch: refetchAuction,
  } = useQuery(["tangleswap-auction-info", [chainId]], fetchAllAuctions, {
    retry: 3, // Number of retry attempts
    retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: 1000 * 60 * 30, // Data will become stale after 30 minutes
    cacheTime: 1000 * 60 * 60 * 6, // 6 hours
    enabled: (!!chainId || !!l1ChainId) && !!tangleship,
  })

  const {
    data: SingleAuctionData,
    isLoading: singleAuctionLoading,
    refetch: refetchSingleAuction,
    isFetching: singleAuctionFetching,
  } = useQuery(
    ["tangleswap-single-auction-info", [auctionAddress, chainId]],
    () => fetchSingleAuction(auctionAddress),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: 1000 * 60 * 30, // Data will become stale after 30 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!auctionAddress && (!!chainId || !!l1ChainId) && !!tangleship,
    }
  )

  return {
    allTangleAuctions: TangleAuctionData,
    auctionsLoading: allAuctionsLoading,
    refetchAuctions: refetchAuction,
    singleTangleAuctions: SingleAuctionData,
    singleAuctionLoading: singleAuctionLoading,
    refetchSingleAuction: refetchSingleAuction,
    singleAuctionFetching: singleAuctionFetching,
  }
}
