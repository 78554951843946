import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import { Caption } from "styles/TextStyles"
import { PairInput } from "./PairInput"

interface PairProps {
  value?: any
  onUserInput?: (value: any) => void
  proMode?: boolean
  name: string
}
const PairInitialize: FC<PairProps> = (props) => {
  const { onUserInput, value, proMode, name } = props

  const displayText = proMode
    ? "This pool must be initialized before you can add liquidity. Steps to initialize: (1) type the starting price for this pool, (2) set a price range, and (3) enter your deposit amount."
    : "This pool must be initialized before you can add liquidity. To initialize, please type the starting price for the pool, and enter your deposit amount."

  return (
    <Body>
      <Cover>
        <Middle>
          <MiddleText>{displayText}</MiddleText>
        </Middle>
        <Bottom>
          <PairInput
            name={name}
            placeholder="0.0"
            value={value}
            onUserInput={onUserInput}
          />
        </Bottom>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  max-width: 1200px;
  height: 158px;
  width: 100%;
  margin: 19px auto 19px auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`

const Middle = styled.div`
  min-height: 80px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 14px 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${TangleColors.tangleRed05};
  color: ${TangleColors.tangleRed};
  align-items: center;
`

const MiddleText = styled(Caption)`
  color: ${TangleColors.tangleRed};
  width: 100%;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
`
const Bottom = styled.div`
  margin: 8px 0;
  width: 100%;
`

export default PairInitialize
