import React, { FC } from 'react'

import { TangleColors } from 'styles/ColorStyles'
import styled from 'styled-components'
import { Header7 } from 'styles/TextStyles'
import { ModalButton } from 'styles/ButtonStyles'
import { CiWarning } from 'react-icons/ci'
interface RejectedProps {
  closeConfirmationModal?:()=>void
}

const UserRejectedCover: FC<RejectedProps> = (props) => {
    const { closeConfirmationModal }=props
  return (
    <>
      {" "}
      <SwapWrap>
        <RejectedSwapIcon>
          <UserRejectedIcon />
        </RejectedSwapIcon>
        <SwapText>Transaction rejected</SwapText>

        <WrapButton onClick={closeConfirmationModal}>Dismiss</WrapButton>
      </SwapWrap>
    </>
  )
}
const SwapWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${TangleColors.white};
`
const RejectedSwapIcon = styled.div`
  max-width: 150px;
  height: 120px;
  width: 100%;
  border-radius: 9999px;
  margin: 24px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const SwapText = styled(Header7)`
  color: ${TangleColors.white};
`
const WrapButton = styled(ModalButton)`
  margin: 40px 0;
`

const UserRejectedIcon = styled(CiWarning)`
  max-width: 150px;
  height: 120px;
  width: 100%;
  margin: 0 auto;
  color: ${TangleColors.red};
`
export default UserRejectedCover