import LiquidityPositionsLoadingPlaceholder from "components/Liquidity/component/LiquidityPositionsLoadingPlaceholder"

import styled from "styled-components"

const AuctionPageLoader = () => {
  return (
    <CardLoader>
      {" "}
      <LiquidityPositionsLoadingPlaceholder />
    </CardLoader>
  )
}
const CardLoader = styled.div`
  height: 16px !important;
  overflow: hidden;
`

export default AuctionPageLoader
