import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body1, Header1 } from "styles/TextStyles"
import { HeroLinkButton } from "styles/ButtonStyles"
import { homeCardsData } from "data/HomeCardsData"
import HomeHeroCards from "./components/HomeHeroCards"

const HomeHero = () => {
  return (
    <Body>
      <HeroOverlay />
      <HomeHeroCover>
        <HeroTextCover>
          <Titlewrapper>
            <HeroTitle>The whole universe of DeFi at your fingertips</HeroTitle>
          </Titlewrapper>
          <HeroTextParagh>
            Buy, sell, farm, stake, govern, provide liquidity, and explore.
          </HeroTextParagh>
          <HeroButtonCover>
            <MainHeroButton to="/swap">
              Jump aboard The Tangleship
            </MainHeroButton>
          </HeroButtonCover>
        </HeroTextCover>

        <CardsWrapper>
          {homeCardsData.map((card, index: number) => (
            <HomeHeroCards
              key={index}
              image={card.image}
              className={card.className}
              degree={card.degree}
              cardMargin={card.cardMargin}
            />
          ))}
        </CardsWrapper>
      </HomeHeroCover>
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/defi-fingertips.png")
    no-repeat center center/cover;
  border-bottom: 4px solid ${TangleColors.lighthover};
`

const HomeHeroCover = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
`

const CardsWrapper = styled.div`
  width: 100%;
  /* margin: 0 auto; */
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
  margin: 56px 0 0 0;
  gap: 24px;
  @media only screen and (max-width: 850px) {
    margin: 120px 0 0 0;
    .hide_mobile {
      display: none;
    }
  }
`

const HeroTextCover = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;

  max-width: 600px;

  width: 100%;
  border-radius: 56px;

  margin: 200px auto 100px auto;
  @media only screen and (max-width: 650px) {
    margin: 130px auto 24px auto;
  }
`

const HeroTitle = styled(Header1)`
  text-align: center;
  color: ${TangleColors.white};
  margin: 8px 0;
`
const HeroButtonCover = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px auto;
`

const Titlewrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MainHeroButton = styled(HeroLinkButton)``

const HeroTextParagh = styled(Body1)`
  color: ${TangleColors.white};
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`
const HeroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.55);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  border-radius: inherit;
  height: 100%;
  content: "";
  z-index: 2;
`
export default HomeHero
