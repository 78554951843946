import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import LoadingDots from "components/utils/LoadingDots"
import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionMedium } from "styles/TextStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

interface DataProps {
  tokenLogo?: any
  tokenSymbol?: any
  tokenAmount?: any
}

const BottomTVLRow: FC<DataProps> = (props) => {
  const { tokenLogo, tokenSymbol, tokenAmount } = props

  return (
    <BottomLargeBox>
      <BoxImageWrapper>
        {!!tokenLogo ? (
          <>
            {" "}
            <CurrencyLogo
              src={tokenLogo}
              alt={`tangleswap farms logo for ${tokenSymbol}`}
            />
          </>
        ) : (
          <>
            {" "}
            <CustomSizeWrapper>
              <ConvertTokenSymbol text={tokenSymbol} />
            </CustomSizeWrapper>
          </>
        )}
      </BoxImageWrapper>
      <BoxRowCover>
        <BoxPriceTitle>
          <BoxPriceAmount>
            {formatNumber(Number(tokenAmount), 3)}
          </BoxPriceAmount>
          <BoxDataName>
            {!tokenAmount && !tokenSymbol ? (
              <>
                <LoadingDots />
              </>
            ) : (
              <>{tokenSymbol}</>
            )}
          </BoxDataName>
        </BoxPriceTitle>
      </BoxRowCover>
    </BottomLargeBox>
  )
}

const BottomLargeBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 4px 0;
  width: 100%;
`
const CurrencyLogo = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 9999px;
  object-fit: contain;
`

const BoxImageWrapper = styled.div`
  width: 100%;
  max-width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BoxDataName = styled(Caption)`
  color: ${TangleColors.grayLight};
  text-align: left;
  margin: 0 0 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const BoxPriceAmount = styled(Caption)`
  color: ${TangleColors.grayLight};
  text-align: left;
  margin: 0 0 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BoxPriceTitle = styled(CaptionMedium)`
  color: ${TangleColors.grayLight};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
`

const BoxRowCover = styled.div`
  width: 100%;
  max-width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`
const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;

  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export default BottomTVLRow
