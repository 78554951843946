import { openWSCProgressModal } from "store/actions/WscProgressAction"
import { useAppDispatch } from "store/hooks"

export default function useOpenWSCActionModal(): {
  openWSCModal: any
} {
  const dispatch = useAppDispatch()

  const openWSCModal = () => {
    dispatch(openWSCProgressModal(true))
  }

  return { openWSCModal }
}
