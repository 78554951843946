import React from "react"
import { useEffect } from "react"
import NotFoundContainer from "components/NotFound/NotFoundContainer"
import styled from "styled-components"

const NotFound = () => {
  useEffect(() => {
    document.title = "Ventured too far into deep space..."
  }, [])

  return (
    <Body>
      <NotFoundContainer />
    </Body>
  )
}

const Body = styled.div`
  min-height: 100vh;
`
export default React.memo(NotFound)
