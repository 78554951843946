import React from "react"
import styled from "styled-components"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
interface CurrencyProps {
  token0Info?: any
  token1Info?: any
  token0Logo?: string
  token1Logo?: string
  token0Symbol?: string
  token1Symbol?: string
}
const CurrencyTokenImages: React.FC<CurrencyProps> = (props) => {
  const {
    token0Info,
    token1Info,
    token0Logo,
    token1Logo,
    token0Symbol,
    token1Symbol,
  } = props
  return (
    <SizeImageWrapper>
      <>
        {" "}
        <SizeImages>
          {!!token0Info?.logoURI || !!token0Logo ? (
            <>
              {" "}
              <SizeFirstIcon src={token0Info?.logoURI ?? token0Logo} />
            </>
          ) : (
            <>
              {" "}
              <SizeFirstIconCover>
                <ConvertTokenSymbol text={token0Info?.symbol ?? token0Symbol} />{" "}
              </SizeFirstIconCover>
            </>
          )}
          {!!token1Info?.logoURI || !!token1Logo ? (
            <>
              <SizeSecondIcon src={token1Info?.logoURI ?? token1Logo} />
            </>
          ) : (
            <>
              {" "}
              <SizeSecondIconCover>
                {" "}
                <ConvertTokenSymbol text={token1Info?.symbol ?? token1Symbol} />
              </SizeSecondIconCover>
            </>
          )}
        </SizeImages>
      </>
    </SizeImageWrapper>
  )
}
const SizeImageWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  max-width: 72px;
  width: 100%;
  margin: 0 12px 0 0;
`
const SizeImages = styled.div`
  max-width: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`
const SizeFirstIcon = styled.img`
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 9999px;
  z-index: 5;
`
const SizeSecondIcon = styled.img`
  height: 30px;
  width: 30px;
  object-fit: cover;

  border-radius: 9999px;
`
const SizeFirstIconCover = styled.div`
  height: 30px;
  width: 30px;
  object-fit: cover;

  top: 0;
  border-radius: 9999px;
`
const SizeSecondIconCover = styled.div`
  height: 30px;
  width: 30px;
  object-fit: cover;

  border-radius: 9999px;

  z-index: 3;
`
export default CurrencyTokenImages
