import React, { FC } from "react"
import { TitleText, TitleTextContainer } from "./styles"
import Tooltip from "components/utils/Tooltip"
import styled from "styled-components"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { TangleColors } from "styles/ColorStyles"
import { useAppDispatch } from "store/hooks"

interface ActionNameProps {
  actionTitle?: string
  skipWallet?: () => void
  openWalletOverview?: () => void
}
const ModalActionState: FC<ActionNameProps> = (props) => {
  const { actionTitle, skipWallet, openWalletOverview } = props

  return (
    <>
      {" "}
      <TitleTextContainer>
        <TitleText>{actionTitle}</TitleText>{" "}
        {actionTitle === "Wrapping" ? (
          <>
            {" "}
            <Tooltip
              direction="right"
              text={
                <span>
                  Pro tip: Wrapping is auto-skipped if you have enough L2 token
                  balance! 💡
                  <br />
                  <br /> You can also wrap/unwrap manually and manage your
                  assets on your Wallet section.
                  <SkipButtonCover onClick={openWalletOverview}>
                    <SkipButton>Open Wallet</SkipButton>
                  </SkipButtonCover>
                </span>
              }
            >
              <HelperCover>
                <HelpIcon />
              </HelperCover>
            </Tooltip>
          </>
        ) : actionTitle === "Unwrapping" ? (
          <>
            {" "}
            <Tooltip
              direction="right"
              text={
                <span>
                  Pro tip: If you plan on making more transactions with the same
                  token, keep your funds in the L2 to skip the wrapping step
                  next time! 💡
                  <br />
                  <br /> You can skip the unwrap below, or in the Settings menu
                  with 'Cancel This Transaction'.
                  <SkipButtonCover onClick={skipWallet}>
                    <SkipButton>Skip</SkipButton>
                  </SkipButtonCover>
                </span>
              }
            >
              <HelperCover>
                <HelpIcon />
              </HelperCover>
            </Tooltip>
          </>
        ) : null}
      </TitleTextContainer>
    </>
  )
}

const HelperCover = styled.div`
  margin: 1px 0 0 4px;
`
const HelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  margin: 6px 0 0 2px;
  width: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const SkipButtonCover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
`
const SkipButton = styled.button`
  width: 100%;
  max-width: 650px;
  margin: 4px auto;
  min-height: 34px;
  background: ${TangleColors.heroCTA};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${TangleColors.white};
  border-radius: 10px;
  border: none;
  font-family: "CabinetGrotesk Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
`
export default ModalActionState
