import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmallPara } from "styles/TextStyles"
import { HiOutlineEmojiHappy } from "react-icons/hi"

import { motion, AnimatePresence } from "framer-motion"

interface WarningProps {
  siteWarningState?: boolean
}
const SwapNightFallInfo = () => {
  return (
    <>
      <AnimatePresence>
        <Body
          exit={{ scale: 0.2, y: -100 }}
          transition={{
            duration: 0.3,
            ease: "easeOut",
          }}
          style={{ transformOrigin: "top left" }}
        >
          <Cover>
            <EmojiIconWrapper>
              <EmojiIcon />
            </EmojiIconWrapper>
            <WarningText>
              Enjoy your 10% swap fee discount,{" "}
              <NightFallText> Night Fall </NightFallText>owner!
            </WarningText>
          </Cover>
        </Body>
      </AnimatePresence>
    </>
  )
}

const Body = styled(motion.div)<WarningProps>`
  background-color: transparent;
  border: 1px solid ${TangleColors.tangleYellow};
  display: flex;
  width: 100%;
  max-width: 400px;
  min-height: 32px;
  margin: 24px auto;
  border-radius: 999px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  color: ${TangleColors.tangleYellow};
  transition: 0.4s ease-in;
  box-shadow: rgba(47, 43, 67, 0.1) 0px 1px 3px 0px,
    rgba(47, 43, 67, 0.1) 0px -1px 0px 0px inset;
`

const Cover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`

const WarningText = styled(CaptionSmallPara)`
  color: ${TangleColors.tangleYellow};
  text-align: left;
`
const NightFallText = styled.span`
  color: ${TangleColors.lighthover};
  margin: 0 2px;
`

const EmojiIcon = styled(HiOutlineEmojiHappy)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.tangleYellow};
  cursor: pointer;
`
const EmojiIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  color: ${TangleColors.tangleYellow};
  display: flex;
  justify-items: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.lighthover};
  }
`

export default SwapNightFallInfo
