import { FETCH_UNKNOWN_TOKEN_FIELD } from "./actionsTypes"

export const unknownTokenInfoField = (tokenAddress, tokenField) => {
  return {
    type: FETCH_UNKNOWN_TOKEN_FIELD,
    tokenAddress: tokenAddress,
    tokenField: tokenField,
  }
}

export const updateUnknownTokenField = (tokenAddress, tokenField) => {
  return (dispatch) => {
    dispatch(unknownTokenInfoField(tokenAddress, tokenField))
  }
}
