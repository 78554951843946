import styled from "styled-components"
import { CaptionSmall } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import BottomTVLRow from "./BottomTVLRow"
import { toSignificantDigits } from "utils/toSignificant"

interface RewardProps {
  token0Amount?: number
  token1Amount?: number
  token0Logo?: string
  token1Logo?: string
  token0Symbol?: string
  token1Symbol?: string
}

const BottomTVLDisplay = (props: RewardProps) => {
  const {
    token0Amount,
    token1Amount,
    token0Logo,
    token1Logo,
    token0Symbol,
    token1Symbol,
  } = props

  return (
    <Body>
      <TextTitle>Your TVL</TextTitle>

      <RewardContainer>
        <BottomTVLRow
          tokenAmount={toSignificantDigits(token0Amount, 4, true)}
          tokenSymbol={token0Symbol}
          tokenLogo={token0Logo}
        />
        <BottomTVLRow
          tokenAmount={toSignificantDigits(token1Amount, 4, true)}
          tokenSymbol={token1Symbol}
          tokenLogo={token1Logo}
        />
      </RewardContainer>
    </Body>
  )
}

const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Reward = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

// const Text = styled(CaptionSmall)`
//   color: ${TangleColors.white};
// `
const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  margin: 0 0 2px 0;
`

const CriteriaRow = styled.div`
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  margin-right: 8px;
  width: 18px;
  height: 18px;
  border-radius: 9999px;
`

const RewardsText = styled.span`
  font-weight: 500;
  font-size: 18px;
`

export default BottomTVLDisplay
