import React from "react"
import styled from "styled-components"

import HomeHero from "./HomeHero"
import HomeDappsGrid from "./HomeDappsGrid"
import HomeTradeInsight from "./components/HomeTradeInsight"

const HomeContainer = () => {
  return (
    <Body>
      <HomeHero />
      {/* <HomeTradeInsight /> */}
      <HomeDappsGrid />
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  min-height: 850px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export default HomeContainer
