import React, { useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { AuctionCreationWizardInput } from "components/LaunchPad/types"
import { useWSCContext } from "context/MilkomedaContext"
import { CaptionSmallRegular, Header5Gothic } from "styles/TextStyles"
import CreationContext from "components/LaunchPad/CreateAuction/CreationContext"
import LaunchPadPurplelist from "components/LaunchPad/CreateAuction/components/LaunchPadPurplelist"
import LaunchPadReview from "components/LaunchPad/CreateAuction/components/LaunchPadReview"
import LiquidityLaunchPad from "components/LaunchPad/CreateAuction/components/LiquidityLaunchPad"
import GeneralDetails from "components/LaunchPad/CreateAuction/components/GeneralDetails"
import LaunchPadDetails from "components/LaunchPad/CreateAuction/components/LaunchPadDetails"
import AuctionWalletConnect from "components/LaunchPad/AuctionWalletConnect"
import { useIsSupportedNetwork } from "constants/hooks"
import WrongNetworkHandler from "connect/WrongNetworkHandler"
import CreateAuctionDetails from "components/LaunchPad/CreateAuction/components/CreateAuctionDetails"
import GoBackButton from "components/LaunchPad/CreateAuction/components/GoBackButton"
import { Chains } from "@tangleswap/sdk"
import AuctionCreationCardano from "components/LaunchPad/AuctionCreationCardano"

interface InvestProps {
  active?: boolean
  disabled?: boolean
}
const CreateInvestmentHub = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [tokenDetails, setTokenDetails] = useState({
    tokenSetupType: "not_set",
  } as AuctionCreationWizardInput)
  const { account, l1ChainId } = useWSCContext()
  const supported = useIsSupportedNetwork()
  return (
    <CreationContext.Provider
      value={{
        tokenDetails,
        setTokenDetails,
        currentStep,
        setCurrentStep,
      }}
    >
      <TopBar>
        <Body>
          <Cover>
            <TopRowTextContainer>
              <RowLeft>
                <GoBackButton link="/invest" />
              </RowLeft>
              <RowRight>
                <TopRowText>Create Auction</TopRowText>
                <TopRowSubText>
                  Each of the TangleSwap auction types has their own unique
                  characteristics — choose the one most appropriate for your
                  needs! For more info, please check our{" "}
                  <a
                    href="https://docs.tangleswap.exchange/token/airdrop-program "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Investment Hub docs.
                  </a>
                  .
                </TopRowSubText>
              </RowRight>
            </TopRowTextContainer>
          </Cover>
        </Body>
      </TopBar>
      <Body>
        {}
        <Cover>
          {!account ? (
            <>
              <AuctionWalletConnect />
            </>
          ) : (
            <>
              {[
                Chains.L1_CARDANO,
                Chains.L1_CARDANO_TEST,
                Chains.CARDANO,
                // Chains.CARDANO_TEST,
              ].includes(l1ChainId) ? (
                <>
                  <AuctionCreationCardano />
                </>
              ) : (
                <>
                  {" "}
                  {supported ? (
                    <>
                      <LaunchPadDetails />
                      <GeneralDetails />
                      <CreateAuctionDetails />
                      <LiquidityLaunchPad />
                      <LaunchPadPurplelist />
                      <LaunchPadReview />
                    </>
                  ) : (
                    <>
                      <WrongNetworkHandler />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Cover>
      </Body>
    </CreationContext.Provider>
  )
}

const Body = styled.div`
  width: 100%;
  padding: 32px;
`

const TopBar = styled.div`
  position: relative;
  padding: 12px 0;
  background: ${TangleColors.dockBG};
  min-height: 250px;
  border-top: 2px solid ${TangleColors.white};
  border-bottom: 2px solid ${TangleColors.white};

  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 32px 0;
`

const TopRowTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepCounter = styled.div<InvestProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${(props) =>
    props.active ? TangleColors.cta : TangleColors.darkText};
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.lighterText};
  font-size: 16px;
  font-weight: 500;
  margin-right: 16px;
`

export const ItemBody = styled.div<InvestProps>`
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.active ? "flex-start" : "center")};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`

export const CounterColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ItemTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${TangleColors.white};
`

export const ItemSubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${TangleColors.darkerText};
`

export const NextButton = styled.a<InvestProps>`
  background: ${TangleColors.cta};
  color: ${TangleColors.white};
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 9999px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: 0.4s ease-in;

  &:hover {
    background: ${TangleColors.lighthover};
  }
`

export const BackButton = styled.a`
  color: ${TangleColors.lighthover};
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s ease-in;

  &:hover {
    color: ${TangleColors.cta};
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 450px;
`

export const InputLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${TangleColors.lighterText};
`

export const Input = styled.input`
  background: transparent;
  border: 1px solid ${TangleColors.darkText};
  border-radius: 8px;
  padding: 12px 16px;
  color: ${TangleColors.darkerText};
  font-size: 16px;
  font-weight: 500;
  transition: 0.4s ease-in;

  &:focus {
    outline: none;
    border: 1px solid ${TangleColors.lighthover};
  }
`

export const InputSubLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${TangleColors.darkText};
`

export const InputError = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${TangleColors.red};
`

const TopRowText = styled(Header5Gothic)`
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
`

const TopRowSubText = styled(CaptionSmallRegular)`
  color: ${TangleColors.grayDark};
  margin: 8px 0 0 0;
  a {
    color: ${TangleColors.lighthover};
  }
`

const Cover = styled.div`
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

const RowLeft = styled.div`
  margin: 0 0 16px 0;
`

const RowRight = styled.div``

export const FormRadioInput = styled.input`
  background: transparent;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`

export default CreateInvestmentHub
