import SpinnerCombined from "components/confirmation/SpinnerCombined"
import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

const CustomTokenLoading = () => {
  return (
    <LiquidityChart>
      <Cover>
        <SpinnerCombined />

        <Title>
          <TitleTop>
            <TitleText> Retrieving unknown token info...</TitleText>
          </TitleTop>
        </Title>
      </Cover>
    </LiquidityChart>
  )
}

const LiquidityChart = styled.div`
  width: 100%;
  max-width: 500px;
  height: 350px;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.div`
  color: ${TangleColors.white};
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
const TitleText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 3px 0 0;
`
const TitleTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 8px 0;
`

export default CustomTokenLoading
