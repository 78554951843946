import React, { FC } from "react"
import { useAppSelector } from "store/hooks"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CgArrowsHAlt } from "react-icons/cg"
import { Body2, Body3 } from "styles/TextStyles"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"

interface MiddleProps {
  priceLowerConvert?: any
  token0Info?: any
  token1Info?: any
  priceUpperConvert?: any
}
const PositionMiddle: FC<MiddleProps> = (props) => {
  const { priceLowerConvert, token0Info, token1Info, priceUpperConvert } = props
  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  return (
    <>
      {" "}
      {Boolean(userTradingMode) ? (
        <>
          <SizeBottom>
            <SizeText>
              <MinMaxWrapper>Min:</MinMaxWrapper>{" "}
              {typeof priceLowerConvert === "number"
                ? formatNumber(priceLowerConvert, 4)
                : priceLowerConvert}
            </SizeText>

            <SizeReturnWrapper>
              <ReturnTop>
                <SizeReturnIcon />
              </ReturnTop>
              <ReturnBottom>
                <ReturnText>
                  {token1Info?.symbol} per {token0Info?.symbol}
                </ReturnText>
              </ReturnBottom>
            </SizeReturnWrapper>

            <SizeText>
              <MinMaxWrapper>Max:</MinMaxWrapper>
              {typeof priceUpperConvert === "number"
                ? formatNumber(priceUpperConvert, 4)
                : priceUpperConvert}
            </SizeText>
          </SizeBottom>
        </>
      ) : null}
    </>
  )
}

const SizeBottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 0 12px;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 850px) {
    justify-content: space-between;
    margin: 0;
  }
`
const SizeText = styled(Body2)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`

const MinMaxWrapper = styled(Body3)`
  color: ${TangleColors.grayDark};
  margin: 0 4px 0 0;
`

const SizeReturnWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 22px 16px 0 16px;
  align-items: center;
  justify-content: center;
`
const ReturnTop = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
`
const ReturnBottom = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
`
const ReturnText = styled(Body2)`
  color: ${TangleColors.grayDark};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
`

const SizeReturnIcon = styled(CgArrowsHAlt)`
  color: ${TangleColors.grayDark};
  height: 24px;
  width: 24px;
  path {
    color: ${TangleColors.grayDark};
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
`

export default React.memo(PositionMiddle)
