import React from "react"

interface WalletLoadingProps {
  children?: React.ReactNode
}
export const WalletLoadingContext = React.createContext<any>(undefined)
const WalletContext: React.FC<WalletLoadingProps> = (props) => {
  const { children } = props
  const [walletLoading, setWalletLoading] = React.useState<boolean>(false)

  const value = {
    walletLoading,
    setWalletLoading,
  }

  return (
    <WalletLoadingContext.Provider value={value}>
      {children}
    </WalletLoadingContext.Provider>
  )
}

export default React.memo(WalletContext)
