import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store/hooks"
import {
  proCheckDeadlineMinute,
  proCheckSlippageState,
} from "store/actions/SettingsActions"
import {
  proCheckClosedPositionStatus,
  proCheckTradingState,
} from "store/actions/proActions"
import { proCheckFarmsTLPState } from "store/actions/FarmsTLPActions"
import { useWSCContext } from "context/MilkomedaContext"
import { proCheckSiteWarningState } from "store/actions/SiteWarningActions"
import { fetchTokenBalance } from "store/actions/TokenBalanceAction"
import { fetchTokensOnChain } from "store/actions/TokenListAction"
import React, { Suspense, useCallback, useEffect } from "react"
import { GlobalDebug } from "utils/remove-consoles"
import "utils/remove-consoles"
import "utils/console"

import Layout from "container/Layout"
import ScrollToTop from "utils/ScrollTop"
import ScrollRestore from "utils/ScrollRestore"

import RouteSpinner from "components/confirmation/RouteSpinner"
import Home from "pages/HomePage"
import Swap from "pages/swap/Swap"
import AddLiquidity from "pages/pools/AddLiquidity"
import LiquidityPools from "pages/pools/LiquidityPools"
import PoolsDetail from "pages/pools/PoolsDetail"
import IncreaseLiquidity from "pages/pools/IncreaseLiquidity"
import RemoveLiquidity from "pages/pools/RemoveLiquidity"
import NFTStaking from "pages/nft-staking/NFTStaking"
import NftStakingDetails from "pages/nft-staking/NftStakingDetails"
import Farming from "pages/farm/Farming"
import Staking from "pages/stake/Staking"
import InvestmentHub from "pages/investment-hub/InvestmentHub"
import InvHubControlRoom from "pages/investment-hub/InvHubControlRoom"
import AuctionEditPage from "pages/investment-hub/AuctionEditPage"
import CreateInvestmentHub from "pages/investment-hub/CreateInvestmentHub"
import LimitOrder from "pages/limit/LimitOrder"
import AccountOverview from "pages/airdrop/AccountOverview"

import PortfolioOverviewContainer from "container/pages/PortfolioOverviewContainer"
import NotFound from "pages/NotFound"
import { useTangleship } from "utils/useTangleship"
import { checkAuctionToggleStatus } from "store/actions/AuctionToggleAction"
import { useDebounce } from "use-debounce"
import {
  checkConnectedWallet,
  proCheckSelectedNetworkStatus,
} from "store/actions/SelectedWalletAction"
import {
  proCheckTXEffectStatus,
  proCheckTransactionState,
} from "store/actions/CardanoSettingsActions"
import { proCheckwalletOverviewPosition } from "store/actions/WscProgressAction"

// declare global {
//   interface Window {
//     ethereum?: MetaMaskInpageProvider
//   }
// }

const App = () => {
  const dispatch = useAppDispatch()
  const { tangleship } = useTangleship()

  // const checkSlippage = () => {
  //   dispatch(proCheckSlippageState())
  // }
  // const checkDeadlineMinute = () => {
  //   dispatch(proCheckDeadlineMinute())
  // }

  // const checkTradingMode = () => {
  //   dispatch(proCheckTradingState())
  // }
  // const checkFarmsTLPMode = () => {
  //   dispatch(proCheckFarmsTLPState())
  // }
  // const checkTradingPositionStatusMode = () => {
  //   dispatch(proCheckClosedPositionStatus())
  // }
  // const checkSiteWarningMode = () => {
  //   dispatch(proCheckSiteWarningState())
  // }

  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )
  const checkAuctionStatus = useCallback(() => {
    dispatch(checkAuctionToggleStatus())
  }, [dispatch])
  const checkSlippage = useCallback(() => {
    dispatch(proCheckSlippageState())
  }, [dispatch])

  const checkDeadlineMinute = useCallback(() => {
    dispatch(proCheckDeadlineMinute())
  }, [dispatch])

  const checkFarmsTLPMode = useCallback(() => {
    dispatch(proCheckFarmsTLPState())
  }, [dispatch])

  const checkTradingPositionStatusMode = useCallback(() => {
    dispatch(proCheckClosedPositionStatus())
    dispatch(proCheckSelectedNetworkStatus())
    dispatch(proCheckwalletOverviewPosition())
    dispatch(proCheckTradingState())
  }, [dispatch])

  const checkSiteWarningMode = useCallback(() => {
    dispatch(proCheckSiteWarningState())
  }, [dispatch])

  const checkTxTransactionEffect = useCallback(() => {
    dispatch(proCheckTransactionState())
    dispatch(proCheckTXEffectStatus())
  }, [dispatch])

  const {
    account,
    chainId,
    l1ChainId,
    l1Account,
    isWSCConnected,
    originTokens,
    destinationTokens,
    destinationBalanceADA,
  } = useWSCContext()
  const connectedWalletCheck = useCallback(
    (l1AccountInfo) => {
      dispatch(checkConnectedWallet(l1AccountInfo))
    },
    [dispatch]
  )
  useEffect(() => {
    connectedWalletCheck(l1Account)
  }, [connectedWalletCheck, l1Account])

  const checkTokenBalance = useCallback(() => {
    if (!l1ChainId || !l1Account || !tangleswapTokenListOnChain) return
    dispatch(
      fetchTokenBalance(
        l1ChainId,
        l1Account,
        tangleship,
        tangleswapTokenListOnChain,
        isWSCConnected,
        originTokens,
        destinationTokens,
        destinationBalanceADA
      )
    )
  }, [
    // hasLoaded, // TODO: remove this after verifying it works without it, and with Tobi's persistent wallet state
    l1ChainId,
    l1Account,
    tangleswapTokenListOnChain,
    dispatch,
    tangleship,
    isWSCConnected,
    originTokens,
    destinationTokens,
    destinationBalanceADA,
  ])

  useEffect(() => {
    checkTokenBalance() // Initialize balances and set up WebSocket subscriptions

    // @dev: unnecessary, already handled when user closes/refreshes the tab -- leave here for reference though
    // return () => {
    //   // Clean up WebSocket subscriptions and any other resources
    //   unsubscribeBalanceFn.forEach((fn) => fn())
    //   Object.values(unsubscribeSlot0Fn).forEach((fn) => fn())
    // }
  }, [checkTokenBalance])

  const [debouncedAccount] = useDebounce(account, 500)
  const checkUserTransaction = useCallback(() => {
    if (!debouncedAccount || !isWSCConnected) return
    // dispatch(fetchUserTransactionHistory(debouncedAccount, tangleship)) // TODO: bring back after public sale!
  }, [dispatch, tangleship, debouncedAccount, isWSCConnected])

  useEffect(() => {
    checkUserTransaction()
  }, [checkUserTransaction])

  const checkTokenListOnChain = useCallback(() => {
    dispatch(fetchTokensOnChain(l1ChainId))
  }, [dispatch, l1ChainId])

  // const checkTangledTokenDecimal = useCallback(
  //   (chainId: number) => {
  //     if (!chainId) return
  //     dispatch(fetchTokenDecimal(chainId, tangleship))
  //   },
  //   [dispatch, tangleship]
  // )

  useEffect(() => {
    checkTokenListOnChain()
    // checkTangledTokenDecimal(chainId)
  }, [checkTokenListOnChain])

  useEffect(() => {
    checkDeadlineMinute()
    checkSlippage()

    checkTradingPositionStatusMode()
    checkSiteWarningMode()
    checkFarmsTLPMode()
    checkTxTransactionEffect()
    checkAuctionStatus()
  }, [chainId, account])

  useEffect(() => {
    process.env.NODE_ENV === "production" && GlobalDebug(false)
  }, [])
  // useEffect(() => {
  //   window.history.scrollRestoration = "manual"
  // }, [])

  return (
    <>
      <Suspense fallback={<RouteSpinner />}>
        <BrowserRouter>
          <ScrollRestore />
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more, always use search params for slug on both swap, add LP and future features, never use /:slug */}
              <Route path="swap" element={<Swap />} />
              <Route path="pools/add" element={<AddLiquidity />} />
              <Route path="/pools" element={<LiquidityPools />} />
              <Route path="/pools/:id" element={<PoolsDetail />} />
              <Route
                path="/pools/increase/:id"
                element={<IncreaseLiquidity />}
              />
              <Route path="/pools/remove/:id" element={<RemoveLiquidity />} />
              <Route
                path="/nft-staking"
                element={
                  <NFTStaking
                  // stakingContract={stakingContract}
                  />
                }
              />
              <Route
                path="/nft-staking/:slug"
                element={
                  <NftStakingDetails
                  // nftContract={nftContract}
                  // stakingContract={stakingContract}
                  />
                }
              />
              <Route path="/farm" element={<Farming />} />
              <Route path="/stake" element={<Staking />} />
              <Route path="/invest" element={<InvestmentHub />} />
              <Route path="/invest/:address" element={<InvHubControlRoom />} />
              <Route
                path="/invest/:address/edit"
                element={<AuctionEditPage />}
              />
              <Route path="/invest/add" element={<CreateInvestmentHub />} />
              <Route path="limit" element={<LimitOrder />} />
              <Route path="/airdrop" element={<AccountOverview />} />
              <Route
                path="/portfolio"
                element={<PortfolioOverviewContainer />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </Suspense>
    </>
  )
}

export default React.memo(App)
