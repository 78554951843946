import { useState, useEffect, FC } from "react"

interface TimeProps {
  targetTimestamp: number
  onTimeRemainingChange?: (newTimeRemaining: number) => void
}

const TimeRemaining: FC<TimeProps> = (props) => {
  const { targetTimestamp, onTimeRemainingChange } = props
  const [timeRemaining, setTimeRemaining] = useState<string>("")

  useEffect(() => {
    const updateTimeRemaining = () => {
      const newTimeRemaining = computeTimeDifference(targetTimestamp)
      setTimeRemaining(newTimeRemaining)

      if (onTimeRemainingChange) {
        const now = Math.floor(Date.now() / 1000)
        const difference = targetTimestamp - now
        onTimeRemainingChange(difference)
      }
    }

    updateTimeRemaining()

    const intervalId = setInterval(updateTimeRemaining, 1000)

    return () => clearInterval(intervalId)
  }, [targetTimestamp, onTimeRemainingChange])

  const computeTimeDifference = (targetTimestamp: number): string => {
    if (isNaN(targetTimestamp)) return

    const now = Math.floor(Date.now() / 1000)
    const difference = targetTimestamp - now

    if (difference <= 0) return "Time's up!"

    const days = Math.floor(difference / (3600 * 24))
    const hours = Math.floor((difference % (3600 * 24)) / 3600)
    const minutes = Math.floor((difference % 3600) / 60)
    const seconds = difference % 60

    let timeStr = ""

    if (days > 0) timeStr += `${days}D `
    if (hours > 0 || days > 0) timeStr += `${String(hours).padStart(2, "0")}H `
    if (minutes > 0 || hours > 0 || days > 0)
      timeStr += `${String(minutes).padStart(2, "0")}M `
    timeStr += `${String(seconds).padStart(2, "0")}S`

    return timeStr.trim()
  }

  return <> {timeRemaining} </>
}

export default TimeRemaining
