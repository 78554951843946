import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
interface SectionProps {
  text?: string
  active?: boolean
  number?: number
  overviewClick?: (value: number) => void
}
const OverviewSectionTitle: FC<SectionProps> = (props) => {
  const { text, active, overviewClick, number } = props
  return (
    <StepperInfoCover onClick={() => overviewClick?.(number)} active={active}>
      <StepperText active={active}>{text}</StepperText>
    </StepperInfoCover>
  )
}
const StepperInfoCover = styled.div<SectionProps>`
  display: flex;
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px 16px;
  border-radius: 999px;

  background: ${(props) =>
    props.active ? ` ${TangleColors.heroCTA}` : ` transparent`};

  box-shadow: ${(props) =>
    props.active
      ? `0px 0px 0px 3px
    hsla(284.77611940298505, 93.05555555555554%, 71.76470588235294%, 0.5)`
      : ` transparent`};
  /* border-bottom: ${(props) =>
    props.active
      ? `3px solid ${TangleColors.lighthover}`
      : `3px solid transparent`}; */
`

const StepperText = styled(Caption)<SectionProps>`
  color: ${(props) =>
    props.active ? ` ${TangleColors.white}` : ` ${TangleColors.lighthover}`};
  margin: 0;
`

export default OverviewSectionTitle
