import { useAppSelector } from "store/hooks"
import { useWSCContext } from "context/MilkomedaContext"

export function useLiquidityState() {
  return useAppSelector((state) => state.liquidity)
}

export function useLiquidityRangeState() {
  return useAppSelector((state) => state.rangeReducer)
}
export function useChainIdState() {
  const { chainId } = useWSCContext()
  const activeWeb = chainId
  return activeWeb
}
