import { LEFT_RANGE_TYPED_VALUE, RIGHT_RANGE_TYPED_VALUE } from "./actionsTypes"

export const leftRangeTypeInput = (leftRangeTypedValue) => {
  return {
    type: LEFT_RANGE_TYPED_VALUE,
    leftRangeTypedValue: leftRangeTypedValue,
  }
}

export const rightRangeTypeInput = (rightRangeTypedValue) => {
  return {
    type: RIGHT_RANGE_TYPED_VALUE,
    rightRangeTypedValue: rightRangeTypedValue,
  }
}
