import { createReducer } from "@reduxjs/toolkit"
import {
  selectLiquidityCurrency as selectCurrency,
  typeInputLiqudity,
} from "store/actions/LiquidityActions"

import {
  Field,
  isInvalid,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
} from "@tangleswap/sdk"

export interface LiquidityState {
  readonly independentField: Field
  readonly typedValue: any
  readonly [Field.INPUT]: {
    readonly currencyId: string | undefined | null
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId: string | undefined | null
  }
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly outPutAmount: string | null
  readonly inPutAmount: string | null
}
const initialState: LiquidityState = {
  independentField: Field.INPUT,
  typedValue: "",
  [Field.INPUT]: {
    currencyId: "",
  },
  [Field.OUTPUT]: {
    currencyId: "",
  },
  outPutAmount: "",
  inPutAmount: "",
}

const liquidityReducer = createReducer<LiquidityState>(
  initialState,
  (builder) => {
    builder
      .addCase(
        selectCurrency,
        (state, { payload: { field, currencyAddress, chainId } }) => {
          const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT

          if (isInvalid([chainId, currencyAddress, field])) return
          const chainAddress = String(NATIVE_SYMBOL[chainId])?.toLowerCase()
          const wrappedChainAddress = String(
            WRAPPED_ADDRESS[chainId]
          )?.toLowerCase()
          const token0 = String(currencyAddress)?.toLowerCase()
          const token1 = String(state[otherField]?.currencyId)?.toLowerCase()
          const token0Native = token0 === chainAddress
          const token1Native = token1 === chainAddress
          const token0Wrapped = token0 === wrappedChainAddress
          const token1Wrapped = token1 === wrappedChainAddress

          const token0Boolean = token0Native && token1Wrapped
          const token1Boolean = token1Native && token0Wrapped
          const tokenSwitch =
            String(currencyAddress)?.toLowerCase() ===
              String(state[otherField]?.currencyId)?.toLowerCase() ||
            String(currencyAddress)?.toUpperCase() ===
              String(state[otherField]?.currencyId)?.toUpperCase()

          // the case where we have to swap the order
          if (token0Boolean && !token1Boolean && !tokenSwitch) {
            return {
              ...state,
              independentField:
                state.independentField === Field.INPUT
                  ? Field.OUTPUT
                  : Field.INPUT,
              [otherField]: { currencyId: currencyAddress },
              [field]: {
                currencyId: state[field].currencyId,
              },
            }
          }
          if (token1Boolean && !token0Boolean && !tokenSwitch) {
            return {
              ...state,
              independentField:
                state.independentField === Field.INPUT
                  ? Field.OUTPUT
                  : Field.INPUT,
              [otherField]: { currencyId: currencyAddress },
              [field]: {
                currencyId: state[field].currencyId,
              },
            }
          }
          if (tokenSwitch && !token1Boolean && !token0Boolean) {
            return {
              ...state,
              independentField:
                state.independentField === Field.INPUT
                  ? Field.OUTPUT
                  : Field.INPUT,
              [otherField]: { currencyId: state[field].currencyId },
              [field]: {
                currencyId: currencyAddress,
              },
            }
          }
          // the normal case
          return {
            ...state,
            [field]: { currencyId: currencyAddress },
          }
        }
      )

      .addCase(
        typeInputLiqudity,
        (state, { payload: { field, typedValue } }) => {
          return {
            ...state,
            independentField: field,
            typedValue: typedValue,
          }
        }
      )
  }
)
export default liquidityReducer
