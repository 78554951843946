import { TangleColors } from "./ColorStyles"

export const themeVars = {
  "--wsc-border-radius": "24px",
  "--wsc-font-family": "CabinetGrotesk Medium",

  "--wsc-connectbutton-font-size": "18px",
  "--wsc-connectbutton-border-radius": "56px",
  "--wsc-connectbutton-color": TangleColors.white,
  "--wsc-connectbutton-background": TangleColors.cta,
  "--wsc-connectbutton-box-shadow": "none",

  "--wsc-connectbutton-hover-background": TangleColors.white,
  "--wsc-connectbutton-hover-color": TangleColors.cta,
  "--wsc-connectbutton-hover-box-shadow": "none",

  "--wsc-connectbutton-secondary-color": TangleColors.cta,
  "--wsc-connectbutton-secondary-background": TangleColors.white,
  "--wsc-connectbutton-secondary-box-shadow": "none",

  "--wsc-connectbutton-secondary-hover-background": TangleColors.white,

  "--wsc-primary-button-border-radius": "56px",
  "--wsc-primary-button-color": TangleColors.white,
  "--wsc-primary-button-background": TangleColors.tangleBlack,
  "--wsc-primary-button-box-shadow": `0 0 0 1px ${TangleColors.lighthover}, 0px 2px 4px rgba(0, 0, 0, 0.02)`,

  "--wsc-primary-button-hover-background": TangleColors.tangleBlack,
  "--wsc-primary-button-hover-box-shadow": `0 0 10px 2px ${TangleColors.lighthover}, 0px 0 0 rgba(0, 0, 0, 0.02)`,

  "--wsc-secondary-button-border-radius": "56px",
  "--wsc-secondary-button-color": TangleColors.white,
  "--wsc-secondary-button-background": TangleColors.tangleBlack,
  "--wsc-secondary-button-box-shadow": `0 0 0 1px ${TangleColors.lighthover}, 0px 2px 4px rgba(0, 0, 0, 0.02)`,

  "--wsc-secondary-button-hover-background": TangleColors.tangleBlack,
  "--wsc-secondary-button-hover-box-shadow": `0 0 10px 2px ${TangleColors.lighthover}, 0px 0 0 rgba(0, 0, 0, 0.02)`,

  "--wsc-focus-color": TangleColors.lighthover,
  "--wsc-modal-box-shadow": `0px 3px 16px rgba(0, 0, 0, 0.08)`,
  "--wsc-body-color": TangleColors.white,
  "--wsc-body-color-muted": "#9196A1",
  "--wsc-body-color-muted-hover": "#000000",
  "--wsc-body-background": TangleColors.tangleBlack,
  "--wsc-body-background-transparent": "rgba(255,255,255,0)",
  "--wsc-body-background-secondary": TangleColors.tangleBlack,
  "--wsc-body-background-secondary-hover-background": TangleColors.tangleBlack,
  "--wsc-body-background-secondary-hover-outline": TangleColors.cta,
  "--wsc-body-background-tertiary": TangleColors.tangleBlack,
  "--wsc-tertiary-border-radius": "56px",
  "--wsc-tertiary-box-shadow": "inset 0 0 0 1px rgba(0, 0, 0, 0.04)",

  "--wsc-body-action-color": "#999999",
  "--wsc-body-divider": TangleColors.lighthover,
  "--wsc-body-color-danger": "#FF4E4E",
  "--wsc-body-color-valid": "#32D74B",

  "--wsc-tooltip-background": TangleColors.tangleBlack,
  "--wsc-tooltip-background-secondary": TangleColors.tangleBlack,
  "--wsc-tooltip-color": TangleColors.white,
  "--wsc-tooltip-shadow": `0px 0px 5px ${TangleColors.lighthover}`,

  "--wsc-spinner-color": "var(--wsc-focus-color)",

  "--wsc-alert-color": "#9196A1",
  "--wsc-alert-background": "#F6F8FA",
  "--wsc-alert-box-shadow": "inset 0 0 0 1px rgba(0, 0, 0, 0.04)",
  "--wsc-alert-border-radius": "56px",

  "--wsc-stepper-background-circle": TangleColors.cta,
  "--wsc-stepper-current-background-circle": TangleColors.lighthover,
  "--wsc-stepper-separator": "#ffffff",
}
