import { updateObject } from "store/utility"
import { TANGLE_SITEWARNING } from "../actions/actionsTypes"

export interface SiteWarningMode {
  siteWarning: any
}
const initialState: SiteWarningMode = {
  siteWarning: false,
}

const updateSiteWarning = (state, action) => {
  return updateObject(state, {
    siteWarning: action.siteWarning,
  })
}

const SiteWarningReducer = (state = initialState, action) => {
  switch (action.type) {
    case TANGLE_SITEWARNING:
      return updateSiteWarning(state, action)

    default:
      return state
  }
}

export default SiteWarningReducer
