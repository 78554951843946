import { createReducer } from "@reduxjs/toolkit"
import { Field } from "@tangleswap/sdk"

import { increaseTypeInput } from "store/actions/IncreaseLiquidityAction"

export interface IncreaseLPProps {
  independentField: any
  typedValue: any
}
const initialState: IncreaseLPProps = {
  independentField: Field.INPUT,
  typedValue: "",
}

const IncreaseLiquidityReducer = createReducer<IncreaseLPProps>(
  initialState,
  (builder) => {
    builder.addCase(
      increaseTypeInput,
      (state, { payload: { field, typedValue } }) => {
        return {
          ...state,
          independentField: field,
          typedValue: typedValue,
        }
      }
    )
  }
)

export default IncreaseLiquidityReducer
