import React from "react"
import styled from "styled-components"

import { TangleColors } from "styles/ColorStyles"
import SpinnerCombined from "./SpinnerCombined"

const RouteSpinner = () => {
  return (
    <RouteSpinnerCover>
      <SpinnerWrapper>
        <SpinnerCombined />
      </SpinnerWrapper>
    </RouteSpinnerCover>
  )
}
const RouteSpinnerCover = styled.div`
  background: ${TangleColors.black};
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`
const SpinnerCover = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  height: 160px;
  width: 100%;
  margin: 0 auto;
`
const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  height: 160px;
  width: 100%;
  margin: -32px auto;
`

const SpinnerTop = styled.div`
  position: absolute;
  /* top: 50%;
  left: 50%; */

  transform: translate(-0%, -0%);

  max-width: 160px;
  height: 160px;
  width: 100%;

  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const SpinnerMidle = styled.div`
  position: absolute;
  /* top: 30%;
  left: 30%; */
  width: 100%;
  transform: translate(-0%, -0%);
  max-width: 140px;
  height: 140px;
  width: 100%;
  transform: rotate(-90deg);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export default RouteSpinner
