import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { TiWarningOutline } from "react-icons/ti"
import { CaptionSmall } from "styles/TextStyles"
interface RangeInvalidProps {
  disabledByBothTokens?: boolean
  token0Disabled?: boolean
  token1Disabled?: boolean
}
const RangeInvalid: FC<RangeInvalidProps> = (props) => {
  const { disabledByBothTokens, token0Disabled, token1Disabled } = props
  return (
    <Body>
      <Cover>
        <TopWrapper>
          <TopIcon />
          <TopText>
            {disabledByBothTokens ? (
              <>
                Invalid range selected. The min price must be lower than the max
                price.
              </>
            ) : (
              <>
                {token1Disabled || token0Disabled ? (
                  <>
                    Your position will not earn fees or be used in trades until
                    the market price moves into your range.
                  </>
                ) : (
                  <>
                    Invalid range selected. The min price must be lower than the
                    max price.
                  </>
                )}
              </>
            )}
          </TopText>
        </TopWrapper>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  max-width: 423px;
  height: 58px;
  width: 100%;
  margin: 16px auto;
  padding: 24px;
  border-radius: 12px;
  background-color: ${TangleColors.tangleYellow05};
  color: ${TangleColors.tangleYellowPrimary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 700px) {
    margin: 56px auto 8px auto;
  }
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TopWrapper = styled.div`
  /* display: flex;
  flex-direction: row;

  align-items: center;
  margin: 0 0 8px 0; */
  display: grid;
  grid-template-columns: 20px auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
`
const TopIcon = styled(TiWarningOutline)`
  width: 32px;
  height: 32px;
`
const TopText = styled(CaptionSmall)`
  color: ${TangleColors.tangleYellowPrimary};
  margin: 0 0 0 8px;
`

export default RangeInvalid
