import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { toast as SonnerToast } from "sonner"
import { CaptionSmall, Subtitle } from "styles/TextStyles"
import { HiOutlineDuplicate } from "react-icons/hi"

import { isInvalid } from "@tangleswap/sdk"
import { hostLink } from "api/siteURL"

import { useWSCContext } from "context/MilkomedaContext"
interface ReferralProps {
  showContainer?: boolean
}
const AccountReferralRow = () => {
  const [referralAccount, setReferralAccount] = useState<any>(undefined)

  const CopyAddress = useCallback(() => {
    navigator.clipboard.writeText(
      `https://${hostLink}swap/?referral=${referralAccount}`
    )
    SonnerToast.success("Your Referral Link copied to clipboard")
  }, [referralAccount])
  const { account } = useWSCContext()

  useEffect(() => {
    if (isInvalid([account])) return

    setReferralAccount(account)
  }, [account])

  return (
    <ReferralAddressCover
      showContainer={referralAccount === undefined || account === null}
    >
      <AccountTitleText>Your referral URL:</AccountTitleText>
      <ReferralAddressWrapper onClick={CopyAddress}>
        <ReferralAddressHref target="_blank" rel="noopener noreferrer">
          <ReferralAddressText>
            {`${hostLink}swap/?referral=${referralAccount}`}
          </ReferralAddressText>{" "}
        </ReferralAddressHref>

        <IconCover>
          <ReferralAddressCopy onClick={CopyAddress} />
        </IconCover>
      </ReferralAddressWrapper>
    </ReferralAddressCover>
  )
}

const ReferralAddressCover = styled.div<ReferralProps>`
  position: relative;

  max-width: 970px;
  border-radius: 12px;
  background: transparent;

  width: 100%;
  margin: 8px 0 0 0;
  display: ${(props) => (props.showContainer ? "none" : "flex")};
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  cursor: pointer;
  border: 1px solid transparent;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`
const AccountTitleText = styled(Subtitle)`
  color: ${TangleColors.icon};
  margin: 0 12px 0 0;
  text-align: left;
  width: 100%;
  max-width: 250px;
  white-space: nowrap;
`
const ReferralAddressHref = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const ReferralAddressWrapper = styled.div`
  width: 100%;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lightPurple};
  max-width: 780px;
  margin: 8px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  border-radius: 12px;
`
const ReferralAddressText = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  white-space: nowrap;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in;
`
const IconCover = styled.div`
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const ReferralAddressCopy = styled(HiOutlineDuplicate)`
  height: 28px;
  width: 28px;
  cursor: pointer;
  margin: 0 0 0 4px;
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
`

export default AccountReferralRow
