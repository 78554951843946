export interface StarsDataProps {
  color?: any
  position?: any
  size?: any
  speed?: any
}

export const starsData: StarsDataProps[] = [
  { color: "#8F4EAD", position: "30%", size: "3px", speed: "145.8s" },
  { color: "#8F4EAD", position: "17%", size: "2px", speed: "185.77s" },
  { color: "#8F4EAD", position: "91%", size: "1px", speed: "109.23s" },
  { color: "#4C0066", position: "53%", size: "1px", speed: "152.09s" },
  { color: "#D874FA", position: "43%", size: "1px", speed: "198.9s" },
  { color: "#000000", position: "1%", size: "1px", speed: "63.48s" },
  { color: "#8F4EAD", position: "68%", size: "0px", speed: "159.01s" },
  { color: "#740E95", position: "74%", size: "1px", speed: "125.52s" },
  { color: "#4C0066", position: "69%", size: "2px", speed: "179.22s" },
  { color: "#D874FA", position: "98%", size: "3px", speed: "134.15s" },
  { color: "#740E95", position: "81%", size: "1px", speed: "77.58s" },
  { color: "#D874FA", position: "73%", size: "1px", speed: "120.95s" },
  { color: "#8F4EAD", position: "26%", size: "1px", speed: "162.71s" },
  { color: "#4C0066", position: "16%", size: "2px", speed: "96.28s" },
  { color: "#4C0066", position: "4%", size: "1px", speed: "93.76s" },
  { color: "#740E95", position: "41%", size: "0px", speed: "55.44s" },
  { color: "#D874FA", position: "101%", size: "0px", speed: "83.53s" },
  { color: "#D874FA", position: "7%", size: "1px", speed: "136.48s" },
  { color: "#FFFFFF", position: "85%", size: "3px", speed: "73.97s" },
  { color: "#8F4EAD", position: "5%", size: "0px", speed: "95.75s" },
  { color: "#8F4EAD", position: "15%", size: "1px", speed: "93.29s" },
  { color: "#8F4EAD", position: "55%", size: "3px", speed: "146.27s" },
  { color: "#D874FA", position: "42%", size: "2px", speed: "137.26s" },
  { color: "#8F4EAD", position: "61%", size: "1px", speed: "128.18s" },
  { color: "#8F4EAD", position: "39%", size: "3px", speed: "87.47s" },
  { color: "#D874FA", position: "62%", size: "3px", speed: "160.05s" },
  { color: "#740E95", position: "25%", size: "3px", speed: "88.98s" },
  { color: "#740E95", position: "18%", size: "2px", speed: "152.59s" },
  { color: "#000000", position: "83%", size: "3px", speed: "96.62s" },
  { color: "#8F4EAD", position: "68%", size: "2px", speed: "162.7s" },
  { color: "#D874FA", position: "79%", size: "3px", speed: "140.53s" },
  //   { position: "34%", size: "1px", speed: "60.69s" },
  { color: "#8F4EAD", position: "81%", size: "1px", speed: "37.37s" },
  { color: "#740E95", position: "9%", size: "1px", speed: "53.91s" },
  { color: "#4C0066", position: "4%", size: "1px", speed: "196.56s" },
  { color: "#FFFFFF", position: "34%", size: "1px", speed: "104.34s" },
  { color: "#8F4EAD", position: "7%", size: "1px", speed: "30.36s" },
  { color: "#8F4EAD", position: "75%", size: "1px", speed: "127.24s" },
  { color: "#FFFFFF", position: "43%", size: "1px", speed: "106.22s" },
  { color: "#740E95", position: "15%", size: "3px", speed: "112.5s" },
  { color: "#000000", position: "64%", size: "1px", speed: "142.04s" },
  { color: "#000000", position: "0%", size: "1px", speed: "163.35s" },
  { color: "#000000", position: "77%", size: "1px", speed: "178.74s" },
  { color: "#FFFFFF", position: "71%", size: "2px", speed: "55.69s" },
  { color: "#D874FA", position: "65%", size: "2px", speed: "97.39s" },
  { color: "#4C0066", position: "75%", size: "2px", speed: "154.6s" },
  //   { position: "43%", size: "2px", speed: "78.05s" },
  { color: "#FFFFFF", position: "95%", size: "1px", speed: "52.03s" },
  { color: "#4C0066", position: "39%", size: "0px", speed: "165.29s" },
  //   { position: "49%", size: "1px", speed: "33.01s" },
  { color: "#740E95", position: "19%", size: "1px", speed: "198.11s" },
  { color: "#FFFFFF", position: "79%", size: "1px", speed: "160.18s" },
  { color: "#740E95", position: "77%", size: "2px", speed: "104.27s" },
  { color: "#000000", position: "58%", size: "1px", speed: "35.9s" },
  //   { position: "39%", size: "2px", speed: "162.77s" },
  { color: "#8F4EAD", position: "48%", size: "3px", speed: "77.65s" },
  { color: "#740E95", position: "39%", size: "1px", speed: "99.11s" },
  { color: "#740E95", position: "86%", size: "0px", speed: "165.18s" },
  { color: "#4C0066", position: "53%", size: "3px", speed: "155.91s" },
  { color: "#740E95", position: "76%", size: "1px", speed: "37.64s" },
  { color: "#4C0066", position: "98%", size: "1px", speed: "79.45s" },
  //   { position: "73%", size: "2px", speed: "67.99s" },
  { color: "#4C0066", position: "34%", size: "2px", speed: "94.52s" },
  { color: "#FFFFFF", position: "54%", size: "1px", speed: "36.47s" },
  { color: "#FFFFFF", position: "14%", size: "1px", speed: "107.09s" },
  { color: "#D874FA", position: "65%", size: "3px", speed: "124.53s" },
  { color: "#000000", position: "20%", size: "2px", speed: "147.15s" },
  { color: "#D874FA", position: "53%", size: "1px", speed: "127.48s" },
  { color: "#740E95", position: "50%", size: "2px", speed: "47.68s" },
  { color: "#740E95", position: "48%", size: "1px", speed: "42.41s" },
  { color: "#FFFFFF", position: "45%", size: "2px", speed: "171.77s" },
  //   { position: "37%", size: "2px", speed: "71.78s" },
  //   { position: "85%", size: "1px", speed: "160.04s" },
  { color: "#8F4EAD", position: "97%", size: "2px", speed: "112.97s" },
  { color: "#740E95", position: "68%", size: "2px", speed: "75.53s" },
  //   { position: "58%", size: "0px", speed: "46.38s" },
  { color: "#4C0066", position: "55%", size: "1px", speed: "143.03s" },
  { color: "#740E95", position: "19%", size: "1px", speed: "193.17s" },
  { color: "#740E95", position: "101%", size: "3px", speed: "169.17s" },
  { color: "#D874FA", position: "82%", size: "3px", speed: "152.63s" },
  { color: "#D874FA", position: "47%", size: "1px", speed: "110.16s" },
  { color: "#8F4EAD", position: "64%", size: "1px", speed: "177.04s" },
  { color: "#FFFFFF", position: "7%", size: "2px", speed: "182.98s" },
  { color: "#D874FA", position: "41%", size: "2px", speed: "174.11s" },
  { color: "#8F4EAD", position: "32%", size: "1px", speed: "68.41s" },
  { color: "#000000", position: "70%", size: "2px", speed: "50.24s" },
  { color: "#4C0066", position: "29%", size: "2px", speed: "131.72s" },
  { color: "#FFFFFF", position: "70%", size: "3px", speed: "106.56s" },
  { color: "#D874FA", position: "51%", size: "1px", speed: "104.86s" },
  { color: "#4C0066", position: "40%", size: "1px", speed: "134.18s" },
  { color: "#4C0066", position: "78%", size: "2px", speed: "72.85s" },
  { color: "#FFFFFF", position: "15%", size: "3px", speed: "80.66s" },
  { color: "#740E95", position: "25%", size: "1px", speed: "124.23s" },
  { color: "#000000", position: "78%", size: "1px", speed: "96.52s" },
  { color: "#D874FA", position: "39%", size: "2px", speed: "168.62s" },
  { color: "#D874FA", position: "20%", size: "1px", speed: "73.55s" },
  { color: "#FFFFFF", position: "61%", size: "0px", speed: "145.63s" },
  { color: "#4C0066", position: "95%", size: "1px", speed: "152.64s" },
  { color: "#4C0066", position: "72%", size: "1px", speed: "47.41s" },
  { color: "#8F4EAD", position: "22%", size: "1px", speed: "145.56s" },
  { color: "#740E95", position: "30%", size: "2px", speed: "130.95s" },
  { color: "#D874FA", position: "38%", size: "1px", speed: "118.13s" },
  { color: "#8F4EAD", position: "51%", size: "3px", speed: "75.65s" },
  { color: "#000000", position: "24%", size: "3px", speed: "64.71s" },
  { color: "#4C0066", position: "77%", size: "0px", speed: "109.28s" },
  { color: "#740E95", position: "69%", size: "1px", speed: "171.73s" },
  { color: "#D874FA", position: "89%", size: "1px", speed: "78.11s" },
  //   { position: "61%", size: "1px", speed: "176.81s" },
  { color: "#4C0066", position: "58%", size: "1px", speed: "125.2s" },
  { color: "#FFFFFF", position: "93%", size: "2px", speed: "97.03s" },
  { color: "#FFFFFF", position: "41%", size: "3px", speed: "43.35s" },
  { color: "#D874FA", position: "52%", size: "3px", speed: "125.83s" },
  { color: "#4C0066", position: "23%", size: "0px", speed: "196.83s" },
  //   { position: "44%", size: "2px", speed: "77.63s" },
  //   { position: "18%", size: "3px", speed: "58.47s" },
  { color: "#4C0066", position: "92%", size: "1px", speed: "66.62s" },
  { color: "#D874FA", position: "56%", size: "3px", speed: "37.32s" },
  { color: "#4C0066", position: "27%", size: "2px", speed: "177.05s" },
  { color: "#4C0066", position: "46%", size: "2px", speed: "158.96s" },
  { color: "#740E95", position: "33%", size: "2px", speed: "174.61s" },
  { color: "#740E95", position: "33%", size: "3px", speed: "92.23s" },
  { color: "#000000", position: "35%", size: "2px", speed: "43.88s" },
  { color: "#D874FA", position: "34%", size: "2px", speed: "187.09s" },
  { color: "#8F4EAD", position: "7%", size: "1px", speed: "138.31s" },
  { color: "#FFFFFF", position: "16%", size: "1px", speed: "127.01s" },
  { color: "#FFFFFF", position: "96%", size: "3px", speed: "113.29s" },
  { color: "#740E95", position: "98%", size: "2px", speed: "90s" },
  { color: "#8F4EAD", position: "79%", size: "1px", speed: "180.74s" },
  { color: "#FFFFFF", position: "27%", size: "2px", speed: "64.8s" },
  { color: "#740E95", position: "68%", size: "2px", speed: "146.61s" },
  { color: "#4C0066", position: "54%", size: "1px", speed: "54.33s" },
  { color: "#D874FA", position: "11%", size: "2px", speed: "184.27s" },
  { color: "#D874FA", position: "78%", size: "3px", speed: "163.58s" },
  { color: "#740E95", position: "43%", size: "1px", speed: "48.73s" },
  { color: "#8F4EAD", position: "33%", size: "1px", speed: "65.48s" },
  { color: "#4C0066", position: "68%", size: "1px", speed: "187.13s" },
  { color: "#8F4EAD", position: "10%", size: "1px", speed: "99.19s" },
  { color: "#D874FA", position: "79%", size: "0px", speed: "81.33s" },
  { color: "#4C0066", position: "41%", size: "2px", speed: "188.02s" },
  { color: "#D874FA", position: "2%", size: "1px", speed: "84.37s" },
  { color: "#8F4EAD", position: "16%", size: "3px", speed: "121.33s" },
  { color: "#740E95", position: "80%", size: "2px", speed: "162.92s" },
  //   { position: "59%", size: "2px", speed: "118.14s" },
  { color: "#D874FA", position: "1%", size: "2px", speed: "121.89s" },
  //   { position: "51%", size: "3px", speed: "159.89s" },
  { color: "#4C0066", position: "3%", size: "1px", speed: "103.04s" },
  { color: "#D874FA", position: "40%", size: "0px", speed: "147.74s" },
  { color: "#FFFFFF", position: "62%", size: "1px", speed: "73.95s" },
  //   { position: "29%", size: "2px", speed: "87.57s" },
  { color: "#4C0066", position: "66%", size: "1px", speed: "72.63s" },
]
