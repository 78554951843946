import React, { FC, useRef } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { closeConnectWalletModal } from "store/actions/modalActions"
import { GrClose } from "react-icons/gr"
import { useAppDispatch } from "store/hooks"
import metamask from "assets/metamask.png"
interface MetaMaskProps {
  open?: boolean
}
const NoMetaMask: FC<MetaMaskProps> = () => {
  const dispatch = useAppDispatch()
  const bodyRef = useRef<HTMLDivElement | any>()
  const bodyClick = (e: any) => {
    if (e?.target === bodyRef.current) {
      dispatch(closeConnectWalletModal())
    }
  }
  const closeMeta = (e: any) => {
    dispatch(closeConnectWalletModal())
  }
  return (
    <ModalCover onClick={bodyClick} ref={bodyRef}>
      <Body>
        <ModalClose onClick={closeMeta} />
        <Cover>
          <MetamaskLogo src={metamask} />
          <MetaMaskText>
            Hold on, Explorer — it looks like you {"don't"} have Metamask
            installed, but you will need it before getting aboard{" "}
            <i>The Tangleship.</i>
            <br />
            <br />
            <a
              href="https://metamask.app.link/dapp/app.tangleswap.exchange"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to get started!
            </a>
          </MetaMaskText>
        </Cover>
      </Body>
    </ModalCover>
  )
}
const ModalCover = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 920;
  background: rgba(255, 255, 255, 0.4);
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 16px;
`
const Body = styled.div`
  width: 100%;
  min-height: 300px;
  max-width: 500px;
  background: ${TangleColors.modalBG};
  border-radius: 24px;
  margin: 0 auto;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 900;

  border: 1px solid ${TangleColors.lighthover};
  @media only screen and (max-width: 650px) {
    padding: 10px;
  }
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
`
const MetaMaskText = styled(Caption)`
  color: ${TangleColors.white};
  text-align: center;
  margin: 2px auto;
  max-width: 350px;
  width: 100%;
  line-height: 1.6;
  a {
    color: ${TangleColors.lighthover};
    transition: 0.4s ease-in;
    :hover {
      color: ${TangleColors.lightPurple};
    }
  }
`

const MetamaskLogo = styled.img`
  height: 48px;
  width: 48px;
  margin: 0 0 24px 0;
`
const ModalClose = styled(GrClose)`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
export default NoMetaMask
