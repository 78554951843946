import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { ExportSquare } from "iconsax-react"
import { NavbarExternalData } from "data/NavbarData"

interface OpenAddressNavProps {
  openExternalMenu?: boolean
}
const NavbarExternalLinks: FC<OpenAddressNavProps> = (props) => {
  const { openExternalMenu } = props

  return (
    <>
      {openExternalMenu ? (
        <>
          <NavCover>
            <Body>
              <>
                <Cover>
                  <DropUL>
                    {NavbarExternalData.map((external, index: number) => (
                      <DropLi key={index}>
                        <a
                          href={external?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TransactionCover>
                            <InfoRowCover>
                              {" "}
                              <ProfileIcon>{external?.icon}</ProfileIcon>
                              <DropText>{external.text}</DropText>
                            </InfoRowCover>

                            <ExternalLinkIcon size={16} />
                          </TransactionCover>
                        </a>
                      </DropLi>
                    ))}
                  </DropUL>
                </Cover>
              </>
            </Body>
          </NavCover>
        </>
      ) : null}
    </>
  )
}

const NavCover = styled.div`
  position: absolute;

  border-radius: 8px;
  right: 24px;
  top: 4px;
  /* padding: 16px; */
  z-index: 32;
  @media only screen and (max-width: 850px) {
    top: 32px;
    left: 50%;

    z-index: 901;
    background: ${TangleColors.white};
    transform: translate(-50%, 20%);
  }
`
const Body = styled.div`
  position: absolute;
  width: 230px;

  border-radius: 8px;
  right: -120px;
  top: 56px;
  z-index: 32;
  background: ${TangleColors.white};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  display: flex;
  transition: 0.4s ease-in;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .activeTour {
    display: flex;
  }
  .inActiveTour {
    display: none;
  }
  .language {
    background: ${TangleColors.grayLight}!important;
    cursor: not-allowed;
    color: ${TangleColors.grayLighter} !important;

    border: none;
    :hover {
      background: ${TangleColors.grayLight}!important;
      cursor: not-allowed;
      color: ${TangleColors.grayLighter}!important;
      border: none;
    }
  }
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
`
const DropUL = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
  position: relative;
`

const DropLi = styled.li`
  max-width: 240px;
  margin: 6px auto;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 32px;
  padding: 20px 8px;
  transition: 0.4s ease-in;
  border-radius: 12px;
  border: 1.5px solid transparent;
  :hover {
    background: ${TangleColors.snowWhite};
    border: 1.5px solid ${TangleColors.lighthover};
  }
  svg {
    height: 24px;
    width: 24px;
    color: ${TangleColors.icon};
  }

  a {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 24px;
    svg {
      height: 24px;
      width: 24px;
      color: ${TangleColors.icon};
    }
  }
`
const InfoRowCover = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 32px;
  position: relative;
  transition: 0.4s ease-in;
`

const TransactionCover = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 32px;
  position: relative;
  transition: 0.4s ease-in;

  :hover {
    background: ${TangleColors.snowWhite};
  }
  svg {
    height: 24px;
    width: 24px;
    color: ${TangleColors.icon};
  }

  a {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 24px;
    svg {
      height: 24px;
      width: 24px;
      color: ${TangleColors.icon};
    }
  }
`

const DropText = styled(Caption)`
  color: ${TangleColors.icon};
  margin: 0 8px;
`

const ExternalLinkIcon = styled(ExportSquare)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  color: ${TangleColors.icon};
  transition: 0.4s ease-in;
  ${DropLi}:hover & {
    color: ${TangleColors.heroCTA};
  }
`

const ProfileIcon = styled.div`
  height: 22px;
  width: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export default NavbarExternalLinks
