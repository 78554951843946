import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"

import { BsArrowRight } from "react-icons/bs"
import { motion } from "framer-motion"
import { CiWarning } from "react-icons/ci"

interface WarningProps {
  handleOpenDCModal?: () => void
}
const CardanoLedgerWarning: FC<WarningProps> = (props) => {
  const { handleOpenDCModal } = props

  return (
    <>
      <>
        {" "}
        <ShimmerBody onClick={handleOpenDCModal}>
          <Body
            exit={{ scale: 0.2, y: -100 }}
            transition={{
              duration: 0.3,
              ease: "easeOut",
            }}
            style={{ transformOrigin: "top left" }}
          >
            <Cover>
              <InfoIconWrapper>
                <InfoIcon />
              </InfoIconWrapper>
              {/* // href="https://twitter.com/shimmernet/status/1655141796308045824"
              // target="_blank"
              // rel="noopener noreferrer" */}
              <a>
                {" "}
                <WarningText>
                  Currently Hardware Wallets (e.g. Ledger) are not supported
                  with Nami/Eternl. Click here for more info.
                </WarningText>
              </a>
              <NextIconWrapper>
                <NextArrowIcon />
              </NextIconWrapper>
            </Cover>
          </Body>
        </ShimmerBody>
      </>
    </>
  )
}

const ShimmerBody = styled.div`
  display: flex;
  margin: 0 0 56px 0;
`
const Body = styled(motion.div)<WarningProps>`
  border: 1px solid ${TangleColors.red};

  background-color: transparent;
  display: flex;
  width: 100%;
  max-width: 680px;
  min-height: 32px;
  margin: auto;
  border-radius: 999px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  z-index: 90;
  padding: 10px 20px;
  color: ${TangleColors.red};
  transition: 0.4s ease-in;
  box-shadow: rgba(47, 43, 67, 0.1) 0px 1px 3px 0px,
    rgba(47, 43, 67, 0.1) 0px -1px 0px 0px inset;

  :hover {
    border: 1px solid ${TangleColors.tangleRed};
    color: ${TangleColors.tangleRed};
  }
  @media only screen and (max-width: 800px) {
    margin: 32px auto;
  }
`

const Cover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  overflow: hidden;
`

const WarningText = styled(Caption)`
  color: ${TangleColors.red};
  text-align: left;

  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.tangleRed};
  }
`
const NextArrowIcon = styled(BsArrowRight)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.red};
  cursor: pointer;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.tangleRed};
  }
`
const NextIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  color: ${TangleColors.red};
  display: flex;
  justify-items: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.tangleRed};
  }
`
const InfoIcon = styled(CiWarning)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.red};
  cursor: pointer;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.tangleRed};
  }
`
const InfoIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  color: ${TangleColors.red};
  display: flex;
  justify-items: center;
  align-items: center;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    color: ${TangleColors.tangleRed};
  }
`

export default CardanoLedgerWarning
