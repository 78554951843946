import React, { FC } from "react"
import { TangleColors } from "styles/ColorStyles"
import styled from "styled-components"

interface GraphProps {
  startPrice?: number
  endPrice?: number
  currentLevel?: number
  currentPrice?: number
  width?: number // Added width prop
  height?: number // Added height prop
  currentPriceSymbol?: string
  auctionType?: boolean
  color?: any
}

interface LiquidityProps {
  width: number
  height: number
}

const LiquidityGraph: FC<GraphProps> = ({
  startPrice,
  endPrice,
  currentLevel = 0, // Assigning a default value for optional props
  currentPrice,
  currentPriceSymbol,
  auctionType,
  color,
}) => {
  const startY = 47
  const startX = 20
  const endX = 274

  // Calculate the x-coordinate of the current level indicator
  const currentX = startX + (endX - startX) * currentLevel

  return (
    <StyledSVG
      auctionType={auctionType}
      className="text-green"
      viewBox={`0 0 300 200`}
      width={1600}
      height={900}
    >
      {/* Start circle */}
      <circle
        r="4"
        cx={startX}
        cy={startY}
        fill={TangleColors.lighthover}
      ></circle>

      {/* Entire line (black) */}
      <line
        x1={startX}
        y1={startY}
        x2={endX}
        y2={startY}
        stroke={TangleColors.grayDark}
        strokeWidth="2"
        opacity="0.4"
      ></line>

      {/* Completed line (green) */}
      <line
        x1={startX}
        y1={startY}
        x2={currentX}
        y2={startY}
        stroke={TangleColors.lighthover}
        strokeWidth="2"
      ></line>

      {/* Current price line (red) */}
      <line
        x1={currentX}
        y1={startY - 10}
        x2={currentX}
        y2={startY + 10}
        stroke={color}
        strokeWidth="2"
      ></line>

      {/* End circle */}
      <circle r="4" cx={endX} cy={startY} fill={TangleColors.grayDark}></circle>
      {/* Current price label */}
      <text x={currentX} y={startY + 25} fontSize="8" textAnchor="middle">
        {/* Current Price - */}
        {currentPrice} {currentPriceSymbol}
      </text>

      {/* End price label */}
    </StyledSVG>
  )
}

const StyledSVG = styled.svg<LiquidityProps & GraphProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  color: ${TangleColors.white};
  margin-top: ${(props) => (props.auctionType ? `96px` : `64px`)};
  transform: ${(props) =>
    props.auctionType ? `rotate(15deg)` : `rotate(0deg)`};
  text {
    fill: ${TangleColors.white} !important;
  }
`

export default LiquidityGraph
