import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import StatCardRow from "./StatCardRow"
import InfoCard from "./InfoCard"

interface AccountProps {
  tradedVolume?: string
  referralTradedVolume?: string
  totalReferrals?: string
  totalTrades?: number
}
const AccountStatCard: FC<AccountProps> = (props) => {
  const { tradedVolume, referralTradedVolume, totalReferrals, totalTrades } =
    props

  return (
    <AccountCover>
      <StatCardRow
        title="Your Trades"
        leftValue={totalTrades}
        leftTitle="Total trades"
        rightTitle="Total traded volume"
        rightValue={tradedVolume}
      />
      <StatCardRow
        title="Your Referrals"
        leftValue={totalReferrals}
        leftTitle="Total referrals"
        rightTitle="Referrals volume"
        rightValue={referralTradedVolume}
        showInfo={true}
      />
      <InfoCard />
    </AccountCover>
  )
}
const AccountCover = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  gap: 12px;
  margin: 16px 0 40px 0;
  padding: 16px 0;
  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export default AccountStatCard
