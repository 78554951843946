import { createReducer } from "@reduxjs/toolkit"
import {
  closeAddressMenu,
  closeMobileNav,
  closeNetworkMenu,
  closeTransactionBackMenu,
  closeTransactionMenu,
  openAddressMenu,
  openMobileNav,
  openNetworkMenu,
  openTransactionMenu,
} from "store/actions/MobileNavAction"

export interface ModalProps {
  navOpen: boolean
  networkOpen: boolean
  addressOpen: boolean
  transactionOpen: boolean
  activeMenu?: string
}
export const initialState: ModalProps = {
  navOpen: false,
  networkOpen: false,
  addressOpen: false,
  transactionOpen: false,
  activeMenu: "mainDropdown",
}

const MobileNavReducer = createReducer<ModalProps>(initialState, (builder) => {
  builder
    .addCase(openMobileNav, (state) => {
      return {
        navOpen: true,
        networkOpen: true,
        addressOpen: false,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
    .addCase(closeMobileNav, (state) => {
      return {
        navOpen: false,
        networkOpen: false,
        addressOpen: false,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
    .addCase(openNetworkMenu, (state) => {
      return {
        navOpen: true,
        networkOpen: true,
        addressOpen: false,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
    .addCase(closeNetworkMenu, (state) => {
      return {
        navOpen: false,
        networkOpen: false,
        addressOpen: false,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
    .addCase(openAddressMenu, (state) => {
      return {
        navOpen: true,
        networkOpen: false,
        addressOpen: true,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
    .addCase(closeAddressMenu, (state) => {
      return {
        navOpen: false,
        networkOpen: false,
        addressOpen: false,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })

    .addCase(openTransactionMenu, (state) => {
      return {
        navOpen: true,
        networkOpen: false,
        addressOpen: true,
        transactionOpen: true,
        activeMenu: "transactionHistory",
      }
    })

    .addCase(closeTransactionMenu, (state) => {
      return {
        navOpen: false,
        networkOpen: false,
        addressOpen: false,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
    .addCase(closeTransactionBackMenu, (state) => {
      return {
        navOpen: true,
        networkOpen: false,
        addressOpen: true,
        transactionOpen: false,
        activeMenu: "mainDropdown",
      }
    })
})
export default MobileNavReducer
