import React, { FC, useEffect } from "react"
import { ethers } from "ethers"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body1, Header7 } from "styles/TextStyles"
import NFTCard from "./NFTCard"
import { NFTProps } from "./NFTData"
import { DAILY_RATE, ZERO } from "config/constants"
import { toSignificantDigits } from "utils/toSignificant"

interface BodyProps {
  data: NFTProps[]
  rewards: any
  totalLocked: any
}

const NFTBody: FC<BodyProps> = (props) => {
  const { data, rewards, totalLocked } = props

  return (
    <Body>
      {data && data.length > 0 ? (
        <Cover>
          {data.map((data: NFTProps, index: number) => {
            const order = Object.keys(DAILY_RATE).indexOf(data.apr.toString())
            const total = [2500, 1500, 500, 300, 200]

            const lockedValue = totalLocked[order]?.value?._hex || 0
            const result = (lockedValue * 100) / total[order]

            return (
              <NFTCard
                slug={data.slug}
                key={index}
                title={data.title}
                text={data.name}
                image={data.media}
                locked={data.value}
                nftyield={toSignificantDigits(
                  ethers.utils.formatEther(
                    rewards[data?.slug].lt(ZERO) ? ZERO : rewards[data?.slug]
                  ),
                  3
                )}
                apr={data.apr}
                totalLocked={result}
              />
            )
          })}
        </Cover>
      ) : (
        <NFTEmpty>
          <EmptyIcon
            src="https://d3m3d54t409w7t.cloudfront.net/homepage/icon-materializing.png"
            alt="tangle no nft placeholder"
          />
          <NoText>No NFT exist in your search query</NoText>
        </NFTEmpty>
      )}
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Cover = styled.div`
  max-width: 1232px;
  margin: 32px auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  grid-gap: 16px;
  @media only screen and (max-width: 980px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
`

const NFTEmpty = styled.div`
  height: 500px;
  max-width: 600px;
  width: 100%;
  margin: 24px auto;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  position: relative;

  flex-direction: column;
  border-radius: 24px;
  padding: 32px;
`

const NoText = styled(Header7)`
  color: ${TangleColors.white};
  margin: 24px 0 0 0;
`

const EmptyIcon = styled.img`
  max-width: 150px;
  height: 150px;
  width: 100%;
  margin: 8px auto;
  object-fit: contain;
`
export default NFTBody
