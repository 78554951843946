import React, { FC } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import BottomBubble from "./BottomBubble"

interface BubbleAliensProps {
  alienText?: any
  alienImage?: any
  alienHref?: any
  active?: boolean
  dappsClassScreen?: boolean
}

const BottomAliens: FC<BubbleAliensProps> = (props) => {
  const { alienImage, alienHref, alienText } = props
  const location = useLocation()

  const dappsClassName =
    location.pathname === "/nft-staking" ||
    location.pathname === "/nft-staking/:slug" ||
    location.pathname === "/stake" ||
    location.pathname === "/farm" ||
    location.pathname === "/swap" ||
    location.pathname === "/invest" ||
    location.pathname === "/pools/add"

  const dappsClassScreen = location.pathname === "/pools/add"
  const addLPClass = location.pathname === "/pools/add" && "addLPClass"
  return (
    <Cover>
      {dappsClassName ? (
        <Body
          active={!dappsClassName}
          className={`${addLPClass ? `addLPClass` : null}`}
        >
          <BodyHref
            href={`${alienHref}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BottomAlienBubble dappsClassScreen={dappsClassScreen}>
              <BottomBubble text={alienText} />
            </BottomAlienBubble>
          </BodyHref>
          <AlienHref
            href={`${alienHref}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BottomAliensImage alt={`${location.pathname}`} src={alienImage} />
          </AlienHref>
        </Body>
      ) : null}
    </Cover>
  )
}
const Cover = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: -77px;
  left: 303px;
`
const Body = styled.div<BubbleAliensProps>`
  width: 100%;
  max-width: 250px;

  display: ${(props) => (props.active ? "none" : "flex")};
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
  position: relative;
  transition: 0.4s ease-in;
  :hover {
    opacity: 0.65;
  }
`
const BottomAliensImage = styled.img`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 8px;
  position: relative;
  object-fit: contain;
  max-width: 640px;

  height: 300px;
  cursor: pointer;
  transition: 0.4s ease-in;
  ${Body}:hover & {
    opacity: 1;
  }
`
const AlienHref = styled.a`
  transition: 0.4s ease-in;
  ${Body}:hover & {
    opacity: 1;
  }
`
const BodyHref = styled.a`
  position: absolute;
  top: 64px;
  left: -110px;
  object-fit: cover;
  max-width: 188px;
  width: 100%;
  cursor: pointer;
  height: 64px;
  z-index: 3;
  margin: 0 60px 0 0;
  transition: 0.4s ease-in;
`

const BottomAlienBubble = styled.div<BubbleAliensProps>`
  position: absolute;
  top: -12px;
  left: ${(props) => (props.dappsClassScreen ? "-56px" : "-36px")};
  object-fit: contain;
  max-width: 300px;
  width: 100%;
  cursor: pointer;
  height: 100px;
  z-index: 3;
  background-size: 30%;
  transition: 0.4s ease-in;
`
export default BottomAliens
