import { useWSCContext } from "context/MilkomedaContext"
import BeginFarmConfirmationModal from "components/confirmation/BeginFarmConfirmationModal"
import EnableFarmConfirmationModal from "components/confirmation/EnableFarmConfirmationModal"
import StopFarmConfirmationModal from "components/confirmation/StopFarmConfirmationModal"
import { toast as TangleToast } from "sonner"
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react"

import { useAppDispatch, useAppSelector } from "store/hooks"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import { FarmTokenButton, StopFarmTokenButton } from "styles/ButtonStyles"
import LoadingDots from "components/utils/LoadingDots"
import AuctionPageLoader from "components/LaunchPad/AuctionPageLoader"
import { PendingStateContext } from "context/PendingContext"
import useFarmTangleArray from "utils/useFarmTangleArray"
import { useTangleship } from "utils/useTangleship"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import { IFarmData } from "interfaces/wscSwap.interface"
import BeginFarmModal, {
  useBeginFarmModalControl,
} from "components/milkomeda/farm/BeginFarmModal"
import StopFarmModal, {
  useStopFarmModalControl,
} from "components/milkomeda/farm/StopFarmModal"
import {
  openWSCProgressModal,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"
import { setNewTokenId } from "utils/useTangleArray"

interface StakedActionProps {
  staked?: boolean
  isFarmApproved?: any
  isEnded?: boolean
  address?: string
  tokenId?: any
  buttonsLoading?: boolean
}

const StakedAction: FC<StakedActionProps> = (props) => {
  const { staked, isFarmApproved, isEnded, tokenId, address, buttonsLoading } =
    props

  const [enableApproved, setEnableApproved] = useState<any>(undefined)
  const [beginFarmApproved, setBeginFarmApproved] = useState<boolean>(false)
  const [beginFarmLoading, setBeginFarmLoading] = useState<boolean>(false)
  const [stopFarmApproved, setStopFarmApproved] = useState<boolean>(false)
  const [stopFarmLoading, setStopFarmLoading] = useState<boolean>(false)
  const [enablePending, setEnablePending] = useState<boolean>(false)
  const [enablingFarmLoading, setEnablingFarmLoading] = useState<boolean>(false)
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const [enableTxPendModal, setEnableTxPendModal] = useState<boolean>(false)
  const [enableTxSent, setEnableTxSent] = useState<any>(undefined)

  const [beginFarmPending, setBeginFarmPending] = useState<boolean>(false)
  const [stopFarmPending, setStopFarmPending] = useState<boolean>(false)

  const [beginTxSent, setBeginTxSent] = useState<any>(undefined)
  const [stopFarmTxSent, setStopFarmTxSent] = useState<any>(undefined)

  const [beginFarmTxPendModal, setBeginFarmTxPendModal] =
    useState<boolean>(false)

  const [modalTxError, setModalTxError] = useState<any>(false)
  const [stopFarmTxPendModal, setStopFarmTxPendModal] = useState<boolean>(false)

  const modalRef = useRef<any>(null)
  const wscModalRef = useRef<any>(null)
  const { setPendingTransaction } = useContext(PendingStateContext)
  const beginFarmModalRef = useRef<any>(null)
  const stopFarmModalRef = useRef<any>(null)
  const noReward = false

  // const farmsStateRefresh = useAppSelector(
  //   (state) => state.FarmsTLPReducer.farmsStateRefresh
  // )
  const { account, chainId, isWSCConnected } = useWSCContext()

  const { tangleship } = useTangleship()

  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }

  const { refetchTangleFarms, refetchFarmPools } = useFarmTangleArray()

  const enableFarms = () => {
    setEnablePending(true)
    setEnablingFarmLoading(true)
    setEnableTxPendModal(true)
    setPendingTransaction(true)
    setModalTxError(false)

    tangleship?.approveFarm(address).then((res) => {
      if (res?.tx !== null) {
        setEnableTxSent(res?.hash)
        res.tx?.wait().then((receipt) => {
          isFarmApproved?.fn(true)
          setPendingTransaction(false)
          setEnablingFarmLoading(false)
          setEnablePending(false)
          setEnableApproved(true)
          refetchTangleFarms()
          refetchFarmPools()
          setModalTxError(false)
          setEnableTxPendModal(true)

          TangleToast.success("Transaction successful!", {
            description: `Approve farm successful`,
          })
        })
      } else {
        refetchTangleFarms()
        refetchFarmPools()
        setEnablePending(false)
        setEnableApproved(false)
        setEnablePending(true)
        setEnablingFarmLoading(false)
        setModalTxError(true)
        setEnableTxPendModal(true)
        setPendingTransaction(false)
        setEnableTxSent(res?.hash)
        TangleToast.error("Transaction reverted!", {
          description: `User rejected transaction`,
        })
      }
    })
  }

  const prepBeginFarmFn = () => {
    const fn = tangleship?.stakeFarm
    const fnParams = [address, tokenId] as const

    const fnFeedback = (params?) => {
      refetchTangleFarms()
      refetchFarmPools()
      setNewTokenId(params?.[1])
    }

    return { fn, fnParams, fnFeedback }
  }

  const startFarming = async (tokenId: number) => {
    setModalTxError(false)
    setBeginFarmTxPendModal(true)
    setBeginFarmPending(true)
    setBeginFarmLoading(true)
    setPendingTransaction(true)

    const { fn, fnParams, fnFeedback } = prepBeginFarmFn()

    fn?.(...fnParams)?.then((res) => {
      if (res?.tx !== null) {
        res.tx?.wait().then((receipt) => {
          fnFeedback(fnParams)

          setBeginFarmApproved(true)
          setBeginFarmPending(false)
          setBeginFarmLoading(false)
          setBeginFarmTxPendModal(true)
          setPendingTransaction(false)
          setBeginTxSent(res?.hash)
          setModalTxError(false)

          TangleToast.success("Transaction successful", {
            description: `Start farming successful`,
          })
        })
      } else {
        const errorReason = res?.error?.reason

        refetchTangleFarms()
        refetchFarmPools()
        setBeginFarmPending(false)
        setBeginFarmApproved(false)
        setModalTxError(errorReason)

        setBeginTxSent(res?.hash)
        setBeginFarmPending(true)
        setBeginFarmLoading(false)

        setPendingTransaction(false)

        TangleToast.error("Transaction reverted!", {
          description: errorReason.includes("INVALID TOKEN")
            ? "Invalid range for this position"
            : "User rejected transaction",
        })
      }
    })
  }

  const prepStopFarmFn = () => {
    const fn = tangleship?.unstakeFarm
    const fnParams = [address, tokenId, noReward] as const

    const fnFeedback = (params) => {
      refetchTangleFarms()
      refetchFarmPools()
      setNewTokenId(params?.[1])
    }

    return { fn, fnParams, fnFeedback }
  }

  const handleStopFarming = () => {
    setStopFarmTxPendModal(true)
    setStopFarmPending(true)
    setStopFarmLoading(true)
    setModalTxError(false)
    setPendingTransaction(true)

    const { fn, fnParams, fnFeedback } = prepStopFarmFn()

    fn?.(...fnParams)?.then((res) => {
      if (res?.tx !== null) {
        res.tx?.wait().then((receipt) => {
          fnFeedback(fnParams)

          setStopFarmApproved(true)
          setStopFarmPending(false)
          setStopFarmLoading(false)
          setPendingTransaction(false)
          setStopFarmTxSent(res?.hash)
          setModalTxError(false)
          setStopFarmTxPendModal(true)

          TangleToast.success("Transaction successful", {
            description: `Stop farming successful`,
          })
        })
      } else {
        setStopFarmPending(false)
        setStopFarmApproved(false)
        setStopFarmTxSent(res?.hash)

        setStopFarmPending(true)
        setStopFarmLoading(false)
        setPendingTransaction(false)
        setModalTxError(true)

        TangleToast.error("Transaction reverted!", {
          description: `User rejected transaction`,
        })
      }
    })
  }

  const closePendingModal = () => {
    setEnableTxPendModal(false)
  }
  const closeConfirmationModal = () => {
    setEnableTxPendModal(false)
  }

  const closeModal = (event: any) => {
    if (modalRef.current === event?.target) {
      setEnableTxPendModal(false)
    }
  }

  const closeBeginFarmPendingModal = () => {
    setBeginFarmTxPendModal(false)
  }
  const closeBeginFarmConfirmationModal = () => {
    setBeginFarmTxPendModal(false)
  }

  const closeBeginFarmModal = (event: any) => {
    if (beginFarmModalRef.current === event?.target) {
      setBeginFarmTxPendModal(false)
    }
  }

  const closeStopFarmPendingModal = () => {
    setStopFarmTxPendModal(false)
  }
  const closeStopFarmConfirmationModal = () => {
    setStopFarmTxPendModal(false)
  }

  const closeStopFarmModal = (event: any) => {
    if (stopFarmModalRef.current === event?.target) {
      setStopFarmTxPendModal(false)
    }
  }

  const [wrapData, setWrapData] = useState<IFarmData>(undefined)
  const {
    isVisible: isBeginFarmWSCModalVisible,
    toggle: toggleBeginFarmWSCModal,
  } = useBeginFarmModalControl()
  const {
    isVisible: isStopFarmWSCModalVisible,
    toggle: toggleStopFarmWSCModal,
  } = useStopFarmModalControl()

  const isWSCReady = () => isWSCConnected && address && tokenId

  const fireBeginFarmWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepBeginFarmFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        farmAddress: address,
        tokenId: tokenId,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleBeginFarmWSCModal()
      }, 0)
    })
  }

  const fireStopFarmWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepStopFarmFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        farmAddress: address,
        tokenId: tokenId,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleStopFarmWSCModal()
      }, 0)
    })
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleBeginFarmWSCModal()
    }
  }
  const dispatch = useAppDispatch()
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleBeginFarmWSCModal()
    setOpenModalSettings(false)
  }
  return (
    <>
      {!!buttonsLoading ? (
        <>
          <AuctionPageLoader />
        </>
      ) : (
        <>
          {enableTxPendModal && !isEnded ? (
            <>
              <EnableFarmConfirmationModal
                pending={enablePending}
                link={enableTxSent}
                modalRef={modalRef}
                txError={modalTxError}
                closeModal={closeModal}
                closePendingModal={closePendingModal}
                closeConfirmationModal={closeConfirmationModal}
              />
            </>
          ) : null}
          {isWSCConnected && (
            <BeginFarmModal
              isVisible={isBeginFarmWSCModalVisible}
              toggleModal={toggleBeginFarmWSCModal}
              wrapData={wrapData}
              closeWSCContinue={closeWSCContinue}
              wscModalRef={wscModalRef}
              cancelTransaction={cancelTransaction}
            />
          )}
          {isWSCConnected && (
            <StopFarmModal
              isVisible={isStopFarmWSCModalVisible}
              toggleModal={toggleStopFarmWSCModal}
              wrapData={wrapData}
              closeWSCContinue={closeWSCContinue}
              wscModalRef={wscModalRef}
            />
          )}
          {beginFarmTxPendModal && !isEnded ? (
            <>
              <BeginFarmConfirmationModal
                pending={beginFarmPending}
                link={beginTxSent}
                txError={modalTxError}
                tokenId={tokenId}
                modalRef={beginFarmModalRef}
                closeModal={closeBeginFarmModal}
                closePendingModal={closeBeginFarmPendingModal}
                closeConfirmationModal={closeBeginFarmConfirmationModal}
              />
            </>
          ) : null}
          {stopFarmTxPendModal ? (
            <>
              <StopFarmConfirmationModal
                pending={stopFarmPending}
                link={stopFarmTxSent}
                modalRef={stopFarmModalRef}
                tokenId={tokenId}
                closeModal={closeStopFarmModal}
                txError={modalTxError}
                closePendingModal={closeStopFarmPendingModal}
                closeConfirmationModal={closeStopFarmConfirmationModal}
              />
            </>
          ) : null}
          {isFarmApproved?.val ? (
            <>
              {staked ? (
                <>
                  <StopFarmButton
                    blockTransactionState={!!blockTransactionState}
                    disabled={stopFarmLoading && !stopFarmApproved}
                    onClick={
                      isWSCConnected
                        ? !!blockTransactionState
                          ? handlePendingTxModal
                          : () => fireStopFarmWSCModal()
                        : () => handleStopFarming()
                    }
                  >
                    {" "}
                    {!!blockTransactionState ? (
                      <>
                        <SwapButtonSpinner />
                      </>
                    ) : null}
                    {!!blockTransactionState ? (
                      <>Continue Transaction</>
                    ) : (
                      <>
                        {stopFarmLoading ? (
                          <>
                            <LoadingDots />
                          </>
                        ) : (
                          <>Stop Farming</>
                        )}
                      </>
                    )}
                  </StopFarmButton>
                </>
              ) : isEnded ? null : (
                <>
                  <StartFarmButton
                    blockTransactionState={!!blockTransactionState}
                    disabled={beginFarmLoading && !beginFarmApproved}
                    onClick={
                      isWSCConnected
                        ? !!blockTransactionState
                          ? handlePendingTxModal
                          : () => fireBeginFarmWSCModal()
                        : () => startFarming(tokenId)
                    }
                  >
                    {" "}
                    {!!blockTransactionState ? (
                      <>
                        <SwapButtonSpinner />
                      </>
                    ) : null}
                    {!!blockTransactionState ? (
                      <>Continue Transaction</>
                    ) : (
                      <>
                        {beginFarmLoading ? (
                          <>
                            <LoadingDots />
                          </>
                        ) : (
                          <> Begin Farming</>
                        )}
                      </>
                    )}
                  </StartFarmButton>
                </>
              )}
            </>
          ) : isEnded ? null : (
            <>
              <EnableFarmButton
                blockTransactionState={!!blockTransactionState}
                disabled={enablingFarmLoading && !enableApproved}
                onClick={
                  !!isWSCConnected && !!blockTransactionState
                    ? handlePendingTxModal
                    : enableFarms
                }
              >
                {" "}
                {!!blockTransactionState ? (
                  <>
                    <SwapButtonSpinner />
                  </>
                ) : null}
                {!!blockTransactionState ? (
                  <>Continue Transaction</>
                ) : (
                  <>
                    {" "}
                    {enablingFarmLoading ? (
                      <>
                        <LoadingDots />{" "}
                      </>
                    ) : (
                      <> Approve </>
                    )}{" "}
                  </>
                )}
              </EnableFarmButton>
            </>
          )}
        </>
      )}
    </>
  )
}

const StartFarmButton = styled(FarmTokenButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const EnableFarmButton = styled(FarmTokenButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StopFarmButton = styled(StopFarmTokenButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: ${TangleColors.lighthover};
  border-radius: 12px;
  padding: 12px 16px;
  cursor: pointer;
  color: white; */
`

export default StakedAction
