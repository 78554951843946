import { useQuery } from "react-query"
import { fetchTangleArray } from "./useTangleArray"
import { getFarmConfig } from "packages/farms/constants"

import { useTangleship } from "./useTangleship"
import { useWSCContext } from "context/MilkomedaContext"

export default function useFarmTangleArray(): {
  TangleswapUserPools: any
  allPoolsLoading: any
  TangleswapFarmsData?: any
  allFarmsLoading?: any
  refetchFarmPools?: any
  refetchTangleFarms?: any
} {
  const { account, chainId, l1ChainId } = useWSCContext()

  const { tangleship } = useTangleship()
  const {
    data: TangleswapFarmsData,
    isLoading: allFarmsLoading,
    refetch: refetchTangleFarms,
  } = useQuery(
    ["tangleswapAllTangleFarms", account, chainId, l1ChainId],
    () => getFarmConfig(chainId, l1ChainId, account, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: 1000 * 60 * 30, // Data will become stale after 30 minutes
      cacheTime: 1000 * 60 * 60 * 4, // 4 hours
      enabled: !!chainId, // Query is enabled conditionally
    }
  )

  const {
    data: TangleswapUserPools, // this includes "Closed" positions
    isLoading: allPoolsLoading,
    refetch: refetchFarmPools,
  } = useQuery(
    ["tangleswapAllUserPools", account, chainId],
    () => fetchTangleArray(account, chainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: 1000 * 60 * 10, // 10 minutes,
      refetchIntervalInBackground: true,
      staleTime: 1000 * 60 * 5, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!account && !!chainId && !!tangleship, // Query is enabled conditionally
    }
  )

  return {
    TangleswapUserPools,
    allPoolsLoading,
    refetchFarmPools,
    TangleswapFarmsData,
    allFarmsLoading,
    refetchTangleFarms,
  }
}
