import React from "react"

interface CancelPendingProps {
  children?: React.ReactNode
}
export const CancelPendingContext = React.createContext<any>(undefined)
const CancelModalContext: React.FC<CancelPendingProps> = (props) => {
  const { children } = props
  const [openModalSettings, setOpenModalSettings] =
    React.useState<boolean>(false)

  const value = {
    openModalSettings,
    setOpenModalSettings,
  }

  return (
    <CancelPendingContext.Provider value={value}>
      {children}
    </CancelPendingContext.Provider>
  )
}

export default React.memo(CancelModalContext)
