import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

import DappsDivider from "utils/DappsDivider"
import LiquidityPositionsLoadingPlaceholder from "./LiquidityPositionsLoadingPlaceholder"

const IncreaseRemoveAuthLayout = () => {
  return (
    <>
      {" "}
      <LPNavigation>
        <LiquidityPositionsLoadingPlaceholder />
      </LPNavigation>
      <DappsDivider />
      <BoxTop>
        <LiquidityPositionsLoadingPlaceholder />
        <LiquidityPositionsLoadingPlaceholder />
        <LiquidityPositionsLoadingPlaceholder />
      </BoxTop>
      {/* selected range box start */}
      <PercentBox>
        <LiquidityPositionsLoadingPlaceholder />
        <LiquidityPositionsLoadingPlaceholder />
      </PercentBox>
      <InfoTierTop>
        {/* start of  box */}
        <LiquidityBox>
          <BoxBottom>
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
          </BoxBottom>
        </LiquidityBox>

        {/* end of  box */}
      </InfoTierTop>
    </>
  )
}

export default React.memo(IncreaseRemoveAuthLayout)

const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const InfoTierTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const LiquidityBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
`
const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0 16px 0;
`

const BoxBottom = styled.div`
  height: 120px;
  max-width: 680px;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 24px;
  width: 100%;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PercentBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.tangleBlack};
  padding: 20px 20px 0 20px;
  display: flex;
  height: 180px;
  flex-direction: column;
  margin: -8px 0 0 0;
`

const SwapButtons = styled.div`
  width: 100%;
  margin: 16px 0;
`
