import { useWSCContext } from "context/MilkomedaContext"

import React, { FC } from "react"

import styled from "styled-components"
import NetworkCover from "./NetworkCover"
import WrongNetwork from "./WrongNetwork"

import AddressCover from "./AddressCover"
import { useIsSupportedNetwork } from "constants/hooks"
import ConnectWalletButton from "components/utils/ConnectWalletButton"
import { TangleColors } from "styles/ColorStyles"
import { Wallet3 } from "iconsax-react"
import { useAppDispatch } from "store/hooks"
import { showWSCInterface } from "store/actions/WscProgressAction"

interface CoverProps {
  isAddressOpen?: boolean
  networkDropDownOpen?: boolean
  openNetworkDropdown?: () => void
  activeMenu?: any
  openMainAddressMenu?: () => void
  openTransactionMenu?: () => void
  goBackToMainMenu?: () => void
  isWSCConnected?: boolean
}

const AccountWrapper: FC<CoverProps> = (props) => {
  const { account } = useWSCContext()

  const {
    isAddressOpen,
    networkDropDownOpen,
    openNetworkDropdown,
    activeMenu,
    openMainAddressMenu,
    openTransactionMenu,
    goBackToMainMenu,
  } = props
  const { isWSCConnected } = useWSCContext()
  const isSupportedNetwork = useIsSupportedNetwork()
  const dispatch = useAppDispatch()
  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
  }
  return (
    <Body>
      {account ? (
        <>
          {isSupportedNetwork ? (
            <NetworkActiveCover>
              <NetworkCover
                networkDropDownOpen={networkDropDownOpen}
                openNetworkDropdown={openNetworkDropdown}
              />
              <AddressCover
                isAddressOpen={isAddressOpen}
                activeMenu={activeMenu}
                goBackToMainMenu={goBackToMainMenu}
                openTransactionMenu={openTransactionMenu}
                openMainAddressMenu={openMainAddressMenu}
              />
              <WalletInfoWrapper
                isWSCConnected={!!isWSCConnected}
                onClick={openWalletOverview}
              >
                <GlobalWalletIcon />
              </WalletInfoWrapper>
            </NetworkActiveCover>
          ) : (
            <>
              <NetworkCover
                networkDropDownOpen={networkDropDownOpen}
                openNetworkDropdown={openNetworkDropdown}
              />
              <NetworkWrap>
                <WrongNetwork />
              </NetworkWrap>
            </>
          )}
        </>
      ) : (
        <NavButton>
          <ConnectWalletButton isNavbar={true} />
        </NavButton>
      )}
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 840 !important;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    margin: 0 0 0 32px;
    min-height: 70px;

    padding: 0;
  }
`
const NetworkActiveCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 4px auto;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
  }
`

const NetworkWrap = styled.div`
  position: relative;
  max-width: 340px;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 850px) {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
  }
`

const NavButton = styled.div`
  width: 100%;
  max-width: 230px;
  margin: 8px 0;
  display: flex;

  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 850px) {
    margin: 0;
  }
`
const WalletInfoWrapper = styled.div<CoverProps>`
  padding: 10px 26px;
  display: ${(props) => (props.isWSCConnected ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  background: transparent;
  border-radius: 99px;
  cursor: pointer;
  background: ${TangleColors.cta};
  margin: 0 0 0 16px;
  border: 1px solid ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.lighthover};
    border: 1px solid ${TangleColors.tangleYellow};
  }
  @media only screen and (max-width: 950px) {
    margin: 16px auto 0 auto;
    max-width: 800px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`

const GlobalWalletIcon = styled(Wallet3)`
  margin: 0;
  width: 26px;
  height: 26px;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  ${WalletInfoWrapper}:hover & {
    color: ${TangleColors.black};
  }
`

export default AccountWrapper
