import { Field } from "@tangleswap/sdk"
import { NftList } from "./type"
import { createAction } from "@reduxjs/toolkit"

export const clearNfts = createAction<{}>("staking/clearNfts")
export const fetchNfts = createAction<{ nftList: NftList }>("staking/fetchNfts")
export const freezeNft = createAction<{ field: Field }>("staking/freezeNft")
export const selectNft = createAction<{ field: Field; nftId: number }>(
  "staking/selectNft"
)
