import { VOID_TOKEN } from "@tangleswap/sdk"
import stakingHeroBg from "assets/hero-blue.png"
import StakeContext from "components/staking/components/StakeContext"
import { BigNumber, ethers } from "ethers"
import { useState, useEffect, useCallback, useContext } from "react"
import { toast, toast as SonnerToast } from "sonner"

import styled from "styled-components"
import { useWSCContext } from "context/MilkomedaContext"
import SiteheroSection from "components/SiteheroSection"
import { fetchTangleFiatValue } from "utils/useTangleFiatValue"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { useTangleship } from "utils/useTangleship"
import StakeBody from "./StakeBody"
import useStakingArray from "utils/useStakingArray"
import RestakeModal, {
  useRestakeModalControl,
} from "components/milkomeda/staking/RestakeModal"
import { IWrapData } from "interfaces/wscSwap.interface"
import UnstakeModal, {
  useUnstakeModalControl,
} from "components/milkomeda/staking/UnstakeModal"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import {
  openWSCProgressModal,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"
import LedgerContext from "components/LedgerContext"

type UserInfo = {
  amount: BigNumber
  stakeStartTime: BigNumber
  lockDuration: BigNumber
  stakeEndTime: BigNumber
  userVE: BigNumber
  rewardDebt: BigNumber
}
interface StakingProps {
  hide?: boolean
}

const StakingContainer = () => {
  const {
    account: userAddress,
    chainId,
    isWSCConnected,
    l1ChainId,
  } = useWSCContext()

  const { tangleship } = useTangleship()

  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const [voidEnergy, setVoidEnergy] = useState(0)

  const [stakingActive, setStakingActive] = useState<boolean>(false)
  const [isLockEnded, setIsLockEnded] = useState<boolean>(false)
  const [reward, setReward] = useState<number>(0)
  const [voidApy, setVoidApy] = useState<number>(0)
  const [voidRewardRate, setVoidRewardRate] = useState<number>(0)
  const [tvl, setTvl] = useState<any>(0)
  const [globalVE, setGlobalVE] = useState<any>(0)
  const [dollarValue, setDollarValue] = useState(0)
  const [existingVoid, setExistingVoid] = useState(null)
  const [existingDuration, setExistingDuration] = useState<number>(null)
  const [startDate, setStartDate] = useState<Date>(null)
  const [avgLockTime, setAvgLockTime] = useState<any>(0)
  const [balance, setBalance] = useState<string>("")

  const [userInfo, setUserInfo] = useState<UserInfo>(null)
  const {
    TangleswapUserInfo,
    TangleswapStakingContractInfo,
    refetchAllContractInfo,
    refetchAllInfo,
    TangleswapFetchRewards,
  } = useStakingArray(userAddress, chainId)

  useEffect(() => {
    if (!TangleswapStakingContractInfo || !chainId) return
    setAvgLockTime(TangleswapStakingContractInfo?.avgLockTime)
    setVoidApy(TangleswapStakingContractInfo?.voidRewardInfo?.APY)
    setVoidRewardRate(
      TangleswapStakingContractInfo?.voidRewardInfo?.rewardRate?._hex / 1e18
    )
    setTvl(TangleswapStakingContractInfo?.voidRewardInfo?.TVL)
    setGlobalVE(Number(TangleswapStakingContractInfo?.globalVE?._hex))
  }, [TangleswapStakingContractInfo])

  const prepUnstakeFn = () => {
    const fn = tangleship?.unstakeVE
    const fnParams = [] as const

    const fnFeedback = (params?) => {
      refetchAllInfo()
      refetchAllContractInfo()
      loadUserData()
    }

    return { fn, fnParams, fnFeedback }
  }

  const handleWithdraw = async () => {
    if (!chainId) {
      toast.error("Something went wrong... Please contact us")
      return
    }

    try {
      toast("Unstake started...")
      const { fn, fnParams, fnFeedback } = prepUnstakeFn()

      const tx = await fn?.(...fnParams)
      tx.tx?.wait().then(async (receipt: any) => {
        if (receipt && receipt.status === 1) {
          fnFeedback()

          toast("Unstake successful")
          // window.location.reload()
        } else toast.error("Error while unstaking")
      })
    } catch (error) {
      toast.error("Error while unstaking")
    }
  }

  useEffect(() => {
    if (!chainId || !balance || !VOID_TOKEN[chainId]) return
    fetchTangleFiatValue(VOID_TOKEN[chainId], 18, chainId).then((res) => {
      setDollarValue(res || 0)
    })
  }, [balance, chainId])

  const prepRestakeFn = (duration: any) => {
    const calculatedDuration = duration * 7 * 86400

    const fn = tangleship?.restakeVE
    const fnParams = [calculatedDuration] as const

    const fnFeedback = (params?) => {
      refetchAllInfo()
      refetchAllContractInfo()
      loadUserData()
      setTimeout(() => updateUserInfo(TangleswapUserInfo), 2000)
    }

    return { fn, fnParams, fnFeedback }
  }

  const handleReStakeAction = async (duration: any) => {
    if (!chainId) {
      toast.error("Something went wrong... Please check your network.")
      return
    }

    if (!stakingActive && duration === 0) {
      toast.error("Something went wrong... Please check the value")
      return
    }

    const { fn, fnParams, fnFeedback } = prepRestakeFn(duration)

    try {
      toast("Restake started...")
      const tx = await fn?.(...fnParams)
      tx.tx?.wait().then(async (receipt: any) => {
        if (receipt && receipt.status === 1) {
          fnFeedback()

          toast("Restake successful")
        } else toast.error("Error while Restaking")
      })
    } catch (error) {
      toast.error("Error while restaking")
    }
  }

  const getVoidBalance = useCallback(() => {
    if (!chainId || !userAddress || !tangleswapTokenListBalances) return

    const voidBalance =
      tangleswapTokenListBalances?.[VOID_TOKEN[chainId]?.toLowerCase()]
    setBalance(voidBalance)
  }, [tangleswapTokenListBalances, chainId, userAddress])

  useEffect(() => {
    getVoidBalance()
  }, [getVoidBalance])

  const loadUserData = async () => {
    if (!chainId || !userAddress) return
    setUserInfo(TangleswapUserInfo)
    setReward(Number(ethers.utils.formatEther(TangleswapFetchRewards)))
  }

  useEffect(() => {
    if (
      !userAddress ||
      !chainId ||
      !TangleswapUserInfo ||
      !TangleswapFetchRewards
    )
      return
    loadUserData()
  }, [chainId, userAddress, TangleswapUserInfo, TangleswapFetchRewards])

  const updateUserInfo = (userInfo) => {
    setVoidEnergy(Number(userInfo.userVE))
    setStakingActive(userInfo.amount?.gt(0))
    setExistingVoid(Number(ethers.utils.formatEther(userInfo.amount)))
    setExistingDuration(userInfo.lockDuration.toNumber() / 86400)
    setIsLockEnded(
      userInfo.stakeEndTime.toNumber() !== 0
        ? userInfo.stakeEndTime.toNumber() < Date.now() / 1000
        : false
    )
    setStartDate(
      userInfo.stakeStartTime.toNumber() !== 0
        ? new Date(userInfo.stakeStartTime.toNumber() * 1000)
        : new Date()
    )
  }

  useEffect(() => {
    if (!TangleswapUserInfo) return
    updateUserInfo(TangleswapUserInfo)
  }, [TangleswapUserInfo])

  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )

  const [wrapData, setWrapData] = useState<IWrapData>(null)
  const { isVisible: isRestakeWSCModalVisible, toggle: toggleRestakeWSCModal } =
    useRestakeModalControl()
  const { isVisible: isUnstakeWSCModalVisible, toggle: toggleUnstakeWSCModal } =
    useUnstakeModalControl()

  const isWSCReady = () => isWSCConnected

  const fireRestakeWSCModal = (duration: any) => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepRestakeFn(duration)
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleRestakeWSCModal()
      }, 0)
    })
  }

  const fireUnstakeWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback } = prepUnstakeFn()
    if (!fn) return

    const VoidTokenInfo = tangleswapTokenListOnChain?.find(
      (token: any) =>
        String(token.address).toLowerCase() ===
          String(VOID_TOKEN[chainId]).toLowerCase() &&
        String(token.chainId) === String(l1ChainId)
    )

    const tokenOutData = {
      ...VoidTokenInfo,
      amount: Number(existingVoid),
      unit: getTokenUnit(VoidTokenInfo.address),
    }

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        tokenOut: tokenOutData,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleUnstakeWSCModal()
      }, 0)
    })
  }
  const dispatch = useAppDispatch()
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    SonnerToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleUnstakeWSCModal()
    setOpenModalSettings(false)
  }
  const restakeCancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    SonnerToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleRestakeWSCModal()
  }
  const contextValues = {
    loadUserData,
    // loadContractInfo,
    voidEnergy,
    stakingActive,
    isLockEnded,
    reward,
    voidApy,
    voidRewardRate,
    tvl,
    globalVE,
    dollarValue,
    existingVoid,
    existingDuration,
    startDate,
    balance,
    setBalance,
    userInfo,
    handleWithdraw,
    avgLockTime,
    handleReStakeAction,
    fireRestakeWSCModal,
    fireUnstakeWSCModal,
  }

  return (
    <>
      <Body>
        <StakeContext.Provider value={contextValues}>
          <SiteheroSection
            backgroundImage={stakingHeroBg}
            title="Staking Fields"
            subText="Stake VOID for rewards, governance power, and decentralized reputation."
          />
          {isWSCConnected && (
            <RestakeModal
              isVisible={isRestakeWSCModalVisible}
              wrapData={wrapData}
              toggleModal={toggleRestakeWSCModal}
              cancelTransaction={restakeCancelTransaction}
            />
          )}
          {isWSCConnected && (
            <UnstakeModal
              isVisible={isUnstakeWSCModalVisible}
              wrapData={wrapData}
              cancelTransaction={cancelTransaction}
              toggleModal={toggleUnstakeWSCModal}
            />
          )}
          <LedgerContext />
          <StakeBody tokenBalanceAmount={balance} tokenFiat={dollarValue} />
        </StakeContext.Provider>
      </Body>
    </>
  )
}
const Body = styled.div<StakingProps>``
export default StakingContainer
