import React, { FC } from "react"
import { TooltipProps } from "recharts" // Make sure this is the correct import path
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { unixToDate } from "utils/time"

// Extend TooltipProps with your custom properties if needed
interface CustomTooltipProps extends TooltipProps<any, any> {
  // Additional custom properties can go here
}

const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload // Assuming 'amount' and 'currency' are in the payload

    return (
      <CustomTooltipCover>
        <p className="label">{`Commitment: ${data.amount} ${data.currency}`}</p>
        <p className="intro">{`Value: ${unixToDate(label)}`}</p>
        {/* ... other content */}
      </CustomTooltipCover>
    )
  }

  return null
}

const CustomTooltipCover = styled.div`
  background: ${TangleColors.heroCTA};
  padding: 16px;
  border-radius: 14px;
  color: ${TangleColors.white};
`

export default CustomTooltip
