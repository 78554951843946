import { FC, ReactNode, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"

interface TooltipProps {
  text?: string | ReactNode
  children?: ReactNode
  direction?: "top" | "right" | "bottom" | "left"
  maxW?: string
  isVisible?: boolean
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { text, children, direction = "top", maxW = "330px" } = props
  const [isVisible, setIsVisible] = useState(false)

  const showTooltip = () => setIsVisible(true)
  const hideTooltip = () => setIsVisible(false)

  return (
    <TooltipContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      <TooltipBox isVisible={isVisible} direction={direction} maxW={maxW}>
        {text}
      </TooltipBox>
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

const TooltipBox = styled(CaptionSmall)<TooltipProps>`
  position: absolute;
  padding: 16px;
  white-space: normal;
  background-color: ${TangleColors.tangleHarshBlack};
  color: ${TangleColors.white};
  border-radius: 16px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.02);
  border: 1px solid ${TangleColors.lighthover};
  transition: opacity 0.3s, visibility 0.3s;
  width: max-content;
  max-width: ${(props) => props.maxW};
  z-index: 999;
  ${(props) => {
    switch (props.direction) {
      case "top":
        return `
          bottom: 110%;
          left: 50%;
          transform: translateX(-50%) translateY(-10px);
        `
      case "right":
        return `
          left: 110%;
          top: 50%;
          transform: translateY(-50%) translateX(10px);
        `
      case "bottom":
        return `
          top: 110%;
          left: 50%;
          transform: translateX(-50%) translateY(10px);
        `
      case "left":
        return `
          right: 110%;
          top: 50%;
          transform: translateY(-50%) translateX(-10px);
        `
    }
  }}
  &:after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    background-color: ${TangleColors.tangleHarshBlack};
    border-width: 1px;
    border-right-style: solid;
    border-bottom-style: solid;
    border-color: ${TangleColors.lighthover};
    transform: rotate(225deg);
    ${(props) => {
      switch (props.direction) {
        case "top":
          return `
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          `
        case "right":
          return `
            top: 50%;
            left: -16px;
            transform: translate(50%, -50%) rotate(135deg);
          `
        case "bottom":
          return `
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 50%) rotate(225deg);
          `
        case "left":
          return `
            top: 50%;
            right: -16px;
            transform: translate(-50%, -50%) rotate(315deg);
          `
      }
    }}
  }
`

export default Tooltip
