import React, { FC, useCallback } from "react"
import { toast as SonnerToast } from "sonner"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { shortenWSCAddress } from "utils"
import { HiDuplicate } from "react-icons/hi"
import AuctionPageLoader from "components/LaunchPad/AuctionPageLoader"
interface WalletInfoProps {
  account?: string
}
const WalletInformation: FC<WalletInfoProps> = (props) => {
  const { account } = props
  const CopyAddress = useCallback(() => {
    navigator.clipboard.writeText(account)
    SonnerToast.success("Address Copied")
  }, [account])
  return (
    <>
      {account ? (
        <>
          <StepperInfoRow onClick={CopyAddress}>
            <StepperText>{shortenWSCAddress(account)}</StepperText>
            <OverviewAddressCopyIcon />
          </StepperInfoRow>
        </>
      ) : (
        <StepperInfoLoadingRow>
          {" "}
          <AuctionPageLoader />
        </StepperInfoLoadingRow>
      )}
    </>
  )
}
const StepperInfoLoadingRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const StepperInfoRow = styled.div`
  display: flex;
  width: 100%;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.lighthover};
`

const StepperText = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 4px 0;
  transition: 0.4s ease-in;
  cursor: pointer;

  ${StepperInfoRow}:hover & {
    color: ${TangleColors.lightPurple};
  }
`

const OverviewAddressCopyIcon = styled(HiDuplicate)`
  width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  cursor: pointer;
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  ${StepperInfoRow}:hover & {
    color: ${TangleColors.lightPurple};
  }
`

export default WalletInformation
