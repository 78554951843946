import { createReducer } from "@reduxjs/toolkit"
import {
  closeConnectWalletModal,
  closeFireFlyModal,
  closeNamiWalletModal,
  closeNoMetMaskModal,
  connectWalletModal,
  noMetMaskModal,
  noNamiWalletModal,
  openFireFlyModal,
} from "store/actions/modalActions"

export interface ModalProps {
  noMetamask: boolean
  connectWallet: boolean
  selectFirefly: boolean
  selectTanglePay: boolean
  noNamiWallet: boolean
}
export const initialState: ModalProps = {
  noMetamask: false,
  connectWallet: false,
  selectFirefly: false,
  selectTanglePay: false,
  noNamiWallet: false,
}

const modalReducer = createReducer<ModalProps>(initialState, (builder) => {
  builder

    .addCase(openFireFlyModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: false,
        noNamiWallet: false,
        selectFirefly: true,
        selectTanglePay: false,
      }
    })
    .addCase(closeFireFlyModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: false,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: false,
      }
    })
    .addCase(noMetMaskModal, (state) => {
      return {
        noMetamask: true,
        connectWallet: false,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: false,
      }
    })
    .addCase(closeNoMetMaskModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: false,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: false,
      }
    })

    .addCase(connectWalletModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: true,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: false,
      }
    })
    .addCase(closeConnectWalletModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: false,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: false,
      }
    })
    .addCase(noNamiWalletModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: false,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: true,
      }
    })
    .addCase(closeNamiWalletModal, (state) => {
      return {
        noMetamask: false,
        connectWallet: false,
        selectFirefly: false,
        selectTanglePay: false,
        noNamiWallet: false,
      }
    })
})
export default modalReducer
