import PoolFee from "components/Liquidity/component/PoolFee"
import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Subtitle } from "styles/TextStyles"
import LPTypeRow from "./LPTypeRow"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import CurrencyTokenImages from "components/Liquidity/component/CurrencyTokenImages"

export interface TokenImagesProps {
  margin?: boolean
}
interface PositionProps {
  token0Info?: any
  token1Info?: any
  showFeePool?: () => void
  fee?: number
  feeShow?: boolean
  showHelpText?: any
  closedLP?: any
  outOfRange?: any
  mouseEnter?: () => void
  mouseLeave?: () => void
}
const PositionsTop: FC<PositionProps> = (props) => {
  const {
    token0Info,
    token1Info,
    showFeePool,
    showHelpText,
    fee,
    feeShow,
    closedLP,
    outOfRange,
    mouseEnter,

    mouseLeave,
  } = props

  return (
    <>
      <SizeTop>
        <CurrencyTokenImages
          token0Info={token0Info}
          token1Info={token1Info}
          token0Symbol={token0Info?.symbol}
          token1Symbol={token1Info?.symbol}
        />
        <SizeName>
          {token0Info?.symbol}/{token1Info?.symbol}
        </SizeName>
        <NumberWrap>
          {feeShow ? <PoolFee /> : null}
          <SizeNumber onMouseEnter={showFeePool} onMouseLeave={showFeePool}>
            {fee / 10000}%
          </SizeNumber>
        </NumberWrap>
        <MobileLPTypeCover>
          <LPTypeRow
            showHelpText={showHelpText}
            closedLP={closedLP}
            outOfRange={outOfRange}
            mouseEnter={mouseEnter}
            mouseLeave={mouseLeave}
          />
        </MobileLPTypeCover>
      </SizeTop>
    </>
  )
}

const SizeTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 24px 0 0;
  white-space: nowrap;
  max-width: 360px;
  @media only screen and (max-width: 850px) {
    max-width: 950px;
    margin: 0 16px 0 0;
    justify-content: space-between;
  }
`

const SizeName = styled(Subtitle)`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  margin: 0 12px 0 0;
`
const SizeNumber = styled(Caption)`
  background: ${TangleColors.icon};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.white};
  cursor: pointer;
  border-radius: 8px;
  padding: 6px 10px;
  transition: 0.4s ease-in;
  border: 1px solid transparent;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`

const NumberWrap = styled.div`
  z-index: 2;
  position: relative;
  display: flex;

  align-items: center;
  justify-content: flex-start;

  border-radius: 8px;
`
const MobileLPTypeCover = styled.div`
  max-width: 160px;
  width: 100%;
  display: none;

  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  margin: 8px 0;
  z-index: 50;

  @media only screen and (max-width: 850px) {
    display: flex;
    max-width: 32px;
    height: 32px;
  }
`
export default React.memo(PositionsTop)
