import { LPInput } from "components/Liquidity/component/LPInput"
import OutRangeBox from "components/Liquidity/utils/OutRangeBox"
import { useWSCContext } from "context/MilkomedaContext"

import { useIsSupportedNetwork } from "constants/hooks"
import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import {
  Body2,
  BodyCaption,
  Caption,
  CaptionSmall,
  CaptionTiny,
} from "styles/TextStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { toSignificantDigits } from "utils/toSignificant"

interface TokenProps {
  label?: string
  position?: string

  onMax?: () => void
  onClick?: () => void
  fieldValue?: any
  handleChange?: any
  hideBalance?: boolean
  id?: any
  showmax?: boolean
  tokenName?: any
  tokenSymbol?: any
  tokenImage?: any
  value?: string
  onUserInput?: (value: any) => void
  tokenAddress?: any
  balanceHidden?: boolean
  currency?: any
  onCurrencySelect?: any
  rangeDisabled?: boolean
  //  onCurrencySelect?: (currency: Currency) => void
  otherCurrency?: any
  fiatValue: string | number
  tokenBalance?: any
}

const LiquidityAddTokenBox: FC<TokenProps> = (props) => {
  const {
    hideBalance = false,
    onCurrencySelect,
    value,
    onUserInput,
    rangeDisabled,
    id,
    tokenAddress,
    tokenName,
    onMax,
    tokenSymbol,
    showmax,
    tokenImage,
    tokenBalance,
    fiatValue,
  } = props

  //user address state via redux
  const { account } = useWSCContext()

  const tokenBalanceFig = tokenBalance?.[tokenAddress?.toLowerCase()]
  const tokenBalanceAmount = !tokenBalanceFig ? "0" : tokenBalanceFig
  const isSupportedNetwork = useIsSupportedNetwork()
  return (
    <Body>
      {rangeDisabled ? (
        <OutRangeBox />
      ) : (
        <Cover>
          <TokenTop>
            <TokenPosition>{null}</TokenPosition>
            {!(account && !isSupportedNetwork) ? (
              <>
                {" "}
                <TokenLabel htmlFor={id}>
                  <>
                    <UserBalance>
                      <BalanceText> Balance:</BalanceText>
                      {account !== null ? (
                        <>
                          {" "}
                          <WalletAmount>
                            <WalletBalance>
                              <>
                                {toSignificantDigits(
                                  tokenBalanceAmount ?? 0,
                                  5,
                                  true,
                                  18
                                )}
                              </>
                            </WalletBalance>{" "}
                            {tokenBalance !== null && showmax ? (
                              <>
                                <MaxTransactions onClick={onMax}>
                                  <MaxText>Max</MaxText>
                                </MaxTransactions>
                              </>
                            ) : null}
                          </WalletAmount>
                        </>
                      ) : (
                        <WalletBalance>—</WalletBalance>
                      )}
                    </UserBalance>
                  </>
                </TokenLabel>
              </>
            ) : null}
          </TokenTop>

          <TokenBody>
            <TokenSelectButton onClick={onCurrencySelect}>
              <>
                {!!tokenImage ? (
                  <>
                    {" "}
                    <SelectedTokenIcon src={tokenImage} />
                  </>
                ) : (
                  <>
                    <ConvertTokenSymbol text={tokenSymbol} />
                  </>
                )}

                <TokenSelectedName>{tokenSymbol}</TokenSelectedName>
              </>
              <SwapIcon />
            </TokenSelectButton>

            <LPInput value={value} name={id} onUserInput={onUserInput} />
          </TokenBody>
          <TokenBottom>
            <TokenName>{tokenName}</TokenName>
            <TokenDollarValue>
              <>
                {fiatValue === undefined || !fiatValue ? (
                  ""
                ) : (
                  <> ${toSignificantDigits(fiatValue, 4, true, 2)}</>
                )}
              </>
            </TokenDollarValue>
          </TokenBottom>
        </Cover>
      )}
    </Body>
  )
}

const Body = styled.div`
  margin: 8px auto;
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  height: 104px;
  width: 100%;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 12px 20px 12px 16px;
`
const TokenTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: -6px 0 0 0;
`
const TokenPosition = styled(Caption)`
  width: 100%;
`
const UserBalance = styled(CaptionSmall)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 28px;
`

const WalletBalance = styled(CaptionSmall)`
  cursor: pointer;
  margin: 0 0 0 4px;
  opacity: 0.7;
`
const TokenLabel = styled.label``
const TokenBody = styled.div`
  min-height: 40px;
  width: 100%;
  margin: 6px 3px 10px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TokenSelectButton = styled.span`
  height: 40px;
  box-shadow: ${TangleColors.darkSlateGray} 0px 6px 10px;
  margin: -16px -4px 0 0;
  cursor: pointer;
  border-radius: 16px;
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  transition: 0.4s ease-in;
  align-items: center;
  border: 0.6px solid transparent;
  background: ${TangleColors.white};
  &:hover {
    background: ${TangleColors.black};
    border: 0.6px solid ${TangleColors.white};
  }
`

const SwapIcon = styled.div``
const TokenBottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: -6px 0 0 3px;
  transition: 0.4s ease-in;
  ${TokenSelectButton}:hover & {
    color: ${TangleColors.white};
  }
`

const TokenName = styled(CaptionTiny)`
  text-transform: capitalize;
`

const BalanceText = styled(CaptionSmall)`
  cursor: pointer;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  height: 28px;
  opacity: 0.7;
`

export const TokenSelectedName = styled(Body2)`
  color: ${TangleColors.black};
  margin: 0 2px;
  white-space: nowrap;
  transition: 0.4s ease-in;
  ${TokenSelectButton}:hover & {
    color: ${TangleColors.white};
  }
`

const WalletAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const MaxTransactions = styled.div`
  height: 22px;
  /* max-width: 64px; */
  /* width: 100%; */
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${TangleColors.heroCTA};
  margin: 0 0 0 8px;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    background: ${TangleColors.black};
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
  }
`

const TokenDollarValue = styled(CaptionSmall)`
  color: ${TangleColors.tangleGray};
  opacity: 0.6;

  margin: 0 2px 0 0;
`
const MaxText = styled(BodyCaption)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectedTokenIcon = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 999px;
  object-fit: cover;
  margin: 0 8px 0 0;
  background: ${TangleColors.black};
  border: 1px solid ${TangleColors.black};
  transition: 0.4s ease-in;

  ${TokenSelectButton}:hover & {
    border: 1px solid ${TangleColors.white};
  }
`
export default LiquidityAddTokenBox
