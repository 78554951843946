import React, { FC } from "react"
import styled from "styled-components"
import chevronUp from "assets/chevblackup.svg"
import chevronDown from "assets/chevblackdown.svg"

interface IconProps {
  addressOpen?: boolean
  pendingTransaction?: boolean
  addressDropIconRef?: any
}

const MobileAddressDropIcon: FC<IconProps> = (props) => {
  const { addressOpen, addressDropIconRef } = props
  return (
    <AddressCover
      className="NetworkDropIcon"
      ref={addressDropIconRef}
      src={addressOpen ? chevronUp : chevronDown}
    />
  )
}

const AddressCover = styled.img<IconProps>`
  display: flex;
  height: 18px !important;
  width: 18px !important;

  display: flex;

  justify-content: center;

  align-items: center;
  transition: 0.4s ease-in;
`

export default MobileAddressDropIcon
