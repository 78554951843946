import React, { useContext, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import CreationContext from "../CreationContext"

import {
  ActionContainer,
  BackButton,
  CounterColumn,
  FormRadioInput,
  Input,
  InputGroup,
  InputLabel,
  InputSubLabel,
  ItemBody,
  ItemColumn,
  ItemSubTitle,
  ItemTitle,
  NextButton,
  StepCounter,
  TitleContainer,
} from "../CreateAuction"
import { SubmitHandler, useForm } from "react-hook-form"
import {
  LaunchPadImage,
  LaunchPadImageWrapper,
  MainParaTokenName,
  MainParaTokenText,
} from "components/LaunchPad/utils/LaunchPadText"

import { Caption, CaptionSmall, CaptionTiny } from "styles/TextStyles"
import dutchSVG from "assets/auction01.svg"
import crowdSVG from "assets/auction02.svg"
import batchSVG from "assets/auction03.svg"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"

interface CreateAuctionDetailsValue {
  batchMinimumRaise?: string
  crowdFixedPrice?: string
  crowdMinimumPercentage?: string
  dutchStartPrice?: any
  dutchEndPrice?: any
  auction_type?: any
}

interface DetailsProps {
  selected?: boolean
  active?: boolean
}
const CreateAuctionDetails = () => {
  const { tokenDetails, setTokenDetails, currentStep, setCurrentStep } =
    useContext(CreationContext)
  // const [crowdMinimumRaised, setCrowdMinimumRaised] = useState<number>(0)
  // const [crowdMaximumRaised, setCrowdMaximumRaised] = useState<number>(0)
  // const [dutchMinimumRaised, setDutchMinimumRaised] = useState<number>(0)
  // const [dutchMaximumRaised, setDutchMaximumRaised] = useState<number>(0)
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm<CreateAuctionDetailsValue>({
    defaultValues: {
      auction_type: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const onSubmit: SubmitHandler<CreateAuctionDetailsValue> = (data) => {
    const auction_type = String(data.auction_type).trim().toLowerCase()
    if (
      (auction_type === "crowdsale" &&
        (Number(crowdMinimumRaised) === 0 ||
          Number(crowdMaximumRaised) === 0)) ||
      (auction_type === "dutch" &&
        (Number(dutchMinimumRaised) === 0 ||
          Number(dutchMaximumRaised) === 0)) ||
      (auction_type === "batch" && Number(watch("batchMinimumRaise")) === 0) ||
      (auction_type === "dutch" &&
        Number(dutchMinimumRaised) === Number(dutchMaximumRaised))
    )
      return

    if (auction_type === "crowdsale") {
      setTokenDetails({
        ...tokenDetails,
        auctionType: "crowdsale",
        fixedPrice: parseFloat(watch("crowdFixedPrice")),
        minimumTarget: parseFloat(watch("crowdMinimumPercentage")),
        minimumRaised: crowdMinimumRaised,
        maximumRaised: crowdMaximumRaised,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    } else if (auction_type === "dutch") {
      if (
        (auction_type === "dutch" &&
          parseFloat(watch("dutchEndPrice")) >
            parseFloat(watch("dutchStartPrice"))) ||
        (auction_type === "dutch" &&
          parseFloat(String(dutchMinimumRaised)) >
            parseFloat(String(dutchMaximumRaised)))
      )
        return
      setTokenDetails({
        ...tokenDetails,
        auctionType: "dutch",
        startPrice: parseFloat(watch("dutchStartPrice")),
        endPrice: parseFloat(watch("dutchEndPrice")),
        minimumRaised: dutchMinimumRaised,
        maximumRaised: dutchMaximumRaised,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    } else {
      setTokenDetails({
        ...tokenDetails,
        auctionType: "batch",
        minimumRaised: parseFloat(watch("batchMinimumRaise")),
        maximumRaised: null,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    }
  }

  const dutchMinimumRaised = useMemo(() => {
    const minPrice = parseFloat(watch("dutchEndPrice") ?? "0")
    const tokensSold = parseFloat(String(tokenDetails?.tokensForSale ?? "0"))
    return minPrice * tokensSold
  }, [watch("dutchEndPrice"), tokenDetails?.tokensForSale])

  const dutchMaximumRaised = useMemo(() => {
    const maxPrice = parseFloat(watch("dutchStartPrice") ?? "0")
    const tokensSold = parseFloat(String(tokenDetails?.tokensForSale ?? "0"))
    return maxPrice * tokensSold
  }, [watch("dutchStartPrice"), tokenDetails?.tokensForSale])

  const crowdMinimumRaised = useMemo(() => {
    const price = parseFloat(watch("crowdFixedPrice") ?? "0")
    const minTokensSold =
      (parseFloat(watch("crowdMinimumPercentage") ?? "0") / 100) *
      parseFloat(String(tokenDetails?.tokensForSale ?? "0"))
    return price * minTokensSold
  }, [
    watch("crowdFixedPrice"),
    watch("crowdMinimumPercentage"),
    tokenDetails?.tokensForSale,
  ])

  const crowdMaximumRaised = useMemo(() => {
    const price = parseFloat(watch("crowdFixedPrice") ?? "0")
    const maxTokensSold = parseFloat(String(tokenDetails?.tokensForSale ?? "0"))
    return price * maxTokensSold
  }, [watch("crowdFixedPrice"), tokenDetails?.tokensForSale])

  return (
    <ItemBody active={currentStep === 2}>
      <CounterColumn>
        <StepCounter active={currentStep === 2}>2</StepCounter>
      </CounterColumn>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ItemColumn>
          <TitleContainer>
            <ItemTitle>Auction Details*</ItemTitle>
            {currentStep === 2 ? (
              <ItemSubTitle>
                Select an auction type, and set the price parameters for your
                auction.
              </ItemSubTitle>
            ) : null}
          </TitleContainer>
          {currentStep === 2 ? (
            <>
              <TypeContainer>
                <TokenType selected={watch("auction_type") === "dutch"}>
                  <FormRadioInput
                    value="dutch"
                    type="radio"
                    {...register("auction_type", { required: true })}
                  />
                  <TypeTitle>Dutch Auction</TypeTitle>
                  <LaunchPadImageWrapper>
                    <LaunchPadImage
                      src={dutchSVG}
                      alt="tangleswap auction dutch logo"
                    />
                  </LaunchPadImageWrapper>
                  <TypeSubtitle>
                    The price starts at a higher value per token than expected,
                    and descends linearly over time.
                  </TypeSubtitle>
                  <TypeInfo>
                    Great for true price discovery of completely novel items
                  </TypeInfo>
                </TokenType>
                <TokenType selected={watch("auction_type") === "crowdsale"}>
                  <FormRadioInput
                    value="crowdsale"
                    type="radio"
                    {...register("auction_type", { required: true })}
                  />
                  <TypeTitle>Crowdsale</TypeTitle>
                  <LaunchPadImageWrapper>
                    <LaunchPadImage
                      src={crowdSVG}
                      alt="tangleswap crowdsale batch logo"
                    />
                  </LaunchPadImageWrapper>
                  <TypeSubtitle>
                    Simple: a fixed price, and a fixed set of tokens.
                  </TypeSubtitle>
                  <TypeInfo>
                    Great when the token price is already known or has been
                    decided previously
                  </TypeInfo>
                </TokenType>
                <TokenType selected={watch("auction_type") === "batch"}>
                  <FormRadioInput
                    value="batch"
                    type="radio"
                    {...register("auction_type", { required: true })}
                  />
                  <TypeTitle>Fair Launch</TypeTitle>
                  <LaunchPadImageWrapper>
                    <LaunchPadImage
                      src={batchSVG}
                      alt="tangleswap auction batch logo"
                    />
                  </LaunchPadImageWrapper>
                  <TypeSubtitle>
                    A set amount of tokens are divided amongst all participants,
                    based on their contribution(s).
                  </TypeSubtitle>
                  <TypeInfo>
                    Great for projects looking to ensure that everyone taking
                    part is rewarded
                  </TypeInfo>
                </TokenType>
              </TypeContainer>
              {watch("auction_type") === "dutch" ? (
                <>
                  <InputGroup>
                    <InputLabel>Start Price*</InputLabel>
                    <Input
                      type="text"
                      {...register("dutchStartPrice", {
                        required: Boolean(watch("auction_type") === "dutch"),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      autoComplete="off"
                      formError={errors.dutchStartPrice ? true : false}
                      placeholder="Price when the auction will start"
                    />
                  </InputGroup>
                  <InputGroup>
                    <TitleRow>
                      <RaisedTitle>
                        <InputLabel>Maximum Raised:</InputLabel>
                      </RaisedTitle>
                      <RaisedRowWrapper>
                        <ParaTokenName>
                          <ParaTokenText left={true}>
                            {formatNumber(dutchMaximumRaised)}
                          </ParaTokenText>
                          <ParaTokenText left={false}>
                            {tokenDetails?.paymentCurrencySymbol}
                          </ParaTokenText>
                        </ParaTokenName>
                      </RaisedRowWrapper>
                    </TitleRow>
                    <InputSubLabel>
                      Maximum possible raised amount if all tokens were sold
                      instantly at the starting price.
                    </InputSubLabel>
                  </InputGroup>
                  <InputGroup>
                    <InputLabel>End Price*</InputLabel>
                    <Input
                      type="text"
                      {...register("dutchEndPrice", {
                        required: Boolean(watch("auction_type") === "dutch"),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      autoComplete="off"
                      formError={errors.dutchEndPrice ? true : false}
                      placeholder="Price when the auction will meet its end date"
                    />
                    <>
                      {" "}
                      <ErrorMessage
                        active={Boolean(
                          (watch("auction_type") === "dutch" &&
                            !!watch("dutchStartPrice") &&
                            !!watch("dutchEndPrice") &&
                            parseFloat(watch("dutchEndPrice")) >
                              parseFloat(watch("dutchStartPrice"))) ||
                            (!!watch("dutchStartPrice") &&
                              !!watch("dutchEndPrice") &&
                              watch("auction_type") === "dutch" &&
                              parseFloat(String(dutchMaximumRaised)) <
                                parseFloat(String(dutchMinimumRaised)))
                        )}
                      >
                        <>Starting price must be greater than end price</>
                      </ErrorMessage>
                      <ErrorMessage
                        active={Boolean(
                          (!!watch("dutchStartPrice") &&
                            !!watch("dutchEndPrice") &&
                            watch("auction_type") === "dutch" &&
                            parseFloat(watch("dutchEndPrice")) ===
                              parseFloat(watch("dutchStartPrice"))) ||
                            (!!watch("dutchStartPrice") &&
                              !!watch("dutchEndPrice") &&
                              watch("auction_type") === "dutch" &&
                              parseFloat(String(dutchMaximumRaised)) ===
                                parseFloat(String(dutchMinimumRaised)))
                        )}
                      >
                        <>Starting price can not be the same as end price</>
                      </ErrorMessage>
                    </>
                  </InputGroup>
                  <InputGroup>
                    <TitleRow>
                      <RaisedTitle>
                        <InputLabel>Minimum Raised:</InputLabel>
                      </RaisedTitle>
                      <RaisedRowWrapper>
                        <ParaTokenName>
                          <ParaTokenText left={true}>
                            {formatNumber(dutchMinimumRaised || 0)}
                          </ParaTokenText>
                          <ParaTokenText left={false}>
                            {tokenDetails?.paymentCurrencySymbol}
                          </ParaTokenText>
                        </ParaTokenName>
                      </RaisedRowWrapper>
                    </TitleRow>

                    <InputSubLabel>
                      Minimum amount in order to have a successful auction. If
                      this value is not met, users can withdraw their committed
                      payment(s) and no tokens will be sold.
                    </InputSubLabel>
                  </InputGroup>
                </>
              ) : null}
              {watch("auction_type") === "crowdsale" /* CROWDSALE */ ? (
                <>
                  <InputGroup>
                    <InputLabel>Fixed Price*</InputLabel>
                    <Input
                      {...register("crowdFixedPrice", {
                        required: Boolean(
                          watch("auction_type") === "crowdsale"
                        ),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      autoComplete="off"
                      formError={errors.crowdFixedPrice ? true : false}
                      placeholder="Price at which your tokens will be sold"
                    />
                  </InputGroup>
                  <InputGroup>
                    <TitleRow>
                      <RaisedTitle>
                        <InputLabel>Maximum Raised:</InputLabel>
                      </RaisedTitle>
                      <RaisedRowWrapper>
                        <ParaTokenName>
                          <ParaTokenText left={true}>
                            {formatNumber(crowdMaximumRaised)}{" "}
                          </ParaTokenText>
                          <ParaTokenText left={false}>
                            {tokenDetails?.paymentCurrencySymbol}
                          </ParaTokenText>
                        </ParaTokenName>
                      </RaisedRowWrapper>
                    </TitleRow>

                    <InputSubLabel>
                      Maximum possible raised amount if 100% of tokens were to
                      be sold.
                    </InputSubLabel>
                  </InputGroup>
                  <InputGroup>
                    <InputLabel>Minimum Percentage Sold*</InputLabel>
                    <Input
                      {...register("crowdMinimumPercentage", {
                        required: Boolean(
                          watch("auction_type") === "crowdsale"
                        ),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "")
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                        if (parseFloat(e.target.value) > 100) {
                          e.target.value = 100
                        } else if (parseFloat(e.target.value) < 1) {
                          e.target.value = 1
                        }
                      }}
                      autoComplete="off"
                      formError={errors.crowdMinimumPercentage ? true : false}
                      placeholder="Percentage of tokens that has to sell for a successful
                      auction"
                    />
                  </InputGroup>
                  <InputGroup>
                    <TitleRow>
                      <RaisedTitle>
                        <InputLabel>Minimum Raised:</InputLabel>
                      </RaisedTitle>
                      <RaisedRowWrapper>
                        <ParaTokenName>
                          <ParaTokenText left={true}>
                            {formatNumber(crowdMinimumRaised || 0)}
                          </ParaTokenText>
                          <ParaTokenText left={false}>
                            {tokenDetails?.paymentCurrencySymbol}
                          </ParaTokenText>
                        </ParaTokenName>
                      </RaisedRowWrapper>
                    </TitleRow>

                    <InputSubLabel>
                      Minimum amount in order to have a successful auction. If
                      this value is not met, users can withdraw their committed
                      payment(s) and no tokens will be sold.
                    </InputSubLabel>
                  </InputGroup>
                </>
              ) : null}
              {watch("auction_type") === "batch" /* BATCH */ ? (
                <>
                  <InputGroup>
                    <InputLabel>Minimum Raise Amount*</InputLabel>
                    <Input
                      {...register("batchMinimumRaise", {
                        required: Boolean(watch("auction_type") === "batch"),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      autoComplete="off"
                      formError={errors.batchMinimumRaise ? true : false}
                      placeholder="10,000"
                    />
                    <InputSubLabel>
                      Minimum amount of {tokenDetails?.paymentCurrencySymbol} to
                      raise in order to have a successful auction.
                    </InputSubLabel>
                  </InputGroup>
                </>
              ) : null}
              <ActionContainer>
                <NextButton
                  disabled={
                    (watch("auction_type") === "dutch" &&
                      (Number(dutchMinimumRaised) === 0 ||
                        Number(dutchMaximumRaised) === 0)) ||
                    (watch("auction_type") === "batch" &&
                      Number(watch("batchMinimumRaise")) === 0) ||
                    (watch("auction_type") === "crowdsale" &&
                      watch("crowdMinimumPercentage") !== "100" &&
                      !(Number(crowdMinimumRaised) > 0)) ||
                    (watch("auction_type") === "dutch" &&
                      Number(dutchMinimumRaised) ===
                        Number(dutchMaximumRaised)) ||
                    (watch("auction_type") === "dutch" &&
                      !!watch("dutchStartPrice") &&
                      !!watch("dutchEndPrice") &&
                      parseFloat(watch("dutchEndPrice")) >
                        parseFloat(watch("dutchStartPrice"))) ||
                    (!!watch("dutchStartPrice") &&
                      !!watch("dutchEndPrice") &&
                      watch("auction_type") === "dutch" &&
                      parseFloat(String(dutchMaximumRaised)) <
                        parseFloat(String(dutchMinimumRaised))) ||
                    (!!watch("dutchStartPrice") &&
                      !!watch("dutchEndPrice") &&
                      watch("auction_type") === "dutch" &&
                      parseFloat(watch("dutchEndPrice")) ===
                        parseFloat(watch("dutchStartPrice"))) ||
                    (!!watch("dutchStartPrice") &&
                      !!watch("dutchEndPrice") &&
                      watch("auction_type") === "dutch" &&
                      parseFloat(String(dutchMaximumRaised)) ===
                        parseFloat(String(dutchMinimumRaised)))
                  }
                  type="submit"
                >
                  Continue
                </NextButton>
                <BackButton
                  onClick={() => {
                    setCurrentStep((currentStep: number) => currentStep - 1)
                  }}
                >
                  Back
                </BackButton>
              </ActionContainer>
            </>
          ) : null}
        </ItemColumn>
      </form>
    </ItemBody>
  )
}

const TypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const TokenType = styled.div<DetailsProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  max-width: 250px;
  border: ${(props) =>
    props.selected
      ? `1px solid ${TangleColors.lighthover}`
      : `1px solid ${TangleColors.black}`};
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  background: ${(props) =>
    props.selected ? TangleColors.modalBG : TangleColors.swapBG};
  color: ${TangleColors.white};

  &:hover {
    border: 1px solid
      ${(props) =>
        props.selected ? TangleColors.cta : TangleColors.lighthover};
  }
`

const TypeTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const TypeSubtitle = styled(CaptionSmall)`
  color: ${TangleColors.white};
`

const TypeInfo = styled(CaptionTiny)`
  margin-top: auto;
  color: ${TangleColors.darkText};
`

const InputText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${TangleColors.white};
`

const ParaTokenName = styled(MainParaTokenName)``
const ParaTokenText = styled(MainParaTokenText)``
const ErrorMessage = styled(Caption)<DetailsProps>`
  color: ${TangleColors.red};
  display: ${(props) => (props.active ? "flex" : "none")};
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const RaisedRowWrapper = styled.div`
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: flex-start;
  margin: 4px 0 0 6px;
  width: 100%;
`

const RaisedTitle = styled.div`
  width: 100%;
  max-width: 160px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`

export default CreateAuctionDetails
