import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import AuctionSpinner from "./AuctionSpinner"

interface ToggleProps {
  isToggled?: boolean
  toggleOffButton?: (e: any) => void
  toggleOnButton?: (e: any) => void
  purplelistEnableLoading?: boolean
}
const AuctionToggle: FC<ToggleProps> = (props) => {
  const {
    isToggled,
    toggleOnButton,
    toggleOffButton,
    purplelistEnableLoading,
  } = props

  return (
    <>
      <StyleWrapper onClick={isToggled ? toggleOffButton : toggleOnButton}>
        <button className={isToggled ? "on" : "off"}>
          <span />
          <Preloader
            purplelistEnableLoading={purplelistEnableLoading}
            isToggled={isToggled}
          >
            <AuctionSpinner />
          </Preloader>
        </button>
      </StyleWrapper>
    </>
  )
}

const StyleWrapper = styled.div`
  height: 32px;
  width: 62px;
  transition: 0.4s ease-in;
  border-radius: 100px;
  outline: none !important;
  position: relative;
  border: 0.5px solid transparent !important;
  cursor: pointer;

  :hover {
    border: 0.5px solid ${TangleColors.tangleBlack};
    outline: none !important;
  }
  &:hover button {
    border: 0.5px solid ${TangleColors.tangleBlack};
    outline: none !important;
    background: ${TangleColors.lighthover} !important;
  }
  &:hover .off span {
    background: ${TangleColors.tangleBlack} !important;
  }
  &:hover .on span {
    background: ${TangleColors.cta} !important;
  }
  button {
    height: 32px;
    width: 62px;
    cursor: pointer;
    border-radius: 100px;

    position: relative;
    background: ${TangleColors.white};
    outline: none !important;
    border: 0.5px solid transparent;
    transition: 0.4s ease-in;

    span {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 26px;
      height: 26px;
      background: ${TangleColors.white};
      outline: none !important;
      border-radius: 999px;
      border: none;
      transition: all 0.2s cubic-bezier(0, 0, 0.46, 1.82);
      transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
    }

    &:active {
      span {
        width: 50px;
        outline: none !important;
      }

      &.on span {
        margin-left: -54px;
        outline: none !important;
      }
    }

    &.on {
      background: ${TangleColors.lighthover};
      outline: none !important;
      border: 1px solid ${TangleColors.lightPurple};
      span {
        left: 100%;
        margin-left: -30px;
        margin-top: -2px;
        background: ${TangleColors.black};
        outline: none !important;
      }
    }
    &.off {
      background: ${TangleColors.white};
      outline: none !important;

      span {
        outline: none !important;
        right: 100%;
        margin-left: 0px;
        margin-top: -2px;

        background: ${TangleColors.modalBG};
      }
    }
  }
`

const Preloader = styled.div<ToggleProps>`
  position: absolute;
  top: 50%;
  right: ${(props) => (props.isToggled ? `6px` : ``)};
  left: ${(props) => (props.isToggled ? `` : `16px`)};
  transform: translate(-50%, -50%);
  display: ${(props) => (props.purplelistEnableLoading ? "flex" : "none")};
`

export default AuctionToggle
