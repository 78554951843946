import React, { FC, useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import NetworkChainRow from "./NetworkChainRow"

import { Caption, CaptionSmall } from "styles/TextStyles"
import addChainToMetamask from "connect/switchChain"
import { Chains, TestChains } from "@tangleswap/sdk"

import { SUPPORTED_CHAIN_IDS } from "constants/chainInfo"
import NetworkExcludedChain from "./NetworkExcludedChain"
import useConnectWallet from "utils/useConnectWallet"
import { updateWalletModalState } from "store/actions/SelectedWalletAction"
import { useAppDispatch } from "store/hooks"

interface BodyClassProps {
  networkDropDownOpen?: boolean
  inActive?: boolean
}
const NetworkDropdown: FC<BodyClassProps> = (props) => {
  const onSelectChain = useCallback(async (targetChainId: Chains) => {
    addChainToMetamask(targetChainId)
  }, [])
  const { networkDropDownOpen } = props

  const [showTestnet, setShowTestnet] = useState<boolean>(false)

  const chainsToShow = useMemo(() => {
    const filteredChains = showTestnet
      ? SUPPORTED_CHAIN_IDS.filter((chainId) =>
          Object.values(TestChains).includes(chainId)
        )
      : SUPPORTED_CHAIN_IDS.filter(
          (chainId) => !Object.values(TestChains).includes(chainId)
        )
    return [...filteredChains]
  }, [showTestnet])

  const toggleTestnet = () => {
    setShowTestnet(!showTestnet)
  }
  const dispatch = useAppDispatch()
  const { connectWallet } = useConnectWallet()
  const selectCardano = () => {
    dispatch(updateWalletModalState(1))
    localStorage.setItem("tangleWalletNetworkMode", JSON.stringify(1))
    const promise = () => new Promise((resolve) => setTimeout(resolve, 1000))
    if (!!promise) connectWallet()
  }

  return (
    <>
      {networkDropDownOpen ? (
        <>
          {" "}
          <NavFunctionCover>
            <Body>
              <Cover>
                <NetworkTop>Select a network</NetworkTop>
                <DropUL>
                  {(
                    Object.values(chainsToShow).filter(
                      (value) => !isNaN(Number(value))
                    ) as number[]
                  ).map((chainId: Chains) => (
                    <DropLi>
                      <NetworkChainRow
                        onSelectChain={onSelectChain}
                        targetChain={chainId}
                        key={chainId}
                      />
                    </DropLi>
                  ))}
                </DropUL>
                <NetworkBottom>
                  <NetworkTestnetText onClick={toggleTestnet}>
                    {showTestnet ? "Show mainnets" : "Show testnets"}
                  </NetworkTestnetText>
                </NetworkBottom>
              </Cover>
            </Body>
          </NavFunctionCover>
        </>
      ) : null}
    </>
  )
}

const Body = styled.div`
  position: absolute;
  min-height: 130px;
  width: 235px;

  border-radius: 18px;
  right: 0px;
  top: 50px;

  z-index: 901;
  background: ${TangleColors.white};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.03);
  display: flex;
  transition: 0.4s ease-in;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 16px 16px 16px;
`
const DropUL = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;

  max-width: 1000px;
  margin: 8px auto;
`

const NetworkTop = styled(Caption)`
  color: ${TangleColors.tangleBlack};
  text-align: center;
`
const DropLi = styled.li<BodyClassProps>`
  cursor: pointer;
  width: 100%;
  display: ${(props) => (props.inActive ? `none` : `flex`)};
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  height: 56px;
  padding: 4px 8px;
  transition: 0.4s ease-in;
  position: relative;
  :hover {
    background: ${TangleColors.snowWhite};
  }
`
const NavFunctionCover = styled.div`
  position: absolute;
  min-height: 130px;
  border-radius: 18px;
  right: 0px;
  top: 2px;
`

const NetworkBottom = styled.div`
  max-width: 130px;
  margin: 2px auto 0 auto;
  width: 100%;
`
const NetworkTestnetText = styled(CaptionSmall)`
  color: ${TangleColors.grayLight};
  text-align: center;
  padding: 6px;
  cursor: pointer;
  border-radius: 99px;
  width: 100%;
  border: 1px solid ${TangleColors.heroCTA};
  background: ${TangleColors.lighthover};
`

export default NetworkDropdown
