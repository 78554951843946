import React, { FC } from "react"
import styled from "styled-components"
import LoadingFarmState from "./components/FarmItem/components/LoadingFarmState"
import FarmItemCard from "./components/FarmItem/FarmItemCard"
import EmptyFarmItem from "./components/FarmItem/components/EmptyFarmItem"

import { FarmDataProps } from "./types"

interface FarmProps {
  filteredFarmData?: any
  farmLoading?: boolean
  allUserPools?: any
}
const FarmBody: FC<FarmProps> = (props) => {
  const { filteredFarmData, farmLoading, allUserPools } = props

  return (
    <Body>
      <Cover>
        <>
          {!farmLoading && filteredFarmData ? (
            <>
              {filteredFarmData && filteredFarmData?.length > 0 ? (
                <>
                  {filteredFarmData.map(
                    (data: FarmDataProps, index: number) => {
                      const featuredList = [
                        "0x113c3079560818A962Ec79Cb33329E14588558d6",
                        "0x9Eb0030189651113831513D912768Df4061EB87C",
                        "0x75BdB98287C7540aaCcF9C3c5B2D48bcffE3300A",
                        "0xea215cced54057b202a9b238968dcf8930682846",
                      ]
                      const isFeatured = featuredList
                        .map((f) => f.toLowerCase())
                        .includes(data?.farmAddress?.toLowerCase())

                      return (
                        <FarmItemCard
                          allUserPools={allUserPools}
                          allPoolsLoading={farmLoading}
                          key={index}
                          farmAddress={data?.farmAddress}
                          endBlock={data?.endBlock}
                          feeTier={data?.feeTier}
                          rewardLowerTick={data?.rewardLowerTick}
                          rewardsAddress0={data?.rewardsAddress0}
                          rewardsAddress1={data?.rewardsAddress1}
                          rewardUpperTick={data?.rewardUpperTick}
                          startBlock={data?.startBlock}
                          token0Address={data?.token0Address}
                          token1Address={data?.token1Address}
                          token0PendingRewards={data?.rewardsPending0}
                          token1PendingRewards={data?.rewardsPending1}
                          // isLPstakedInFarm={data?.isLPstakedInFarm}
                          calculatedAPR={data.maxAPR}
                          calculatedTVL={data.TVL}
                          baseAPR={data.baseAPR}
                          featured={isFeatured}
                        />
                      )
                    }
                  )}
                </>
              ) : (
                <>
                  <EmptyFarmItem />
                </>
              )}
            </>
          ) : (
            <>
              <LoadingFarmState />{" "}
            </>
          )}
        </>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  width: 100%;
  padding: 16px;
  position: relative;
`

const Cover = styled.div`
  max-width: 1232px;
  margin: 16px auto 64px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  position: relative;
`

export default FarmBody
