import {
  CHECK_WALLET_OVERVIEW_STEP,
  OPEN_WSC_INTERFACE,
  OPEN_WSC_STEP_MODAL,
  UPDATE_WSC_STEP,
} from "./actionsTypes"

const updateTangleWSCProgress = (pendingWscTxStep?: any) => {
  return {
    type: UPDATE_WSC_STEP,
    pendingWscTxStep: pendingWscTxStep,
  }
}
export const updateWalletStep = (tangleswapWalletStep: any) => {
  return {
    type: CHECK_WALLET_OVERVIEW_STEP,
    tangleswapWalletStep: tangleswapWalletStep,
  }
}

export const openWSCProgressModal = (openWSCModal?: boolean) => {
  return {
    type: OPEN_WSC_STEP_MODAL,
    openWSCModal: openWSCModal,
  }
}

export const wscInterfaceModal = (wscInterfaceOpen?: boolean) => {
  return {
    type: OPEN_WSC_INTERFACE,
    wscInterfaceOpen: wscInterfaceOpen,
  }
}

export const showWSCInterface = (click?: boolean) => {
  return (dispatch) => {
    dispatch(wscInterfaceModal(click))
  }
}
export const updateUserWSCProgress = (data?: any) => {
  return (dispatch) => {
    localStorage.setItem("pendingWscTxStep", JSON.stringify(data))
    dispatch(updateTangleWSCProgress(data))
  }
}
export const triggerUpdateWalletStep = (tangleswapWalletStep?: any) => {
  return (dispatch) => {
    localStorage.setItem(
      "tangleswapWalletStep",
      JSON.stringify(tangleswapWalletStep)
    )
    dispatch(updateWalletStep(tangleswapWalletStep))
  }
}

export const checkTangleWSCProgress = () => {
  return (dispatch) => {
    const tangleProgress = JSON.parse(localStorage.getItem("pendingWscTxStep"))

    dispatch(updateUserWSCProgress(tangleProgress))
  }
}

export const proCheckwalletOverviewPosition = () => {
  return (dispatch: any) => {
    try {
      const tangleswapWalletStep = getTangleSwapWalletStep()
      const step = tangleswapWalletStep || 2
      dispatch(triggerUpdateWalletStep(step))
    } catch (error) {
      console.error("Error parsing tangleswapWalletStep:", error)
      // Handle error or dispatch an error action
    }
  }
}

const getTangleSwapWalletStep = (): number | null => {
  const item = localStorage.getItem("tangleswapWalletStep")
  return item ? JSON.parse(item) : null
}
