import React from "react"

interface PendingProps {
  children?: React.ReactNode
}
export const PendingStateContext = React.createContext<any>(undefined)
const PendingContext: React.FC<PendingProps> = (props) => {
  const { children } = props
  const [pendingTransaction, setPendingTransaction] =
    React.useState<boolean>(false)
  const [limit, setLimit] = React.useState<any>({
    palm: 9,
    floating: 9,
    tranquillity: 9,
    ascension: 9,
    night: 9,
  })
  const [walletModal, setWalletModal] = React.useState<boolean>(false)
  const value = {
    pendingTransaction,
    setPendingTransaction,
    limit,
    walletModal,
    setLimit,
    setWalletModal,
  }

  return (
    <PendingStateContext.Provider value={value}>
      {children}
    </PendingStateContext.Provider>
  )
}

export default React.memo(PendingContext)
