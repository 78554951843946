import { DEVNET_CHAINID } from "config/milkomeda/config"
import { wagmiClientMainnet, wagmiClientTestnet } from "config/milkomeda/wagmi"
import MilkomedaContext, { lastChainId } from "context/MilkomedaContext"
import { ConnectWSCProvider } from "milkomeda-wsc-ui-test-beta"
import { FC, ReactNode } from "react"
import { themeVars } from "styles/WscStyles"
import { WagmiConfig } from "wagmi"

interface AppWrapperProps {
  children?: ReactNode
}

const AppWrapper: FC<AppWrapperProps> = ({ children }) => {
  const isTestnet = lastChainId === DEVNET_CHAINID

  return (
    <WagmiConfig client={wagmiClientMainnet}>
      <ConnectWSCProvider customTheme={themeVars}>
        <MilkomedaContext>{children}</MilkomedaContext>
      </ConnectWSCProvider>
    </WagmiConfig>
  )
}

export default AppWrapper
