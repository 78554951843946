import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { BsArrowLeft } from "react-icons/bs"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import {
  Caption,
  CaptionMedium,
  CaptionSmall,
  Header2,
  Header5,
  Header6Light,
  Subtitle,
} from "styles/TextStyles"
import { AiOutlineInfoCircle, AiOutlineSwap } from "react-icons/ai"
import {
  CollectFeesButton,
  IncreaseLiquidityButton,
  RemoveLiquidityButton,
} from "styles/ButtonStyles"

import {
  Chains,
  FIXED_RANGE_FARMS,
  LIQUID_STAKING_TOKEN_ADDRESS,
  MAX_UINT128,
  NATIVE_SYMBOL,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
  WRAPPED_ADDRESS,
  WRAPPED_SYMBOL,
  calcAmount,
  isInvalid,
  tickToPrice,
  wrapAddr,
} from "@tangleswap/sdk"
import { toast as TangleToast } from "sonner"
import HoverBox from "components/utils/HoverBox"
import LiquidityLoading from "components/Liquidity/component/LiquidityLoading"
import LiquidityPositionsLoadingPlaceholder from "components/Liquidity/component/LiquidityPositionsLoadingPlaceholder"

import ClaimFeesModal from "components/Liquidity/component/ClaimFeesModal"
import { useWSCContext } from "context/MilkomedaContext"
import CollectFeesConfirmationModal from "components/confirmation/CollectFeesConfirmationModal"
import LoadingDots from "components/utils/LoadingDots"
import { useAppDispatch, useAppSelector } from "store/hooks"
import LPType from "utils/LPType"
import PoolFee from "components/Liquidity/component/PoolFee"
import CollectAsWrappedNative from "components/Liquidity/CollectAsWrappedNative"

import LiquidityDetailsGraph from "./LiquidityDetailsGraph"
import { useIsSupportedNetwork } from "constants/hooks"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import { fetchTangleCurrency } from "../utils/liquidity/useFetchLPCurrency"

import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import useExplorerInfo from "utils/useExplorerInfo"
import { fetchTangleFiatValue } from "utils/useTangleFiatValue"
import { toApproxMax, toApproxMin } from "utils/toSignificant"
import { useQuery } from "react-query"
import { PendingStateContext } from "context/PendingContext"
import { useTangleship } from "utils/useTangleship"
import CurrencyTokenImages from "../component/CurrencyTokenImages"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"
import useTangleArray, { getSinglePos } from "utils/useTangleArray"
import Tooltip from "components/utils/Tooltip"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import CollectFeesModal, {
  useCollectFeesModalControl,
} from "components/milkomeda/liquidity/CollectFeesModal"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import {
  openWSCProgressModal,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"

interface PoolNFTProps {
  description?: string
  image?: string
  name?: string
  active?: boolean
}

interface BoxHeightProps {
  closedLP?: boolean
  inFarm?: boolean
  collectNativeToken?: string | boolean
}

export const DEFAULT_LP_REFETCH = true

const LiquidityDetailsContainer = () => {
  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()

  const { tangleship } = useTangleship()

  const [feeForLiquidStaking, setFeeForLiquidStaking] = useState<any>(undefined)
  const { setOpenModalSettings } = useContext(CancelPendingContext)

  const [showHelpText, setShowHelpText] = useState<boolean>(false)
  const [showTopHelpText, setShowTopHelpText] = useState<boolean>(false)
  const [isWrappedToggled, setIsWrappedToggled] = useState<any>(undefined)
  const [feeValue0, setFeeValue0] = useState<any>(NaN)
  const [feeValue1, setFeeValue1] = useState<any>(NaN)
  const [showCollectFees, setShowCollectFees] = useState<boolean>(undefined)
  const [positionInfo, setPositionInfo] = useState<any>()
  const [feesFetched, setFeesFetched] = useState<boolean>(false)
  const [positionFeesAvailable, setPositionFeesAvailable] = useState<any>()
  const [userIsPositionOwner, setUserIsPositionOwner] = useState<boolean>()
  const [farmIsPositionOwner, setFarmIsPositionOwner] = useState<boolean>()
  const [token0FiatValue, setToken0FiatValue] = useState<number | any>()
  const [token1FiatValue, setToken1FiatValue] = useState<number | any>()
  const [liquidityFiatValue, setLiquidityFiatValue] = useState<number>()
  const [unclaimedFeesFiatValue, setUnclaimedFeesFiatValue] = useState<number>()
  const [feeForCurrencyA, setFeeForCurrencyA] = useState<any>(undefined)
  const [feeForCurrencyB, setFeeForCurrencyB] = useState<any>(undefined)
  const [feeShow, setFeeShow] = useState<boolean>(false)
  const [tx, setTx] = useState<any>(undefined)
  const [collectingFees, setCollectingFees] = useState<boolean>(false)
  const [txSent, setTxSent] = useState<any>(undefined)
  const { setPendingTransaction } = useContext(PendingStateContext)
  const [priceUpperConvert, setPriceUpperConvert] = useState<any>(undefined)
  const [priceLowerConvert, setPriceLowerConvert] = useState<any>(undefined)
  const [poolNFT, setPoolNFT] = useState<any>(undefined)
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false)
  const [txpending, setTxPending] = useState<boolean>(true)
  const [selectedToken, setSelectedToken] = useState<any>(undefined)

  const [tokenAAddress, setTokenAAddress] = useState<any>(undefined)
  const [tokenBAddress, setTokenBAddress] = useState<any>(undefined)
  const [poolToken0, setPoolToken0] = useState<any>(undefined)
  const [poolToken1, setPoolToken1] = useState<any>(undefined)

  const [toggledAddress, setToggledAddress] = useState<any>(undefined)
  const [showToggleWrapped, setShowToggleWrapped] = useState<any>(undefined)
  const [tokenASymbol, setTokenASymbol] = useState<any>(undefined)
  const [tokenBSymbol, setTokenBSymbol] = useState<any>(undefined)
  const [logoCurrencyA, setLogoCurrencyA] = useState<any>(undefined)
  const [logoCurrencyB, setLogoCurrencyB] = useState<any>(undefined)
  const [decimalsA, setDecimalsA] = useState<any>(undefined)
  const [decimalsB, setDecimalsB] = useState<any>(undefined)

  const [closedLP, setClosedLP] = useState<boolean>(false)
  const [currentPrice, setCurrentPrice] = useState<any>(undefined)
  const [outOfRange, setOutOfRange] = useState<any>(undefined)
  const switchRef = useRef<HTMLDivElement | any>(null)
  const lpModalRef = useRef<HTMLDivElement | any>(null)
  const modalRef = useRef<HTMLDivElement | any>(null)
  // const [currencyA, setCurrencyA] = useState<any>(undefined)
  // const [currencyB, setCurrencyB] = useState<any>(undefined)
  // const [toggledToken, setToggledToken] = useState<any>(undefined)
  const { explorerURL } = useExplorerInfo()
  const { id: liquidityID } = useParams()
  const mouseEnter = () => {
    setShowHelpText(true)
  }
  const wscModalRef = useRef<any>(null)
  const mouseLeave = () => {
    setShowHelpText(false)
  }
  const mouseTopEnter = () => {
    setShowTopHelpText(true)
  }

  const mouseTopLeave = () => {
    setShowTopHelpText(false)
  }

  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  useEffect(() => {
    setTimeout(() => {
      setFeesFetched(false)
    }, 1000)
  }, [feesFetched])

  const { TangleswapUserPools } = useTangleArray(account, chainId)

  const getPosInfo = useCallback(
    async (refetch = false) => {
      const id = Number(liquidityID)
      let info = TangleswapUserPools?.find((pool) => Number(pool.id) === id)
      if (info && !refetch) return info

      const updatedInfo = await getSinglePos(chainId, tangleship, null, id)
      return {
        feeTier: Number(updatedInfo?.feeTier),
        liquidity: Number(updatedInfo?.liquidity),
        tickLower: Number(updatedInfo?.tickLower),
        tickUpper: Number(updatedInfo?.tickUpper),
        token0Address: updatedInfo?.token0Address,
        token1Address: updatedInfo?.token1Address,
        owner: info?.owner,
        userWalletAddress: info?.userWalletAddress,
      }
    },
    [TangleswapUserPools, chainId, liquidityID, tangleship]
  )

  useEffect(() => {
    if (!TangleswapUserPools || !liquidityID || !chainId) return

    const liqChangeKey = `liquidityIDchange${chainId}${liquidityID}`
    const storageValue = Number(localStorage.getItem(liqChangeKey) || 0)
    const needsRefetch = storageValue > 0 || DEFAULT_LP_REFETCH
    if (needsRefetch)
      localStorage.setItem(liqChangeKey, String(storageValue - 1))

    getPosInfo(needsRefetch).then((info) => {
      if (!info) return
      // const storageValue = localStorage.getItem(liqChangeKey)
      // let updatedLiq = Number(info?.liquidity)
      // if (storageValue) {
      //   updatedLiq *= 1 + Number(storageValue)
      //   setTimeout(() => localStorage.setItem(liqChangeKey, "0"), 1000)
      // }

      const formattedInfo = {
        fee: Number(info?.feeTier),
        // liquidity: updatedLiq,
        liquidity: Number(info?.liquidity),
        tickLower: Number(info?.tickLower),
        tickUpper: Number(info?.tickUpper),
        token0: info?.token0Address,
        token1: info?.token1Address,
        owner: info?.owner,
        userWalletAddress: info?.userWalletAddress,
      }
      setPositionInfo(formattedInfo)
    })
  }, [TangleswapUserPools, chainId, getPosInfo, liquidityID])

  useEffect(() => {
    if (!chainId || !tokenAAddress || isInvalid([decimalsA])) return

    fetchTangleFiatValue(tokenAAddress, decimalsA, chainId).then((res) => {
      setToken0FiatValue(res || 0)
    })
  }, [chainId, decimalsA, tokenAAddress])

  useEffect(() => {
    if (!chainId || !tokenBAddress || isInvalid([decimalsB])) return

    fetchTangleFiatValue(tokenBAddress, decimalsB, chainId).then((res) => {
      setToken1FiatValue(res || 0)
    })
  }, [chainId, decimalsB, tokenBAddress])

  const { data: currencyA, refetch: tokenARefetch } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleswapDetailsLPToken0", tokenAAddress, l1ChainId],
    () => fetchTangleCurrency(tokenAAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenAAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: currencyB, refetch: tokenBRefetch } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleswapDetailsLPToken1", tokenBAddress, l1ChainId],
    () => fetchTangleCurrency(tokenBAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenBAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const { data: toggledToken, refetch: tokenToggleRefetch } = useQuery(
    ["tangleswapDetailsLPToken1", toggledAddress, l1ChainId],
    () => fetchTangleCurrency(toggledAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!toggledAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )
  useEffect(() => {
    const tokenA =
      WRAPPED_ADDRESS[chainId] === positionInfo?.token0
        ? NATIVE_SYMBOL[chainId]
        : positionInfo?.token0
    const tokenB =
      WRAPPED_ADDRESS[chainId] === positionInfo?.token1
        ? NATIVE_SYMBOL[chainId] // this is what we use as address for the native currency
        : positionInfo?.token1
    setTokenAAddress(tokenA)
    setTokenBAddress(tokenB)

    const toggledAddressValue = isWrappedToggled
      ? WRAPPED_ADDRESS[chainId]
      : NATIVE_SYMBOL[chainId] // we use symbol as native address
    const showToggleWrappedValue =
      WRAPPED_ADDRESS[chainId] === positionInfo?.token0 ||
      WRAPPED_ADDRESS[chainId] === positionInfo?.token1
    setToggledAddress(toggledAddressValue)
    setShowToggleWrapped(showToggleWrappedValue)

    const tokenASymbolValue =
      currencyA && currencyA?.symbol === WRAPPED_SYMBOL[chainId]
        ? NATIVE_SYMBOL[chainId]
        : currencyA && currencyA?.symbol
    const tokenBSymbolValue =
      currencyB && currencyB?.symbol === WRAPPED_SYMBOL[chainId]
        ? NATIVE_SYMBOL[chainId]
        : currencyB && currencyB?.symbol
    setTokenASymbol(tokenASymbolValue)
    setTokenBSymbol(tokenBSymbolValue)

    const logoCurrencyAValue =
      WRAPPED_ADDRESS[chainId] === positionInfo?.token0
        ? toggledToken && toggledToken?.logoURI
        : currencyA && currencyA?.logoURI
    const logoCurrencyBValue =
      WRAPPED_ADDRESS[chainId] === positionInfo?.token1
        ? toggledToken && toggledToken?.logoURI
        : currencyB && currencyB?.logoURI

    setLogoCurrencyA(logoCurrencyAValue)
    setLogoCurrencyB(logoCurrencyBValue)

    const decimalsAValue =
      currencyA === undefined ? 0 : currencyA && currencyA?.decimals
    const decimalsBValue =
      currencyB === undefined ? 0 : currencyB && currencyB?.decimals
    setDecimalsA(decimalsAValue)
    setDecimalsB(decimalsBValue)
  }, [
    chainId,
    currencyA,
    currencyB,
    isWrappedToggled,
    positionInfo?.token0,
    positionInfo?.token1,
    toggledToken,
  ])

  useEffect(() => {
    if (!account) {
      setUserIsPositionOwner(false)
    } else if (positionInfo) {
      const owner = positionInfo?.owner?.trim()?.toLowerCase()
      const userWallet = positionInfo?.userWalletAddress?.trim()?.toLowerCase()
      const formattedAccount = account?.trim()?.toLowerCase()

      if (owner === formattedAccount) return setUserIsPositionOwner(true)

      const isFarmOwner = FIXED_RANGE_FARMS[chainId]
        .map((addr) => addr.trim().toLowerCase())
        .includes(owner)

      if (!!isFarmOwner) {
        setFarmIsPositionOwner(true)
        setUserIsPositionOwner(userWallet === formattedAccount)
      } else return setUserIsPositionOwner(false)
    }
  }, [positionInfo, liquidityID, account, chainId, tangleship])

  const calcPendingFees = (refetch = false) => {
    const storageKey0 = `liquidityID0fees${chainId}${liquidityID}`
    const storageKey1 = `liquidityID1fees${chainId}${liquidityID}`
    const storageValue0 = sessionStorage.getItem(storageKey0)
    const storageValue1 = sessionStorage.getItem(storageKey1)
    if (storageValue0 !== null && storageValue1 !== null) {
      setFeeValue0(Number(storageValue0))
      setFeeValue1(Number(storageValue1))
      if (!refetch) return
    }

    if (!liquidityID) return
    tangleship
      .calculatePendingFees(Number(liquidityID), MAX_UINT128, MAX_UINT128)
      .then((res) => {
        const fee0 = parseInt(res?.amount0?._hex)
        const fee1 = parseInt(res?.amount1?._hex)
        if (isNaN(fee0) || isNaN(fee1)) return
        sessionStorage.setItem(storageKey0, String(fee0))
        sessionStorage.setItem(storageKey1, String(fee1))
        setFeeValue0(fee0)
        setFeeValue1(fee1)
      })
  }

  useEffect(() => {
    if (isInvalid([liquidityID, positionInfo])) return
    calcPendingFees(DEFAULT_LP_REFETCH)
  }, [liquidityID, chainId, positionInfo])

  useEffect(() => {
    if (isInvalid([decimalsA, decimalsB])) return
    const lowerValue = tickToPrice(
      positionInfo?.tickLower,
      decimalsA,
      decimalsB
    )
    const priceLower = toApproxMin(lowerValue)

    setPriceLowerConvert(priceLower)
  }, [decimalsA, decimalsB, positionInfo?.tickLower])
  useEffect(() => {
    if (isInvalid([decimalsA, decimalsB, positionInfo?.tickUpper])) return

    const upperValue = tickToPrice(
      positionInfo?.tickUpper,
      decimalsA,
      decimalsB
    )
    const priceUpper = toApproxMax(upperValue)
    setPriceUpperConvert(priceUpper)
  }, [decimalsA, decimalsB, positionInfo?.tickUpper])

  const dispatch = useAppDispatch()

  // Define setter function
  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  // Set the reducer values
  useEffect(() => {
    if (
      isInvalid([
        currencyA?.address,
        currencyB?.address,
        currencyA?.decimals,
        currencyB?.decimals,
      ])
    )
      return

    const params = {
      token0: currencyA?.address,
      token1: currencyB?.address,
      decimals0: currencyA?.decimals,
      decimals1: currencyB?.decimals,
      feeTier: positionInfo?.fee,
    }
    checkSlot0Price(params)
  }, [
    checkSlot0Price,
    currencyA?.address,
    currencyA?.decimals,
    currencyB?.address,
    currencyB?.decimals,
    positionInfo?.fee,
  ])

  // Get the reducer values
  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  // Set the specific values
  useEffect(() => {
    if (!slot0PricesList) return

    const address0 = currencyA?.address
    const address1 = currencyB?.address
    const addr0 = wrapAddr(address0, chainId)?.tokenAddress?.toLowerCase()
    const addr1 = wrapAddr(address1, chainId)?.tokenAddress?.toLowerCase()
    const isSameOrder = addr0 < addr1
    const token0 = isSameOrder ? addr0 : addr1
    const token1 = isSameOrder ? addr1 : addr0

    const poolKey = `${token0}_${token1}_${positionInfo?.fee}`
    const listItem = slot0PricesList?.[poolKey]
    if (!listItem) return

    setCurrentPrice(listItem?.price)
  }, [
    currencyA?.address,
    currencyB?.address,
    positionInfo?.fee,
    slot0PricesList,
  ])

  useEffect(() => {
    const outOfRangeValue = Boolean(
      currentPrice &&
        priceLowerConvert &&
        priceUpperConvert &&
        (currentPrice < priceLowerConvert || currentPrice > priceUpperConvert)
    )
    setOutOfRange(outOfRangeValue)
  }, [currentPrice, priceLowerConvert, priceUpperConvert])

  useEffect(() => {
    if (
      !currencyA ||
      !currencyB ||
      !currencyA?.address ||
      !currencyB?.address ||
      !currentPrice ||
      isInvalid([decimalsA, decimalsB])
    )
      return

    const amount0 =
      calcAmount(
        currencyA?.address,
        currencyB?.address,
        chainId,
        true,
        positionInfo?.liquidity,
        currentPrice,
        tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB),
        tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB),
        decimalsA,
        decimalsB
      ) /
      10 ** decimalsA

    setPoolToken0(amount0)

    const amount1 =
      calcAmount(
        currencyA?.address,
        currencyB?.address,
        chainId,
        false,
        positionInfo?.liquidity,
        currentPrice,
        tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB),
        tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB),
        decimalsA,
        decimalsB
      ) /
      10 ** decimalsB
    setPoolToken1(amount1)
  }, [
    chainId,
    currencyA,
    currencyB,
    currentPrice,
    decimalsA,
    decimalsB,
    positionInfo?.liquidity,
    positionInfo?.tickLower,
    positionInfo?.tickUpper,
  ])

  const prepCollectFeesFn = () => {
    const isUnwrapNeeded = showToggleWrapped ? !!isWrappedToggled : undefined

    const fn = tangleship?.collectPendingFees
    const fnParams = [
      currencyA?.address,
      currencyB?.address,
      Number(liquidityID),
      account,
      MAX_UINT128,
      MAX_UINT128,
      isUnwrapNeeded,
    ] as const

    const fnFeedback = (params?) => {
      calcPendingFees(true)
    }

    return { fn, fnParams, fnFeedback }
  }

  const isSupportedNetwork = useIsSupportedNetwork()
  const collectMaxFees = () => {
    const supported = isSupportedNetwork
    if (!supported) {
      return
    } else {
      // if (!chainId || !account || !tokenId || !provider) return
      setShowCollectFees(false)
      setShowPendingModal(true)
      setCollectingFees(true)
      setTxPending(true)
      if (account === undefined || account === null) return

      const { fn, fnParams, fnFeedback } = prepCollectFeesFn()

      fn?.(...fnParams)?.then((res) => {
        setTx(res)
        setShowPendingModal(true)
        setTxPending(false)
        setPendingTransaction(true)
        setCollectingFees(true)
        setShowCollectFees(false)
        setTxSent(res?.hash)
        setCollectingFees(false)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fnFeedback()

            setPendingTransaction(false)
            setFeesFetched(true)
            tokenARefetch()
            tokenBRefetch()
            tokenToggleRefetch()
            TangleToast.success("Transaction successful", {
              description: `Collecting fees for ${currencyA?.symbol} and ${currencyB?.symbol} successful`,
            })
          })
        } else {
          setShowCollectFees(false)
          setShowPendingModal(false)
          setTxSent(res?.hash)
          setTx(undefined)
          setTxPending(true)
          setPendingTransaction(false)
          setCollectingFees(false)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  const collectFeesModal = () => {
    setShowCollectFees(!showCollectFees)
  }

  useEffect(() => {
    const feeForCurrencyValue =
      feeValue0 / 10 ** (currencyA && currencyA?.decimals)
    setFeeForCurrencyA(feeForCurrencyValue)
  }, [positionInfo, currencyA && currencyA?.decimals, feesFetched, feeValue0])

  useEffect(() => {
    const feeForCurrencyValue =
      feeValue1 / 10 ** (currencyB && currencyB?.decimals)
    setFeeForCurrencyB(feeForCurrencyValue)
  }, [positionInfo, feesFetched, currencyB && currencyB?.decimals, feeValue1])

  useEffect(() => {
    if (positionInfo?.liquidity === undefined) return
    const closedLPValue = parseInt(positionInfo?.liquidity) === parseInt("0")
    setClosedLP(closedLPValue)
  }, [positionInfo?.liquidity, chainId, account])

  useEffect(() => {
    const positionFeesValues =
      positionInfo &&
      Boolean(!feesFetched) &&
      (feeForCurrencyA !== 0 || feeForCurrencyB !== 0)
    setPositionFeesAvailable(positionFeesValues)
  }, [positionInfo, feesFetched, feeForCurrencyB, feeForCurrencyA])

  const feeANotNaN = positionInfo && !isNaN(feeForCurrencyA)
  const feeBNotNaN = positionInfo && !isNaN(feeForCurrencyB)

  const lpBodyClick = (e: any) => {
    if (e?.target === lpModalRef.current) {
      setShowCollectFees(!showCollectFees)
    }
  }

  const closePendingModal = () => {
    setShowPendingModal(false)
  }

  const closeCollectFeesModal = () => {
    setShowPendingModal(false)
    setFeesFetched(true)
  }

  const closeShowPendingModal = (event: any) => {
    if (modalRef.current === event?.target) {
      setShowPendingModal(false)
    }
  }

  const onWrappedChange = () => {
    setIsWrappedToggled(true)
  }
  const offWrappedChange = () => {
    setIsWrappedToggled(false)
  }

  const showFeePool = () => {
    setFeeShow(!feeShow)
  }

  useEffect(() => {
    if (
      isInvalid([
        token0FiatValue,
        token1FiatValue,
        feeForCurrencyA,
        feeForCurrencyB,
        poolToken0,
        poolToken1,
      ])
    )
      return

    setLiquidityFiatValue(
      token0FiatValue * poolToken0 + token1FiatValue * poolToken1
    )
    setUnclaimedFeesFiatValue(
      token0FiatValue * feeForCurrencyA + token1FiatValue * feeForCurrencyB
    )

    // setLiquidityFiatValue(poolToken0 / token0FiatValue + poolToken1 / token1FiatValue)
    // setUnclaimedFeesFiatValue(feeForCurrencyA / token0FiatValue + feeForCurrencyB / token1FiatValue)
  }, [
    token0FiatValue,
    token1FiatValue,
    feeForCurrencyA,
    feeForCurrencyB,
    poolToken0,
    poolToken1,
    positionInfo,
    feesFetched,
  ])

  const collectNativeToken =
    positionFeesAvailable && feeANotNaN && feeBNotNaN && showToggleWrapped

  useEffect(() => {
    if (!currencyA && currencyA?.address) return
    const reverseActiveCurrency = currencyA && currencyA?.symbol
    setSelectedToken(reverseActiveCurrency)

    return () => {
      setSelectedToken(undefined)
    }
  }, [currencyA && currencyA?.address])

  useEffect(() => {
    if (!liquidityID || !chainId || !tangleship) return

    const storageKey = `liquidityIDimage${chainId}${liquidityID}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setPoolNFT(storageValue)
      return
    }
    tangleship
      .getURIFromNFT(
        NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId],
        Number(liquidityID)
      )
      .then((res: PoolNFTProps) => {
        if (!res?.image) return
        localStorage.setItem(storageKey, res.image)
        setPoolNFT(res.image)
      })
  }, [liquidityID, chainId, tangleship])

  const stAdaLogo =
    "https://raw.githubusercontent.com/TangleSwap/assets/main/chains/cardano-testnet/info/logo.png"

  const isLiquidStaking =
    (chainId === Chains.CARDANO_TEST || chainId === Chains.CARDANO) &&
    (positionInfo?.token0 === LIQUID_STAKING_TOKEN_ADDRESS[chainId] ||
      positionInfo?.token1 === LIQUID_STAKING_TOKEN_ADDRESS[chainId])

  useEffect(() => {
    if (
      !account ||
      !chainId ||
      (chainId !== Chains.CARDANO_TEST && chainId !== Chains.CARDANO)
    )
      return

    tangleship
      .getLiqStakingPendingRewards(account)
      .then((res) => setFeeForLiquidStaking(res))
  }, [chainId, account, tangleship])

  const [wrapData, setWrapData] = useState<IWrapMultipleData>(undefined)
  const [evmFn, setEvmFn] = useState<any>(undefined)
  const { isVisible: isWSCModalVisible, toggle: toggleWSCModal } =
    useCollectFeesModalControl()

  const isWSCReady = () =>
    isWSCConnected && currencyA?.address && currencyB?.address

  const fireWSCModal = () => {
    if (!isWSCReady()) return

    const tokenOutData = []
    const { fn, fnParams, fnFeedback } = prepCollectFeesFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      if (Number(feeForCurrencyA) > 0) {
        tokenOutData.push({
          address: currencyA?.address,
          name: currencyA?.name,
          symbol: currencyA?.symbol,
          chainId: chainId,
          decimals: currencyA?.decimals,
          logoURI: currencyA?.logoURI,
          amount: Number(feeForCurrencyA),
          unit: getTokenUnit(currencyA?.l1Address),
          l1Address: currencyA?.l1Address,
          l1EncodedName: currencyA?.l1EncodedName,
          l1Decimals: currencyA?.l1Decimals,
        })
      }

      if (Number(feeForCurrencyB) > 0) {
        tokenOutData.push({
          address: currencyB?.address,
          name: currencyB?.name,
          symbol: currencyB?.symbol,
          chainId: chainId,
          decimals: currencyB?.decimals,
          logoURI: currencyB?.logoURI,
          amount: Number(feeForCurrencyB),
          unit: getTokenUnit(currencyB?.l1Address),
          l1Address: currencyB?.l1Address,
          l1EncodedName: currencyB?.l1EncodedName,
          l1Decimals: currencyB?.l1Decimals,
        })
      }

      setWrapData({
        tokenOut: tokenOutData,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleWSCModal()
      }, 0)
    })
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleWSCModal()
    }
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWSCModal()
    setOpenModalSettings(false)
  }
  return (
    <Body>
      <Cover>
        {showPendingModal && (
          <>
            <CollectFeesConfirmationModal
              modalRef={modalRef}
              pending={txpending}
              closePendingModal={closePendingModal}
              closeModal={closeShowPendingModal}
              link={txSent}
              closeCollectFeesModal={closeCollectFeesModal}
            />
          </>
        )}

        {isWSCConnected && (
          <CollectFeesModal
            isVisible={isWSCModalVisible}
            toggleModal={toggleWSCModal}
            wrapData={wrapData}
            wscModalRef={wscModalRef}
            cancelTransaction={cancelTransaction}
            closeWSCContinue={closeWSCContinue}
          />
        )}

        {!positionInfo ? (
          // start of loading
          <>
            <LiquidityLoading />
          </>
        ) : (
          <>
            <>
              {" "}
              {showCollectFees ? (
                <>
                  <ClaimFeesModal
                    closeRemoveModal={collectFeesModal}
                    clickCollect={
                      isWSCConnected
                        ? () => {
                            fireWSCModal()
                            collectFeesModal()
                          }
                        : collectMaxFees
                    }
                    lpBodyClick={lpBodyClick}
                    lpModalRef={lpModalRef}
                    inputSymbol={
                      isWrappedToggled &&
                      WRAPPED_ADDRESS[chainId] === positionInfo?.token0
                        ? WRAPPED_SYMBOL[chainId]
                        : tokenASymbol
                    }
                    inputLogo={logoCurrencyA}
                    inputValue={
                      positionInfo &&
                      feeForCurrencyA &&
                      formatNumber(feeForCurrencyA, 4)
                    }
                    outputSymbol={
                      isWrappedToggled &&
                      WRAPPED_ADDRESS[chainId] === positionInfo?.token1
                        ? WRAPPED_SYMBOL[chainId]
                        : tokenBSymbol
                    }
                    outputLogo={logoCurrencyB}
                    outputValue={
                      positionInfo &&
                      feeForCurrencyB &&
                      formatNumber(feeForCurrencyB, 4)
                    }
                    {...(isLiquidStaking && {
                      isLiquidStaking: true,
                      feeForLiquidStaking: feeForLiquidStaking,
                    })}
                  />
                </>
              ) : null}
            </>
            <>
              <>
                <LPNavigation>
                  <NavBottom>
                    <SizeWrapper>
                      <ButtonWrapper>
                        <LPLink to={`/pools`}>
                          <BackIcon />
                        </LPLink>
                      </ButtonWrapper>
                      <SizeTop>
                        <CurrencyTokenImages
                          token0Info={currencyA}
                          token1Info={currencyB}
                        />

                        {currencyA ? (
                          <>
                            {" "}
                            <SizeName>
                              {tokenASymbol}/{tokenBSymbol}
                            </SizeName>
                          </>
                        ) : (
                          <>
                            {" "}
                            <LiquidityPositionsLoadingPlaceholder />
                          </>
                        )}

                        {positionInfo ? (
                          <NumberWrap>
                            {feeShow ? <PoolFee /> : null}
                            <SizeNumber
                              onMouseEnter={showFeePool}
                              onMouseLeave={showFeePool}
                            >
                              {positionInfo?.fee / 10000}%
                            </SizeNumber>
                          </NumberWrap>
                        ) : (
                          <>
                            <LiquidityPositionsLoadingPlaceholder />
                          </>
                        )}

                        {Boolean(userTradingMode) === true ? (
                          <>
                            <LPTypeCover>
                              <LPType
                                positionInfo={positionInfo}
                                closedLP={closedLP}
                                outOfRange={outOfRange}
                              />
                            </LPTypeCover>
                            <InfoFarmWrapper inFarm={!!farmIsPositionOwner}>
                              <InFarmText>
                                <InFarmHelpWrapper>
                                  <Tooltip
                                    direction="bottom"
                                    text="To remove your liquidity or collect fees, please navigate to the Farm section and select Stop Farming for the specific LP-position."
                                  >
                                    <HelpIcon />
                                  </Tooltip>
                                </InFarmHelpWrapper>
                                Currently in Farm
                              </InFarmText>
                            </InfoFarmWrapper>
                          </>
                        ) : null}
                      </SizeTop>
                    </SizeWrapper>
                    <LPButtons closedLP={Boolean(closedLP)}>
                      {userIsPositionOwner && (
                        <div>
                          {" "}
                          <Link to={`/pools/increase/${liquidityID}`}>
                            <AddLiquid>Increase Liquidity</AddLiquid>
                          </Link>
                        </div>
                      )}
                      {!closedLP &&
                        userIsPositionOwner &&
                        !farmIsPositionOwner && (
                          <div>
                            <Link to={`/pools/remove/${liquidityID}`}>
                              <RemoveLiquid>Remove Liquidity</RemoveLiquid>
                            </Link>
                          </div>
                        )}
                    </LPButtons>
                  </NavBottom>
                </LPNavigation>

                <LPInfo>
                  <InfoTop>
                    {/* start of left box */}
                    <LiquidityBox
                      collectNativeToken={
                        [
                          Chains.L1_CARDANO,
                          Chains.L1_CARDANO_TEST,
                          Chains.CARDANO,
                          Chains.CARDANO_TEST,
                        ].includes(l1ChainId)
                          ? "cardano"
                          : String(collectNativeToken)
                      }
                    >
                      <BoxTop>
                        <BoxTitle>Liquidity</BoxTitle>
                      </BoxTop>
                      {true ? (
                        <>
                          {" "}
                          <BoxMiddle>
                            {positionInfo && (
                              <>
                                <MiddleText>
                                  {`$${
                                    liquidityFiatValue === 0 ||
                                    (liquidityFiatValue &&
                                      !isNaN(Number(liquidityFiatValue))) ||
                                    (liquidityFiatValue &&
                                      !Number.isNaN(liquidityFiatValue))
                                      ? formatNumber(
                                          liquidityFiatValue,
                                          3,
                                          true
                                        )
                                      : "—"
                                  }`}
                                </MiddleText>
                              </>
                            )}
                          </BoxMiddle>
                          {currencyA && positionInfo ? (
                            <>
                              {" "}
                              <BoxBottom>
                                <BowRow>
                                  {currencyA ? (
                                    <>
                                      <IconWrap>
                                        {!!currencyA?.logoURI ? (
                                          <>
                                            {" "}
                                            <BoxIcon
                                              src={
                                                currencyA && currencyA?.logoURI
                                              }
                                            />
                                          </>
                                        ) : (
                                          <CustomSizeWrapper>
                                            <ConvertTokenSymbol
                                              text={currencyA?.symbol}
                                            />
                                          </CustomSizeWrapper>
                                        )}

                                        <IconName>{tokenASymbol}</IconName>
                                      </IconWrap>
                                      <LPBoxValue>
                                        <IconName>
                                          {positionInfo && isNaN(poolToken0) ? (
                                            <>
                                              <LoadingDots />
                                            </>
                                          ) : (
                                            <>
                                              <>
                                                {poolToken0 === 0 ? (
                                                  <>0</>
                                                ) : (
                                                  formatNumber(poolToken0, 4)
                                                )}
                                              </>
                                            </>
                                          )}
                                        </IconName>
                                      </LPBoxValue>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <LiquidityPositionsLoadingPlaceholder />
                                    </>
                                  )}
                                </BowRow>
                                <BowRow>
                                  {positionInfo && (
                                    <>
                                      <IconWrap>
                                        {!!currencyB?.logoURI ? (
                                          <>
                                            {" "}
                                            <BoxIcon
                                              src={
                                                currencyB && currencyB?.logoURI
                                              }
                                            />
                                          </>
                                        ) : (
                                          <CustomSizeWrapper>
                                            {" "}
                                            <ConvertTokenSymbol
                                              text={currencyB?.symbol}
                                            />
                                          </CustomSizeWrapper>
                                        )}

                                        <IconName>{tokenBSymbol}</IconName>
                                      </IconWrap>
                                      <LPBoxValue>
                                        <IconName>
                                          <>
                                            {" "}
                                            {positionInfo &&
                                            isNaN(poolToken1) ? (
                                              <>
                                                <LoadingDots />
                                              </>
                                            ) : (
                                              <>
                                                {poolToken1 === 0 ? (
                                                  <>0</>
                                                ) : (
                                                  <>
                                                    {formatNumber(
                                                      Number(poolToken1),
                                                      4
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        </IconName>
                                      </LPBoxValue>
                                    </>
                                  )}
                                </BowRow>
                              </BoxBottom>
                            </>
                          ) : (
                            <LiquidityPositionsLoadingPlaceholder />
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <LiquidityPositionsLoadingPlaceholder />
                        </>
                      )}
                    </LiquidityBox>

                    {/* end of left box */}
                    {/* start of right box */}
                    <LiquidityBox
                      collectNativeToken={Boolean(collectNativeToken)}
                    >
                      <BoxTop>
                        <BoxTitle>Unclaimed Fees</BoxTitle>
                        {positionFeesAvailable &&
                        feeANotNaN &&
                        feeBNotNaN &&
                        userIsPositionOwner &&
                        !farmIsPositionOwner ? (
                          <>
                            <BoxTopButton
                              disabled={Boolean(collectingFees)}
                              onClick={collectFeesModal}
                            >
                              {collectingFees ? (
                                <>
                                  <LoadingDots />
                                </>
                              ) : (
                                <> Collect Fees</>
                              )}
                            </BoxTopButton>
                          </>
                        ) : null}
                      </BoxTop>
                      <BoxMiddle>
                        {currencyA && (
                          <MiddleText>
                            {`$${
                              unclaimedFeesFiatValue === 0 ||
                              (unclaimedFeesFiatValue &&
                                !isNaN(Number(unclaimedFeesFiatValue))) ||
                              (unclaimedFeesFiatValue &&
                                !Number.isNaN(unclaimedFeesFiatValue))
                                ? formatNumber(unclaimedFeesFiatValue, 3, true)
                                : "—"
                            }`}
                          </MiddleText>
                        )}
                      </BoxMiddle>
                      <BoxBottom>
                        {positionInfo ? (
                          <>
                            {" "}
                            <BowRow>
                              <IconWrap>
                                {!!logoCurrencyA ? (
                                  <>
                                    <BoxIcon src={logoCurrencyA} />
                                  </>
                                ) : (
                                  <CustomSizeWrapper>
                                    {" "}
                                    <ConvertTokenSymbol text={tokenASymbol} />
                                  </CustomSizeWrapper>
                                )}

                                <IconName>
                                  {isWrappedToggled &&
                                  WRAPPED_ADDRESS[chainId] ===
                                    positionInfo?.token0
                                    ? WRAPPED_SYMBOL[chainId]
                                    : tokenASymbol}
                                </IconName>
                              </IconWrap>
                              <LPBoxValue>
                                <IconName>
                                  <>
                                    {" "}
                                    {positionInfo &&
                                    isNaN(feeForCurrencyA) &&
                                    userIsPositionOwner !== false ? (
                                      <>
                                        {" "}
                                        <LoadingDots />
                                      </>
                                    ) : (
                                      <>
                                        {!userIsPositionOwner &&
                                        !farmIsPositionOwner
                                          ? "—"
                                          : feeForCurrencyA &&
                                            formatNumber(feeForCurrencyA, 4)}
                                      </>
                                    )}
                                  </>
                                </IconName>
                              </LPBoxValue>
                            </BowRow>
                            <BowRow>
                              <IconWrap>
                                {!!logoCurrencyB ? (
                                  <>
                                    {" "}
                                    <BoxIcon src={logoCurrencyB} />
                                  </>
                                ) : (
                                  <CustomSizeWrapper>
                                    {" "}
                                    <ConvertTokenSymbol text={tokenBSymbol} />
                                  </CustomSizeWrapper>
                                )}

                                <IconName>
                                  {isWrappedToggled &&
                                  WRAPPED_ADDRESS[chainId] ===
                                    positionInfo?.token1
                                    ? WRAPPED_SYMBOL[chainId]
                                    : tokenBSymbol}
                                </IconName>
                              </IconWrap>
                              <LPBoxValue>
                                <IconName>
                                  <>
                                    {" "}
                                    {positionInfo &&
                                    isNaN(feeForCurrencyB) &&
                                    userIsPositionOwner !== false ? (
                                      <>
                                        {" "}
                                        <LoadingDots />
                                      </>
                                    ) : (
                                      <>
                                        {!userIsPositionOwner &&
                                        !farmIsPositionOwner
                                          ? "—"
                                          : feeForCurrencyB &&
                                            formatNumber(feeForCurrencyB, 4)}
                                      </>
                                    )}
                                  </>
                                </IconName>
                              </LPBoxValue>
                            </BowRow>
                            {isLiquidStaking && (
                              <>
                                <BowRow>
                                  <IconWrap>
                                    {stAdaLogo && <BoxIcon src={stAdaLogo} />}
                                    <IconName>Native ADA</IconName>
                                    <Tooltip
                                      text={
                                        <span>
                                          Cardano Liquid Staking allows you to
                                          stake your ADA and earn constant
                                          (double) rewards. For more
                                          information, please{" "}
                                          <DocLink href="https://docs.tangleswap.exchange/dapps/special/liquid-staking.md">
                                            refer to our documentation.
                                          </DocLink>
                                        </span>
                                      }
                                    >
                                      <HelpIcon />
                                    </Tooltip>
                                  </IconWrap>
                                  <LPBoxValue>
                                    <IconName>
                                      <ItalicText>Coming in Jan '24</ItalicText>
                                      {/* <>
                                    {positionInfo &&
                                    isNaN(feeForLiquidStaking) &&
                                    userIsPositionOwner !== false &&
                                    !isUseWeb3ReactLoaded ? (
                                      <LoadingDots />
                                    ) : (
                                      <>
                                        {!userIsPositionOwner &&
                                        !farmIsPositionOwner
                                          ? "—"
                                          : feeForLiquidStaking &&
                                            formatNumber(feeForLiquidStaking, 4)}
                                      </>
                                    )}
                                  </> */}
                                    </IconName>
                                  </LPBoxValue>
                                </BowRow>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <LiquidityPositionsLoadingPlaceholder />
                          </>
                        )}
                      </BoxBottom>
                      {positionFeesAvailable &&
                        feeANotNaN &&
                        feeBNotNaN &&
                        showToggleWrapped &&
                        !isWSCConnected && (
                          <>
                            <CollectAsWrappedNative
                              wrappedSymbol={WRAPPED_SYMBOL[chainId]}
                              isToggled={isWrappedToggled}
                              toggleOnButton={onWrappedChange}
                              toggleOffButton={offWrappedChange}
                            />
                          </>
                        )}
                    </LiquidityBox>

                    {/* end of right box */}
                  </InfoTop>

                  {Boolean(userTradingMode) === true ? (
                    <>
                      <InfoMiddleWrapper
                        active={Boolean(poolNFT === undefined)}
                      >
                        <InfoBottomLeft active={Boolean(poolNFT === undefined)}>
                          {Boolean(poolNFT === undefined) ? (
                            <>
                              <LiquidityPositionsLoadingPlaceholder />
                              <LiquidityPositionsLoadingPlaceholder />
                              <LiquidityPositionsLoadingPlaceholder />
                              <LiquidityPositionsLoadingPlaceholder />
                              <LiquidityPositionsLoadingPlaceholder />
                              <LiquidityPositionsLoadingPlaceholder />
                            </>
                          ) : (
                            <>
                              <InfoNftWrapper
                                href={`${
                                  chainId === Chains.SEPOLIA_TEST
                                    ? `${explorerURL?.explorer}/nft/${NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]}/${liquidityID}`
                                    : `${explorerURL?.explorer}/token/${NONFUNGIBLE_POSITION_MANAGER_ADDRESS[chainId]}/instance/${liquidityID}`
                                }`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <InfoNFTImage src={poolNFT} />
                              </InfoNftWrapper>
                            </>
                          )}
                        </InfoBottomLeft>
                        <InfoBottom>
                          <InfoBottomRight>
                            <InfoTop>
                              <TopLeft>
                                <TopText>Price Range</TopText>

                                {Boolean(userTradingMode) === true ? (
                                  <>
                                    <LPTypeCover
                                      onMouseEnter={mouseEnter}
                                      onMouseLeave={mouseLeave}
                                    >
                                      {showHelpText && (
                                        <>
                                          <HoverBox
                                            text={
                                              (closedLP &&
                                                `Your position has zero liquidity, hence it's not earning LP fees.`) ||
                                              (!outOfRange &&
                                                !closedLP &&
                                                `The price of this pool is within your predefined range — your position is currently earning LP fees!`) ||
                                              (outOfRange &&
                                                !closedLP &&
                                                `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                                              //||
                                              // (outOfRange &&
                                              //   !closedLP &&
                                              //   `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                                            }
                                          />
                                        </>
                                      )}
                                      <LPType
                                        positionInfo={positionInfo}
                                        closedLP={closedLP}
                                        outOfRange={outOfRange}
                                      />
                                    </LPTypeCover>
                                  </>
                                ) : null}
                              </TopLeft>
                              <TopRight>
                                {/* <ReverseTokenOrder
                                currencyA={currencyA && currencyA?.symbol}
                                currencyB={currencyB && currencyB?.symbol}
                                handleRateToggle={handleTokenToggle}
                                selectedTokenSymbol={selectedToken}
                              /> */}
                              </TopRight>
                            </InfoTop>
                            {positionInfo ? (
                              <>
                                <LPBottomWrap>
                                  <TopBox>
                                    <BottomBox>
                                      <BoxSmallTitle>Min Price</BoxSmallTitle>
                                      <BoxPrice>
                                        {positionInfo &&
                                        Number.isNaN(priceLowerConvert) ? (
                                          <>
                                            {" "}
                                            <LoadingDots />
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            {typeof priceLowerConvert ===
                                              "number" ||
                                            priceLowerConvert !== "undefined"
                                              ? formatNumber(
                                                  priceLowerConvert,
                                                  4
                                                )
                                              : "≈0"}
                                          </>
                                        )}
                                      </BoxPrice>
                                      <BoxSmallTitleGray>
                                        {tokenBSymbol} per {tokenASymbol}
                                      </BoxSmallTitleGray>
                                    </BottomBox>
                                    <BoxCenter>
                                      <BottomBoxIcon />
                                    </BoxCenter>
                                    <BottomBox>
                                      <BoxSmallTitle>Max Price</BoxSmallTitle>
                                      <BoxPrice>
                                        {" "}
                                        {positionInfo &&
                                        Number.isNaN(priceUpperConvert) ? (
                                          <>
                                            {" "}
                                            <LoadingDots />
                                          </>
                                        ) : (
                                          <>
                                            {priceUpperConvert !==
                                              "undefined" &&
                                            !isNaN(Number(priceUpperConvert))
                                              ? formatNumber(
                                                  priceUpperConvert,
                                                  4
                                                )
                                              : "∞"}
                                          </>
                                        )}
                                      </BoxPrice>
                                      <BoxSmallTitleGray>
                                        {tokenBSymbol} per {tokenASymbol}
                                      </BoxSmallTitleGray>
                                      {/* <BodBodyTitle>
                                    Your position will be 100%{" "}
                                    {tokenASymbol} at this
                                    price
                                  </BodBodyTitle> */}
                                    </BottomBox>
                                  </TopBox>

                                  <BottomLargeBox>
                                    <BoxSmallTitle>Current Price</BoxSmallTitle>
                                    <BoxPrice>
                                      {positionInfo && isNaN(currentPrice) ? (
                                        <>
                                          {" "}
                                          <LoadingDots />
                                        </>
                                      ) : (
                                        <>
                                          {currentPrice &&
                                            formatNumber(currentPrice, 4)}
                                        </>
                                      )}
                                    </BoxPrice>
                                    <BoxSmallTitleGray>
                                      {" "}
                                      {tokenBSymbol} per {tokenASymbol}
                                    </BoxSmallTitleGray>
                                  </BottomLargeBox>
                                </LPBottomWrap>
                              </>
                            ) : (
                              <>
                                {" "}
                                <LiquidityPositionsLoadingPlaceholder />
                                <LiquidityPositionsLoadingPlaceholder />
                                <LiquidityPositionsLoadingPlaceholder />
                                <LiquidityPositionsLoadingPlaceholder />
                                <LiquidityPositionsLoadingPlaceholder />
                                <LiquidityPositionsLoadingPlaceholder />
                              </>
                            )}
                          </InfoBottomRight>
                        </InfoBottom>

                        <ChartBackground>
                          {" "}
                          <LiquidityDetailsGraph
                            currentPrice={currentPrice}
                            minRange={priceLowerConvert}
                            maxRange={priceUpperConvert}
                            currencyALogo={logoCurrencyA}
                            currencyBLogo={logoCurrencyB}
                          />{" "}
                        </ChartBackground>

                        {/* <PointerGraph /> */}
                      </InfoMiddleWrapper>
                    </>
                  ) : null}
                </LPInfo>
              </>
            </>
          </>
        )}
      </Cover>
    </Body>
  )
}

export default React.memo(LiquidityDetailsContainer)

const Body = styled.div`
  width: 100%;
  margin: -19px 0 0 0;
`
const Cover = styled.div`
  max-width: 1056px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const LPLink = styled(Link)`
  max-width: 24px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 80px;
`

const ButtonWrapper = styled.div`
  max-width: 24px;
  width: 100%;
  margin: 0 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const BackIcon = styled(BsArrowLeft)`
  height: 24px;
  width: 26px;
  color: ${TangleColors.white};
  opacity: 0.8;

  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const NavBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0 32px 0;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;

    justify-content: center;
  }
`
const LPInfo = styled.div`
  width: 100%;
  padding: 0 !important;
`
const InfoTop = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px;
  gap: 32px;
  justify-content: center;
  align-items: stretch;

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const InfoBottom = styled.div<PoolNFTProps>`
  width: 100%;
  display: flex; // for production
  /* display: none; //for staging */
`
const InfoMiddleWrapper = styled.div<PoolNFTProps>`
  display: grid; // for staging
  /* display: none; //for production */
  width: 100%;
  padding: 0 !important;
  grid-template-columns: 408px auto;
  grid-gap: ${(props) => (props.active ? `8px` : `0`)};
  gap: ${(props) => (props.active ? `8px` : `0`)};
  align-content: flex-start;

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ChartBackground = styled.div`
  display: none;
`
const InfoBottomLeft = styled.div<PoolNFTProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 620px;
  max-width: 650px;
  border-radius: 32px;
  background: ${(props) =>
    props.active ? `${TangleColors.swapBG}` : "transparent"};
  margin: 32px auto 0 -24px;

  position: relative;
  z-index: 3;
  @media only screen and (max-width: 800px) {
    min-height: 450px;
  }
`

const InfoBottomRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1232px;
  min-height: 620px;
  width: 100%;
  border: 1px solid ${TangleColors.lighthover};
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  margin: 32px auto;
  padding: 16px;
  position: relative;
  z-index: 3;
  @media only screen and (max-width: 800px) {
    min-height: 450px;
  }
`
const InfoNftWrapper = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 32px !important;
`
const InfoNFTImage = styled.img`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 32px !important;
  object-fit: contain !important;
  height: 620px !important;

  @media only screen and (max-width: 800px) {
    min-height: 450px !important;
  }
`

const SizeTop = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin: 0 0 0 36px;
`

const SizeWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
`

const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const SizeName = styled(Caption)`
  margin: 0 8px;

  text-align: left;
`
const SizeNumber = styled(Caption)`
  background: ${TangleColors.icon};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.white};
  cursor: pointer;
  flex-direction: row;
  /* height: 32px; */
  /* max-width: 64px; */
  border-radius: 8px;
  margin: 0 8px 0 0;
  padding: 4px 12px;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`

const LPButtons = styled.div<BoxHeightProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  max-width: ${(props) => (props.closedLP ? "180px" : "380px")};
  margin: 0;
  div {
    margin: 0 0 0 16px;
    width: 100%;
    max-width: 190px;
    @media only screen and (max-width: 800px) {
      max-width: 800px;
    }
  }
  @media only screen and (max-width: 800px) {
    max-width: 800px;
    justify-content: flex-start;
    margin: 24px 0;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    div {
      margin: 6px 0;
      width: 100%;
    }
  }
`
const NumberWrap = styled.div`
  z-index: 2;
  position: relative;
  height: 32px;
  max-width: 72px;
  border-radius: 8px;
`
const AddLiquid = styled(IncreaseLiquidityButton)`
  margin: 0 16px 0 0;

  position: relative;
  z-index: 3;
  @media only screen and (max-width: 650px) {
    margin: 0 0 8px 0;
  }
`
const RemoveLiquid = styled(RemoveLiquidityButton)`
  position: relative;
  z-index: 3;
`

const LPTypeCover = styled.div`
  margin: 0 0 0 4px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 50;
  cursor: pointer;
  align-items: flex-start;
`

const LiquidityBox = styled.div<BoxHeightProps>`
  min-height: ${(props) => {
    if (props.collectNativeToken === "cardano") {
      return "300px"
    } else if (props.collectNativeToken === "true") {
      return "408px"
    } else {
      return "330px"
    }
  }};
  max-width: 512px;
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;

  padding: ${(props) =>
    props.collectNativeToken
      ? "24px 24px 24px 24px "
      : "16.5px 24px 8px 24px "};
  border: 1px solid ${TangleColors.lighthover};
  position: relative;
  z-index: 3;

  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
`
const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin: 0 0 0 -1.5px;
  min-height: 56px;
`
const BoxTitle = styled(Header6Light)`
  color: ${TangleColors.white};
  margin: 0;
`

const BoxTopButton = styled(CollectFeesButton)`
  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.grayLight};

    border: none;
  }
  @media only screen and (max-width: 650px) {
    font-size: 21px;
  }
`
const BoxMiddle = styled.div`
  margin: 16px 0 16px -3.5px;
  width: 100%;
`
const MiddleText = styled(Header2)`
  color: ${TangleColors.white};
  margin: 0;
`
const BoxBottom = styled.div`
  max-width: 100%;
  margin: 24px auto;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;

  padding: 8px 16px;
  width: 100%;

  @media only screen and (max-width: 800px) {
    max-width: 800px;
  }
`
const BowRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0;
`
const BoxIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`
const LPBoxValue = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`
const IconName = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
`

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 290px;
  align-items: center;
  width: 100%;
`

const TopText = styled(Header5)`
  color: ${TangleColors.white};
  width: 100%;
  max-width: 150px;
`

const LPBottomWrap = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

const TopBox = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const BottomBox = styled.div`
  min-height: 150px;
  max-width: 800px;
  width: 100%;
  background: ${TangleColors.modalBG};
  padding: 16px;
  margin: 8px auto;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.4px solid ${TangleColors.lighthover};
`

const BottomLargeBox = styled.div`
  min-height: 150px;
  max-width: 996px;
  width: 100%;

  background: ${TangleColors.modalBG};
  padding: 16px;
  margin: 8px auto;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.4px solid ${TangleColors.lighthover};
`

const BottomBoxIcon = styled(AiOutlineSwap)`
  margin: 0 32px;
  color: ${TangleColors.white};
  height: 20px;
  width: 20px;
`

const BoxSmallTitle = styled(CaptionMedium)`
  color: ${TangleColors.gray};
`

const BoxSmallTitleGray = styled(CaptionMedium)`
  color: ${TangleColors.white};
`
const BoxPrice = styled(Header5)`
  color: ${TangleColors.white};
  margin: 8px 0;
  white-space: normal;
  max-width: 20ch;
`

const BoxCenter = styled.div`
  max-width: 40px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
`

const ItalicText = styled.div`
  font-style: italic;
`

const InFarmHelpWrapper = styled.div`
  margin-right: 4px;
`

const HelpIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
  width: 16px;
`

const DocLink = styled.a`
  color: ${TangleColors.lighthover};
`

const InfoFarmWrapper = styled.div<BoxHeightProps>`
  background: ${TangleColors.dockBG};
  color: ${TangleColors.white};
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  display: ${(props) => (props.inFarm ? "flex" : "none")};
  border: 1px solid ${TangleColors.tangleDarkGreen};
  align-items: center;
  justify-content: center;
`

const InFarmText = styled(CaptionSmall)`
  white-space: nowrap;
  display: flex;
  color: ${TangleColors.white};
`
