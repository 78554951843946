import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { TiWarningOutline } from "react-icons/ti"
import { Subtitle, CaptionSmallPara } from "styles/TextStyles"
import { GrClose } from "react-icons/gr"
interface EfficiencyProps {
  acceptEfficiency: () => void
  closeEfficiency: () => void
}
const EfficiencyComparison: FC<EfficiencyProps> = (props) => {
  const { acceptEfficiency, closeEfficiency } = props
  return (
    <ComparisonBody>
      <CloseIconWrapper onClick={closeEfficiency}>
        {" "}
        <CloseIcon />
      </CloseIconWrapper>
      <Cover>
        <Top>
          <TopIcon />
          <TopText>Heads up!</TopText>
        </Top>
        <Middle>
          <MiddleText>
            Full range positions may earn less fees than concentrated ones. To
            learn more, please check the{" "}
            <EfficientHref
              href="https://docs.tangleswap.exchange/dapps/liquidity/"
              target="_blank"
              rel="noopener noreferrer"
            >
              TangleSwap documentation.
            </EfficientHref>
          </MiddleText>
        </Middle>
        <Bottom>
          <BottomButtom onClick={acceptEfficiency}>
            <BottomText>I understand</BottomText>
          </BottomButtom>
        </Bottom>
      </Cover>
    </ComparisonBody>
  )
}

const ComparisonBody = styled.div`
  max-width: 423px;
  height: 164px;
  width: 100%;
  margin: 24px auto;
  padding: 14px;
  position: relative;
  border-radius: 12px;
  background-color: ${TangleColors.black};
  color: ${TangleColors.tangleYellowPrimary};
  display: flex;
  border: 0.4px solid ${TangleColors.tangleYellow};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const Top = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  margin: 0 0 8px 0;
`
const TopIcon = styled(TiWarningOutline)`
  width: 24px;
  height: 24px;
`
const TopText = styled(Subtitle)`
  color: ${TangleColors.tangleYellowPrimary};
  margin: 0 8px;
`
const Middle = styled.div`
  display: flex;
  flex-direction: row;
`

const MiddleText = styled(CaptionSmallPara)`
  color: ${TangleColors.tangleYellowPrimary};
`
const BottomText = styled(CaptionSmallPara)`
  color: ${TangleColors.black};
`

const EfficientHref = styled.a`
  text-decoration: underline;
  transition: 0.4s ease-in;
  text-underline-offset: 4px;
  color: ${TangleColors.tangleYellowPrimary};
  text-decoration-color: ${TangleColors.lighthover};
  -webkit-text-decoration-color: ${TangleColors.lighthover};
  :hover {
    color: ${TangleColors.lighthover};
    text-decoration-color: ${TangleColors.tangleYellowPrimary};
    -webkit-text-decoration-color: ${TangleColors.tangleYellowPrimary};
  }
`
const Bottom = styled.div`
  margin: 16px 0 2px 0;
  width: 100%;
`
const BottomButtom = styled.button`
  max-width: 440px;
  cursor: pointer;
  height: 40px;
  width: 100%;
  margin: 0 auto;
  border-radius: 12px;
  background-color: ${TangleColors.tangleYellowPrimary};
  color: ${TangleColors.black};
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const CloseIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`
const CloseIcon = styled(GrClose)`
  width: 18px;
  height: 18px;
  color: ${TangleColors.white};

  cursor: pointer;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
export default EfficiencyComparison
