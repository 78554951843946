import HomeContainer from "components/home/HomeContainer"

import { useEffect } from "react"

const Home = () => {
  useEffect(() => {
    document.title = "Welcome Aboard"
  }, [])

  return (
    <>
      <HomeContainer />
    </>
  )
}

export default Home
