import React, {
  FC,
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

import { useAccount, useConnect, useNetwork, useSigner } from "wagmi"
import { Chains, WRAPPED_ADDRESS, fetchInfo, sendError } from "@tangleswap/sdk"
import {
  useGetOriginAddress,
  useGetOriginTokens,
  useGetDestinationBalance,
  useGetWSCTokens,
  useGetPendingTxs,
  useWSCProvider,
} from "milkomeda-wsc-ui-test-beta"
import { metamaskConnector } from "config/milkomeda/wagmi"
import { WalletLoadingContext } from "./WalletContext"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateConnectWalletState } from "store/actions/SelectedWalletAction"

import { toast } from "sonner"
import {
  getBridgeExplorerUrl,
  getEvmExplorerUrl,
} from "utils/milkomeda/transaction"

interface MilkomedaProps {
  children?: ReactNode
}

export const WSCContext = createContext(null)

export const defaultChainId = Chains.SHIMMER
export const lastChainId =
  Number(localStorage.getItem("defaultChainId")) || defaultChainId
export const lastL1ChainId =
  localStorage.getItem("defaultL1ChainId") || Chains.L1_CARDANO

const MilkomedaContext: FC<MilkomedaProps> = ({ children }) => {
  const { chain } = useNetwork()
  const { wscProvider } = useWSCProvider()
  const { connect, connectors } = useConnect()
  const {
    connector: activeConnector,
    // isConnected: isWalletConnected,
    address: account,
  } = useAccount()
  const { data: signer } = useSigner()
  const { setWalletLoading } = useContext(WalletLoadingContext)

  // const pendingL1L2Txs = useGetPendingTxs() // null
  const [pendingWSCTxs, setPendingWSCTxs] = useState<any>(undefined)
  const [pendingHookWSCTxs, setPendingHookWSCTxs] = useState<any>(undefined)
  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )

  const [chainId, setChainId] = useState<any>(lastChainId)
  const [l1ChainId, setL1ChainId] = useState<number | string>(null)
  const [l1Account, setL1Account] = useState<string>(null)
  const [stargate, setStargate] = useState<any>(null)

  const isWSCConnected = activeConnector?.id?.includes("wsc") ?? false
  const walletName = localStorage.getItem("connectedWallet")
  const l1Wallets = ["Nami", "Eternl"] // "Yoroi", "NuFi"]

  const originAddress = useGetOriginAddress()
  const rawOriginTokens = useGetOriginTokens()
  const [originTokens, setOriginTokens] = useState<any>(undefined)
  // const [destinationTokens, setDestinationTokens] = useState<any>(undefined)
  // const [destinationBalanceADA, setDestinationBalanceADA] =
  //   useState<any>(undefined)
  const destinationTokens = useGetWSCTokens()
  const destinationBalanceADA = useGetDestinationBalance()
  const listOnChain = useAppSelector((state) => state.tokenList.tokenList)
  // const listBalances = useAppSelector(
  //   (state) => state.tokenBalance.tokenbalance
  // )
  const dispatch = useAppDispatch()

  // @dev: don't delete!!!!!
  // const findMaxBal = (addr, bal) => {
  //   let l2token = listOnChain.find(
  //     (tok) => tok.address.toLowerCase() === addr.toLowerCase()
  //   )
  //   if (!l2token && addr === "ADA")
  //     l2token = {
  //       address: "ADA",
  //       l1Address: "lovelace",
  //       decimals: 18,
  //       name: "Cardano",
  //       symbol: "ADA",
  //     }
  //   const l2Balance =
  //     addr === "ADA"
  //       ? bal
  //       : parseUnits(String(bal), l2token.decimals).toString()

  //   const l1token = originTokens.data.find((b) => {
  //     const l1Addr =
  //       l2token.l1Address === "ADA"
  //         ? WRAPPED_ADDRESS[chainId].toLowerCase()
  //         : l2token.l1Address
  //     return b.unit.startsWith(l1Addr)
  //   })
  //   let l1Balance = l1token?.quantity || 0
  //   if (addr === "ADA") l1Balance = String(Number(l1Balance) / 1e6)

  //   return addr === "ADA"
  //     ? l2Balance
  //     : {
  //         balance: l2Balance,
  //         maxBalance:
  //           Number(l2Balance) > Number(l1Balance) ? l2Balance : l1Balance,
  //         contractAddress: l2token.address,
  //         decimals: String(l2token.decimals),
  //         name: l2token.name,
  //         symbol: l2token.symbol,
  //         type: "ERC-20",
  //       }
  // }

  // const formatAsDestTokens = (data) => {
  //   const maxBalances =
  //     typeof data === "string"
  //       ? findMaxBal("ADA", data)
  //       : Object.entries(data).map(([addr, bal]) => findMaxBal(addr, bal))

  //   return {
  //     data: maxBalances,
  //     // tokens: maxBalances,
  //     // destinationBalance: maxBalances,
  //     fetchStatus: "idle",
  //     isError: false,
  //     isSuccess: true,
  //   }
  // }

  // useEffect(() => {
  //   if (
  //     !isWSCConnected ||
  //     !listBalances ||
  //     !listOnChain ||
  //     Object.values(listOnChain).length > Object.values(listBalances).length || // wait for all the listBalances to load
  //     !originTokens?.data
  //   )
  //     return

  //   const { ada: adaBalance, ...tokenBalances } = listBalances

  //   const destAda = formatAsDestTokens(adaBalance)
  //   const destTokens = formatAsDestTokens(tokenBalances)
  //   setDestinationBalanceADA(destAda)
  //   setDestinationTokens(destTokens)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isWSCConnected, listOnChain, originTokens?.data])

  // useEffect(() => {
  //   if (!listBalances || (!!destinationTokens && !!destinationBalanceADA))
  //     return

  //   const { ada: adaBalance, ...tokenBalances } = listBalances

  //   const destAda = formatAsDestTokens(adaBalance)
  //   const destTokens = formatAsDestTokens(tokenBalances)
  //   setDestinationBalanceADA(destAda)
  //   setDestinationTokens(destTokens)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [listBalances]) // we use a separate useEffect conditional for "listBalances" dependency to avoid infinite loop

  // useEffect(() => {
  //   if (
  //     // !isWSCConnected ||
  //     // !listBalances ||
  //     !destinationTokens ||
  //     !destinationBalanceADA
  //   )
  //     return

  //   const updatedTokenBalances = Object.fromEntries(
  //     Object.entries(listBalances).map(([key, _]) => {
  //       const token = destinationTokens.data.find(
  //         (tok) => tok.contractAddress.toLowerCase() === key.toLowerCase()
  //       )
  //       const maxBalance =
  //         !token && key === "ada" ? destinationBalanceADA : token.maxBalance
  //       return [key, maxBalance]
  //     })
  //   )
  //   console.log("sevupdatedTokenBalances", updatedTokenBalances, listBalances)
  //   dispatch(tokenBalanceSuccess(updatedTokenBalances))
  // }, [destinationTokens, destinationBalanceADA])

  const updateOriginTokens = useCallback(() => {
    if (!listOnChain || !rawOriginTokens) return

    const { data, ...rawWithoutData } = rawOriginTokens

    const updatedDataWithDecimals =
      data &&
      data.map((token) => {
        if (token.decimals !== 0) return token
        const realDecimals = listOnChain.find((tok) =>
          token.unit.startsWith(tok.l1Address)
        )?.l1Decimals
        return { ...token, decimals: realDecimals || 0 }
      })

    setOriginTokens({ ...rawWithoutData, data: updatedDataWithDecimals })
  }, [rawOriginTokens?.data, listOnChain])

  useEffect(() => {
    updateOriginTokens()
  }, [updateOriginTokens])

  const [refetchInterval1, setRefetchInterval1] = useState<any>(0)
  const [refetchInterval2, setRefetchInterval2] = useState<any>(0)
  useEffect(() => {
    const interval = setInterval(
      () => setRefetchInterval1((prev) => prev + 1),
      1000 * 20
    ) // in seconds
    const interval2 = setInterval(
      () => setRefetchInterval2((prev) => prev + 1),
      1000 * 30
    ) // in seconds
    return () => {
      clearInterval(interval)
      clearInterval(interval2)
    }
  }, [])

  useEffect(() => {
    if (!isWSCConnected || refetchInterval2 > 7) return
    rawOriginTokens.refetch()
    destinationTokens.refetch()
    destinationBalanceADA.refetch()
  }, [refetchInterval2])

  useEffect(() => {
    if (!isWSCConnected) return
    const loadWscProvider = async () => {
      try {
        const provider = await activeConnector?.getProvider()

        if (!provider) {
          throw new Error("No WSC Provider found")
        }

        const stargate = await provider.stargateObject()
        setStargate(stargate)
      } catch (e) {
        if (e instanceof Error) {
          console.error("Error while getting stargate: ", e)
        }
      }
    }
    loadWscProvider()
  }, [activeConnector])

  useEffect(() => {
    localStorage.setItem("tangleSwapTransactions", "false") // TODO: delete after Auto-Tx is fixed

    if (chainId) localStorage.setItem("defaultChainId", String(chainId))
    if (l1ChainId) localStorage.setItem("defaultL1ChainId", String(l1ChainId))
  }, [chainId, l1ChainId])

  useEffect(() => {
    const l1Acc = (isWSCConnected && originAddress?.originAddress) || account
    setL1Account(l1Acc)

    const chainID = !l1Acc
      ? lastChainId
      : isWSCConnected && l1Acc?.startsWith("addr_test")
      ? Chains.CARDANO_TEST
      : isWSCConnected && l1Acc?.startsWith("addr")
      ? Chains.CARDANO
      : chain?.id
    setChainId(chainID as number)

    const isL1WalletLoading =
      l1Wallets.includes(walletName) &&
      ![Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(chainID as any)
    let l1Chain
    if (isL1WalletLoading)
      l1Chain =
        chainID === Chains.CARDANO_TEST
          ? Chains.L1_CARDANO_TEST
          : Chains.L1_CARDANO
    else
      l1Chain =
        chainID === undefined
          ? lastChainId
          : isWSCConnected && chainID === Chains.CARDANO
          ? Chains.L1_CARDANO
          : isWSCConnected && chainID === Chains.CARDANO_TEST
          ? Chains.L1_CARDANO_TEST
          : chainID
    setL1ChainId(l1Chain)

    // setWalletLoading(false)
  }, [account, chain?.id, isWSCConnected, originAddress?.originAddress])

  useEffect(() => {
    if (!account || !l1Account) return
    if (!tangleProgress) {
      setPendingWSCTxs(null)
      return
    }

    const hash = tangleProgress?.txHash
    const isWrap = tangleProgress?.stepTitle === "Wrap"
    const isUnwrap = tangleProgress?.stepTitle === "Unwrap"
    const explorerUrl =
      isWrap || isUnwrap
        ? `${getBridgeExplorerUrl(chainId)}/search/tx?query=`
        : `${getEvmExplorerUrl(chainId)}/tx/`

    const timestamp = tangleProgress?.timestamp
    const now = Math.floor(Date.now() / 1000)
    const isOldEvmTx = now - timestamp > 15 // in seconds
    const isOldWscTx = now - timestamp > 60 * 10
    const isMinimumOldWscTx =
      isOldWscTx || (now - timestamp > 90 && refetchInterval1 > 2) // we use this to give the hook time to start catching the tx

    const isWscStep = isWrap || isUnwrap
    const isPendingL1L2Tx =
      pendingHookWSCTxs &&
      pendingHookWSCTxs?.some(
        (tx) => tx?.hash?.toLowerCase() === hash?.toLowerCase()
      )

    const localPendingTx =
      hash === null
        ? []
        : [
            {
              completed:
                tangleProgress?.completed ||
                // (!isWscStep && isOldEvmTx) ||
                (isWscStep &&
                  ((!isPendingL1L2Tx && isMinimumOldWscTx) ||
                    (!pendingHookWSCTxs && isOldWscTx))),
              data: tangleProgress?.data,
              hash,
              timestamp: tangleProgress?.timestamp,
              explorer: `${explorerUrl}${hash}`,
              type: tangleProgress?.stepTitle,
              actionType: tangleProgress?.actionType,
              actionSubType: tangleProgress?.actionSubType,
              subStep: tangleProgress?.subStep,
              destinationAddress: isUnwrap ? l1Account : account,
              isLastStep: tangleProgress?.isLastStep,
            },
          ]

    // if (!!tangleProgress?.isLastStep) dispatch(updateUserWSCProgress(null))

    setPendingWSCTxs(localPendingTx)
  }, [
    account,
    l1Account,
    tangleProgress,
    pendingHookWSCTxs,
    // refetchInterval1,
  ])

  const getCardanoTxMetadata = async (txHash: string, l1Chain: any) => {
    const storageKey = `adaTxMetadata${l1Chain}${txHash}`
    const storageValue = JSON.parse(sessionStorage.getItem(storageKey))
    if (storageValue) return storageValue

    const url = `https://${l1Chain}.blockfrost.io/api/v0/txs/${txHash}/metadata`
    const blockfrostApiKey =
      l1Chain === Chains.L1_CARDANO
        ? "mainnetaD8KnvUxv9pSLIVBQvdPKeVHtSjy5HcB"
        : "preprodliMqEQ9cvQgAFuV7b6dhA4lkjTX1eBLb"
    const headers = { project_id: blockfrostApiKey }
    const data = await fetchInfo(url, 60, headers).catch(sendError)

    if (data) sessionStorage.setItem(storageKey, JSON.stringify(data))
    return data
  }

  const isValidTx = async (tx, storageKey, completedHashes) => {
    if (
      !tx?.hash ||
      tx?.timestamp > 10000000000 || // timestamp in seconds, not milliseconds
      completedHashes[tx?.hash] === true
    )
      return null

    let txObject = null

    if (tx.type === "Wrap") {
      const txMetadata = await getCardanoTxMetadata(tx.hash, l1ChainId)
      const isValidL2Tx = txMetadata?.some(
        (md) => md?.json_metadata === "devnet.cardano-evm.c1"
      )
      const isValidL2Address = txMetadata?.some(
        (md) => md?.json_metadata?.toLowerCase() === account?.toLowerCase()
      )
      txObject =
        !txMetadata?.length || !isValidL2Tx || !isValidL2Address
          ? null
          : {
              hash: tx.hash,
              timestamp: tx.timestamp,
              explorer: `https://preprod.cardanoscan.io/transaction/${tx.hash}`,
              type: tx.type,
              destinationAddress: account,
              completed: false,
            }
    } else if (tx.type === "Unwrap") {
      txObject = !tx.destinationAddress?.startsWith("addr")
        ? null
        : {
            hash: tx.hash,
            timestamp: tx.timestamp,
            explorer: `https://explorer-devnet-cardano-evm.c1.milkomeda.com/tx/${tx.hash}`,
            type: tx.type,
            destinationAddress: l1Account,
            completed: false,
          }
    }

    completedHashes[tx?.hash] = !txObject
    localStorage.setItem(storageKey, JSON.stringify(completedHashes))

    return txObject
  }

  useEffect(() => {
    if (!isWSCConnected || refetchInterval2 > 20) return
    const loadWscProvider = async () => {
      try {
        const provider = await activeConnector?.getProvider()
        if (!provider) throw new Error("No wsc provider found")

        const pendingTxs = (await provider.getPendingTransactions()) ?? []
        const storageKey = `completedTxHashes${l1ChainId}` //${l1Account}`
        const hashes = JSON.parse(localStorage.getItem(storageKey)) ?? {}

        const validTxs = (
          await Promise.all(
            pendingTxs.map((tx) => isValidTx(tx, storageKey, hashes))
          )
        ).filter(Boolean)

        // const isNewData = !validTxs.every((v) =>
        //   pendingHookWSCTxs.map((p) => p?.hash)?.includes(v)
        // )
        // if (isNewData) setPendingHookWSCTxs(validTxs)
        setPendingHookWSCTxs(validTxs)
      } catch (e) {
        console.error(e)
      }
    }
    loadWscProvider()
  }, [
    // activeConnector,
    // isWSCConnected,
    // wscProvider,
    // refetchInterval1,
    refetchInterval2,
  ])

  // @dev: don't delete!!
  // useEffect(() => {
  // if (
  //   !account ||
  //   !l1Account ||
  //   !l1ChainId ||
  //   !pendingL1L2Txs ||
  //   pendingL1L2Txs?.fetchStatus === "fetching"
  // )
  //   return

  // const duplicates = new Map()
  // const pendingTxData = !pendingL1L2Txs?.data
  //   ? []
  //   : pendingL1L2Txs.data.filter(
  //       (tx) =>
  //         tx &&
  //         !duplicates.has(tx?.hash?.toLowerCase()) &&
  //         duplicates.set(tx?.hash?.toLowerCase(), true)
  //     )

  // Promise.all(
  //   pendingTxData.map(async (tx) => {
  //     const txData = tx
  //     if (
  //       // (pendingL1L2Txs?.error as any)?.message ||
  //       // !txData.destinationAddress ||
  //       !txData.hash
  //     )
  //       return null

  //     if (txData.type === "Wrap") {
  //       const txMetadata = await getCardanoTxMetadata(txData.hash, l1ChainId)
  //       const isValidL2Tx = txMetadata?.some(
  //         (md) => md?.json_metadata === "devnet.cardano-evm.c1"
  //       )
  //       const isValidL2Address = txMetadata?.some(
  //         (md) => md?.json_metadata?.toLowerCase() === account?.toLowerCase()
  //       )
  //       return !txMetadata?.length || !isValidL2Tx || !isValidL2Address
  //         ? null
  //         : {
  //             hash: txData.hash,
  //             timestamp: txData.timestamp,
  //             explorer: txData.explorer,
  //             type: txData.type,
  //             destinationAddress: l1Account,
  //             completed: false,
  //           }
  //     } else if (txData.type === "Unwrap") {
  //       if (!txData.destinationAddress?.startsWith("addr")) return null
  //       return { ...txData, completed: false }
  //     }
  //   })
  // ).then((pendingTxs) => {
  // let validTxs: any = pendingTxs?.filter(Boolean)

  //   if (validTxs?.length === 0 && !!tangleProgress) {
  //     const recentTime = 1000 * 20 // in seconds
  //     const isRecent =
  //       Date.now() - tangleProgress?.timestamp * 1000 < recentTime

  //     const isWrap = tangleProgress?.stepTitle === "Wrap"
  //     const isUnwrap = tangleProgress?.stepTitle === "Unwrap"
  //     const explorerUrl = isWrap
  //       ? "https://preprod.cardanoscan.io/transaction/"
  //       : "https://explorer-devnet-cardano-evm.c1.milkomeda.com/tx/"

  //     validTxs = !tangleProgress?.txHash
  //       ? []
  //       : [
  //           {
  //             completed:
  //               // (isWrap && !isRecent) || isUnwrap
  //               //   ? true
  //               //   : tangleProgress?.completed,
  //               tangleProgress?.completed,
  //             hash: tangleProgress?.txHash,
  //             timestamp: tangleProgress?.timestamp,
  //             explorer: `${explorerUrl}${tangleProgress?.txHash}`,
  //             type: tangleProgress?.stepTitle,
  //             actionSubType: tangleProgress?.actionSubType,
  //             destinationAddress: isUnwrap ? l1Account : account,
  //           },
  //         ]

  //     if (!!tangleProgress?.isLastStep) dispatch(updateUserWSCProgress(null))
  //   }
  //   console.log("sevPendingWSCTxs", pendingL1L2Txs, validTxs, tangleProgress)
  //   setPendingWSCTxs(validTxs)
  // })

  // }, [account, l1Account, l1ChainId, pendingL1L2Txs?.fetchStatus])

  const walletStateRef = useRef(isWSCConnected)
  const l1ChainRef = useRef(l1ChainId)
  useEffect(() => {
    walletStateRef.current = isWSCConnected
    l1ChainRef.current = l1ChainId
  }, [isWSCConnected, l1ChainId])

  const hasPhantom = (window as any).solana && (window as any).solana.isPhantom
  const hasCore =
    (window as any).avalanche && (window as any).avalanche.info.name === "core"
  const openExternalLink = (url: string, target = "_blank") =>
    window.open(url, target)

  useEffect(() => {
    if (!walletName) return

    if (walletName === "Metamask") connectToMetamask()
    else connectToWSC(walletName as any)

    setTimeout(() => {
      const l1Chains = [Chains.L1_CARDANO, Chains.L1_CARDANO_TEST]
      const chain = l1ChainRef.current as any
      const isEvm = !l1Chains.includes(chain)
      if (Boolean(walletStateRef.current) || isEvm) return

      const warningString =
        "Typically caused by interference with other wallet extensions in your browser."
      const walletConflictString = !(hasPhantom || hasCore)
        ? ""
        : ` In your case, potentially conflicting wallets are${
            hasPhantom ? " | Phantom" : hasCore ? " | Core Wallet" : " none"
          }.`
      const fixString =
        " To learn how to fix your issue, click the +INFO button."

      dispatch(updateConnectWalletState(true))
      console.warn(`Wallet connect timed out. ${warningString}`)

      toast.error("Wallet Connection Timed Out.", {
        description: `${warningString}${walletConflictString}${fixString}`,
        action: {
          label: "+INFO",
          onClick: () =>
            openExternalLink(
              "https://docs.tangleswap.exchange/guides/getting-started/configure-wallet#troubleshooting"
            ),
        },
        duration: 20000,
      })
    }, 1000 * 15) // in seconds
  }, [])

  const connectToMetamask = async () => {
    try {
      await connect({
        connector: metamaskConnector,
      })
      localStorage.setItem("connectedWallet", "Metamask")
      setWalletLoading(false)
    } catch (error) {
      console.error("Error connecting to Metamask:", error)
    }
  }
  const connectToWSC = async (wallet: (typeof l1Wallets)[number]) => {
    dispatch(updateConnectWalletState(true))
    const name = `${wallet.toLowerCase()}-wsc`
    const wscConnector = connectors.find((connector) => connector.id === name)

    if (!wscConnector) return

    try {
      console.log("Connecting to wallet:", wallet)

      await connect({
        connector: wscConnector,
      })
      localStorage.setItem("connectedWallet", wallet)
      dispatch(updateConnectWalletState(false))
    } catch (error) {
      console.error(`Error connecting to ${wallet} WSC:`, error)
      dispatch(updateConnectWalletState(false))
    }
  }

  const value = {
    account,
    l1Account,
    chainId,
    l1ChainId,
    walletName,
    originTokens,
    destinationTokens,
    destinationBalanceADA,
    pendingWSCTxs,
    pendingHookWSCTxs,
    provider: signer?.provider,
    activeConnector,
    isWSCConnected,
    stargate,
    connectToMetamask,
    connectToWSC,
    refetchL1Tokens: rawOriginTokens.refetch,
    refetchL2Tokens: destinationTokens.refetch,
    refetchL2ADA: destinationBalanceADA.refetch,
  }

  return <WSCContext.Provider value={value}>{children}</WSCContext.Provider>
}

export const useWSCContext = () => {
  const context = useContext(WSCContext)
  if (!context) throw new Error("Hook must be used within a Provider")
  return context
}

export default memo(MilkomedaContext)
