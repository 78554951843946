import {
  Chains,
  chainIdsToNames,
  defaultProviderURL,
  explorerURL,
} from "@tangleswap/sdk"
import { BigNumber, ethers } from "ethers"

export enum GenesisApy {
  PALM = 20,
  FLOATING = 30,
  TRANQUILLITY = 60,
  ASCENSION = 80,
  NIGHT = 100,
}

export const DAILY_RATE = {
  [GenesisApy.PALM]: 49,
  [GenesisApy.FLOATING]: 92,
  [GenesisApy.TRANQUILLITY]: 128,
  [GenesisApy.ASCENSION]: 161,
  [GenesisApy.NIGHT]: 190,
}

export const NETWORK_CONFIG = {
  [Chains.CARDANO]: {
    network: chainIdsToNames[Chains.CARDANO],
    rpc: defaultProviderURL[Chains.CARDANO],
    chainId: Chains.CARDANO,
    symbol: "ADA",
    explorer: explorerURL[Chains.CARDANO],
  },
  [Chains.CARDANO_TEST]: {
    network: chainIdsToNames[Chains.CARDANO_TEST],
    rpc: defaultProviderURL[Chains.CARDANO_TEST],
    chainId: Chains.CARDANO_TEST,
    symbol: "ADA",
    explorer: explorerURL[Chains.CARDANO_TEST],
  },
  [Chains.SHIMMER]: {
    network: chainIdsToNames[Chains.SHIMMER],
    // rpc: defaultProviderURL[Chains.SHIMMER],
    rpc: "https://json-rpc.evm.shimmer.network",
    chainId: Chains.SHIMMER,
    symbol: "SMR",
    explorer: explorerURL[Chains.SHIMMER],
  },
  [Chains.SHIMMER_TEST]: {
    network: chainIdsToNames[Chains.SHIMMER_TEST],
    rpc: defaultProviderURL[Chains.SHIMMER_TEST],
    chainId: Chains.SHIMMER_TEST,
    symbol: "SMR",
    explorer: explorerURL[Chains.SHIMMER_TEST],
  },
  [Chains.SEPOLIA_TEST]: {
    network: chainIdsToNames[Chains.SEPOLIA_TEST],
    rpc: defaultProviderURL[Chains.SEPOLIA_TEST],
    chainId: Chains.SEPOLIA_TEST,
    symbol: "ETH",
    explorer: explorerURL[Chains.SEPOLIA_TEST],
  },
}

export const FAST_INTERVAL = 10000
export const SLOW_INTERVAL = 60000

export const ZERO = BigNumber.from("0")

export const LOCK_PERIOD_DAYS = 7 // for 'Unstake' button in the NFT Staking Details UI

export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// export const contractAddress = "0x32f20190540CCc55E137928c11d70d24daf52995"
