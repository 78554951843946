import HoverBox from "components/utils/HoverBox"
import Tooltip from "components/utils/Tooltip"
import { FC, useState } from "react"
import { AiOutlineInfoCircle } from "react-icons/ai"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { toApproxMax, toSignificantDigits } from "utils/toSignificant"

interface AprDisplayProps {
  baseAPR?: number
  maxAPR?: number
  isEnded?: boolean
}

const TopAPRDisplay: FC<AprDisplayProps> = (props) => {
  const { baseAPR, maxAPR, isEnded } = props

  const aprBase = toSignificantDigits(baseAPR, 2, true, 2)
  const aprMax = toApproxMax(maxAPR, 2, true, false, null, 1e6)

  return (
    <Body>
      <TextTitle>APR</TextTitle>
      <AprTextContainer>
        <AprText>
          {isEnded
            ? "ENDED"
            : !!maxAPR
            ? // ? `${aprBase}%`
              `${aprBase}% - ${aprMax}%`
            : "0 %"}
        </AprText>
        {!isEnded ? (
          <InfoButton>
            <Tooltip
              direction="bottom"
              // text="Estimated from the rewards received in the past 30 days respectively by (1) full-range LP positions, and (2) LP positions that match *exactly* the reward range of this farm."
              text="Estimated from the rewards received in the past 30 days by LP positions that match *exactly* the reward range of this farm."
            >
              <InfoCircle />
            </Tooltip>
          </InfoButton>
        ) : null}
      </AprTextContainer>
    </Body>
  )
}

const Body = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`

const TextTitle = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  margin: 0 0 2px 0;
`

const AprText = styled(Caption)`
  color: ${TangleColors.white};
`

const AprTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const InfoCircle = styled(AiOutlineInfoCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.white};
  cursor: pointer;
`

const InfoButton = styled.div`
  color: ${TangleColors.white};
  cursor: pointer;
  position: relative;
`

export default TopAPRDisplay
