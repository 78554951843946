import { AIRDROPS_DATA } from "@tangleswap/sdk"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import AirdropModal, {
  useAirdropModalControl,
} from "components/milkomeda/airdrop/AirdropModal"
import { useWSCContext } from "context/MilkomedaContext"
import { ethers } from "ethers"
import { IWrapData } from "interfaces/wscSwap.interface"
import React, { FC, useEffect, useRef, useState } from "react"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { useAppDispatch, useAppSelector } from "store/hooks"
import styled from "styled-components"
import { AidropclaimButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import {
  Caption,
  Header4,
  Header5,
  HeaderSmall,
  Subtitle,
} from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"
import { useTangleship } from "utils/useTangleship"
import { toast as SonnerToast } from "sonner"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"

interface CardProps {}

const InfoCard: FC<CardProps> = (props) => {
  const { tangleship } = useTangleship()
  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()
  const { isVisible: isWSCModalVisible, toggle: toggleWSCModal } =
    useAirdropModalControl()

  const airdropsOnChain = AIRDROPS_DATA[chainId]

  const [isLoading, setIsLoading] = useState(false)
  const [airdropFetching, setAirdropFetching] = useState(false)
  const [currentAirdrop, setCurrentAirdrop] = useState(
    airdropsOnChain ? 0 : null
  )
  const [airdropData, setAirdropData] = useState(airdropsOnChain)
  const [wrapData, setWrapData] = useState<IWrapData>(undefined)

  const dispatch = useAppDispatch()
  const wscModalRef = useRef<any>(null)

  const nextAirdrop = () => {
    setCurrentAirdrop((prevIndex) => (prevIndex + 1) % airdropData.length)
  }

  const previousAirdrop = () => {
    setCurrentAirdrop((prevIndex) => {
      if (prevIndex === 0) {
        return airdropData.length - 1
      } else {
        return prevIndex - 1
      }
    })
  }

  useEffect(() => {
    const fetchAirdropData = async () => {
      setAirdropFetching(true)
      if (airdropsOnChain && tangleship && account) {
        const airdropData = []

        for (const airdrop of airdropsOnChain) {
          const res = await tangleship.checkAirdropUserInfo(
            airdrop?.id,
            account
          )
          const token = await fetchTangleCurrency(
            airdrop?.params.tokenToDistribute,
            l1ChainId,
            tangleship
          )

          airdropData.push({
            id: airdrop?.id,
            name: airdrop?.name,
            token,
            totalAmount: airdrop?.tokenTotalAirdropAmount,
            amount: res?.userData?.amount,
            status: res?.status,
            claimed: res?.userAlreadyClaimed,
          })

          console.log("checking airdrop", { res, airdrop, token })
        }

        setAirdropData(airdropData)
        setCurrentAirdrop(
          airdropData.length > 0 ? airdropData.length - 1 : null
        )
        setAirdropFetching(false)
      }
    }

    fetchAirdropData()
  }, [airdropsOnChain, tangleship, account, chainId])

  useEffect(() => {
    console.log("airdrops", airdropData)
  }, [airdropData])

  const prepClaimAirdropFn = () => {
    const fn = tangleship?.claimAirdropRewards
    const fnParams = [currentAirdrop, account] as const

    const fnFeedback = (params, resOrReceipt) => {
      resOrReceipt?.tx
        ?.wait()
        .then(() => {
          setAirdropData((prev) => {
            return prev.map((airdrop) =>
              airdrop.id === currentAirdrop
                ? { ...airdrop, status: "Already Claimed", claimed: true }
                : airdrop
            )
          })
          setIsLoading(false)
        })
        .catch((err) => {
          console.error("Error while claiming airdrop", err)
          setIsLoading(false)
        })
    }

    return { fn, fnParams, fnFeedback }
  }

  const claimAirdrop = () => {
    if (tangleship && account) {
      setIsLoading(true)

      const { fn, fnParams, fnFeedback } = prepClaimAirdropFn()

      fn?.(...fnParams)?.then((res) => {
        fnFeedback([], res)
      })
    }
  }

  const fireWSCModal = () => {
    if (!isWSCConnected) return

    const { fn, fnParams, fnFeedback } = prepClaimAirdropFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      setWrapData({
        tokenOut: {
          ...airdropData[currentAirdrop]?.token,
          amount: ethers.utils.formatUnits(
            airdropData[currentAirdrop]?.amount,
            airdropData[currentAirdrop]?.token?.decimals ?? 18
          ),
          unit: getTokenUnit(airdropData[currentAirdrop]?.token?.address),
        },
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: null,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleWSCModal()
      }, 0)
    })
  }

  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    SonnerToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWSCModal()
  }

  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleWSCModal()
    }
  }

  return (
    <AccountWrapper>
      {isWSCConnected && (
        <AirdropModal
          wrapData={wrapData}
          isVisible={isWSCModalVisible}
          toggleModal={toggleWSCModal}
          wscModalRef={wscModalRef}
          cancelTransaction={cancelTransaction}
          closeWSCContinue={closeWSCContinue}
        />
      )}
      <AccountLiner />
      <AcccountOverlay />
      <DetailsWrapper>
        <DetailsNav>
          {airdropData?.length > 1 && (
            <DetailsNavButton onClick={nextAirdrop}>
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 1L1 5L5 9" stroke="white" strokeWidth="1.5" />
              </svg>
            </DetailsNavButton>
          )}
          <DetailsTitle>
            <SectionTitle>Your Allocation</SectionTitle>
            <AirdropName>
              {!airdropFetching && airdropData[currentAirdrop]?.name}
            </AirdropName>
          </DetailsTitle>
          {airdropData?.length > 1 && (
            <DetailsNavButton onClick={previousAirdrop}>
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L5 5L1 9" stroke="white" strokeWidth="1.5" />
              </svg>
            </DetailsNavButton>
          )}
        </DetailsNav>
        <AccountStateBottom>
          <AccountStatRow>
            <StatValue>
              {airdropData[currentAirdrop]?.totalAmount
                ? `${toSignificantDigits(
                    airdropData[currentAirdrop].totalAmount || 0,
                    4,
                    true,
                    18
                  )} ${airdropData[currentAirdrop]?.token?.symbol}`
                : `—`}
            </StatValue>

            <StatTitle>Total airdrop amount</StatTitle>
          </AccountStatRow>
          <AccountStatRow>
            <StatValue>
              {airdropData[currentAirdrop]?.amount ||
              (airdropData[currentAirdrop]?.status &&
                airdropData[currentAirdrop]?.status !==
                  "Airdrop Not Started Yet!")
                ? `${toSignificantDigits(
                    ethers.utils.formatUnits(
                      airdropData[currentAirdrop]?.amount || 0,
                      airdropData[currentAirdrop]?.token?.decimals ?? 18
                    ),
                    4,
                    true,
                    18
                  )} ${airdropData[currentAirdrop]?.token?.symbol}`
                : `—`}
            </StatValue>
            <StatTitle>Your earned rewards</StatTitle>
          </AccountStatRow>
        </AccountStateBottom>

        <NoTransactionDone>
          <NoTransactionButtonCover
            disabled={
              !airdropData ||
              airdropData?.length === 0 ||
              isLoading ||
              airdropData[currentAirdrop]?.status !== "Pending Claim"
            }
            onClick={isWSCConnected ? fireWSCModal : claimAirdrop}
          >
            {isLoading
              ? "Transaction in Progress..."
              : !airdropData || airdropData?.length === 0
              ? "No Airdrop Available"
              : airdropData[currentAirdrop]?.status === "Already Claimed"
              ? "Already Claimed"
              : airdropData[currentAirdrop]?.status === "Not In Airdrop"
              ? "Not In Airdrop"
              : airdropData[currentAirdrop]?.status ===
                "Airdrop Not Started Yet!"
              ? "Airdrop Not Started Yet!"
              : "Claim Your Tokens"}
          </NoTransactionButtonCover>
        </NoTransactionDone>
      </DetailsWrapper>
    </AccountWrapper>
  )
}

const AccountWrapper = styled.div`
  border: 1px solid ${TangleColors.cta};
  background-color: ${TangleColors.modalBG};
  background-image: url("https://d3m3d54t409w7t.cloudfront.net/homepage/the-VOID-pilot.webp");
  background-size: 420px;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 280px;
  max-width: 800px;
  position: relative;
  border-radius: 20px;
  padding: 12px 12px 32px 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transition: 0.4s ease-in;
  :hover {
    background-position: left center;
    background-size: 750px;
  }
`

const AccountLiner = styled.div`
  margin: 0 auto;
  width: 100%;
  position: absolute;
  border-radius: 20px;
  top: 2%;
  left: 2%;
  transform: translate(0%, 0%);
  width: 96%;
  height: 94%;
  z-index: 3;
  opacity: 0;
  border: 1px solid ${TangleColors.white};
  transition: 0.4s ease-in;
  ${AccountWrapper}:hover & {
    opacity: 1;
  }
`

const DetailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 12;
`

const AcccountOverlay = styled.div`
  position: absolute;
  border-radius: 20px;
  content: "";
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.75);
`

const DetailsNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;
`

const DetailsNavButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 9999px;
  background-color: ${TangleColors.cta};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  cursor: pointer;
  transition: 0.4s ease-in;
  user-select: none;
  :hover {
    background-color: ${TangleColors.lighthover};
  }
`

const DetailsTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SectionTitle = styled(Header4)`
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
`

const AirdropName = styled(HeaderSmall)`
  color: ${TangleColors.lighthover};
  transition: 0.4s ease-in;
  font-size: 18px;
`

const AccountStateBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -13.5px 0 0 0;
`
const AccountStatRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StatValue = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 8px 0;
  transition: 0.4s ease-in;
`
const StatTitle = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0;
  transition: 0.4s ease-in;
`
const NoTransactionDone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 520px;
  margin: 0 auto;
`
const NoTransactionButtonCover = styled(AidropclaimButton)``

export default InfoCard
