import React, { FC, useEffect, useMemo, useState } from "react"
import BottomPositionInfoRow from "./BottomPositionInfoRow"
import styled from "styled-components"
import { motion } from "framer-motion"
import { TangleColors } from "styles/ColorStyles"

import NoTLPPosition from "../components/NoTLPPosition"
import { PoolsProps } from "components/swap/types"
import { NATIVE_SYMBOL, isWrapped, wrapAddr } from "@tangleswap/sdk"
import { useWSCContext } from "context/MilkomedaContext"

interface BottomCardsProps {
  positions?: PoolsProps[]
  token1DataAddress?: string
  token0DataAddress?: string
  feeTier?: number
  formattedPrice?: number
  farmAddress?: string
  isEnded?: boolean
  isFarmApproved?: any
  isLPstakedInFarm?: any
  sqrtPriceX96?: number
}

const BottomPositionInfo: FC<BottomCardsProps> = (props) => {
  const {
    positions,
    token1DataAddress,
    token0DataAddress,
    feeTier,
    formattedPrice,
    farmAddress,
    isEnded,
    isFarmApproved,
    sqrtPriceX96,
  } = props

  const { chainId, l1ChainId } = useWSCContext()
  const [filteredPositions, setFilteredPositions] = useState<any>(undefined)

  const getNativeAddr = (addr: string) => {
    return isWrapped(addr.trim(), chainId) ? NATIVE_SYMBOL[chainId] : addr
  }

  const getWrappedAddr = (addr: string) => {
    return wrapAddr(addr.trim(), chainId, l1ChainId).tokenAddress.toLowerCase()
  }

  const positionsFiltered = useMemo(() => {
    const wrapped0 = getWrappedAddr(token0DataAddress)
    const wrapped1 = getWrappedAddr(token1DataAddress)
    const feeTierFloat = parseFloat(String(feeTier))

    return positions?.filter((res: PoolsProps) => {
      const resToken0 = getWrappedAddr(res.token0Address)
      const resToken1 = getWrappedAddr(res.token1Address)
      const resFee = parseFloat(String(res.feeTier))

      const isLPfromFarm =
        ((resToken0 === wrapped0 && resToken1 === wrapped1) ||
          (resToken0 === wrapped1 && resToken1 === wrapped0)) &&
        resFee === feeTierFloat

      return isLPfromFarm
    })
  }, [feeTier, positions, token0DataAddress, token1DataAddress])

  useEffect(() => {
    setFilteredPositions(positionsFiltered)
  }, [positionsFiltered])

  return (
    <BottomBodyCoverRow>
      <>
        {filteredPositions?.length > 0 ? (
          <>
            {filteredPositions &&
              filteredPositions?.map((token: PoolsProps, index: number) => {
                const isLPstakedInFarm =
                  token?.owner?.toLowerCase() !==
                  token?.userWalletAddress?.toLowerCase()
                return (
                  <BottomPositionInfoRow
                    key={index}
                    status={isLPstakedInFarm}
                    isEnded={isEnded}
                    isFarmApproved={isFarmApproved}
                    token0={getNativeAddr(token?.token0Address)}
                    token1={getNativeAddr(token?.token1Address)}
                    nftId={Number(token.id)}
                    fee={Number(token.feeTier)}
                    farmAddress={farmAddress}
                    liquidity={token.liquidity}
                    formattedPrice={formattedPrice}
                    sqrtPriceX96={sqrtPriceX96}
                    tickUpper={token.tickUpper}
                    tickLower={token.tickLower}
                  />
                )
              })}
          </>
        ) : (
          <>
            <NoTLPPosition />
          </>
        )}
      </>
    </BottomBodyCoverRow>
  )
}

const BottomBodyCoverRow = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  grid-gap: 6px;
  border-radius: 24px;
  position: relative;

  padding: 16px;
  margin: 6px 0;
  color: ${TangleColors.white};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;
`

export default BottomPositionInfo
