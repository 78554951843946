import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

const DappsDivider = () => {
  return <DappsDividerWrap />
}

const DappsDividerWrap = styled.hr`
  width: 100%;
  opacity: 0.15;
  margin: 10px 0;
  border-top: 0.8px solid ${TangleColors.grayDark};
`

export default DappsDivider
