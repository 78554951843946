import React, { useEffect, useMemo, useState } from "react"
import FarmSearch from "components/farm/FarmSearch"
import styled from "styled-components"
import FarmBody from "./FarmBody"
import { useForm } from "react-hook-form"
import { dateFromUnixTimestamp } from "./hooks/dateFromUnixTimestamp"
import { FarmDataProps, UserPoolsProps } from "./types"

import useFarmTangleArray from "utils/useFarmTangleArray"

import { PoolsProps } from "components/swap/types"

interface FarmValues {
  search: string
  farmSorting?: any
  farmState?: any
}

const FarmsContainer = () => {
  const [TangleswapUserPools, setTangleswapUserPools] = useState<any>()

  const [isModeToggled, setIsModeToggled] = useState<boolean>(false)
  const { register, setValue, watch } = useForm<FarmValues>({
    defaultValues: {
      farmState: "live",
    },
  })
  const {
    TangleswapFarmsData,
    allFarmsLoading,
    TangleswapUserPools: UnfilteredPools,
  } = useFarmTangleArray()

  useEffect(() => {
    const filteredPools = UnfilteredPools?.filter((position) => {
      const minLiquidityToFarm = 1e6 // Source: https://github.com/TangleSwap/tangleswap-farming/blob/main/contracts/fixRange/FixRange.sol#L177
      return position.liquidity && position.liquidity > minLiquidityToFarm
    })
    setTangleswapUserPools(filteredPools)
  }, [UnfilteredPools])

  const tangleswapMergedFarmsData = [...(TangleswapFarmsData || [])]

  // Memoizing the search filter
  const tangleChosenFarms = tangleswapMergedFarmsData?.filter(
    (farms: FarmDataProps) => {
      const searchStr = String(watch("search")).toLowerCase()
      return (
        String(farms?.token0Address)?.toLowerCase().includes(searchStr) ||
        String(farms?.token1Address)?.toLowerCase().includes(searchStr)
      )
    }
  )

  // Memoizing the sort operation
  const sortType = watch("farmSorting")
  const farmSortData = useMemo(() => {
    if (sortType === "apr") {
      return [...tangleChosenFarms].sort(
        (a: FarmDataProps, b: FarmDataProps) =>
          Number(b?.maxAPR) - Number(a?.maxAPR)
      )
    } else if (sortType === "latest") {
      return [...tangleChosenFarms].sort(
        (a: FarmDataProps, b: FarmDataProps) =>
          new Date(b?.startBlock).getTime() - new Date(a?.startBlock).getTime()
      )
    } else if (sortType === "yield") {
      return [...tangleChosenFarms].sort(
        (a: FarmDataProps, b: FarmDataProps) => Number(b?.TVL) - Number(a?.TVL)
      )
    } else {
      return tangleChosenFarms
    }
  }, [tangleChosenFarms, watch("farmSorting")])

  const tangleswapFarmsData =
    watch("farmState") === "live"
      ? farmSortData?.filter((data: FarmDataProps) => {
          const farmEnded =
            dateFromUnixTimestamp(Number(data?.endBlock)) > 0 ||
            new Date(data?.endBlock * 1000) > new Date()
          return farmEnded
        })
      : farmSortData?.filter((data: FarmDataProps) => {
          const farmEnded =
            dateFromUnixTimestamp(Number(data?.endBlock)) <= 0 ||
            new Date(data?.endBlock * 1000) < new Date()
          return farmEnded
        })

  const filteredFarmData = !isModeToggled
    ? tangleswapFarmsData
    : tangleswapFarmsData?.filter((farmRes: FarmDataProps) => {
        return TangleswapUserPools?.some((positionRes: PoolsProps) => {
          const resToken0 = String(positionRes.token0Address)
            .trim()
            .toLowerCase()
          const resToken1 = String(positionRes.token1Address)
            .trim()
            .toLowerCase()
          const resFee = parseFloat(String(positionRes.feeTier))
          const token0Lower = String(farmRes?.token0Address)
            .trim()
            .toLowerCase()
          const token1Lower = String(farmRes.token1Address).trim().toLowerCase()
          const feeTierFloat = parseFloat(String(farmRes.feeTier))
          return (
            (resToken0 === token0Lower &&
              resToken1 === token1Lower &&
              resFee === feeTierFloat) ||
            (resToken0 === token1Lower &&
              resToken1 === token0Lower &&
              resFee === feeTierFloat)
          )
        })
      })

  const [openPositions, closedPositions] = TangleswapUserPools?.sort(
    (a: PoolsProps, b: PoolsProps) => (Number(a.id) > Number(b.id) ? 1 : -1)
  )?.reduce(
    (acc: any, p: PoolsProps) => {
      const isLPstakedInFarm =
        p?.owner?.toLowerCase() !== p?.userWalletAddress?.toLowerCase()
      acc[Boolean(isLPstakedInFarm) === true ? 1 : 0].push(p)
      return acc
    },
    [[], []]
  ) ?? [[], []]

  const filteredPositions = [...closedPositions, ...openPositions]

  const toggleOffExpertMode = (e?: any) => {
    e.preventDefault()
    setIsModeToggled(false)
  }

  const toggleOnExpertMode = (e?: any) => {
    e.preventDefault()
    setIsModeToggled(true)
  }

  return (
    <>
      <Body>
        {/* <FarmHero /> */}

        <BodySection>
          <FarmSearch
            searchRegister={register}
            tangleswapFarmsData={tangleswapFarmsData}
            farmState={watch("farmState")}
            setValue={setValue}
            isModeToggled={isModeToggled}
            toggleOnExpertMode={toggleOnExpertMode}
            toggleOffExpertMode={toggleOffExpertMode}
          />
          <FarmBody
            filteredFarmData={filteredFarmData}
            farmLoading={!filteredFarmData || allFarmsLoading}
            allUserPools={filteredPositions}
          />
        </BodySection>
      </Body>
    </>
  )
}

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* max-width: 1232px; */
  width: 100%;
  margin: -48px auto 0 auto;
  background: transparent;
`

const BodySection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1232px;
  width: 100%;
  margin: 0 auto;
  background: transparent;
`

export default React.memo(FarmsContainer)
