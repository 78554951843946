import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionTiny } from "styles/TextStyles"

interface ReverseTokenOrderProps {
  currencyA?: any
  currencyB?: any
  handleRateToggle?: (value?: string) => void
  isActive?: boolean
  margin?: boolean
  selectedTokenSymbol?: string
}
interface ReverseProps {
  isActive?: boolean
  margin?: boolean
}
const ReverseTokenOrder: FC<ReverseTokenOrderProps> = (props) => {
  const {
    currencyA,
    currencyB,
    handleRateToggle,
    selectedTokenSymbol,
    isActive,
    margin,
  } = props
  return (
    <>
      {currencyA && currencyB ? (
        <>
          <InfoToggleBody margin={margin}>
            {" "}
            <InfoToggle>
              <CurrencyToggle
                onClick={() => handleRateToggle?.(currencyA)}
                isActive={
                  String(selectedTokenSymbol).toLowerCase() ===
                  String(currencyA).toLowerCase()
                }
              >
                <CurrencyText>{currencyA}</CurrencyText>
              </CurrencyToggle>
              <CurrencyToggle
                onClick={() => handleRateToggle?.(currencyB)}
                isActive={
                  String(selectedTokenSymbol).toLowerCase() ===
                  String(currencyB).toLowerCase()
                }
              >
                <CurrencyText>{currencyB}</CurrencyText>
              </CurrencyToggle>
            </InfoToggle>
          </InfoToggleBody>
        </>
      ) : null}
    </>
  )
}

const InfoToggleBody = styled.button<ReverseProps>`
  background: ${TangleColors.tangleBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.white};
  border: none;
  border-radius: 8px;
  margin: ${(props) => props.margin && `0 16px 0 0`};
  padding: 3px 4px;
  margin: 0 12px 0 0;
`
const InfoToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const CurrencyToggle = styled.button<ReverseProps>`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  border: none;
  border-radius: 6px;
  background: ${(props) =>
    props.isActive ? `${TangleColors.cta}` : "transparent"};
  margin: 2px;
  cursor: pointer;
`
const CurrencyText = styled(CaptionTiny)<ReverseProps>`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export default ReverseTokenOrder
