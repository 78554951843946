import { useEffect } from "react"
import auctionHeroBg from "assets/hero-yellow.png"
import styled from "styled-components"
import SiteheroSection from "components/SiteheroSection"
import LaunchBody from "components/LaunchPad/LaunchBody"
import LedgerContext from "components/LedgerContext"

const InvestmentHub = () => {
  useEffect(() => {
    document.title = "Investment Hub | TangleSwap"
  }, [])
  return (
    <Body>
      <SiteheroSection
        backgroundImage={auctionHeroBg}
        title="Deep Space Investment Hub"
        subText="Your Token Launchpad to jump early into the latest, hottest projects."
      />
      <LedgerContext />
      <LaunchBody />
    </Body>
  )
}

const Body = styled.div`
  height: 100%;
`
export default InvestmentHub
