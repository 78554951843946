import { TangleswapTokenList, Chains } from "@tangleswap/sdk"
import { FETCH_TOKENS, FETCH_TOKENS_LOADING } from "./actionsTypes"
import { Dispatch } from "redux"
import { TangleTokenProps } from "components/swap/types"
import { defaultChainId } from "context/MilkomedaContext"

export const tokenListOnChain = (tokenList: any) => {
  return {
    type: FETCH_TOKENS,
    tokenList: tokenList,
  }
}
export const tokenListOnChainLoading = (tokenListLoading: boolean) => {
  return {
    type: FETCH_TOKENS_LOADING,
    tokenListLoading: tokenListLoading,
  }
}

export const updateTangleswapTokenList = (tokenList: any, chainId: any) => {
  return (dispatch: Dispatch) => {
    localStorage.setItem(
      `tangleswapChainId${chainId}Tokens`,
      JSON.stringify(tokenList)
    )

    dispatch(tokenListOnChain(tokenList))
  }
}

// export const fetchTokensOnChain = (chainId: Chains) => {
//   return async (dispatch: any) => {
//     if (!chainId) return

//     const tokensAvailableOnChain = TangleswapTokenList?.tokens?.filter(
//       (tokens) => tokens?.chainId === chainId
//     )

//     const formattedListChain = tokensAvailableOnChain?.map(
//       (data: TangleTokenProps) => {
//         return {
//           address: data.address,
//           chainId: data.chainId,
//           decimals: data.decimals,
//           logoURI: data?.logoURI,
//           name: data.name,
//           symbol: data.symbol,
//           l1Address: data?.l1Address,
//           l1Decimals: data?.l1Decimals,
//           l1EncodedName: data?.l1EncodedName,
//         }
//       }
//     )
//     dispatch(updateTangleswapTokenList(formattedListChain, chainId))
//   }
// }

export const fetchTokensOnChain = (chainId: Chains) => {
  return async (dispatch: any) => {
    if (!chainId) return

    const storedTokenListKey = `tangleswapChainId${chainId}Tokens`
    // const storedTokenList = localStorage.getItem(storedTokenListKey)

    let tokenList

    // if (storedTokenList) {
    //   tokenList = JSON.parse(storedTokenList) // Use stored list if available
    // } else {
    // // Fetch new list if not in localStorage
    const tokensAvailableOnChain = TangleswapTokenList?.tokens?.filter(
      (token) => token?.chainId === chainId
    )

    tokenList = tokensAvailableOnChain?.map((data: TangleTokenProps) => ({
      address: data.address,
      chainId: data.chainId,
      decimals: data.decimals,
      logoURI: data.logoURI,
      name: data.name,
      symbol: data.symbol,
      l1Address: data.l1Address,
      l1Decimals: data.l1Decimals,
      l1EncodedName: data.l1EncodedName,
    }))

    localStorage.setItem(storedTokenListKey, JSON.stringify(tokenList))
    // }

    dispatch(tokenListOnChain(tokenList))
  }
}
