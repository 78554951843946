import { SLOT0_PRICE, TOKEN_BALANCE } from "store/actions/actionsTypes"
import { updateObject } from "../utility"
export interface TokenBalance {
  tokenbalance: any
  slot0: any
}

const initialState: TokenBalance = {
  tokenbalance: null,
  slot0: null,
}

const tokenBalanceSuccess = (state, action) => {
  return updateObject(state, {
    tokenbalance: action.data,
  })
}

const slot0PriceSuccess = (state, action) => {
  return updateObject(state, {
    slot0: action.data,
  })
}

const tokenBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOKEN_BALANCE:
      return tokenBalanceSuccess(state, action)
    case SLOT0_PRICE:
      return slot0PriceSuccess(state, action)
    default:
      return state
  }
}

export default tokenBalanceReducer
