import React from "react"

const LPIcon = () => {
  return (
    <>
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M101.3 26.25C97.15 26.25 93.8 22.9 93.8 18.75C93.8 14.6 97.15 11.25 101.3 11.25H101.35C105.5 11.25 108.85 14.6 108.85 18.75C108.85 22.9 105.45 26.25 101.3 26.25Z"
          fill="#D874FA"
        />
        <path
          d="M18.8 108.75C14.65 108.75 11.3 105.4 11.3 101.25C11.3 97.1 14.65 93.75 18.8 93.75H18.8499C22.9999 93.75 26.3499 97.1 26.3499 101.25C26.3499 105.4 22.95 108.75 18.8 108.75Z"
          fill="#D874FA"
        />
        <path
          d="M30.15 82C28.9 82 27.6501 81.35 26.9501 80.2C23.2501 74.15 21.25 67.15 21.25 60C21.25 38.65 38.65 21.25 60 21.25C67.05 21.25 73.95 23.15 79.95 26.75C81.7 27.8 82.3 30.1 81.25 31.9C80.2 33.65 77.9 34.25 76.1 33.2C71.25 30.3 65.7 28.75 60.05 28.75C42.8 28.75 28.8 42.75 28.8 60C28.8 65.75 30.4 71.4 33.4 76.3C34.5 78.05 33.95 80.35 32.15 81.45C31.5 81.85 30.8 82 30.15 82Z"
          fill="#D874FA"
        />
        <path
          d="M60 98.7502C52.8 98.7502 45.8 96.7502 39.75 93.0502C38 91.9502 37.45 89.6502 38.5 87.9002C39.6 86.1502 41.9 85.6002 43.65 86.6502C48.55 89.6502 54.2 91.2502 60 91.2502C77.25 91.2502 91.25 77.2502 91.25 60.0002C91.25 54.2502 89.6499 48.6002 86.6499 43.7002C85.5499 41.9502 86.0999 39.6502 87.8999 38.5502C89.6499 37.5002 91.9499 38.0002 93.0499 39.8002C96.7499 45.9002 98.75 52.8502 98.75 60.0502C98.75 81.3502 81.35 98.7502 60 98.7502Z"
          fill="#D874FA"
        />
      </svg>
    </>
  )
}

export default LPIcon
