import { useWSCContext } from "context/MilkomedaContext"
import { FC, useEffect, useState } from "react"
import { BiChevronDown } from "react-icons/bi"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import {
  Body2,
  BodyCaption,
  Caption,
  CaptionSmall,
  CaptionTiny,
} from "styles/TextStyles"
import { toSignificantDigits } from "utils/toSignificant"
import { SwapInput } from "./components/SwapInput"

import { useIsSupportedNetwork } from "constants/hooks"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"
import { isAddress } from "utils"

interface TokenProps {
  label?: string
  position?: string
  tokenselected?: boolean
  disabled?: boolean
  onMax?: () => void
  onClick?: () => void
  fieldValue?: any
  handleChange?: any
  tokenBalance: any

  id?: any
  showmax: boolean
  tokenName?: any
  tokenSymbol?: any
  tokenImage?: any
  value?: string
  fiatValue: string | number
  onUserInput?: (value: any) => void
  tokenAddress?: any
  balanceHidden?: boolean
  currency?: any
  onCurrencySelect?: any
  loading?: boolean
}

interface ButtonProps {
  tokenselected?: boolean
}
const TokenContainer: FC<TokenProps> = (props) => {
  const {
    tokenselected,
    tokenBalance,
    balanceHidden,

    onCurrencySelect,
    value,
    showmax,
    onUserInput,
    disabled,
    id,
    tokenAddress,
    tokenName,
    onMax,
    loading,
    tokenSymbol,
    fiatValue,
    tokenImage,
    onClick,
  } = props

  //user address state via redux
  const { account } = useWSCContext()

  const balance = tokenBalance?.[tokenAddress?.toLowerCase()]
  const tokenBalanceAmount = !balance ? 0 : parseFloat(String(balance))

  const isSupportedNetwork = useIsSupportedNetwork()

  return (
    <Body onClick={onClick}>
      <Cover>
        <TokenTop>
          <TokenPosition>{null}</TokenPosition>
          {!(account && !isSupportedNetwork) ? (
            <>
              {" "}
              <TokenLabel htmlFor={id}>
                {balanceHidden ? (
                  <>
                    <UserBalance>
                      <BalanceText> Balance:</BalanceText>
                      {account !== null ? (
                        tokenselected ? (
                          <WalletAmount>
                            <WalletBalance>
                              <>
                                {toSignificantDigits(
                                  tokenBalanceAmount ?? 0,
                                  5,
                                  true,
                                  18
                                )}
                              </>
                            </WalletBalance>{" "}
                            {tokenBalance !== null && showmax ? (
                              <>
                                <MaxTransactions onClick={onMax}>
                                  <MaxText>Max</MaxText>
                                </MaxTransactions>
                              </>
                            ) : null}
                          </WalletAmount>
                        ) : (
                          <></>
                        )
                      ) : (
                        <WalletBalance>—</WalletBalance>
                      )}
                    </UserBalance>
                  </>
                ) : (
                  <UserBalance></UserBalance>
                )}
              </TokenLabel>
            </>
          ) : null}
        </TokenTop>

        <TokenBody>
          <TokenSelectButton
            disabled={account && !isSupportedNetwork}
            onClick={onCurrencySelect}
            tokenselected={tokenselected}
          >
            {!(account && !isSupportedNetwork) ? (
              <>
                {" "}
                {tokenselected ? (
                  <>
                    {!!tokenImage ? (
                      <>
                        {" "}
                        <SelectedTokenIcon src={tokenImage} />
                      </>
                    ) : (
                      <CustomIconCover>
                        {" "}
                        <ConvertTokenSymbol text={tokenSymbol} />
                      </CustomIconCover>
                    )}

                    <TokenSelectedName
                      tokenselected={Boolean(
                        tokenselected && !(account && !isSupportedNetwork)
                      )}
                    >
                      {tokenSymbol}
                    </TokenSelectedName>
                  </>
                ) : (
                  <>
                    <TokenSelectedName>Select token</TokenSelectedName>
                  </>
                )}
              </>
            ) : (
              <>
                <TokenSelectedName>Select token</TokenSelectedName>
              </>
            )}

            <SwapIcon
              tokenselected={Boolean(
                tokenselected && !(account && !isSupportedNetwork)
              )}
            />
          </TokenSelectButton>

          <SwapInput
            disabled={account && !isSupportedNetwork}
            value={value}
            name={id}
            loading={loading}
            onUserInput={onUserInput}
          />
        </TokenBody>
        <TokenBottom>
          {!(account && !isSupportedNetwork) ? (
            <>
              {" "}
              <TokenName>{tokenName}</TokenName>
              <TokenDollarValue>
                <>
                  {fiatValue === undefined || !fiatValue ? (
                    ""
                  ) : (
                    <> ${toSignificantDigits(fiatValue, 4, true, 18)}</>
                  )}
                </>
              </TokenDollarValue>
            </>
          ) : null}
        </TokenBottom>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  height: 104px;
  width: 100%;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  margin: 8px auto;
  padding: 12px 20px;

  /* display: flex;
  flex-direction: column;
  height: 104px;
  width: 100%;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 12px 20px 12px 16px; */
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
`
const TokenTop = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: -6px 0 0 0;
`
const TokenPosition = styled(Caption)`
  width: 100%;
  height: 24px;
`
const UserBalance = styled(CaptionSmall)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 28px;
`

const BalanceText = styled(CaptionSmall)`
  cursor: pointer;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  height: 28px;
  opacity: 0.7;
`

const WalletBalance = styled(CaptionSmall)`
  cursor: pointer;
  margin: 0 0 0 4px;
  opacity: 0.7;
`
const TokenLabel = styled.label``
const TokenBody = styled.div`
  min-height: 40px;
  width: 100%;
  margin: 6px 24px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TokenSelectButton = styled.button<ButtonProps>`
  height: 40px;
  box-shadow: ${TangleColors.darkSlateGray} 0px 6px 10px;
  margin: -16px 0 0 0;
  cursor: pointer;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  justify-content: center;
  outline: none;
  border: none;

  align-items: center;
  background: ${(props) =>
    props.tokenselected ? `${TangleColors.white}` : `${TangleColors.heroCTA}`};
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    background: ${TangleColors.black};
    color: ${TangleColors.white};
    border: 1px solid ${TangleColors.white};
  }
  :disabled {
    background: ${TangleColors.red};
    :hover {
      cursor: not-allowed;
    }
  }
`

const SwapIcon = styled(BiChevronDown)<ButtonProps>`
  height: 18px;
  width: 18px;
  margin: 0 0 0 4px;
  color: ${(props) =>
    props.tokenselected ? `${TangleColors.black}` : `${TangleColors.white}`};
  transition: 0.4s ease-in;
  ${TokenSelectButton}:hover & {
    color: ${(props) =>
      props.tokenselected ? `${TangleColors.white}` : `${TangleColors.white}`};
  }
`
const TokenBottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: -6px 0 0 0;
`

const TokenName = styled(CaptionTiny)`
  text-transform: capitalize;
`
const TokenDollarValue = styled(CaptionSmall)`
  color: ${TangleColors.tangleGray};
  opacity: 0.6;
  margin: 0 2px 0 0;
`

export const TokenSelectedName = styled(Body2)<ButtonProps>`
  color: ${(props) =>
    props.tokenselected ? `${TangleColors.black}` : `${TangleColors.white}`};
  margin: 0 2px;
  white-space: nowrap;
  transition: 0.4s ease-in;
  ${TokenSelectButton}:hover & {
    color: ${(props) =>
      props.tokenselected ? `${TangleColors.white}` : `${TangleColors.white}`};
  }
`

const WalletAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 24px;
`
const MaxTransactions = styled.div`
  height: 22px;
  /* max-width: 64px; */
  /* width: 100%; */
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: ${TangleColors.heroCTA};
  margin: 0 0 0 8px;
  cursor: pointer;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.black};
    color: ${TangleColors.white};
    border: 0.5px solid ${TangleColors.white};
  }
  :disabled {
    background: ${TangleColors.grayLight};
    color: ${TangleColors.black};
    cursor: not-allowed;
    :hover {
      cursor: not-allowed;
    }
  }
`

const MaxText = styled(BodyCaption)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelectedTokenIcon = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 999px;
  object-fit: cover;
  margin: 0 8px 0 0;
  background: ${TangleColors.black};
  border: 1px solid ${TangleColors.black};
  transition: 0.4s ease-in;

  ${TokenSelectButton}:hover & {
    border: 1px solid ${TangleColors.white};
  }
`
const CustomIconCover = styled.div`
  margin: 0 12px 0 0;
`
export default TokenContainer
