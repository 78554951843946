import nftHeroBg from "assets/hero-white.png"
import { useEffect } from "react"
import SiteheroSection from "components/SiteheroSection"
import NFTContainer from "components/nfts/NFTContainer"
import styled from "styled-components"
import LedgerContext from "components/LedgerContext"
const NFTStaking = () => {
  useEffect(() => {
    document.title = "NFT Staking"
  }, [])
  return (
    <>
      <Body>
        {/* <NFTsHero /> */}
        <SiteheroSection
          backgroundImage={nftHeroBg}
          title="NFT Docking Stations"
          subText="Stake your NFTs and earn passive income. Unlock the power of
              NFT-Fi."
        />
        <LedgerContext />
        <NFTContainer />
      </Body>
    </>
  )
}
const Body = styled.div`
  /* padding: 0 16px; */
  padding: 0;
`

export default NFTStaking
