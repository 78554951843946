import { useWSCContext } from "context/MilkomedaContext"
import React, { FC, useState } from "react"
import { useWSCProvider } from "milkomeda-wsc-ui-test-beta"
import styled from "styled-components"
import { CardanoWalletButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall, Header7 } from "styles/TextStyles"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import { useForm } from "react-hook-form"
import LoaderSpinner from "components/LoaderSpinner"
import EmptyOverviewState from "./EmptyOverviewState"
import WalletInformation from "./WalletInformation"
import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"

import {
  LOCKED_WITH_ASSETS_ADA_AMOUNT,
  LOVELACE_UNIT,
  REQUIRED_FEE_ADA,
  TX_STATUS_CHECK_INTERVAL,
  TxStatus,
} from "constants/milkomeda/transaction"

import { convertTokensToWei } from "components/utils/convertWeiToTokens"
import BigNumber from "bignumber.js"
import CardanoTxFeedback from "./CardanoTxFeedback"
import { OriginDataProps } from "../utils/types"
import { walletOverviewWrapMessages } from "../utils/helper"
import WalletLayout from "./WalletLayout"
import { useTransactionFees } from "hooks/milkomeda/useTransactionFees"
import useInterval from "components/utils/useInterval"

interface OverviewProps {
  active?: boolean
  token?: string
  amount?: string | number
  contract?: string
  tokenBalance?: number
  grid?: boolean
}

interface MoveToFormProps {
  amounts: Array<number | string>
}

const MoveCardano: FC<OverviewProps> = (props) => {
  const { active } = props
  const { l1Account, originTokens: originBalance } = useWSCContext()
  const { wscProvider } = useWSCProvider()

  const [processingIndex, setProcessingIndex] = useState<number | null>(null)
  const [activeLayout, setActiveLayout] = useState<number | null>(1)
  const [errorDisplay, setErrorDisplay] = useState<number | null>(null)
  const [txHash, setTxHash] = useState<string | undefined>()
  const [transactionErrorIndex, setTransactionErrorIndex] = useState<
    number | null
  >(null)

  const { txStatus, txStatusError, setTxStatusError, setTxStatus } =
    useTransactionStatus()
  const { wrappingFee, evmEstimatedFee, adaLocked, unwrappingFee } =
    useTransactionFees()

  const { register, watch, setValue } = useForm<MoveToFormProps>({
    defaultValues: {
      amounts: originBalance?.data?.map(() => "") || [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  })
  useInterval(
    async () => {
      if (!wscProvider || txHash == null) return
      const response = await wscProvider.getTxStatus(txHash)
      setTxStatus(response)
    },
    txHash != null && txStatus !== TxStatus.Confirmed
      ? TX_STATUS_CHECK_INTERVAL
      : null
  )
  const handleValueMax = (index: number, value: string, unit: string) => {
    setValue(
      `amounts.${index}`,
      unit === LOVELACE_UNIT
        ? parseInt(value.replace(/,/g, ""), 10) - LOCKED_WITH_ASSETS_ADA_AMOUNT
        : parseInt(value.replace(/,/g, ""), 10)
    )
  }
  const handleL1ToL2 = async (
    index: number,
    value: number | string,
    token?: OriginDataProps
  ) => {
    setErrorDisplay(null)
    setProcessingIndex(index)
    setTxStatus(TxStatus.Init)
    const totalFees = adaLocked
      .multipliedBy(10 ** 6)
      .plus(unwrappingFee.multipliedBy(10 ** 6))
      .plus(evmEstimatedFee.multipliedBy(10 ** 6))
      .plus(wrappingFee.multipliedBy(10 ** 6))
    try {
      const wrapAmount =
        token.unit === LOVELACE_UNIT
          ? convertTokensToWei({
              value: new BigNumber(value),
              token: { decimals: 6 },
            })
              .plus(totalFees)
              .minus(wrappingFee.multipliedBy(10 ** 6))
              .dp(0, BigNumber.ROUND_UP)
          : convertTokensToWei({
              value: new BigNumber(value),
              token: { decimals: token.decimals },
            })

      const txHash = await wscProvider?.wrap(
        undefined,
        token.unit,
        wrapAmount.toNumber(),
        token.unit !== LOVELACE_UNIT
          ? totalFees.integerValue(BigNumber.ROUND_UP).toNumber()
          : 0
      )

      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)
    } catch (err) {
      setErrorDisplay(err)
      setTxStatus(TxStatus.Error)
      setTransactionErrorIndex(index)
      console.error(err)
      if (err instanceof Error) {
        setTxStatusError(err.message)
      }
    }
  }

  const changeLayout = (value: number) => {
    setActiveLayout(value)
  }
  const calculateBalance = (balance: number, decimals: number) => {
    const tokenValue = balance / 10 ** decimals
    const tokenBalance = formatNumber(tokenValue)
    return tokenBalance
  }

  return (
    <OverviewStepperCover active={active}>
      <OverviewWrapper>
        <StepperTopRow>
          <StepperRowTitle>Assets in your Cardano L1 Wallet</StepperRowTitle>
          <WalletInformation account={l1Account} />
        </StepperTopRow>
        {processingIndex !== null ? (
          <>
            {" "}
            <CardanoTxFeedback txStatus="Wrapping may take a few minutes (~2-5 min)" />
          </>
        ) : null}
        <WalletLayout
          tokensLength={originBalance?.data?.length}
          number={activeLayout}
          onClick={changeLayout}
        />
        <OverviewBalanceBody>
          {originBalance?.data?.length > 0 ? (
            <>
              {originBalance?.data?.map(
                (data: OriginDataProps, index: number) => {
                  const tokenBalance = calculateBalance(
                    Number(data.quantity),
                    Number(data.decimals)
                  )

                  return (
                    <React.Fragment key={index}>
                      <OverviewButtonCover key={index}>
                        <OverviewCardanoTextRow>
                          <BalanceText>
                            Balance:{" "}
                            <BalanceNumber
                              onClick={() =>
                                handleValueMax(index, tokenBalance, data.unit)
                              }
                            >
                              {tokenBalance}{" "}
                              <CardanoTokenName>
                                {" "}
                                {data.assetName}
                              </CardanoTokenName>
                            </BalanceNumber>
                          </BalanceText>
                        </OverviewCardanoTextRow>
                        <CardanoFormCover grid={activeLayout === 1}>
                          {" "}
                          <InputCover grid={activeLayout === 1}>
                            <OverviewCardanoInput
                              type="text"
                              {...register(`amounts.${index}`, {
                                required: false,
                              })}
                              onInput={(e: any) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                )
                                const parts = e.target.value.split(".")

                                if (parts.length > 2) {
                                  e.target.value =
                                    parts[0] + "." + parts.slice(1).join("")
                                }
                              }}
                              autoComplete="off"
                              placeholder="Enter amount"
                            />{" "}
                            <MaxButton
                              onClick={() =>
                                handleValueMax(index, tokenBalance, data.unit)
                              }
                            >
                              MAX
                            </MaxButton>
                          </InputCover>
                          <OverviewMainButton
                            disabled={
                              !watch(`amounts.${index}`) ||
                              Number(watch(`amounts.${index}`)) >
                                Number(tokenBalance)
                            }
                            onClick={() =>
                              handleL1ToL2(
                                index,
                                watch(`amounts.${index}`),
                                data
                              )
                            }
                          >
                            {!!watch(`amounts.${index}`) &&
                            Number(watch(`amounts.${index}`)) >
                              Number(tokenBalance) ? (
                              <>Insufficient {data.assetName}</>
                            ) : (
                              <>
                                {" "}
                                {processingIndex === index ? (
                                  <>
                                    {walletOverviewWrapMessages[txStatus]}
                                    <LoaderSpinner />
                                  </>
                                ) : (
                                  <> Move to L2</>
                                )}
                              </>
                            )}
                          </OverviewMainButton>
                        </CardanoFormCover>
                        {errorDisplay !== null &&
                        transactionErrorIndex === index &&
                        txStatus === "Error" ? (
                          <>
                            {" "}
                            <CardanoTxFeedback
                              error={true}
                              txStatus={txStatusError}
                            />
                          </>
                        ) : null}
                      </OverviewButtonCover>
                    </React.Fragment>
                  )
                }
              )}
            </>
          ) : (
            <>
              {" "}
              <EmptyOverviewState title="No tokens available" />
            </>
          )}
        </OverviewBalanceBody>
      </OverviewWrapper>
    </OverviewStepperCover>
  )
}
const OverviewStepperCover = styled.div<OverviewProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
  transition: 0.4s ease-in;
  width: 100%;

  padding: 8px;
  margin: 12px 0 0 0;
`
const OverviewWrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;

  align-items: flex-start;
  width: 100%;
  gap: 12px;
  border-radius: 4px;
`
const OverviewButtonCover = styled.div`
  width: 100%;

  margin: 12px 0;
`
const OverviewMainButton = styled(CardanoWalletButton)`
  margin: 12px 0;
`
const StepperTopRow = styled.div`
  display: flex;
  width: 100%;
  margin: -12px auto 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StepperRowTitle = styled(Header7)`
  color: ${TangleColors.white};
  margin: 4px 0;
`

const OverviewCardanoInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  border-radius: 12px;
  font-family: "CabinetGrotesk Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;

  max-width: 900px;
  width: 100%;
  margin: 12px 0;
  height: 48px;
  padding: 12px 16px;
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.grayDark};
  ::placeholder {
    color: ${TangleColors.grayDark};
    font-size: 16px;
    font-family: "DM Sans Regular";
  }
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`
const OverviewCardanoTextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  position: relative;
  z-index: 800;
`
const BalanceText = styled(CaptionSmall)`
  margin: 0;
  color: ${TangleColors.darkerText};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const BalanceNumber = styled(Caption)`
  color: ${TangleColors.white};
  margin: 0 0 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
`
const CardanoTokenName = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 8px;
  cursor: pointer;
`

const InputCover = styled.div<OverviewProps>`
  width: 100%;
  position: relative;
  transition: 0.4s ease-in;
  max-width: ${(props) => (props.grid ? `450px` : `950px`)};
`
const MaxButton = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0%, -50%);
  color: ${TangleColors.white};
  background: ${TangleColors.heroCTA};
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  transition: 0.4s ease-in;
  :hover {
    color: ${TangleColors.black};
    background: ${TangleColors.white};
  }
  :disabled {
    background: ${TangleColors.darkGray};
    cursor: not-allowed;
    color: ${TangleColors.grayLight};

    border: none;
  }
`
const OverviewBalanceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const CardanoFormCover = styled.div<OverviewProps>`
  width: 100%;
  display: grid;
  transition: 0.4s ease-in;
  grid-template-columns: ${(props) => (props.grid ? `2fr 1.4fr` : `1fr 1.4r`)};
  gap: 8px;
`

export default MoveCardano
