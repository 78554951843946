import AddLiquidContainer from "components/Liquidity/AddLiquid/AddLiquidContainer"
import styled from "styled-components"

import { useEffect } from "react"

const AddLiquidity = () => {
  useEffect(() => {
    document.title = "Add Liquidity"
  }, [])
  return (
    <>
      {" "}
      <Body>
        {" "}
        <AddLiquidContainer />
      </Body>
    </>
  )
}
const Body = styled.div`
  min-height: 110vh;
  padding: 24px 0 32px 0;
`
export default AddLiquidity
