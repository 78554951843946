import { AiOutlineInfoCircle, AiOutlineMinusCircle } from "react-icons/ai"
import LPRowHoverBox from "components/utils/LPRowHoverBox"
import React, { FC } from "react"
import { useAppSelector } from "store/hooks"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"
interface LPTypeProps {
  showHelpText?: any
  closedLP?: any
  outOfRange?: any
  mouseEnter?: () => void
  mouseLeave?: () => void
}
const LPTypeRow: FC<LPTypeProps> = (props) => {
  const { outOfRange, closedLP, showHelpText, mouseEnter, mouseLeave } = props
  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  return (
    <>
      {" "}
      {Boolean(userTradingMode) ? (
        <>
          {" "}
          <LPTypeCover>
            {showHelpText && (
              <>
                <LPRowHoverBox
                  text={
                    (closedLP &&
                      `Your position has zero liquidity, hence it's not earning LP fees.`) ||
                    (!outOfRange &&
                      !closedLP &&
                      `The price of this pool is within your predefined range — your position is currently earning LP fees!`) ||
                    (outOfRange &&
                      !closedLP &&
                      `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                    //||
                    // (outOfRange &&
                    //   !closedLP &&
                    //   `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                  }
                />
              </>
            )}

            {closedLP ? (
              <>
                <LiquidityInStatus
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                >
                  <StatusSymbol>
                    <StatusInfoIcon />
                  </StatusSymbol>
                  <StatusText>Closed </StatusText>
                </LiquidityInStatus>
              </>
            ) : (
              <>
                {" "}
                {!outOfRange && !closedLP ? (
                  <>
                    <LiquidityClosedStatus
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                    >
                      <StatusSymbol>
                        <StatusDotIcon />
                      </StatusSymbol>

                      <StatusText> In range</StatusText>
                    </LiquidityClosedStatus>
                  </>
                ) : null}
                {outOfRange && !closedLP ? (
                  <>
                    <LiquidityOutStatus
                      onMouseEnter={mouseEnter}
                      onMouseLeave={mouseLeave}
                    >
                      <StatusSymbol>
                        <StatusOutInfoIcon />
                      </StatusSymbol>

                      <StatusOutText>Out of range </StatusOutText>
                    </LiquidityOutStatus>
                  </>
                ) : null}
              </>
            )}
          </LPTypeCover>
        </>
      ) : (
        <>
          {" "}
          <LPTypeCover>
            {showHelpText && (
              <>
                <LPRowHoverBox
                  text={
                    (closedLP &&
                      `Your position has zero liquidity, hence it's not earning LP fees.`) ||
                    (!outOfRange &&
                      !closedLP &&
                      `The price of this pool is within your predefined range — your position is currently earning LP fees!`) ||
                    (outOfRange &&
                      !closedLP &&
                      `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                    //||
                    // (outOfRange &&
                    //   !closedLP &&
                    //   `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                  }
                />
              </>
            )}
            {!outOfRange && !closedLP ? (
              <>
                <LiquidityClosedStatus
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                >
                  <StatusSymbol>
                    <StatusDotIcon />
                  </StatusSymbol>

                  <StatusText> Open</StatusText>
                </LiquidityClosedStatus>
              </>
            ) : null}

            {outOfRange && !closedLP ? (
              <>
                <LiquidityClosedStatus
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                >
                  <StatusSymbol>
                    <StatusDotIcon />
                  </StatusSymbol>

                  <StatusText>Open</StatusText>
                </LiquidityClosedStatus>
              </>
            ) : null}
            {closedLP && (
              <>
                <LiquidityInStatus
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                >
                  <StatusSymbol>
                    <StatusInfoIcon />
                  </StatusSymbol>
                  <StatusText>Closed </StatusText>
                </LiquidityInStatus>
              </>
            )}
          </LPTypeCover>
        </>
      )}
    </>
  )
}

const LPTypeCover = styled.div`
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;
  margin: 8px 0;
  position: relative;
  z-index: 50;
  @media only screen and (max-width: 960px) {
    /* margin: 10px 0; */
    max-width: 500px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 470px) {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
`

const LiquidityClosedStatus = styled.div`
  height: 32px;
  max-width: 96px;
  width: 100%;
  border-radius: 12px;

  background: ${TangleColors.tangleBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
  padding: 8px;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  border-radius: 12px;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`
const LiquidityOutStatus = styled.div`
  height: 32px;
  padding: 8px;
  max-width: 130px;
  width: 100%;
  border-radius: 12px;
  background: ${TangleColors.tangleYellow};
  display: flex;
  color: ${TangleColors.black};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  border-radius: 12px;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`
const LiquidityInStatus = styled.div`
  margin: 3px 0;
  height: 32px;
  max-width: 96px;
  width: 100%;
  border-radius: 12px;
  padding: 8px;
  background: ${TangleColors.tangleBlack};
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  border: 1px solid transparent;
  transition: 0.4s ease-in;
  border-radius: 12px;

  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
`
const StatusSymbol = styled.div``

const StatusInfoIcon = styled(AiOutlineMinusCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.white};
  border-radius: 9999px;
`
const StatusDotIcon = styled.div`
  height: 16px;
  width: 16px;

  border-radius: 9999px;
  background: ${TangleColors.tangleGreen};
`
const StatusText = styled(CaptionSmall)`
  color: ${TangleColors.white};
  margin: 0 4px;
  white-space: nowrap;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`
const StatusOutText = styled(CaptionSmall)`
  color: ${TangleColors.black};
  margin: 0 4px;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const StatusOutInfoIcon = styled(AiOutlineInfoCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.black};
  border-radius: 9999px;
`

export default LPTypeRow
