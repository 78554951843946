import React from "react"
import Spinner from "react-spinner-material"
import styled from "styled-components"
interface PendingProps {
  hoverPending?: boolean
}
const PendingLoaderSpinner: React.FC<PendingProps> = (props) => {
  const { hoverPending } = props
  return (
    <SpinnerCover>
      <Spinner
        className="PendingLoaderSpinner"
        radius={12}
        color={hoverPending ? "#fff" : "#000"}
        stroke={2}
        visible={true}
      />
    </SpinnerCover>
  )
}

const SpinnerCover = styled.div`
  margin: -2px 6px 0 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default PendingLoaderSpinner
