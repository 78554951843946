import {
  BLOCK_TRANSACTION_STATE,
  SELECTED_WALLET,
  USER_WALLET_CONNECTION,
  USER_WALLET_STATE,
  WALLET_MODAL_STATE,
} from "store/actions/actionsTypes"
import { updateObject } from "../utility"

export interface UserState {
  selectedWallet?: any
  tangleswapWallet?: any
  tangleswapWalletState?: boolean
  blockTransactionState?: boolean
  activeNetwork?: number
}

export const initialState: UserState = {
  selectedWallet: undefined,
  tangleswapWallet: null,
  tangleswapWalletState: null,
  activeNetwork: 1,
}

const updateWalletSelected = (state, action) => {
  return updateObject(state, {
    selectedWallet: action.selectedWallet,
  })
}
const proWalletSelected = (state, action) => {
  return updateObject(state, {
    tangleswapWallet: action.tangleswapWallet,
  })
}

const updateConnectWalletState = (state, action) => {
  return updateObject(state, {
    tangleswapWalletState: action.tangleswapWalletState,
  })
}
const updateBlockedTransactionState = (state, action) => {
  return updateObject(state, {
    blockTransactionState: action.blockTransactionState,
  })
}
const updateWalletModalState = (state, action) => {
  return updateObject(state, {
    activeNetwork: action.activeNetwork,
  })
}

const SelectedWalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_WALLET:
      return updateWalletSelected(state, action)
    case USER_WALLET_CONNECTION:
      return proWalletSelected(state, action)
    case USER_WALLET_STATE:
      return updateConnectWalletState(state, action)
    case BLOCK_TRANSACTION_STATE:
      return updateBlockedTransactionState(state, action)
    case WALLET_MODAL_STATE:
      return updateWalletModalState(state, action)
    default:
      return state
  }
}

export default SelectedWalletReducer
