interface LimitOrderDataProps {
  id: number
  text: string
}

export const LimitOrderData: LimitOrderDataProps[] = [
  {
    id: 1,
    text: "<p>Go to the <a href='/pools/add'>liquidity interface</a> and select a token pair for your order.</p>",
  },
  {
    id: 2,
    text: "<p>Choose your price range to either <a href='https://investopedia.com/terms/t/take-profitorder.asp'>take profits</a> or <a href='https://investopedia.com/terms/b/buy-limit-order.asp'>limit-buy.</a></p>",
  },
  {
    id: 3,
    text: "<p>Decide your order size (i.e. token amount) and <a href='https://docs.tangleswap.exchange/guides/step-by-step/providing-liquidity#pro-mode'>place it as liquidity.</a></p>",
  },
  {
    id: 4,
    text: "<p><a href='https://docs.tangleswap.exchange/dapps/swap#range-orders-next-gen-limit-orders'>Start earning fees</a> while your order gets partially filled. Withdraw your liquidity once completed, or at any time before.</p>",
  },
]
