import React, { FC, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall, Subtitle } from "styles/TextStyles"

import { GrClose } from "react-icons/gr"
import { SwapTokenButton } from "styles/ButtonStyles"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

interface RowsProps {
  inputValue?: any
  inputSymbol?: any
  inputLogo?: any
  outputValue?: any
  outputSymbol?: any
  outputLogo?: any
  closeRemoveModal?: () => void
  clickCollect?: () => void
  lpModalRef?: any
  lpBodyClick?: (e: any) => void
  isLiquidStaking?: boolean
  feeForLiquidStaking?: any
}
const ClaimFeesModal: FC<RowsProps> = (props) => {
  const {
    closeRemoveModal,
    clickCollect,
    inputValue,
    inputSymbol,
    inputLogo,
    outputValue,
    outputSymbol,
    lpModalRef,
    outputLogo,
    lpBodyClick,
    isLiquidStaking,
    feeForLiquidStaking,
  } = props

  const [step, setStep] = useState(1)

  const stAdaLogo =
    "https://raw.githubusercontent.com/TangleSwap/assets/main/chains/cardano-testnet/info/logo.png"

  return (
    <Body ref={lpModalRef} onClick={lpBodyClick}>
      <Cover>
        <LPNavigation>
          <IconText>Collect Fees</IconText>
          <CloseIconWrapper onClick={closeRemoveModal}>
            <CloseIcon />
          </CloseIconWrapper>
        </LPNavigation>

        {(isLiquidStaking && step === 1) || !isLiquidStaking ? (
          <InfoTierTop>
            <LiquidityBox>
              <BoxBottom>
                <BowFirstRow>
                  <LPBoxValue>Claimable {inputSymbol}:</LPBoxValue>
                  <IconWrap>
                    <IconName>{inputValue}</IconName>

                    {!!inputLogo ? (
                      <BoxIcon src={inputLogo} />
                    ) : (
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={inputSymbol} />
                      </CustomSizeWrapper>
                    )}
                    <IconName>{inputSymbol}</IconName>
                  </IconWrap>
                </BowFirstRow>

                <BowSecondRow>
                  <LPBoxValue>Claimable {outputSymbol}:</LPBoxValue>
                  <IconWrap>
                    <IconName>{outputValue}</IconName>

                    {!!outputLogo ? (
                      <BoxIcon src={outputLogo} />
                    ) : (
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={outputSymbol} />
                      </CustomSizeWrapper>
                    )}
                    <IconName>{outputSymbol}</IconName>
                  </IconWrap>
                </BowSecondRow>
              </BoxBottom>
            </LiquidityBox>
          </InfoTierTop>
        ) : null}
        {isLiquidStaking && step === 2 ? (
          <InfoTierTop>
            <LiquidityBox>
              <BoxBottom>
                <BowFirstRow>
                  <LPBoxValue>Liquid Staking L1 Rewards:</LPBoxValue>
                  <IconWrap>
                    <IconName>{feeForLiquidStaking}</IconName>
                    <BoxIcon src={stAdaLogo} />
                    <IconName>Native ADA</IconName>
                  </IconWrap>
                </BowFirstRow>
              </BoxBottom>
            </LiquidityBox>
          </InfoTierTop>
        ) : null}

        {isLiquidStaking && (
          <LiquidStakingStepWrapper>
            <LiquidStakingStepContainer>
              <LiquidStakingStepButton
                active={step === 1}
                onClick={() => setStep(1)}
              >
                1
              </LiquidStakingStepButton>
              <LiquidStakingStepTitle active={step === 1}>
                Swap Fees
              </LiquidStakingStepTitle>
            </LiquidStakingStepContainer>
            <LiquidStakingStepDivider />
            <LiquidStakingStepContainer>
              <LiquidStakingStepButton
                active={step === 2}
                onClick={() => setStep(2)}
              >
                2
              </LiquidStakingStepButton>
              <LiquidStakingStepTitle active={step === 2}>
                Staking Rewards
              </LiquidStakingStepTitle>
            </LiquidStakingStepContainer>
          </LiquidStakingStepWrapper>
        )}

        <CollectFeesWrapper>
          <CollectFeesText>
            Collecting fees will withdraw currently available fees for you.
          </CollectFeesText>
          {/* FIXME LiquidStaking Collection */}
          <SwapButtons
            onClick={
              !isLiquidStaking
                ? clickCollect
                : step === 1
                ? clickCollect
                : () =>
                    alert(
                      "Not so fast, Explorer! 🫡 ADA Liquid Staking is being currently integrated into the Tangleship — coming very soon. 🛸"
                    )
            }
          >
            <SwapButton>
              {isLiquidStaking && step === 2
                ? "Collect Liquid Staking"
                : "Collect"}
            </SwapButton>
          </SwapButtons>
        </CollectFeesWrapper>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 260;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 24px;
`
const Cover = styled.div`
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 40px;
  padding: 20px;
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${TangleColors.lighthover};
  position: relative;
`
const CloseIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
`
const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 4px 0;
  justify-content: center;
  align-items: center;
`

const IconText = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
  transition: 0.4s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const InfoTierTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const LiquidityBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
`

const BoxBottom = styled.div`
  max-width: 680px;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 24px 24px 26px 24px;
  width: 100%;
  margin: 24px auto;
`
const BowFirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2px 0 16px 0;
`

const BowSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
`

const BoxIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`
const LPBoxValue = styled.div`
  margin: 0;
`

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`
const IconName = styled(Caption)`
  margin: 0 8px;
  color: ${TangleColors.white};
`

const SwapButtons = styled.div`
  width: 100%;
  margin: 8px 0;
`

const SwapButton = styled(SwapTokenButton)``

const CollectFeesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0 8px 0;
`
const CollectFeesText = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
  text-align: center;
`

const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const LiquidStakingStepWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`

const LiquidStakingStepButton = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 9999px;
  width: 48px;
  height: 48px;
  background: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.tangleBlack};
  color: ${TangleColors.white};
  cursor: pointer;
`

const LiquidStakingStepDivider = styled.div`
  width: 50%;
  height: 1px;
  background: ${TangleColors.lighthover};
  margin: 16px 0;
`

const LiquidStakingStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const LiquidStakingStepTitle = styled.div<{ active?: boolean }>`
  text-align: center;
  color: ${(props) =>
    props.active ? TangleColors.lighthover : TangleColors.white};
`

export default ClaimFeesModal
