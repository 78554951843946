import React, { FC, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall, Header3, Header4 } from "styles/TextStyles"
import { AiOutlineInfoCircle } from "react-icons/ai"

import { formatDollarAmount } from "components/LaunchPad/utils/formatDollarAmount"
import { formatNumber } from "components/LaunchPad/utils/formatNumber"

import AccountHoverBox from "components/utils/AccountHoverBox"
interface CardProps {
  title?: string
  leftTitle?: string
  rightTitle?: string
  leftValue?: string | number
  rightValue?: string | number
  leftIcon?: any
  rightIcon?: any
  showInfo?: boolean
}
const StatCardRow: FC<CardProps> = (props) => {
  const {
    title,
    leftTitle,
    rightTitle,
    leftValue,
    rightValue,
    rightIcon,
    leftIcon,
    showInfo,
  } = props
  const [showHelpText, setShowHelpText] = useState<boolean>(false)

  const mouseEnter = () => {
    setShowHelpText(true)
  }

  const mouseLeave = () => {
    setShowHelpText(false)
  }
  return (
    <AccountWrapper>
      <AccountStatTop>
        <AccountStatTitle>{title}</AccountStatTitle>{" "}
        {showInfo ? (
          <>
            {" "}
            <AccountInfoLink>
              <InfoCircle onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
            </AccountInfoLink>
            {showHelpText ? (
              <>
                <AccountHoverBox
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                  right={-180}
                  top={52}
                  text={`10% of the Volume from your Referrals is counted towards your earned Airdrop rewards.  <a
                  href="https://docs.tangleswap.exchange/token/airdrop-program "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here for more info.
                </a> `}
                />
              </>
            ) : null}
          </>
        ) : null}
      </AccountStatTop>
      <AccountStateBottom>
        <AccountStatRow>
          {leftIcon}
          <StatValue>
            {!!leftValue ? formatNumber(leftValue) : <>—</>}
          </StatValue>
          <StatTitle>{leftTitle}</StatTitle>
        </AccountStatRow>
        <AccountStatRow>
          {rightIcon}
          <StatValue>
            {!!rightValue ? formatDollarAmount(Number(rightValue)) : <>—</>}
          </StatValue>
          <StatTitle>{rightTitle}</StatTitle>
        </AccountStatRow>
      </AccountStateBottom>
      <AccountExternalStateBottom></AccountExternalStateBottom>
    </AccountWrapper>
  )
}

const AccountWrapper = styled.div`
  background: ${TangleColors.white};
  min-height: 250px;
  max-width: 800px;
  position: relative;
  border-radius: 20px;
  padding: 12px 12px 32px 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  transition: 0.4s ease-in;
  border: 1px solid ${TangleColors.heroCTA};
`
const AccountStatTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 16px 0;
`
const AccountStatTitle = styled(Header4)`
  color: ${TangleColors.icon};
  transition: 0.4s ease-in;
  text-align: center;
`
const AccountStateBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -25px 0 0 0;
`

const AccountExternalStateBottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0 16px 0;
`

const AccountStatRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StatValue = styled(Header3)`
  color: ${TangleColors.lightPurple};
  margin: 8px 0;
  transition: 0.4s ease-in;
`
const StatTitle = styled(CaptionSmall)`
  color: ${TangleColors.grayDark};
  margin: 0;
  transition: 0.4s ease-in;
`

const AccountInfoLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 0 8px;
`

const InfoCircle = styled(AiOutlineInfoCircle)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.icon};
  cursor: pointer;
`
export default StatCardRow
