import { FC, useCallback, useEffect, useState } from "react"
import { IToken } from "interfaces/token.interface"
import WSCModalCover from "components/milkomeda/component/WSCModalCover"
import { WrapContainer } from "components/milkomeda/styles"
import ModalTopIcon from "components/milkomeda/component/ModalTopIcon"
import styled from "styled-components"
import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"
import { ethers } from "ethers"
import { TangleColors } from "styles/ColorStyles"
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi"
import { CardanoWalletButton } from "styles/ButtonStyles"
import LoaderSpinner from "components/LoaderSpinner"
import { toSignificantDigits } from "utils/toSignificant"
import { useDebounce } from "use-debounce"
import { popAnimation } from "utils/framer"
import { getEvmExplorerUrl } from "utils/milkomeda/transaction"

interface TransferModalProps {
  data: any
  closeModal: () => void
}

const TransferModal: FC<TransferModalProps> = (props) => {
  const { data, closeModal } = props

  const [amount, setAmount] = useState<string | number>()
  const [debouncedAmount] = useDebounce(amount, 400)
  const [recipient, setRecipient] = useState<string | null>()
  const [evmFunction, setEvmFunction] = useState()
  const [error, setError] = useState<string | null>()
  const [isLoading, setIsLoading] = useState(false)

  const { tangleship } = useTangleship()
  const { account, chainId } = useWSCContext()

  const prepareContractWriteQuery = usePrepareContractWrite(evmFunction)
  const contractWriteQuery = useContractWrite(prepareContractWriteQuery.config)
  const waitForTransactionQuery = useWaitForTransaction({
    hash: contractWriteQuery.data?.hash,
    enabled: !!contractWriteQuery.data?.hash,
  })

  const writeLoading =
    contractWriteQuery.isLoading || waitForTransactionQuery.isLoading
  const isSuccess = waitForTransactionQuery.isSuccess
  const isError = waitForTransactionQuery.isError || contractWriteQuery.isError

  const initTransferFunction = useCallback(async () => {
    try {
      const transferFn = await tangleship?.sendTokensFromWscWallet(
        data.contractAddress,
        ethers.utils
          .parseUnits(debouncedAmount.toString(), data.decimals)
          .toString(),
        recipient
      )
      setEvmFunction(transferFn)
      console.log("Transfer Function: ", transferFn)
    } catch (error) {
      console.error("Transfer Function Error: ", error)
      return
    }
  }, [
    tangleship,
    data.contractAddress,
    data.decimals,
    debouncedAmount,
    recipient,
  ])

  const makeTransaction = () => {
    if (!ethers.utils.isAddress(recipient)) {
      setError("Invalid recipient address")
      return
    }
    if (Number(amount) <= 0) {
      setError("Invalid amount")
      return
    }
    setIsLoading(true)
    try {
      contractWriteQuery?.write?.()
      setError(null)
      setIsLoading(false)
    } catch (error) {
      setError(`An error occurred. Please try again!`)
      setIsLoading(false)
      console.error("Transfer Transaction Error: ", error)
      return
    }
  }

  useEffect(() => {
    if (!data || !account || !tangleship) {
      setError("Something went wrong. Please try again!")
      console.error("Transfer Modal Error: ", data, account, tangleship)
      return
    }
    initTransferFunction()
  }, [data, account, tangleship, debouncedAmount, recipient])

  useEffect(() => {
    if (isSuccess) {
      setRecipient("")
      setAmount("")
    }
  }, [isSuccess])

  return (
    <WSCModalCover closeContinue={closeModal}>
      <WrapContainer
        variants={popAnimation}
        initial="initial"
        animate="animate"
        exit="exit"
        onClick={(e) => e.stopPropagation()}
      >
        <ModalTopIcon
          title="Transfer L2 Token"
          closeModal={closeModal}
          settings={false}
        />
        <Body>
          {error && (
            <AlertWrapper>
              <Danger>{error}</Danger>
            </AlertWrapper>
          )}
          {isSuccess && (
            <AlertWrapper>
              <Success
                href={`${getEvmExplorerUrl(chainId)}/tx/${
                  contractWriteQuery?.data?.hash
                }`}
                target="_b"
              >
                Your transaction has been successfully executed!
              </Success>
            </AlertWrapper>
          )}
          {isError && (
            <AlertWrapper>
              <Danger>Error while executing your transaction!</Danger>
            </AlertWrapper>
          )}
          <InfoWrapper>
            <Text>
              You're about to transfer{" "}
              <HighlightText>{data.symbol}</HighlightText> tokens.
            </Text>
            <Text>
              Your total balance is:{" "}
              <HighlightText>
                {toSignificantDigits(
                  ethers.utils.formatUnits(data.balance, data.decimals),
                  4,
                  true,
                  2
                )}
              </HighlightText>
            </Text>
          </InfoWrapper>
          <Input
            type="text"
            placeholder="Recipient Address"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
          <BottomRow>
            <Input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Button
              onClick={makeTransaction}
              disabled={
                isLoading ||
                writeLoading ||
                amount !== debouncedAmount ||
                !amount ||
                !recipient
              }
            >
              {isLoading || writeLoading ? (
                <LoaderSpinner />
              ) : (
                <>Start Transfer</>
              )}
            </Button>
          </BottomRow>
        </Body>
      </WrapContainer>
    </WSCModalCover>
  )
}

const Body = styled.div`
  padding: 8px;
  width: 100%;
`

const InfoWrapper = styled.div`
  margin: 8px 0 16px 0;
`

const Text = styled.p`
  font-weight: 500;
  color: ${TangleColors.white};
`

const HighlightText = styled.span`
  font-weight: 600;
  color: ${TangleColors.lighthover};
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`

const Danger = styled.div`
  background: ${TangleColors.red}BB;
  color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
`

const Success = styled.a`
  background: ${TangleColors.tangleGreen}BB;
  color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
`

const BottomRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  gap: 16px;
`

const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
  border-radius: 16px;
  font-family: "CabinetGrotesk Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  color: ${TangleColors.white};
  border: 1px solid ${TangleColors.grayDark};
  ::placeholder {
    color: ${TangleColors.grayDark};
    font-size: 16px;
    font-family: "DM Sans Regular";
  }
  @media only screen and (max-width: 650px) {
    font-size: 14px;
  }
`

const Button = styled(CardanoWalletButton)`
  width: 100%;
  transition: 0.4s ease-in;
  color: ${TangleColors.black};
  background: ${TangleColors.lighthover};
`

export default TransferModal
