import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall } from "styles/TextStyles"

const VoidErrorInfo = () => {
  return (
    <CirculationCover>
      <CirculationText>
        {" "}
        VOID is not in circulation yet, it will launch on 28th Dec 2023!
      </CirculationText>
    </CirculationCover>
  )
}
const CirculationCover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${TangleColors.lighthover};
  padding: 12px 14px;
  margin: 24px 0 4px 0;
  cursor: pointer;
`
const CirculationText = styled(CaptionSmall)`
  color: ${TangleColors.lighthover};
`
export default VoidErrorInfo
