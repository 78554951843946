import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { toast as TangleToast } from "sonner"
import { BsArrowLeft } from "react-icons/bs"
import { RiSettings2Line } from "react-icons/ri"
import styled from "styled-components"
import {
  ChainChangeButton,
  SwapConnectButton,
  SwapTokenButton,
} from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { Header5Gothic, Subtitle } from "styles/TextStyles"

import HoverBox from "components/utils/HoverBox"

import {
  Chains,
  Field,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
  calcAmount,
  calcToken0Deposit,
  calcToken1Deposit,
  wrapAddr,
  isInvalid,
  stringToBigInt,
  tickToPrice,
  MAX_UINT256,
} from "@tangleswap/sdk"
import LPPrice from "components/Liquidity/AddLiquid/LPPrice"
import IncreaseLiquidityModal from "components/Liquidity/IncreaseLiquidity/IncreaseLiquidityModal"
import LiquidityAddTokenBox from "components/Liquidity/IncreaseLiquidity/LiquidityAddTokenBox"
import { useIncreaseLiquidityState } from "components/Liquidity/IncreaseLiquidity/actions"
import { useIncreaseLiquidityActionHandlers } from "components/Liquidity/IncreaseLiquidity/useIncreaseLiquidityActionHandlers"
import PositionsLoadingPlaceholder from "components/Liquidity/component/PositionsLoadingPlaceholder"

import { defaultChainId, useWSCContext } from "context/MilkomedaContext"
import LPConfirmationModal from "components/confirmation/LPConfirmationModal"
import SlippageContainer from "components/utils/SlippageContainer"

import addChainToMetamask from "connect/switchChain"
import { PendingStateContext } from "context/PendingContext"

import { useAppDispatch, useAppSelector } from "store/hooks"
import LPType from "utils/LPType"
import { toSignificantDigits } from "utils/toSignificant"
import DappsDivider from "utils/DappsDivider"
import IncreaseLiquidityLoading from "components/Liquidity/component/IncreaseLiquidityLoading"
import IncreaseRemoveAuthLayout from "components/Liquidity/component/IncreaseRemoveAuthLayout"
import { useDebounce } from "use-debounce"

import { useIsSupportedNetwork } from "constants/hooks"

import { useNavigate, useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import { fetchTangleCurrency } from "../utils/liquidity/useFetchLPCurrency"

import { fetchTangleFiatValue } from "utils/useTangleFiatValue"
import { useQuery } from "react-query"
import { useTangleship } from "utils/useTangleship"
import CurrencyTokenImages from "../component/CurrencyTokenImages"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"
import useTangleArray from "utils/useTangleArray"
import ConnectWalletButton from "components/utils/ConnectWalletButton"
import { Dots } from "components/utils/LoadingDots"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"
import IncreaseLiqModal, {
  useIncreaseLiqModalControl,
} from "components/milkomeda/liquidity/IncreaseLiqModal"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"
import { LP_DETAILS_REFETCHES } from "../utils/LiquidContainer"
import { DEFAULT_SLIPPAGE } from "components/swap/SwapContainer"

interface LiquidityProps {
  account?: boolean
}
const IncreaseLiquidityContainer = () => {
  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const { tangleship } = useTangleship()
  const [showHelpText, setShowHelpText] = useState<boolean>(false)

  const navigateRouter = useNavigate()

  const [inputTokenDisabledByMaxRange, setInputTokenDisabledByMaxRange] =
    useState<boolean>(false)
  const [outputTokenDisabledByMinRange, setOutputTokenDisabledByMinRange] =
    useState<boolean>(false)
  const [token0CompareBoolean, setToken0CompareBoolean] =
    useState<boolean>(false)
  const [token1CompareBoolean, setToken1CompareBoolean] =
    useState<boolean>(false)
  const [showSlippage, setShowSlippage] = useState<boolean>(false)
  const { setPendingTransaction } = useContext(PendingStateContext)

  const [txSent, setTxSent] = useState<any>()
  const [positionInfo, setPositionInfo] = useState<any>()
  const [removeModalActive, setRemoveModalActive] = useState<boolean>(false)
  const [showPendingModal, setShowPendingModal] = useState<boolean>(false)
  const [txError, setTxError] = useState<boolean>(false)

  const [txpending, setTxPending] = useState<boolean>(true)
  const [token0Fiat, setToken0Fiat] = useState<any>(undefined)
  const [token1Fiat, setToken1Fiat] = useState<any>(undefined)
  const [token0USDFiat, setToken0USDFiat] = useState<any>(undefined)
  const [token1USDFiat, setToken1USDFiat] = useState<any>(undefined)
  const [token0Balance, setToken0Balance] = useState<any>()
  const [token1Balance, setToken1Balance] = useState<any>()
  const [token0Allowance, setToken0Allowance] = useState<any>()
  const [token1Allowance, setToken1Allowance] = useState<any>()
  const [displayApprovalOne, setDisplayApprovalOne] = useState<boolean>(false)
  const [displayApprovalTwo, setDisplayApprovalTwo] = useState<boolean>(false)
  const [showToken0Approval, setShowToken0Approval] = useState<boolean>(false)
  const [showToken1Approval, setShowToken1Approval] = useState<boolean>(false)
  const [token0Approved, setToken0Approved] = useState<any>(undefined)
  const [token1Approved, setToken1Approved] = useState<any>(undefined)
  // const [token0ApprovalTx, setToken0ApprovalTx] = useState<any>(undefined)
  // const [token1ApprovalTx, setToken1ApprovalTx] = useState<any>(undefined)
  const [token0Approving, setToken0Approving] = useState<any>(undefined)
  const [token1Approving, setToken1Approving] = useState<any>(undefined)
  const [decimalsA, setDecimalsA] = useState<any>(undefined)
  const [decimalsB, setDecimalsB] = useState<any>(undefined)
  const [outOfRange, setOutOfRange] = useState<any>(undefined)
  const [tokenAAddress, setTokenAAddress] = useState<any>(undefined)
  const [tokenBAddress, setTokenBAddress] = useState<any>(undefined)
  const [wrappedAddress0, setWrappedAddress0] = useState<any>(undefined)
  const [wrappedAddress1, setWrappedAddress1] = useState<any>(undefined)
  const [tokenWrappedAddress, setTokenWrappedAddress] = useState<any>(undefined)
  const [price, setPrice] = useState<any>(undefined)
  const [poolToken0, setPoolToken0] = useState<number>(undefined)
  const [poolToken1, setPoolToken1] = useState<number>(undefined)

  const [closedLP, setClosedLP] = useState<boolean>(false)
  const slippageRef = useRef<HTMLDivElement | any>()
  const closeRef = useRef<HTMLDivElement | any>()
  const bodyRef = useRef<HTMLDivElement | any>()
  const swapRef = useRef<HTMLDivElement | any>()
  const modalRef = useRef<HTMLDivElement | any>()
  const lpModalRef = useRef<HTMLDivElement | any>()
  const wscModalRef = useRef<any>(null)
  const { openWSCModal } = useOpenWSCActionModal()
  const { id: liquidityID } = useParams()
  const isSupportedNetwork = useIsSupportedNetwork()
  const supported = isSupportedNetwork
  const showSlippageDropdown = () => {
    setShowSlippage(!showSlippage)
  }
  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )

  const independentField = useAppSelector(
    (state) => state.increaseliquidity.independentField
  )

  const { onUserInput } = useIncreaseLiquidityActionHandlers()
  const { typedValue } = useIncreaseLiquidityState()

  const slippageTolerance = useAppSelector(
    (state) => state.settings.userSlippageTolerance
  )

  const deadlineMinute = useAppSelector(
    (state) => state.settings.deadlineMinute
  )

  const {
    data: currencyA,
    isLoading: poolsTokenALoading,
    refetch: tokenARefetch,
  } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapIncreaseLPToken0", tokenAAddress, l1ChainId],
    () => fetchTangleCurrency(tokenAAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenAAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const {
    data: currencyB,
    isLoading: poolsTokenBLoading,
    refetch: tokenBRefetch,
  } = useQuery<TangleTokenProps, QueryError>(
    ["tangleswapIncreaseLPToken1", tokenBAddress, l1ChainId],
    () => fetchTangleCurrency(tokenBAddress, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,

      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!tokenBAddress && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
  const closeSlippageDropdown = useCallback(
    (event: any) => {
      if (
        event?.target === closeRef.current &&
        event?.target !== slippageRef.current
      ) {
        setShowSlippage(false)
      }
    },
    [showSlippage, slippageRef]
  )
  const mouseEnter = () => {
    setShowHelpText(true)
  }

  const mouseLeave = () => {
    setShowHelpText(false)
  }

  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )
  const prepIncreaseLiqFn = () => {
    const deadline = Math.floor(Date.now() / 1000 + Number(deadlineMinute)) // deadlineMinute is stored in seconds, despite its name
    const amount0Desired = stringToBigInt(
      formattedAmounts[Field.INPUT],
      decimalsA
    )
    const amount1Desired = stringToBigInt(
      formattedAmounts[Field.OUTPUT],
      decimalsB
    )
    const slippage =
      slippageTolerance === "auto"
        ? DEFAULT_SLIPPAGE
        : 1 - Number(slippageTolerance) / 10000 // slippageTolerance is e.g. "400" for "4%""
    const amount0Min = stringToBigInt(
      String(Number(formattedAmounts[Field.INPUT]) * slippage),
      decimalsA
    )
    const amount1Min = stringToBigInt(
      String(Number(formattedAmounts[Field.OUTPUT]) * slippage),
      decimalsB
    )

    const fn = tangleship?.increaseLiquidity
    const fnParams = [
      currencyA?.address,
      currencyB?.address,
      Number(liquidityID),
      amount0Desired,
      amount1Desired,
      amount0Min,
      amount1Min,
      deadline,
    ] as const

    const fnFeedbackParams = {
      token0: Number(formattedAmounts[Field.INPUT]),
      token1: Number(formattedAmounts[Field.OUTPUT]),
      poolToken0,
      poolToken1,
      chainId,
      liquidityID,
    }
    const fnFeedback = (params, txReceipt) => {
      if (txReceipt?.status !== 0) {
        const percentageChange =
          params.poolToken0 > 0
            ? params.token0 / params.poolToken0
            : params.token1 / params.poolToken1

        const liqChangeKey = `liquidityIDchange${params.chainId}${params.liquidityID}`
        const prev = localStorage.getItem(liqChangeKey)
        const next = Number(prev) + percentageChange
        // localStorage.setItem(liqChangeKey, String(next))
        localStorage.setItem(liqChangeKey, LP_DETAILS_REFETCHES)
      }
    }

    return { fn, fnParams, fnFeedback, fnFeedbackParams }
  }

  const _increaseLiquidity = async () => {
    if (!supported) {
      return
    } else {
      setTxError(false)
      // toast.info("Waiting for confirmation...")
      setRemoveModalActive(false)
      setShowPendingModal(true)
      setTxPending(true)

      const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepIncreaseLiqFn()

      fn?.(...fnParams)?.then((res: any) => {
        setPendingTransaction(true)
        setTxError(false)

        setTxSent(res?.hash)
        setTxPending(false)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fnFeedback(fnFeedbackParams, receipt)

            setPendingTransaction(false)
            // tokenARefetch()
            // tokenBRefetch()
            TangleToast.success("Transaction successful", {
              description: `Increasing liquidity for ${currencyA?.symbol} and ${currencyB?.symbol} successful`,
            })
          })
        } else {
          setRemoveModalActive(false)
          setTxError(true)
          setShowPendingModal(true)
          setTxPending(false)

          setTxSent(res?.hash)

          setPendingTransaction(false)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
        }
      })
    }
  }

  useEffect(() => {
    if (
      !currencyA ||
      !currencyB ||
      !currencyA?.address ||
      !currencyB?.address ||
      !price ||
      !decimalsA ||
      !decimalsB
    )
      return

    setPoolToken0(
      calcAmount(
        currencyA?.address,
        currencyB?.address,
        chainId,
        true,
        positionInfo?.liquidity,
        price,
        tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB),
        tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB),
        decimalsA,
        decimalsB
      ) /
        10 ** decimalsA
    )

    setPoolToken1(
      calcAmount(
        currencyA?.address,
        currencyB?.address,
        chainId,
        false,
        positionInfo?.liquidity,
        price,
        tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB),
        tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB),
        decimalsA,
        decimalsB
      ) /
        10 ** decimalsB
    )
  }, [
    chainId,
    currencyA,
    currencyB,
    price,
    decimalsA,
    decimalsB,
    positionInfo?.liquidity,
    positionInfo?.tickLower,
    positionInfo?.tickUpper,
  ])

  useEffect(() => {
    const closeSlippageDropdownBody = (event) => {
      if (showSlippage && !slippageRef.current.contains(event.target)) {
        setShowSlippage(false)
      }
    }

    document.addEventListener("mousedown", closeSlippageDropdownBody)

    return () => {
      document.removeEventListener("mousedown", closeSlippageDropdownBody)
    }
  }, [showSlippage])

  useEffect(() => {
    const tokenWrappedAddressValue = WRAPPED_ADDRESS[chainId]
    const tokenAAddressValue: any =
      tokenWrappedAddress === positionInfo?.token0
        ? NATIVE_SYMBOL[chainId]
        : positionInfo?.token0
    const tokenBAddressValue =
      tokenWrappedAddress === positionInfo?.token1
        ? NATIVE_SYMBOL[chainId]
        : positionInfo?.token1

    const wrappedAddress0Value = wrapAddr(
      currencyA?.address,
      chainId
    ).tokenAddress
    const wrappedAddress1Value = wrapAddr(
      currencyB?.address,
      chainId
    ).tokenAddress
    setTokenWrappedAddress(tokenWrappedAddressValue)
    setTokenAAddress(tokenAAddressValue)
    setTokenBAddress(tokenBAddressValue)
    setWrappedAddress0(wrappedAddress0Value)
    setWrappedAddress1(wrappedAddress1Value)
    return () => {
      setTokenAAddress(undefined)
      setTokenBAddress(undefined)
      setWrappedAddress0(undefined)
      setWrappedAddress1(undefined)
      setTokenWrappedAddress(undefined)
    }
  }, [
    chainId,
    currencyA,
    currencyB,
    positionInfo?.token0,
    positionInfo?.token1,
    tokenWrappedAddress,
  ])

  const approveInputToken = () => {
    if (!supported) {
      return
    } else {
      tangleship?.approveContract(currencyA?.address, "LP").then((res: any) => {
        // setToken0ApprovalTx(res)
        setToken0Approving(true)
        setPendingTransaction(true)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fetchAllowanceToken0()

            setToken0Approving(false)
            setPendingTransaction(false)
            setToken0Approved(true)

            TangleToast.success("Transaction successful", {
              description: `Token approval for ${currencyA?.symbol} successful`,
            })
            return
          })
        } else {
          setToken0Approving(false)
          setPendingTransaction(false)
          setToken0Approved(false)
          setTxSent(res?.hash)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
          return
        }
      })
    }
  }

  const approveOutputToken = () => {
    if (!supported) {
      return
    } else {
      tangleship?.approveContract(currencyB?.address, "LP").then((res: any) => {
        // setToken1ApprovalTx(res)
        setToken1Approving(true)
        setPendingTransaction(true)
        if (res?.tx !== null) {
          res.tx?.wait().then((receipt) => {
            fetchAllowanceToken1()

            setToken1Approving(false)
            setPendingTransaction(false)
            setToken1Approved(true)

            TangleToast.success("Transaction successful", {
              description: `Token approval for ${currencyB?.symbol} successful`,
            })
            return
          })
        } else {
          setToken1Approved(false)
          setPendingTransaction(false)
          setToken1Approving(false)

          TangleToast.error("Transaction reverted!", {
            description: `User rejected transaction`,
          })
          return
        }
      })
    }
  }

  const fetchAllowanceToken0 = useCallback(
    async (refetch = false) => {
      if (
        !account ||
        isInvalid([currencyA?.address], { null: true }) ||
        !decimalsA
      )
        return

      if (isWSCConnected) {
        setToken0Allowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const storageKey = `increaseLiq0Allowance${chainId}${account}${currencyA?.address.toLowerCase()}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setToken0Allowance(storageValue)
        return
      }

      const res = await tangleship?.getAllowance(
        currencyA?.address,
        account,
        "LP"
      )
      const allowance = Number(res?._hex) / 10 ** decimalsA
      sessionStorage.setItem(storageKey, allowance.toString())
      setToken0Allowance(allowance)
    },
    [account, chainId, currencyA?.address, decimalsA, tangleship]
  )
  useEffect(() => {
    fetchAllowanceToken0()
  }, [fetchAllowanceToken0])

  const fetchAllowanceToken1 = useCallback(
    async (refetch = false) => {
      if (
        !account ||
        isInvalid([currencyB?.address], { null: true }) ||
        !decimalsB
      )
        return

      if (isWSCConnected) {
        setToken1Allowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const storageKey = `increaseLiq1Allowance${chainId}${account}${currencyB?.address.toLowerCase()}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setToken1Allowance(storageValue)
        return
      }

      const res = await tangleship?.getAllowance(
        currencyB?.address,
        account,
        "LP"
      )
      const allowance = Number(res?._hex) / 10 ** decimalsB
      sessionStorage.setItem(storageKey, allowance.toString())
      setToken1Allowance(allowance)
    },
    [account, chainId, currencyB?.address, decimalsB, tangleship]
  )
  useEffect(() => {
    fetchAllowanceToken1()
  }, [fetchAllowanceToken1])

  const isSameBlockhainOrder = isInvalid([wrappedAddress0, wrappedAddress1])
    ? null
    : wrappedAddress0.toLowerCase() < wrappedAddress1.toLowerCase() // this is enforced by contract

  useEffect(() => {
    if (isInvalid([currencyA])) return
    const decimalsValue = currencyA === undefined ? 0 : currencyA?.decimals
    setDecimalsA(decimalsValue)
  }, [currencyA?.address])

  useEffect(() => {
    if (isInvalid([currencyB])) return
    const decimalsValue = currencyB === undefined ? 0 : currencyB?.decimals
    setDecimalsB(decimalsValue)
  }, [currencyB?.address])

  const { TangleswapUserPools } = useTangleArray(account, chainId)
  useEffect(() => {
    if (!TangleswapUserPools) return
    const info = TangleswapUserPools?.find(
      (pool) => Number(pool.id) === Number(liquidityID)
    )
    const formattedInfo = {
      fee: Number(info?.feeTier),
      liquidity: Number(info?.liquidity),
      tickLower: Number(info?.tickLower),
      tickUpper: Number(info?.tickUpper),
      token0: info?.token0Address,
      token1: info?.token1Address,
    }
    setPositionInfo(formattedInfo)
  }, [TangleswapUserPools])

  const priceLower = tickToPrice(positionInfo?.tickLower, decimalsA, decimalsB)
  const priceUpper = tickToPrice(positionInfo?.tickUpper, decimalsA, decimalsB)

  useEffect(() => {
    const addrA = currencyA?.address?.toLowerCase()
    const addrB = currencyB?.address?.toLowerCase()
    const isSameOrder = addrA < addrB
    const addr0 = isSameOrder ? addrA : addrB
    const addr1 = isSameOrder ? addrB : addrA
    const feeTier = positionInfo?.fee
    if (
      isInvalid([addr0, addr1, feeTier, chainId]) ||
      positionInfo === undefined
    )
      return

    const storageKey = `poolAddress${chainId}${addr0}${addr1}${feeTier}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      return
    }

    tangleship?.getPool(addrA, addrB, feeTier).then((res) => {
      localStorage.setItem(storageKey, res)
    })
  }, [currencyA, currencyB, positionInfo])

  const dispatch = useAppDispatch()

  // Define setter function
  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  // Set the reducer values
  useEffect(() => {
    if (
      isInvalid([
        currencyA?.address,
        currencyB?.address,
        currencyA?.decimals,
        currencyB?.decimals,
      ])
    )
      return

    const params = {
      token0: currencyA?.address,
      token1: currencyB?.address,
      decimals0: currencyA?.decimals,
      decimals1: currencyB?.decimals,
      feeTier: positionInfo?.fee,
    }
    checkSlot0Price(params)
  }, [
    checkSlot0Price,
    currencyA?.address,
    currencyA?.decimals,
    currencyB?.address,
    currencyB?.decimals,
    positionInfo?.fee,
  ])

  // Get the reducer values
  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  // Set the specific values
  useEffect(() => {
    if (!slot0PricesList) return

    const address0 = currencyA?.address
    const address1 = currencyB?.address
    const addr0 = wrapAddr(address0, chainId)?.tokenAddress?.toLowerCase()
    const addr1 = wrapAddr(address1, chainId)?.tokenAddress?.toLowerCase()
    const isSameOrder = addr0 < addr1
    const token0 = isSameOrder ? addr0 : addr1
    const token1 = isSameOrder ? addr1 : addr0

    const poolKey = `${token0}_${token1}_${positionInfo?.fee}`
    const listItem = slot0PricesList?.[poolKey]
    if (!listItem) return

    setPrice(listItem?.price)
  }, [
    currencyA?.address,
    currencyB?.address,
    positionInfo?.fee,
    slot0PricesList,
  ])

  const uiPrice = useCallback(() => {
    if (!isInvalid([price, isSameBlockhainOrder])) {
      const uiAdjustedPrice = isSameBlockhainOrder ? price : 1 / price
      if (price !== 0) return uiAdjustedPrice
    }
  }, [isSameBlockhainOrder, price])

  const calc =
    independentField === Field.INPUT
      ? String(calcToken1Deposit(typedValue, uiPrice(), priceLower, priceUpper))
      : String(calcToken0Deposit(typedValue, uiPrice(), priceLower, priceUpper))

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue === "" ? "" : typedValue,
      [dependentField]:
        isNaN(Number(String(calc))) || Number.isNaN(calc)
          ? ""
          : String(Number(calc)) ?? "",
    }),
    [dependentField, independentField, calc, typedValue, account]
  )
  const formattedAmountsConvert = useMemo(
    () => ({
      [independentField]: typedValue === "" ? "" : typedValue,
      [dependentField]:
        isNaN(Number(String(calc))) || Number.isNaN(calc)
          ? ""
          : toSignificantDigits(calc, 6, true, 18) ?? "",
    }),
    [dependentField, independentField, calc, typedValue, account]
  )
  const [debouncedInputValue] = useDebounce(formattedAmounts[Field.INPUT], 100)
  const [debouncedOutputValue] = useDebounce(
    formattedAmounts[Field.OUTPUT],
    100
  )

  useEffect(() => {
    if (isInvalid([token0Allowance, debouncedInputValue, chainId])) return
    const initialized =
      Number(token0Allowance) === 0 ||
      Number(debouncedInputValue) > Number(token0Allowance)
        ? true
        : false

    setDisplayApprovalOne(Boolean(initialized))
  }, [debouncedInputValue, token0Allowance, token0Approved, chainId, account])

  useEffect(() => {
    if (isInvalid([token1Allowance, debouncedOutputValue, chainId])) return
    const initialized =
      Number(token1Allowance) === 0 ||
      Number(debouncedOutputValue) > Number(token1Allowance)
        ? true
        : false

    setDisplayApprovalTwo(Boolean(initialized))
  }, [debouncedOutputValue, token1Allowance, token1Approved, chainId, account])

  const token0InputValue = toSignificantDigits(formattedAmounts[Field.INPUT], 6)
  const token1InputValue = toSignificantDigits(
    formattedAmounts[Field.OUTPUT],
    6
  )

  useEffect(() => {
    if (!account || !currencyA?.address) return
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[currencyA?.address.toLowerCase()]
    setToken0Balance(!tokenBalanceFig ? 0 : tokenBalanceFig)
  }, [tangleswapTokenListBalances, currencyA?.address, account])

  useEffect(() => {
    if (!account || !currencyB?.address) return
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[currencyB?.address.toLowerCase()]
    setToken1Balance(!tokenBalanceFig ? 0 : tokenBalanceFig)
  }, [tangleswapTokenListBalances, currencyB?.address, account])

  useEffect(() => {
    if (isInvalid([price, priceLower, priceUpper])) return
    const rangeValue = Boolean(
      price &&
        priceLower &&
        priceUpper &&
        (price < priceLower || price > priceUpper)
    )
    setOutOfRange(rangeValue)
  }, [priceUpper, priceLower, price])
  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const closeRemoveModal = () => {
    setRemoveModalActive(false)
  }

  const lpBodyClick = useCallback((event: any) => {
    if (lpModalRef.current === event?.target) {
      setRemoveModalActive(false)
    }
  }, [])

  const showRemoveModal = () => {
    setRemoveModalActive(true)
  }

  useEffect(() => {
    return () => {
      onUserInput(Field.INPUT, "")
      onUserInput(Field.OUTPUT, "")
    }
  }, [])

  useEffect(() => {
    const closedLPValue = parseInt(positionInfo?.liquidity) === parseInt("0")
    setClosedLP(closedLPValue)
  }, [positionInfo?.liquidity])

  const closePendingModal = () => {
    if (txpending) {
      setShowPendingModal(false)
    } else {
      setShowPendingModal(false)
      onUserInput(Field.INPUT, "")
      onUserInput(Field.OUTPUT, "")
      navigateRouter(`/pools/${liquidityID}`)
    }
  }

  const closeDismissModal = () => {
    setShowPendingModal(false)
  }

  const closeLPTransactionModal = () => {
    if (txpending) {
      setShowPendingModal(false)
    } else {
      setShowPendingModal(false)
      onUserInput(Field.INPUT, "")
      onUserInput(Field.OUTPUT, "")
      navigateRouter(`/pools/${liquidityID}`)
    }
  }
  const closeModal = (event: any) => {
    if (txpending) {
      if (modalRef.current === event?.target) {
        setShowPendingModal(false)
      }
    } else {
      if (modalRef.current === event?.target) {
        setShowPendingModal(false)
        onUserInput(Field.INPUT, "")
        onUserInput(Field.OUTPUT, "")
        navigateRouter(`/pools/${liquidityID}`)
      }
    }
  }

  const handleMaxInput = useCallback(() => {
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[currencyA?.address.toLowerCase()]
    const tokenBalance = !tokenBalanceFig ? 0 : tokenBalanceFig
    onUserInput(Field.INPUT, toSignificantDigits(tokenBalance, 6))
  }, [tangleswapTokenListBalances, onUserInput, currencyA?.address])

  const handleMaxOutput = useCallback(() => {
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[currencyB?.address.toLowerCase()]
    const tokenBalance = !tokenBalanceFig ? 0 : tokenBalanceFig
    onUserInput(Field.OUTPUT, toSignificantDigits(tokenBalance, 6))
  }, [tangleswapTokenListBalances, onUserInput, currencyB?.address])

  useEffect(() => {
    const inputTokenDisabled =
      (!isNaN(Number(priceUpper)) && Number(priceUpper) < Number(price)) ||
      (!isNaN(Number(priceUpper)) && Number(priceLower) > Number(priceUpper))
    setInputTokenDisabledByMaxRange(Boolean(inputTokenDisabled))
  }, [priceUpper, price, priceLower])

  useEffect(() => {
    const outputTokenDisabled =
      !isNaN(Number(priceLower)) && Number(priceLower) > Number(price)
    setOutputTokenDisabledByMinRange(Boolean(outputTokenDisabled))
  }, [priceLower, price])
  useEffect(() => {
    if (!chainId || !decimalsA || !currencyA?.address) return
    fetchTangleFiatValue(currencyA?.address, decimalsA, chainId).then((res) => {
      const fiatAValue = !res ? 0 : res
      setToken0Fiat(fiatAValue)
    })
    if (!chainId || !decimalsB || !currencyB?.address) return
    fetchTangleFiatValue(currencyB?.address, decimalsB, chainId).then((res) => {
      const fiatBValue = !res ? 0 : res
      setToken1Fiat(fiatBValue)
    })
  }, [chainId, currencyA?.address, currencyB?.address, decimalsA, decimalsB])

  useEffect(() => {
    if (isInvalid([token0Fiat, formattedAmounts[Field.INPUT]])) return
    const fiatValue = token0Fiat * Number(formattedAmounts[Field.INPUT])
    setToken0USDFiat(fiatValue)
  }, [token0Fiat, formattedAmounts[Field.INPUT]])
  useEffect(() => {
    if (isInvalid([token1Fiat, formattedAmounts[Field.OUTPUT]])) return
    const fiatValue = token1Fiat * Number(formattedAmounts[Field.OUTPUT])
    setToken1USDFiat(fiatValue)
  }, [token1Fiat, formattedAmounts[Field.OUTPUT]])

  useEffect(() => {
    const tokenCompareBoolean =
      Number(token0InputValue) > Number(token0Balance) &&
      formattedAmounts[Field.OUTPUT]

    setToken0CompareBoolean(Boolean(tokenCompareBoolean))
  }, [
    token0InputValue,
    token0Balance,
    formattedAmounts[Field.OUTPUT],
    // account,
    // chainId,
    // tangleswapTokenListBalances,
    // formattedAmounts,
    // formattedAmounts[Field.INPUT],
  ])
  useEffect(() => {
    const tokenCompareBoolean =
      Number(token1InputValue) > Number(token1Balance) &&
      formattedAmounts[Field.OUTPUT]
    setToken1CompareBoolean(Boolean(tokenCompareBoolean))
  }, [
    token1InputValue,
    token1Balance,
    formattedAmounts[Field.OUTPUT],
    // account,
    // tangleswapTokenListBalances,
    // formattedAmounts,
    // formattedAmounts[Field.INPUT],
  ])

  // const token0CompareBoolean =
  //   Boolean(Number(token0InputValue) > Number(token0Balance)) &&
  //   Boolean(formattedAmounts[Field.OUTPUT])
  // const token1CompareBoolean =
  //   Boolean(Number(token1InputValue) > Number(token1Balance)) &&
  //   Boolean(formattedAmounts[Field.OUTPUT])

  //   showToken0Approval
  // setShowToken0Approval
  // showToken1Approval
  // setShowToken1Approval
  useEffect(() => {
    const token0Allowed = displayApprovalOne && !token0Approved

    setShowToken0Approval(Boolean(token0Allowed))
  }, [displayApprovalOne, token0Approved])
  useEffect(() => {
    const token1Allowed = displayApprovalTwo && !token1Approved

    setShowToken1Approval(Boolean(token1Allowed))
  }, [displayApprovalTwo, token1Approved])
  const handlePendingTxModal = () => {
    openWSCModal()
  }

  const [wrapData, setWrapData] = useState<IWrapMultipleData>(undefined)
  const [evmFn, setEvmFn] = useState<any>(undefined)
  const { isVisible: isWSCModalVisible, toggle: toggleWSCModal } =
    useIncreaseLiqModalControl()

  const isWSCReady = () =>
    isWSCConnected && currencyA?.address && currencyB?.address

  const fireWSCModal = () => {
    if (!isWSCReady()) return

    const tokenInData = []
    const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepIncreaseLiqFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      if (Number(formattedAmounts[Field.INPUT]) > 0) {
        tokenInData.push({
          address: currencyA?.address,
          name: currencyA?.name,
          symbol: currencyA?.symbol,
          chainId: chainId,
          decimals: currencyA?.decimals,
          logoURI: currencyA?.logoURI,
          amount: Number(formattedAmounts[Field.INPUT]),
          unit: getTokenUnit(currencyA?.l1Address),
          l1Address: currencyA?.l1Address,
          l1EncodedName: currencyA?.l1EncodedName,
          l1Decimals: currencyA?.l1Decimals,
        })
      }

      if (Number(formattedAmounts[Field.OUTPUT]) > 0) {
        tokenInData.push({
          address: currencyB?.address,
          name: currencyB?.name,
          symbol: currencyB?.symbol,
          chainId: chainId,
          decimals: currencyB?.decimals,
          logoURI: currencyB?.logoURI,
          amount: Number(formattedAmounts[Field.OUTPUT]),
          unit: getTokenUnit(currencyB?.l1Address),
          l1Address: currencyB?.l1Address,
          l1EncodedName: currencyB?.l1EncodedName,
          l1Decimals: currencyB?.l1Decimals,
        })
      }

      setWrapData({
        tokenIn: tokenInData,
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: fnFeedbackParams,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleWSCModal()
      }, 0)
    })
  }
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    toggleWSCModal()
    closeRemoveModal()
  }

  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    toggleWSCModal()
    closeRemoveModal()
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      toggleWSCModal()
      closeRemoveModal()
    }
  }
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    toggleWSCModal()
    setOpenModalSettings(false)
  }

  return (
    <>
      {(account && !currencyA) || poolsTokenALoading || poolsTokenBLoading ? (
        <IncreaseLiquidityLoading />
      ) : (
        <Body ref={bodyRef}>
          <>
            {showPendingModal ? (
              <>
                <LPConfirmationModal
                  modalRef={modalRef}
                  txError={txError}
                  amount0={formattedAmounts[Field.INPUT]}
                  amount1={formattedAmounts[Field.OUTPUT]}
                  currency0={currencyA?.symbol}
                  currency1={currencyB?.symbol}
                  pending={txpending}
                  closePendingModal={closePendingModal}
                  closeLPTransactionModal={closeLPTransactionModal}
                  closeModal={closeModal}
                  closeDismissModal={closeDismissModal}
                  link={txSent}
                />
              </>
            ) : null}
            {isWSCConnected && (
              <IncreaseLiqModal
                isVisible={isWSCModalVisible}
                toggleModal={toggleWSCModal}
                wrapData={wrapData}
                skipWallet={skipWallet}
                openWalletOverview={openWalletOverview}
                closeWSCContinue={closeWSCContinue}
                wscModalRef={wscModalRef}
                cancelTransaction={cancelTransaction}
              />
            )}
            {removeModalActive ? (
              <IncreaseLiquidityModal
                lpModalRef={lpModalRef}
                inputLogo={currencyA?.logoURI}
                outputLogo={currencyB?.logoURI}
                inputSymbol={currencyA?.symbol}
                outputSymbol={currencyB?.symbol}
                inputValue={formattedAmounts[Field.INPUT]}
                outputValue={formattedAmounts[Field.OUTPUT]}
                clickAdd={
                  isWSCConnected
                    ? () => {
                        fireWSCModal()
                        closeRemoveModal()
                      }
                    : _increaseLiquidity
                }
                closeRemoveModal={closeRemoveModal}
                lpBodyClick={lpBodyClick}
              />
            ) : null}

            <Cover account={Boolean(!account)} ref={swapRef}>
              {account ? (
                <>
                  {" "}
                  <LPNavigation>
                    <Link to={`/pools/${liquidityID}`}>
                      <NavTop>
                        <BackIcon />
                      </NavTop>
                    </Link>
                    <IconText>Increase Liquidity</IconText>
                    <SettingsWrap>
                      <SettingsWrapper
                        ref={closeRef}
                        onClick={closeSlippageDropdown}
                      >
                        <SettingsIcon onClick={showSlippageDropdown} />
                      </SettingsWrapper>
                      <>
                        <SlippageContainer
                          slippageTop={32}
                          showSlippage={showSlippage}
                          slippageRef={slippageRef}
                        />
                      </>
                    </SettingsWrap>
                  </LPNavigation>
                  <DappsDivider />
                  <LPPrice
                    inputPrice={String(uiPrice())}
                    inputCurrency={currencyA?.symbol}
                    outputCurrency={currencyB?.symbol}
                  />
                  <BoxTop>
                    <SizeTop>
                      {currencyA ? (
                        <>
                          <CurrencyTokenImages
                            token0Info={currencyA}
                            token1Info={currencyB}
                          />
                        </>
                      ) : (
                        <>
                          <PositionsLoadingPlaceholder />
                        </>
                      )}
                      {currencyA ? (
                        <SizeName>
                          {" "}
                          {currencyA?.symbol}/{currencyB?.symbol}
                        </SizeName>
                      ) : (
                        <>
                          <PositionsLoadingPlaceholder />
                        </>
                      )}
                    </SizeTop>
                    {Boolean(userTradingMode) === true ? (
                      <>
                        {" "}
                        {currencyA ? (
                          <LPTypeCover
                            onMouseEnter={mouseEnter}
                            onMouseLeave={mouseLeave}
                          >
                            {showHelpText && (
                              <>
                                <HoverBox
                                  left={68}
                                  text={
                                    (closedLP &&
                                      `Your position has zero liquidity, hence it's not earning LP fees.`) ||
                                    (!outOfRange &&
                                      !closedLP &&
                                      `The price of this pool is within your predefined range — your position is currently earning LP fees!`) ||
                                    (outOfRange &&
                                      !closedLP &&
                                      `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                                    //||
                                    // (outOfRange &&
                                    //   !closedLP &&
                                    //   `The price of this pool is outside your predefined range — your position is not currently earning LP fees.`)
                                  }
                                />
                              </>
                            )}
                            <LPType
                              positionInfo={currencyA}
                              closedLP={closedLP}
                              outOfRange={outOfRange}
                            />
                          </LPTypeCover>
                        ) : (
                          <>
                            {" "}
                            <PositionsLoadingPlaceholder />
                          </>
                        )}
                      </>
                    ) : null}
                  </BoxTop>
                  <LPInfo>
                    <InfoBottom>
                      <SwapToken>
                        {currencyA ? (
                          <>
                            <LiquidityAddTokenBox
                              showmax={true}
                              tokenBalance={tangleswapTokenListBalances}
                              fiatValue={token0USDFiat}
                              rangeDisabled={inputTokenDisabledByMaxRange}
                              tokenAddress={currencyA?.address}
                              tokenImage={currencyA?.logoURI}
                              tokenSymbol={currencyA?.symbol}
                              tokenName={currencyA?.name}
                              currency={currencyA?.address}
                              value={
                                (typedValue === "" &&
                                  String(formattedAmounts[Field.INPUT]) ===
                                    "0") ||
                                formattedAmounts[Field.INPUT] === "NaN" ||
                                (independentField === Field.OUTPUT &&
                                  Number(typedValue) === parseInt("0"))
                                  ? ""
                                  : formattedAmountsConvert[Field.INPUT]
                              }
                              onMax={handleMaxInput}
                              onUserInput={handleTypeInput}
                              id="lpCurrencyInput"
                            />
                            <LiquidityAddTokenBox
                              showmax={true}
                              tokenBalance={tangleswapTokenListBalances}
                              fiatValue={token1USDFiat}
                              rangeDisabled={outputTokenDisabledByMinRange}
                              tokenAddress={currencyB?.address}
                              tokenImage={currencyB?.logoURI}
                              tokenSymbol={currencyB?.symbol}
                              tokenName={currencyB?.name}
                              currency={currencyB?.address}
                              value={
                                (typedValue === "" &&
                                  String(formattedAmounts[Field.OUTPUT]) ===
                                    "0") ||
                                formattedAmounts[Field.OUTPUT] === "NaN" ||
                                (independentField === Field.INPUT &&
                                  Number(typedValue) === parseInt("0"))
                                  ? ""
                                  : formattedAmountsConvert[Field.OUTPUT]
                              }
                              onMax={handleMaxOutput}
                              onUserInput={handleTypeOutput}
                              id="lpCurrencyOutput"
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <PositionsLoadingPlaceholder />
                            <PositionsLoadingPlaceholder />
                            <PositionsLoadingPlaceholder />
                            <PositionsLoadingPlaceholder />
                          </>
                        )}
                      </SwapToken>

                      {currencyA ? (
                        <>
                          {" "}
                          <SwapButtons>
                            {account &&
                            (!window.ethereum || isSupportedNetwork) ? (
                              <>
                                <>
                                  {typedValue?.trim() === "" ||
                                  typedValue === "" ||
                                  typedValue === "0" ||
                                  (independentField === Field.INPUT &&
                                    Number(formattedAmounts[Field.INPUT]) ===
                                      parseInt("0")) ||
                                  (independentField === Field.OUTPUT &&
                                    Number(formattedAmounts[Field.OUTPUT]) ===
                                      parseInt("0")) ? (
                                    <>
                                      {" "}
                                      <SwapButton disabled={true}>
                                        Enter an Amount
                                      </SwapButton>
                                    </>
                                  ) : (
                                    <>
                                      {showToken0Approval ||
                                      showToken1Approval ? (
                                        <ApproveButtonWrapper>
                                          {showToken0Approval ? (
                                            <>
                                              <ApproveButton
                                                onClick={approveInputToken}
                                                disabled={Boolean(
                                                  token0Approving
                                                )}
                                              >
                                                {token0Approving ? (
                                                  <>
                                                    Approving{" "}
                                                    {currencyA?.symbol}
                                                    <Dots />
                                                  </>
                                                ) : (
                                                  <>
                                                    Approve {currencyA?.symbol}
                                                  </>
                                                )}
                                              </ApproveButton>
                                            </>
                                          ) : null}
                                          {showToken1Approval ? (
                                            <>
                                              <ApproveButton
                                                onClick={approveOutputToken}
                                                disabled={Boolean(
                                                  token1Approving
                                                )}
                                              >
                                                {token1Approving ? (
                                                  <>
                                                    Approving{" "}
                                                    {currencyB?.symbol}
                                                    <Dots />
                                                  </>
                                                ) : (
                                                  <>
                                                    Approve {currencyB?.symbol}
                                                  </>
                                                )}
                                              </ApproveButton>
                                            </>
                                          ) : null}
                                        </ApproveButtonWrapper>
                                      ) : (
                                        <>
                                          <>
                                            {Boolean(token0CompareBoolean) ||
                                            Boolean(token1CompareBoolean) ? (
                                              <>
                                                <>
                                                  {Boolean(
                                                    token0CompareBoolean
                                                  ) ? (
                                                    <>
                                                      {" "}
                                                      <SwapButton
                                                        disabled={true}
                                                      >
                                                        <>
                                                          Insufficient{" "}
                                                          {currencyA?.symbol}{" "}
                                                          Balance
                                                        </>
                                                      </SwapButton>
                                                    </>
                                                  ) : null}
                                                </>
                                                <>
                                                  {Boolean(
                                                    token1CompareBoolean
                                                  ) ? (
                                                    <>
                                                      {" "}
                                                      <SwapButton
                                                        disabled={true}
                                                      >
                                                        <>
                                                          Insufficient{" "}
                                                          {currencyB?.symbol}{" "}
                                                          Balance
                                                        </>
                                                      </SwapButton>
                                                    </>
                                                  ) : null}
                                                </>
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <SwapButton
                                                  disabled={Boolean(
                                                    isNaN(
                                                      Number(
                                                        formattedAmounts[
                                                          Field.OUTPUT
                                                        ]
                                                      )
                                                    )
                                                  )}
                                                  blockTransactionState={
                                                    !!blockTransactionState
                                                  }
                                                  onClick={
                                                    !!isWSCConnected &&
                                                    !!blockTransactionState
                                                      ? handlePendingTxModal
                                                      : showRemoveModal
                                                  }
                                                >
                                                  {!!blockTransactionState ? (
                                                    <>
                                                      <SwapButtonSpinner />
                                                    </>
                                                  ) : null}
                                                  {!!blockTransactionState ? (
                                                    <>
                                                      Continue Pending
                                                      Transaction
                                                    </>
                                                  ) : (
                                                    <>Preview</>
                                                  )}
                                                </SwapButton>
                                              </>
                                            )}
                                          </>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              </>
                            ) : null}
                          </SwapButtons>
                        </>
                      ) : (
                        <>
                          {" "}
                          <PositionsLoadingPlaceholder />
                          <PositionsLoadingPlaceholder />
                        </>
                      )}
                    </InfoBottom>
                  </LPInfo>
                </>
              ) : (
                <>
                  <IncreaseRemoveAuthLayout />
                  {account && !isSupportedNetwork ? (
                    <>
                      <ChainButton
                        onClick={() => addChainToMetamask(defaultChainId)}
                      >
                        Switch to Cardano
                      </ChainButton>
                    </>
                  ) : (
                    <>
                      <ConnectWalletButton />
                    </>
                  )}
                </>
              )}
            </Cover>
          </>
        </Body>
      )}
    </>
  )
}

export default IncreaseLiquidityContainer

const Body = styled.div`
  width: 100%;
  margin: 24px 0 0 0;
  position: relative;
`
const Cover = styled.div<LiquidityProps>`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  border-radius: 40px;

  padding: ${(props) =>
    props.account ? "12px 20px 24px 20px" : "12px 20px 0 20px"};
  min-height: 440px;
  /* overflow: hidden; */
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  border: 1px solid ${TangleColors.lighthover};
  position: relative;
  z-index: 3;
`

const SettingsIcon = styled(RiSettings2Line)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`
const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
`
const BackIcon = styled(BsArrowLeft)`
  height: 32px;
  width: 32px;
  color: ${TangleColors.white};
  opacity: 0.8;

  transition: 0.4s ease-in;

  :hover {
    color: ${TangleColors.lighthover};
  }
`
const IconText = styled(Header5Gothic)`
  margin: 0 8px;
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  transition: 0.4s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const LPInfo = styled.div`
  width: 100%;
  margin: -8px 0 0 0;
`

const InfoBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
`

const SizeTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  max-width: 340px;
  margin: 0;
`

const SizeName = styled(Subtitle)`
  /* width: 100%; */
  margin: 0 0 0 -8px;

  text-align: left;
`

const SettingsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  position: relative;
`

const LPTypeCover = styled.div`
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 50;
  cursor: pointer;
  align-items: flex-end;
`

const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`

const ChainButton = styled(ChainChangeButton)``
const SettingsWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: ${TangleColors.white};
`
const SwapToken = styled.div`
  width: 100%;
  position: relative;
  margin: 0;
`

const ConnectButton = styled(SwapConnectButton)``

const SwapButtons = styled.div`
  width: 100%;
  margin: 32px 0;
`

const SwapButton = styled(SwapTokenButton)`
  margin: 8px 0;
`

const ApproveButton = styled(SwapTokenButton)`
  background: ${TangleColors.heroCTA};
  margin: 10px 0;
  position: relative;
  z-index: 9;
`

const ApproveButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
