import React, { FC, Fragment } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body3 } from "styles/TextStyles"
import { motion, AnimatePresence } from "framer-motion"
interface HoverProps {
  text?: string
  textRef?: any
  linktext?: string
  link?: string
  showHelpText?: boolean
  onMouseEnter?: any
  onMouseLeave?: any
  left?: any
}
const LPHoverBox: FC<HoverProps> = (props) => {
  const {
    text,
    link,
    linktext,
    showHelpText,
    textRef,
    onMouseEnter,
    onMouseLeave,
  } = props
  return (
    <Fragment>
      <AnimatePresence>
        {showHelpText ? (
          <HoverBody
            onMouseEnter={onMouseEnter}
            ref={textRef}
            onMouseLeave={onMouseLeave}
            initial={{ scale: 0, y: 0 }}
            animate={{ scale: 1, y: 10 }}
            exit={{ scale: 0, y: 0 }}
            transition={{
              duration: 0.1,
              ease: "easeIn",
            }}
            style={{ transformOrigin: "top left" }}
          >
            {" "}
            <HoverCover>
              <HoverText>
                {text}{" "}
                <a href={`${link}`} target="_blank" rel="noopener noreferrer">
                  {linktext}
                </a>
              </HoverText>
            </HoverCover>
          </HoverBody>
        ) : null}
      </AnimatePresence>
    </Fragment>
  )
}

const HoverBody = styled(motion.div)`
  height: 104px;
  width: 280px;

  position: absolute;
  top: 72px;
  left: 68px;

  transition: 0.4s ease-in;
  background: ${TangleColors.tangleHarshBlack};
  border: 1px solid ${TangleColors.lighthover};
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 8px 16px;
  z-index: 99;
  color: ${TangleColors.white};
`
const HoverCover = styled(motion.div)<HoverProps>`
  height: 88px;
  width: 320px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    position: absolute;
    content: "";
    top: -24px;
    width: 16px;
    height: 16px;
    left: 20px;
    background-color: ${TangleColors.tangleHarshBlack};
    transform: translate(-50%, 50%) rotate(225deg);

    border-width: 2px;
    border-right-style: solid;
    border-bottom-style: solid;
    border-color: ${TangleColors.lighthover};
  }
`

const HoverText = styled(Body3)`
  text-align: left;

  a {
    color: ${TangleColors.lightPurple};
  }
`
export default LPHoverBox
