import { FC } from "react"
import styled from "styled-components"
import { GrClose } from "react-icons/gr"
import { TangleColors } from "styles/ColorStyles"
import { Body3, Caption, CaptionSmall } from "styles/TextStyles"
import { GoArrowUpRight } from "react-icons/go"

import { TickCircle } from "iconsax-react"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import { ContinueProgressButton } from "styles/ButtonStyles"

import { formatNumber } from "components/LaunchPad/utils/formatNumber"
import { useWSCContext } from "context/MilkomedaContext"
import { Chains } from "@tangleswap/sdk"
import { REQUIRED_FEE_ADA } from "constants/milkomeda/transaction"
import {
  getBridgeExplorerUrl,
  getEvmExplorerUrl,
} from "utils/milkomeda/transaction"

interface ProgressProps {
  pendingTx?: any
  completed?: boolean
  bodyCopy?: string
  explorerLink?: string
  txType?: any
  closeNotification?: (value?: any) => void
  number?: number
  blockTransactionState?: boolean
  hash?: string
}
const InProgress: FC<ProgressProps> = (props) => {
  const { completed, closeNotification, txType, hash, pendingTx } = props
  const { isWSCConnected, chainId } = useWSCContext()
  const { openWSCModal } = useOpenWSCActionModal()
  const handlePendingTxModal = () => {
    openWSCModal()
  }

  const type = pendingTx?.actionType
  const subtype = pendingTx?.actionSubType
  const swapAction = type === "Swap" && subtype === "exactInput"

  const addLiquidity = type === "LP" && subtype === "mint"
  const initializePool =
    type === "LP" && subtype === "createAndInitializePoolIfNecessary"
  const removeLP = type === "LP" && subtype === "decreaseLiquidity"
  const increaseLiquidity = type === "LP" && subtype === "increaseLiquidity"
  const collectLPFees = type === "LP" && subtype === "collect"

  const beginFarming = type === "Farm" && subtype === "deposit"
  const stopFarming = type === "Farm" && subtype === "withdraw"
  const harvestFarming = type === "Farm" && subtype === "collectRewards"

  const beginStaking = type === "VEStaking" && subtype === "stake"
  const stopStaking = type === "VEStaking" && subtype === "unstake"
  const reStartStaking = type === "VEStaking" && subtype === "restake"
  const addTokensStaking =
    beginStaking && pendingTx?.data?.evmFunction?.args?.[0] > 0
  const addDurationStaking =
    beginStaking && pendingTx?.data?.evmFunction?.args?.[1] > 0

  const participateAuction =
    type === "IHub" && (subtype === "commitTokens" || subtype === "commitEth")
  const finalizeAuction = type === "IHub" && subtype === "finalize"
  const claimFromAuction = type === "IHub" && subtype === "withdrawTokens"

  const tokenIn =
    txType !== "GasWrap"
      ? pendingTx?.data?.tokenIn
      : {
          symbol: "ADA",
          amount: REQUIRED_FEE_ADA,
        }
  const tokenOut = pendingTx?.data?.tokenOut
  const txToken =
    txType !== "Unwrap" &&
    (txType === "Wrap" ||
      txType === "GasWrap" ||
      swapAction ||
      addLiquidity ||
      initializePool ||
      increaseLiquidity ||
      beginFarming ||
      beginStaking ||
      addTokensStaking ||
      participateAuction)
      ? tokenIn || tokenOut
      : tokenOut || tokenIn

  const tokenAmount0 = txToken?.amount || txToken?.[0]?.amount
  const tokenSymbol0 = txToken?.symbol || txToken?.[0]?.symbol
  const tokenAmount1 = txToken?.[1]?.amount
  const tokenSymbol1 = txToken?.[1]?.symbol
  let tokenValues =
    !!tokenAmount1 && !!tokenSymbol1
      ? `${formatNumber(tokenAmount0)} ${tokenSymbol0} and ${formatNumber(
          tokenAmount1
        )} ${tokenSymbol1}`
      : `${formatNumber(tokenAmount0)} ${tokenSymbol0}`
  const tokenSymbols =
    !!tokenAmount1 && !!tokenSymbol1
      ? `${tokenSymbol0} and ${tokenSymbol1}`
      : !!tokenSymbol0
      ? `${tokenSymbol0}`
      : "token"
  if (!(tokenAmount0 > 0)) tokenValues = tokenSymbols

  const lpNftId = !(beginFarming || stopFarming)
    ? null
    : `LP Position #${pendingTx?.data?.tokenId}`

  const inProgressActionName = swapAction
    ? "Swap"
    : addLiquidity
    ? "Add Liquidity"
    : removeLP
    ? "Remove Liquidity"
    : increaseLiquidity
    ? "Increase Liquidity"
    : beginFarming
    ? "Begin Farming"
    : stopFarming
    ? "Stop Farming"
    : harvestFarming
    ? "Harvest Farm Rewards"
    : beginStaking && addTokensStaking && addDurationStaking
    ? "Begin Staking"
    : beginStaking && addTokensStaking
    ? "Increase Stake Amount"
    : beginStaking && addDurationStaking
    ? "Extend Stake Duration"
    : stopStaking
    ? "Withdraw Stake"
    : reStartStaking
    ? "Continue Staking"
    : harvestFarming
    ? "Harvest Farm Rewards"
    : harvestFarming
    ? "Harvest Farm Rewards"
    : initializePool
    ? "Initialize Liquidity"
    : participateAuction
    ? "Participate in Auction"
    : claimFromAuction
    ? "Claim Auction Tokens"
    : collectLPFees
    ? "Collect fees"
    : finalizeAuction
    ? "Finalize Auction"
    : null

  const explorerUrl =
    chainId === Chains.CARDANO || chainId === Chains.CARDANO_TEST
      ? `${getEvmExplorerUrl(chainId)}/tx/`
      : chainId === Chains.L1_CARDANO || chainId === Chains.L1_CARDANO_TEST
      ? `${getBridgeExplorerUrl(chainId)}/search/tx?query=`
      : null

  return (
    <ProgressWrapper>
      <ProgressCover>
        <CloseIconWrapper onClick={closeNotification}>
          <CloseIcon />
        </CloseIconWrapper>
        <ProgressTop>
          <ProgressLoader>
            {completed ? (
              <>
                {" "}
                <CompletedStateIcon />
              </>
            ) : (
              <>
                {" "}
                <SpinnerCombined
                  bigRadius={24}
                  smallRadius={16}
                  height={32}
                  width={32}
                />{" "}
              </>
            )}
            {completed ? (
              <>
                {" "}
                <TopTitle completed={completed}>Completed!</TopTitle>
              </>
            ) : (
              <>
                {" "}
                <TopTitle completed={completed}>In progress...</TopTitle>
              </>
            )}
          </ProgressLoader>
        </ProgressTop>
        <ProgressInfoBottom completed={completed}>
          <ProgressText completed={completed}>
            <>
              {completed ? (
                <>
                  {String(txType).trim() === "Wrap" ||
                  String(txType).trim() === "GasWrap" ? (
                    <>Wrapped {tokenValues} from Cardano L1 to L2!</>
                  ) : String(txType).trim() === "Approve" ? (
                    <>Approved {lpNftId ? "Farm" : tokenSymbols} allowance!</>
                  ) : String(txType).trim() === "Transact" ? (
                    <>
                      Completed {inProgressActionName ?? ""} transaction with{" "}
                      {lpNftId || tokenValues}!
                    </>
                  ) : String(txType).trim() === "Unwrap" ? (
                    <>Unwrapped {tokenValues} from Cardano L2 to L1!</>
                  ) : null}
                </>
              ) : (
                <>
                  {String(txType).trim() === "Wrap" ||
                  String(txType).trim() === "GasWrap" ? (
                    <>
                      Wrapping {tokenValues} from Cardano L1 to L2. Estimated
                      time: 2-4 min.
                    </>
                  ) : String(txType).trim() === "Approve" ? (
                    <>
                      Awaiting your signature for{" "}
                      {lpNftId ? "Farm" : tokenSymbols} approval.
                    </>
                  ) : String(txType).trim() === "Transact" ? (
                    <>
                      Awaiting your signature for {inProgressActionName ?? ""}{" "}
                      transaction with {lpNftId || tokenValues || ""}.
                    </>
                  ) : String(txType).trim() === "Unwrap" ? (
                    <>
                      Unwrapping {tokenValues} from Cardano L2 to L1. Estimated
                      time: 1-3 min.
                    </>
                  ) : null}
                </>
              )}
            </>
          </ProgressText>
        </ProgressInfoBottom>
        <CompletedBottom>
          <ProgressContinueButtonCover
            blockTransactionState={!(pendingTx?.isLastStep && completed)}
          >
            <ProgressMainButton onClick={handlePendingTxModal}>
              Continue
            </ProgressMainButton>
          </ProgressContinueButtonCover>

          <BottomRow completed={completed}>
            <BottomHref
              href={`${explorerUrl}${hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BottomText>View in explorer</BottomText>
              <BottomIcon />
            </BottomHref>
          </BottomRow>
        </CompletedBottom>
      </ProgressCover>
    </ProgressWrapper>
  )
}

const ProgressWrapper = styled.div`
  margin: 3px 0;
`
const ProgressCover = styled.div`
  width: 100%;
  position: relative;
  background: ${TangleColors.modalBG};
  border: 1px solid ${TangleColors.lighthover};
  padding: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  max-width: 350px;
  border-radius: 22px;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.swapBG};
    border: 1px solid ${TangleColors.white};
  }
`
const ProgressTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const ProgressLoader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 110px;
`
const TopTitle = styled(Caption)<ProgressProps>`
  color: ${(props) =>
    props.completed
      ? `${TangleColors.progressSuccess.middle}`
      : `${TangleColors.white}`};
  white-space: nowrap;
  margin: 0 0 0 10px;
`
const ProgressInfoBottom = styled.div<ProgressProps>`
  background: ${(props) =>
    props.completed
      ? `${TangleColors.progressSuccess.light}`
      : `${TangleColors.progressInfo.light}`};
  padding: 20px 12px;
  border-radius: 12px;
  margin: 14px 0;
  border: 1px solid
    ${(props) =>
      props.completed
        ? `${TangleColors.progressSuccess.main}`
        : `${TangleColors.progressInfo.main}`};
`
const ProgressText = styled(Body3)<ProgressProps>`
  color: ${(props) =>
    props.completed
      ? ` ${TangleColors.progressSuccess.main}`
      : ` ${TangleColors.progressInfo.main}`};
`
const CompletedStateIcon = styled(TickCircle)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.progressSuccess.middle};
`
const CloseIcon = styled(GrClose)`
  width: 18px;
  height: 18px;
  color: ${TangleColors.white};

  cursor: pointer;
  path {
    stroke: ${TangleColors.white};
    fill: ${TangleColors.white};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
const CloseIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: ${TangleColors.white};
  position: absolute;
  top: 24px;
  right: 8px;
  transform: translate(-50%, -50%);
  @media only screen and (max-width: 750px) {
    /* max-width: 80%; */
    right: 10px;
  }
`

const CompletedBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const BottomRow = styled.div<ProgressProps>`
  display: ${(props) => (props.completed ? `flex` : `none`)};
`
const BottomHref = styled.a`
  color: ${TangleColors.white};
  text-decoration: underline;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const BottomText = styled(CaptionSmall)``
const BottomIcon = styled(GoArrowUpRight)`
  width: 24px;
  height: 24px;
  color: ${TangleColors.white};
`
const ProgressContinueButtonCover = styled.div<ProgressProps>`
  width: 100%;
  margin: 0;
  max-width: 80px;
  opacity: ${(props) => (props.blockTransactionState ? "1" : "0")};
`
const ProgressMainButton = styled(ContinueProgressButton)`
  margin: 0;
  width: 100%;
`

export default InProgress
