import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body3, Subtitle } from "styles/TextStyles"

const LearnMore = () => {
  return (
    <Body>
      <Cover>
        <LearnLeftHref
          href=" https://docs.tangleswap.exchange/dapps/liquidity"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LeftMore>
            <BodyWrap>
              {" "}
              <MoreTitleRow>
                <Title>Learn about liquidity pools</Title>
              </MoreTitleRow>
              <MoreBodyText>
                Enjoy our interactive documentation and your fav creators on
                Youtube and Spotify.
              </MoreBodyText>
            </BodyWrap>
          </LeftMore>
        </LearnLeftHref>
        <LearnMiddleHref
          href="https://info.tangleswap.exchange"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MiddleMore>
            {" "}
            <BodyWrap>
              <MoreTitleRow>
                <Title>Top charts</Title>
              </MoreTitleRow>
              <MoreBodyText>Explore the TangleSwap analytics.</MoreBodyText>
            </BodyWrap>
          </MiddleMore>
        </LearnMiddleHref>
        <LearnRightHref
          href="https://whitepaper.tangleswap.exchange"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <RightMore>
            <BodyWrap>
              <MoreTitleRow>
                <Title>The Whitepaper</Title>
              </MoreTitleRow>
              <MoreBodyText>
                {" "}
                A 28-page blueprint to dive deep into the rabbit hole.
              </MoreBodyText>
            </BodyWrap>
          </RightMore>
        </LearnRightHref>
      </Cover>
    </Body>
  )
}

const Body = styled.div`
  margin: 24px 0 0 0;
  color: ${TangleColors.white};

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
  position: relative;
`
const Cover = styled.div`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 0.4s ease-in;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`
const LearnLeftHref = styled.a`
  width: 100%;
  margin: 8px auto;
  max-width: 600px;
  color: ${TangleColors.white};
  background: transparent;
  border: 1px solid transparent;
  margin: 0 8px 0 0;
  border-radius: 24px;
`
const LearnMiddleHref = styled.a`
  width: 100%;
  margin: 8px auto;
  max-width: 600px;
  color: ${TangleColors.white};
  background: transparent;
  border: 1px solid transparent;

  border-radius: 24px;
`

const LearnRightHref = styled.a`
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  color: ${TangleColors.white};
  background: transparent;
  transition: 0.4s ease-in;
  border-radius: 24px;
  margin: 0 0 0 8px;
`
const LeftMore = styled.div`
  border-radius: 24px;
  border: 1px solid ${TangleColors.grayLight};

  width: 100%;
  height: 160px;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px;
  transition: 0.4s ease-in;
  background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/dapp-LPool.png")
    no-repeat center center/cover;
  position: relative;
  overflow: hidden;
  ::after {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  transition: 0.4s ease-in;
  :hover {
    opacity: 0.8;
    border: 1px solid ${TangleColors.lighthover};
  }
`

const BodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
  transition: 0.4s ease-in;
`
const MiddleMore = styled.div`
  border-radius: 24px;
  border: 1px solid ${TangleColors.grayLight};
  height: 160px;

  width: 100%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  align-items: flex-start;

  background: url(" https://d3m3d54t409w7t.cloudfront.net/homepage/dapp-analytics.png")
    no-repeat center center/cover;
  position: relative;
  overflow: hidden;
  ::after {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  transition: 0.4s ease-in;
  :hover {
    opacity: 0.8;
    border: 1px solid ${TangleColors.lighthover};
  }
`
const RightMore = styled.div`
  border-radius: 24px;
  border: 1px solid ${TangleColors.grayLight};
  height: 160px;

  width: 100%;
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  align-items: flex-start;
  transition: 0.4s ease-in;
  :hover {
    opacity: 0.8;
    border: 1px solid ${TangleColors.lighthover};
  }
  background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/whitepaper-banner.png")
    no-repeat center center/cover;
  position: relative;
  overflow: hidden;
  ::after {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.75);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

const MoreTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;
`
const Title = styled(Subtitle)``
const MoreBodyText = styled(Body3)`
  margin: 8px 0;
`

export default LearnMore
