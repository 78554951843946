import {
  useState,
  Fragment,
  FC,
  useEffect,
  useRef,
  RefObject,
  useContext,
} from "react"
import {
  ActionButton,
  ButtonContainer,
  CurrentStepWrapper,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  TitleText,
  TitleTextContainer,
  WrapContainer,
} from "../styles"
import { useTangleship } from "utils/useTangleship"
import { useWSCContext } from "context/MilkomedaContext"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { ILaunchPadData, IWrapData } from "interfaces/wscSwap.interface"
import MultipleTokenAllowanceStep from "../transactionStepper/multiaction/MultipleTokenAllowanceStep"
import ModalTopIcon from "../component/ModalTopIcon"
import WrapStep from "../transactionStepper/WrapStep"
import { LOVELACE_UNIT } from "constants/milkomeda/transaction"
import TokenAllowanceStep from "../transactionStepper/TokenAllowanceStep"
import WSCModalCover from "../component/WSCModalCover"
import ModalActionState from "../ModalActionState"
import { useBackup } from "../component/Backup"
import { isInvalid } from "@tangleswap/sdk"
import { useCheckBalanceForGas } from "../component/GasCheck"
import WrapGas from "../transactionStepper/WrapGasStep"

interface ParticipateModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: ILaunchPadData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  skipWallet?: () => void
  openWalletOverview?: () => void
  cancelTransaction?: () => void
}

const ParticipateModal: FC<ParticipateModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    wscModalRef,
    closeWSCContinue,
    skipWallet,
    openWalletOverview,
    cancelTransaction,
  } = props

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [needApproveOrigin, setNeedApproveOrigin] = useState<boolean>(false)
  const [steps, setSteps] = useState<any[]>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const actionType = "IHub"
  const { tangleship } = useTangleship()
  const { account, chainId } = useWSCContext()
  const { data, backup } = useBackup(steps, actionType, setCurrentStep) as {
    data: ILaunchPadData
    backup: boolean
  }
  const { needGasWrap } = useCheckBalanceForGas()

  const gasWrap = needGasWrap && wrapData?.tokenIn?.unit !== LOVELACE_UNIT

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData, backup, data])

  useEffect(() => {
    if (!backup) {
      setCurrentStep(1)
    }

    const newSteps = []
    let stepCounter = 1

    if (gasWrap && !backup) {
      newSteps.push({
        number: stepCounter++,
        title: "Gas",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            data={wrapData}
            nextStep={nextStep}
            actionType={actionType}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Wrap",
      actionTitle: "Wrapping",
      component: (
        <WrapStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isBackup={backup}
          isUnwrapNeeded={false}
        />
      ),
    })

    if (needApproveOrigin) {
      newSteps.push({
        number: stepCounter++,
        title: "Approve",
        actionTitle: "Approving",
        component: (
          <TokenAllowanceStep
            nextStep={nextStep}
            tokenToCheck={backup ? data?.tokenIn : wrapData?.tokenIn}
            actionType={actionType}
            evmFunction={backup ? data?.evmFeedback : wrapData?.evmFunction}
            data={backup ? data : wrapData}
            auctionAddress={
              backup ? data?.auctionAddress : wrapData?.auctionAddress
            }
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          isBackup={backup}
          toggleModal={toggleModal}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
  }, [wrapData, needApproveOrigin, data, backup])

  const fetchAllowance = async (token, refetch = false) => {
    if (!account || !token?.address || isInvalid([token?.decimals])) return

    const auctionAddress = wrapData?.auctionAddress
    const storageKey = `wscAllowance${chainId}${account}${token.address}${auctionAddress}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      return Number(storageValue)
    }

    const res = await tangleship?.getAllowance(
      token.address,
      account,
      auctionAddress,
      true
    )
    const allowance = Number(res?._hex) / 10 ** token.decimals
    sessionStorage.setItem(storageKey, String(allowance))
    return allowance
  }

  const checkExecuteActionAllowance = async () => {
    if (!wrapData?.tokenIn) return
    if (wrapData?.tokenIn?.unit === LOVELACE_UNIT) {
      setNeedApproveOrigin(false)
      return
    }

    const allowance = await fetchAllowance(wrapData?.tokenIn)

    setNeedApproveOrigin(Number(allowance) < wrapData?.tokenIn?.amount)
  }

  useEffect(() => {
    if (!account || !chainId) return
    checkExecuteActionAllowance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapData?.tokenIn, account, chainId])

  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title="Participate in Auction"
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <ModalActionState
          skipWallet={skipWallet}
          openWalletOverview={openWalletOverview}
          actionTitle={steps[currentStep - 1]?.actionTitle}
        />

        <StepWrapper>
          {steps.map((step, index) => (
            <Fragment key={step.number}>
              <StepContainer>
                <StepButton
                  active={currentStep === step.number}
                  onClick={() => {
                    if (currentStep === step.number) return
                    setCurrentStep(step.number)
                  }}
                >
                  {step.number}
                </StepButton>
                <StepTitle active={currentStep === step.number}>
                  {step.title}
                </StepTitle>
              </StepContainer>
              {index < steps.length - 1 && <StepDivider />}
            </Fragment>
          ))}
        </StepWrapper>
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useParticipateModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default ParticipateModal
