import React, { FC } from "react"
import styled from "styled-components"
import BubbleTail from "./BubbleTail"
import { Body2 } from "styles/TextStyles"
import { TangleColors } from "styles/ColorStyles"
import { useLocation } from "react-router-dom"
import BubbleBody from "./BubbleBody"

interface BubbleProps {
  text?: string
}
const BottomBubble: FC<BubbleProps> = (props) => {
  const { text } = props

  const location = useLocation()

  // const dappsClassName =
  //   location.pathname === "/swap" || location.pathname === "/pools/add"
  const homeTrue = location.pathname === "/"
  const swapDappsClassName = location.pathname === "/swap"
  const lPAddDappsClassName = location.pathname === "/pools/add"
  const swapClass = !homeTrue && swapDappsClassName && "swapClass"
  const addLPClass = !homeTrue && lPAddDappsClassName && "addLPClass"
  return (
    <BubbleWrapper>
      <BubbleBody>
        <BubbleText>{text}</BubbleText>
      </BubbleBody>
      <TailWrapper className={swapClass || addLPClass || "otherDapps"}>
        <BubbleTail />
      </TailWrapper>
    </BubbleWrapper>
  )
}

export default BottomBubble
const BubbleWrapper = styled.div`
  position: relative;

  transition: 0.4s ease-in;
  &:hover {
    opacity: 0.65;
  }
  &:active {
    opacity: 0.85;
  }
  .otherDapps {
    top: 28px;
    right: -20px;
    z-index: -1;
  }
  .swapClass {
    top: 22px;
    right: -20px;
    z-index: -1;
  }
  .addLPClass {
    top: 22px;
    right: -20px;
    z-index: -1;
  }
`

const BubbleText = styled(Body2)`
  color: ${TangleColors.white};
`

const TailWrapper = styled.div`
  position: absolute;
`
