import { Chains } from "@tangleswap/sdk"
import { useAppSelector } from "store/hooks"

export default function TopTokens(): {
  topTokensData: any
} {
  const TangleswapTokenList = useAppSelector(
    (state) => state.tokenList.tokenList
  )

  const topTokens = {
    [Chains.CARDANO]: ["ADA", "0x6085C822B7A4c688D114468B1380a0Ed1873a0B3"],
    [Chains.CARDANO_TEST]: ["ADA"],
    [Chains.L1_CARDANO]: ["ADA", "0x6085C822B7A4c688D114468B1380a0Ed1873a0B3"],
    [Chains.L1_CARDANO_TEST]: ["ADA"],
    [Chains.SHIMMER]: [
      "0x1074010000000000000000000000000000000000", // Magic SMR
      "0x4638C9fb4eFFe36C49d8931BB713126063BF38f9", // ETH
      "0xb0119035d08CB5f467F9ed8Eae4E5f9626Aa7402", // BTC
      "0xeCE555d37C37D55a6341b80cF35ef3BC57401d1A", // USDC
      "0xa4f8C7C1018b9dD3be5835bF00f335D9910aF6Bd", // USDT
      "0x5dA63f4456A56a0c5Cb0B2104a3610D5CA3d48E8", // sIOTA
    ],
    [Chains.SHIMMER_TEST]: [
      "0x1074010000000000000000000000000000000000", // Magic SMR
      "0x9ee409aa1e2cd2d813e3b2471e00d5260a71b833", // USDT
      "0xd82c5eb4b816fcb380eea196342884782597c613", // USDC
      "0xdfc050688123a92d822afc050753db94f2dc6618", // MIOTA
      "0x1347b471986cf1b1cB63B8CCB2e8c41045C8dd45", // DAI
      "0x99df8EdFDE4E781dF6B954e18122ed39b8a5e440", // VOID
    ],
    [Chains.SEPOLIA_TEST]: [
      "ETH",
      "0x9Ef0C71EA63e29aB62d792245023707C9C3DD2A5", // USDT
      "0x07865c6e87b9f70255377e024ace6630c1eaa37f", // USDC
      "0x3D0AaF7780346243e74B6De1BfFdE88D5a865aEF", // DAI
      "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", // WETH
      "0x0eA185018F0cA3f8c545424d27bE300B22EE31D4", // VOID
    ],
  }

  const topTokensData = TangleswapTokenList?.filter((token) =>
    topTokens[token.chainId]
      .map((addr) => addr.trim().toLowerCase())
      .includes(token.address.trim().toLowerCase())
  )
  return { topTokensData }
}
