import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"

import StakeInput from "./StakeInput"

import ConnectWalletButton from "components/utils/ConnectWalletButton"
interface StakingProps {
  register?: any
  tokenUSDFiat?: any
  tokenBalanceAmount?: any
  tangleswapTokenListBalances?: any
  stakeDisable?: any
}
const StakingAuthenication: FC<StakingProps> = (props) => {
  const {
    register,
    tokenUSDFiat,
    tokenBalanceAmount,
    tangleswapTokenListBalances,
    stakeDisable,
  } = props

  return (
    <>
      <ActionColumn>
        <StakeTitle>
          <DarkPurpleText>VOID</DarkPurpleText> TO STAKE
        </StakeTitle>
        <StakeInput
          tokenUSDFiat={tokenUSDFiat}
          tokenBalanceAmount={tokenBalanceAmount}
          voidValue={0}
          dollarValue={0}
          duration={0}
          balance={0}
          register={register}
          tokenBalance={tangleswapTokenListBalances}
          stakeDisable={stakeDisable}
        />
      </ActionColumn>
      <InfoColumn>
        <ConnectContainer>
          <DisconnectedBackground />
          <ConnectBtn>
            {" "}
            <ConnectWalletButton />
          </ConnectBtn>
        </ConnectContainer>
      </InfoColumn>
    </>
  )
}
const DisconnectedBackground = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
`
const DarkPurpleText = styled(Subtitle)`
  color: ${TangleColors.lighthover};
`

const ActionColumn = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
  height: 100%;
`

const InfoColumn = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StakeTitle = styled(Subtitle)`
  display: flex;
  color: ${TangleColors.white};
  gap: 4px;
  font-weight: 600;
`

const ConnectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ConnectBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 320px;
  margin: 24px auto;
  color: ${TangleColors.white};
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s ease-in;
  z-index: 2;
`
export default StakingAuthenication
