import { useState, Fragment, FC, useEffect, RefObject } from "react"
import {
  ActionButton,
  ButtonContainer,
  CurrentStepWrapper,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  TitleText,
  TitleTextContainer,
  WrapContainer,
} from "../styles"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { IWrapData } from "interfaces/wscSwap.interface"
import ModalTopIcon from "../component/ModalTopIcon"
import UnwrapStep from "../transactionStepper/UnwrapStep"
import WSCModalCover from "../component/WSCModalCover"
import { useBackup } from "../component/Backup"
import WrapGas from "../transactionStepper/WrapGasStep"
import { useCheckBalanceForGas } from "../component/GasCheck"
import { LOVELACE_UNIT } from "constants/milkomeda/transaction"

interface WithdrawModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: IWrapData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  cancelTransaction?: () => void
}

const WithdrawModal: FC<WithdrawModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    wscModalRef,
    closeWSCContinue,
    cancelTransaction,
  } = props

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [steps, setSteps] = useState<any[]>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const actionType = "IHub"
  const { data, backup } = useBackup(steps, actionType, setCurrentStep) as {
    data: IWrapData
    backup: boolean
  }
  const { needGasWrap } = useCheckBalanceForGas()
  const gasWrap = needGasWrap && wrapData?.tokenIn?.unit !== LOVELACE_UNIT

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData, backup, data])

  useEffect(() => {
    if (!backup) {
      setCurrentStep(1)
    }

    const newSteps = []
    let stepCounter = 1

    if (gasWrap && !backup) {
      newSteps.push({
        number: stepCounter++,
        title: "Gas",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            nextStep={nextStep}
            actionType={actionType}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isBackup={backup}
          toggleModal={toggleModal}
          isLastStep={
            wrapData?.tokenOut?.symbol === "VOID" ||
            (backup && data?.tokenOut?.symbol === "VOID")
              ? true
              : false
          }
        />
      ),
    })

    if (
      wrapData?.tokenOut?.symbol !== "VOID" ||
      (backup && data?.tokenOut?.symbol !== "VOID")
    ) {
      newSteps.push({
        number: stepCounter++,
        title: "Unwrap",
        actionTitle: "Unwrapping",
        component: (
          <UnwrapStep
            data={backup ? data : wrapData}
            nextStep={nextStep}
            actionType={actionType}
            isLastStep={true}
            setIsComplete={setIsComplete}
          />
        ),
      })
    }

    setSteps(newSteps)
  }, [wrapData, data, backup, needGasWrap])

  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title="Withdraw Funds"
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <TitleTextContainer>
          <TitleText>{steps[currentStep - 1]?.actionTitle}</TitleText>
        </TitleTextContainer>

        <StepWrapper>
          {steps.map((step, index) => (
            <Fragment key={step.number}>
              <StepContainer>
                <StepButton
                  active={currentStep === step.number}
                  onClick={() => {
                    if (currentStep === step.number) return
                    setCurrentStep(step.number)
                  }}
                >
                  {step.number}
                </StepButton>
                <StepTitle active={currentStep === step.number}>
                  {step.title}
                </StepTitle>
              </StepContainer>
              {index < steps.length - 1 && <StepDivider />}
            </Fragment>
          ))}
        </StepWrapper>
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useWithdrawModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default WithdrawModal
