import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { HiOutlineArrowDown } from "react-icons/hi"
import { RiSettings2Line } from "react-icons/ri"
import styled from "styled-components"
import { ChainChangeButton, SwapTokenButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"
import { CaptionSmall, Header5Gothic } from "styles/TextStyles"
import TokenContainer from "./TokenContainer"
import { toast as SonnerToast } from "sonner"
import {
  FeeAmount,
  wrapAddr,
  isInvalid,
  calcPriceImpact,
  WRAPPED_ADDRESS,
  TANGLE_NFT_NIGHT_FALL_ADDRESS,
  Chains,
  Field,
  ZERO_ADDRESS,
  NATIVE_SYMBOL,
  MAX_UINT256,
  VOID_TOKEN,
  MULTISIG_ADDRESS,
} from "@tangleswap/sdk"
import SwapConfirmationModal from "components/confirmation/SwapConfirmationModal"
import SlippageContainer from "components/utils/SlippageContainer"
import { useSwapState } from "./utils/actions"
import { defaultChainId, useWSCContext } from "context/MilkomedaContext"
import TokensModal from "components/modals/tokenmodal/TokensModal"

import { toast as TangleToast } from "sonner"
import addChainToMetamask from "connect/switchChain"
import { PendingStateContext } from "context/PendingContext"
import { useDebounce } from "use-debounce"
import {
  convertNumber,
  getTestnetChains,
  toSignificantDigits,
} from "utils/toSignificant"
import useParsedQueryString from "utils/useParsedQueryString"
import SwapRoute from "./RoutingDiagram/SwapRoute"
import ConfirmSwapModal from "./components/ConfirmSwapModal"
import ConversionPanel from "./components/ConversionPanel"
import TokenConversion from "./components/TokenConversion"

import { useSwapActionHandlers } from "./utils/useSwapActionHandlers"

import CustomTokenModal from "components/CustomToken/CustomTokenModal"
import {
  fetchTokensOnChain,
  updateTangleswapTokenList,
} from "store/actions/TokenListAction"
import { updateUnknownToken } from "store/actions/UnknownTokenAction"
import { updateUnknownTokenField } from "store/actions/UnknownTokenFieldAction"
import SwapNightFallInfo from "./SwapNightFallInfo"

import { useIsSupportedNetwork } from "constants/hooks"
import SwapChart from "./chart/SwapChart"

import { Link, useSearchParams } from "react-router-dom"

import { useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store/hooks"

import { TangleTokenProps } from "./types"
import { fetchTangleFiatValue } from "utils/useTangleFiatValue"

import { useCurrencyLP } from "components/Liquidity/utils/hooks"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { useTangleship } from "utils/useTangleship"
import { updateUserReferralInfo } from "store/actions/UserReferralActions"
import { ethers } from "ethers"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"
import SwapModal, {
  useSwapModalControl,
} from "components/milkomeda/swap/SwapModal"

import { IWrapData } from "interfaces/wscSwap.interface"
import { getNativeAddress } from "components/Liquidity/AddLiquid/useNative"
import ConnectWalletButton from "components/utils/ConnectWalletButton"
import { Dots } from "components/utils/LoadingDots"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "./SwapButtonSpinner"
import { getTokenUnit } from "utils/milkomeda/tokenUnit"
import {
  openWSCProgressModal,
  showWSCInterface,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"
import { Chart21, ChartFail } from "iconsax-react"
import { updateTradingVoidModal } from "store/actions/VoidTradingActions"
import VoidErrorInfo from "./VoidErrorInfo"
import LedgerContext from "components/LedgerContext"

export const DEFAULT_SLIPPAGE = 0.995

export interface SwapChartProps {
  swapChartEnabled?: boolean
  blockTransactionState?: boolean
}

const SwapContainer = () => {
  const { account, chainId, l1ChainId, isWSCConnected } = useWSCContext()

  const { tangleship } = useTangleship()

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    swapInPutError,
    swapOutPutError,
  } = useSwapActionHandlers()
  const locationChange = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()
  const typedValue = useAppSelector((state) => state.swap.typedValue)
  const tangleswapUserReferral = useAppSelector(
    (state) => state.UserReferralReducer.tangleswapUserReferral
  )
  const nativeTokenAddress = getNativeAddress(l1ChainId)
  const parsedQs = useParsedQueryString()
  const wscModalRef = useRef<any>(null)
  const independentField = useAppSelector(
    (state) => state.swap.independentField
  )
  const deadlineMinute = useAppSelector(
    (state) => state.settings.deadlineMinute
  )
  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )

  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )
  const tangleswapTokenListOnChain = useAppSelector(
    (state) => state.tokenList.tokenList
  )

  const [tokenSwapPending, setTokenSwapPending] = useState<boolean>(false)
  const [confirmSwap, setConfirmSwap] = useState<boolean>(false)

  const [openCustomTokenModal, setOpenCustomTokenModal] =
    useState<boolean>(false)

  const [txpending, setTxPending] = useState<boolean>(false)

  const unknownTokenField = useAppSelector(
    (state) => state.unKnownTokenFieldReducer.tokenField
  )

  const unknownTokenSymbol = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenSymbol
  )
  const unKnownFieldTokenAddress = useAppSelector(
    (state) => state.unKnownTokenFieldReducer.tokenAddress
  )
  const unknownTokenDecimal = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenDecimal
  )
  const unknownTokenName = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenName
  )
  const unknownTokenl1Address = useAppSelector(
    (state) => state.unKnownTokenFieldReducer.unknownTokenl1Address
  )
  const unknownToken1Decimals = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownToken1Decimals
  )
  const unknownTokenl1EncodedName = useAppSelector(
    (state) => state.unKnownTokenReducer.unknownTokenl1EncodedName
  )

  const { openWSCModal } = useOpenWSCActionModal()
  const [txSent, setTxSent] = useState<any>(undefined)
  const [tokenModal, setTokenModal] = useState<boolean>(false)
  const [showSlippage, setShowSlippage] = useState<boolean>(false)

  const [swapChartEnabled, setSwapChartEnabled] = useState<boolean>(false)
  const [token0Fiat, setToken0Fiat] = useState<any>(undefined)
  const [token1Fiat, setToken1Fiat] = useState<any>(undefined)

  const [swapQuote, setSwapQuote] = useState<any>(undefined) // @dev: dont' delete!
  const [queuedQuote, setQueuedQuote] = useState<any>() // @dev: dont' delete!
  // const [inputValue, setInputValue] = useState<number | string>("")
  // const [outputValue, setOutputValue] = useState<number | string>("")
  const [poolLiquidities, setPoolLiquidities] = useState<{
    [key: number]: number
  }>()
  const [isUseWeb3ReactLoaded, setIsUseWeb3ReactLoaded] =
    useState<boolean>(false)
  setTimeout(() => {
    setIsUseWeb3ReactLoaded(true)
  }, 1500)

  const [slot0Price, setSlot0Price] = useState<any>()
  const [token0Allowance, setToken0Allowance] = useState<any>()
  const [isToken0AllowanceEnough, setIsToken0AllowanceEnough] =
    useState<any>(true)
  const [priceImpact, setPriceImpact] = useState<number | any>(undefined)
  const { setPendingTransaction } = useContext(PendingStateContext)
  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const [quoteRefetchCounter, setQuoteRefetchCounter] = useState<boolean>(null)
  const [tokenApproved, setTokenApproved] = useState<boolean | undefined>(
    undefined
  )
  const [limitSlug, setLimitSlug] = useState<any>(undefined)

  const [nightFallsOwnedByUser, setNightFallsOwnedByUser] = useState<number>(0)
  const [showWrapState, setShowWrapState] = useState<boolean | any>(undefined)
  const [showWrapText, setShowWrapText] = useState<string>("")
  const [routeIsSyncing, setRouteIsSyncing] = useState<boolean | undefined>(
    undefined
  )

  const [selectedCurrency, setSelectedCurrency] = useState("")
  const [activeField, setActiveField] = useState()
  const [otherCurrency, setOtherCurrency] = useState("")
  const [token0Balance, setToken0Balance] = useState<any>()
  const [swapError, setSwapError] = useState<boolean>(false)
  const [amountOut, setAmountOut] = useState<any>()
  const [amountIn, setAmountIn] = useState<any>()

  const [insufficientLiquidity, setInsufficientLiquidity] =
    useState<boolean>(false)
  const [openRouter, setOpenRouter] = useState<boolean>(false)

  const [tokenApproving, setTokenApproving] = useState<boolean>(false)
  const [invertedPrice, setInvertedPrice] = useState<boolean>(true)

  const lpModalRef = useRef<HTMLDivElement | any>()
  const customTokenRef = useRef<any>()
  const pendingModalRef = useRef<HTMLDivElement | any>()
  const modalRef = useRef<HTMLDivElement | any>()
  const slippageRef = useRef<HTMLDivElement | any>()
  const closeRef = useRef<HTMLDivElement | any>()
  const bodyRef = useRef<HTMLDivElement | any>()
  const swapRef = useRef<HTMLDivElement | any>()

  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  }: any = useSwapState()

  const inputCurrency = useCurrencyLP(inputCurrencyId, Field.INPUT)
  const outputCurrency = useCurrencyLP(outputCurrencyId, Field.OUTPUT)

  const currencies = useMemo(() => {
    return {
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }
  }, [inputCurrency, outputCurrency])

  const disableVoid = false

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
  const showSlippageDropdown = () => {
    setShowSlippage(!showSlippage)
  }

  const closeSlippageDropdown = (event: any) => {
    if (
      event?.target === closeRef.current &&
      event?.target !== slippageRef.current
    ) {
      setShowSlippage(false)
    }
  }

  const slippageTolerance = useAppSelector(
    (state) => state.settings.userSlippageTolerance
  )

  const slippageAdjustment =
    slippageTolerance === "auto"
      ? DEFAULT_SLIPPAGE
      : 1 - Number(slippageTolerance) / 10000 // slippageTolerance is e.g. "400" for "4%""

  const isInputTokenInValid = !swapInPutError
  const isOutputTokenInValid = !swapOutPutError

  const handleInputSelect = useCallback(
    (inputCurrency: any, field: any, otherAddress: any) => {
      setShowSlippage(false)
      setOtherCurrency(otherAddress)
      setSelectedCurrency(inputCurrency)
      setActiveField(field)
      setTokenModal(true)
      onCurrencySelection(Field.INPUT, inputCurrency, l1ChainId)
    },
    [onCurrencySelection]
  )

  const handleOutputSelect = useCallback(
    (outputCurrency: any, field: any, otherAddress: any) => {
      setShowSlippage(false)
      setOtherCurrency(otherAddress)
      setSelectedCurrency(outputCurrency)
      setActiveField(field)
      setTokenModal(true)
      onCurrencySelection(Field.OUTPUT, outputCurrency, l1ChainId)
    },
    [onCurrencySelection]
  )

  const fetchAllowanceToken0 = useCallback(
    async (refetch = false) => {
      if (
        !account ||
        !chainId ||
        !currencies[Field.INPUT]?.address ||
        !currencies[Field.INPUT]?.decimals
      )
        return

      if (isWSCConnected) {
        setToken0Allowance(Number(MAX_UINT256.toString())) // for L1 wallets, approval is handled in WSC modal
        return
      }

      const addr = currencies[Field.INPUT]?.address.toLowerCase()
      const storageKey = `swapAllowance${chainId}${account}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setToken0Allowance(storageValue)
        return
      }

      const res = await tangleship?.getAllowance(
        currencies[Field.INPUT]?.address,
        account,
        "Swap"
      )
      const allowance =
        Number(res?._hex) / 10 ** currencies[Field.INPUT]?.decimals

      sessionStorage.setItem(storageKey, allowance.toString())
      setToken0Allowance(allowance)
    },
    [
      account,
      currencies[Field.INPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      tangleship,
      chainId,
    ]
  )

  useEffect(() => {
    fetchAllowanceToken0()
  }, [fetchAllowanceToken0])

  const selectCurrency = useCallback(
    (field: any, currencyAddress: any) => {
      onCurrencySelection(field, currencyAddress, l1ChainId)
    },
    [onCurrencySelection, tangleswapTokenListOnChain]
  )
  const toggleRouter = () => {
    setOpenRouter(!openRouter)
  }

  useEffect(() => {
    const closeSlippageDropdownBody = (event) => {
      if (showSlippage && !slippageRef.current.contains(event.target)) {
        setShowSlippage(false)
      }
    }

    document.addEventListener("mousedown", closeSlippageDropdownBody)

    return () => {
      document.removeEventListener("mousedown", closeSlippageDropdownBody)
    }
  }, [showSlippage])

  const token0Selected = Boolean(isInputTokenInValid)
  const token1Selected = Boolean(isOutputTokenInValid)
  const actualPrice = useCallback(() => {
    if (independentField === Field.INPUT) {
      const adjustedAmountOut: any =
        Number(amountOut) / 10 ** currencies[Field.OUTPUT]?.decimals
      const result = convertNumber(adjustedAmountOut / typedValue)

      return result
    } else {
      const adjustedAmountIn: any =
        Number(amountIn) / 10 ** currencies[Field.INPUT]?.decimals
      const result = convertNumber(typedValue / adjustedAmountIn)
      return result
    }
  }, [
    independentField,
    amountIn,
    amountOut,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.OUTPUT]?.decimals,
    typedValue,
    account,
  ])
  useEffect(() => {
    const token0 = currencies[Field.INPUT]?.address
    const token1 = currencies[Field.OUTPUT]?.address
    const firstCheck =
      String(token0)?.trim()?.toLowerCase() ===
        String(NATIVE_SYMBOL[chainId])?.trim()?.toLowerCase() &&
      String(token1).trim()?.toLowerCase() ===
        String(WRAPPED_ADDRESS[chainId]).trim()?.toLowerCase()
    const secondCheck =
      String(token1)?.trim()?.toLowerCase() ===
        String(NATIVE_SYMBOL[chainId])?.trim()?.toLowerCase() &&
      String(token0).trim()?.toLowerCase() ===
        String(WRAPPED_ADDRESS[chainId]).trim()?.toLowerCase()

    const token0Show =
      String(currencies[Field.OUTPUT]?.address).trim()?.toLowerCase() ===
        String(WRAPPED_ADDRESS[chainId]).trim()?.toLowerCase() &&
      String(currencies[Field.INPUT]?.address).trim()?.toLowerCase() ===
        String(NATIVE_SYMBOL[chainId]).trim()?.toLowerCase()
    const token1Show =
      String(currencies[Field.INPUT]?.address).trim()?.toLowerCase() ===
        String(WRAPPED_ADDRESS[chainId]).trim()?.toLowerCase() &&
      String(currencies[Field.OUTPUT]?.address).trim()?.toLowerCase() ===
        String(NATIVE_SYMBOL[chainId]).trim()?.toLowerCase()

    if (firstCheck && token0Show) {
      setShowWrapState(true)
      setShowWrapText("Wrap")
    } else if (secondCheck && token1Show) {
      setShowWrapState(true)
      setShowWrapText("Unwrap")
    } else {
      setShowWrapState(false)
      setShowWrapText("")
    }
  }, [
    chainId,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    nativeTokenAddress,
  ])

  useEffect(() => {
    const storageKey = `nightFallsOwned${chainId}${account}`
    const storageValue = sessionStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      setNightFallsOwnedByUser(Number(storageValue))
      return
    }

    tangleship
      ?.checkNFTsOwnedFromCollection(
        account,
        TANGLE_NFT_NIGHT_FALL_ADDRESS[chainId]
      )
      ?.then((res) => {
        const nightFallsOwned = res || 0
        sessionStorage.setItem(storageKey, nightFallsOwned.toString())
        setNightFallsOwnedByUser(nightFallsOwned)
      })
  }, [account, chainId])

  const calc =
    independentField === Field.INPUT
      ? showWrapState
        ? typedValue
        : String(Number(typedValue) * actualPrice())
      : showWrapState
      ? typedValue
      : String(Number(typedValue) / actualPrice())

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue === "" ? "" : typedValue,
      [dependentField]:
        isNaN(Number(String(calc))) || Number.isNaN(calc)
          ? ""
          : String(Number(calc)) ?? "",
    }),
    [
      dependentField,
      independentField,
      calc,
      typedValue,
      account,
      chainId,
      currencies[Field.INPUT]?.address,
      currencies[Field.OUTPUT]?.address,
    ]
  )

  const formattedAmountsConvert = useMemo(
    () => ({
      [independentField]: typedValue === "" ? "" : typedValue,
      [dependentField]:
        isNaN(Number(String(calc))) || Number.isNaN(calc)
          ? ""
          : toSignificantDigits(calc, 6, true, 18) ?? "",
    }),
    [
      dependentField,
      independentField,
      calc,
      typedValue,
      account,
      chainId,
      currencies[Field.INPUT]?.address,
      currencies[Field.OUTPUT]?.address,
    ]
  )
  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )
  const isSupportedNetwork = useIsSupportedNetwork()
  const [debouncedTypedValue] = useDebounce(typedValue, 400)
  const [inputValue] = useDebounce(formattedAmounts[Field.INPUT], 200)
  const [outputValue] = useDebounce(formattedAmounts[Field.OUTPUT], 500)
  const supported = isSupportedNetwork
  const token0InputValue = toSignificantDigits(formattedAmounts[Field.INPUT], 6)

  const prepSwapFn = () => {
    const deadline = Math.floor(Date.now() / 1000 + Number(deadlineMinute))

    const fn = tangleship?.multiSwapFromRouter
    const fnParams = [
      currencies[Field.INPUT]?.address,
      currencies[Field.OUTPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      currencies[Field.OUTPUT]?.decimals,
      Number(formattedAmounts[Field.INPUT]),
      Number(formattedAmounts[Field.OUTPUT]),
      swapQuote,
      account,
      slippageAdjustment,
      deadline,
    ] as const

    const fnFeedbackParams = {
      firstTxParams: {
        chainId,
        token0Address: wrappedAddress0,
        token1Address: wrappedAddress1,
        token0Decimals: currencies[Field.INPUT]?.decimals,
        token1Decimals: currencies[Field.OUTPUT]?.decimals,
        amount0: formattedAmounts[Field.INPUT],
        amount1: formattedAmounts[Field.OUTPUT],
        account,
        slippageAdjustment,
        deadline,
      },
      showWrapState,
      chainId,
      tangleswapUserReferral,
      searchParams: searchParams.toString(),
    }
    const fnFeedback = (params, resOrReceipt) => {
      onUserInput(Field.INPUT, "")
      onUserInput(Field.OUTPUT, "")

      const testnetChains = getTestnetChains()
      const txHash = resOrReceipt?.hash || resOrReceipt?.transactionHash
      if (
        txHash &&
        !params.showWrapState &&
        !testnetChains.includes(params.chainId)
      ) {
        const firstTxParams = { ...params.firstTxParams, txHash }
        const referral = ethers.utils.isAddress(params.tangleswapUserReferral)
          ? params.tangleswapUserReferral
          : ZERO_ADDRESS

        tangleship
          ?.submitSwapReferral(
            firstTxParams.account,
            referral, // IMPORTANT: if user swapped with no referral, pass in a null address
            firstTxParams
          )
          .then((isReferred) => {
            // if (isReferred !== null) {
            dispatch(updateUserReferralInfo(undefined))
            const newSearchParams = new URLSearchParams(params.searchParams)
            newSearchParams.delete("referral")
            setSearchParams(newSearchParams)
            // }
          })
      }
    }

    return { fn, fnParams, fnFeedback, fnFeedbackParams }
  }
  // toggleSwapActionModal
  // handleSwap
  const handleSwap = () => {
    if (!supported) return

    if (
      !(Number(formattedAmounts[Field.INPUT]) > 0) ||
      !(Number(formattedAmounts[Field.OUTPUT]) > 0)
    )
      return

    setConfirmSwap(false)
    setTokenSwapPending(true)
    setTxPending(true)
    // toast.info("Waiting for wallet confirmation")
    setSwapError(false)
    if (!account) return

    const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepSwapFn()

    fn?.(...fnParams)?.then((res) => {
      fnFeedback(fnFeedbackParams, res) // Swap is the only transaction where the feedback is submitted after res, not receipt

      setTxPending(true)
      setPendingTransaction(true)
      setSwapError(false)

      if (res?.tx !== null) {
        setSwapError(false)
        setTxSent(res?.hash)
        setTxPending(false)
        setPendingTransaction(true)
        res.tx?.wait().then((receipt) => {
          setPendingTransaction(false)
          TangleToast.success("Transaction successful", {
            description: `Swap for ${currencies[Field.INPUT]?.symbol} and ${
              currencies[Field.OUTPUT]?.symbol
            } successful`,
          })
        })
      } else {
        setSwapError(true)
        setTxPending(false)
        setPendingTransaction(false)
        setSwapError(true)

        TangleToast.error("Transaction rejected", {
          description: `User rejected transaction`,
        })
      }
    })
  }

  useEffect(() => {
    if (
      isInvalid([
        token0Allowance,
        formattedAmounts[Field.INPUT],
        currencies[Field.INPUT]?.decimals,
      ])
    )
      return
    setIsToken0AllowanceEnough(
      token0Allowance > Number(formattedAmounts[Field.INPUT])
    )
  }, [
    token0Allowance,
    formattedAmounts[Field.INPUT],
    currencies[Field.INPUT]?.decimals,
  ])

  const prepareOrbitRouterParams = useCallback(() => {
    const token0Info = tangleswapTokenListOnChain?.find(
      (token: any) =>
        String(token.address)?.trim().toLowerCase() ===
          String(wrappedAddress0)?.trim().toLowerCase() &&
        String(token.chainId) === String(l1ChainId)
    )
    const token1Info = tangleswapTokenListOnChain?.find(
      (token: any) =>
        String(token.address)?.trim().toLowerCase() ===
          String(wrappedAddress1)?.trim().toLowerCase() &&
        String(token.chainId) === String(l1ChainId)
    )

    return {
      chainId,
      address0: currencies[Field.INPUT]?.address,
      address1: currencies[Field.OUTPUT]?.address,
      isExactInTrade: independentField === Field.INPUT,
      amountToTrade:
        independentField === Field.INPUT
          ? formattedAmounts[Field.INPUT]
          : formattedAmounts[Field.OUTPUT],
      recipient: account || MULTISIG_ADDRESS[chainId],
      slippage: slippageAdjustment,
      deadline: Math.floor(Date.now() / 1000 + Number(deadlineMinute)),
      token0Info,
      token1Info,
    }
  }, [
    chainId,
    currencies,
    independentField,
    formattedAmounts,
    account,
    slippageAdjustment,
    deadlineMinute,
  ])

  const address0 = currencies[Field.INPUT]?.address
  const address1 = currencies[Field.OUTPUT]?.address
  const wrappedAddress0 = wrapAddr(address0, chainId).tokenAddress
  const wrappedAddress1 = wrapAddr(address1, chainId).tokenAddress

  const isSameBlockhainOrder = isInvalid([address0, address1])
    ? null
    : wrapAddr(address0, chainId)?.tokenAddress?.toLowerCase() <
      wrapAddr(address1, chainId)?.tokenAddress?.toLowerCase()

  useEffect(() => {
    if (quoteRefetchCounter !== null) return
    setInterval(() => setQuoteRefetchCounter(!quoteRefetchCounter), 120 * 1000)
  }, []) // empty array to only run this once on mount

  // @dev: prefer to use Orbit Router (better swap routes). Minimal Router is a fallback method.
  const USE_ROUTER = { ORBIT: true, MINIMAL: false }
  // const USE_ROUTER = {
  //   ORBIT: chainId !== Chains.CARDANO,
  //   MINIMAL: chainId === Chains.CARDANO,
  // }

  const requestMinimalRouterQuote = (displayRefreshAnimation = true) => {
    if (poolLiquidities === undefined || !tangleswapTokenListOnChain) return
    const liq =
      poolLiquidities[FeeAmount.LOW] ||
      0 + poolLiquidities[FeeAmount.MEDIUM] ||
      0 + poolLiquidities[FeeAmount.HIGH]
    if (isInvalid([liq]) || !(liq > 0)) {
      if (!showWrapState) setInsufficientLiquidity(true)
      return
    }

    if (
      isInvalid([chainId, address0, address1, debouncedTypedValue], {
        null: true,
        emptyString: true,
      }) ||
      Number(formattedAmounts[Field.INPUT]) +
        Number(formattedAmounts[Field.OUTPUT]) <=
        0
    )
      return

    const params = prepareOrbitRouterParams()

    if (displayRefreshAnimation) setInsufficientLiquidity(false)
    if (showWrapState || (!displayRefreshAnimation && routeIsSyncing)) return
    if (displayRefreshAnimation) setRouteIsSyncing(true)

    const poolLiquidityValue =
      poolLiquidities !== undefined
        ? poolLiquidities
        : { "500": 0, "3000": 0, "10000": 0 }
    const bestFeeTier = Object.entries(poolLiquidityValue).reduce<{
      key: string
      value: number
    }>(
      (acc, [key, value]) => {
        return value > acc.value ? { key, value } : acc
      },
      { key: "", value: 0 }
    ).key

    const getQuote = params.isExactInTrade
      ? tangleship?.getAmountOut
      : tangleship?.getAmountIn

    const amountToTrade = params.isExactInTrade
      ? Number(formattedAmounts[Field.INPUT]) *
        10 ** currencies[Field.INPUT]?.decimals
      : Number(formattedAmounts[Field.OUTPUT]) *
        10 ** currencies[Field.OUTPUT]?.decimals
    if (!amountToTrade) return

    getQuote(
      params?.address0,
      params?.address1,
      BigInt(Math.floor(amountToTrade)),
      Number(bestFeeTier),
      BigInt(0)
    )?.then((res) => {
      const amountQuoted = params.isExactInTrade
        ? Number(res?.amountOut?._hex)
        : Number(res?.amountIn?._hex)

      if (displayRefreshAnimation) setRouteIsSyncing(false)

      const amount0 = params.isExactInTrade
        ? formattedAmounts[Field.INPUT]
        : amountQuoted / 10 ** currencies[Field.INPUT]?.decimals
      const amount1 = params.isExactInTrade
        ? amountQuoted / 10 ** currencies[Field.OUTPUT]?.decimals
        : formattedAmounts[Field.OUTPUT]

      if (res === undefined || res === null || amountQuoted === 0) {
        if (!showWrapState) setInsufficientLiquidity(true)
        setSwapQuote(undefined)
      } else if (amountQuoted > 0) {
        const quoteObject = {
          estimatedGasUsedUSD: 0.01,
          routeFeeTiers: [[Number(bestFeeTier)]],
          routePercentages: [100],
          routeTokenAddresses: [[wrappedAddress0, wrappedAddress1]],
          route: [wrappedAddress0, wrappedAddress1],
          routeWeiAmountsIn: [
            amount0 * 10 ** currencies[Field.INPUT]?.decimals,
          ],
          routeWeiAmountsOut: [
            amount1 * 10 ** currencies[Field.OUTPUT]?.decimals,
          ],
        }
        setSwapQuote(quoteObject)

        if (params.isExactInTrade) setAmountOut(amountQuoted)
        else setAmountIn(amountQuoted)
        if (slot0Price?.[bestFeeTier] === undefined) return
        setTimeout(() => {
          const priceImpact = calcPriceImpact(
            slot0Price[bestFeeTier],
            amount1 / amount0,
            !invertedPrice
          )

          if (priceImpact < 100) setPriceImpact(priceImpact)
        }, 1.5 * 1000)
      }
    })
  }

  const dispatch = useAppDispatch()

  // Define setter function
  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!USE_ROUTER.MINIMAL || !chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  // Set the reducer values
  useEffect(() => {
    if (
      isInvalid([
        address0,
        address1,
        FeeAmount,
        currencies[Field.INPUT]?.decimals,
        currencies[Field.OUTPUT]?.decimals,
      ])
    )
      return

    for (const fee of Object.values(FeeAmount)) {
      const params = {
        token0: address0,
        token1: address1,
        decimals0: currencies[Field.INPUT]?.decimals,
        decimals1: currencies[Field.OUTPUT]?.decimals,
        feeTier: fee,
      }
      checkSlot0Price(params)
    }
  }, [
    address0,
    address1,
    checkSlot0Price,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.OUTPUT]?.decimals,
  ])

  // Get the reducer values
  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  // Set the specific values
  useEffect(() => {
    if (!slot0PricesList) return

    const token0 = isSameBlockhainOrder ? wrappedAddress0 : wrappedAddress1
    const token1 = isSameBlockhainOrder ? wrappedAddress1 : wrappedAddress0

    for (const fee of Object.values(FeeAmount)) {
      const poolKey = `${token0}_${token1}_${fee}`.toLowerCase()
      const listItem = slot0PricesList?.[poolKey]
      if (!listItem) continue

      const uiAdjustedPrice = isSameBlockhainOrder
        ? listItem?.price
        : 1 / listItem?.price
      setSlot0Price((args: any) => ({ ...args, [fee]: uiAdjustedPrice }))
    }
  }, [wrappedAddress0, wrappedAddress1, isSameBlockhainOrder, slot0PricesList])

  const updatePoolLiquidities = () => {
    if (
      isInvalid([wrappedAddress0, wrappedAddress1, chainId]) ||
      !currencies[Field.INPUT]?.decimals ||
      !currencies[Field.OUTPUT]?.decimals
    )
      return

    for (const fee of Object.values(FeeAmount)) {
      tangleship
        ?.getPool(wrappedAddress0, wrappedAddress1, fee)
        ?.then((res) => {
          // setPoolAddress((args) => ({ ...args, [fee]: res }))

          if (!isInvalid([res])) {
            tangleship?.getPoolLiquidity(res)?.then((poolLiq) => {
              setPoolLiquidities((args) => ({
                ...args,
                [fee]: poolLiq === undefined ? null : Number(poolLiq?._hex),
              }))
            })
          }
        })
    }
  }

  useEffect(() => {
    if (!USE_ROUTER.MINIMAL) return
    updatePoolLiquidities()
  }, [
    wrappedAddress0,
    wrappedAddress1,
    chainId,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.OUTPUT]?.decimals,
  ])

  useEffect(() => {
    if (!USE_ROUTER.MINIMAL) return
    // Request quote from Minimal Router periodically:
    requestMinimalRouterQuote()
  }, [
    poolLiquidities,
    debouncedTypedValue,
    chainId,
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
  ])

  const requestOrbitRouterQuote = (displayRefreshAnimation = true) => {
    if (!tangleswapTokenListOnChain) return

    const params = prepareOrbitRouterParams()
    if (isInvalid(Object.values(params)) || !params.amountToTrade || !zeroTyped)
      return

    if (displayRefreshAnimation) setInsufficientLiquidity(false)
    if (showWrapState || (!displayRefreshAnimation && routeIsSyncing)) return
    if (displayRefreshAnimation) setRouteIsSyncing(true)

    tangleship
      ?.quoteFromOrbitRouter(
        params.address0,
        params.address1,
        params.isExactInTrade,
        params.amountToTrade, // NOTE: this amount needs to be human-readable format, not in wei
        params.recipient,
        params.slippage,
        params.deadline,
        params.token0Info,
        params.token1Info
      )
      ?.then((res) => {
        setQueuedQuote({
          quote: res,
          params,
          displayRefreshAnimation,
        })
      })
  }

  useEffect(() => {
    if (!USE_ROUTER.ORBIT || isInvalid([queuedQuote])) return
    const originalParams = { ...queuedQuote.params, deadline: null }
    const currentParams = { ...prepareOrbitRouterParams(), deadline: null }
    if (JSON.stringify(originalParams) !== JSON.stringify(currentParams)) return

    if (queuedQuote.displayRefreshAnimation) setRouteIsSyncing(false)
    setSwapQuote(queuedQuote.quote)

    if (queuedQuote.quote === null || queuedQuote.quote?.quote === 0) {
      if (!showWrapState) setInsufficientLiquidity(true)
      return
    } else if (queuedQuote.quote?.quote > 0) {
      if (queuedQuote.params.isExactInTrade)
        setAmountOut(
          queuedQuote.quote?.quote * 10 ** currencies[Field.OUTPUT]?.decimals
        )
      else
        setAmountIn(
          queuedQuote.quote?.quote * 10 ** currencies[Field.INPUT]?.decimals
        )
      setPriceImpact(queuedQuote.quote?.priceImpact)
    }
  }, [
    queuedQuote?.displayRefreshAnimation,
    queuedQuote?.params,
    queuedQuote?.quote,
    showWrapState,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.OUTPUT]?.decimals,
  ])

  useEffect(() => {
    if (!USE_ROUTER.ORBIT) return
    // Request quote from Orbit Router on user action:
    requestOrbitRouterQuote(true)
  }, [
    currencies[Field.INPUT]?.address,
    currencies[Field.OUTPUT]?.address,
    debouncedTypedValue,
    chainId,
  ])

  useEffect(() => {
    if (!USE_ROUTER.ORBIT) return
    // Update quote from Orbit Router periodically:
    requestOrbitRouterQuote(false)
  }, [quoteRefetchCounter])
  //

  const swapClick = () => {
    setConfirmSwap(true)
  }

  const changeInverted = () => {
    setInvertedPrice(!invertedPrice)
  }

  useEffect(() => {
    if (!typedValue) return
    setRouteIsSyncing(true)
  }, [typedValue])

  const convertPrice: number | any =
    typedValue !== debouncedTypedValue || routeIsSyncing
      ? undefined
      : invertedPrice
      ? Number(actualPrice())
      : Number(1 / actualPrice())

  const convertCurrency1 = invertedPrice
    ? currencies[Field.INPUT]?.symbol
    : currencies[Field.OUTPUT]?.symbol

  const convertCurrency2 = invertedPrice
    ? currencies[Field.OUTPUT]?.symbol
    : currencies[Field.INPUT]?.symbol

  const allowToken = () => {
    const supported = isSupportedNetwork
    if (!supported) return
    tangleship
      ?.approveContract(currencies[Field.INPUT]?.address, "Swap")
      ?.then((res) => {
        setTokenApproving(true)
        setTxPending(true)
        setPendingTransaction(true)

        if (res?.tx !== null) {
          setSwapError(false)
          setTxPending(false)
          setPendingTransaction(true)
          res.tx?.wait().then((receipt) => {
            fetchAllowanceToken0(true)

            setTokenApproving(false)
            setTokenApproved(true)
            setPendingTransaction(false)
            TangleToast.success("Transaction successful", {
              description: `Token approval for ${
                currencies[Field.INPUT]?.symbol
              } successful`,
            })
          })
        } else {
          setSwapError(true)
          setTxPending(false)
          setTokenApproving(false)
          setTokenApproved(false)
          setPendingTransaction(false)

          TangleToast.error("Transaction rejected", {
            description: `User rejected transaction`,
          })
          return
        }
      })
  }

  // useEffect(() => {
  //   if (address0 !== undefined && address1 !== undefined) {
  //     setSearchParams(`input=${address0}&output=${address1}`)
  //   }
  // }, [
  //   address0,
  //   address1,
  //   currencies[Field.INPUT]?.address,
  //   currencies[Field.OUTPUT]?.address,
  // ])
  const inputAddress = useMemo(
    () => currencies[Field.INPUT]?.address,
    [currencies]
  )
  const outputAddress = useMemo(
    () => currencies[Field.OUTPUT]?.address,
    [currencies]
  )

  useEffect(() => {
    if (inputAddress !== undefined && outputAddress !== undefined) {
      setSearchParams({ input: inputAddress, output: outputAddress })
    }
  }, [inputAddress, outputAddress, setSearchParams])

  const swapCurrencySwitch = () => {
    onSwitchTokens()
    const parsedInput = parsedQs?.input
    const parsedOutput = parsedQs?.output
    if (parsedInput !== undefined && parsedOutput !== undefined) {
      setSearchParams(`input=${parsedInput}&output=${parsedOutput}`)
    }
    if (parsedInput !== undefined && parsedOutput === undefined) {
      setSearchParams(`input=${parsedInput}`)
    }
    if (parsedInput === undefined && parsedOutput !== undefined) {
      setSearchParams(`output=${parsedOutput}`)
    }
  }

  // useEffect(() => {
  //   if (
  //     parsedQs.input === undefined &&
  //     parsedQs.output === undefined &&
  //     !isOutputTokenInValid
  //   ) {
  //     onCurrencySelection(Field.INPUT, `${nativeTokenAddress}`)
  //   }
  // }, [
  //   parsedQs?.input,
  //   parsedQs?.output,
  //   isInputTokenInValid,
  //   isOutputTokenInValid,
  //   nativeTokenAddress,
  //   isActive,

  // ])
  // useEffect(() => {
  //   if (parsedQs.input !== undefined) {
  //     onCurrencySelection(Field.INPUT, parsedQs.input, l1ChainId)
  //   }
  //   if (parsedQs.output !== undefined) {
  //     onCurrencySelection(Field.OUTPUT, parsedQs.output, l1ChainId)
  //   }
  // }, [
  //   parsedQs.input,
  //   parsedQs.output,
  //   isOutputTokenInValid,
  //   onCurrencySelection,
  //   Field,
  //   l1ChainId,
  //   nativeTokenAddress,
  // ])

  useEffect(() => {
    if (parsedQs.input !== undefined) {
      onCurrencySelection(Field.INPUT, parsedQs.input, l1ChainId)
    }
    if (parsedQs.output !== undefined) {
      onCurrencySelection(Field.OUTPUT, parsedQs.output, l1ChainId)
    }
  }, [
    parsedQs.input,
    parsedQs.output,
    isOutputTokenInValid,
    onCurrencySelection,
    l1ChainId,
  ])

  // useEffect(() => {
  //   if (!parsedQs.input && !parsedQs.output && !isOutputTokenInValid) {
  //     onCurrencySelection(Field.INPUT, `${nativeTokenAddress}`, l1ChainId)
  //   }
  // }, [
  //   parsedQs.input,
  //   parsedQs.output,
  //   isOutputTokenInValid,
  //   nativeTokenAddress,
  //   l1ChainId,
  //   Field,
  // ])
  useEffect(() => {
    if (!parsedQs.input && !parsedQs.output && !isOutputTokenInValid) {
      onCurrencySelection(Field.INPUT, nativeTokenAddress, l1ChainId)
    }
  }, [
    parsedQs.input,
    parsedQs.output,
    isOutputTokenInValid,
    nativeTokenAddress,
    l1ChainId,
  ])
  //TODO: SEVANE - DLEETE THIS AFTER NO BUGS REPORT ON SWAP LP token0
  // useEffect(() => {
  //   if (!tangleswapTokenListOnChain || tangleswapTokenListOnChain === null)
  //     return
  //   if (parsedQs.input === undefined || parsedQs.input === "undefined") return
  //   if (parsedQs.input !== undefined) {
  //     const inputTangleswapTokens = tangleswapTokenListOnChain?.filter(
  //       (token: TangleTokenProps) => {
  //         const tokenInfo =
  //           String(token?.address)?.trim()?.toLowerCase() ===
  //             String(parsedQs.input)?.trim()?.toLowerCase() &&
  //           token.chainId === l1ChainId
  //         return tokenInfo
  //       }
  //     )

  //     if (inputTangleswapTokens === undefined) return
  //     if (inputTangleswapTokens?.length > 0) {
  //       onCurrencySelection(Field.INPUT, parsedQs.input, l1ChainId)
  //     } else if (isUseWeb3ReactLoaded) {
  //       const tokenField = Field.INPUT
  //       const tokenAddress = parsedQs.input
  //       fetchTangleCurrency(
  //         String(parsedQs.input).trim()?.toLowerCase(),
  //         l1ChainId,
  //         tangleship
  //       )?.then((res: TangleTokenProps) => {
  //         if (
  //           res === undefined ||
  //           (res?.name === null &&
  //             res?.symbol === null &&
  //             res?.decimals === null)
  //         ) {
  //           setTokenModal(false)
  //           setOpenCustomTokenModal(false)
  //           return
  //         }

  //         dispatch(
  //           updateUnknownToken(
  //             res?.name,
  //             parsedQs.input,
  //             res?.symbol,
  //             res?.decimals,
  //             res?.l1Address,
  //             res?.l1Decimals,
  //             res?.l1EncodedName
  //           )
  //         )
  //         dispatch(updateUnknownTokenField(tokenAddress, tokenField))
  //         activateCustomModal()
  //       })
  //     }
  //   }
  // }, [
  //   isUseWeb3ReactLoaded,
  //   tangleswapTokenListOnChain,
  //   parsedQs.input,
  //   parsedQs.output,
  //   l1ChainId,
  //   isInputTokenInValid,
  //   locationChange.pathname,
  // ])
  useEffect(() => {
    if (!tangleswapTokenListOnChain) return
    if (parsedQs.input === undefined || parsedQs.input === "undefined") return

    const inputTangleswapTokens = tangleswapTokenListOnChain.filter(
      (token: TangleTokenProps) => {
        return (
          String(token?.address).trim().toLowerCase() ===
            String(parsedQs.input).trim().toLowerCase() &&
          token.chainId === l1ChainId
        )
      }
    )

    if (inputTangleswapTokens.length > 0) {
      onCurrencySelection(Field.INPUT, parsedQs.input, l1ChainId)
    } else if (isUseWeb3ReactLoaded) {
      if (!!isWSCConnected) return
      fetchTangleCurrency(
        String(parsedQs.input).trim().toLowerCase(),
        l1ChainId,
        tangleship
      ).then((res: TangleTokenProps) => {
        if (!res || !res.name || !res.symbol || !res.decimals) {
          setTokenModal(false)
          setOpenCustomTokenModal(false)
          return
        }

        dispatch(
          updateUnknownToken(
            res.name,
            parsedQs.input,
            res.symbol,
            res.decimals,
            res.l1Address,
            res.l1Decimals,
            res.l1EncodedName
          )
        )
        dispatch(updateUnknownTokenField(parsedQs.input, Field.INPUT))
        activateCustomModal()
      })
    }
  }, [
    isUseWeb3ReactLoaded,
    tangleswapTokenListOnChain,
    parsedQs.input,
    l1ChainId,
    isWSCConnected,
  ])
  //TODO: SEVANE - DLEETE THIS AFTER NO BUGS REPORT ON SWAP LP token1
  // useEffect(() => {
  //   if (!tangleswapTokenListOnChain || tangleswapTokenListOnChain === null)
  //     return
  //   if (parsedQs.output === undefined || parsedQs.output === "undefined") return

  //   const outputTangleswapTokens = tangleswapTokenListOnChain?.filter(
  //     (token: TangleTokenProps) => {
  //       const tokenInfo =
  //         String(token?.address)?.trim()?.toLowerCase() ===
  //           String(parsedQs.secondToken)?.trim()?.toLowerCase() &&
  //         token.chainId === l1ChainId
  //       return tokenInfo
  //     }
  //   )

  //   if (outputTangleswapTokens?.length > 0) {
  //     onCurrencySelection(Field.OUTPUT, parsedQs.output, l1ChainId)
  //   } else if (isUseWeb3ReactLoaded) {
  //     const tokenField = Field.OUTPUT
  //     const tokenAddress = parsedQs.output
  //     fetchTangleCurrency(
  //       String(parsedQs.secondToken).trim()?.toLowerCase(),
  //       l1ChainId,
  //       tangleship
  //     )?.then((res: TangleTokenProps) => {
  //       if (
  //         res === undefined ||
  //         (res?.name === null && res?.symbol === null && res?.decimals === null)
  //       ) {
  //         setTokenModal(false)
  //         setOpenCustomTokenModal(false)
  //         return
  //       }

  //       dispatch(
  //         updateUnknownToken(
  //           res?.name,
  //           parsedQs.output,
  //           res?.symbol,
  //           res?.decimals,
  //           res?.l1Address,
  //           res?.l1Decimals,
  //           res?.l1EncodedName
  //         )
  //       )
  //       dispatch(updateUnknownTokenField(tokenAddress, tokenField))
  //     })
  //     activateCustomModal()
  //   }
  // }, [
  //   isUseWeb3ReactLoaded,
  //   tangleswapTokenListOnChain,
  //   parsedQs.output,
  //   parsedQs.input,
  //   l1ChainId,
  //   isOutputTokenInValid,
  //   locationChange.pathname,
  // ])

  useEffect(() => {
    if (!tangleswapTokenListOnChain) return

    if (parsedQs.output === undefined || parsedQs.output === "undefined") return

    const outputTangleswapTokens = tangleswapTokenListOnChain.filter(
      (token: TangleTokenProps) => {
        return (
          String(token?.address).trim().toLowerCase() ===
            String(parsedQs.output).trim().toLowerCase() &&
          token.chainId === l1ChainId
        )
      }
    )

    if (outputTangleswapTokens.length > 0) {
      onCurrencySelection(Field.OUTPUT, parsedQs.output, l1ChainId)
    } else if (isUseWeb3ReactLoaded) {
      // Activate the modal immediately
      if (!!isWSCConnected) return
      activateCustomModal()

      fetchTangleCurrency(
        String(parsedQs.output).trim().toLowerCase(),
        l1ChainId,
        tangleship
      ).then((res: TangleTokenProps) => {
        if (!res || !res.name || !res.symbol || !res.decimals) {
          setTokenModal(false)
          setOpenCustomTokenModal(false)
          return
        }

        dispatch(
          updateUnknownToken(
            res.name,
            parsedQs.output,
            res.symbol,
            res.decimals,
            res.l1Address,
            res.l1Decimals,
            res.l1EncodedName
          )
        )
        dispatch(updateUnknownTokenField(parsedQs.output, Field.OUTPUT))
      })
    }
  }, [
    isUseWeb3ReactLoaded,
    tangleswapTokenListOnChain,
    parsedQs.output,
    l1ChainId,
    isWSCConnected,
  ])

  useEffect(() => {
    if (parsedQs.input !== undefined) {
      onCurrencySelection(Field.INPUT, parsedQs.input, l1ChainId)
    }
    if (parsedQs.secondToken !== undefined) {
      onCurrencySelection(Field.OUTPUT, parsedQs.output, l1ChainId)
    }
  }, [
    parsedQs.input,
    parsedQs.output,
    isOutputTokenInValid,
    onCurrencySelection,
    Field,
    l1ChainId,
    nativeTokenAddress,
  ])

  useEffect(() => {
    if (
      parsedQs.input === undefined &&
      parsedQs.output === undefined &&
      !isOutputTokenInValid
    ) {
      onCurrencySelection(Field.INPUT, `${nativeTokenAddress}`, l1ChainId)
    }
  }, [
    parsedQs.input,
    parsedQs.output,
    isOutputTokenInValid,
    onCurrencySelection,
    Field,
    l1ChainId,
    nativeTokenAddress,
  ])

  useEffect(() => {
    if (
      isInvalid([
        currencies[Field.INPUT]?.address,
        currencies[Field.INPUT]?.decimals,
      ])
    )
      return

    fetchTangleFiatValue(
      currencies[Field.INPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      chainId
    ).then((res) => {
      setToken0Fiat(res || 0)
    })
  }, [currencies[Field.INPUT]?.address, currencies[Field.INPUT]?.decimals])

  useEffect(() => {
    if (!account || !currencies[Field.INPUT]?.address) return
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[
        currencies[Field.INPUT]?.address.toLowerCase()
      ]
    const tokenBalance = !!tokenBalanceFig ? Number(tokenBalanceFig) : 0
    setToken0Balance(tokenBalance)
  }, [tangleswapTokenListBalances, account, currencies[Field.INPUT]?.address])

  const handleMaxInput = useCallback(() => {
    const tokenBalanceFig =
      tangleswapTokenListBalances?.[
        currencies[Field.INPUT]?.address.toLowerCase()
      ]
    const tokenBalance = !!tokenBalanceFig ? Number(tokenBalanceFig) : 0
    onUserInput(Field.INPUT, toSignificantDigits(tokenBalance, 6))
  }, [
    tangleswapTokenListBalances,
    currencies[Field.INPUT]?.address,
    onUserInput,
  ])

  const swapAmountOut =
    independentField === Field.INPUT
      ? Number(amountOut / 10 ** currencies[Field.OUTPUT]?.decimals)
      : Number(amountIn / 10 ** currencies[Field.INPUT]?.decimals)

  const lpBodyClick = (event: any) => {
    if (lpModalRef.current === event?.target) {
      setConfirmSwap(false)
    }
  }
  const closeSwapModal = () => {
    setConfirmSwap(false)
  }
  const closePendingModal = (event: any) => {
    if (pendingModalRef.current === event?.target) {
      setTokenSwapPending(false)
    }
  }
  const closeSwapPendingModal = () => {
    setTokenSwapPending(false)
  }
  const closeSwapSectionModal = () => {
    setTokenSwapPending(false)
    onUserInput(Field.INPUT, "")
    onUserInput(Field.OUTPUT, "")
  }

  const _minAfterSlippage = Number(outputValue) * slippageAdjustment

  const insufficientLiquidityLogic =
    ((formattedAmounts[Field.INPUT] &&
      Number(formattedAmounts[Field.INPUT]) > parseInt("0")) ||
      (formattedAmounts[Field.OUTPUT] &&
        Number(formattedAmounts[Field.OUTPUT]) > parseInt("0"))) &&
    Boolean(account && isSupportedNetwork) &&
    insufficientLiquidity !== undefined &&
    insufficientLiquidity === true

  const tokensSelected =
    Boolean(isOutputTokenInValid) && Boolean(isInputTokenInValid)

  const zeroTyped =
    (independentField === Field.INPUT || independentField === Field.OUTPUT) &&
    Number(typedValue) > 0 &&
    !isNaN(Number(typedValue))

  const valuesShown = inputValue || outputValue

  //close token selection modal
  const closeTokenModal = () => {
    setTokenModal(false)
  }

  const activateCustomModal = () => {
    setTokenModal(false)
    setOpenCustomTokenModal(true)
  }
  const closeCustomTokenModal = () => {
    setTokenModal(false)
    setOpenCustomTokenModal(false)
  }

  const userUnderstand = () => {
    const tokenInfo = {
      address: unKnownFieldTokenAddress,
      name: unknownTokenName,
      symbol: unknownTokenSymbol,
      chainId: l1ChainId,
      decimals: unknownTokenDecimal,
      logoURI: undefined,
      l1Address: unknownTokenl1Address,
      l1Decimals: unknownToken1Decimals,
      l1EncodedName: unknownTokenl1EncodedName,
    }

    const newToken = [...tangleswapTokenListOnChain, tokenInfo]

    dispatch(updateTangleswapTokenList(newToken, l1ChainId))

    setTokenModal(false)
    setOpenCustomTokenModal(false)

    const tokenField =
      unknownTokenField === Field.INPUT ? Field.INPUT : Field.OUTPUT
    onCurrencySelection(tokenField, unKnownFieldTokenAddress, l1ChainId)
    dispatch(fetchTokensOnChain(l1ChainId))
  }

  const closeCustomTokenModalWithBg = (event: any) => {
    if (customTokenRef.current === event?.target) {
      setTokenModal(false)
      setOpenCustomTokenModal(false)
    }
  }

  const closeModal = (event: any) => {
    if (modalRef.current === event?.target) {
      setTokenModal(false)
    }
  }

  const closeTokenCustomModal = () => {
    setOpenCustomTokenModal(false)
  }

  useEffect(() => {
    if (
      !chainId ||
      !currencies[Field.INPUT]?.decimals ||
      !currencies[Field.INPUT]?.address
    )
      return

    fetchTangleFiatValue(
      currencies[Field.INPUT]?.address,
      currencies[Field.INPUT]?.decimals,
      chainId
    )?.then((res) => {
      setToken0Fiat(res || 0)
    })
  }, [
    chainId,
    currencies[Field.INPUT]?.decimals,
    currencies[Field.INPUT]?.address,
  ])

  useEffect(() => {
    if (
      !chainId ||
      !currencies[Field.OUTPUT]?.decimals ||
      !currencies[Field.OUTPUT]?.address
    )
      return

    fetchTangleFiatValue(
      currencies[Field.OUTPUT]?.address,
      currencies[Field.OUTPUT]?.decimals,
      chainId
    )?.then((res) => {
      setToken1Fiat(!res ? 0 : res)
    })
  }, [
    chainId,
    currencies[Field.OUTPUT]?.decimals,
    currencies[Field.OUTPUT]?.address,
  ])

  const token0USDFiat = token0Fiat * Number(formattedAmounts[Field.INPUT])
  const token1USDFiat = token1Fiat * Number(formattedAmounts[Field.OUTPUT])
  const tokenCompareBoolean =
    formattedAmounts[Field.OUTPUT] &&
    Number(token0InputValue) > Number(token0Balance)

  const limitInputToken = !currencies[Field.INPUT]?.address
    ? ""
    : currencies[Field.INPUT]?.address
  const limitOutputToken = !currencies[Field.OUTPUT]?.address
    ? ""
    : currencies[Field.OUTPUT]?.address

  useEffect(() => {
    if (
      !!currencies[Field.INPUT]?.address &&
      !currencies[Field.OUTPUT]?.address
    ) {
      setLimitSlug(`/limit?input=${limitInputToken}`)
    } else if (
      !currencies[Field.INPUT]?.address &&
      !!currencies[Field.OUTPUT]?.address
    ) {
      setLimitSlug(`/limit?output=${limitOutputToken}`)
    } else if (
      !!currencies[Field.INPUT]?.address &&
      !!currencies[Field.OUTPUT]?.address
    ) {
      setLimitSlug(`/limit?input=${limitInputToken}&output=${limitOutputToken}`)
    }
  }, [currencies[Field.INPUT]?.address, currencies[Field.OUTPUT]?.address])

  const [swapData, setSwapData] = useState<IWrapData>(undefined)
  const { isVisible: isSwapModalVisible, toggle: toggleSwapModal } =
    useSwapModalControl()

  const isWSCReady = () =>
    isWSCConnected ||
    currencies[Field.INPUT]?.address ||
    currencies[Field.OUTPUT]?.address ||
    zeroTyped

  const fireSwapWSCModal = () => {
    if (!isWSCReady()) return

    const { fn, fnParams, fnFeedback, fnFeedbackParams } = prepSwapFn()
    if (!fn) return

    fn?.(...fnParams).then((res: any) => {
      setSwapData({
        tokenIn: {
          address: currencies[Field.INPUT]?.address,
          name: currencies[Field.INPUT]?.name,
          symbol: currencies[Field.INPUT]?.symbol,
          chainId: chainId,
          decimals: currencies[Field.INPUT]?.decimals,
          logoURI: currencies[Field.INPUT]?.logoURI,
          amount: Number(formattedAmounts[Field.INPUT]),
          unit: getTokenUnit(currencies[Field.INPUT]?.l1Address),
          l1Address: currencies[Field.INPUT]?.l1Address,
          l1EncodedName: currencies[Field.INPUT]?.l1EncodedName,
          l1Decimals: currencies[Field.INPUT]?.l1Decimals,
        },
        tokenOut: {
          address: currencies[Field.OUTPUT]?.address,
          name: currencies[Field.OUTPUT]?.name,
          symbol: currencies[Field.OUTPUT]?.symbol,
          chainId: chainId,
          decimals: currencies[Field.OUTPUT]?.decimals,
          logoURI: currencies[Field.OUTPUT]?.logoURI,
          amount: Number(formattedAmounts[Field.OUTPUT]),
          unit: getTokenUnit(currencies[Field.OUTPUT]?.l1Address),
          l1Address: currencies[Field.OUTPUT]?.l1Address,
          l1EncodedName: currencies[Field.OUTPUT]?.l1EncodedName,
          l1Decimals: currencies[Field.OUTPUT]?.l1Decimals,
        },
        evmFunction: res,
        evmFeedback: {
          function: fnFeedback,
          params: fnFeedbackParams,
        },
      })

      // @dev: don't delete, necessary for setWrapData to complete before firing the modal
      setTimeout(() => {
        toggleSwapModal()
      }, 0)
    })
  }

  const handlePendingTxModal = () => {
    openWSCModal()
  }

  const toggleSwapActionModal = () => {
    closeSwapModal()
    fireSwapWSCModal()
  }
  const closeWSCContinue = (e?: any) => {
    if (e?.target === wscModalRef?.current) {
      closeSwapModal()
      toggleSwapModal()
    }
  }
  const skipWallet = () => {
    dispatch(updateUserWSCProgress(null))
    closeSwapModal()
    toggleSwapModal()
  }

  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    SonnerToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    closeSwapModal()
    toggleSwapModal()
    setOpenModalSettings(false)
  }
  const openWalletOverview = () => {
    dispatch(showWSCInterface(true))
    toggleSwapModal()
  }
  const highPriceImpact =
    formattedAmounts[Field.INPUT] &&
    !isNaN(swapAmountOut) &&
    !isNaN(priceImpact) &&
    !Number.isNaN(priceImpact) &&
    parseFloat(priceImpact) > 10

  return (
    <>
      {openCustomTokenModal && !tokenModal ? (
        <>
          {" "}
          <CustomTokenModal
            userUnderstand={userUnderstand}
            selectCurrency={selectCurrency}
            customTokenRef={customTokenRef}
            closeTokenCustomModal={closeTokenCustomModal}
            closeCustomTokenModalWithBg={closeCustomTokenModalWithBg}
            closeCustomTokenModal={closeCustomTokenModal}
          />{" "}
        </>
      ) : null}
      {confirmSwap && (
        <>
          <ConfirmSwapModal
            showWrap={showWrapState}
            amountIn={String(formattedAmounts[Field.INPUT])}
            token0={currencies[Field.INPUT]?.address}
            token1={currencies[Field.OUTPUT]?.address}
            lpModalRef={lpModalRef}
            inputValue={toSignificantDigits(
              formattedAmounts[Field.INPUT],
              6,
              false
            )}
            outputValue={toSignificantDigits(
              formattedAmounts[Field.OUTPUT],
              6,
              false
            )}
            inputSymbol={currencies[Field.INPUT]?.symbol}
            inputLogo={currencies[Field.INPUT]?.logoURI}
            outputLogo={currencies[Field.OUTPUT]?.logoURI}
            outputSymbol={currencies[Field.OUTPUT]?.symbol}
            inputAddress={currencies[Field.INPUT]?.address}
            inputDecimals={currencies[Field.INPUT]?.decimals}
            outputAddress={currencies[Field.OUTPUT]?.address}
            outputDecimals={currencies[Field.OUTPUT]?.decimals}
            closeModal={closeSwapModal}
            lpBodyClick={lpBodyClick}
            price={convertPrice}
            confirmSwap={!!isWSCConnected ? toggleSwapActionModal : handleSwap}
            expectedAmount={_minAfterSlippage}
            expectedCurrency={currencies[Field.OUTPUT]?.symbol}
          />
        </>
      )}
      {isWSCConnected && (
        <SwapModal
          skipWallet={skipWallet}
          openWalletOverview={openWalletOverview}
          swapData={swapData}
          isVisible={isSwapModalVisible}
          toggleModal={toggleSwapModal}
          wscModalRef={wscModalRef}
          cancelTransaction={cancelTransaction}
          closeWSCContinue={closeWSCContinue}
        />
      )}
      {tokenSwapPending && (
        <>
          <SwapConfirmationModal
            modalRef={pendingModalRef}
            amount0={toSignificantDigits(
              convertNumber(formattedAmounts[Field.INPUT]),
              6,
              true
            )}
            closeSwapSectionModal={closeSwapSectionModal}
            amount1={toSignificantDigits(
              convertNumber(formattedAmounts[Field.OUTPUT]),
              6,
              true
            )}
            txError={swapError}
            currency0={currencies[Field.INPUT]?.symbol}
            currency1={currencies[Field.OUTPUT]?.symbol}
            pending={txpending}
            currencyToAdd={currencies[Field.OUTPUT]?.address}
            closePendingModal={closeSwapPendingModal}
            closeModal={closePendingModal}
            link={txSent}
          />
        </>
      )}
      {tokenModal ? (
        <>
          <TokensModal
            activateCustomModal={activateCustomModal}
            tokenOpenModal={tokenModal}
            activeField={activeField}
            selectCurrency={selectCurrency}
            selectedCurrency={selectedCurrency}
            otherCurrency={otherCurrency}
            modalRef={modalRef}
            closeTokenModal={closeTokenModal}
            closeModal={closeModal}
          />
        </>
      ) : null}
      <Body ref={bodyRef}>
        {/* <SwapTopCover>
          <ChartToggleCover>
            <ChartWrapper
              onClick={() => handleSwapChart(true)}
              swapChartEnabled={swapChartEnabled === true}
            >
              <ChartIconOn swapChartEnabled={swapChartEnabled === true} />
            </ChartWrapper>
            <ChartWrapper
              onClick={() => handleSwapChart(false)}
              swapChartEnabled={swapChartEnabled === false}
            >
              <ChartIconOff swapChartEnabled={swapChartEnabled === false} />
            </ChartWrapper>
          </ChartToggleCover>
        </SwapTopCover> */}
        <LedgerContext />
        <InterfaceWrapper swapChartEnabled={swapChartEnabled}>
          <SwapChartCover swapChartEnabled={swapChartEnabled}>
            <SwapChart
              token0={currencies[Field.INPUT]?.address}
              token1={currencies[Field.OUTPUT]?.address}
            />
          </SwapChartCover>
          <SwapContentCover swapChartEnabled={swapChartEnabled}>
            <SwapInterfaceCover>
              <Cover ref={swapRef}>
                <SwapTop>
                  <SwapRight />
                  <SwapTextDescriptionCover>
                    <SwapDescription>Swap</SwapDescription>

                    <Link to={limitSlug}>
                      <LimitDescription>Limit</LimitDescription>
                    </Link>
                  </SwapTextDescriptionCover>

                  <SettingsWrap>
                    <SettingsWrapper
                      ref={closeRef}
                      onClick={closeSlippageDropdown}
                    >
                      <SettingsIcon onClick={showSlippageDropdown} />
                    </SettingsWrapper>
                    <SlippageContainer
                      slippageTop={32}
                      showSlippage={showSlippage}
                      slippageRef={slippageRef}
                    />
                  </SettingsWrap>
                </SwapTop>
                {/* <DappsDivider /> */}
                <SwapToken>
                  <TokenContainer
                    showmax={true}
                    fiatValue={token0USDFiat}
                    tokenBalance={tangleswapTokenListBalances}
                    onMax={handleMaxInput}
                    balanceHidden={Boolean(isInputTokenInValid)}
                    tokenselected={Boolean(isInputTokenInValid)}
                    tokenAddress={currencies[Field.INPUT]?.address}
                    tokenImage={currencies[Field.INPUT]?.logoURI}
                    tokenSymbol={currencies[Field.INPUT]?.symbol}
                    tokenName={currencies[Field.INPUT]?.name}
                    currency={currencies[Field.INPUT]?.address}
                    onUserInput={handleTypeInput}
                    value={
                      (typedValue === "" &&
                        String(formattedAmounts[Field.INPUT]) === "0") ||
                      formattedAmounts[Field.INPUT] === "NaN" ||
                      (independentField === Field.OUTPUT &&
                        Number(typedValue) === parseInt("0"))
                        ? ""
                        : formattedAmountsConvert[Field.INPUT]
                    }
                    onCurrencySelect={() =>
                      handleInputSelect(
                        currencies[Field.INPUT]?.address,
                        Field.INPUT,
                        currencies[Field.OUTPUT]?.address
                      )
                    }
                    id="swapCurrencyInput"
                  />

                  <SwapLocation
                    disabled={
                      (account && !isSupportedNetwork) ||
                      Boolean(token0Selected && !token1Selected)
                    }
                    onClick={swapCurrencySwitch}
                  >
                    <LocationIcon />
                  </SwapLocation>
                  <TokenContainer
                    showmax={false}
                    fiatValue={token1USDFiat}
                    tokenBalance={tangleswapTokenListBalances}
                    balanceHidden={token1Selected}
                    tokenselected={Boolean(isOutputTokenInValid)}
                    onUserInput={handleTypeOutput}
                    value={
                      (typedValue === "" &&
                        String(formattedAmounts[Field.OUTPUT]) === "0") ||
                      formattedAmounts[Field.OUTPUT] === "NaN" ||
                      (independentField === Field.INPUT &&
                        Number(typedValue) === parseInt("0"))
                        ? ""
                        : formattedAmountsConvert[Field.OUTPUT]
                    }
                    loading={
                      Boolean(isOutputTokenInValid) && showWrapState
                        ? !showWrapState
                        : independentField === Field.INPUT &&
                          Boolean(routeIsSyncing)
                    }
                    tokenAddress={currencies[Field.OUTPUT]?.address}
                    tokenImage={currencies[Field.OUTPUT]?.logoURI}
                    tokenSymbol={currencies[Field.OUTPUT]?.symbol}
                    tokenName={currencies[Field.OUTPUT]?.name}
                    currency={currencies[Field.OUTPUT]?.address}
                    onCurrencySelect={() =>
                      handleOutputSelect(
                        currencies[Field.OUTPUT]?.address,
                        Field.OUTPUT,
                        currencies[Field.INPUT]?.address
                      )
                    }
                    label={
                      independentField === Field.INPUT ? "To (estimated)" : "To"
                    }
                    id="swapCurrencyOutput"
                  />
                </SwapToken>
                {zeroTyped &&
                Boolean(tokensSelected) &&
                !!valuesShown &&
                !insufficientLiquidityLogic &&
                !showWrapState ? (
                  <>
                    <TokenConversion
                      pending={!swapQuote || routeIsSyncing}
                      isNaN={
                        Boolean(Number.isNaN(convertPrice)) ||
                        Boolean(isNaN(convertPrice))
                      }
                      switchPrice={changeInverted}
                      price={convertPrice}
                      currencyOne={convertCurrency1}
                      currencyTwo={convertCurrency2}
                    />
                  </>
                ) : null}
                {!!swapQuote && zeroTyped && formattedAmounts[Field.OUTPUT] ? (
                  <>
                    <SwapRoute
                      showRoute={openRouter}
                      toggleRouter={toggleRouter}
                      swapQuote={swapQuote}
                      estimatedGasUsedUSD={swapQuote?.estimatedGasUsedUSD} //done
                      routeFeeTiers={swapQuote?.routeFeeTiers}
                      routePercentages={swapQuote?.routePercentages}
                      routeTokenAddresses={swapQuote.routeTokenAddresses}
                    />
                  </>
                ) : null}
                {!!disableVoid ? (
                  <>
                    <VoidErrorInfo />
                  </>
                ) : null}
                <SwapButtons>
                  <>
                    {account ? (
                      <>
                        {isSupportedNetwork ? (
                          <>
                            {Boolean(isOutputTokenInValid) ? (
                              <>
                                {typedValue !== "" ? (
                                  <>
                                    <>
                                      {isToken0AllowanceEnough ||
                                      Boolean(tokenApproved) ? (
                                        <>
                                          {typedValue === "" ||
                                          !zeroTyped ||
                                          (independentField === Field.INPUT &&
                                            Number(
                                              formattedAmounts[Field.INPUT]
                                            ) === parseInt("0")) ||
                                          (independentField === Field.OUTPUT &&
                                            Number(
                                              formattedAmounts[Field.OUTPUT]
                                            ) === parseInt("0")) ? (
                                            <>
                                              <SwapButton disabled={true}>
                                                <> Enter an amount</>
                                              </SwapButton>
                                            </>
                                          ) : (
                                            <>
                                              <>
                                                {tokenCompareBoolean ? (
                                                  <>
                                                    {tokenCompareBoolean ? (
                                                      <>
                                                        <SwapButton
                                                          disabled={true}
                                                        >
                                                          <>
                                                            Insufficient{" "}
                                                            {
                                                              currencies[
                                                                Field.INPUT
                                                              ]?.symbol
                                                            }
                                                            {""} Balance
                                                          </>
                                                        </SwapButton>
                                                      </>
                                                    ) : null}
                                                  </>
                                                ) : (
                                                  <>
                                                    {Boolean(!showWrapState) &&
                                                    (insufficientLiquidityLogic ||
                                                      convertPrice === 0) ? (
                                                      <>
                                                        {" "}
                                                        <SwapWrapperCover>
                                                          <SwapCoverText>
                                                            {" "}
                                                            You can instead
                                                            provide liquidity
                                                            for this pool!
                                                          </SwapCoverText>
                                                          <SwapLiquidityWrapper>
                                                            <AddLiqudiityButton>
                                                              <Link
                                                                to={`/pools/add?firstToken=${address0}&secondToken=${address1}`}
                                                              >
                                                                <AddLPButtonText>
                                                                  Add Liquidity
                                                                </AddLPButtonText>
                                                              </Link>
                                                            </AddLiqudiityButton>
                                                          </SwapLiquidityWrapper>
                                                        </SwapWrapperCover>
                                                        <SwapButton
                                                          disabled={true}
                                                        >
                                                          Insufficient Liquidity
                                                          for this Trade
                                                        </SwapButton>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <SwapButton
                                                          onClick={
                                                            !!isWSCConnected &&
                                                            !!blockTransactionState
                                                              ? handlePendingTxModal
                                                              : swapClick
                                                          }
                                                          blockTransactionState={
                                                            !!blockTransactionState
                                                          }
                                                          disabled={
                                                            !!disableVoid ||
                                                            !formattedAmounts[
                                                              Field.INPUT
                                                            ] ||
                                                            !formattedAmounts[
                                                              Field.OUTPUT
                                                            ] ||
                                                            !account ||
                                                            !isSupportedNetwork ||
                                                            (!showWrapText &&
                                                              routeIsSyncing)
                                                          }
                                                          className={`${
                                                            highPriceImpact
                                                              ? "anyway"
                                                              : null
                                                          }`}
                                                        >
                                                          {!!blockTransactionState ? (
                                                            <>
                                                              <SwapButtonSpinner />
                                                            </>
                                                          ) : null}
                                                          {!!blockTransactionState ? (
                                                            <>
                                                              Continue Pending
                                                              Transaction
                                                            </>
                                                          ) : (
                                                            <>
                                                              {" "}
                                                              {highPriceImpact
                                                                ? // || Number(priceImpact) >1
                                                                  "Swap despite high Price Impact"
                                                                : showWrapState
                                                                ? showWrapText
                                                                : "Swap"}
                                                            </>
                                                          )}
                                                        </SwapButton>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <>
                                            {tokenCompareBoolean ? (
                                              <>
                                                {tokenCompareBoolean && (
                                                  <>
                                                    {" "}
                                                    <SwapButton disabled={true}>
                                                      <>
                                                        Insufficient{" "}
                                                        {
                                                          currencies[
                                                            Field.INPUT
                                                          ]?.symbol
                                                        }
                                                        {""} Balance
                                                      </>
                                                    </SwapButton>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {!zeroTyped ? (
                                                  <>
                                                    <SwapButton disabled={true}>
                                                      Enter an amount
                                                    </SwapButton>
                                                  </>
                                                ) : (
                                                  !isToken0AllowanceEnough && (
                                                    <>
                                                      {" "}
                                                      <SwapButton
                                                        onClick={allowToken}
                                                        disabled={Boolean(
                                                          tokenApproving
                                                        )}
                                                      >
                                                        {tokenApproving ? (
                                                          <>
                                                            Approving{" "}
                                                            {
                                                              currencies[
                                                                Field.INPUT
                                                              ]?.symbol
                                                            }
                                                            <Dots />
                                                          </>
                                                        ) : (
                                                          <>
                                                            Approve{" "}
                                                            {
                                                              currencies[
                                                                Field.INPUT
                                                              ]?.symbol
                                                            }
                                                          </>
                                                        )}
                                                      </SwapButton>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            )}
                                          </>
                                        </>
                                      )}
                                    </>
                                  </>
                                ) : (
                                  <>
                                    <SwapButton
                                      disabled={
                                        Boolean(!isOutputTokenInValid) ||
                                        formattedAmounts[Field.INPUT] === "" ||
                                        (formattedAmounts[Field.OUTPUT] ===
                                          "" &&
                                          true)
                                      }
                                    >
                                      {Boolean(!isOutputTokenInValid) ||
                                      Boolean(!isInputTokenInValid) ? (
                                        <>Select a token</>
                                      ) : (
                                        <> Enter an amount</>
                                      )}
                                    </SwapButton>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {" "}
                                <SwapButton disabled={true}>
                                  Select a token
                                </SwapButton>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <ChainButton
                              onClick={() => addChainToMetamask(defaultChainId)}
                            >
                              Switch to Cardano
                            </ChainButton>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {Boolean(!showWrapState) &&
                        (insufficientLiquidityLogic || convertPrice === 0) ? (
                          <>
                            <SwapButton disabled={true}>
                              Insufficient Liquidity for this Trade
                            </SwapButton>
                          </>
                        ) : (
                          <>
                            {!isSupportedNetwork ? (
                              <>
                                <ChainButton
                                  onClick={() =>
                                    addChainToMetamask(defaultChainId)
                                  }
                                >
                                  Switch to Cardano
                                </ChainButton>
                              </>
                            ) : (
                              <>
                                <ConnectWalletButton />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </SwapButtons>
                {zeroTyped &&
                tokensSelected &&
                valuesShown &&
                inputValue &&
                outputValue &&
                !insufficientLiquidityLogic &&
                !showWrapState &&
                nightFallsOwnedByUser > 0 ? (
                  <>
                    {" "}
                    <SwapNightFallInfo />
                  </>
                ) : null}
              </Cover>
            </SwapInterfaceCover>
          </SwapContentCover>
          <ConversionWrapper>
            {zeroTyped &&
            tokensSelected &&
            valuesShown &&
            inputValue &&
            outputValue &&
            !insufficientLiquidityLogic &&
            !showWrapState ? (
              <>
                {" "}
                <ConversionPanel
                  loading={Boolean(routeIsSyncing)}
                  priceImpactAmount={priceImpact}
                  amountIn={inputValue}
                  amountOut={outputValue}
                  token0={currencies[Field.INPUT]?.address}
                  token1={currencies[Field.OUTPUT]?.address}
                  symbolToken0={currencies[Field.INPUT]?.symbol}
                  symbolToken1={currencies[Field.OUTPUT]?.symbol}
                  slippageAdjustment={slippageAdjustment}
                />
              </>
            ) : null}
          </ConversionWrapper>
        </InterfaceWrapper>
      </Body>
    </>
  )
}

const Body = styled.div`
  width: 100%;
  position: relative;
  margin: 24px auto;
  max-width: 600px;
  padding: 0 12px;
`
const Cover = styled.div`
  max-width: 620px;
  width: 100%;
  /* margin: 24px auto 0 auto; */
  margin: 0 auto;
  position: relative;
  z-index: 3;
  border: 1px solid ${TangleColors.lighthover};
  background: ${TangleColors.swapBG};
  border-radius: 40px;
  padding: 8px 20px;
  color: ${TangleColors.white};
`
const SettingsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* width: 100%; */
  position: relative;
`
const SwapTop = styled.div`
  width: 100%;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  margin: 15.5px 0 10px 0;
`
const SwapTextDescriptionCover = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const SwapDescription = styled(Header5Gothic)`
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  margin: 0;
`
const LimitDescription = styled(Header5Gothic)`
  color: ${TangleColors.grayDark};
  text-transform: capitalize;

  text-align: center;
  margin: 0 0 0 24px;
  cursor: pointer;
  a {
    color: transparent;
    text-transform: capitalize;

    -webkit-text-fill-color: transparent;
    background-image: radial-gradient(
      20.76% 68.57% at 50% 100%,
      rgb(105, 92, 50) 0%,
      rgb(225, 217, 178) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
  }
`

const SwapRight = styled.div``
const SettingsIcon = styled(RiSettings2Line)`
  height: 26px;
  width: 26px;
  cursor: pointer;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`

const SettingsWrapper = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: ${TangleColors.white};
`

const SwapToken = styled.div`
  width: 100%;
  position: relative;
  padding: 4px 0 0 0;
`
const SwapButtons = styled.div<SwapChartProps>`
  width: 100%;
  margin: 32px 0;

  .anyway {
    background: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.dockBG} !important`
        : `${TangleColors.tangleRed} !important`};
    color: ${(props) =>
      props.blockTransactionState
        ? `${TangleColors.black} !important`
        : `${TangleColors.white}!important`};
    border: ${(props) =>
      props.blockTransactionState
        ? `none !important`
        : `1px solid transparent !important`};
    :hover {
      color: ${(props) =>
        props.blockTransactionState
          ? `${TangleColors.white} !important`
          : `${TangleColors.tangleRed}!important`};
      background: ${(props) =>
        props.blockTransactionState
          ? `${TangleColors.tangleDarkGreen} !important`
          : `${TangleColors.tangleRed05} !important`};
      border: ${(props) =>
        props.blockTransactionState
          ? `none`
          : `1px solid ${TangleColors.tangleRed} !important`};
    }
  }
`
const SwapButton = styled(SwapTokenButton)``

const SwapLocation = styled.button`
  height: 48px;
  width: 48px;
  top: 52%;
  left: 50%;
  background: ${TangleColors.tangleBlack};
  transform: translate(-50%, -50%);
  transition: 0.4s ease-in;
  border: 2px solid ${TangleColors.white};
  outline: none;
  border-radius: 16px;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
  }
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  svg {
    color: ${TangleColors.white};
  }

  :disabled {
    border: 1px solid ${TangleColors.grayDark};
    :hover {
      cursor: not-allowed;
    }
    svg {
      color: ${TangleColors.grayDark};
      :hover {
        cursor: not-allowed;
      }
    }
  }

  @media only screen and (max-width: 650px) {
    height: 34px;
    width: 34px;
    border-radius: 10px;
  }
`

const LocationIcon = styled(HiOutlineArrowDown)`
  height: 20px;
  width: 20px;
  transition: 0.4s ease-in;
  ${SwapLocation} :hover& {
    color: ${TangleColors.lighthover};
  }
  ${SwapLocation}:disabled :hover& {
    color: ${TangleColors.grayDark};
  }
  @media only screen and (max-width: 650px) {
    height: 16px;
    width: 16px;
  }
`

const ChainButton = styled(ChainChangeButton)``
const ConversionWrapper = styled.div`
  width: 100%;
`
const SwapTopCover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 96px;
  margin: 0 auto 24px auto;
`

const ChartToggleCover = styled.div<SwapChartProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 96px;
  margin: 0 auto;
  border-radius: 16px;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  padding: 6px 8px;
  gap: 6px;
  grid-gap: 6px;
`
const ChartWrapper = styled.div<SwapChartProps>`
  background: ${(props) =>
    props.swapChartEnabled
      ? `${TangleColors.tangleBlack}`
      : ` ${TangleColors.swapBG}`};
  border: ${(props) =>
    props.swapChartEnabled
      ? ` 1px solid ${TangleColors.lightPurple}`
      : `  1px solid transparent`};
  padding: 4px 8px;
  border-radius: 8px;
  transition: 0.4s ease-in;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ChartIconOn = styled(Chart21)<SwapChartProps>`
  height: 20px;
  width: 20px;
  cursor: pointer;

  transition: 0.4s ease-in;
  color: ${(props) =>
    props.swapChartEnabled
      ? `${TangleColors.lighthover}`
      : `${TangleColors.white}`};
`
const ChartIconOff = styled(ChartFail)<SwapChartProps>`
  height: 20px;
  width: 20px;
  cursor: pointer;

  transition: 0.4s ease-in;
  color: ${(props) =>
    props.swapChartEnabled
      ? `${TangleColors.lighthover}`
      : `${TangleColors.white}`};
`
const InterfaceWrapper = styled.div<SwapChartProps>`
  width: 100%;
  display: grid;
  gap: 24px;
  grid-gap: 24px;
  justify-content: center;
  align-items: center;

  grid-template-columns: ${(props) =>
    props.swapChartEnabled ? `repeat(2,1fr)` : `repeat(1, 1fr)`};
`
const SwapContentCover = styled.div<SwapChartProps>`
  width: 100%;
  display: grid;
  gap: 24px;
  grid-gap: 24px;
  justify-content: center;
  align-items: center;

  grid-template-columns: ${(props) =>
    props.swapChartEnabled ? `500px auto` : "repeat(1, 1fr)"};
`
const SwapChartCover = styled.div<SwapChartProps>`
  width: 100%;
  display: ${(props) => (props.swapChartEnabled ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`
const SwapInterfaceCover = styled.div``
const SwapWrapperCover = styled.div`
  width: 100%;
  padding: 6px 16px;
  max-width: 800px;
  margin: 12px auto 24px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 13px;
  background: ${TangleColors.tangleBlack};
`
const SwapCoverText = styled(CaptionSmall)``

const SwapLiquidityWrapper = styled.div``
const AddLiqudiityButton = styled.div`
  padding: 6px 12px;
  background: ${TangleColors.heroCTA};
  color: ${TangleColors.white};
  border-radius: 10px;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  a {
    color: ${TangleColors.white};
  }
  :hover {
    border: 1px solid ${TangleColors.white};
  }
`
const AddLPButtonText = styled(CaptionSmall)``

export default React.memo(SwapContainer)
