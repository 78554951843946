import React, { FC, useMemo } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Subtitle } from "styles/TextStyles"
import EfficiencyComparison from "../AddLiquid/EfficiencyComparison"
import RangeInvalid from "../AddLiquid/RangeInvalid"

import LiquidityChartRangeInput from "."
import {
  priceToTick,
  MIN_LP_TICKER,
  MAX_LP_TICKER,
  getDivisibleTicker,
} from "@tangleswap/sdk"
import { Bound } from "./types"
import { useAppSelector } from "store/hooks"
import InputCounter from "./InputCounter"

interface TradingModeProps {
  rangeWarningDisabled?: boolean
}

interface RangeProps {
  fullRangeClick: () => void
  efficiency: boolean
  minUiTextPrice?: any
  maxUiTextPrice?: any
  token0Decimals?: any
  token1Decimals?: any
  acceptEfficiency: () => void
  userInputMinPrice?: any
  userInputMaxPrice?: any
  onLeftRangeInput: (value: string) => void
  onRightRangeInput: (value: string) => void
  tokenA?: string | undefined
  tokenB?: string | undefined
  minDecrement?: () => void
  minIncrement?: () => void
  maxDecrement?: () => void
  maxIncrement?: () => void
  closeEfficiency: () => void
  priceRangeTokenDisabledWarning?: boolean
  disabledByBothTokens?: boolean
  price?: any
  feeAmount?: any
  poolAddress?: any
  poolLiquidity?: any
  slot0?: any
  priceLower?: any
  priceUpper?: any

  interactive?: boolean
  token0Disabled?: boolean
  token1Disabled?: boolean
  currencyA?: any
  currencyB?: any
  address0: any
  address1: any
  poolNeedsInitialization?: boolean
}

const PriceRange: FC<RangeProps> = (props) => {
  const {
    fullRangeClick,
    efficiency,
    acceptEfficiency,
    onLeftRangeInput,
    onRightRangeInput,
    tokenB,
    tokenA,
    currencyA,
    minUiTextPrice,
    maxUiTextPrice,
    currencyB,
    closeEfficiency,
    minDecrement,
    minIncrement,
    maxDecrement,
    maxIncrement,
    price,
    userInputMinPrice,
    userInputMaxPrice,
    priceRangeTokenDisabledWarning,
    disabledByBothTokens,
    feeAmount,
    poolAddress,
    poolLiquidity,
    slot0,
    priceLower,
    priceUpper,
    interactive,
    token0Disabled,
    token1Disabled,
    token0Decimals,
    token1Decimals,
    address0,
    address1,
    poolNeedsInitialization,
  } = props

  const userTradingMode = useAppSelector(
    (state) => state.tradingMode.tradingMode
  )
  const tickLower = getDivisibleTicker(
    priceToTick(Number(priceLower), token0Decimals, token1Decimals),
    feeAmount
  )
  const tickUpper = getDivisibleTicker(
    priceToTick(Number(priceUpper), token0Decimals, token1Decimals),
    feeAmount
  )

  const ticksAtLimit = useMemo(
    () => ({
      [Bound.LOWER]: feeAmount && tickLower <= MIN_LP_TICKER,
      [Bound.UPPER]: feeAmount && tickUpper >= MAX_LP_TICKER,
    }),
    [MAX_LP_TICKER, MIN_LP_TICKER, feeAmount]
  )

  return (
    <>
      {Boolean(userTradingMode) === true ? (
        <>
          <WrapRightTop>
            <WrapMiddle>
              <TextWrap>
                <LiquidityChartRangeInput
                  userTradingMode={Boolean(userTradingMode)}
                  poolNeedsInitialization={Boolean(poolNeedsInitialization)}
                  feeAmount={feeAmount}
                  interactive={interactive}
                  ticksAtLimit={ticksAtLimit}
                  price={price}
                  poolAddress={poolAddress}
                  poolLiquidity={poolLiquidity}
                  slot0={slot0}
                  address0={address0}
                  address1={address1}
                  priceUpper={priceUpper}
                  priceLower={priceLower}
                  token0Decimals={token0Decimals}
                  token1Decimals={token1Decimals}
                  onLeftRangeInput={onLeftRangeInput}
                  onRightRangeInput={onRightRangeInput}
                  currencyA={currencyA}
                  currencyB={currencyB}
                  minUiTextPrice={minUiTextPrice}
                  maxUiTextPrice={maxUiTextPrice}
                />
              </TextWrap>
            </WrapMiddle>
            {priceRangeTokenDisabledWarning ? (
              <>
                <RangeInvalid
                  disabledByBothTokens={disabledByBothTokens}
                  token0Disabled={token0Disabled}
                  token1Disabled={token1Disabled}
                />
              </>
            ) : null}
            <WrapRangeCover>
              <InputCounter
                title="Min Price"
                tokenA={tokenA}
                tokenB={tokenB}
                name="minprice"
                right={true}
                placeholder="0"
                increment={minIncrement}
                decrement={minDecrement}
                value={userInputMinPrice}
                onUserInput={onLeftRangeInput}
                // disable={disable}
              />
              <InputCounter
                title="Max Price"
                // disable={disable}
                name="maxprice"
                tokenA={tokenA}
                tokenB={tokenB}
                placeholder="0"
                value={userInputMaxPrice}
                left={true}
                increment={maxIncrement}
                decrement={maxDecrement}
                onUserInput={onRightRangeInput}
              />
            </WrapRangeCover>

            <FullCover rangeWarningDisabled={priceRangeTokenDisabledWarning}>
              {efficiency ? (
                <>
                  {" "}
                  <EfficiencyComparison
                    acceptEfficiency={acceptEfficiency}
                    closeEfficiency={closeEfficiency}
                  />
                </>
              ) : (
                <>
                  <FullRangeWrapper onClick={fullRangeClick}>
                    <FullRangeText>Full Range</FullRangeText>
                  </FullRangeWrapper>
                </>
              )}
            </FullCover>
          </WrapRightTop>
        </>
      ) : null}
    </>
  )
}

const WrapRightTop = styled.div`
  margin: 0;
`

const WrapMiddle = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;

  align-items: center; */
  margin: 0 0 32px 0;
  width: 100%;
  height: 300px !important;
`

const TextWrap = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-content: center;
`
const WrapRangeCover = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 41.5px 0;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
  }
`

const FullCover = styled.div<TradingModeProps>`
  position: relative;
  width: 100%;
  /* margin: 23px 0 5px 0; */
  margin: ${(props) =>
    props.rangeWarningDisabled ? "-29px 0 5px 0" : "-18px 0 5px 0"};
`
const FullRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  max-width: 800px;
  color: ${TangleColors.white};
  border: 2px solid ${TangleColors.tangleBlacker};
  width: 100%;

  cursor: pointer;
  border-radius: 8px;
  transition: 0.4s ease-in;
  :hover {
    background: ${TangleColors.tangleBlacker};
  }
`
const FullRangeText = styled(Subtitle)`
  color: ${TangleColors.white};
`

export default PriceRange
