import { FC, useEffect, useRef, useState } from "react"
import BigNumber from "bignumber.js"
import { convertTokensToWei } from "components/utils/convertWeiToTokens"
import { useGetWSCTokens, useWSCProvider } from "milkomeda-wsc-ui-test-beta"
import useInterval from "components/utils/useInterval"
import {
  LOCK_ADA,
  LOVELACE_UNIT,
  TX_STATUS_CHECK_INTERVAL,
  TxStatus,
} from "constants/milkomeda/transaction"
import { IWrapMultipleData } from "interfaces/wscSwap.interface"
import {
  getBridgeExplorerUrl,
  getDefaultTokenByChainId,
} from "utils/milkomeda/transaction"
import { TxPendingStatus } from "milkomeda-wsc"
import { useWSCContext } from "context/MilkomedaContext"
import SpinnerCombined from "components/confirmation/SpinnerCombined"
import { useTransactionFees } from "hooks/milkomeda/useTransactionFees"
import { useTransactionStatus } from "hooks/milkomeda/useTransactionStatus"
import {
  ActionButton,
  ActionText,
  ActionWrapper,
  ButtonContainer,
  ExternalLinkIcon,
  Link,
} from "components/milkomeda/styles"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import LabelWithBalance from "components/milkomeda/LabelWithBalance"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { updateUserWSCProgress } from "store/actions/WscProgressAction"
import {
  VOID_TOKEN,
  WRAPPED_ADDRESS,
  extractEvmFunctionName,
} from "@tangleswap/sdk"
import { playSound } from "components/milkomeda/component/PlaySound"
import { useEstimateGas } from "hooks/milkomeda/useEstimatedGas"
import { useTangleship } from "utils/useTangleship"
import { useContractWrite, usePrepareContractWrite } from "wagmi"

interface IUnwrapMultipleStep {
  nextStep?: () => void
  toggleModal?: () => void
  data?: IWrapMultipleData
  actionType?: string
  subStep?: number
  isLastStep?: boolean
  setIsComplete?: (isComplete: boolean) => void
}

export const statusUnwrapMessages = {
  [TxStatus.Init]: "Confirm Unwrapping",
  [TxStatus.Pending]: "Unwrapping your token",
  [TxStatus.WaitingL1Confirmation]: "Waiting for L1 confirmation",
  [TxStatus.WaitingBridgeConfirmation]: "Waiting for bridge confirmation",
  [TxStatus.WaitingL2Confirmation]: "Waiting for L2 confirmation",
  [TxStatus.Confirmed]: "Your asset has been successfully unwrapped!",
}

const UnwrapMultipleStep: FC<IUnwrapMultipleStep> = (props) => {
  const {
    nextStep,
    toggleModal,
    data,
    actionType,
    subStep,
    isLastStep,
    setIsComplete,
  } = props
  const [txHash, setTxHash] = useState<string | undefined>()
  const [currentTokenIndex, setCurrentTokenIndex] = useState(subStep ?? 0)
  const [unwrapErrors, setUnwrapErrors] = useState<{ [index: number]: string }>(
    {}
  )
  const [selectedUnwrapToken, setSelectedUnwrapToken] = useState<any>()
  const [isUnwrapPending, setIsUnwrapPending] = useState<boolean>(false)
  const [fetchTokens, setFetchTokens] = useState<boolean>(true)

  const { account, chainId, destinationBalanceADA } = useWSCContext()
  const { wscProvider } = useWSCProvider()
  const { unwrappingFee, adaLocked } = useTransactionFees()
  const {
    txStatus,
    txStatusError,
    setTxStatusError,
    setTxStatus,
    isIdle,
    isLoading,
    isError,
    isSuccess,
  } = useTransactionStatus()
  const {
    isSuccess: isTokensSuccess,
    tokens,
    refetch: refetchTokens,
  } = useGetWSCTokens()
  const defaultSymbol = getDefaultTokenByChainId(chainId)
  const { feeCardano } = useEstimateGas()
  const { tangleship } = useTangleship()

  const [tokenOut, setTokenOut] = useState<any[]>(data.tokenOut)
  // const tokenOut = data.tokenOut
  const isFirstTokenAda = tokenOut && tokenOut[0]?.unit === LOVELACE_UNIT
  const currentToken = tokenOut[currentTokenIndex]
  const isAda = data
    ? currentToken?.unit === LOVELACE_UNIT ||
      selectedUnwrapToken?.contractAddress === WRAPPED_ADDRESS[chainId]
    : undefined

  useEffect(() => {
    if (isFirstTokenAda && tokenOut) {
      const newTokenOut = [...tokenOut]
      newTokenOut.reverse()
      setTokenOut(newTokenOut)
    }
  }, [tokenOut])

  const destinationBalance = destinationBalanceADA?.data
  const tangleProgress = useAppSelector(
    (state) => state.WscProgressReducer.pendingWscTxStep
  )

  useEffect(() => {
    if (!tangleProgress) return
    if (tangleProgress.stepTitle === "Unwrap") {
      setTxHash(tangleProgress.txHash)
      setTxStatus(TxStatus.WaitingBridgeConfirmation)
      setIsUnwrapPending(true)
      if (setIsComplete && tangleProgress.txHash) setIsComplete(true)
    }
  }, [])

  // @dev: this is the part that makes the transaction execute automatically if the user has the setting enabled

  const tangleSwapTransactions = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTransactions
  )

  const tangleSwapTXEffects = useAppSelector(
    (state) => state.CardanoSettings.tangleSwapTXEffects
  )

  const autoExecute = useRef(false)

  useEffect(() => {
    if (
      tangleSwapTransactions &&
      !autoExecute.current &&
      tokenOut[currentTokenIndex]
    ) {
      autoExecute.current = true
      // @dev: without the setTimeout, the transaction decline will not be shown
      setTimeout(() => {
        unwrapToken()
      }, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tangleSwapTransactions, tokenOut[currentTokenIndex]])

  useInterval(
    async () => {
      if (!wscProvider || txHash == null) return
      setIsUnwrapPending(true)
      const response = await wscProvider.getTxStatus(txHash)
      setTxStatus(response)
      if (
        response === TxStatus.WaitingBridgeConfirmation &&
        currentTokenIndex < tokenOut.length - 1
      ) {
        setCurrentTokenIndex(currentTokenIndex + 1)
      }

      if (response === TxStatus.Confirmed) {
        if (currentTokenIndex < tokenOut.length - 1) {
          setTxHash(undefined)
          dispatch(updateUserWSCProgress(null))
          if (setIsComplete) setIsComplete(true)
          if (tangleSwapTXEffects) playSound()
        }
      } else if (TxStatus.WaitingBridgeConfirmation) {
        if (currentTokenIndex === tokenOut.length - 1) {
          if (setIsComplete) setIsComplete(true)
          setFetchTokens(false)
        }
      }
    },
    txHash != null && txStatus !== TxStatus.Confirmed
      ? TX_STATUS_CHECK_INTERVAL
      : null
  )

  useEffect(() => {
    if (currentTokenIndex !== 0 && currentTokenIndex < tokenOut.length) {
      unwrapToken()
    }
  }, [selectedUnwrapToken])

  const dispatch = useAppDispatch()
  const executeStep = (tx: any) => {
    const stepperParams = {
      completed: false,
      stepTitle: "Unwrap",
      subStep: currentTokenIndex,
      actionType,
      actionSubType: extractEvmFunctionName(data?.evmFunction),
      txHash: tx,
      data,
      timestamp: Math.floor(Number(new Date().getTime()) / 1000),
      isLastStep,
    }
    dispatch(updateUserWSCProgress(stepperParams))
  }

  useEffect(() => {
    if (isTokensSuccess && fetchTokens) {
      const selectedToken = !isAda
        ? tokens.find(
            (t) =>
              t.contractAddress?.toLowerCase() ===
              currentToken?.address?.toLowerCase()
          )
        : {
            balance: "0",
            contractAddress: "",
            decimals: "18",
            name: "",
            symbol: defaultSymbol,
            type: "",
          }

      if (!selectedToken && !isAda) {
        refetchTokens()
      }

      const defaultToken = {
        ...selectedToken,
        hasError: !selectedToken ? true : false,
        balance:
          !isAda && selectedToken?.symbol !== "WADA"
            ? new BigNumber(selectedToken?.balance ?? 0).toString() // unscaled
            : convertTokensToWei({
                value: new BigNumber(destinationBalance ?? 0).dp(6),
                token: { decimals: 6 },
              }).toFixed(), // unscaled
      }
      setSelectedUnwrapToken(defaultToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTokenIndex, currentToken, tokens, destinationBalance, tokenOut])

  const [wadaUnwrapFn, setWadaUnwrapFn] = useState<any>()

  useEffect(() => {
    if (
      !selectedUnwrapToken ||
      !account ||
      !tangleship ||
      selectedUnwrapToken?.contractAddress !== WRAPPED_ADDRESS[chainId]
    )
      return
    const getWadaUnwrapFn = async () => {
      const wadaUnwrap = await tangleship?.directSwap(
        selectedUnwrapToken?.contractAddress,
        "ADA",
        123, // param not needed, feetier
        selectedUnwrapToken?.balance ?? 0,
        BigInt(0),
        account,
        123 // param not needed, deadline
      )
      setWadaUnwrapFn(wadaUnwrap)
    }
    getWadaUnwrapFn()
  }, [selectedUnwrapToken, account, tangleship, actionType])

  const prepareContractWriteQuery = usePrepareContractWrite(wadaUnwrapFn)

  const contractWriteQuery = useContractWrite(prepareContractWriteQuery.config)

  const directSwapped = useRef(false)

  const unwrapToken = async () => {
    if (!currentToken || !wscProvider || !unwrappingFee || !selectedUnwrapToken)
      return

    setTxStatus(TxStatus.Init)

    if (
      selectedUnwrapToken?.contractAddress === WRAPPED_ADDRESS[chainId] &&
      !directSwapped.current
    ) {
      await contractWriteQuery?.writeAsync?.()
      directSwapped.current = true
    }

    const unwrapOptions = {
      destination: undefined,
      assetId: !isAda ? selectedUnwrapToken?.contractAddress : undefined,
      amount: !isAda
        ? new BigNumber(selectedUnwrapToken?.balance ?? 0)
        : new BigNumber(selectedUnwrapToken?.balance ?? 0).minus(feeCardano),
    }

    try {
      const txHash = await wscProvider.unwrap(
        unwrapOptions.destination,
        unwrapOptions.assetId,
        unwrapOptions.amount
      )
      setTxHash(txHash)
      setTxStatus(TxStatus.Pending)

      executeStep(txHash)
    } catch (err) {
      console.error(
        `[${currentTokenIndex}] unwrapToken error: ${err.message} unwrap: ${unwrapOptions}`
      )
      setUnwrapErrors({ ...unwrapErrors, [currentTokenIndex]: err.message })
      setTxStatus(TxStatus.Error)
    }
  }

  const retryUnwrapToken = (index: number) => {
    setCurrentTokenIndex(index)
    setTxHash(undefined)
    setUnwrapErrors({ ...unwrapErrors, [index]: undefined })
    unwrapToken()
  }

  return (
    <ActionWrapper>
      {!isSuccess && (
        <>
          {!isLoading && !selectedUnwrapToken?.hasError && (
            // <div>
            //   {isAda ? (
            //     <LabelWithBalance
            //       label="Bridge Lock-up:"
            //       amount={LOCK_ADA}
            //       assetName={defaultSymbol}
            //     />
            //   ) : (
            //     <>
            //       <LabelWithBalance
            //         label="Received:"
            //         amount={
            //           tokenOut[currentTokenIndex] &&
            //           convertTokensToWei({
            //             value: tokenOut[currentTokenIndex].amount,
            //             token: { decimals: 6 },
            //           })
            //             .dividedBy(10 ** 6)
            //             .toFixed()
            //         }
            //         assetName={defaultSymbol}
            //       />
            //       <LabelWithBalance
            //         label="Bridge Lock-up:"
            //         amount={LOCK_ADA}
            //         assetName={defaultSymbol}
            //       />
            //       <LabelWithBalance
            //         label="Bridge fees:"
            //         amount={unwrappingFee?.toFixed()}
            //         assetName={unwrappingFee && defaultSymbol}
            //         tooltipMessage="This fee is paid to the bridge for unwrapping your token."
            //       />
            //     </>
            //   )}

            //   {isAda ? (
            //     <>
            //       <div style={{ alignSelf: "center" }}>You'll transfer</div>
            //       <LabelWithBalance
            //         label="Token:"
            //         amount={tokenOut[currentTokenIndex]?.amount}
            //         assetName={
            //           unwrappingFee && tokenOut[currentTokenIndex]?.symbol
            //         }
            //       />
            //       <LabelWithBalance
            //         label="ADA:"
            //         amount={LOCK_ADA}
            //         assetName={defaultSymbol}
            //       />
            //     </>
            //   ) : (
            //     <>
            //       <LabelWithBalance
            //         label="You'll transfer:"
            //         amount={
            //           tokenOut[currentTokenIndex] &&
            //           unwrappingFee &&
            //           convertTokensToWei({
            //             value: tokenOut[currentTokenIndex].amount,
            //             token: { decimals: 6 },
            //           })
            //             .plus(adaLocked.multipliedBy(10 ** 6))
            //             .plus(unwrappingFee?.multipliedBy(10 ** 6))
            //             .dividedBy(10 ** 6)
            //             .toFixed()
            //         }
            //         assetName={defaultSymbol}
            //       />
            //     </>
            //   )}
            // </div>
            <ActionText>Unwrapping may take a few minutes (~2 min)</ActionText>
          )}
        </>
      )}
      {txHash && txStatus === TxStatus.WaitingBridgeConfirmation && (
        <ActionText>
          Unwrapping in progress. You can now return back to the Tangleship!
        </ActionText>
      )}
      {isLoading && (
        <>
          <SpinnerCombined />
          <span>{statusUnwrapMessages[txStatus]}</span>
          {/* <p style={{ marginBottom: 30, fontSize: "0.875rem" }}>
            Unwrapping may take a few minutes (~2 min)
          </p> */}
        </>
      )}
      {selectedUnwrapToken?.hasError && !isUnwrapPending && (
        <div>
          It seems like you don't have any tokens to unwrap. Perhaps you already
          unwrapped them?{" "}
        </div>
      )}
      {isError && (
        <>
          <ActionText>
            Oops, something went wrong. Please try again. <br />
            {unwrapErrors[currentTokenIndex]
              ? `Error: ${unwrapErrors[currentTokenIndex]}`
              : ""}
          </ActionText>
          <ButtonContainer>
            <ActionButton onClick={() => retryUnwrapToken(currentTokenIndex)}>
              Retry
            </ActionButton>
          </ButtonContainer>
        </>
      )}

      {isSuccess && txHash && (
        <>
          <Link
            href={`${getBridgeExplorerUrl(chainId)}/search/tx?query=${txHash}`}
          >
            {statusUnwrapMessages[TxPendingStatus.Confirmed]}
            <ExternalLinkIcon />
          </Link>
        </>
      )}
      {selectedUnwrapToken?.hasError && !isUnwrapPending && (
        <ButtonContainer>
          <ActionButton onClick={() => refetchTokens()}>
            Refetch Tokens
          </ActionButton>
        </ButtonContainer>
      )}
      {isIdle && !selectedUnwrapToken?.hasError && (
        <ButtonContainer>
          <ActionButton
            onClick={unwrapToken}
            disabled={!tokenOut[currentTokenIndex]}
          >
            Confirm Unwrapping
          </ActionButton>
        </ButtonContainer>
      )}
    </ActionWrapper>
  )
}

export default UnwrapMultipleStep
