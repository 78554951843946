export const TangleColors = {
  dark: "#19191A",
  darkDark: "#0A0A12",
  darkMedia: "#2c2d32",
  black: "#000000",
  blackDark: "#0c0b10",
  modalBG: "#0c0c0c",

  blackSlateGray: "#252628",
  lightBlack: "#080a13",
  containerBlack: "#191b1f",
  darkSlateGray: "#212429",
  cardBg: "#191A1B",
  shimmerWarning: "#EB6D5B",
  featuresCL: "#181818",
  white: "#ffffff",
  bulbBG: "#FFFBEB",
  preloaderBG: "#0B0B0B",
  disabledBG: "#fc91c5",
  tangleLogoBG: "#6A1A90",
  tangleGold: "#967739",
  primary: "#6938DC",
  secondary: "#171717",
  tangleHarshBlack: "#111111",
  auctionGray: "#98989b",
  secondarySecond: "#1f1f1f",
  inputBG: "#232526",
  primaryDark: "#0E0E10",
  lighthover: "#D874FA",
  // primaryDarker: "#020104",
  primaryDarker: "#040209",
  tangleBlacker: "#333333",
  swapBG: "#120D18",
  tintDark: "#29292A",
  smokeWhite: "#FAFAFA",
  tangleYellow: "#EAB741",

  gray: "#E0E0E0",
  grayLighter: "#f5f6fc",
  grayLight: "#EDEEF2",
  darkGray: "#525A67",
  grayDark: "#888888",
  snowWhite: "#F1F4F9",
  icon: "#1F1F41",
  rowBG: "#21072E",
  rowBGEven: "#260427",
  cta: "#740E95",
  disabledCTA: " #656876",
  heroCTA: "#4C0066",
  lightPurple: "#8F4EAD",
  orange: "#FFE7AC",
  offWhite: "#f8fbff",
  tangleGray: "#D4D4D4",
  tangleBlack: "#222222",

  tangleGreen: "#5DD170",
  tangleDarkGreen: "#247F33",
  tangleRed: "#E10036",
  tangleRed05: "#DED5D8",

  tangleDarkRed: "#7A001D",
  tangleGray10: "#6E727D",

  tangleNFTRed: "#fe5262",
  red: "#FF4343",
  balanceColor: "#f09a98",
  tangle10: "#2E003D",
  rewardText: "#837a97",
  orangeDark: "#f6851b",
  blueLight: " rgb(59, 153, 252)",
  blue: "#001B96",
  gradientsPurpleBlue:
    "linear-gradient(94.41deg, #FF00F3 - 36.26 %, #00C4FF 147.8 %)",
  gradientsRainbow:
    "linear-gradient(90deg, #FFCA62 8.93%, #6938DC 51.28%, #4A94ED 100%)",
  gradientsPurpleBlack:
    "linear-gradient(180deg, rgba(250, 82, 160, 0.3) 0%, rgba(250, 82, 160, 0) 100%)",
  trustWallet: "#4A94ED",
  namiWallet: "#349EA3",
  coinbase: "#2E58F1",
  walletConnect: "#3389FB",
  firefly: "#FFCA62",
  tangleYellow05: "#2A2740",
  tangleYellow600: "#421D06",
  tangleYellowPrimary: "#f3b71e",
  secondary05: "#FFFEE7",
  secondary500: "#FFCB00",
  stake: "rgba(255, 211, 51, 0.1)",
  dockBG: "#00e59919",
  dock: "#00E599",
  farm: "rgba(255, 64, 96, 0.1)",
  farmBG: "#ff042e19",
  transparentStake: "rgba(255, 211, 51, 0.06)",
  transparentDockBG: "rgba(0, 229, 153, 0.06)",
  transparentModalBG: "rgb(12, 12, 12,0.06)",
  transparentFarmBG: "rgba(255, 4, 46, 0.06)",
  assetCardTitle:
    "linear-gradient(0deg,hsla(0,0%,100%,.7),hsla(0,0%,100%,.7)),linear-gradient(32.93deg,#c703bf -19.03%,#3129df 46.85%,#0dd4e0);",
  assetBorder: " hsla(0,0%,100%,.05)",
  trade: "rgba(178, 218, 255, 0.1)",
  invest: "rgba(0, 170, 255, 0.1)",
  lpQuid: "rgba(195, 76, 255, 0.1)",
  feeRateBg: "#4C0066",
  cardPrimary: "#161522",
  cardSecondary: "#202231",
  feeRateText: "#D874FA",
  defi: "#740E95",
  defiSecondary: "#D874FA",
  gaming: "#4e4bc5",
  gamingSecondary: "#c1c0ff",
  utility: "#d23cb9",
  utilitySecondary: "#ffa9f1",
  social: "#da3838",
  socialSecondary: "#ffa3a3",
  governance: "#2cac74",
  governanceSecondary: "#7ceaba",
  nfts: "#d5b533",
  nftsSecondary: "#ffeda3",
  // Colored Button //
  coloredButtonPrimary: "#D874FA",
  coloredButtonSecondary: "#740E95",
  darkText: "#464c5a",
  darkerText: "#aaa",
  lighterText: "#e3e3e3",
  purpleBorder: "#362f46",
  blogBg: "#222529",
  blogArrow: "#2c3036",
  devSub: "#191919",
  devBgColor: "#15181e",
  specialBgPrimary10:
    " background: linear-gradient(180deg, #131313, #131313 53.65%, #131313);",
  gridBG1: "#101010",
  gridBG2: "#2E003D",
  gridBG3: "#4C0066",
  gridBG4: "#740E95",
  gridBG5: "#15181E",
  gridBG6: "#101010",
  live950: "#022C21",
  destructive950: "#421D06",
  upcoming950: "#500000",
  eternl: "#FF108C",
  nufi: "#B6FF00",
  yoroi: "#1B52CC",
  progressSuccess: {
    light: "#ECFDF9",
    middle: "#10B98C",
    main: "#064E3B",
  },
  progressInfo: {
    light: "#E5F3FF",
    main: "#0036FF",
  },
}
