export const supportedChains = [
  {
    id: 200101,
    name: "Cardano EVM Testnet",
    network: "Cardano EVM Testnet",
    nativeCurrency: {
      name: "tADA",
      symbol: "tADA",
      decimals: 18,
    },
    rpcUrls: {
      public: { http: ["https://rpc-devnet-cardano-evm.c1.milkomeda.com"] },
      default: { http: ["https://rpc-devnet-cardano-evm.c1.milkomeda.com"] },
    },
    blockExplorers: {
      etherscan: { name: "", url: "" },
      default: {
        name: "",
        url: "https://explorer-devnet-cardano-evm.c1.milkomeda.com",
      },
    },
  },
  {
    id: 2001,
    name: "Cardano EVM",
    network: "Cardano EVM",
    nativeCurrency: {
      name: "ADA",
      symbol: "ADA",
      decimals: 18,
    },
    rpcUrls: {
      public: {
        http: [
          // "https://rpc1.tangleswap.space",
          // "https://rpc2.tangleswap.space",
          // "https://rpc3.tangleswap.space",
          // "https://rpc4.tangleswap.space",
          // "https://rpc5.tangleswap.space",
          "https://rpc-mainnet-cardano-evm.c1.milkomeda.com",
        ],
      },
      default: {
        http: [
          // "https://rpc1.tangleswap.space",
          // "https://rpc2.tangleswap.space",
          // "https://rpc3.tangleswap.space",
          // "https://rpc4.tangleswap.space",
          // "https://rpc5.tangleswap.space",
          "https://rpc-mainnet-cardano-evm.c1.milkomeda.com",
        ],
      },
    },
    blockExplorers: {
      etherscan: { name: "", url: "" },
      default: {
        name: "",
        url: "https://explorer-mainnet-cardano-evm.c1.milkomeda.com",
      },
    },
  },
]
