import React, { FC, useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Link } from "react-router-dom"

import {
  sqrtPriceToPrice,
  tickToPrice,
  NATIVE_SYMBOL,
  WRAPPED_ADDRESS,
  isInvalid,
  wrapAddr,
  Chains,
} from "@tangleswap/sdk"

import PositionsTop from "./components/PositionsTop"
import PositionMiddle from "./components/PositionMiddle"
import LPTypeRow from "./components/LPTypeRow"
import { useWSCContext } from "context/MilkomedaContext"
import { fetchTangleCurrency } from "../utils/liquidity/useFetchLPCurrency"

import { useQuery } from "react-query"
import PositionsLoadingPlaceholder from "components/PositionsLoadingPlaceholder"

import { useTangleship } from "utils/useTangleship"
import { QueryError, TangleTokenProps } from "components/swap/types"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { fetchSlot0 } from "store/actions/TokenBalanceAction"

interface RowsProps {
  tokenId?: number | string | any

  fee: string

  liquidity: any
  nonce?: any
  operator?: string
  tickLower: string
  tickUpper: string
  token0: string
  token1: string
  tokensOwed0?: any
  tokensOwed1?: any
  poolsLoading?: boolean
}

const LiquidityRows: FC<RowsProps> = (props) => {
  const { account, chainId, l1ChainId } = useWSCContext()

  const { tangleship } = useTangleship()
  const [showHelpText, setShowHelpText] = useState<boolean>(false)
  const [feeShow, setFeeShow] = useState<boolean>(false)
  const [priceLowerConvert, setPriceLowerConvert] = useState<any>(undefined)
  const [priceUpperConvert, setPriceUpperConvert] = useState<any>(undefined)
  const [priceLower, setPriceLower] = useState<any>(undefined)
  const [priceUpper, setPriceUpper] = useState<any>(undefined)

  const [tokenWrappedAddress, setTokenWrappedAddress] = useState<any>(undefined)
  const [token0Address, setToken0Address] = useState<any>(undefined)
  const [token1Address, setToken1Address] = useState<any>(undefined)
  const [outOfRange, setOutOfRange] = useState<any>(undefined)
  const [closedLP, setClosedLP] = useState<boolean>(false)

  const [price, setPrice] = useState<any>()
  const { data: currency0, isLoading: poolsToken0Loading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleswapLPTableToken0", token0Address, l1ChainId],
    () => fetchTangleCurrency(token0Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,

      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token0Address && !!l1ChainId, // Query is enabled conditionally
    }
  )
  const { data: currency1, isLoading: poolsToken1Loading } = useQuery<
    TangleTokenProps,
    QueryError
  >(
    ["tangleswapLPTableToken1", token1Address, l1ChainId],
    () => fetchTangleCurrency(token1Address, l1ChainId, tangleship),
    {
      retry: 3, // Number of retry attempts
      retryDelay: (attemptIndex) => Math.min(attemptIndex * 1000, 500), // Time delay in milliseconds
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity, // Data will become stale after 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
      enabled: !!token1Address && !!l1ChainId, // Query is enabled conditionally
    }
  )

  const {
    fee,
    token0,
    token1,
    tokenId,
    liquidity,
    tickLower,
    tickUpper,
    poolsLoading,
  } = props

  const mouseEnter = () => {
    setShowHelpText(true)
  }
  const mouseLeave = () => {
    setShowHelpText(false)
  }
  useEffect(() => {
    if (isInvalid([chainId])) return

    const isL1 = [Chains.L1_CARDANO, Chains.L1_CARDANO_TEST].includes(l1ChainId)
    const wrapAddr = WRAPPED_ADDRESS[chainId]?.trim()?.toLowerCase()
    const addr0 = token0?.trim()?.toLowerCase()
    const addr1 = token1?.trim()?.toLowerCase()
    const address0 =
      wrapAddr === addr0 && !isL1 ? NATIVE_SYMBOL[chainId] : addr0
    const address1 =
      wrapAddr === addr1 && !isL1 ? NATIVE_SYMBOL[chainId] : addr1

    setTokenWrappedAddress(WRAPPED_ADDRESS[chainId])
    setToken0Address(address0)
    setToken1Address(address1)
  }, [
    chainId,
    currency0?.address,
    currency1?.address,
    token0,
    token0Address,
    token1,
    token1Address,
    tokenWrappedAddress,
  ])

  const dispatch = useAppDispatch()

  // Define setter function
  const checkSlot0Price = useCallback(
    (params: any, pool?: string) => {
      if (!chainId) return

      dispatch(fetchSlot0(chainId, tangleship, params, pool))
    },
    [chainId, dispatch, tangleship]
  )

  // Set the reducer values
  useEffect(() => {
    if (
      isInvalid([
        currency0?.address,
        currency1?.address,
        currency0?.decimals,
        currency1?.decimals,
      ])
    )
      return

    const params = {
      token0: currency0?.address,
      token1: currency1?.address,
      decimals0: currency0?.decimals,
      decimals1: currency1?.decimals,
      feeTier: fee,
    }
    checkSlot0Price(params)
  }, [
    checkSlot0Price,
    currency0?.address,
    currency0?.decimals,
    currency1?.address,
    currency1?.decimals,
    fee,
  ])

  // Get the reducer values
  const slot0PricesList = useAppSelector((state) => state.tokenBalance.slot0)

  // Set the specific values
  useEffect(() => {
    if (!slot0PricesList) return

    const address0 = currency0?.address
    const address1 = currency1?.address
    const addr0 = wrapAddr(address0, chainId)?.tokenAddress?.toLowerCase()
    const addr1 = wrapAddr(address1, chainId)?.tokenAddress?.toLowerCase()
    const isSameOrder = addr0 < addr1
    const token0 = isSameOrder ? addr0 : addr1
    const token1 = isSameOrder ? addr1 : addr0

    const poolKey = `${token0}_${token1}_${fee}`
    const listItem = slot0PricesList?.[poolKey]
    if (!listItem) return

    setPrice(listItem?.price)
  }, [currency0?.address, currency1?.address, fee, slot0PricesList])

  useEffect(() => {
    if (isInvalid([tickUpper, currency0?.decimals, currency1?.decimals])) return

    const priceUpperFigure = tickToPrice(
      Number(tickUpper),
      currency0?.decimals,
      currency1?.decimals
    )
    setPriceUpper(priceUpperFigure)

    const priceUpperValue =
      Number(priceUpperFigure) > Number(100000000000)
        ? "∞"
        : Number(priceUpperFigure)
    setPriceUpperConvert(priceUpperValue)

    return () => {
      setPriceUpper(undefined)
      setPriceUpperConvert(undefined)
    }
  }, [tickUpper, currency0?.decimals, currency1?.decimals])

  useEffect(() => {
    if (isInvalid([tickLower, currency0?.decimals, currency1?.decimals])) return

    const priceLowerFigure = tickToPrice(
      Number(tickLower),
      currency0?.decimals,
      currency1?.decimals
    )
    setPriceLower(priceLowerFigure)

    const priceLowerValue =
      Number(priceLowerFigure) < 0.000001 ? "≈0" : Number(priceLowerFigure)
    setPriceLowerConvert(priceLowerValue)

    return () => {
      setPriceLower(undefined)
      setPriceLowerConvert(undefined)
    }
  }, [tickLower, currency0?.decimals, currency1?.decimals])

  useEffect(() => {
    const outOfRangeValue = Boolean(
      price &&
        priceLower &&
        priceUpper &&
        (price < priceLower || price > priceUpper)
    )
    setOutOfRange(outOfRangeValue)

    const closedLPValue = parseInt(liquidity) === parseInt("0")
    setClosedLP(closedLPValue)
  }, [
    liquidity,
    priceLower,
    priceUpper,
    price,
    currency0?.decimals,
    currency1?.decimals,
  ])

  const showFeePool = () => {
    setFeeShow(!feeShow)
  }

  return (
    <>
      {poolsToken0Loading || poolsToken1Loading || poolsLoading ? (
        <>
          <PositionsLoadingPlaceholder />
          <PositionsLoadingPlaceholder />
          <PositionsLoadingPlaceholder />
        </>
      ) : (
        <>
          <BodyLink to={`/pools/${tokenId}`}>
            <Body>
              <Cover>
                <LPDesktop>
                  <PositionsTop
                    fee={Number(fee)}
                    feeShow={feeShow}
                    token0Info={currency0}
                    token1Info={currency1}
                    showFeePool={showFeePool}
                    showHelpText={showHelpText}
                    closedLP={closedLP}
                    outOfRange={outOfRange}
                    mouseEnter={mouseEnter}
                    mouseLeave={mouseLeave}
                  />
                  <PositionMiddle
                    priceLowerConvert={priceLowerConvert}
                    token0Info={currency0}
                    token1Info={currency1}
                    priceUpperConvert={priceUpperConvert}
                  />

                  <DesktopLPType>
                    <LPTypeRow
                      showHelpText={showHelpText}
                      closedLP={closedLP}
                      outOfRange={outOfRange}
                      mouseEnter={mouseEnter}
                      mouseLeave={mouseLeave}
                    />
                  </DesktopLPType>
                </LPDesktop>
              </Cover>{" "}
            </Body>
          </BodyLink>
        </>
      )}
    </>
  )
}

const BodyLink = styled(Link)`
  color: ${TangleColors.white};
  width: 100%;
  a {
    color: ${TangleColors.white};
  }
`
const Body = styled.div`
  max-width: 1016px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: ${TangleColors.black}; */
  background: ${TangleColors.rowBG};
  border-radius: 24px;
  :nth-child(2) {
    background: ${TangleColors.rowBGEven};
  }

  padding: 20px;
  margin: 12px auto;
  color: ${TangleColors.white};
  a {
    color: ${TangleColors.white};
  }
  cursor: pointer;
  transition: 0.4s ease-in;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${TangleColors.lighthover};
    background: ${TangleColors.blackDark};
  }
`
const Cover = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DesktopLPType = styled.div`
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  margin: 8px 0;
  position: relative;
  z-index: 50;

  @media only screen and (max-width: 850px) {
    display: none;
  }
`

const LPDesktop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
    max-width: 800px;
    margin: 0 auto;
    padding: 12px 8px;
  }
`

export default React.memo(LiquidityRows)
