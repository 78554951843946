import { FC, useContext } from "react"

import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { toast as TangleToast } from "sonner"
import TransactionInfoRow from "./TransactionInfoRow"
import TransactionHrefRow from "./TransactionHrefRow"
import { CaptionSmall } from "styles/TextStyles"
import { TransmitSqaure2 } from "iconsax-react"
import {
  openWSCProgressModal,
  updateUserWSCProgress,
} from "store/actions/WscProgressAction"
import { CancelPendingContext } from "context/CancelModalContext"
import { useAppDispatch } from "store/hooks"
import { CancelTransactionButton } from "styles/ButtonStyles"
import { useWSCContext } from "context/MilkomedaContext"
import {
  getBridgeExplorerUrl,
  getEvmExplorerUrl,
} from "utils/milkomeda/transaction"

interface OverviewProps {
  hash?: string
  timestamp?: string | number
  type?: string
  completed?: boolean
  txType?: any
}
interface WalletAboutProps {
  active?: boolean
}
const TransactionDetails: FC<OverviewProps> = (props) => {
  const { hash, timestamp, type, txType } = props
  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000)
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC", // Add this if you want to display in UTC, or remove it for local time
    }
    return date.toLocaleString("en-US", options)
  }
  const dispatch = useAppDispatch()
  const isWrap = type === "Wrap"
  const isUnwrap = type === "Unwrap"
  const { chainId } = useWSCContext()

  const explorerUrl =
    isWrap || isUnwrap
      ? `${getBridgeExplorerUrl(chainId)}/search/tx?query=`
      : `${getEvmExplorerUrl(chainId)}/tx/`

  const { setOpenModalSettings } = useContext(CancelPendingContext)
  const cancelTransaction = () => {
    dispatch(updateUserWSCProgress(null))
    TangleToast.message("Transaction Cancelled")
    dispatch(openWSCProgressModal(false))
    setOpenModalSettings(false)
  }
  return (
    <>
      <OverviewStepperCover>
        <OverviewTopWrapper>
          {" "}
          <OverviewWrapper>
            {!!hash ? (
              <>
                {" "}
                <TransactionHrefRow
                  text="Link"
                  link={explorerUrl}
                  hash={hash}
                />
              </>
            ) : null}

            <TransactionInfoRow
              text="Time"
              subText={formatDate(Number(timestamp))}
            />
            <TransactionInfoRow text="Type" subText={type} />
          </OverviewWrapper>
          <OverviewStatus>
            <TransactionText>
              {txType === "TangleSwap Transaction" ? (
                <>
                  <NavbarLogo
                    src="https://d3m3d54t409w7t.cloudfront.net/logos/Logo_White_Alpha.webp"
                    alt="tangleswap white logo"
                  />
                </>
              ) : (
                <>
                  {" "}
                  <TransmitCheckComplete size={20} />
                </>
              )}

              {txType}
            </TransactionText>
          </OverviewStatus>
        </OverviewTopWrapper>

        <CancelCoverTransaction active={txType === "TangleSwap Transaction"}>
          <CancelButtonTransaction onClick={cancelTransaction}>
            Cancel transaction
          </CancelButtonTransaction>
        </CancelCoverTransaction>
      </OverviewStepperCover>
    </>
  )
}
const CancelCoverTransaction = styled.div<WalletAboutProps>`
  max-width: 800px;
  width: 100%;
  margin: 12px auto;
  display: ${(props) => (props.active ? `flex` : "none")};
`
const CancelButtonTransaction = styled(CancelTransactionButton)`
  width: 100%;
`

const OverviewStepperCover = styled.div<OverviewProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  padding: 10px;
  margin: 8px 0;
  border-radius: 6px;
  border: 1px solid ${TangleColors.lightPurple};
`
const OverviewTopWrapper = styled.div<OverviewProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  color: ${TangleColors.white};
`
const OverviewStatus = styled.div`
  margin: 12px 0 0 0;
`
const TransactionText = styled(CaptionSmall)<OverviewProps>`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 4px;
  display: flex;
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const OverviewWrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`

const TransmitCheckComplete = styled(TransmitSqaure2)`
  color: ${TangleColors.tangleGreen};
  margin: 0 8px 0 0;
`
const NavbarLogo = styled.img`
  object-fit: contain;
  border-radius: 9999px;
  position: relative;
  z-index: 3;
  width: 22px;
  height: 22px;
  margin: 0 8px 0 0;
`
export default TransactionDetails
