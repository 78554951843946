import React, { FC, useRef } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"
import { GrClose } from "react-icons/gr"
import { useAppDispatch } from "store/hooks"
import { updateLedgerModalOpen } from "store/actions/CardanoSettingsActions"

interface FireFlyProps {
  openDCModal?: boolean
}
const LedgerWarningModal: FC<FireFlyProps> = (props) => {
  const { openDCModal } = props
  const bodyRef = useRef<HTMLDivElement | any>()
  const dispatch = useAppDispatch()

  const handleOpenDCModal = () => {
    dispatch(updateLedgerModalOpen(false))
  }
  return (
    <>
      <ModalCover openDCModal={openDCModal} ref={bodyRef}>
        <Body>
          <ModalClose onClick={handleOpenDCModal} />
          <Cover>
            <MetaMaskText>
              The Wrapped Smart Contract functionality that TangleSwap uses to
              operate natively on Cardano (with{" "}
              <a
                href="https://namiwallet.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nami
              </a>{" "}
              and{" "}
              <a
                href="https://eternl.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Eternl
              </a>
              ) is currently in the process of adding support for hardware
              wallets, such as Ledger or Trezor — a process which was recently
              funded by{" "}
              <a
                href="https://projectcatalyst.io/funds/10"
                target="_blank"
                rel="noopener noreferrer"
              >
                Catalyst Fund 10!
              </a>
              <br />
              <br /> In the meantime, if you are using Nami or Eternl, please
              refrain from participating in the VOID Public Token Sale event
              with a hardware wallet. If you did this, what would happen is that
              your hardware-wallet-funds will become effectively 'stuck' until
              the Ledger/hardware wallet support would be added later on.
              <br />
              <br /> For this reason, if you want to participate in the VOID
              event with Nami or Eternl, please use a temporary hot wallet
              instead of a hardware-based one! For security we would suggest
              using a fresh new wallet, where you can transfer your ADA to
              participate in the sale.
              <br />
              <br /> Alternatively, if you want to participate with a Ledger
              wallet for optimal security (which is always our best
              recommendation), you will want to use{" "}
              <a
                href="https://www.ledger.com/academy/security/the-safest-way-to-use-metamask"
                target="_blank"
                rel="noopener noreferrer"
              >
                MetaMask with your hardware wallet like Ledger,
              </a>{" "}
              by first wrapping your $ADA to the Cardano L2. This process is
              described in detail in our step-by-step{" "}
              <a
                href="https://docs.tangleswap.exchange/guides/step-by-step/investment-hub/participate/?network=cardano-nami#phase-one--get-ready"
                target="_blank"
                rel="noopener noreferrer"
              >
                TangleSwap Investment Hub Participation Guide.
              </a>
              <br />
              <br /> Thank you, and we hope you enjoy the experience aboard the
              Investment Hub! 🪐
            </MetaMaskText>
          </Cover>
        </Body>
      </ModalCover>
    </>
  )
}

const ModalCover = styled.div<FireFlyProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 920;
  background: rgba(255, 255, 255, 0.4);
  display: ${(props) => (props?.openDCModal ? `flex` : `none`)};

  justify-content: center;
  align-items: center;
  padding: 16px;
`
const Body = styled.div`
  width: 100%;
  min-height: 340px;
  max-width: 500px;
  background: #181818;
  border-radius: 24px;
  margin: 0 auto;
  padding: 32px 16px;

  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 65;
  border: 1px solid ${TangleColors.lighthover};

  @media only screen and (max-width: 650px) {
    padding: 10px;
  }
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
`

const MetaMaskText = styled(CaptionSmall)`
  color: ${TangleColors.white};
  text-align: center;
  position: relative;
  z-index: 3;
  line-height: 1.7 !important;
  text-align: left;
  a {
    color: ${TangleColors.lighthover};
    transition: 0.4s ease-in;
    :hover {
      ${TangleColors.lightPurple}
    }
  }
`

const ModalClose = styled(GrClose)`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
    transition: 0.4s ease-in;
  }
  :hover {
    transition: 0.4s ease-in;
    path {
      transition: 0.4s ease-in;
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`
export default LedgerWarningModal
