import React, { FC, useState } from "react"
import styled from "styled-components"
import OverviewSectionTitle from "../components/OverviewSectionTitle"
import WalletAboutView from "../components/WalletAboutView"
import OverviewTransactionInfo from "./OverviewTransactionInfo"
import OverviewPendingTransactions from "../components/OverviewPendingTransactions"
import MoveCardano from "../components/MoveCardano"
import { TangleColors } from "styles/ColorStyles"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { triggerUpdateWalletStep } from "store/actions/WscProgressAction"
interface PendingProps {
  text?: string
  clickTitle?: () => void
}
const OverviewPending: FC<PendingProps> = (props) => {
  const overviewData = [
    { id: 2, title: "Cardano L1" },
    { id: 4, title: "Cardano L2" },
    { id: 3, title: "Pending" },
    { id: 1, title: "About" },
  ]
  const dispatch = useAppDispatch()

  const overviewClick = (value: number) => {
    dispatch(triggerUpdateWalletStep(value))
  }
  const tangleswapWalletStep = useAppSelector(
    (state) => state.WscProgressReducer.tangleswapWalletStep
  )

  return (
    <OverviewPendingCover>
      <OverviewTitleRow>
        {overviewData?.map((data) => (
          <OverviewSectionTitle
            key={data.id}
            overviewClick={() => overviewClick(data.id)}
            text={data.title}
            active={tangleswapWalletStep === data.id}
          />
        ))}
      </OverviewTitleRow>

      <OverviewInfoCover>
        <WalletAboutView active={Number(tangleswapWalletStep) === 1} />
        <MoveCardano active={Number(tangleswapWalletStep) === 2} />
        <OverviewPendingTransactions
          active={Number(tangleswapWalletStep) === 3}
        />
        <OverviewTransactionInfo active={Number(tangleswapWalletStep) === 4} />
      </OverviewInfoCover>
    </OverviewPendingCover>
  )
}

const OverviewPendingCover = styled.div`
  display: flex;
  width: 100%;
  margin: 56px auto 14px auto;
  flex-direction: column;
`
const OverviewTitleRow = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  flex-direction: row;
  padding: 0 0 24px 0;
  border-bottom: 0.4px solid ${TangleColors.lightPurple};
  /* align-items: center;
  justify-content: center; */
`
const OverviewInfoCover = styled.div`
  display: flex;
  width: 100%;
  margin: -8px auto 0 auto;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
`
export default OverviewPending
