import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionSmall } from "styles/TextStyles"

import { ReferralAddressListProps } from "../types"
import { unixToDate } from "utils/time"
import useExplorerInfo from "utils/useExplorerInfo"
import { shortenTableAddress } from "utils"
import { toSignificantDigits } from "utils/toSignificant"

interface TokenDataRowProps {
  index?: any
  referralData?: ReferralAddressListProps
}
const ReferralDataRow: FC<TokenDataRowProps> = (props) => {
  const { index, referralData } = props
  const { explorerURL } = useExplorerInfo()
  return (
    <>
      <LinkWrapper>
        <TokensDataTitle>
          <ClickableText>
            {" "}
            <BoxDataName>{index + 1}</BoxDataName>
          </ClickableText>

          <ClickableText>
            <BoxDataName>
              {" "}
              <a
                href={`${explorerURL?.explorer}/address/${referralData?.referred}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shortenTableAddress(String(referralData?.referred))}
              </a>
            </BoxDataName>
          </ClickableText>

          <ClickableText>
            <BoxDataName>{`$${toSignificantDigits(
              referralData?.volume,
              4,
              true,
              2
            )}`}</BoxDataName>
          </ClickableText>
          <ClickableText>
            <BoxDataName>
              {" "}
              {unixToDate(Number(referralData?.timestamp))}
            </BoxDataName>
          </ClickableText>
        </TokensDataTitle>
      </LinkWrapper>
    </>
  )
}
const TokensDataTitle = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 16px;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 56px 3fr repeat(2, 1fr);
  transition: 0.4s ease-in;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: 20px 2.5fr repeat(1, 1fr);
    & :nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 2.5fr repeat(1, 1fr);
    > *:nth-child(1) {
      display: none;
    }
  }
`

const LinkWrapper = styled.a`
  text-decoration: none;
  width: 100%;
  margin: 4px 0;
  transition: 0.4s ease-in;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const BoxDataName = styled(Caption)`
  color: ${TangleColors.black};
  text-transform: capitalize;

  text-align: left;
  margin: 0 8px 0 0;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
`

const ClickableText = styled.label<TokenDataRowProps>`
  display: flex;
  flex-direction: row;
  font-size: 16px;

  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  color: ${TangleColors.black};
  font-variant-numeric: tabular-nums;
  a {
    color: ${TangleColors.defi};
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
  /* text-align: end; */
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  user-select: none;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`

const ComingSoon = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 14px;
  position: absolute;
  right: -20px;
  top: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${TangleColors.blackSlateGray};
  transform: translate(-50%, -50%);
`
const SoonText = styled(CaptionSmall)`
  color: ${TangleColors.white};
`
export default ReferralDataRow
