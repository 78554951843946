import DappsDivider from "utils/DappsDivider"
import LiquidityPositionsLoadingPlaceholder from "./LiquidityPositionsLoadingPlaceholder"
import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"

const IncreaseLiquidityLoading = () => {
  return (
    <Body>
      <>
        <Cover>
          <LPNavigation>
            <LiquidityPositionsLoadingPlaceholder />
          </LPNavigation>
          <DappsDivider />

          <BoxTop>
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
            <LiquidityPositionsLoadingPlaceholder />
          </BoxTop>

          <LPInfo>
            <InfoBottom>
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
              <LiquidityPositionsLoadingPlaceholder />
            </InfoBottom>
          </LPInfo>
        </Cover>
      </>
    </Body>
  )
}

export default React.memo(IncreaseLiquidityLoading)

const Body = styled.div`
  width: 100%;
  margin: 24px 0 0 0;
  position: relative;
`
const Cover = styled.div`
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  border-radius: 40px;
  padding: 12px 20px 0 20px;
  height: 620px;
  /* overflow: hidden; */
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  flex-direction: column;
  border: 1px solid ${TangleColors.lighthover};
`

const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const LPInfo = styled.div`
  width: 100%;
  margin: -8px 0 0 0;
`

const InfoBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  background: ${TangleColors.swapBG};
`

const BoxTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`
