import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionTiny } from "styles/TextStyles"
import { useTimeSince } from "utils/parseRemote"

interface TimeSinceProps {
  timestamp?: any
}
const TimeSinceRow: FC<TimeSinceProps> = (props) => {
  const { timestamp } = props
  const timeSince = useTimeSince(timestamp)
  return (
    <TransactionDateWrapper>
      <TransactionDate>{timeSince} ago</TransactionDate>
    </TransactionDateWrapper>
  )
}

const TransactionDate = styled(CaptionTiny)`
  color: ${TangleColors.icon};
  white-space: nowrap;
  margin: 0 4px 0 0;
`

const TransactionDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
`
export default TimeSinceRow
