import { updateObject } from "store/utility"
import {
  UPDATE_USER_SLIPPAGE_TOLERANCE,
  UPDATE_USER_DEADLINE_MINUTE,
} from "../actions/actionsTypes"

export interface SettingsProps {
  userSlippageTolerance: any
  deadlineMinute: any
  userNetworkSelected: any
}
const initialState: SettingsProps = {
  userSlippageTolerance: null,
  deadlineMinute: null,
  userNetworkSelected: null,
}

const updateUserSlippageTolerance = (state: any, action: any) => {
  return updateObject(state, {
    userSlippageTolerance: action.userSlippageTolerance,
  })
}
const updateDeadlineMinute = (state: any, action: any) => {
  return updateObject(state, {
    deadlineMinute: action.deadlineMinute,
  })
}

const SettingsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER_SLIPPAGE_TOLERANCE:
      return updateUserSlippageTolerance(state, action)
    case UPDATE_USER_DEADLINE_MINUTE:
      return updateDeadlineMinute(state, action)

    default:
      return state
  }
}

export default SettingsReducer
