import React, { FC, useCallback } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, CaptionTiny } from "styles/TextStyles"
import { HiDuplicate } from "react-icons/hi"
import { RiGuideLine } from "react-icons/ri"
import { FaLanguage } from "react-icons/fa"
import { IoWalletOutline } from "react-icons/io5"
import { FiLogOut, FiExternalLink } from "react-icons/fi"
import { HiMiniChevronRight } from "react-icons/hi2"
import { useWSCContext } from "context/MilkomedaContext"
import { CgProfile } from "react-icons/cg"
import TransactionDropdown from "./TransactionDropdown"
import { toast as SonnerToast } from "sonner"
import { useLocation, useNavigate } from "react-router-dom"
import exchangeIconImg from "assets/exchangeicon.svg"
import useExplorerInfo from "utils/useExplorerInfo"
import { useAppDispatch } from "store/hooks"
import { showWSCInterface } from "store/actions/WscProgressAction"

interface OpenAddressNavProps {
  openAddressNav?: boolean
  activeMenu?: any
  addressOpen?: boolean
  active?: boolean
  goBackToMainMenu?: () => void
  openTransactionMenu?: () => void
}
const AccountDropdown: FC<OpenAddressNavProps> = (props) => {
  const { account, isWSCConnected } = useWSCContext()

  const {
    activeMenu,
    addressOpen,

    openTransactionMenu,
    goBackToMainMenu,
  } = props

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const disconnectWallet = async () => {
    localStorage.removeItem("connectedWallet")
    window.location.reload()
  }
  const { explorerURL } = useExplorerInfo()

  const goToAccount = () => {
    navigate("/airdrop")
  }
  const goToWallet = () => {
    dispatch(showWSCInterface(true))
  }

  // const goToMenu = () => {
  //   handleActiveMainMenu("transactionHistory")
  // }
  const CopyAddress = useCallback(() => {
    navigator.clipboard.writeText(account)
    SonnerToast.success("Address Copied")
  }, [account])

  const location = useLocation()
  const dappsClassName =
    location.pathname === "/swap" ||
    location.pathname === "/pools" ||
    location.pathname === "/stake" ||
    location.pathname === "/nft-staking" ||
    location.pathname === "/farm" ||
    location.pathname === "/invest"

  return (
    <>
      {addressOpen ? (
        <>
          {activeMenu === "transactionHistory" ? (
            <>
              <TransactionDropdown openMainMenu={goBackToMainMenu} />
            </>
          ) : null}
          {activeMenu === "mainDropdown" ? (
            <>
              <NavCover>
                <Body>
                  <>
                    <Cover>
                      <DropUL>
                        <DropLi onClick={goToAccount}>
                          <TransactionCover>
                            <ProfileIcon />
                            <DropText>Account Overview</DropText>
                          </TransactionCover>
                          <ExchangeRightIcon />
                        </DropLi>

                        <DropLi
                          active={!!isWSCConnected}
                          onClick={openTransactionMenu}
                        >
                          <TransactionCover>
                            <ExchangeIcon src={exchangeIconImg} />
                            <DropText>Transactions</DropText>
                          </TransactionCover>
                          <ExchangeRightIcon />
                        </DropLi>

                        <DropLi onClick={CopyAddress}>
                          <CopyAddressIconWrapper>
                            <DuplicateIcon />
                          </CopyAddressIconWrapper>
                          <DropText>Copy address</DropText>
                        </DropLi>
                        <DropLi>
                          {account ? (
                            <>
                              <a
                                href={`${explorerURL?.explorer}/address/${account}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <CopyAddressIconWrapper>
                                  <ExternalLinkIcon />
                                </CopyAddressIconWrapper>
                                <DropText>View on Explorer</DropText>
                              </a>
                            </>
                          ) : (
                            <>
                              <p>
                                <CopyAddressIconWrapper>
                                  <ExternalLinkIcon />
                                </CopyAddressIconWrapper>
                                <DropText>External Explorer</DropText>
                              </p>
                            </>
                          )}
                        </DropLi>
                        <DropLi
                          className={`${
                            dappsClassName
                              ? "activeTour language"
                              : "inActiveTour language"
                          }`}
                        >
                          <CopyAddressIconWrapper>
                            <TakeTourIcon />
                          </CopyAddressIconWrapper>
                          {/* <DropText>Take me on a tour</DropText> */}
                          <DropText>Go on a tour</DropText>
                          <ComingSoon>
                            <SoonText>Soon™</SoonText>
                          </ComingSoon>
                        </DropLi>
                        <DropLi className="activeTour language">
                          <TransactionCover>
                            <CopyAddressIconWrapper>
                              <LanguageIcon />
                            </CopyAddressIconWrapper>
                            <DropText>Language</DropText>
                          </TransactionCover>
                          <ComingSoon>
                            <SoonText>Soon™</SoonText>
                          </ComingSoon>
                          {/* <ExchangeRightIcon src={righticonImg} /> */}
                        </DropLi>
                        <DropLi onClick={disconnectWallet}>
                          <CopyAddressIconWrapper>
                            {" "}
                            <FiLogOut />
                          </CopyAddressIconWrapper>
                          <DropText>Disconnect</DropText>
                        </DropLi>
                      </DropUL>
                    </Cover>
                  </>
                </Body>
              </NavCover>
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}

const NavCover = styled.div`
  position: absolute;

  border-radius: 8px;
  right: 24px;
  top: 4px;
  /* padding: 16px; */
  z-index: 810;
  @media only screen and (max-width: 850px) {
    top: 32px;
    left: 50%;

    z-index: 901;
    background: ${TangleColors.white};
    transform: translate(-50%, 20%);
  }
`
const Body = styled.div`
  position: absolute;
  width: 240px;

  border-radius: 8px;
  right: 0px;
  top: 56px;
  z-index: 32;
  background: ${TangleColors.white};
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  display: flex;
  transition: 0.4s ease-in;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .activeTour {
    display: flex;
  }
  .inActiveTour {
    display: none;
  }
  .language {
    background: ${TangleColors.grayLight}!important;
    cursor: not-allowed;
    color: ${TangleColors.grayLighter} !important;

    border: none;
    :hover {
      background: ${TangleColors.grayLight}!important;
      cursor: not-allowed;
      color: ${TangleColors.grayLighter}!important;
      border: none;
    }
  }
`
const Cover = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
`
const DropUL = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
`

const DropLi = styled.li<OpenAddressNavProps>`
  max-width: 240px;
  margin: 6px auto;
  display: ${(props) => (props.active ? `none` : `flex`)};
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 32px;
  padding: 20px 8px;
  transition: 0.4s ease-in;
  border-radius: 12px;
  border: 1.5px solid transparent;
  :hover {
    background: ${TangleColors.snowWhite};
    border: 1.5px solid ${TangleColors.lighthover};
  }
  svg {
    height: 24px;
    width: 24px;
    color: ${TangleColors.icon};
  }

  a {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 24px;
    svg {
      height: 24px;
      width: 24px;
      color: ${TangleColors.icon};
    }
  }
`

const TransactionCover = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 32px;
  position: relative;
  transition: 0.4s ease-in;

  :hover {
    background: ${TangleColors.snowWhite};
  }
  svg {
    height: 24px;
    width: 24px;
    color: ${TangleColors.icon};
  }

  a {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: 24px;
    svg {
      height: 24px;
      width: 24px;
      color: ${TangleColors.icon};
    }
  }
`

const DropText = styled(Caption)`
  color: ${TangleColors.icon};
  margin: 0 8px;
`

const ExternalLinkIcon = styled(FiExternalLink)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.icon};
`

const CopyAddressIconWrapper = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 0;
`
const TakeTourIcon = styled(RiGuideLine)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.icon};
`
const LanguageIcon = styled(FaLanguage)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.icon};
`

const DuplicateIcon = styled(HiDuplicate)`
  height: 16px;
  width: 16px;
  color: ${TangleColors.icon};
`

const ExchangeIcon = styled.img`
  height: 24px;
  width: 24px;
  color: ${TangleColors.icon};
`
const ProfileIcon = styled(CgProfile)`
  height: 24px;
  width: 24px;
  color: ${TangleColors.icon};
`
const WalletIcon = styled(IoWalletOutline)`
  height: 24px;
  width: 24px;
  color: ${TangleColors.icon};
`

const ExchangeRightIcon = styled(HiMiniChevronRight)`
  height: 24px;
  width: 24px;
  color: ${TangleColors.icon};
`
const ComingSoon = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 14px;
  position: absolute;
  right: -20px;
  top: 50%;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${TangleColors.blackSlateGray};
  transform: translate(-50%, -50%);
`

const SoonText = styled(CaptionTiny)`
  color: ${TangleColors.white};
`
export default AccountDropdown
