import { area, curveStepAfter, ScaleLinear } from "d3"
import React, { useMemo } from "react"
import styled from "styled-components"

import { ChartEntry } from "./types"
import { TangleColors } from "styles/ColorStyles"
interface ChartProps {
  fill?: string | undefined
}
const ChartArea = ({
  series,
  xScale,
  yScale,
  xValue,
  yValue,
  fill,
}: {
  series: ChartEntry[]
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  xValue: (d: ChartEntry) => number
  yValue: (d: ChartEntry) => number
  fill?: string | undefined
}) =>
  useMemo(
    () => (
      <Path
        fill={fill}
        d={
          area()
            ?.curve(curveStepAfter)
            ?.x((d: unknown) => xScale(xValue(d as ChartEntry)))
            ?.y1((d: unknown) => yScale(yValue(d as ChartEntry)))
            ?.y0(yScale(0))(
            series?.filter((d) => {
              const value = xScale(xValue(d))
              return value > 0 && value <= window.innerWidth
            }) as Iterable<[number, number]>
          ) ?? undefined
        }
      />
    ),
    [fill, series, xScale, xValue, yScale, yValue]
  )

const Path = styled.path<ChartProps>`
  opacity: 0.5;

  stroke: ${(props) =>
    props.fill ? props.fill : `${TangleColors.lightPurple}`};
  fill: ${(props) => (props.fill ? props.fill : `${TangleColors.lightPurple}`)};
`

export default ChartArea
