import { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { CaptionLittle } from "styles/TextStyles"
interface PNGProps {
  text?: string
  width?: number
  height?: number
}
const ConvertTokenSymbol: FC<PNGProps> = (props) => {
  const { text, height, width } = props
  return (
    <TokenTradeCover height={height} width={width}>
      <TokenName>{String(text)?.slice(0, 3)}</TokenName>
    </TokenTradeCover>
  )
}
const TokenTradeCover = styled.div<PNGProps>`
  height: ${(props) => (props?.height ? `${props.height}px` : "30px")};
  width: ${(props) => (props?.width ? `${props.width}px` : "30px")};

  border: 1px solid ${TangleColors.white};
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
  background: ${TangleColors.black};
`
const TokenName = styled(CaptionLittle)`
  margin: 0;
  text-transform: uppercase;
  color: ${TangleColors.white};
`
export default ConvertTokenSymbol
