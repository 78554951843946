import Spinner from "react-spinner-material"
import React, { FC } from "react"

interface SpinnerProps {
  color?: any
  visible?: boolean
}
const BlockSpinner: FC<SpinnerProps> = (props) => {
  const { color, visible } = props

  return (
    <div>
      <Spinner radius={14} color={color} stroke={1} visible={visible} />
    </div>
  )
}

export default BlockSpinner
