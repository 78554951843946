import React from "react"
import Spinner from "react-spinner-material"

const AddressLoadingSpinner = () => {
  return (
    <>
      <div>
        <Spinner radius={16} color={"#4C0066"} stroke={2} visible={true} />
      </div>
    </>
  )
}

export default AddressLoadingSpinner
