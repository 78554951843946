import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { BigNumber, ethers } from "ethers"
import styled from "styled-components"
import { toast } from "sonner"
import { nftData } from "components/nfts/NFTData"
import { BsArrowLeft } from "react-icons/bs"
import { TangleColors } from "styles/ColorStyles"

import {
  ChainChangeButton,
  NFTWSCTokenButton,
  StakingButton,
  StakingClaimButton,
  SwapConnectButton,
} from "styles/ButtonStyles"
import { Caption, Header6Gothic, Header7 } from "styles/TextStyles"
import {
  useDerivedStakingInfo,
  // useGetClaimableAmount,
  useStakingActionHandlers,
} from "state/staking/hook"
import { LOCK_PERIOD_DAYS, ZERO } from "config/constants"
import { calculateInterest, shortenAddress } from "utils/index"

import { ApprovalState } from "hooks/useApproveCallback"
import CountDown from "react-countdown"
import PositionsLoadingPlaceholder from "components/PositionsLoadingPlaceholder"
import moment from "moment"
import { PendingStateContext } from "context/PendingContext"
import {
  Chains,
  Field,
  VOID_TOKEN,
  convertValuesToString,
} from "@tangleswap/sdk"

import { Subtitle } from "styles/TextStyles"
import { defaultChainId, useWSCContext } from "context/MilkomedaContext"
import addChainToMetamask from "connect/switchChain"
import { useIsSupportedNetwork } from "constants/hooks"

import { useNavigate, useParams } from "react-router-dom"

import { useAppSelector } from "store/hooks"
import { useTangleship } from "utils/useTangleship"
import { toSignificantDigits } from "utils/toSignificant"
import ConnectWalletButton from "components/utils/ConnectWalletButton"
import useOpenWSCActionModal from "utils/useOpenWSCActionModal"
import SwapButtonSpinner from "components/swap/SwapButtonSpinner"

const NFT_IMAGES_LOWRES = {
  palm: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_PalmSprings.webp",
  floating: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_Floating.webp",
  tranquillity:
    "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_Tranquillity.webp",
  ascension: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_Ascencion.webp",
  night: "https://d3m3d54t409w7t.cloudfront.net/nfts/Lowres_NightFall.webp",
}

const countRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return "0m 0s"
  } else {
    if (Number(hours) <= 0) {
      return minutes + "m " + seconds + "s"
    } else if (Number(days) <= 0) {
      return hours + "h " + minutes + "m " + seconds + "s"
    }
    return days + "d " + hours + "h " + minutes + "m " + seconds + "s"
  }
}
interface NFTDetailsProps {
  hide?: boolean
  blockTransactionState?: boolean
}

const NFTDetailsContainer: FC<NFTDetailsProps> = (props) => {
  const slug: any = useParams().slug
  const [activeLoader, setActiveLoader] = useState<boolean>(true)
  const navigate = useNavigate()
  const { account, chainId, isWSCConnected } = useWSCContext()

  const { tangleship } = useTangleship()

  const blockTransactionState = useAppSelector(
    (state) => state.wallet.blockTransactionState
  )

  const tangleswapTokenListBalances = useAppSelector(
    (state) => state.tokenBalance.tokenbalance
  )
  const { openWSCModal } = useOpenWSCActionModal()
  const { pendingTransaction, setPendingTransaction }: any =
    useContext(PendingStateContext)
  const { nftIds } = useDerivedStakingInfo(slug)
  const { onFreezeNft, onNftSelection } = useStakingActionHandlers()
  const [dataFetching, setDataFetching] = useState<boolean>(false)
  const [voidBalance, setVoidBalance] = useState<any>(null)
  const [userNfts, setUserNfts] = useState<any>([])
  const [stakedNfts, setStakedNfts] = useState<any>([])
  const [stakeRewards, setStakeRewards] = useState<any>({})
  const [selectedNft, setSelectedNft] = useState<any>({})

  useEffect(() => {
    let rewards = {
      [slug]: ZERO,
    }

    stakedNfts.forEach((nft: any) => {
      rewards[slug] = rewards[slug].add(
        calculateInterest(
          moment().utc().unix(),
          nft.stakeTime,
          nft.baseApy,
          nft.rewardPrincipal, // instanceof BigNumber ? nft.rewardPrincipal : ZERO,
          nft.interestClaimed // instanceof BigNumber ? nft.interestClaimed : ZERO
        )
      )
    })

    setStakeRewards(rewards)
  }, [slug, stakedNfts])

  const getUserStakedNfts = useCallback(
    async (refetch = false) => {
      if (!account || !chainId) return

      const storageKey = `userStakedNfts${chainId}${account}`
      const storageValue = sessionStorage.getItem(storageKey)
      // @dev: the output of this function is complex, disabling until we find a better way to cache it
      // if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      //   return JSON.parse(storageValue)
      // }

      const res = await tangleship?.getNftStakedOfOwner(account)
      const nfts = res || []
      // sessionStorage.setItem(storageKey, convertValuesToString(nfts))
      return nfts
    },
    [account, chainId]
  )

  const [approved, setApproved] = useState<ApprovalState>(
    ApprovalState.NOT_APPROVED
  )

  const getApproved = async (refetch = false) => {
    const storageKey = `nftsApprovedForAll${chainId}${account}`
    const storageValue = sessionStorage.getItem(storageKey)
    const booleanValue = storageValue === "true"
    if (storageValue !== null && storageValue !== "undefined" && !refetch) {
      setApproved(
        booleanValue ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED
      )
      return
    }

    const approved = await tangleship?.getNftIsApprovedForAll(account)
    sessionStorage.setItem(storageKey, approved)
    setApproved(approved ? ApprovalState.APPROVED : ApprovalState.NOT_APPROVED)
  }

  const getWalletNfts = async (refetch = false) => {
    const storageKey = `nftsFromWallet${chainId}${account}`
    const storageValue = sessionStorage.getItem(storageKey)
    // @dev: the output of this function is complex, disabling until we find a better way to cache it
    // if (storageValue !== null && storageValue !== "undefined" && !refetch) {
    //   return JSON.parse(storageValue)
    // }

    const walletNfts = await tangleship?.getNftsFromWallet(account)
    // sessionStorage.setItem(storageKey, convertValuesToString(walletNfts))
    return walletNfts
  }

  useEffect(() => {
    if (!chainId || !account) return
    getApproved()
    getData()
  }, [chainId, account])

  const getVoidBalance = () => {
    if (!chainId || !account || !tangleswapTokenListBalances) return

    const voidAddress = VOID_TOKEN[chainId].toLowerCase()
    const balance = tangleswapTokenListBalances?.[voidAddress]
    const balanceFormatted = String(balance) || "0"
    setVoidBalance(balanceFormatted)
  }

  useEffect(() => {
    getVoidBalance()
  }, [chainId, account, tangleswapTokenListBalances])

  const getNameFromIpfsData = async (ipfsUrl: string) => {
    const storageKey = `ipfsName${ipfsUrl}`
    const storageValue = localStorage.getItem(storageKey)
    if (storageValue !== null && storageValue !== "undefined") {
      return storageValue
    }

    const ipfsResponse = await fetch(ipfsUrl)
    const ipfsData = await ipfsResponse.json()
    const ipfsName = ipfsData?.name
    if (ipfsName) localStorage.setItem(storageKey, ipfsName)
    return ipfsName
  }

  const getFreeNftMetadata = async () => {
    const staked = await getUserStakedNfts()
    const stakedTmp = []
    for (let i = 0; i < staked[0].length; i++) {
      const nftId = staked[0][i].toString()
      const ipfsUrl = staked[1][i]
      const stake = staked[2][i]

      const name = await getNameFromIpfsData(ipfsUrl)

      if (name.toLowerCase().includes(slug.toLowerCase())) {
        stakedTmp.push({
          id: nftId,
          name: name,
          baseApy: stake["baseApy"],
          claimedTime: stake["claimedTime"],
          interestClaimed: stake["interestClaimed"],
          lockPeriodDays: LOCK_PERIOD_DAYS, // stake["lockPeriodDays"],
          rewardPrincipal: stake["rewardPrincipal"],
          stakeTime: stake["stakeTime"],
        })
      }
    }
    setStakedNfts(stakedTmp)
  }

  const getLockedNftMetadata = async () => {
    const metadata = await getWalletNfts()
    const activeTmp = []
    for (let i = 0; i < metadata[0].length; i++) {
      const nftId = metadata[0][i].toString()
      const ipfsUrl = metadata[1][i]

      const name = await getNameFromIpfsData(ipfsUrl)

      if (name.toLowerCase().includes(slug.toLowerCase())) {
        activeTmp.push({
          id: nftId,
          name: name,
        })
      }
    }
    setUserNfts(activeTmp)
  }

  async function getData() {
    setDataFetching(true)

    try {
      await Promise.all([getFreeNftMetadata(), getLockedNftMetadata()])

      setDataFetching(false)
    } catch (e) {
      setDataFetching(false)
      // toast.error("Error while fetching Data")
    }
  }

  useEffect(() => {
    onFreezeNft(Field.INPUT)
    onFreezeNft(Field.OUTPUT)
  }, []) // eslint-disable-line

  const stakedIds = stakedNfts?.map((nft: any) => nft.id) || []
  // const rewardsPerSlug = useGetClaimableAmount(stakingContract, stakedIds)

  const { isLocked, unstakeLeft } = useMemo(() => {
    if (!stakedNfts || stakedNfts?.length <= 0 || nftIds[Field.OUTPUT] <= 0) {
      return {
        isLocked: true,
        unstakeLeft: 0,
      }
    }
    const selected = stakedNfts?.filter(
      (nft: any) => nft.id === selectedNft["STAKED"].id
    )[0]

    if (selected === undefined && selectedNft["STAKED"] === 0) {
      onFreezeNft(Field.OUTPUT)
      return { isLocked: false, unstakeLeft: 0 }
    }

    const locktime = selected.lockPeriodDays * 24 * 60 * 60 // from days to seconds
    const remained = parseInt(selected.stakeTime) + locktime

    return {
      isLocked: remained > moment().utc().unix(),
      unstakeLeft: remained,
    }
  }, [stakedNfts, nftIds]) // eslint-disable-line

  const claimLeft: any = useMemo(() => {
    if (stakedNfts?.length >= 0 && nftIds) {
      let time = parseInt(stakedNfts[0]?.claimedTime)

      stakedNfts?.map((nft: any) => {
        if (time > nft.claimedTime) {
          time = nft.claimedTime
        }
        return time
      })
      const remained = time + 604800
      return remained
    }
    return null
  }, [stakedNfts, nftIds])

  const handleApprove = async () => {
    if (!account || !chainId || selectedNft["USER"]?.id === 0) {
      return
    }
    setPendingTransaction(true)
    try {
      toast(`NFT approve requested...`)
      const tx = await tangleship?.setNftIsApprovedForAll()
      await tx.tx?.wait()
      setApproved(ApprovalState.APPROVED)
      getData()
      toast.success("NFT approval succeeded!")
    } catch (error) {
      toast.error("NFT approval failed.")
    }
    setPendingTransaction(false)
  }

  const handleStakeOrUnstake = async (field: any) => {
    if (!account || !chainId || Object.keys(selectedNft).length === 0) {
      return
    }
    setPendingTransaction(true)

    const methodName = field.hasOwnProperty("USER") ? "stakeNft" : "unstakeNft"
    const nft = field.hasOwnProperty("USER") ? field["USER"] : field["STAKED"]

    try {
      toast(`NFT ${methodName.slice(0, -3)} requested...`)
      const tx = await tangleship[methodName](nft.id)
      await tx.tx?.wait()
      onFreezeNft(field)
      toast.success(`NFT ${methodName.slice(0, -3)}d successfully!`)
      setActiveLoader(true)
      getData()
      setSelectedNft({})
      setTimeout(() => {
        setActiveLoader(false)
      }, 2000)
    } catch (e: any) {
      toast.error(`NFT ${methodName.slice(0, -3)} failed.`)
    }
    setPendingTransaction(false)
  }

  const handleClaim = async () => {
    if (claimLeft === undefined || claimLeft === null) return
    if (
      !account ||
      !chainId ||
      stakedIds?.length <= 0 ||
      claimLeft > moment().utc().unix()
    ) {
      return
    }
    setPendingTransaction(true)
    try {
      toast(`NFT claim requested...`)
      const tx = await tangleship?.batchClaimNft(stakedIds)
      await tx.tx?.wait()
      setActiveLoader(true)
      getData()
      setSelectedNft({})
      setTimeout(() => {
        setActiveLoader(false)
      }, 2000)
      toast.success("Rewards claimed successfully!")
    } catch (error) {
      toast.error("Rewards couldn't be claimed.")
    }
    setPendingTransaction(false)
  }
  const title = nftData.filter((nft) => nft?.slug === slug)[0]?.title

  useEffect(() => {
    setTimeout(() => {
      setActiveLoader(false)
    }, 100)
  }, [])

  const isSupportedNetwork = useIsSupportedNetwork()

  const handlePendingTxModal = () => {
    openWSCModal()
  }
  return (
    <>
      {" "}
      <Cover>
        {/* header section starts */}
        <TopSection>
          <HeaderTop>
            <SectionInfo>
              <NFTName>Docking Station: {title}</NFTName>
            </SectionInfo>
          </HeaderTop>
        </TopSection>
        {/* header sections ends */}

        {/* staking section starts */}
        <StackingWrapper hide={chainId === Chains.SHIMMER}>
          <StakingSectionTop>
            <TopStaking>
              <WalletWrap>
                <GoBack onClick={() => navigate(-1)}>
                  <BackIcon />
                </GoBack>
                <WalletInfoWrapper>
                  <VoidTitle>Wallet: </VoidTitle>
                  {account ? (
                    <VoidText>{shortenAddress(account)}</VoidText>
                  ) : (
                    <>
                      <VoidText>—</VoidText>
                    </>
                  )}
                </WalletInfoWrapper>

                <VoidWrapLeft>
                  <VoidTitle>Balance:</VoidTitle>
                  <VoidText>
                    {activeLoader ||
                    voidBalance === null ||
                    isNaN(voidBalance) ? (
                      <> — VOID</>
                    ) : (
                      <>
                        {!!voidBalance
                          ? toSignificantDigits(voidBalance, 3, false, 18)
                          : "0"}{" "}
                        VOID
                      </>
                    )}
                  </VoidText>
                </VoidWrapLeft>
              </WalletWrap>
            </TopStaking>
            <TopStaking>
              <WalletWrap>
                <VoidWrapRight>
                  <VoidTitle>Claimable Amount:</VoidTitle>
                  <VoidText>
                    {activeLoader ||
                    voidBalance === null ||
                    isNaN(voidBalance) ? (
                      <>— VOID</>
                    ) : (
                      <>
                        {toSignificantDigits(
                          ethers.utils.formatEther(
                            stakeRewards[slug].lt(ZERO)
                              ? ZERO
                              : stakeRewards[slug]
                          ),
                          3
                        )}{" "}
                        VOID
                      </>
                    )}
                  </VoidText>
                </VoidWrapRight>
              </WalletWrap>
            </TopStaking>
          </StakingSectionTop>
          <StakingBottom>
            {" "}
            {!!account ? (
              <>
                <StakingSection>
                  <StakingRow>
                    {activeLoader || dataFetching ? (
                      <NFTEmpty>
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                      </NFTEmpty>
                    ) : (
                      <>
                        {userNfts && userNfts?.length <= 0 ? (
                          <>
                            {" "}
                            <NFTEmpty>
                              <EmptyIcon
                                src="https://d3m3d54t409w7t.cloudfront.net/homepage/icon-materializing.png"
                                alt="tangle no nft placeholder"
                              />
                              <NFTEmpText>
                                No matching NFTs in your wallet.
                              </NFTEmpText>
                            </NFTEmpty>
                          </>
                        ) : (
                          <StakingMain>
                            {userNfts &&
                              userNfts?.map((nft: any, index: number) => (
                                <NFTPhotoWrap
                                  key={index}
                                  style={
                                    nft === nftIds[Field.INPUT]
                                      ? activePhoto
                                      : {}
                                  }
                                  onClick={() => {
                                    onNftSelection(Field.INPUT, nft)
                                    setSelectedNft({ USER: nft })
                                  }}
                                >
                                  <NFTPhoto
                                    alt={nft.name}
                                    src={NFT_IMAGES_LOWRES[slug]}
                                  />
                                  <NFTNameWrap>{nft.name}</NFTNameWrap>
                                </NFTPhotoWrap>
                              ))}
                            {/* <NFTPhotoWrap
                        onClick={() => setLimit({ ...limit, [slug]: limit[slug] + 9 })}>
                        <NFTPhoto alt="load" src="assets/no_image.svg" />
                        <NFTNameWrap>Load more</NFTNameWrap>
                      </NFTPhotoWrap> */}
                          </StakingMain>
                        )}
                      </>
                    )}

                    <StakingLeftButtons
                      blockTransactionState={!!blockTransactionState}
                    >
                      <StakeButton>
                        <StakingPrimaryButton
                          onClick={() => {
                            if (approved === ApprovalState.APPROVED) {
                              handleStakeOrUnstake(selectedNft)
                            } else if (selectedNft["USER"] !== 0) {
                              handleApprove()
                            } else {
                              toast.error("Please select a NFT to stake.")
                            }
                          }}
                          disabled={!selectedNft["USER"] || pendingTransaction}
                        >
                          {approved === ApprovalState.APPROVED ||
                          nftIds[Field.INPUT] <= 0
                            ? "Stake"
                            : "Approve"}
                        </StakingPrimaryButton>
                      </StakeButton>
                    </StakingLeftButtons>
                  </StakingRow>
                  <StakingRow>
                    {activeLoader || dataFetching ? (
                      <NFTEmpty>
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                        <PositionsLoadingPlaceholder />
                      </NFTEmpty>
                    ) : (
                      <>
                        {stakedNfts && stakedNfts?.length <= 0 ? (
                          <NFTEmpty>
                            {" "}
                            <EmptyIcon
                              src="https://d3m3d54t409w7t.cloudfront.net/homepage/icon-singularity.png"
                              alt="tangle no nft placeholder"
                            />
                            <NFTEmpText>No {title} NFTs staked.</NFTEmpText>
                          </NFTEmpty>
                        ) : (
                          <StakingMain>
                            {" "}
                            {stakedNfts &&
                              stakedNfts?.map((nft: any, index: number) => (
                                <NFTPhotoWrap
                                  key={index}
                                  style={
                                    nft.id === selectedNft["STAKED"]?.id
                                      ? activePhoto
                                      : {}
                                  }
                                  onClick={() => {
                                    if (selectedNft["STAKED"]?.id !== nft.id) {
                                      onNftSelection(Field.OUTPUT, nft.id)
                                      setSelectedNft({ STAKED: nft })
                                    }
                                  }}
                                >
                                  {nft.id === selectedNft["STAKED"]?.id && (
                                    <>
                                      <CooldownText>
                                        Unstake cooldown:{" "}
                                        <CountDown
                                          date={unstakeLeft * 1000}
                                          renderer={countRenderer}
                                        />
                                      </CooldownText>
                                    </>
                                  )}
                                  <NFTPhoto
                                    alt={nft.name}
                                    src={NFT_IMAGES_LOWRES[slug]}
                                    style={{
                                      opacity:
                                        nft.id === selectedNft["STAKED"]?.id
                                          ? 0.4
                                          : 1,
                                    }}
                                  />
                                  <NFTNameWrap>{nft.name}</NFTNameWrap>
                                </NFTPhotoWrap>
                              ))}
                          </StakingMain>
                        )}
                      </>
                    )}

                    <StakingRightButtons
                      blockTransactionState={!!blockTransactionState}
                    >
                      <StakeButton>
                        <StakingPrimaryButton
                          disabled={
                            nftIds[Field.OUTPUT] === 0 ||
                            pendingTransaction ||
                            isLocked
                          }
                          onClick={() => handleStakeOrUnstake(selectedNft)}
                        >
                          Unstake
                        </StakingPrimaryButton>
                      </StakeButton>
                      <StakeButton>
                        <ClaimPrimaryButton
                          disabled={
                            claimLeft > moment().utc().unix() ||
                            pendingTransaction ||
                            stakedNfts.length < 1
                          }
                          onClick={() => handleClaim()}
                        >
                          {claimLeft > moment().utc().unix() &&
                          claimLeft > 0 ? (
                            <ButtonText>
                              Next Claimable: <br />
                              {/* @ts-ignore */}
                              <CountDown
                                date={claimLeft * 1000}
                                renderer={countRenderer}
                              />
                            </ButtonText>
                          ) : (
                            <ButtonText>Claim</ButtonText>
                          )}
                        </ClaimPrimaryButton>
                      </StakeButton>
                    </StakingRightButtons>
                  </StakingRow>
                </StakingSection>
              </>
            ) : (
              <>
                {" "}
                <StakingSection>
                  <StakingRow>
                    <StakeWrapper>
                      <NotConnected>
                        {account && !isSupportedNetwork ? (
                          <NotCorrectChainButton
                            onClick={() => addChainToMetamask(defaultChainId)}
                          >
                            Switch to Cardano
                          </NotCorrectChainButton>
                        ) : (
                          <NotConnectButton>
                            <ConnectWalletButton />
                          </NotConnectButton>
                        )}
                      </NotConnected>
                    </StakeWrapper>
                  </StakingRow>
                  <StakingRow>
                    <StakeWrapper>
                      <NotConnected>
                        {account && !isSupportedNetwork ? (
                          <>
                            <NotCorrectChainButton
                              onClick={() => addChainToMetamask(defaultChainId)}
                            >
                              Switch to Cardano
                            </NotCorrectChainButton>
                          </>
                        ) : (
                          <>
                            <NotConnectButton>
                              <ConnectWalletButton />
                            </NotConnectButton>
                          </>
                        )}
                      </NotConnected>
                    </StakeWrapper>
                  </StakingRow>
                </StakingSection>
              </>
            )}
          </StakingBottom>

          <ContinueWSCProgress blockTransactionState={!!blockTransactionState}>
            <WSCProgressStakingButton
              onClick={handlePendingTxModal}
              blockTransactionState={!!blockTransactionState}
            >
              {" "}
              {!!blockTransactionState ? (
                <>
                  <SwapButtonSpinner />
                </>
              ) : null}
              {!!blockTransactionState ? (
                <>Continue Previous Transaction</>
              ) : null}
            </WSCProgressStakingButton>
          </ContinueWSCProgress>
        </StackingWrapper>

        {/* staking section ends */}
      </Cover>
    </>
  )
}

const activePhoto = {
  zIndex: 1,
  opacity: 1,
  cursor: "pointer",
  borderRadius: "20px",
  padding: "6px",
  border: `2px solid ${TangleColors.lighthover}`,
}

const Cover = styled.div`
  max-width: 1232px;
  margin: 12px auto 24px auto;

  width: 100%;
  height: 100%;
  padding: 0 16px;
  /* display: none; */
  flex-direction: column;

  align-items: center;
  @media only screen and (max-width: 700px) {
    margin: 24px auto;
  }
`

const HeaderTop = styled.div`
  width: 100%;
  margin: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const CooldownText = styled.div`
  top: 35%;
  position: absolute;
  text-align: center;
`
const GoBack = styled.div`
  height: 48px;
  width: 100%;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;

  @media only screen and (max-width: 900px) {
    width: auto;
  }
`

// const BackText = styled(CaptionSmall)`
//   color: ${TangleColors.white};
//   margin: 0 8px;
// `
const BackIcon = styled(BsArrowLeft)`
  height: 32px;
  width: 32px;
  transition: 0.4s ease-in;
  color: ${TangleColors.white};
  opacity: 0.8;
  :hover {
    color: ${TangleColors.lighthover};
  }
`
const NotConnected = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StakeButton = styled.div`
  margin: 0 4px;
  width: 100%;
`
const ButtonText = styled.div``
const NotConnectButton = styled.div`
  width: 100%;
`

const SectionInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${TangleColors.white};
  justify-content: center;
  align-items: center;
`

const NFTName = styled(Header6Gothic)`
  color: transparent;
  text-transform: capitalize;

  -webkit-text-fill-color: transparent;
  background-image: radial-gradient(
    20.76% 68.57% at 50% 100%,
    rgb(105, 92, 50) 0%,
    rgb(225, 217, 178) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
`
const NFTPhoto = styled.img`
  max-width: 150px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;

  @media only screen and (max-width: 700px) {
    max-width: 130px;
    height: 130px;
  }
`

const NFTPhotoWrap = styled.div`
  color: ${TangleColors.white};
  cursor: pointer;
  max-width: 160px;
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 4px;
  padding: 8px;
  border: 2px solid transparent;
  flex: 1;

  position: relative;

  @media only screen and (max-width: 700px) {
    max-width: 130px;
    margin: 8px auto;
  }
`
const NFTNameWrap = styled(Caption)`
  color: ${TangleColors.white};
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
`

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
  }
`

const StakingSection = styled.div`
  color: ${TangleColors.white};
  margin: 0;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  gap: 12px;
  padding: 0;

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StakingSectionTop = styled.div`
  color: ${TangleColors.white};
  margin: 0;
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  gap: 12px;
  padding: 0;

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (max-width: 750px) {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
  }
`
const StackingWrapper = styled.div<NFTDetailsProps>`
  width: 100%;

  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
`
const StakingBottom = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`

const StakingRow = styled.div`
  width: 100%;
`
const TopStaking = styled.div`
  height: 84px;
  max-width: 600px;
  width: 100%;
  margin: 16px auto;

  border-radius: 16px;
  padding: 16px;
  border: 1px solid ${TangleColors.lighthover};
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${TangleColors.lighthover};
`
const WalletWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
`

const NFTEmpty = styled.div`
  height: 500px;
  max-width: 600px;
  width: 100%;
  margin: 24px auto;
  display: flex;
  background: ${TangleColors.swapBG};
  border: 1px solid ${TangleColors.lighthover};
  justify-content: center;
  align-items: center;
  position: relative;

  flex-direction: column;
  border-radius: 24px;
  padding: 32px;
`
const EmptyIcon = styled.img`
  max-width: 150px;
  height: 150px;
  width: 100%;
  margin: 8px auto;
  object-fit: contain;
`

const NFTEmpText = styled(Header7)`
  color: ${TangleColors.white};
  margin: 12px 0;
`
const WalletText = styled(Header7)`
  margin: 0 10px 0 0;
`

const WalletInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`
const WalletAddress = styled(Subtitle)``
const VoidWrapLeft = styled.div`
  margin: 0 0 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const VoidWrapRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 0 0 3px;
`

const VoidText = styled(Subtitle)`
  color: ${TangleColors.white};
  margin: 0 0 0 8px;
  white-space: nowrap;
`
const StakingMain = styled.div`
  height: 500px;
  max-width: 600px;
  width: 100%;
  margin: 24px auto;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 24px;
  padding: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  border: 1px solid ${TangleColors.lighthover};
  background: ${TangleColors.swapBG};
  ::-webkit-scrollbar {
    width: 8px;
    height: 400px !important;
    border-radius: 24px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 24px !important;
    border-radius: 24px !important;
    height: 400px !important;
    margin-block: 1.4rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 24px !important;

    border-radius: 24px !important;
    background: ${TangleColors.white};

    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: ${TangleColors.white};
  }

  @media only screen and (max-width: 900px) {
    padding: 8px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const StakeWrapper = styled.div`
  height: 500px;
  max-width: 600px;
  width: 100%;
  border-radius: 24px;
  padding: 32px;
  border: 1px solid ${TangleColors.lighthover};
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 900px) {
    padding: 8px;
  }
`

const StakingLeftButtons = styled.div<NFTDetailsProps>`
  display: ${(props) => (props.blockTransactionState ? `none` : `flex`)};
  flex-direction: row;
  width: 100%;
  margin: 24px 0;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`
const VoidTitle = styled(Header7)`
  color: ${TangleColors.lighthover};
`

const StakingPrimaryButton = styled(StakingButton)``
const ClaimPrimaryButton = styled(StakingClaimButton)``
const StakingRightButtons = styled.div<NFTDetailsProps>`
  display: ${(props) => (props.blockTransactionState ? `none` : `flex`)};
  flex-direction: row;
  width: 100%;
  margin: 24px 0;
  position: relative;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const NotCorrectChainButton = styled(ChainChangeButton)`
  position: relative;
  z-index: 9;
`
const ContinueWSCProgress = styled.div<NFTDetailsProps>`
  margin: 24px auto;
  display: ${(props) => (props.blockTransactionState ? `flex` : `none`)};
  width: 100%;
  max-width: 1232px;
  background: ${TangleColors.swapBG};
  border-radius: 24px;
  overflow: hidden;
`
const WSCProgressStakingButton = styled(NFTWSCTokenButton)`
  border: 0.3px solid ${TangleColors.white} !important;
`
export default NFTDetailsContainer
