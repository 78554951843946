import React, { FC } from "react"
import styled from "styled-components"
import chevronUp from "assets/chevron-up.svg"
import chevronDown from "assets/chevron-down.svg"
interface IconProps {
  networkDropDownIconOpen?: boolean
  pendingTransaction?: boolean
  dropIconRef?: any
}

const MobileNetworkDropIcon: FC<IconProps> = (props) => {
  const { networkDropDownIconOpen, pendingTransaction, dropIconRef } = props
  return (
    <DropdownCover
      className="NetworkDropIcon"
      pendingTransaction={pendingTransaction}
      ref={dropIconRef}
      src={networkDropDownIconOpen ? chevronUp : chevronDown}
    />
  )
}
const DropdownCover = styled.img<IconProps>`
  display: ${(props) => (props.pendingTransaction ? "none" : "flex")};
  height: 20px !important;
  width: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in;
`

export default MobileNetworkDropIcon
