import React, { FC, useCallback, useEffect, useState } from "react"
import RangeSelector from "./RangeSelector"

interface CounterProps {
  value: string
  onUserInput: (value: string) => void
  decrement: () => void
  increment: () => void
  left?: boolean
  right?: boolean
  disable?: boolean
  name?: string
  placeholder?: string
  title: string
  tokenA?: any
  tokenB?: any
}
const InputCounter: FC<CounterProps> = (props) => {
  const {
    value,
    decrement,
    increment,
    onUserInput,
    title,
    name,
    right,
    left,
    tokenA,
    tokenB,
    disable,
    placeholder,
  } = props
  const [active, setActive] = useState<boolean>(false)

  // let user type value and only update parent value on blur
  const [localValue, setLocalValue] = useState<any>("")
  const [useLocalValue, setUseLocalValue] = useState<boolean>(false)

  // animation if parent value updates local value
  // const [pulsing, setPulsing] = useState<boolean>(false)

  const handleOnFocus = () => {
    setUseLocalValue(true)
    setActive(true)
  }

  const handleOnBlur = useCallback(() => {
    setUseLocalValue(false)
    setActive(false)
    onUserInput(localValue) // trigger update on parent value
  }, [localValue, onUserInput])

  // for button clicks
  const handleDecrement = useCallback(() => {
    setUseLocalValue(false)
    decrement()
  }, [decrement])

  const handleIncrement = useCallback(() => {
    setUseLocalValue(false)
    increment()
  }, [increment])

  useEffect(() => {
    if (localValue !== value && !useLocalValue) {
      setTimeout(() => {
        setLocalValue(value) // reset local value to match parent
      }, 0)
    }
  }, [localValue, useLocalValue, value])
  return (
    <>
      {" "}
      <RangeSelector
        value={localValue}
        right={right}
        left={left}
        title={title}
        onUserInput={(val) => {
          setLocalValue(val)
        }}
        disable={disable}
        name={name}
        tokenA={tokenA}
        tokenB={tokenB}
        handleOnFocus={handleOnFocus}
        handleOnBlur={handleOnBlur}
        active={active}
        decrement={handleDecrement}
        increment={handleIncrement}
        placeholder={placeholder}
      />
    </>
  )
}

export default InputCounter
