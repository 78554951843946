import React from "react"
import styled from "styled-components"
import { Body1, Header2 } from "styles/TextStyles"
import { MainLinkButton } from "styles/ButtonStyles"
import { TangleColors } from "styles/ColorStyles"

const NotFoundContainer = () => {
  return (
    <Body>
      <Cover>
        <NoftH1>404 — Not Found 🛸 👾</NoftH1>
        <NotPara>
          {" "}
          Oops, you ventured into uncharted territory and found the lost
          spaceship. {"Let's"} go back!
        </NotPara>
        <NotButton to="/">Head home</NotButton>
      </Cover>
    </Body>
  )
}
const Body = styled.div`
  width: 100%;
  min-height: 100vh;
  background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/the-lost-ship.png")
    no-repeat center center/cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 650px) {
    background: url("https://d3m3d54t409w7t.cloudfront.net/homepage/the-lost-ship.png")
      no-repeat center center/contain;
  }

  :after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
`
const Cover = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
  z-index: 4;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${TangleColors.white};
`
const NoftH1 = styled(Header2)`
  text-align: center;
`
const NotPara = styled(Body1)`
  text-align: center;
`
const NotButton = styled(MainLinkButton)`
  margin: 32px 0;
`
export default React.memo(NotFoundContainer)
