import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { CgArrowsBreakeV } from "react-icons/cg"
interface BoxProps {
  bothTokensDisabled?: boolean
}
const OutRangeBox: FC<BoxProps> = (props) => {
  const { bothTokensDisabled } = props
  const OUR_OF_RANGE = "https://docs.tangleswap.exchange/dapps/liquidity"
  return (
    <BottomLargeBox>
      <BottomBoxIcon />
      {bothTokensDisabled ? (
        <BoxSmallTitle>
          Invalid range selected. The min price must be lower than the max
          price.{" "}
          <a href={`${OUR_OF_RANGE}`} target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </BoxSmallTitle>
      ) : (
        <BoxSmallTitle>
          The market price is outside your specified price range. Single-asset
          deposit only.{" "}
          <a href={`${OUR_OF_RANGE}`} target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </BoxSmallTitle>
      )}
    </BottomLargeBox>
  )
}

const BottomLargeBox = styled.div`
  height: 104px;
  max-width: 996px;
  width: 100%;
  background: ${TangleColors.feeRateText};
  padding: 4px 20px;
  margin: 0 auto;
  border-radius: 20px;

  display: grid;
  grid-template-columns: 28px auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
`

const BottomBoxIcon = styled(CgArrowsBreakeV)`
  color: ${TangleColors.black};
  height: 28px;
  width: 28px;
`

const BoxSmallTitle = styled(Caption)`
  color: ${TangleColors.black};

  width: 100%;
  margin: 0 auto;
  text-align: left;
  a {
    margin: 0 2px 0 4px;
    /* text-underline-offset: 4px; */
    color: ${TangleColors.cta};
    transition: 0.4s ease-in;
    text-decoration-color: ${TangleColors.cta};
    -webkit-text-decoration-color: ${TangleColors.cta};
    :hover {
      color: ${TangleColors.white};
      text-decoration-color: ${TangleColors.white};
      -webkit-text-decoration-color: ${TangleColors.white};
    }
  }
`
export default OutRangeBox
