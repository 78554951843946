import {
  useState,
  Fragment,
  FC,
  useEffect,
  useCallback,
  RefObject,
} from "react"
import {
  ActionButton,
  ButtonContainer,
  CurrentStepWrapper,
  StepButton,
  StepContainer,
  StepDivider,
  StepTitle,
  StepWrapper,
  TitleText,
  TitleTextContainer,
  WrapContainer,
} from "../styles"
import ActionExecutionStep from "../transactionStepper/ActionExecutionStep"
import { IFarmData } from "interfaces/wscSwap.interface"
import ModalTopIcon from "../component/ModalTopIcon"
import { useWSCContext } from "context/MilkomedaContext"
import { useTangleship } from "utils/useTangleship"
import NFTTokenAllowanceStep from "../transactionStepper/NFTTokenAllowanceStep"
import WSCModalCover from "../component/WSCModalCover"
import { useBackup } from "../component/Backup"
import WrapGas from "../transactionStepper/WrapGasStep"
import { useCheckBalanceForGas } from "../component/GasCheck"
import { LOVELACE_UNIT } from "constants/milkomeda/transaction"

interface BeginFarmModalProps {
  isVisible?: boolean
  toggleModal?: () => void
  wrapData?: IFarmData
  closeWSCContinue?: () => void
  wscModalRef?: RefObject<any>
  cancelTransaction?: () => void
}

const BeginFarmModal: FC<BeginFarmModalProps> = (props) => {
  const {
    isVisible,
    toggleModal,
    wrapData,
    closeWSCContinue,
    cancelTransaction,
    wscModalRef,
  } = props
  const { chainId, account } = useWSCContext()
  const { tangleship } = useTangleship()

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [needApproval, setNeedApproval] = useState<boolean>(false)
  const [steps, setSteps] = useState<any[]>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const actionType = "Farm"
  const { data, backup } = useBackup(steps, actionType, setCurrentStep) as {
    data: IFarmData
    backup: boolean
  }
  const { needGasWrap } = useCheckBalanceForGas()
  const gasWrap =
    needGasWrap &&
    !wrapData?.tokenIn?.some((token) => token.unit === LOVELACE_UNIT)

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (isComplete) setIsComplete(false)
  }, [wrapData, backup, data])

  useEffect(() => {
    if (!backup) {
      setCurrentStep(1)
    }

    const newSteps = []
    let stepCounter = 1

    if (gasWrap && !backup) {
      newSteps.push({
        number: stepCounter++,
        title: "Gas",
        actionTitle: "Wrapping",
        component: (
          <WrapGas
            nextStep={nextStep}
            actionType={actionType}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    if (needApproval) {
      newSteps.push({
        number: stepCounter++,
        title: "Approve",
        actionTitle: "Approve",
        component: (
          <NFTTokenAllowanceStep
            nextStep={nextStep}
            actionType={actionType}
            data={backup ? data : wrapData}
          />
        ),
      })
    }

    newSteps.push({
      number: stepCounter++,
      title: "Transact",
      actionTitle: "Transaction",
      component: (
        <ActionExecutionStep
          data={backup ? data : wrapData}
          nextStep={nextStep}
          actionType={actionType}
          isLastStep={true}
          isBackup={backup}
          toggleModal={toggleModal}
          setIsComplete={setIsComplete}
        />
      ),
    })

    setSteps(newSteps)
  }, [wrapData, needApproval, backup, data, needGasWrap])

  const fetchApproval = useCallback(
    async (refetch = false) => {
      const farmAddress = wrapData?.farmAddress

      if (!account || farmAddress === undefined || !chainId) return

      const storageKey = `farmApproved${chainId}${account}${farmAddress}`
      const storageValue = sessionStorage.getItem(storageKey)

      if (storageValue === null)
        sessionStorage.setItem(storageKey, "loading") // to avoid refetching
      else if (!refetch) {
        const booleanValue = storageValue === "true"
        if (storageValue !== "loading") setNeedApproval(!booleanValue)
        return
      }

      tangleship
        .getIsApprovedForAll(farmAddress, account, "LP")
        .then((res: any) => {
          sessionStorage.setItem(storageKey, String(res))
          setNeedApproval(!res)
          console.log("res", res)
        })
    },
    [account, wrapData?.farmAddress, chainId, tangleship]
  )

  useEffect(() => {
    fetchApproval()
  }, [fetchApproval])

  return isVisible ? (
    <WSCModalCover closeContinue={closeWSCContinue} bodyRef={wscModalRef}>
      <WrapContainer onClick={(e) => e.stopPropagation()}>
        <ModalTopIcon
          title="Begin Farming"
          closeModal={toggleModal}
          cancelTransaction={cancelTransaction}
        />

        <TitleTextContainer>
          <TitleText>{steps[currentStep - 1]?.actionTitle}</TitleText>
        </TitleTextContainer>
        {steps.length > 1 && (
          <StepWrapper>
            {steps.map((step, index) => (
              <Fragment key={step.number}>
                <StepContainer>
                  <StepButton
                    active={currentStep === step.number}
                    onClick={() => {
                      if (currentStep === step.number) return
                      setCurrentStep(step.number)
                    }}
                  >
                    {step.number}
                  </StepButton>
                  <StepTitle active={currentStep === step.number}>
                    {step.title}
                  </StepTitle>
                </StepContainer>
                {index < steps.length - 1 && <StepDivider />}
              </Fragment>
            ))}
          </StepWrapper>
        )}
        <CurrentStepWrapper>
          {steps[currentStep - 1]?.component}
        </CurrentStepWrapper>
        {isComplete && currentStep === steps.length && (
          <ButtonContainer>
            <ActionButton onClick={toggleModal}>
              Continue using TangleSwap
            </ActionButton>
          </ButtonContainer>
        )}
      </WrapContainer>
    </WSCModalCover>
  ) : null
}

export const useBeginFarmModalControl = () => {
  const [isVisible, setIsVisible] = useState(false)
  const toggle = () => setIsVisible(!isVisible)

  return { isVisible, toggle }
}

export default BeginFarmModal
