import { getDivisibleTicker, tickToPrice } from "@tangleswap/sdk"
import { TickProcessedForChart } from "./types"

// Funct to calculate liquidity distribution from (-∞, ∞):
const computeSurroundingTicks = (
  activeTickProcessed: any,
  sortedTickData: any,
  pivot: number,
  ascending: boolean,
  decimals0: number,
  decimals1: number
) => {
  let previousTickProcessed = {
    ...activeTickProcessed,
  }
  // Iterate outwards (either up or down depending on direction) from the active tick,
  // building active liquidity for every tick.
  let processedTicks = []
  for (
    // let i = pivot + (ascending ? 1 : -1); // @dev: don't delete - Uni bug?
    let i = pivot + (ascending ? 1 : 0);
    ascending ? i < sortedTickData.length : i >= 0;
    ascending ? i++ : i--
  ) {
    const tick = Number(sortedTickData[i]?.tick)
    const currentTickProcessed = {
      liquidityActive: previousTickProcessed.liquidityActive,
      tick,
      liquidityNet: BigInt(sortedTickData[i]?.liquidityNet),
      price0: tickToPrice(tick, decimals0, decimals1),
    }

    // Update the active liquidity.
    // If we are iterating ascending and we found an initialized tick we immediately apply
    // it to the current processed tick we are building.
    // If we are iterating descending, we don't want to apply the net liquidity until the following tick.
    if (ascending) {
      currentTickProcessed.liquidityActive =
        previousTickProcessed?.liquidityActive +
        BigInt(sortedTickData[i]?.liquidityNet)
    } else if (
      !ascending &&
      previousTickProcessed?.liquidityNet !== BigInt(0)
    ) {
      // We are iterating descending, so look at the previous tick and apply any net liquidity.
      currentTickProcessed.liquidityActive =
        previousTickProcessed?.liquidityActive -
        previousTickProcessed?.liquidityNet
    }

    processedTicks.push(currentTickProcessed)
    previousTickProcessed = currentTickProcessed
  }

  if (!ascending) processedTicks = processedTicks.reverse()

  return processedTicks
}

// Funct to make the d3 chart display the actual data when there aren't many positions:
// const augmentTickDataIfNeeded = (
//   sortedTickData: any,
//   maxDatapointsToTrigger = 20,
//   augmentationInterval = 100000
// ) => {
//   let augmentedData = [...sortedTickData]

//   if (sortedTickData.length <= maxDatapointsToTrigger) {
//     for (let i = 0; i < sortedTickData.length - 1; i++) {
//       const currentTick = sortedTickData[i].tick
//       const nextTick = sortedTickData[i + 1].tick
//       const tickDifference = Math.abs(nextTick - currentTick)

//       if (tickDifference > augmentationInterval) {
//         let newTick =
//           Math.ceil(currentTick / augmentationInterval) * augmentationInterval
//         while (newTick < nextTick) {
//           augmentedData.push({
//             liquidityNet: "1",
//             tick: newTick,
//           })
//           newTick += augmentationInterval
//         }
//       }
//     }
//   }

//   return augmentedData
// }

// Funct to prepare raw liquidity data for comsumption by the chart:
export const formatTickData = (
  tickData: any,
  slot0: any,
  poolLiquidity: number,
  feeTier: number,
  decimals0: number,
  decimals1: number,
  isSameBlockhainOrder: boolean
) => {
  try {
    // tickData = augmentTickDataIfNeeded(tickData)

    if (isSameBlockhainOrder === false)
      [decimals0, decimals1] = [decimals1, decimals0]

    const currentTick = slot0?.tick
    const activeTick = getDivisibleTicker(currentTick, feeTier)

    const pivot = tickData.findIndex(({ tick }) => tick > activeTick) - 1

    const activeTickProcessed = {
      liquidityActive: BigInt(poolLiquidity ?? 0),
      tick: activeTick,
      liquidityNet:
        Number(tickData[pivot]?.tick) === activeTick
          ? BigInt(tickData[pivot]?.liquidityNet)
          : BigInt(0),
      price0: tickToPrice(activeTick, decimals0, decimals1),
    }

    const subsequentTicks = computeSurroundingTicks(
      activeTickProcessed,
      tickData,
      pivot,
      true,
      decimals0,
      decimals1
    )
    const previousTicks = computeSurroundingTicks(
      activeTickProcessed,
      tickData,
      pivot,
      false,
      decimals0,
      decimals1
    )
    const ticksProcessed = previousTicks
      .concat(activeTickProcessed)
      .concat(subsequentTicks)

    if (!(ticksProcessed?.length > 0)) return undefined

    let formatData = []
    const baselineValue = ticksProcessed.reduce((min, item) => {
      const liq = parseFloat(item.liquidityActive.toString())
      return min === null || liq < min ? liq : min
    }, null)

    for (const tick of ticksProcessed) {
      const chartEntry = {
        activeLiquidity:
          parseFloat(tick.liquidityActive.toString()) + Math.abs(baselineValue),
        price0: parseFloat(tick.price0),
      }
      // if (chartEntry.activeLiquidity > 0) formatData.push(chartEntry) // @dev: Uni bug?
      if (tick.liquidityNet !== BigInt(0)) formatData.push(chartEntry) // @dev: don't delete - TS alternative?
    }

    if (isSameBlockhainOrder === false) {
      // If reversed tokens, display reversed liquidity chart:
      formatData = formatData.map((item, index) => {
        const prevIndex = index - 1
        return {
          activeLiquidity: parseFloat(
            prevIndex >= 0 ? formatData[prevIndex].activeLiquidity : 0
          ),
          price0: parseFloat(String(1 / Number(item.price0))),
        }
      })
      formatData = formatData.sort((a, b) => a.price0 - b.price0)
    }

    return formatData
  } catch (err) {}
}
