import { Book1, Bookmark, Chart, CodeCircle } from "iconsax-react"
import { TangleColors } from "styles/ColorStyles"

export interface NavTypes {
  id: number
  text: string
  link: string
  external: boolean
}
export interface NavExternalTypes {
  id: number
  text: string
  link: string
  icon: any
  external: boolean
}

export const navlinkData = [
  // { text: "Home", link: "" },
  {
    id: 1,
    text: "Trade",
    link: "swap",
    external: false,
  },
  {
    id: 2,
    text: "Liquidity",
    link: "pools",
    external: false,
  },
  {
    id: 3,
    text: "Farm",
    link: "farm",
    external: false,
  },
  {
    id: 4,
    text: "Stake",
    link: "stake",
    external: false,
  },
  /* {
    id: 5,
    text: "NFT",
    link: "nft-staking",
    external: false,
  }, */
  {
    id: 6,
    text: "Invest",
    link: "invest",
    external: false,
  },
]

export const NavbarExternalData: NavExternalTypes[] = [
  {
    id: 1,
    text: "Bridge",
    icon: <CodeCircle size="22" color={TangleColors.heroCTA} />,
    link: "https://bridge.tangleswap.exchange/",
    external: true,
  },
  {
    id: 2,
    text: "Analytics",
    icon: <Chart size="22" color={TangleColors.heroCTA} />,
    link: "https://info.tangleswap.exchange/",
    external: true,
  },
  {
    id: 3,
    text: "Documentation",
    icon: <Bookmark size="22" color={TangleColors.heroCTA} />,
    link: "https://docs.tangleswap.exchange/",
    external: true,
  },
  {
    id: 4,
    text: "Whitepaper",
    icon: <Book1 size="22" color={TangleColors.heroCTA} />,
    link: "https://whitepaper.tangleswap.exchange/",
    external: true,
  },
]
