import React from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption } from "styles/TextStyles"
import { escapeRegExp } from "utils/index"

interface SlippageProps {
  percent?: boolean
  minute?: boolean
}
export const SlippageInput = React.memo(function SlippageInput({
  percent,
  value,
  onUserInput,
  placeholder,
  minute,
  name,
  ...rest
}: {
  percent?: boolean
  minute?: boolean
  placeholder?: string
  name: string

  value?: any
  onUserInput?: (value: any) => void
} & Omit<React.HTMLProps<HTMLInputElement>, "ref" | "onChange" | "as">) {
  const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === "" || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <>
      <Wrapper>
        <Input
          pattern="^[0-9]*[.,]?[0-9]*$"
          name={name}
          value={value}
          id={name}
          autoComplete="off"
          autoCorrect="off"
          type="text"
          percent={percent}
          minute={minute}
          spellCheck="false"
          onChange={(event) => {
            // replace commas with periods, because we exclusively uses period as the decimal separator
            enforcer(event.target.value.replace(/,/g, "."))
          }}
          placeholder={placeholder}
        ></Input>
        {percent && (
          <Percent>
            <PercentText>%</PercentText>
          </Percent>
        )}
      </Wrapper>
    </>
  )
})
const Wrapper = styled.div`
  max-width: 216px;
  width: 100%;
  position: relative;
  margin: 0 8px 0 0;
`

const Input = styled.input<SlippageProps>`
  display: flex;
  flex-direction: row;
  padding: ${(props) =>
    props.minute ? `16px 24px 16px 0` : `16px 12px 16px 0`};
  text-align: right;
  width: 100%;
  outline: none;
  max-width: 216px;
  height: 32px;

  background: ${TangleColors.black};
  cursor: pointer;
  color: ${TangleColors.white};
  transition: 0.4s ease-in;
  border-radius: 14px;
  border: 1px solid ${TangleColors.white};
  caret-color: ${TangleColors.white};
  color: ${TangleColors.white};
  font-family: "CabinetGrotesk Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  margin: 0;
  @media only screen and (max-width: 650px) {
    font-size: 12px;
  }
  ::placeholder {
    color: ${TangleColors.white};
    font-family: "CabinetGrotesk Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    @media only screen and (max-width: 650px) {
      font-size: 12px;
    }
  }

  :hover {
    outline: none;
    border: 1px solid ${TangleColors.lighthover};
  }
`

const Percent = styled.div`
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translate(-50%, -50%);
`
const PercentText = styled(Caption)`
  color: ${TangleColors.white};
`
