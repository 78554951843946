import React, { FC } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Subtitle } from "styles/TextStyles"

import { GrClose } from "react-icons/gr"
import { SwapTokenButton } from "styles/ButtonStyles"
import { convertNumber, toSignificantDigits } from "utils/toSignificant"
import ConvertTokenSymbol from "utils/ConvertTokenSymbol"

interface RowsProps {
  inRange?: boolean
  closedLP?: boolean
  outRange?: boolean
  inputValue: any
  inputSymbol: any
  inputLogo: any
  outputValue: any
  outputSymbol: any
  outputLogo: any
  closeRemoveModal: () => void
  clickAdd: () => void
  lpModalRef: any
  lpBodyClick: (e: any) => void
}
const IncreaseLiquidityModal: FC<RowsProps> = (props) => {
  const {
    closeRemoveModal,
    clickAdd,
    inputValue,
    inputSymbol,
    inputLogo,
    outputValue,
    outputSymbol,
    lpModalRef,
    outputLogo,
    lpBodyClick,
  } = props

  return (
    <Body ref={lpModalRef} onClick={lpBodyClick}>
      <Cover>
        <LPNavigation>
          <IconText> Increase Liquidity</IconText>

          <CloseIconWrapper onClick={closeRemoveModal}>
            {" "}
            <CloseIcon />
          </CloseIconWrapper>
        </LPNavigation>

        <InfoTierTop>
          {/* start of  box */}
          <LiquidityBox>
            <BoxBottom>
              <BowRow>
                <LPBoxValue>Pooled {inputSymbol}:</LPBoxValue>
                <IconWrap>
                  <IconName>
                    {toSignificantDigits(inputValue, 4, true, 18)}
                  </IconName>
                  {!!inputLogo ? (
                    <>
                      <BoxIcon src={inputLogo} />
                    </>
                  ) : (
                    <>
                      {" "}
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={inputSymbol} />
                      </CustomSizeWrapper>
                    </>
                  )}

                  <IconName>{inputSymbol}</IconName>
                </IconWrap>
              </BowRow>

              <BowRow>
                <LPBoxValue>Pooled {outputSymbol}:</LPBoxValue>
                <IconWrap>
                  <IconName>
                    {toSignificantDigits(outputValue, 4, true, 18)}
                  </IconName>

                  {!!outputLogo ? (
                    <>
                      <BoxIcon src={outputLogo} />
                    </>
                  ) : (
                    <>
                      {" "}
                      <CustomSizeWrapper>
                        <ConvertTokenSymbol text={outputSymbol} />
                      </CustomSizeWrapper>
                    </>
                  )}
                  <IconName>{outputSymbol}</IconName>
                </IconWrap>
              </BowRow>
            </BoxBottom>
          </LiquidityBox>

          {/* end of  box */}
        </InfoTierTop>

        <SwapButtons onClick={clickAdd}>
          <SwapButton>Add</SwapButton>
        </SwapButtons>
      </Cover>
    </Body>
  )
}

export default IncreaseLiquidityModal

const Body = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 800;
  background: rgba(255, 255, 255, 0.5);
  padding: 16px 24px;
`
const Cover = styled.div`
  max-width: 490px;
  width: 100%;
  margin: 24px auto;
  border-radius: 40px;
  padding: 20px;
  background: ${TangleColors.swapBG};
  color: ${TangleColors.white};
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${TangleColors.lighthover};
`

const CloseIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 16px;
  right: 24px;
  cursor: pointer;
`
const CloseIcon = styled(GrClose)`
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in;
  path {
    stroke: ${TangleColors.grayDark};
    fill: ${TangleColors.grayDark};
  }
  :hover {
    path {
      stroke: ${TangleColors.lighthover};
      fill: ${TangleColors.lighthover};
    }
  }
`

const LPNavigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavTop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 240px;
  margin: 8px 0;
  justify-content: center;
  align-items: center;
`

const IconText = styled(Subtitle)`
  margin: 0 8px;
  color: ${TangleColors.white};
  transition: 0.4s ease;
  ${NavTop}:hover & {
    color: ${TangleColors.grayLight};
  }
`

const InfoTierTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const LiquidityBox = styled.div`
  width: 100%;
  border-radius: 32px;
  background: ${TangleColors.swapBG};
  display: flex;
  flex-direction: column;
`

const BoxBottom = styled.div`
  height: 130px;
  max-width: 680px;
  background: ${TangleColors.tangleBlack};
  border-radius: 24px;
  padding: 24px;
  width: 100%;
  margin: 24px auto;
`
const BowRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 16px 0;
  white-space: nowrap;
`
const BoxIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
`
const LPBoxValue = styled.div``

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconName = styled(Caption)`
  margin: 0 8px;
  color: ${TangleColors.white};
`

const SwapButtons = styled.div`
  width: 100%;
  margin: 32px 0;
`

const SwapButton = styled(SwapTokenButton)``
const CustomSizeWrapper = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  z-index: 1;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
