import { TxStatus } from "constants/milkomeda/transaction"
import { useState } from "react"

export const useTransactionStatus = () => {
  const [txStatus, setTxStatus] = useState<keyof typeof TxStatus>(TxStatus.Idle)
  const [txStatusError, setTxStatusError] = useState<string | null>(null)

  const isIdle = txStatus === TxStatus.Idle
  const isLoading =
    txStatus === TxStatus.Init ||
    txStatus === TxStatus.Pending ||
    txStatus === TxStatus.WaitingL1Confirmation ||
    txStatus === TxStatus.WaitingBridgeConfirmation ||
    txStatus === TxStatus.WaitingL2Confirmation
  const isError =
    txStatus === TxStatus.Error || txStatus === TxStatus.InputError
  const isSuccess = txStatus === TxStatus.Confirmed
  const isErrorWhileFetching = txStatus === TxStatus.FetchError

  return {
    txStatus,
    txStatusError,
    isErrorWhileFetching,
    setTxStatusError,
    setTxStatus,
    isIdle,
    isLoading,
    isError,
    isSuccess,
  }
}
