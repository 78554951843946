import React, { FC, useEffect, useRef, useState } from "react"
import styled from "styled-components"

import MobileAccountWrapper from "./mobile/MobileAccountWrapper"

interface DropProps {
  openMenu?: boolean
}

const MobileAccountNavbar: FC<DropProps> = (props) => {
  const [isAddressOpen, setIsAddressOpen] = useState<boolean>(false)
  const [networkDropDownOpen, setNetworkDropDownOpen] = useState<boolean>(false)
  const [activeMenu, setActiveMenu] = useState("mainDropdown")
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const wrapRef = useRef<HTMLDivElement | any>()
  const modalWrapperRef = useRef<any>(null)

  const openNetworkDropdown = () => {
    setNetworkDropDownOpen(true)
    setIsAddressOpen(false)
    setOpenMenu(true)
  }
  const openMainAddressMenu = () => {
    setIsAddressOpen(true)
    setActiveMenu("mainDropdown")
    setNetworkDropDownOpen(false)
  }
  const openTransactionMenu = () => {
    setIsAddressOpen(true)
    setActiveMenu("transactionHistory")
    setNetworkDropDownOpen(false)
  }
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        modalWrapperRef.current &&
        !modalWrapperRef.current.contains(e.target)
      ) {
        setIsAddressOpen(false)
        setNetworkDropDownOpen(false)
        setActiveMenu("mainDropdown")
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <NavButtonCover ref={modalWrapperRef}>
      <MobileAccountWrapper />
    </NavButtonCover>
  )
}

const NavButtonCover = styled.div`
  width: 100%;
  display: flex;
  margin: 0;

  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 650px) {
    max-width: 800px;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
  }
`

export default MobileAccountNavbar
