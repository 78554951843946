import { createClient } from "wagmi"
import {
  CARDANO_ALLOWED_WALLETS,
  DEVNET_BLOCKFROST_ID,
  DEVNET_CHAINID,
  DEVNET_NETWORK,
  DEVNET_ORACLE_URL,
  MAINNET_BLOCKFROST_ID,
  MAINNET_CHAINID,
  MAINNET_NETWORK,
  MAINNET_ORACLE_URL,
} from "./config"
import { supportedChains } from "./supportedChains"
import {
  MilkomedaNetworkName,
  getDefaultConfig,
} from "milkomeda-wsc-ui-test-beta"
import { MetaMaskConnector } from "wagmi/connectors/metaMask"
import { Chains } from "@tangleswap/sdk"

/* const defaultChainId =
  Number(localStorage.getItem("defaultChainId")) || Chains.CARDANO

const chainId =
  defaultChainId === DEVNET_CHAINID ? DEVNET_CHAINID : MAINNET_CHAINID */

export const supportedChain =
  supportedChains.find((chain) => chain.id === MAINNET_CHAINID) ??
  supportedChains[0]

export const metamaskConnector = new MetaMaskConnector({
  chains: [supportedChain],
})

export const wagmiClientTestnet = createClient(
  getDefaultConfig({
    oracleUrl: DEVNET_ORACLE_URL,
    blockfrostId: "preprodliMqEQ9cvQgAFuV7b6dhA4lkjTX1eBLb",
    chains: [supportedChain],
    network: DEVNET_NETWORK as MilkomedaNetworkName,
    autoConnect: false,
    cardanoWalletNames: CARDANO_ALLOWED_WALLETS as any,
  })
)

export const wagmiClientMainnet = createClient(
  getDefaultConfig({
    oracleUrl: MAINNET_ORACLE_URL,
    blockfrostId: "mainnetaD8KnvUxv9pSLIVBQvdPKeVHtSjy5HcB",
    chains: [supportedChain],
    network: MAINNET_NETWORK as MilkomedaNetworkName,
    autoConnect: false,
    cardanoWalletNames: CARDANO_ALLOWED_WALLETS as any,
  })
)
