import React, { FC, useCallback } from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Body4, Caption, CaptionTiny } from "styles/TextStyles"
interface TierProps {
  editActive?: boolean
  title?: any
  text?: string
  selected?: boolean
  number?: any
  handleFeePoolSelect?: (feeAmount: any) => void
  hide?: boolean
}
const TiersBox: FC<TierProps> = (props) => {
  const { title, text, number, handleFeePoolSelect, selected } = props

  const handleFeePoolSelectWithEvent = useCallback(
    (fee: any) => {
      handleFeePoolSelect(fee)
    },
    [handleFeePoolSelect]
  )

  const feeAmountNumber = title * 10000

  return (
    <Body
      onClick={() => handleFeePoolSelectWithEvent(feeAmountNumber)}
      selected={selected}
    >
      <Cover>
        <TiersTitle>{title}%</TiersTitle>
        <TierText>{text}</TierText>

        <SelectButton hide={number === "undefined" || number === undefined}>
          <>
            {isNaN(Number(number)) ||
            Number.isNaN(number) ||
            number === "undefined" ||
            number === undefined ||
            number === null ? (
              <SelectText>Not created</SelectText>
            ) : (
              <>
                {" "}
                <SelectText>
                  {String(number) === "0" || Number(number) === 0
                    ? "0"
                    : number}
                  % select
                </SelectText>
              </>
            )}
          </>
        </SelectButton>
      </Cover>
    </Body>
  )
}

const Body = styled.div<TierProps>`
  height: 134px;
  max-width: 250px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.5px 8px 3px 8px;
  border: ${(props) =>
    props.selected
      ? `2px solid ${TangleColors.cta}`
      : `2px solid ${TangleColors.tangleBlack}`};

  border-radius: 16px;

  transition: 0.4s ease-in;
  :hover {
    border: 1px solid ${TangleColors.cta};
  }
`
const Cover = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${TangleColors.white};
  margin: 0 !important;
`
const TiersTitle = styled(Caption)`
  color: ${TangleColors.white};
  text-align: center;
  margin: -2px 0 0 0 !important;
  line-height: 1 !important;
`
const TierText = styled(Body4)`
  text-align: center;
  margin: 4px 0;
  color: ${TangleColors.white};
`
const SelectButton = styled.button<TierProps>`
  color: ${TangleColors.black};
  height: 24px;
  max-width: 100px;
  border: none;
  width: 100%;
  margin: 8px auto 0 auto;
  border-radius: 12px;
  padding: 6px;
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const SelectText = styled(CaptionTiny)``
export default TiersBox
