import { useWSCContext } from "context/MilkomedaContext"
import { PendingStateContext } from "context/PendingContext"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { shortenAddress } from "utils/index"
import { HeaderNavbar } from "styles/TextStyles"
import AccountDropdown from "./AccountDropdown"
import AddressDropIcon from "./AddressDropIcon"
import AddressLoadingSpinner from "components/utils/AddressLoadingSpinner"
import { useAppDispatch } from "store/hooks"
import { openAddressMenu } from "store/actions/MobileNavAction"
import React from "react"
import Jazzicon from "./JazzIcon"
import PendingLoaderSpinner from "components/utils/PendingLoaderSpinner"
import { WalletLoadingContext } from "context/WalletContext"
import { Dots } from "components/utils/LoadingDots"

interface NavProps {
  openDropDown?: () => void
  pendingTransaction?: boolean
  isAddressOpen?: boolean
  activeMenu?: any
  openMainAddressMenu?: () => void
  openTransactionMenu?: () => void
  goBackToMainMenu?: () => void
}

const AddressCover: React.FC<NavProps> = (props) => {
  const {
    isAddressOpen,
    openMainAddressMenu,
    activeMenu,
    openTransactionMenu,
    goBackToMainMenu,
  } = props
  const { pendingTransaction } = React.useContext(PendingStateContext)
  const { walletLoading } = React.useContext(WalletLoadingContext)
  const [hoverPending, setHoverPending] = React.useState<boolean>(false)
  const { account } = useWSCContext()
  const dispatch = useAppDispatch()

  const openMainAddressDropdown = () => {
    dispatch(openAddressMenu())
  }
  const onMouseEnter = () => {
    setHoverPending(true)
  }
  const onMouseLeave = () => {
    setHoverPending(false)
  }
  return (
    <CoverAddress
      isAddressOpen={isAddressOpen}
      pendingTransaction={pendingTransaction}
    >
      <AddressCoverWrap
        onClick={openMainAddressMenu}
        pendingTransaction={Boolean(pendingTransaction)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {walletLoading ? (
          <>
            <AddressText>
              <PendingLoaderSpinner hoverPending={hoverPending} />
              Connecting <Dots walletConnection={walletLoading} />
            </AddressText>
          </>
        ) : (
          <>
            {" "}
            {account !== undefined ? (
              <>
                <AddressWrap>
                  <AddressText pendingTransaction={Boolean(pendingTransaction)}>
                    <>
                      {pendingTransaction ? (
                        <AddressText>
                          <PendingLoaderSpinner hoverPending={hoverPending} />{" "}
                          Pending
                        </AddressText>
                      ) : (
                        <>
                          <AddressText>
                            <Jazzicon address={account} />
                            {shortenAddress(account)}
                          </AddressText>
                        </>
                      )}
                    </>
                  </AddressText>
                  {!walletLoading ? (
                    <>
                      {" "}
                      <NetworkWrapper>
                        <AddressDropIcon
                          addressOpen={isAddressOpen}
                          pendingTransaction={pendingTransaction}
                          hoverPending={hoverPending}
                        />
                      </NetworkWrapper>
                    </>
                  ) : null}
                </AddressWrap>
              </>
            ) : (
              <SpinnerBody>
                <AddressLoadingSpinner />
              </SpinnerBody>
            )}
          </>
        )}
      </AddressCoverWrap>
      <MobileAddressCoverWrap
        onClick={openMainAddressDropdown}
        pendingTransaction={Boolean(pendingTransaction)}
      >
        {account !== undefined ? (
          <>
            <AddressWrap>
              <AddressText pendingTransaction={Boolean(pendingTransaction)}>
                {pendingTransaction ? (
                  <>
                    <PendingLoaderSpinner hoverPending={hoverPending} />
                    Pending
                  </>
                ) : (
                  <AddressText>
                    <Jazzicon address={account} />
                    {shortenAddress(account)}
                  </AddressText>
                )}
              </AddressText>
              <NetworkWrapper>
                <AddressDropIcon
                  addressOpen={isAddressOpen}
                  pendingTransaction={pendingTransaction}
                />
              </NetworkWrapper>
            </AddressWrap>
          </>
        ) : (
          <SpinnerBody>
            <AddressLoadingSpinner />
          </SpinnerBody>
        )}
      </MobileAddressCoverWrap>
      <AccountDropdown
        addressOpen={isAddressOpen}
        activeMenu={activeMenu}
        goBackToMainMenu={goBackToMainMenu}
        openTransactionMenu={openTransactionMenu}
      />
    </CoverAddress>
  )
}

const CoverAddress = styled.div<NavProps>`
  max-width: ${(props) => (props.pendingTransaction ? `150px` : `200px`)};
  height: 64px;
  margin: 0 0 0 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 850px) {
    max-width: 800px;
    margin: 0;
    width: 100%;
    position: relative;
    z-index: 1;
  }
`
const SpinnerBody = styled.div`
  width: 180px;
  height: 64px;

  padding: 11px 16px 8px 16px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
const AddressWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 11px 28px 8px 16px;
  cursor: pointer;
`

const AddressCoverWrap = styled.div<NavProps>`
  height: 48px;
  max-width: ${(props) => (props.pendingTransaction ? `150px` : `200px`)};
  width: 100%;
  border-radius: 24px;
  display: flex;
  padding: 8px 2px;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  border: 1.5px solid transparent;
  background: ${(props) =>
    props.pendingTransaction
      ? `${TangleColors.lighthover}`
      : `${TangleColors.grayLighter}`};

  color: ${(props) =>
    props.pendingTransaction
      ? `${TangleColors.white}`
      : `${TangleColors.icon}`};
  &:hover {
    border: 1.5px solid ${TangleColors.white};
    background: ${TangleColors.black};
  }
  @media only screen and (max-width: 850px) {
    max-width: 740px;
    margin: 12px auto;
    height: 64px;
    width: 100%;
    padding: 8px 24px;
    border-radius: 32px;
    display: none;
  }
`
const MobileAddressCoverWrap = styled.div<NavProps>`
  height: 48px;
  max-width: ${(props) => (props.pendingTransaction ? `150px` : `200px`)};
  width: 100%;
  border-radius: 24px;
  display: none;
  padding: 8px 2px;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: 0.4s ease-in;
  border: 1.5px solid transparent;
  background: ${(props) =>
    props.pendingTransaction
      ? `${TangleColors.lighthover}`
      : `${TangleColors.grayLighter}`};

  color: ${(props) =>
    props.pendingTransaction
      ? `${TangleColors.white}`
      : `${TangleColors.icon}`};
  &:hover {
    border: 1.5px solid ${TangleColors.white};
    background: ${TangleColors.black};
  }
  @media only screen and (max-width: 850px) {
    max-width: 740px;
    margin: 12px auto;
    height: 64px;
    width: 100%;
    padding: 8px 24px;
    border-radius: 32px;
    display: flex;
  }
`

const AddressText = styled(HeaderNavbar)<NavProps>`
  margin: ${(props) => (props.pendingTransaction ? `0 0 0 16px` : `0 4px 0 `)};
  color: ${(props) =>
    props.pendingTransaction
      ? `${TangleColors.white}`
      : `${TangleColors.icon}`};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;

  align-items: center;

  transition: 0.4s ease-in;
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  ${AddressCoverWrap}:hover & {
    color: ${TangleColors.white};
  }
`

const NetworkWrapper = styled.div`
  height: 26px;
  width: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${AddressCoverWrap}:hover & {
    img {
      filter: invert(1);
    }
  }
`

export default AddressCover
