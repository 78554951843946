import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import { Caption, Header7 } from "styles/TextStyles"
interface ParaTextProps {
  left?: boolean
}
export const MainParaTokenText = styled(Caption)<ParaTextProps>`
  color: ${TangleColors.lighthover};
  margin: ${(props) => (props.left ? `0 6px 0 0` : `0 0 0 6px`)};
`
export const MainTitleTokenText = styled(Header7)`
  color: ${TangleColors.white};
`

export const MainParaTokenName = styled(Caption)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 2px 0;
`

export const LaunchPadImageWrapper = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 12px 0 20px 0;
  height: 130px;
`
export const LaunchPadImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
