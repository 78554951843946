import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import styled from "styled-components"
import { TangleColors } from "styles/ColorStyles"
import CreationContext from "../CreationContext"
import mintSVG from "assets/tokentype01.svg"
import fixedSVG from "assets/tokentype02.svg"
import {
  ActionContainer,
  CounterColumn,
  FormRadioInput,
  Input,
  InputGroup,
  InputLabel,
  InputSubLabel,
  ItemBody,
  ItemColumn,
  ItemSubTitle,
  ItemTitle,
  NextButton,
  StepCounter,
  TitleContainer,
} from "../CreateAuction"
import { SubmitHandler, useForm } from "react-hook-form"

import { useWSCContext } from "context/MilkomedaContext"
import { fetchTangleCurrency } from "components/Liquidity/utils/liquidity/useFetchLPCurrency"
import { IoWarningOutline } from "react-icons/io5"

import { Caption } from "styles/TextStyles"
import {} from "@tangleswap/sdk"
import {
  LaunchPadImage,
  LaunchPadImageWrapper,
} from "components/LaunchPad/utils/LaunchPadText"

import { useTangleship } from "utils/useTangleship"
import { isAddress } from "utils"
import { ethers } from "ethers"
interface TokenDetailsValue {
  tokenName?: string
  tokenSymbol?: string
  totalSupply?: number
  tokensForSale?: number
  tokenOption?: any
  providedToken?: any
  tokenType?: any
}

interface DetailsProps {
  selected?: boolean
  side?: string
  optionSelected?: boolean

  active?: boolean
}

const LaunchPadDetails = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TokenDetailsValue>({
    defaultValues: {
      tokenOption: "owned_token",
      tokenType: "fixed",
    },
    mode: "onChange",
    reValidateMode: "onChange",
  })
  const { account, chainId, l1ChainId } = useWSCContext()
  const [tokenSupply, setTokenSupply] = useState<number>(undefined)
  const [userBalance, setUserBalance] = useState<number>(undefined)
  const [addressInvalid, setAddressInvalid] = useState<boolean>(false)

  const { tangleship } = useTangleship()

  const onSubmit: SubmitHandler<TokenDetailsValue> = (data) => {
    const tokenName = data.tokenName
    const tokenSymbol = data.tokenSymbol
    const totalSupply = data.totalSupply
    const tokensForSale = data.tokensForSale
    const providedToken = data.providedToken

    if (tokenInfo?.decimals !== undefined && Number(tokenInfo?.decimals) !== 18)
      return
    if (watch("tokenOption") === "owned_token") {
      if (
        parseFloat(String(watch("tokensForSale"))) >
          parseFloat(String(tokenSupply)) ||
        !isAddress(providedToken)
      )
        return
      setTokenDetails({
        ...tokenDetails,
        tokenSetupType: "owned_token",
        tokenType: null,
        tokenAddress: providedToken,
        tokenName: tokenInfo?.name,
        tokenSymbol: tokenInfo?.symbol,
        tokenSupply: tokenSupply,
        tokensForSale: tokensForSale,
        tokenDecimals: tokenInfo?.decimals,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    } else if (watch("tokenOption") === "create_token") {
      if (
        parseFloat(String(watch("tokensForSale"))) >
        parseFloat(String(watch("totalSupply")))
      )
        return

      setTokenDetails({
        ...tokenDetails,
        tokenSetupType: "create_token",
        tokenType: watch("tokenType"),
        tokenAddress: providedToken,
        tokenName: tokenName,
        tokenSymbol: tokenSymbol,
        tokenSupply: totalSupply,
        tokensForSale: tokensForSale,
        tokenDecimals: 18,
      })
      setCurrentStep((currentStep: number) => currentStep + 1)
    }
  }
  useEffect(() => {
    if (watch("tokenOption") === "owned_token" && !!watch("providedToken"))
      return
    setValue("providedToken", "")
  }, [watch("tokenOption"), watch("providedToken")])

  useEffect(() => {
    if (watch("tokenOption") === "owned_token" && !watch("providedToken"))
      return
    const inValid = !isAddress(watch("providedToken"))
    setAddressInvalid(Boolean(inValid))
  }, [watch("tokenOption"), watch("providedToken")])

  const { tokenDetails, setTokenDetails, currentStep, setCurrentStep } =
    useContext(CreationContext)

  const [tokenInfo, setTokenInfo] = useState<any>()

  const fetchTokenInfo = useCallback(
    async (refetch = false) => {
      const addr = String(watch("providedToken"))?.trim()
      if (!addr || addr.length < 30 || !chainId) return

      const storageKey = `ihubNewAucTokInfo${chainId}${addr}`
      const storageValue = localStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setTokenInfo(JSON.parse(storageValue))
        return
      }

      const res = await fetchTangleCurrency(addr, l1ChainId, tangleship)
      localStorage.setItem(storageKey, JSON.stringify(res))
      setTokenInfo(res)
    },
    [chainId, tangleship, watch("providedToken")]
  )
  useEffect(() => {
    fetchTokenInfo(false)
  }, [fetchTokenInfo])

  const fetchTokenSupply = useCallback(
    async (refetch = false) => {
      const addr = String(watch("providedToken"))?.trim()?.toLowerCase()
      if (!addr || addr.length < 30 || !chainId) return

      const storageKey = `ihubTokenSupply${chainId}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setTokenSupply(Number(storageValue))
        return
      }

      const res = await tangleship?.getTokenSupply(addr)
      sessionStorage.setItem(storageKey, String(res))
      setTokenSupply(Number(res))
    },
    [chainId, tangleship, watch("providedToken")]
  )
  useEffect(() => {
    fetchTokenSupply(false)
  }, [fetchTokenSupply])

  useEffect(() => {
    if (watch("tokenOption") === "owned_token") {
      setTokenDetails({
        ...tokenDetails,
        tokenSetupType: "owned_token",
      })
    } else {
      setTokenDetails({
        ...tokenDetails,
        tokenSetupType: "create_token",
      })
    }
  }, [watch("tokenOption")])

  const fetchUserBalance = useCallback(
    async (refetch = false) => {
      const addr = String(watch("providedToken"))?.trim()?.toLowerCase()
      if (watch("tokenOption") === "create_token") {
        setUserBalance(Infinity)
        return
      }
      if (!addr) return
      const storageKey = `ihubNewAucTokBal${chainId}${addr}`
      const storageValue = sessionStorage.getItem(storageKey)
      if (storageValue !== null && storageValue !== "undefined" && !refetch) {
        setUserBalance(Number(storageValue))

        return
      }
      const res = await tangleship?.getTokenBalance(account, addr)
      sessionStorage.setItem(storageKey, String(res))

      setUserBalance(Number(res))
    },
    [chainId, account, tangleship, watch("providedToken"), watch("tokenOption")]
  )
  useEffect(() => {
    fetchUserBalance(false)
  }, [fetchUserBalance])

  return (
    <ItemBody active={currentStep === 0}>
      <CounterColumn>
        <StepCounter active={currentStep === 0}>0</StepCounter>
      </CounterColumn>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ItemColumn>
          <TitleContainer>
            <ItemTitle>Token Details*</ItemTitle>
            {currentStep === 0 ? (
              <ItemSubTitle>
                If you already have an ERC-20 token, choose 'Provide Token'. If
                you'd like to create one now with us, use 'Create Token'.
              </ItemSubTitle>
            ) : null}
          </TitleContainer>
          {currentStep === 0 ? (
            <>
              <SelectContainer>
                <Option
                  side="owned_token"
                  optionSelected={Boolean(
                    watch("tokenOption") === "owned_token"
                  )}
                >
                  <FormRadioInput
                    value="owned_token"
                    type="radio"
                    {...register("tokenOption", { required: true })}
                  />

                  <OptionTitle>Provide Token</OptionTitle>
                  <OptionSubTitle side="owned_token">
                    I already have a token
                  </OptionSubTitle>
                </Option>
                <Option
                  side="create_token"
                  optionSelected={Boolean(
                    watch("tokenOption") === "create_token"
                  )}
                >
                  <FormRadioInput
                    value="create_token"
                    type="radio"
                    {...register("tokenOption", { required: true })}
                  />
                  <OptionTitle>Create Token</OptionTitle>
                  <OptionSubTitle side="create_token">
                    I want to mint a new token
                  </OptionSubTitle>
                </Option>
              </SelectContainer>
              {watch("tokenOption") === "owned_token" ? (
                <InputGroup>
                  <InputLabel>Token Address*</InputLabel>
                  <Input
                    {...register("providedToken", {
                      required: watch("tokenOption") === "owned_token",
                    })}
                    autoComplete="off"
                    formError={errors.providedToken ? true : false}
                    placeholder="Enter the address of your existing token"
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(
                        /[^0-9a-zA-Z\s]/g,
                        ""
                      )
                      const parts = e.target.value.split(".")

                      if (parts.length > 2) {
                        e.target.value =
                          parts[0] + "." + parts.slice(1).join("")
                      }
                    }}
                  />
                  {tokenInfo !== undefined &&
                  watch("tokenOption") === "owned_token" &&
                  String(watch("providedToken")).trim().length > 25 &&
                  tokenInfo?.symbol === undefined &&
                  tokenInfo?.name === undefined &&
                  tokenInfo?.decimals === undefined ? (
                    <>
                      {" "}
                      <ErrorMessage
                        active={Boolean(
                          `${
                            tokenInfo !== undefined &&
                            tokenInfo?.symbol === undefined &&
                            tokenInfo?.name === undefined &&
                            tokenInfo?.decimals === undefined
                          }`
                        )}
                      >
                        <>
                          Token is invalid. Try switching chain or check token
                          address and try again
                        </>
                      </ErrorMessage>
                    </>
                  ) : (
                    <>
                      {watch("tokenOption") === "owned_token" &&
                      String(watch("providedToken")).trim().length > 25 ? (
                        <>
                          <InputSubRow>
                            <ParaTokenName>
                              {!!tokenInfo?.name ? (
                                <>
                                  <ParaTokenName>
                                    <>
                                      Name:{" "}
                                      <ParaTokenText>
                                        {tokenInfo?.name}
                                      </ParaTokenText>
                                    </>
                                  </ParaTokenName>
                                </>
                              ) : null}
                            </ParaTokenName>
                            <ParaTokenName>
                              {!!tokenInfo?.symbol ? (
                                <>
                                  Symbol:
                                  <ParaTokenText>
                                    {tokenInfo?.symbol}
                                  </ParaTokenText>
                                </>
                              ) : null}
                            </ParaTokenName>
                            <InputSubLabel
                              formError={
                                tokenInfo?.decimals !== undefined &&
                                Number(tokenInfo?.decimals) === 18
                                  ? false
                                  : true
                              }
                            >
                              {tokenInfo?.decimals !== undefined &&
                              Number(tokenInfo?.decimals) === 18 ? (
                                <>
                                  {tokenInfo?.decimals !== undefined ? (
                                    <>
                                      <ParaText>
                                        <ParaTokenName>
                                          Decimals:{" "}
                                          <ParaTokenText>
                                            {" "}
                                            {tokenInfo?.decimals}
                                          </ParaTokenText>
                                        </ParaTokenName>
                                      </ParaText>
                                    </>
                                  ) : null}
                                </>
                              ) : (
                                <ParaText>
                                  <ParaDecimals>
                                    Decimals: {tokenInfo?.decimals}
                                  </ParaDecimals>
                                  <ParaTextIcon /> Only tokens with 18 decimals
                                  are supported!
                                </ParaText>
                              )}
                            </InputSubLabel>
                          </InputSubRow>
                        </>
                      ) : null}
                      <ErrorMessage
                        active={Boolean(
                          `${
                            errors.providedToken
                              ? errors.providedToken.message
                              : null
                          }`
                        )}
                      >
                        <>
                          {errors.providedToken
                            ? errors.providedToken.message
                            : null}
                        </>
                      </ErrorMessage>
                    </>
                  )}
                </InputGroup>
              ) : watch("tokenOption") === "create_token" ? (
                <>
                  <TypeContainer>
                    <TokenType selected={watch("tokenType") === "fixed"}>
                      <FormRadioInput
                        value="fixed"
                        type="radio"
                        {...register("tokenType", {
                          required:
                            watch("tokenOption") === "create_token"
                              ? true
                              : false,
                        })}
                      />
                      <TypeTitle>Fixed</TypeTitle>
                      {/* <TypeImage src={VoidLogo} /> */}
                      <LaunchPadImageWrapper>
                        <LaunchPadImage
                          src={fixedSVG}
                          alt="tangleswap auction dutch logo"
                        />
                      </LaunchPadImageWrapper>
                      <TypeDescription>
                        A standard ERC-20 token with fixed supply and
                        protections against token minting or burning.
                      </TypeDescription>
                    </TokenType>
                    <TokenType selected={watch("tokenType") === "mintable"}>
                      <FormRadioInput
                        value="mintable"
                        type="radio"
                        {...register("tokenType", {
                          required: Boolean(
                            watch("tokenOption") === "create_token"
                          ),
                        })}
                      />
                      <TypeTitle>Mintable</TypeTitle>
                      {/* <TypeImage src={VoidLogo} /> */}
                      <LaunchPadImageWrapper>
                        <LaunchPadImage
                          src={mintSVG}
                          alt="tangleswap auction dutch logo"
                        />
                      </LaunchPadImageWrapper>
                      <TypeDescription>
                        An ERC-20 token that allows further minting at a later
                        date. Creators will have to assign an owner for the
                        minting controls.
                      </TypeDescription>
                    </TokenType>
                  </TypeContainer>
                  <InputGroup>
                    <InputLabel>Name*</InputLabel>
                    <Input
                      {...register("tokenName", {
                        required: Boolean(
                          watch("tokenOption") === "create_token"
                        ),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(
                          /[^0-9a-zA-Z\s]/g,
                          ""
                        )
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      autoComplete="off"
                      formError={errors.tokenName ? true : false}
                      placeholder="TangleSwap"
                    />
                    <InputSubLabel>
                      Choose wisely, this is final and immutable.
                    </InputSubLabel>
                  </InputGroup>
                  <InputGroup>
                    <InputLabel>Symbol*</InputLabel>
                    <Input
                      {...register("tokenSymbol", {
                        required: Boolean(
                          watch("tokenOption") === "create_token"
                        ),
                      })}
                      autoComplete="off"
                      formError={errors.tokenSymbol ? true : false}
                      placeholder="VOID"
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(
                          /[^0-9a-zA-Z]|[\s]/g,
                          ""
                        )
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                    />
                    <InputSubLabel>
                      The token symbol is also immutable.
                    </InputSubLabel>
                  </InputGroup>
                  <InputGroup>
                    <InputLabel>
                      {watch("tokenType") === "fixed"
                        ? "Total Supply*"
                        : "Initial Supply*"}
                    </InputLabel>

                    <Input
                      type="text"
                      {...register("totalSupply", {
                        required: Boolean(
                          watch("tokenOption") === "create_token"
                        ),
                      })}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9.]/g, "")
                        const parts = e.target.value.split(".")

                        if (parts.length > 2) {
                          e.target.value =
                            parts[0] + "." + parts.slice(1).join("")
                        }
                      }}
                      autoComplete="off"
                      formError={errors.totalSupply ? true : false}
                      placeholder="100,000,000"
                    />
                    <InputSubLabel>
                      {watch("tokenType") === "fixed"
                        ? "Because your token type is set to fixed, this value will be final and immutable."
                        : "Because your token type is set to mintable, the supply can be increased in the future."}
                    </InputSubLabel>
                  </InputGroup>
                </>
              ) : null}
              {watch("tokenOption") === "owned_token" &&
              !!watch("providedToken") &&
              addressInvalid ? (
                <>
                  {" "}
                  <ErrorMessage
                    active={Boolean(
                      watch("tokenOption") === "owned_token" &&
                        !!watch("providedToken") &&
                        addressInvalid
                    )}
                  >
                    <>Token Address is invalid</>
                  </ErrorMessage>
                </>
              ) : null}
              <InputGroup>
                <InputLabel>Amount of Tokens for Sale*</InputLabel>
                <Input
                  type="text"
                  {...register("tokensForSale", { required: true })}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9.]/g, "")

                    const parts = e.target.value.split(".")

                    if (parts.length > 2) {
                      e.target.value = parts[0] + "." + parts.slice(1).join("")
                    }
                  }}
                  autoComplete="off"
                  formError={errors.tokensForSale ? true : false}
                  placeholder="These are the tokens that you will auction to the
                  public"
                />{" "}
                {String(watch("tokensForSale")).trim() === "" ? null : (
                  <>
                    {watch("tokenOption") === "create_token" ? (
                      <>
                        {watch("tokenOption") === "create_token" &&
                        parseFloat(String(watch("totalSupply"))) > 0 &&
                        parseFloat(String(watch("tokensForSale"))) >
                          parseFloat(String(watch("totalSupply"))) ? (
                          <>
                            {" "}
                            <ErrorMessage
                              active={Boolean(
                                watch("tokenOption") === "create_token" &&
                                  parseFloat(String(watch("totalSupply"))) >
                                    0 &&
                                  parseFloat(String(watch("tokensForSale"))) >
                                    parseFloat(String(watch("totalSupply")))
                              )}
                            >
                              <>
                                Tokens for sale can not be greater than total
                                supply
                              </>
                            </ErrorMessage>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {Number(tokenSupply) > 0 &&
                        watch("tokensForSale") > Number(tokenSupply) ? (
                          <>
                            <ErrorMessage
                              active={Boolean(
                                Number(tokenSupply) > 0 &&
                                  watch("tokensForSale") > Number(tokenSupply)
                              )}
                            >
                              <>
                                Tokens for sale can not be greater than total
                                supply
                              </>
                            </ErrorMessage>
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                {watch("tokenOption") === "owned_token" &&
                !!watch("providedToken") &&
                watch("tokensForSale") > Number(userBalance) ? (
                  <>
                    {" "}
                    <ErrorMessage
                      active={Boolean(
                        watch("tokenOption") === "owned_token" &&
                          !!watch("providedToken") &&
                          watch("tokensForSale") > Number(userBalance)
                      )}
                    >
                      <>Tokens for sale cannot be more than your balance</>
                    </ErrorMessage>
                  </>
                ) : null}
              </InputGroup>
              <ActionContainer>
                <NextButton
                  disabled={
                    (watch("tokenOption") === "owned_token" &&
                      !!watch("providedToken") &&
                      watch("tokensForSale") > Number(userBalance)) ||
                    parseInt(String(watch("tokensForSale"))) === 0 ||
                    parseInt(String(watch("totalSupply"))) === 0 ||
                    Number(watch("tokensForSale")) >
                      Number(watch("totalSupply"))
                  }
                  type="submit"
                >
                  Continue
                </NextButton>
              </ActionContainer>
            </>
          ) : null}
        </ItemColumn>
      </form>
    </ItemBody>
  )
}

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0;
`

const TypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const TokenType = styled.div<DetailsProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  max-width: 250px;
  border: ${(props) =>
    props.selected ? `1px solid ${TangleColors.cta}` : "1px solid transparent"};
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s ease-in;
  background: ${(props) =>
    props.selected ? TangleColors.cardPrimary : TangleColors.swapBG};
  color: ${TangleColors.white};
  position: relative;
  &:hover {
    border: 1px solid
      ${(props) =>
        props.selected ? TangleColors.cta : TangleColors.lighthover};
    background: ${(props) =>
      props.selected ? TangleColors.modalBG : TangleColors.cardSecondary};
  }
`

const TypeTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const TypeDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
`

const Option = styled.div<DetailsProps>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.side === "owned_token" ? "flex-end" : "flex-start"};
  padding: 32px;
  max-width: 250px;
  border: 1px solid ${TangleColors.lighthover};
  border-radius: ${(props) =>
    props.side === "owned_token" ? "12px 0 0 12px" : "0 12px 12px 0"};
  cursor: pointer;
  transition: 0.4s ease-in;
  background: ${(props) =>
    props.optionSelected ? TangleColors.cta : TangleColors.modalBG};
  color: ${(props) =>
    props.optionSelected ? TangleColors.white : TangleColors.lighthover};
  position: relative;
  &:hover {
    color: ${(props) =>
      props.optionSelected ? TangleColors.white : TangleColors.lighthover};
    border: 1px solid
      ${(props) =>
        props.optionSelected ? TangleColors.cta : TangleColors.lighthover};
  }
`

const OptionTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`

const OptionSubTitle = styled.div<DetailsProps>`
  font-size: 14px;
  font-weight: 500;
  text-align: ${(props) =>
    props.side === "owned_token" ? "create_token" : "owned_token"};
`

const InputSubRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const ParaText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${TangleColors.tangleYellow};
  margin: 2px 0;
`

const ParaTextIcon = styled(IoWarningOutline)`
  color: ${TangleColors.tangleYellow};
  margin: 0 2px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
`

const ParaTokenName = styled(Caption)`
  color: ${TangleColors.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2px 0;
`
const ParaTokenText = styled(Caption)`
  color: ${TangleColors.lighthover};
  margin: 0 0 0 6px;
`

const ParaDecimals = styled.div`
  color: ${TangleColors.red};
  margin: 0 4px 0 2px;
`
const ErrorMessage = styled(Caption)<DetailsProps>`
  color: ${TangleColors.red};
  display: ${(props) => (props.active ? "flex" : "none")};
`
export default LaunchPadDetails
