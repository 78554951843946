export enum LiquidityLauncherTemplate {
  PostAuctionLauncher = 1,
}

export enum AuctionTemplate {
  NOT_SET = "not_set",
  CROWDSALE = "crowdsale",
  DUTCH_AUCTION = "dutch",
  BATCH_AUCTION = "batch",
  HYPERBOLIC_AUCTION = "hyperbolic",
}

export enum AuctionStatus {
  LIVE = 1,
  UPCOMING = 2,
  FINISHED = 3,
}

export interface AuctionTokenInfo {
  address: string
  decimals: number
  name: string
  symbol: string
}

export interface TokenInfoType {
  address: string
  chainId: number
  decimals: 18
  logoURI: string
  name: string
  symbol: string
}

export interface Currency {
  chainId: number
  address: string
  isNative: boolean
  isToken: boolean
  name: string
  symbol: string
  decimals: number
}

export interface AuctionDocument {
  icon: string
  description: string
  website: string
  whitepaper?: string
  tokenomics?: string
  github?: string
  telegram?: string
  wechat?: string
  discord?: string
  medium?: string
  reddit?: string
  twitter?: string
  docs?: string
  desktopBanner: string
  mobileBanner: string
  bannedCountries?: string
  bannedWarning?: string
  category: string
}

export interface AuctionPaymentCurrencyInfo {
  address: string
  decimals: number
  name: string
  symbol: string
}

export interface RawAuction {
  address: string
  endTime: number
  finalized: boolean
  startTime: number
  templateId: number
  tokenInfo: {
    address: string
    decimals: number
    name: string
    symbol: string
  }
}

export interface RawAuctionInfo {
  address: string
  auctionSuccessful: boolean
  commitmentsTotal: number
  documents: AuctionDocument[]
  endTime: number
  finalized: boolean
  goal: number
  paymentCurrency: string
  paymentCurrencyInfo: AuctionPaymentCurrencyInfo
  rate?: number
  startTime: number
  tokenInfo: AuctionTokenInfo
  totalTokens: number
  totalTokensCommitted?: number
  usePointList: boolean
  minimumPrice?: number
  startPrice?: number
  minimumCommitmentAmount?: number
  liquidityTemplate?: number
  lpTokenAddress?: string
}

export interface RawMarketInfo {
  claimed: number
  commitments: number
  tokensClaimable: number
  isAdmin: boolean
}

export interface RawLauncherInfo {
  address: string
  locktime: number
  unlock: number
  liquidityPercent: number
  launched: boolean
  liquidityAdded: number
  token1Balance: number
  token2Balance: number
  liquidityTemplate: number
}

export interface Auction {
  address: string
  endTime: number
  finalized: boolean
  startTime: number
  templateId: number
  tokenInfo: AuctionTokenInfo
  status: AuctionStatus
  title: string
  auctionTemplate: AuctionTemplate
}

export interface AuctionCommitment {
  txHash: string
  blockNumber: number
  address: string
  amount: number
}

export type PurplelistEntry = {
  account: string
  amount: string
}

export enum AuctionCategory {
  DEFI = "DeFi",
  GAMING = "Gaming",
  UTILITY = "Utility",
  SOCIAL = "Social",
  GOVERNANCE = "Governance",
  NFTS = "NFTs",
}

export enum TokenType {
  CUSTOM = 0,
  FIXED = 1,
  MINTABLE = 2,
}

export enum TokenSetup {
  NOT_SET = "not_set",
  CREATE = "create_token",
  PROVIDE = "owned_token",
}

export interface AuctionCreationWizardInput {
  tokenSetupType?: any
  paymentCurrencyAddress: string
  paymentCurrencySymbol?: string
  startDate: string
  endDate: string
  tokenType: TokenType
  tokenAddress?: string
  tokenName?: string
  tokenSymbol?: string
  tokenSupply?: number
  tokenForLiquidity: number
  auctionType?: string
  fixedPrice?: string
  minimumTarget?: number
  minimumRaised?: number
  maximumRaised?: number
  tokensForSale?: number
  startPrice?: number
  endPrice?: number
  liqLockTime?: number
  liqPercentage?: number
  liqLauncherEnabled?: boolean
  purplelistEnabled?: boolean
  purplelistData?: string[]
  feeTier: number
  tokenDecimals?: number
  paymentCurrencyDecimals?: number
}

export interface MarketProps {
  auctionAddress?: string
  auctionStatus?: number
  auctionToken?: any
  auctionType?: string
  currentAuctionPrice?: string
  currentTokenPrice?: number
  documents?: any
  endTime?: any
  isFinalized?: boolean
  isLPLauncherEnabled?: boolean
  isSuccessful?: boolean
  paymentToken?: any
  startTime?: any
  tokensForSale?: number
  tokensRemaining?: number
  totalCommitments?: any
  currentProgress?: number
  isPurplelistEnabled?: boolean
  category?: any
}

export interface CommitmentProps {
  account?: string
  amount?: string
  block?: string
  timestamp?: string
  hash?: string
}

export interface AuctionDetailsProps {
  addr: string
  auctionAddress: string
  auctionSuccessful: boolean
  auctionType: string
  commitmentsTotal: any
  documents: any
  endTime: any
  finalized: boolean
  minimumPrice: any
  paymentCurrency: string
  paymentCurrencyInfo: any
  startPrice: any
  startTime: any
  tokenInfo: any
  totalTokens: any
  usePointList: boolean
}
