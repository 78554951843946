import { createAction } from "@reduxjs/toolkit"
import { Field } from "@tangleswap/sdk"
import { UPDATE_DEFAULT_TOKEN } from "./actionsTypes"

export const selectCurrency = createAction<{
  field: Field
  currencyAddress: any
  chainId?: any
}>("swap/selectcurrency")

export const switchCurrencies = createAction<void>("swap/switchcurrencies")
export const typeInput = createAction<{ field: Field; typedValue: any }>(
  "swap/typeinput"
)
export const selectDefaultToken = (data: any) => {
  return {
    type: UPDATE_DEFAULT_TOKEN,
    data,
  }
}

export const checkDefaultToken = (chainId: any) => {
  return (dispatch: any) => {
    dispatch(selectDefaultToken(chainId))
  }
}
